import React, { useState, useEffect } from "react";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import Layout from "../components/include/layout";
import DashboardItems from "./dashboardItems";
import "../scss/dashboard/dasboardmap.scss";
import arrow from "../assets/dashboard/arrow.png";
import location from "../assets/dashboard/location.png";

import { MapContainer, Marker, Popup, TileLayer, useMapEvent } from "react-leaflet";
import { get_agent_details } from "../data/api";
import Loading from "../components/customcomponents/loading";

export default function DashboardMap() {
   const [loading, setLoading] = useState(true);
   const [agentdata, setAgentData] = useState("");

   const [position, setPosition] = useState(null);
   useEffect(() => {
      async function get_agent() {
         const res = await get_agent_details();
         const response = res.data.data;

         setAgentData(response);
      }

      get_agent();
   }, [loading]);
   useEffect(() => {
      if (agentdata === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });

   return loading ? (
      <Loading />
   ) : (
      <div>
         <h2 id="billings">Nearest Outlet</h2>
         <p id="billings-details">Findout your nearest DishHome outlet</p>
         <div className="map-align">
            <div style={{ flexBasis: "40%" }}>
               {Object.entries(agentdata).map(([key, value]) => {
                  return (
                     <Location
                        name={value.name}
                        address={value.address}
                        setPosition={setPosition}
                        position={value.coordinates}
                     />
                  );
               })}
            </div>
            <div
               style={{
                  flexBasis: "55%",
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <MapDisplay data={agentdata} position={position} />
            </div>
         </div>
      </div>
   );
}

export function Location({ name, address, setPosition, position }) {
   return (
      <div className="map">
         <div>
            <p id="map-title">{name}</p>
            <p id="map-address">
               {" "}
               <img style={{ marginRight: "5px" }} src={location} height="15px" /> {address}
            </p>
         </div>
         <div onClick={() => setPosition(position)}>
            <img height="18px" src={arrow} />
            <p id="direction">Directions</p>
         </div>
      </div>
   );
}

export function MapDisplay({ data, position }) {
   // console.log("pos", position);
   return (
      <div>
         <MapContainer className="map" center={position} zoom={12} scrollWheelZoom={false}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {Object.entries(data).map(([key, value]) => {
               return (
                  <Marker position={value.coordinates}>
                     <Popup>{value.name}</Popup>
                  </Marker>
               );
            })}
         </MapContainer>
      </div>
   );
}
