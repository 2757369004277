import React, { useState, useEffect } from "react";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import "../scss/ott/ottFeature.scss";
import PlayIcon from "../assets/ott/play_icon.png";
import HdIcon from "../assets/ott/hd_icon.png";
import LoopIcon from "../assets/ott/loop_icon.png";
import TvshowIcon from "../assets/ott/tvshow_icon.png";
import { get_why_dishhome } from "../data/api";

const OttFeature = () => {
   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_banner() {
         const res = await get_why_dishhome();
         const response = res.data.data;
         setBannerData(response);
      }
      get_banner();
   }, []);
   return (
      <Widthcontainer>
         <div className="ottFeature__container fixed-width">
            {bannerdata &&
               Object.entries(bannerdata).map(([key, value]) => {
                  return (
                     <>
                        {value.page === "DishHome_go" ? (
                           <>
                              <div className="ottFeatureText">
                                 {/* <p className="feature_text">Features</p> */}
                                 <h2 className="featuretextt_heading">{value.title}</h2>
                                 <p className="featuretext_para">{value.description}</p>
                              </div>
                              <div className="ottFeatureCards">
                                 {Object.entries(value.whyDishhome).map(([key, item]) => {
                                    return (
                                       <OttFeatureCard
                                          src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                                          heading={item.title}
                                          para={item.description}
                                          key={key}
                                       />
                                    );
                                 })}
                              </div>
                           </>
                        ) : (
                           <></>
                        )}
                     </>
                  );
               })}
         </div>
      </Widthcontainer>
   );
};

function OttFeatureCard(props) {
   return (
      <div className="ottFeatureCard" key={props.key}>
         <img src={props.src} alt="" />
         <h2 className="feature_card_heading">{props.heading}</h2>
         <p className="feature_card_text">{props.para}</p>
      </div>
   );
}

export default OttFeature;
