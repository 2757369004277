import React, { useState } from "react";
// import { Button } from "react-bootstrap";
// import banner from "../../assets/kmg_banner.png";
import "../../scss/components/midbanner.scss";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";
import Widthcontainer from "./widthcontainer";

const ComponentToTrack = (props) => {
   return (
      <div className="midBanner__player-wrapper">
         <ReactPlayer
            url={`${process.env.REACT_APP_FILE_URL}/${props.link}`}
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            // controls={true}
            className="react-player"
            muted={true}
            style={{
               position: "absolute",
               zIndex: "1",
               pointerEvents: "none",
            }}
            config={{
               youtube: {
                  playerVars: {
                     autoplay: 1,
                     rel: 1,
                     controls: 0,
                     showinfo: 0,
                     disablekb: 1,
                     modestbranding: 1,
                     loop: 1,
                     iv_load_policy: 3,
                  },
               },
            }}
            playsinline
         />
      </div>
   );
};

export default function Banner({ banner, data }) {
   //UseState Hooks
   const [show, setShow] = useState("0"); //For Modal Show/Hide
   const [title, setTitle] = useState("TV Shows"); //KGM Banner Title
   // console.log("banner", data);
   //Functions
   const handleClick = (id) => {
      setShow(id);
   };

   return (
      <div
         className="tv_banner__wraper"
         // style={{
         //    "background-image":
         //       "linear-gradient(90deg,rgba(0, 0, 0, 0.6) 45.2%,rgba(67, 6, 8, 0) 100%),url(" +
         //       `${process.env.REACT_APP_FILE_URL}/${banner.media}` +
         //       ")",
         // }}
      >
         {Object.entries(data).map(([key, value]) => {
            if (key === show) {
               return (
                  <TrackVisibility>
                     <ComponentToTrack link={value?.media} />
                  </TrackVisibility>
               );
            } else return null;
         })}
         <Widthcontainer>
            <div className="tv_banner__wrapper fixed-width">
               <div className="tv_banner__container ">
                  <div className="tv_banner__button_group">
                     {Object.entries(data).map(([key, value]) => {
                        // console.log("key", key);
                        return (
                           <button
                              className={
                                 show === key ? "tv_banner__button active" : "tv_banner__button"
                              }
                              onClick={() => handleClick(key)}
                           >
                              {value.title}
                           </button>
                        );
                     })}
                  </div>
                  {Object.entries(data).map(([key, value]) => {
                     if (key === show) {
                        return (
                           <div className="tv_banner__text">
                              <p className="tv_banner__heading">{value.title}</p>
                              <p className="tv_banner__para">{value.description}</p>
                              <>
                                 {Object.entries(value.channels).map(([key, value]) => {
                                    // console.log("key", key);
                                    // console.log("value", value);
                                    return (
                                       <img
                                          src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                          alt=""
                                          height={55}
                                          width={55}
                                          style={{ marginRight: "10px", marginBottom: "10px" }}
                                       />
                                    );
                                 })}
                              </>
                           </div>
                        );
                     } else return null;
                  })}
               </div>
            </div>
         </Widthcontainer>
      </div>
   );
}
