import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useMemo, useEffect } from "react";
import Layout from "../components/include/layout";
import DashboardItems from "./dashboardItems";
import "../scss/dashboard/main.scss";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import ActivePackage from "./activepackage";
import DashboardAccount from "./dashboardAccount";
import { Formik } from "formik";
import * as yup from "yup";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import DashboardBillings from "./dashboardBillings";
import DashboardSupports from "./dashboardsupports.js";
import DashboardPassword from "./dashboardPassword";
import DashboardMap from "./dashboardmap";
import DashboardFeedback from "./dashboardFeedback";
import DashboardPpv from "./dashboardPpv";
import Token from "../utils/useToken";
import DashboardOrderHistory from "./dashboardOrderHistory";
import Wishlist from "../store/pages/wishlist";
import { useMediaQuery } from "react-responsive";
import { Offcanvas } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { ImCircleRight } from "react-icons/im";
import Dashboardreferal from "./dashboardreferal";
import DashboardNotifications from "./dashboardNotifications";
import DashboardDocument from "./dashboardDocument";
import seoTags from "../utils/seoTags";
import { Button, FormControl, Form, FormLabel, InputGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { otp_login, otp_set_password, update_profile } from "../data/protectedapi";
import useToken from "../utils/useToken";

import MessageModal from "../components/customcomponents/messageModal";
import { verify_otp, verify_otp_users } from "../data/protectedapi";
import { change_otp_userpassword } from "../data/protectedapi";
import { FaLaptopHouse } from "react-icons/fa";
import DashboardTransaction from "./dashboardTransaction";
import DishHomeGoHistory from "./dishHomeGoHistory";
import IPTVPPV from "./iptvppv";
const Main = () => {
   const { token } = Token();

   const userDetails = token.user;
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let type = query.get("type");
   //
   const [item, setItem] = useState("0");
   const [show, setShow] = useState(false);
   const [wishStatus, setwishStatus] = useState(false);
   const [cartupdate, setCartupdate] = useState(false);
   const [hasPassword, setHasPassword] = useState(false);
   const [resetToken, setResetToken] = useState("");
   const [changePasswordModal, setchangePasswordModal] = useState(false);
   const [showNewpassword, setshowNewpassword] = useState(false);
   const [showconfirmPassword, setshowconfirmPassword] = useState(false);
   const [message, setMessage] = useState("");
   const [emptyCode, setEmptyCode] = useState("");
   const [emptyOtp, setEmptyOtp] = useState(false);
   const [otpNumber, setOtpNumber] = useState("");
   const [verificationCode, setVerificationCode] = useState("");
   const [verifyModal, setVerifyModal] = useState(false);
   const [submitModal, setSubmitModal] = useState(false);
   const [displayMessage, setDisplayMessage] = useState(false);
   const [modalError, setModalError] = useState(false);
   const [otpStatus, setOtpStatus] = useState(false);
   const { setToken } = useToken();
   const [invalidOtp, setInvalidOtp] = useState(false);

   const [messageModal, setMessageModal] = useState(false);

   //close has password modal
   const closePasswordModal = (id) => {
      setHasPassword(false);
   };

   const newPassword = () => {
      setshowNewpassword(!showNewpassword);
   };
   const confirmPassword = () => {
      setshowconfirmPassword(!showconfirmPassword);
   };
   //get OTP
   const handleOTP = async () => {
      const response = await otp_set_password(
         otpNumber?.length > 10
            ? otpNumber?.slice(0, 4) === "+977"
               ? otpNumber?.slice(4, 18)
               : otpNumber
            : otpNumber,
      );
      if (response?.status === 200) {
         setDisplayMessage(true);
      }
      // console.log("response", response);
   };

   //get user profile
   const [userProfile, setUserprofile] = useState(false);

   useEffect(() => {
      async function get_profile() {
         const response = await update_profile();
         setUserprofile(response?.data);
      }
      get_profile();
   }, []);
   // console.log("user", userProfile);
   //verify OTP
   const verifyOTP = async () => {
      if (verificationCode === "") {
         setEmptyCode(true);
      } else {
         setEmptyCode(false);

         const response = await verify_otp_users(verificationCode);
         if (response.status === 200) {
            // setVerifyModal(true);
            setHasPassword(false);
            setResetToken(response?.data?.token?.resetToken);
            setOtpStatus(true);
            setchangePasswordModal(true);
         } else {
            setInvalidOtp(true);
         }
         // console.log("response", response);
      }
   };

   //close otp passwordmodal
   const closeOtpModal = () => {
      setchangePasswordModal(false);
   };
   //submit password from
   const handleSubmit = async (data) => {
      const res = await change_otp_userpassword(resetToken, data.newpassword, data.confirmpassword);
      if (res.status === 200) {
         setMessageModal(true);
         setchangePasswordModal(false);
      } else {
         // console.log("error", res?.response);
         setchangePasswordModal(false);
         setModalError(true);
         setMessage(res?.response?.data?.message);
      }
   };
   const { clearToken } = Token();
   let navigate = useNavigate();
   const handleLogout = () => {
      clearToken();
      window.location.href = "/";
   };
   const isResponsive = useMediaQuery({ query: "(min-width: 1100px)" });
   const isMobileResponsive = useMediaQuery({ query: "(max-width: 1100px)" });
   const handleShow = () => setShow(true);
   const handleClose = () => setShow(false);
   useEffect(() => {
      setItem(type === null ? "0" : type);
   }, [type]);

   //FORMIK
   const schema = yup.object().shape({
      newpassword: yup
         .string()
         .required("New Password is required")
         .matches(
            /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
            "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character and no whitespace",
         ),
      confirmpassword: yup
         .string()
         .required("Confirm Password is required")
         .oneOf([yup.ref("newpassword")], "Password do not match"),
   });
   // console.log("otpstatus", otpStatus);
   return (
      <Layout
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
         wishState={wishStatus}
         setWishState={setwishStatus}
      >
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading="Password created successfully!"
            buttonText="Ok"
            reload={true}
         />
         <MessageModal
            show={submitModal}
            setShow={setSubmitModal}
            heading="Password created"
            message="Your password has been created successfully. Thank you for using DishHome"
            buttonText="Ok"
            reload={true}
         />
         <MessageModal
            show={modalError}
            setShow={setModalError}
            type="error"
            message={message}
            buttonText="Ok"
         />
         <MessageModal
            show={verifyModal}
            setShow={setVerifyModal}
            heading="OTP verified"
            buttonText="Ok"
         />
         <Modal
            centered
            show={hasPassword}
            onHide={closePasswordModal}
            animation={false}
            id="deletecart-modal"
         >
            {/* OTP MODAL */}
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body" style={{ textAlign: "center" }}>
               {otpStatus === false ? (
                  <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Verify OTP</h2>
               ) : (
                  <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Create password</h2>
               )}

               <FormControl
                  type="number"
                  aria-label="customer id"
                  placeholder="Enter number"
                  aria-describedby="basic-addon2"
                  name="customer_id"
                  onChange={(e) => setOtpNumber(e.target.value)}
                  value={otpNumber}
               />
               <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  className={"apply-cas-button"}
                  onClick={handleOTP}
               >
                  Get OTP
               </Button>

               {emptyOtp === true && (
                  <p style={{ color: "red", textAlign: "left" }}>Field can't be empty</p>
               )}
               {/* <InputGroup className="mb-3" style={{ width: "400px", height: "40px" }}>
                  <FormControl
                     type="text"
                     placeholder="Enter OTP"
                     aria-label="customer id"
                     value={verificationCode}
                     onChange={(e) => setVerificationCode(e.target.value)}
                     aria-describedby="basic-addon2"
                     name="customer_id"
                  />
                  <Button
                     variant="outline-secondary"
                     id="button-addon2"
                     className={"apply-cas-button"}
                     onClick={verifyOTP}
                  >
                     Verify OTP
                  </Button>
               </InputGroup> */}
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               {/* <Button variant="secondary" id="cancel">
                  Cancel
               </Button>
               <Button variant="primary" id="removecart">
                  Create password
               </Button> */}
            </Modal.Footer>
         </Modal>

         {/* PASSWORD MODAL */}
         <Modal
            centered
            show={changePasswordModal}
            onHide={() => {
               setchangePasswordModal(false);
            }}
            animation={false}
            id="deletecart-modal"
         >
            <Modal.Body id="modal-body">
               {otpStatus === false ? (
                  <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Verify OTP</h2>
               ) : (
                  <h2 style={{ textAlign: "left", marginBottom: "20px" }}>Create password</h2>
               )}

               {otpStatus === false ? (
                  <>
                     <Button variant="secondary" className={"apply-otp-button"} onClick={handleOTP}>
                        Get OTP
                     </Button>

                     {emptyOtp === true && <p style={{ color: "red" }}>Field can't be empty</p>}
                     {displayMessage === true && (
                        <p style={{ color: "green", marginTop: "5px" }}>
                           OTP sent to your registered number {userDetails?.phone}!
                        </p>
                     )}
                     <InputGroup
                        className="mb-3"
                        style={{ width: "400px", height: "40px", marginTop: "15px" }}
                     >
                        <FormControl
                           type="text"
                           placeholder="Enter OTP"
                           aria-label="customer id"
                           value={verificationCode}
                           onChange={(e) => setVerificationCode(e.target.value)}
                           aria-describedby="basic-addon2"
                           name="customer_id"
                        />
                        <Button
                           variant="outline-secondary"
                           id="button-addon2"
                           className={"apply-cas-button"}
                           onClick={verifyOTP}
                        >
                           Verify OTP
                        </Button>
                        <br />
                     </InputGroup>
                     {invalidOtp === true && <p style={{ color: "red" }}>Invalid OTP</p>}
                     {emptyCode === true && <p style={{ color: "red" }}>Field can't be empty</p>}
                  </>
               ) : (
                  <>
                     <Formik
                        validationSchema={schema}
                        initialValues={{
                           newpassword: "",
                           confirmpassword: "",
                        }}
                        onSubmit={(values, actions) => {
                           setTimeout(() => {
                              actions.setSubmitting(false);
                              handleSubmit(values);
                              actions.resetForm();
                           });
                        }}
                     >
                        {({ handleSubmit, submitForm, handleChange, values, touched, errors }) => (
                           <Form onSubmit={handleSubmit}>
                              <div className={"Password_Container"}>
                                 <Form.Label id="dashboard-label">New password</Form.Label>
                                 <Form.Control
                                    id="input-dashboard newpw"
                                    type={showNewpassword ? "text" : "password"}
                                    name="newpassword"
                                    controlId="validationFormik01"
                                    value={values.newpassword}
                                    onChange={handleChange}
                                    style={{ paddingRight: "38px" }}
                                    isValid={touched.newpassword && !errors.newpassword}
                                    onPaste={(e) => {
                                       e.preventDefault();
                                       return false;
                                    }}
                                 />
                                 {!showNewpassword && (
                                    <AiOutlineEyeInvisible
                                       size={19}
                                       className={"Pw_Icon"}
                                       onClick={newPassword}
                                    />
                                 )}
                                 {showNewpassword && (
                                    <span className={"Pw_Icon"} onClick={newPassword}>
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                             fill="#374253"
                                          />
                                       </svg>{" "}
                                    </span>
                                 )}
                              </div>

                              <p style={{ color: "red" }} className="error">
                                 {errors.newpassword}
                              </p>

                              <div className={"Password_Container"} style={{ marginTop: "15px" }}>
                                 <Form.Label id="dashboard-label">Confirm New Password</Form.Label>
                                 <Form.Control
                                    id="input-dashboard confpw"
                                    type={showconfirmPassword ? "text" : "password"}
                                    name="confirmpassword"
                                    value={values.confirmpassword}
                                    onChange={handleChange}
                                    style={{ paddingRight: "38px" }}
                                    isValid={touched.confirmpassword && !errors.confirmpassword}
                                    onPaste={(e) => {
                                       e.preventDefault();
                                       return false;
                                    }}
                                 />

                                 {!showconfirmPassword && (
                                    <AiOutlineEyeInvisible
                                       size={19}
                                       className={"Pw_Icon"}
                                       onClick={confirmPassword}
                                    />
                                 )}
                                 {showconfirmPassword && (
                                    <span className={"Pw_Icon"} onClick={confirmPassword}>
                                       <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                       >
                                          <path
                                             d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                             fill="#374253"
                                          />
                                       </svg>{" "}
                                    </span>
                                 )}

                                 <p style={{ color: "red" }} className="error">
                                    {errors.confirmpassword}
                                 </p>
                              </div>
                              <div className="button_wrapper_modal">
                                 <Button
                                    type="submit"
                                    className={" cancel "}
                                    onClick={() => {
                                       setchangePasswordModal(false);
                                       window.location.reload();
                                    }}
                                 >
                                    Cancel
                                 </Button>
                                 <Button id="dashboardform-btn" type="submit">
                                    Save Changes
                                 </Button>
                              </div>

                              <Modal.Footer id="modal-footer"></Modal.Footer>
                           </Form>
                        )}
                     </Formik>
                  </>
               )}
            </Modal.Body>
         </Modal>

         {userProfile?.has_password === false && (
            <div className="kyc_notice">
               <p>
                  Please secure your acount by creating new password
                  <br />
                  <a style={{ cursor: "pointer" }} onClick={() => setchangePasswordModal(true)}>
                     Do it now{"-> "}
                  </a>
               </p>
            </div>
         )}

         {userProfile?.has_password === true && userDetails?.is_kyc_verified === false && (
            <div className="kyc_notice">
               <p>
                  Please fill the kyc form. It will take 2 minutes to fill the form
                  <br />
                  <a href="/dashboard?type=1">Do it now </a>
               </p>
            </div>
         )}

         {seoTags(
            "Dashboard | DishHome Nepal",
            "Dashboard",
            "Dashboard",
            "Dashboard",
            null,
            `${process.env.REACT_APP_PUBLIC_URL}/dashboard`,
         )}
         <Widthcontainer>
            <div className="dashboard_container fixed-width mb-5">
               {isMobileResponsive && (
                  <Offcanvas
                     show={show}
                     onHide={handleClose}
                     style={{
                        width: "330px",
                        background: "rgba(255,255,255,0.9)",
                        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                        backdropFilter: "blur(14px)",
                        // WebkitBackdropFilter: "blur(14px)",
                        transition: "transform 1.2s",
                     }}
                  >
                     <Offcanvas.Body>
                        <button
                           className="close"
                           onClick={() => handleClose()}
                           style={{
                              position: "absolute",
                              top: "18px",
                              right: "22px",
                              background: "transparent",
                              border: "none",
                              color: "#fff",
                              fontSize: "20px",
                              zIndex: "1",
                           }}
                        >
                           <AiOutlineClose />
                        </button>
                        <DashboardItems setItem={setItem} item={item} mobileClose={handleClose} />
                     </Offcanvas.Body>
                  </Offcanvas>
               )}
               {isResponsive && <DashboardItems setItem={setItem} item={item} />}

               <div
                  style={
                     isMobileResponsive
                        ? { flex: "1", paddingLeft: "12px", paddingRight: "12px" }
                        : { flex: "1" }
                  }
               >
                  {isMobileResponsive && (
                     <div
                        className="table_of_content_btn cursor_pointer"
                        onClick={() => handleShow()}
                     >
                        Dashboard Menu &nbsp;&nbsp;
                        <ImCircleRight />
                     </div>
                  )}
                  {item === "0" ? <ActivePackage /> : null}
                  {item === "1" ? <DashboardAccount /> : null}
                  {item === "2" ? <DashboardTransaction /> : null}

                  {/* {item === "2" ? <DashboardBillings /> : null} */}
                  {item === "3" ? <DashboardSupports /> : null}
                  {item === "4" ? <DashboardPassword /> : null}
                  {item === "5" ? <DashboardMap /> : null}
                  {item === "6" ? <DashboardFeedback /> : null}
                  {item === "8" ? <DashboardPpv /> : null}
                  {item === "9" ? <DashboardOrderHistory /> : null}
                  {item === "10" ? <Wishlist /> : null}
                  {item === "11" ? <Dashboardreferal /> : null}
                  {item === "7" ? handleLogout() : null}
                  {item === "12" ? <DashboardNotifications /> : null}
                  {item === "13" ? <DashboardDocument /> : null}
                  {item === "14" ? <IPTVPPV /> : null}
                  {item === "16" ? <DishHomeGoHistory /> : null}
                  {/* {item === "14" ? <DashboardTransaction /> : null} */}
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export default Main;
