import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import tick from "../../../assets/tick.svg";
import Loading from "../../../components/customcomponents/loading";
import { get_individual_package, get_single_internet } from "../../../data/api";
import "../../../scss/packages/indvpackagecard.scss";
import SeoTags from "../../../utils/seoTags";
import { useLocation } from "react-router-dom";
import Error from "../../../components/pages/404error";
import ErrorPage from "../../../components/pages/errorPage";
import { ShareBtnPopup } from "../../../utils/shareBtnPopup";

const IndividualInternetPackageCard = ({ id }) => {
   const [packageData, setPackageData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [duration, setDuration] = useState("6 Months");
   let navigate = useNavigate();

   //use query
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let type = query.get("type");
   useEffect(() => {
      if (type) {
         setDuration(type);
      }
   }, [type]);
   useEffect(() => {
      async function fetchData() {
         const response = await get_single_internet(id);
         setPackageData(response?.data?.data);
      }
      fetchData();
   }, [empty]);
   useEffect(() => {
      if (packageData === "" || packageData === null) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [packageData]);

   useEffect(() => {
      let status = false;
      packageData &&
         Object.entries(packageData.prices).map(([key, value]) => {
            if (value.type === duration) {
               status = true;
            }
         });
      packageData && !status && setDuration("1 Month");
   }, [packageData]);

   return empty ? (
      <Loading />
   ) : (
      <div className="individualpackagecard_wrapper px-3 py-1">
         {/* <div className="ribbon">Free Installation</div> */}
         {SeoTags(
            `${packageData?.title} | DishHome Nepal`,
            packageData?.seo_title,
            packageData?.seo_description,
            packageData?.seo_keywords,
            "DishHome",
            ` ${process.env.REACT_APP_PUBLIC_URL}tv/${id}`,
         )}

         <div className="info_wrapper py-4 mx-5">
            <h3 className="title">{packageData?.title}</h3>
            <p className="description px-2">{packageData?.description}</p>
            <h1 className="price">
               <sup>Rs. </sup>{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  let totalPrice =
                     value?.installation_charge +
                     value?.internet_price +
                     value?.drop_wire +
                     value?.itv_price +
                     value?.itv_stb_charge +
                     value?.router;
                  if (value.type === duration) {
                     return totalPrice.toLocaleString("hi-IN");
                  }
               })}
               *<sub> {duration}</sub>
            </h1>
         </div>
         <div className="individualpackagecard_feature_wrapper py-4 mx-5">
            <div style={{ position: "absolute", right: "6px", top: "-30px" }}>
               <ShareBtnPopup type={"icon"} />
            </div>
            <li
               style={{ display: "flex", marginBottom: "-15px", alignItems: "baseline" }}
               className="li_alignment"
            >
               {" "}
               <img src={tick} alt="tick" />
               <p>Speed: {packageData?.speed}</p>
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               Internet Charge:&nbsp;Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value?.internet_price.toLocaleString("hi-IN");
                  }
               })}
            </li>
            <li
               style={{ display: "flex", marginBottom: "-15px", alignItems: "baseline" }}
               className="li_alignment"
            >
               {" "}
               <img src={tick} alt="tick" />
               <p>ONT Type: {packageData?.ont_type}</p>
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               Router Rental Charge: Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value.router.toLocaleString("hi-IN");
                  } else return null;
               })}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               Drop Wire Charge: Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value?.drop_wire.toLocaleString("hi-IN");
                  }
               })}
            </li>

            {/* <li>
               {" "}
               <img src={tick} alt="tick" />
               Internet Installation Charge: Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value?.installation_charge.toLocaleString("hi-IN");
                  }
               })}
            </li> */}

            {packageData?.combo_type !== "Fibernet" && (
               <>
                  <li>
                     {" "}
                     <img src={tick} alt="tick" />
                     Television Charge: Rs.{" "}
                     {Object.entries(packageData?.prices).map(([key, value]) => {
                        if (value.type === duration) {
                           return value?.itv_price.toLocaleString("hi-IN");
                        }
                     })}
                  </li>
                  {/* <li>
                     {" "}
                     <img src={tick} alt="tick" />
                     No. of Television:{" "}
                     {Object.entries(packageData.prices).map(([key, value]) => {
                        if (value.type === duration) {
                           return value?.no_of_itv;
                        }
                     })}
                  </li> */}
                  <li>
                     {" "}
                     <img src={tick} alt="tick" />
                     TV STB Charge: Rs.{" "}
                     {Object.entries(packageData.prices).map(([key, value]) => {
                        if (value.type === duration) {
                           return value?.itv_stb_charge.toLocaleString("hi-IN");
                        }
                     })}
                  </li>
                  {/* <li>
                     {" "}
                     <img src={tick} alt="tick" />
                     Additional TV Price: Rs.{" "}
                     {Object.entries(packageData.prices).map(([key, value]) => {
                        if (value.type === duration) {
                           return value?.additional_itv_price.toLocaleString("hi-IN");
                        }
                     })}
                  </li>
                  <li>
                     {" "}
                     <img src={tick} alt="tick" />
                     Additional TV STB Price: Rs.{" "}
                     {Object.entries(packageData.prices).map(([key, value]) => {
                        if (value.type === duration) {
                           return value?.additional_itv_stb_cost.toLocaleString("hi-IN");
                        }
                     })}
                  </li> */}
               </>
            )}
         </div>
         <div className="duration_wrapper py-3 ps-5 mx-5">
            {Object.entries(packageData.prices).map(([key, value]) => {
               return (
                  <div className="checkbox_wrapper">
                     <input
                        type="radio"
                        id={value?._id}
                        name="duration"
                        value={value.type}
                        checked={duration === value.type}
                        onClick={(e) => setDuration(e.target.value)}
                     />
                     <label style={{ cursor: "pointer" }} for={value?._id}>
                        {value.type}
                     </label>
                  </div>
               );
            })}{" "}
         </div>
         <button
            className="order_button my-3"
            onClick={() => navigate(`/internetpackage/order/${id}?type=${duration}`)}
         >
            Order Now ➔
         </button>
      </div>
   );
};

export default IndividualInternetPackageCard;
