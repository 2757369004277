import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Layout from "../include/layout";
import { Navigate, useLocation, useNavigate } from "react-router";
import parse from "html-react-parser";
import Highlighter from "react-highlight-words";
import "../../scss/components/searchresult.scss";

import ReactPaginate from "react-paginate";

export default function Searchresult() {
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   const parse = require("html-react-parser");
   let query = useQuery();
   let search = query.get("search");
   const [submitted, setSubmitted] = useState(search);
   const navigate = useNavigate();
   const [result, setResult] = useState("");
   const [pageCount, setpageCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);
   const [limit, setLimit] = useState("5");
   useEffect(() => {
      if (search) {
         setSubmitted(search);
      } else {
         setSubmitted("");
      }
   }, [search]);
   useEffect(() => {
      axios
         .get(
            `${process.env.REACT_APP_BASE_URL}v1/search?page=${currentPage}&limit=10&keyword=${submitted}`,
         )
         .then((res) => {
            setpageCount(res?.data?.paging?.pages);

            setResult(res.data.data);
         })

         .catch((err) => console.log(err));

      navigate(`/searchresult?search=${submitted}`);
   }, [submitted, pageCount, currentPage]);

   const handlePageClick = async (data) => {
      let currentPage = data.selected + 1;
      setCurrentPage(currentPage);
   };
   const pressEnter = (e) => {
      e.preventDefault();
   };
   return (
      <Layout>
         <h2 className="search-title">Search</h2>
         <div className=" container fixed-width">
            <form id="search-form" onSubmit={(e) => pressEnter(e)}>
               <input
                  id="search-form-input"
                  value={submitted}
                  placeholder="Enter keyword"
                  onChange={(e) => {
                     setSubmitted(e.target.value);
                  }}
               />
            </form>
            {result?.length === 0 ? (
               <p
                  style={{
                     textAlign: "center",
                     marginTop: "33px",
                     color: "gray",
                     fontSize: "22px",
                  }}
               >
                  No results found{" "}
               </p>
            ) : (
               <>
                  <>
                     <div style={{ paddingTop: "20px" }}>
                        {Object.entries(result).map(([key, value]) => {
                           return (
                              <div className="search-result-wrapper">
                                 <Title submitted={submitted} value={value} search={search} />
                                 <Highlighter
                                    id="search-para"
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[search ? search : submitted]}
                                    autoEscape={true}
                                    textToHighlight={
                                       value?.description
                                          ? value?.description
                                               ?.toString()
                                               ?.replace(/(<([^>]+)>)/gi, "")
                                          : value?.answer?.toString()?.replace(/(<([^>]+)>)/gi, "")
                                          ? value?.answer?.toString()?.replace(/(<([^>]+)>)/gi, "")
                                          : ""
                                    }
                                 />
                                 <div>
                                    {(() => {
                                       if (value.model === "internet") {
                                          return (
                                             <a
                                                id="search-link"
                                                href={`/${value.model}/${value._id}`}
                                             >{`${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`}</a>
                                          );
                                       }
                                       if (value.model === "internet/plans") {
                                          return (
                                             <a
                                                id="search-link"
                                                href={`/${value.model}?type=${value.title}`}
                                             >{`${process.env.REACT_APP_PUBLIC_URL}${value.model}?ttype=${value.title}`}</a>
                                          );
                                       }

                                       if (value.model === "store") {
                                          return (
                                             <a
                                                id="search-link"
                                                href={`/${value.model}/${value._id}`}
                                             >{`${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`}</a>
                                          );
                                       }
                                       if (value.model === "company/faqs") {
                                          return (
                                             <a
                                                id="search-link"
                                                href={`/${value.model}?faq=${value.category.title}`}
                                             >{`${process.env.REACT_APP_PUBLIC_URL}/${value.model}?faq=${value.category.title}`}</a>
                                          );
                                       }
                                       if (value.model === "tv") {
                                          if (!value?.type) {
                                             return (
                                                <a
                                                   id="search-link"
                                                   href={`${value.model}/${value._id}`}
                                                >{`${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`}</a>
                                             );
                                          }
                                       }
                                       if (value.model === "tv-category") {
                                          return (
                                             <a
                                                id="search-link"
                                                href={`${value.link}`}
                                             >{`${process.env.REACT_APP_PUBLIC_URL}${value.model}`}</a>
                                          );
                                       }
                                       if (value.model === "channel-types") {
                                          return <a id="search-link" href={`/tv`}>{`/tv`}</a>;
                                       }
                                    })()}
                                 </div>
                              </div>
                           );
                        })}
                     </div>
                     {pageCount > 1 && (
                        <ReactPaginate
                           previousLabel={"Previous"}
                           nextLabel={"Next"}
                           breakLabel={"..."}
                           pageCount={pageCount}
                           marginPagesDisplayed={2}
                           pageRangeDisplayed={3}
                           onPageChange={handlePageClick}
                           containerClassName={"pagination justify-content-center"}
                           pageClassName={"page-item"}
                           pageLinkClassName={"page-link"}
                           previousClassName={"page-item"}
                           previousLinkClassName={"page-link"}
                           nextClassName={"page-item"}
                           nextLinkClassName={"page-link"}
                           breakClassName={"page-item"}
                           breakLinkClassName={"page-link"}
                           activeClassName={"active"}
                        />
                     )}
                  </>
               </>
            )}
         </div>
      </Layout>
   );
}
export function Title({ submitted, value, search }) {
   return (
      <div>
         {(() => {
            if (value.model === "internet") {
               return (
                  <div
                     onClick={() =>
                        window.open(
                           `${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`,
                        )
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[search ? search : submitted]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "internet/plans") {
               return (
                  <div
                     onClick={() =>
                        window.open(
                           `${process.env.REACT_APP_PUBLIC_URL}${value.model}?type=${value.title}`,
                        )
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[search ? search : submitted]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "tv") {
               return (
                  <div
                     onClick={() =>
                        window.open(
                           `${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`,
                        )
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[search ? search : submitted]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "tv-category") {
               return (
                  <div
                     onClick={() => window.open(`${process.env.REACT_APP_PUBLIC_URL}${value.link}`)}
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[search ? search : submitted]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "channel-types") {
               return (
                  <div onClick={() => window.open(`${process.env.REACT_APP_PUBLIC_URL}tv`)}>
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[search ? search : submitted]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "store") {
               return (
                  <div
                     onClick={() =>
                        window.open(
                           `${process.env.REACT_APP_PUBLIC_URL}${value.model}/${value._id}`,
                        )
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[submitted ? submitted : ""]}
                        autoEscape={true}
                        textToHighlight={
                           value.title
                              ? value.title
                              : value.question
                              ? value.question
                              : value.name
                              ? value.name
                              : ""
                        }
                     />
                  </div>
               );
            }
            if (value.model === "company/faqs") {
               return (
                  <div
                     onClick={() =>
                        window.open(
                           `${process.env.REACT_APP_PUBLIC_URL}${value.model}?faq=${value.category.title}`,
                        )
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[submitted ? submitted : ""]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            } else {
               return (
                  <div
                     onClick={() =>
                        window.open(`${process.env.REACT_APP_PUBLIC_URL}${value.model}`)
                     }
                  >
                     <Highlighter
                        id="search-heading"
                        highlightClassName="YourHighlightClass"
                        searchWords={[submitted ? submitted : ""]}
                        autoEscape={true}
                        textToHighlight={
                           value.title ? value.title : value.question ? value.question : ""
                        }
                     />
                  </div>
               );
            }
         })()}
      </div>
   );
}
