import React, { useState, useEffect } from "react";
import IndividualPackageCard from "./individualpackagecard";
import "../../../scss/packages/individualpackageinfo.scss";
import Video from "../../../assets/package/tvorder.jpg";

import hd from "../../../assets/package/hd_icon.png";
import sd from "../../../assets/package/sd_icon.png";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";
import { get_individual_package } from "../../../data/api";
import ErrorPage from "../../../components/pages/errorPage";
import Loading from "../../../components/customcomponents/loading";

const IndividualPackageInfo = ({ id }) => {
   const [packageData, setPackageData] = useState("");
   const [empty, setEmpty] = useState(true);

   //fetching individual internet package data from id
   useEffect(() => {
      async function fetchData() {
         const response = await get_individual_package(id);
         setPackageData(response?.data?.data);
         // console.log("Package Data:", response.data.data);
      }
      fetchData();
   }, [empty, id]);

   useEffect(() => {
      if (packageData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [packageData]);

   return empty ? (
      <Loading />
   ) : !packageData ? (
      <ErrorPage />
   ) : (
      <Widthcontainer className={"individualpackageinfo_bg"}>
         <div className="individualpackageinfo_wrapper mb-5 py-5 fixed-width">
            <div className="individualpackageinfo_packagecard">
               <IndividualPackageCard id={id} />
            </div>
            <div className="individualpackageinfo_moreinfo_wrapper">
               {/* <p className="small_text">Packages Table</p> */}
               <h2 className="individualpackageinfo_title">Experience it with us.</h2>
               <img src={Video} alt="" style={{ width: "100%" }} />
               <div className="channel_info mt-5">
                  <div className="text">
                     <h1>
                        Now we are <span>available</span> with
                     </h1>
                  </div>
                  <div className="hd_channel">
                     <img src={hd} alt="" />
                     <div className="channel_text">
                        <h1>{packageData.channels.hd_count}</h1>
                        <p>Channels</p>
                     </div>
                  </div>
                  <div className="sd_channel">
                     <img src={sd} alt="" />
                     <div className="channel_text">
                        <h1>{packageData.channels.sd_count}</h1>
                        <p>Channels</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Widthcontainer>
   );
};

export default IndividualPackageInfo;
