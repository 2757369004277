import React from "react";
import "../../scss/components/500Error/styles.scss";
import "../../scss/components/500Error/responsive.scss";
import logo from "../../assets/logo.png";
import { BsFacebook } from "react-icons/bs";
import { FaTwitterSquare } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { TfiYoutube } from "react-icons/tfi";

function ServerError() {
   return (
      <div className="Error500">
         <div className="main-area">
            <div className="container full-height position-static">
               <section className="left-section full-height">
                  <a className="logo" href="#">
                     <img src={logo} alt="Logo" />
                  </a>

                  <div className="display-table">
                     <div className="display-table-cell">
                        <div className="main-content">
                           <h1 className="mb-3">
                              <b>Under Construction</b>
                           </h1>
                           <p>
                              Our website is currently undergoing scheduled maintenance. We Should
                              be back shortly. Thank you for your patience.
                           </p>
                        </div>
                     </div>
                  </div>

                  <ul className="footer-icons">
                     <li>Stay in touch : </li>
                     <li>
                        <a href="https://www.facebook.com/dishhome" target={"_blank"}>
                           <BsFacebook size={24} />
                        </a>
                     </li>
                     <li>
                        <a href="https://twitter.com/dishhome" target={"_blank"}>
                           <FaTwitterSquare size={24} />
                        </a>
                     </li>
                     <li>
                        <a href="https://www.instagram.com/dishhome.official/" target={"_blank"}>
                           <RiInstagramFill size={24} />
                        </a>
                     </li>
                     <li>
                        <a
                           href="https://www.youtube.com/channel/UCNKKyb9-Emqvp7efxoY1yjA"
                           target={"_blank"}
                        >
                           <TfiYoutube size={24} />
                        </a>
                     </li>
                  </ul>
               </section>

               <section
                  className="right-section"
                  style={{ backgroundImage: `url(${require("../../assets/refer.jpg")})` }}
               >
                  <div className="display-table center-text">
                     <div className="display-table-cell"></div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   );
}

export default ServerError;
