import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import DHGOActivePackage from "./DHGOActivePackage.js";
import DHGOHistory from "./DHGOHistory.js";
import { getDishhomeGoOrder } from "../data/protectedapi.js";
import "../scss/dashboard/dashbaordDishhomego.scss";

function DishHomeGoHistory() {
   const [empty, setEmpty] = useState(true);
   const [history, setHistory] = useState([]);
   const [addons, setAddons] = useState([]);
   const [activePackage, setActivePackage] = useState([]);
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let option = query.get("option");
   let navigate = useNavigate();

   useEffect(() => {
      async function get_ppv() {
         const response = await getDishhomeGoOrder();
         setHistory(response?.data?.history);
         setActivePackage(response?.data?.package);
         setAddons(response?.data?.addons);
      }
      get_ppv();
   }, [empty]);

   useEffect(() => {
      if (
         history?.length === 0 ||
         history === undefined ||
         addons?.length === 0 ||
         addons === undefined ||
         activePackage?.length === 0 ||
         activePackage === undefined
      ) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [history, addons, activePackage]);

   const [activeCategory, setActiveCategory] = useState(option ? option : "activePackage");
   return (
      <div className="dashboardOrderHistory__Wrapper">
         <h1 className="ms-2 header">DishHome Go</h1>
         <p className="ms-2 subheading">
            Find all of your Dishhome Go active package and order history in one place
         </p>

         <div className="ms-2 mt-3 mb-3 order_options">
            <div
               className={
                  activeCategory === "activePackage"
                     ? "order_options__item active"
                     : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=16&option=activePackage");
                  setActiveCategory("activePackage");
               }}
            >
               Active Packages
            </div>
            <div
               className={
                  activeCategory === "history"
                     ? "order_options__item active"
                     : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=16&option=history");
                  setActiveCategory("history");
               }}
            >
               Order History
            </div>
         </div>
         {activeCategory === "activePackage" ? (
            <DHGOActivePackage activePackage={activePackage} addons={addons} />
         ) : (
            <DHGOHistory data={history} />
         )}
      </div>
   );
}

export default DishHomeGoHistory;
