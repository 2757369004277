import React from "react";
import "../../scss/components/appcard.scss";
import card from "../../assets/card.webp";
// import recharge from "../../assets/recharge.png";
import recharge from "../../assets/recharge-border1.png";
import useToken from "../../utils/useToken";

import recharge2 from "../../assets/recharge-border2.png";
import recharge3 from "../../assets/recharge-border3.png";
import { useNavigate, Link } from "react-router-dom";
import Widthcontainer from "./widthcontainer";

import PackageCard from "./packagecard";
import LatestOffer from "./latestOffer";
const AppCard = ({ tvData, internetData }) => {
   let navigate = useNavigate();
   const { userDetails } = useToken();

   return (
      <div>
         <Widthcontainer>
            <div className="offer fixed-width">
               <div className="container">
                  <div className="app-card">
                     <div className="app-card-div" onClick={() => navigate("/recharge")}>
                        <RechargeCard
                           img={card}
                           title="Recharge"
                           className="recharge"
                           description="Now pay your bills online."
                        />
                     </div>
                     <div className="app-card-div" onClick={() => navigate("/company/support")}>
                        <RechargeCard
                           img={recharge}
                           title="Support"
                           className="recharge"
                           description="Get instant support from our representatives."
                        />{" "}
                     </div>
                     <div className="app-card-div" onClick={() => navigate("/internet/refer")}>
                        <RechargeCard
                           img={recharge2}
                           title="Refer"
                           className="recharge"
                           description="Refer your friend and get an amazing bonus."
                        />{" "}
                     </div>
                     <div className="app-card-div" onClick={() => navigate("/locate")}>
                        <RechargeCard
                           img={recharge3}
                           title="Locate Us"
                           className="recharge"
                           description="Find us in your location."
                        />{" "}
                     </div>
                  </div>
               </div>
            </div>
         </Widthcontainer>
         {/* Package details of home page Internet and TV */}
         <PackageCard tvData={tvData} internetData={internetData} />
         {/* <LatestOffer /> */}
      </div>
   );
};
export default AppCard;

export function RechargeCard(props) {
   return (
      <div className="offer_card">
         <div className="offer_img">
            <img src={props.img} alt="" style={{ marginTop: "14px" }}></img>
         </div>
         <div id="appcard_child">
            <span className="appcard_text">{props.title} </span>
            <br />
            <span className="appcard_des"> {props.description}</span>
         </div>
      </div>
   );
}
