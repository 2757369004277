import React, { useEffect, useState } from "react";
import cartIcon from "../../assets/ott/packageCart.webp";
import closeIcon from "../../assets/closeIcon.webp";
import Token from "../../utils/useToken";
import { MdDeleteForever } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import khalti from "../../assets/cart/khalti.png";
import esewa from "../../assets/store/esewa.webp";
import ips from "../../assets/store/ips.png";
import prabhu from "../../assets/store/prabhu.png";
import ime from "../../assets/store/ime.png";
import { handleOnlinePayment } from "../../components/pages/payment/payment";
import KhaltiCheckout from "khalti-checkout-web";
import { order_ott } from "../../data/protectedapi";
import MessageModal from "../../components/customcomponents/messageModal";
import moment from "moment-timezone";

function DishhomeGoCart({ setShow: setLoginShow, isCartOpen, setIsCartOpen, updateCart }) {
   const [data, setData] = useState(JSON.parse(localStorage?.getItem("goCart")));
   const [paymentModal, setPaymentModal] = useState(false);
   const [paymentMethod, setPaymentMethod] = useState("");
   const [success, setSuccess] = useState(false);
   const [balance, setBalance] = useState("");
   const [onlinePaymentShow, setOnlinePaymentShow] = useState(false);
   const [voucherPin, setVoucherPin] = useState("");
   const [error, setError] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [btnLoading, setBtnLoading] = useState(false);
   const [update, setUpdate] = useState(false);

   useEffect(() => {
      setData(JSON.parse(localStorage?.getItem("goCart")));
      if (userDetails && data && userDetails?.id != data?.user) localStorage.removeItem("goCart");
   }, [updateCart, isCartOpen, update]);
   const cart = data?.cart;

   const { userDetails } = Token();

   function deletePackage(deleteData) {
      const localData = JSON.parse(localStorage?.getItem("goCart"));
      const cartItem = localData?.cart;
      const filteredData = cartItem?.filter((d) => d?._id !== deleteData?._id);

      const newData = {
         user: localData?.user,
         cart: filteredData,
      };
      localStorage.setItem("goCart", JSON.stringify(newData));
      setUpdate(!update);
   }
   const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
   let total = 0;

   const onlinePaymentMethod = ["esewa", "khalti", "connectips", "imepay"];

   const handlePayment = async (order_id, total) => {
      if (
         // paymentMethod !== "khalti" &&
         paymentMethod !== ""
      ) {
         handleOnlinePayment({
            order_id: order_id,
            payment_method: paymentMethod,
            order_name: "DishHome Go",
            total: total,
            uid: userDetails?.id,
         });
      }
      //  else if (paymentMethod === "khalti") {
      //    const data = await handleOnlinePayment({
      //       order_id: order_id,
      //       payment_method: paymentMethod,
      //       order_name: "DishHome Go",
      //       uid: userDetails?.id,
      //       setSuccess: setSuccess,
      //    });
      //    const checkout = new KhaltiCheckout(data);
      //    checkout.show({ amount: total * 100 });
      // }
   };

   const handleOrder = async () => {
      setBtnLoading(true);
      const addons = [];
      cart?.length > 0 &&
         Object.entries(cart)?.map(([key, value]) => {
            addons.push({
               category: value?.type,
               assetId: value?.product_id,
               assetPricingId: value?.prices?.priceId,
               expiryDate: moment()
                  .add(value?.prices?.duration, value?.prices?.duration_type)
                  .format("YYYY-MM-DDTHH:mm:ss"),
            });
         });
      const response = await order_ott(paymentMethod, "", "", total, addons, "addon");
      setPaymentMethod("");
      if (response?.status === 200) {
         const data = response.data.data;
         localStorage?.removeItem("goCart");
         if (onlinePaymentMethod?.includes(paymentMethod)) {
            setBtnLoading(false);
            handlePayment(data?.order_id, data?.total);
            setPaymentModal(false);
         } else {
            setBtnLoading(false);
            setPaymentModal(false);
            setPaymentMethod("");
            setSuccess(true);
         }
      } else {
         setBtnLoading(false);
         setPaymentMethod("");
         setErrorMessage(response?.response?.data?.message);
         setPaymentModal(false);
         setError(true);
      }
   };

   return (
      <>
         <MessageModal
            show={success}
            setShow={setSuccess}
            message="Payment Successful"
            link={"/"}
            buttonText="Go Home"
            reload={true}
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading="Payment Failed"
            message={errorMessage}
            type={"error"}
            buttonText="OK"
         />
         {success && setPaymentModal(false)}
         <Modal show={paymentModal} onHide={() => setPaymentModal(false)} size={"lg"}>
            <Modal.Body>
               <div
                  className="order_summary d-flex justify-content-between px-4"
                  style={
                     isMobile
                        ? {
                             flexDirection: "column",
                             maxHeight: "520px",
                             minHeight: "auto",
                             overflowY: "auto",
                          }
                        : {}
                  }
               >
                  <div
                     className="order_summary_left mt-4 w-100"
                     style={
                        isMobile ? { overflowY: "auto" } : { overflowY: "auto", minHeight: "320px" }
                     }
                  >
                     <h4 className="mb-4">Order Summary</h4>
                     <div className="packageCartContainer">
                        {cart?.length > 0 &&
                           cart &&
                           Object.entries(cart)?.map(([key, value]) => {
                              total += value?.prices?.price;
                              return (
                                 <div className="d-flex package-data">
                                    <div className="d-flex ">
                                       <img
                                          src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                          alt=""
                                       />
                                       <div>
                                          <h6>{value?.name}</h6>
                                          <p className="mb-0">Type:{value?.type}</p>
                                       </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                       <h7>
                                          {" "}
                                          Rs. {value?.prices?.price}
                                          <sub>
                                             / {value?.prices?.duration}{" "}
                                             {value?.prices?.duration_type === "H"
                                                ? "hours"
                                                : value?.prices?.duration_type === "D"
                                                ? "days"
                                                : value?.prices?.duration_type === "M"
                                                ? "months"
                                                : "years"}
                                          </sub>
                                       </h7>
                                       <div className="delectContainer">
                                          <MdDeleteForever
                                             className="deleteIcon px-1 align-self-center"
                                             onClick={() => deletePackage(value)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}
                     </div>
                     <div className="d-flex justify-content-between fw-bold mt-2">
                        <p>Total:</p>
                        <p className="text-success">Rs.{total}</p>
                     </div>
                  </div>
                  <div
                     style={isMobile ? { padding: "20px" } : { padding: "20px", minWidth: "420px" }}
                     className="myorder_payment"
                  >
                     <p className="payment_method-title">Payment Methods</p>
                     <div className="payment_methods">
                        {
                           <div
                              className="payment_options"
                              style={paymentMethod === "imepay" ? { border: "2px solid red" } : {}}
                              onClick={() => setPaymentMethod("imepay")}
                           >
                              <img src={ime} alt="" />
                           </div>
                        }
                        {
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "esewa"
                                    ? { border: "2px solid red" }
                                    : { border: "2px solid #E6EAF0" }
                              }
                              onClick={() => setPaymentMethod("esewa")}
                           >
                              <img src={esewa} alt="" />
                           </div>
                        }
                        {
                           <div
                              className="payment_options"
                              style={paymentMethod === "khalti" ? { border: "2px solid red" } : {}}
                              onClick={() => setPaymentMethod("khalti")}
                           >
                              <img src={khalti} alt="" />
                           </div>
                        }
                        {
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "connectips" ? { border: "2px solid red" } : {}
                              }
                              onClick={() => setPaymentMethod("connectips")}
                           >
                              <img src={ips} alt="" />
                           </div>
                        }
                        {
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "prabhupay" ? { border: "2px solid red" } : {}
                              }
                              onClick={() => setPaymentMethod("prabhupay")}
                           >
                              <img src={prabhu} alt="" />
                           </div>
                        }
                     </div>
                     <button
                        className="btn btn-danger my-3"
                        style={{ width: "100%", borderRadius: "18px" }}
                        disabled={
                           (paymentMethod === "" || paymentMethod === "online" ? true : false) ||
                           btnLoading
                              ? true
                              : false
                        }
                        onClick={() => handleOrder()}
                     >
                        Pay Now
                     </button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         <div className="packageCartDishhomeGo__container">
            <div className={`packageCartDishhomeGo p-4 ${isCartOpen ? "" : "display-none"}`}>
               <div className="packageCartContainer">
                  <h4>Package Cart</h4>
                  <div
                     className="d-flex flex-column"
                     style={{
                        maxHeight: "285px",
                        overflowY: "scroll",
                        paddingRight: "6px",
                     }}
                  >
                     {cart?.length > 0 ? (
                        cart &&
                        Object.entries(cart)?.map(([key, value]) => {
                           return (
                              <div className="d-flex package-data">
                                 <div className="d-flex ">
                                    <img
                                       src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                       alt=""
                                    />
                                    <div>
                                       <h6>{value?.name}</h6>
                                       <p className="mb-0">Type:{value?.type}</p>
                                    </div>
                                 </div>
                                 <div className="d-flex align-items-center">
                                    <h7>
                                       Rs. {value?.prices?.price}
                                       <sub>
                                          / {value?.prices?.duration}{" "}
                                          {value?.prices?.duration_type === "H"
                                             ? "hours"
                                             : value?.prices?.duration_type === "D"
                                             ? "days"
                                             : value?.prices?.duration_type === "M"
                                             ? "months"
                                             : "years"}
                                       </sub>
                                    </h7>
                                    <div className="delectContainer">
                                       <MdDeleteForever
                                          className="deleteIcon px-1 align-self-center"
                                          onClick={() => deletePackage(value)}
                                       />
                                    </div>
                                 </div>
                              </div>
                           );
                        })
                     ) : (
                        <p className="mt-3 text-danger">No Items in the cart</p>
                     )}
                  </div>
                  {cart?.length > 0 && (
                     <a
                        className="btn btn-danger mt-2"
                        as="button"
                        onClick={() => {
                           cart?.length > 0 && setPaymentModal(true);
                        }}
                     >
                        Buy Now
                     </a>
                  )}
               </div>
            </div>

            <div
               className="DishhomegoPackageCart__icon"
               onClick={() => {
                  !userDetails ? setLoginShow(true) : setIsCartOpen(!isCartOpen);
               }}
            >
               <img
                  src={isCartOpen ? closeIcon : cartIcon}
                  alt="cart icon"
                  className={!isCartOpen ? "cartIcon" : "closeIcon"}
               />
               {!isCartOpen && cart?.length > 0 && userDetails && (
                  <p className="cart-number">{cart?.length}</p>
               )}
            </div>
         </div>
      </>
   );
}
export default DishhomeGoCart;
