import React, { useState, useEffect } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import FormControl from "react-bootstrap/FormControl";
import "../../scss/components/locateus.scss";
import { InputGroup } from "react-bootstrap";
import search from "../../assets/search.png";
import { get_agent_type, locate_dealer } from "../../data/api";
// import { locate_dealer } from "../../data/protectedapi";
import ReactPaginate from "react-paginate";
import Dropdown from "react-dropdown";
import { provinces, districts } from "../../data/location/province-district";
import { useMediaQuery } from "react-responsive";
import SeoTags from "../../utils/seoTags";

export default function LocateUs() {
   const [name, setName] = useState("");
   const [address, setAddress] = useState("");
   const [type, setType] = useState("");
   const [pageCount, setPageCount] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
   const [agentdata, setAgentData] = useState("");
   const [agenttype, setAgentType] = useState("");
   const [district, setDistrict] = useState("");

   const [cartupdate, setCartupdate] = useState(false);

   const [province, setProvince] = useState("");

   useEffect(() => {
      async function get_agent() {
         const res = await locate_dealer(name, address, type, currentPage, province, district);
         const response = res.data.data;
         const pages = res.data?.paging?.pages;
         const agentresponse = await get_agent_type();
         setAgentType(agentresponse?.data?.data);
         setAgentData(response);
         setPageCount(pages);
      }

      get_agent();
   }, [name, address, type, currentPage, province, district]);
   useEffect(() => {
      if (name || address || type) {
         setCurrentPage("1");
      }
   }, [name, address, type]);
   const handlePageClick = async (e) => {
      setCurrentPage(e.selected + 1);
   };

   let agentType = [];
   let provinceData = [];

   let districtData = [];
   let defaultOption = agentType[0]?.label;

   const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

   const isVisible = useMediaQuery({ query: "(min-width:992px)" });

   return (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         <Widthcontainer>
            {SeoTags("Locate us | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}{" "}
            <div className="fixed-width ps-4 locateus_wrapper" style={{ marginTop: "40px" }}>
               {" "}
               <p className="locate-dealer">Dealers List</p>
               <p className="locate-nearest">Find your nearest DishHome Dealer Dai</p>
               <div className="filter-section-locate district_province">
                  <div className="dropdown">
                     <p className="dropdown_name">Province</p>

                     {Object.entries(provinces).map(([key, value]) => {
                        if (provinceData?.length === 0) {
                           provinceData?.push({ value: "", label: "Select..." });
                        }
                        provinceData.push({
                           value: value.name,
                           label: value.name,
                        });
                        return null;
                     })}

                     {provinceData && (
                        <Dropdown
                           value={provinceData[0]?.label}
                           onChange={(option) => {
                              setProvince(option.value);
                              districtData = [];
                              setDistrict("");
                           }}
                           options={provinceData}
                        />
                     )}
                  </div>
                  <div className="dropdown">
                     <p className="dropdown_name">District</p>
                     {Object.entries(districts).map(([key, value]) => {
                        province === value?.province &&
                           districtData.push({
                              value: value.id,
                              label: value.name,
                           });
                        return null;
                     })}
                     <Dropdown
                        value={district}
                        onChange={(option) => setDistrict(option.label)}
                        options={districtData}
                     />
                  </div>
               </div>
               <div className="filter-section-locate">
                  <div>
                     <p id="locate-title">Name/Phone</p>
                     <InputGroup className="mb-3">
                        <InputGroup.Text id="search-locate-icon">
                           <img src={search} alt="search icon" />
                        </InputGroup.Text>
                        <FormControl
                           id="search-locate"
                           aria-label="Small"
                           placeholder={isMobile ? "Enter..." : "Filter by Name/Phone"}
                           aria-describedby="inputGroup-sizing-sm"
                           onChange={(e) => setName(e.target.value)}
                        />
                     </InputGroup>
                  </div>
                  <div>
                     <p id="locate-title">Address</p>
                     <InputGroup>
                        <InputGroup.Text id="search-locate-icon">
                           <img src={search} alt="search icon" />
                        </InputGroup.Text>

                        <FormControl
                           type="search"
                           placeholder={isMobile ? "Enter.." : "Filter by Address"}
                           aria-describedby="inputGroup-sizing-sm"
                           aria-label="Search"
                           id="search-locate"
                           onChange={(e) => setAddress(e.target.value)}
                        />
                     </InputGroup>
                  </div>
                  <div>
                     <p id="locate-title">Agent Type</p>
                     {Object.entries(agenttype).map(([key, value]) => {
                        agentType.push({
                           value: value._id,
                           label: value.name,
                        });
                        return null;
                     })}

                     <Dropdown
                        value={defaultOption}
                        onChange={(option) => setType(option.value)}
                        options={agentType}
                     />
                  </div>
               </div>
               {isVisible && (
                  <div className="dealer-address mb-0 mt-3 ">
                     <div>
                        <p id="dealer-address-title">Name</p>
                     </div>
                     <div>
                        <p id="dealer-address-title">Address</p>
                     </div>

                     <div>
                        <p id="dealer-address-title">Agent Type</p>
                     </div>

                     <div>
                        <p id="dealer-address-title">Email Address</p>
                     </div>
                  </div>
               )}
               <LocateDealer agentdata={agentdata} />
               {pageCount > 1 && (
                  <ReactPaginate
                     previousLabel={"Previous"}
                     nextLabel={"Next"}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={3}
                     onPageChange={handlePageClick}
                     containerClassName={"pagination dashboard_pagination"}
                     pageClassName={"page-item dashboard_page_item"}
                     pageLinkClassName={"page-link dashboard_page_link"}
                     previousClassName={"page-item"}
                     previousLinkClassName={"page-link"}
                     nextClassName={"page-item"}
                     nextLinkClassName={"page-link"}
                     breakClassName={"page-item"}
                     breakLinkClassName={"page-link"}
                     activeClassName={"active dashboard_active"}
                  />
               )}
               {agentdata.length === 0 && <p className="nodatafound">No data found</p>}
            </div>
         </Widthcontainer>
      </Layout>
   );
}
export function LocateDealer({ agentdata }) {
   const isVisible = useMediaQuery({ query: "(min-width:992px)" });

   return (
      <div className={"dealer-wrapper mt-0"}>
         {Object.entries(agentdata).map(([key, value]) => {
            return (
               <div className="dealer-address ">
                  <div>
                     <p id="dealer-address-title">{value.name}</p>
                     <p id="dealer-phone">
                        {!isVisible ? "Number: " : null}
                        {value.phone}
                     </p>
                  </div>
                  <div>
                     <p id="dealer-address-p">
                        {!isVisible ? "Address: " : null}

                        {value.address}
                     </p>
                  </div>

                  <div>
                     <p id="dealer-address-p">
                        {!isVisible ? "Agent Type: " : null}

                        {value.agent_type.name}
                     </p>
                  </div>

                  <div>
                     <p id="dealer-address-p">
                        {!isVisible ? "Email Address: " : null}

                        {value.email ? value.email : "N/A"}
                     </p>
                  </div>
               </div>
            );
         })}
      </div>
   );
}
