import React from "react";
import "../../scss/components/packagecard.scss";
import tick from "../../assets/tick.svg";
import { useNavigate } from "react-router";

const IndvPackage = (props) => {
   let navigate = useNavigate();
   return (
      <div className="HD_package ">
         <div className="rectangle_image">
            <img
               style={{ marginTop: "30px" }}
               src={props.img}
               alt="package"
               height={60}
               width={60}
            ></img>
         </div>
         <p className="package_name">{props.package_name}</p>
         <p className="package_des">
            {/* Power up your business with out <br></br> invoice{" "} */}
            {props.description}
         </p>
         <p className="package_cost">
            <sup style={{ fontSize: "12px" }}>Rs. </sup>{" "}
            <span className="cost">{props?.price?.toLocaleString("hi-IN")}*</span>
            <sub>/{props.duration}</sub>
         </p>
         <div className="channels_list">
            <li>
               {" "}
               <img src={tick} alt="tick" />
               {props.feature0}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               {props.feature1}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               {props.feature2}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               {props.feature3}
            </li>
         </div>
         <div className="package_link">
            {props.type === "tv_page" ? (
               <a
                  style={{ cursor: "pointer" }}
                  className={props.keys === "1" ? "middle_button" : "_link"}
                  onClick={() => navigate(props.link)}
               >
                  {" "}
                  Get started →
               </a>
            ) : (
               <a className="link" href={props.link}>
                  {" "}
                  View Package
               </a>
            )}
         </div>
      </div>
   );
};

export default IndvPackage;
