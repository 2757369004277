import { React, useState, useEffect } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import Img from "../../assets/videotutorial_top.png";
import "../../scss/pages/videoTutorial.scss";
import video from "../../assets/tutorial_video.png";
import { get_tutorial } from "../../data/api";
import Loading from "../customcomponents/loading";
import ReactPaginate from "react-paginate";
import { InputGroup } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
const VideoTutorial = () => {
   const [videoData, setVideoData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [pageCount, setPageCount] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
   const [keyword, setKeyword] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [allItems, setallItem] = useState(0);

   useEffect(() => {
      async function fetchData() {
         const response = await get_tutorial(currentPage, keyword);
         setVideoData(response.data.data);
         setPageCount(response?.data?.paging?.pages);
         setallItem(response?.data?.paging?.totalItems);
      }
      fetchData();
   }, [empty, pageCount, currentPage, keyword]);

   useEffect(() => {
      if (videoData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   const handlePageClick = async (e) => {
      setCurrentPage(e.selected + 1);
      window.scrollTo(0, 200);
   };

   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         <Widthcontainer className={"tutorial_width_container"}>
            <div className="tutorial_banner fixed-width">
               <div className="tutorial_banner_text">
                  <p className="tutorial">Video Tutorial </p>
                  <h1 className="tutorial_header mb-3">
                     Get Your Needed Video <span>Tutorials</span>
                  </h1>
                  <p className="tutorial_sub_header">
                     Learn everything about DishHome with our video tutorials and make your DishHome
                     experience amazing
                  </p>
               </div>
               <div className="tutorial_banner_img">
                  <img src={Img} alt="top" />
               </div>
            </div>
         </Widthcontainer>
         <Widthcontainer>
            <div className="tutorial_container fixed-width">
               <div className="tutorial_container_title_container mb-5">
                  <div className="titleContainer">
                     <h2 className="title">Recent Videos</h2>
                     <p className="sub_caption">Showing latest videos posted by DishHome team</p>
                  </div>
                  {(allItems > 1 || keyword?.length !== 0) && (
                     <div className="searchContainer">
                        <InputGroup>
                           <InputGroup.Text id="search-icon">
                              <BiSearchAlt />
                           </InputGroup.Text>

                           <input
                              type="text"
                              className="searchInput"
                              placeholder="Search Video"
                              onChange={(e) => setKeyword(e.target.value)}
                           />
                        </InputGroup>
                     </div>
                  )}
               </div>
               <div className="tutorial_video_container mb-4">
                  {allItems !== 0 ? (
                     Object.entries(videoData).map(([key, value]) => {
                        return (
                           <div className="video_card">
                              <div
                                 className="video_ mb-3 cursor_pointer"
                                 onClick={() => {
                                    window.open(value.videoUrl);
                                 }}
                              >
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.thumbnail}`}
                                    height="150px"
                                    style={{ objectFit: "contaain", maxWidth: "290px" }}
                                    alt="video"
                                 />
                              </div>
                              <div className="title mb-1">{value.title}</div>
                              <div className="description">{value.description}</div>
                           </div>
                        );
                     })
                  ) : (
                     <p className="d-flex align-items-center justify-content-center w-100 fs-4 ">
                        No Video Available!
                     </p>
                  )}
               </div>
               {pageCount > 1 && (
                  <ReactPaginate
                     previousLabel={"Previous"}
                     nextLabel={"Next"}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={3}
                     onPageChange={handlePageClick}
                     containerClassName={"pagination dashboard_pagination"}
                     pageClassName={"page-item dashboard_page_item"}
                     pageLinkClassName={"page-link dashboard_page_link"}
                     previousClassName={"page-item"}
                     previousLinkClassName={"page-link"}
                     nextClassName={"page-item"}
                     nextLinkClassName={"page-link"}
                     breakClassName={"page-item"}
                     breakLinkClassName={"page-link"}
                     activeClassName={"active dashboard_active"}
                  />
               )}
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export default VideoTutorial;
