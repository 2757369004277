import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Video from "../../../assets/individualinternet.jpg";
import hd from "../../../assets/package/hd_icon.png";
import { Accordion } from "react-bootstrap";
import coverDhitv from "../../../assets/offer/cover_dhitv.png";
import covercoverDth from "../../../assets/offer/cover_DTH.png";
import coverFiberNet from "../../../assets/offer/cover_fibernet.png";
import sd from "../../../assets/package/sd_icon.png";
import PathaoImg from "../../../assets/pathaoFest/images/DHGOpathao.png";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";
import Layout from "../../../components/include/layout";
import IndividualInternetPackageCard from "./individualInternetPackagecard";
import { get_internet_components, get_why_dishhome, get_single_internet } from "../../../data/api";
import Error from "../../../components/pages/404error";
import Faq from "../../../components/customcomponents/faq";
import Loading from "../../../components/customcomponents/loading";

export default function IndividualInternetpakcage() {
   const parameter = useParams();
   const [internetData, setinternetData] = useState("");
   const [faqData, setFaqData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [packageData, setPackageData] = useState("");

   const { id } = parameter;

   //get internet components
   useEffect(() => {
      async function fetchData() {
         const response = await get_internet_components();
         setinternetData(response?.data);
      }
      fetchData();
   }, [empty]);

   //get indvidual internet data from id
   useEffect(() => {
      async function fetchData() {
         const response = await get_single_internet(id);
         setPackageData(response?.data?.data);
      }
      fetchData();
   }, [empty, id]);

   //faq section
   useEffect(() => {
      setFaqData(internetData?.faq);
      if (internetData?.banners === undefined || packageData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [packageData, internetData]);

   return empty ? (
      <Loading />
   ) : !packageData ? (
      <Error />
   ) : (
      <div>
         <Layout>
            <Widthcontainer className={"individualpackageinfo_bg"}>
               <div className="individualpackageinfo_wrapper mb-5 py-5 fixed-width">
                  <div className="individualpackageinfo_packagecard">
                     <IndividualInternetPackageCard id={id} />
                  </div>
                  <div className="individualpackageinfo_moreinfo_wrapper">
                     <h2 className="individualpackageinfo_title">Experience it with us.</h2>
                     <img
                        style={{ width: "100%" }}
                        src={
                           packageData?.dashain_offer === true
                              ? PathaoImg
                              : // ? packageData?.combo_type === "Fibernet"
                                //    ? coverFiberNet
                                //    : packageData?.combo_type === "DTH Fibernet Combo"
                                //    ? covercoverDth
                                //    : packageData?.combo_type === "iTV Fibernet Combo"
                                //    ? coverDhitv
                                //    : Video
                                Video
                        }
                        alt=""
                     />
                     <div className="channel_info mt-5"></div>
                  </div>
               </div>
            </Widthcontainer>
            <BlockSection page="Internet" />
            <Faq data={faqData} type="internet" />
         </Layout>
      </div>
   );
}

//why dishhome section

export function BlockSection({ page }) {
   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await get_why_dishhome();
         const response = res.data.data;
         setBannerData(response);
      }
      get_navigation();
   }, []);
   // console.log("banner", bannerdata);
   return (
      <div className="infobanner_wrapper my-5">
         {Object.entries(bannerdata).map(([key, value]) => {
            return (
               <>
                  {value.page === page ? (
                     <>
                        <div className="text my-2">
                           <h2 className="title">{value.title}</h2>
                        </div>
                        <div className="info_card_wrapper mb-5 fixed-width">
                           {Object.entries(value.whyDishhome).map(([key, item]) => {
                              return (
                                 <InfoCard
                                    color={
                                       key == 0
                                          ? "red"
                                          : key == 1
                                          ? "green"
                                          : key == 2
                                          ? "yellow"
                                          : "blue"
                                    }
                                    src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                                    title={item.title}
                                    description={item.description}
                                 />
                              );
                           })}
                        </div>
                     </>
                  ) : (
                     ""
                  )}
               </>
            );
         })}
      </div>
   );
}

export function InfoCard({ color, src, title, description }) {
   return (
      <div className={"infocard_wrapper px-2 py-4 my-4 " + color}>
         <div className="img">
            <img src={src} alt="" />
         </div>
         <h4 className="title">{title}</h4>
         <p className="description">{description}</p>
      </div>
   );
}
