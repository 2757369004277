import React, { useEffect, useState } from "react";
import Navigation from "./navbar";
import Footer from "./footer";
import { get_company_details } from "../../data/api";

import { io } from "socket.io-client";
import Token from "../../utils/useToken";
import { get_initial_notifiaciton } from "../../data/protectedapi";
import MessageModal from "../customcomponents/messageModal";
import ReactGA from "react-ga4";
import CookieConsent, {
   Cookies,
   getCookieConsentValue,
   resetCookieConsentValue,
} from "react-cookie-consent";
import CookieConsentModal from "./cookieConsent";

const Layout = ({
   children,
   footer,
   cartupdate,
   setCartupdate,
   wishState,
   setWishState,
   setContactdetails,
}) => {
   //get company details
   // const [isEmpty, setisEmpty] = useState(true);
   const { userDetails, expired, setExpired, CheckUser, setCheckUser, isUser } = Token();
   const [socket, setSocket] = useState("");
   const [notification, setNotification] = useState([]);
   const [notificationRead, setNotificationRead] = useState(true);
   const [career, setCareer] = useState(0);
   // console.log("expired", expired);
   //cookie consent Model
   const [showModal, setShowModal] = useState(false);
   const [cookieConsent, setCookieConsent] = useState("show");

   //Cookie Switch
   const [googleAnalyst, setGoogleAnalyst] = useState(true);

   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         if (setContactdetails) setContactdetails(response.data.data);
         setCareer(response?.data?.career);
      }
      getCompanyDetails();
   }, []);

   useEffect(() => {
      async function getInitialNotification() {
         const response = await get_initial_notifiaciton();
         setNotification(response?.data?.data);
      }
      userDetails && getInitialNotification();
   }, [notificationRead, setNotificationRead]);

   //socket io
   useEffect(() => {
      if (userDetails) {
         const newSocket = io(process.env.REACT_APP_SOCKET_URL);
         setSocket(newSocket);
         notification &&
            Object.entries(notification)
               ?.reverse()
               .map(([key, value]) => {
                  if (value.read === false) {
                     setNotificationRead(false);
                  } else {
                     setNotificationRead(true);
                  }
                  return null;
               });
         return () => newSocket.close();
      }
   }, [setSocket, notification, setNotificationRead, userDetails, notificationRead]);

   useEffect(() => {
      userDetails && isUser();
      if (!userDetails) localStorage.removeItem("goCart");
   }, [CheckUser, userDetails]);

   //GA initaialize

   //Cookies consent
   const handleAcceptCookie = () => {
      Cookies.set("CookieConsent", "true", { expires: 365 });
      if (process.env.REACT_APP_GA_TRACKING_CODE && googleAnalyst) {
         ReactGA.initialize("G-P3G460B5MQ");
      }
      setCookieConsent("hidden");
      setShowModal(false);
   };

   const handleDeclineCookie = () => {
      //remove google analytics cookies
      // Cookies.remove("_ga");
      // Cookies.remove("_gat");
      // Cookies.remove("_gid");
      setShowModal(true);
      resetCookieConsentValue();
   };

   useEffect(() => {
      const isConsent = getCookieConsentValue();
      if (isConsent === "true") {
         handleAcceptCookie();
      }
   }, []);

   // useEffect(() => {
   //    ReactGA?.send({ hittype: "pageview", page: window.location.pathname });
   // }, [window.location.pathname]);

   return (
      <>
         {" "}
         <Navigation
            cartupdate={cartupdate}
            wishState={wishState}
            notification={notification}
            setNotification={setNotification}
            socket={socket}
            notificationRead={notificationRead}
            setNotificationRead={setNotificationRead}
         />
         <main className="main_page" style={{scrollSnapAlign: "none start"}}>
            <MessageModal
               type="error"
               show={expired}
               setShow={setExpired}
               message="Session Expired"
               buttonText="Login"
               button2={"Continue"}
               link2=""
               link="/login"
            />
            <MessageModal
               type="error"
               show={CheckUser}
               setShow={setCheckUser}
               message="Something went wrong"
               buttonText="Login"
               link="/login"
               reload={true}
            />

            {children}
         </main>
         <CookieConsent
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            buttonText="Accept"
            declineButtonText="Settings"
            visible={cookieConsent}
            buttonStyle={{
               background: "#ed1c24",
               color: "#FFF",
               fontSize: "16px",
               borderRadius: "5px",
               padding: "10px 20px",
               margin: "0",
               marginRight: "35px",
            }}
            declineButtonStyle={{
               background: "black",
               color: "#FFF ",
               fontSize: "16px",
               borderRadius: "5px",
               padding: "10px 20px",
               marginRight: "10px",
            }}
            style={{
               boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
               backgroundColor: "rgba(255,255,255,0.8)",
               color: "#2B373B",
               fontSize: "18px",
               display: "flex",
               flexDirection: "column",
               padding: "20px 35px",
               alignItems: "center",
            }}
            contentStyle={{
               flex: "1",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               minWidth: "250px",
               textAlign: "center",
               marginBottom: "0",
            }}
            buttonWrapperStyle={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}
         >
            This website uses cookies to ensure you get the best experience on our website. By using
            our website, you agree to our cookie policy.{" "}
         </CookieConsent>
         <CookieConsentModal
            handleAccept={handleAcceptCookie}
            show={showModal}
            setShow={setShowModal}
            googleAnalyst={googleAnalyst}
            setGoogleAnalyst={setGoogleAnalyst}
         />
         <Footer
            cartupdate={cartupdate}
            setCartupdate={setCartupdate}
            appBanner={footer}
            career={career}
         />
      </>
   );
};

export default Layout;
