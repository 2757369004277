import React, { useState, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";
import success from "../../assets/question.jpg";
import tick from "../../assets/success.png";

import {
   delete_wishlist_items,
   get_wishlist_items,
   move_all_wishlist_items,
   update_wishlist_cart,
} from "../../data/protectedapi";
import Token from "../../utils/useToken";
import "../../scss/pages/wishlist.scss";
import axios from "axios";

import { FaCartPlus } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router";
import MessageModal from "../../components/customcomponents/messageModal";
import { setDate } from "date-fns";

const Wishlist = ({ wishStatus, setWishStatus }) => {
   const { token } = Token();
   const accessToken = token.accessToken;

   //state declaration
   const [show, setShow] = useState(false);

   const [wishlist, setWishlist] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [wishlistupdate, setWishlistupdate] = useState(false);
   const [error, setError] = useState(false);
   const [deleteId, setDeleteid] = useState("");
   const [addId, setAddid] = useState("");
   const [addSingleProduct, setAddSingleProduct] = useState(false);
   const handleShow = () => setShow(true);

   //api call and functions
   useEffect(() => {
      async function get_wishlist() {
         const response = await get_wishlist_items();

         setWishlist(response?.data?.data?.wishlist);
      }
      get_wishlist();
   }, [wishlistupdate, accessToken]);
   const handleDelete = async (id) => {
      const response = await delete_wishlist_items(id);
      setMessageModal(false);
      setWishlistupdate(!wishlistupdate);
      if (setWishStatus) setWishStatus(!wishStatus);
   };
   //move all to cart
   const moveAllCart = () => {
      Object.entries(wishlist).map(([key, value]) => {
         if (value?.productId?.stock_quantity <= 0) {
            setError(true);
         } else {
            setShow(true);
         }
      });
   };
   const handleWishlistCart = async (id) => {
      const response = await update_wishlist_cart(id);
      if (response?.status === 200) {
         setWishlistupdate(!wishlistupdate);
         handleShow();
         setAddSingleProduct(false);
         setWishStatus(!wishStatus);
      } else {
         setError(true);
      }
   };
   const navigate = useNavigate();
   //move all to cart
   const moveProducts = async () => {
      const response = await move_all_wishlist_items();
      if (response?.status === 200) {
         setShow(true);
         setWishlistupdate(!wishlistupdate);
      }
   };

   const CartdeleteModalopen = (id) => {
      setDeleteid(id);
      setMessageModal(true);
   };
   const wishListModal = (id) => {
      setAddid(id);
      setAddSingleProduct(true);
   };

   return (
      <div>
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Failed"}
            message="Out of stock"
            buttonText={"Ok"}
            type={"error"}
         />
         {/* {delete modal} */}
         <Modal
            show={messageModal}
            onHide={() => setMessageModal(false)}
            dialogClassName={"MessageModal"}
            centered
         >
            <Modal.Body>
               <div className="message_modal_wrapper">
                  <img src={success} alt="" height={80} width={80} />
                  <p className="add-to-cart-header"> Are you sure ?</p>
                  <div id="modal-addcart-footer">
                     <Button
                        variant="secondary"
                        id="addtocart-cart"
                        className="red"
                        onClick={() => setMessageModal(false)}
                     >
                        Cancel
                     </Button>
                     <Button
                        variant="secondary"
                        id="continue-shopping"
                        className="white"
                        onClick={() => {
                           handleDelete(deleteId);
                           window.location.reload();
                        }}
                     >
                        Remove
                     </Button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {/* {} */}

         <Modal
            show={show}
            onHide={() => {
               setShow(false);
               window.location.reload();
            }}
            dialogClassName={"MessageModal"}
            centered
         >
            <Modal.Body>
               <div className="message_modal_wrapper">
                  <img src={tick} alt="" height={80} width={80} />
                  <p className="add-to-cart-header"> Product succesfully added to cart</p>
                  <div id="modal-addcart-footer">
                     <Button
                        variant="secondary"
                        id="addtocart-cart"
                        className="red"
                        onClick={() => navigate("/cart")}
                     >
                        Proceed to checkout
                     </Button>
                     <Button
                        variant="primary"
                        className="white"
                        id="continue-shopping"
                        onClick={() => navigate("/store")}
                     >
                        Continue shopping
                     </Button>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         <div className="container">
            <h1 className="wishlist-title">Wishlist</h1>
            {Object.entries(wishlist).map(([key, value]) => {
               return (
                  <div className="wishlist-wrapper">
                     <div className="wishlist-pic-wrapper">
                        <div>
                           <img
                              src={`${process.env.REACT_APP_FILE_URL}/${value.productId.featured_image}`}
                              width="95px"
                              height="100px"
                              alt="Product"
                           />
                        </div>
                        <div>
                           <p id="wishlist-product-title" className="mt-2 mb-0">
                              {" "}
                              {value.productId.name}
                           </p>
                        </div>
                     </div>
                     <div id="wishlist-product-name">
                        <p> Rs. {value.productId.price.toLocaleString("hi-IN")}</p>
                     </div>
                     <div id="wishlist-button">
                        <Button
                           variant="secondary"
                           className="red"
                           id="wishlist-add"
                           onClick={() => wishListModal(value.productId._id)}
                        >
                           <FaCartPlus size={20} />{" "}
                        </Button>
                        <Button
                           variant="secondary"
                           className="grey"
                           id="wishlist-delete"
                           onClick={() => CartdeleteModalopen(value.productId._id)}
                        >
                           <AiFillDelete size={20} />{" "}
                        </Button>
                     </div>
                  </div>
               );
            })}
         </div>
         {wishlist.length > 0 ? (
            <>
               <Button
                  variant="secondary"
                  className="red"
                  style={{
                     marginBottom: "10px",
                     marginLeft: "10px",
                     marginTop: "15px",
                     backgroundColor: "#FF0000",
                     border: "none",
                  }}
                  onClick={() => moveProducts()}
               >
                  Move all to cart
               </Button>
               <Modal
                  show={addSingleProduct}
                  onHide={() => setAddSingleProduct(false)}
                  dialogClassName={"MessageModal"}
                  centered
               >
                  <Modal.Body>
                     <div className="message_modal_wrapper">
                        <img src={success} alt="" height={80} width={80} />
                        <p className="add-to-cart-header"> Do yo wish to move items?</p>
                        <div id="modal-addcart-footer">
                           <Button
                              className="red"
                              variant="secondary"
                              id="addtocart-cart"
                              onClick={() => handleWishlistCart(addId)}
                           >
                              Yes
                           </Button>
                           <Button
                              variant="primary"
                              id="continue-shopping"
                              onClick={() => setAddSingleProduct(false)}
                           >
                              Cancel
                           </Button>
                        </div>
                     </div>
                  </Modal.Body>
               </Modal>
            </>
         ) : (
            <div style={{ position: "relative" }}>
               <p
                  style={{
                     textAlign: "center",
                     fontSize: "24px",
                     color: "grey",
                     marginTop: "22px",
                     marginBottom: "22px",
                  }}
               >
                  No items in Wishlist
               </p>
               <div className="d-flex justify-content-center mt-2">
                  <Button
                     onClick={() => {
                        navigate("/store");
                     }}
                     className="red"
                     style={{
                        backgroundColor: "red",
                        border: "none",
                     }}
                  >
                     Continue Shopping
                  </Button>
               </div>
            </div>
         )}
      </div>
   );
};
export default Wishlist;
