import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeroBanner from "../../../components/customcomponents/herobanner";
import Layout from "../../../components/include/layout";
import Faq from "../../../components/customcomponents/faq";
import DhInfoBanner from "../../../components/customcomponents/dhinfobanner";
import IndividualPackageInfo from "./individualpackageinfo";
import { get_tv_components } from "../../../data/api";
import ChannelList from "./channellist";
import Error from "../../../components/pages/404error";
import Loading from "../../../components/customcomponents/loading";

const Main = () => {
   //For fetching url params
   const params = useParams();
   const { packagename } = params;
   //Variables
   const [tvData, setTvData] = useState("");
   const [faqData, setFaqData] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [empty, setEmpty] = useState(true);

   //Fetching tv page componets
   useEffect(() => {
      async function fetchData() {
         const response = await get_tv_components();
         setTvData(response.data);
      }
      fetchData();
   }, [empty]);

   //Setting Banner Variable
   useEffect(() => {
      setBannerData(tvData?.banners);
      setFaqData(tvData?.faq);
      if (tvData?.banners === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   // let banner;
   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {/* Top Banner Of individual package page */}
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === "tv_indv") {
               return <HeroBanner type={"indv_top"} data={value} />;
            } else {
               return null;
            }
         })}
         {/* Package Info */}
         <IndividualPackageInfo id={packagename} />
         {/* Channel oF the Individual Tv package */}
         <ChannelList id={packagename} />

         <DhInfoBanner page="Tv" />
         <Faq data={faqData} type="tv" />
      </Layout>
   );
};

export default Main;
