import React from "react";
import { Button, Col, Row } from "react-bootstrap";
// import cartoon from "../../assets/cartoon.png";
import "../../scss/components/cartoonbanner.scss";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";
import Dongle from "../../assets/streamer.png";

const ComponentToTrack = (props) => {
   // console.log("props", props);
   return (
      <div className="cartoonBanner__player-wrapper">
         <ReactPlayer
            url={`${process.env.REACT_APP_FILE_URL}/${props.data.media}`}
            playing={props.isVisible}
            loop={true}
            className="react-player"
            muted={true}
            style={{
               pointerEvents: "none",
            }}
            config={{
               youtube: {
                  playerVars: {
                     autoplay: 1,
                     rel: 0,
                     controls: 0,
                     showinfo: 0,
                     disablekb: 1,
                     modestbranding: 1,
                     start: 76,
                  },
               },
            }}
            playsinline
         />
         <img src={Dongle} alt="" className="dongle" />
      </div>
   );
};

export default function CartoonBanner({ data }) {
   // console.log("data", data);
   //React Router Hooks
   let navigate = useNavigate();

   return (
      <div className="cartoon_banner ">
         <Row className="fixed-width">
            <Col xl={6}>
               {" "}
               <div className="cartoon_image">
                  {data.media_type === "video" ? (
                     <TrackVisibility>
                        <ComponentToTrack data={data} />
                     </TrackVisibility>
                  ) : (
                     <img src={`${process.env.REACT_APP_FILE_URL}/${data.media}`} alt=""></img>
                  )}
               </div>
            </Col>
            <Col xl={5} className="cartoon_text_wrapper">
               <div className="cartoon_text">
                  <p className="cartoon_title">
                     {/* <span style={{ color: "#0E9E49" }}>Watch </span>the
                            Latest Movies at your Home{" "} */}
                     {data.caption}
                  </p>
                  <p className="cartoon_description">{data.sub_caption}</p>
                  {data?.button_type === "" ? (
                     <></>
                  ) : (
                     <>
                        <Button
                           variant="secondary"
                           className="cartoon_button"
                           onClick={() => navigate(data.external_link)}
                        >
                           {data.button_type} <BsArrowRightShort size="24px" />
                        </Button>
                     </>
                  )}
               </div>
            </Col>
         </Row>
      </div>
   );
}
