import React from "react";
import "../../scss/pages/error.scss";
import notFound from "../../assets/notFound.png";
import Layout from "../include/layout";

const Error = () => {
   return (
      <Layout>
         <div id="notfound">
            <div class="notfound">
               <div class="notfound-404">
                  <img src={notFound} alt="" />
                  <p className="notFoundText">Something’s Missing Page Not Found</p>
               </div>
               <a href="/">Go Home</a>
            </div>
         </div>
      </Layout>
   );
};

export default Error;

// handleOnlinePayment({
// order_id: "62d113692b5287d11221360a",
// payment_method: "connectips",
// order_name: "tv",
// total: "100",
// })
