import React from "react";
import SonyMax from "../../../assets/package/sonymax.png";
import "../../../scss/packages/tvchannelcard.scss";

const TvChannelCard = ({ img, channelName }) => {
   return (
      <div className="tvchannelcard_wrapper mx-2 mb-4 mt-3">
         <div className="tvchannelcard_img">
            <img src={`${process.env.REACT_APP_FILE_URL}/${img}`} alt="" />
         </div>
         <div className="tvchannelcard_text">
            <p>{channelName}</p>
         </div>
      </div>
   );
};

export default TvChannelCard;
