import React from "react";
import "../../../scss/packages/categorycard.scss";

const CategoryCard = ({ classname, img, text, setActiveCategory }) => {
   return (
      <div className={"categorycard_wrapper " + classname} onClick={() => setActiveCategory(text)}>
         <div className="categorycard_img">
            <img src={img} alt="" />
         </div>
         <div className="categorycard_text pt-2">
            <p>{text}</p>
         </div>
      </div>
   );
};

export default CategoryCard;
