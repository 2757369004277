import React from "react";
// import discountbanner from "../../assets/iphoneback.png";
import { Button } from "react-bootstrap";
import "../../scss/components/discountbanner.scss";
import productbg from "../../assets/productbg.png";

import iphonebackground from "../../assets/iphonebackground.webp";
import line1 from "../../assets/line1.png";

// import back from "../../assets/back.png";
// import cover from "../../assets/cover.png";
import Widthcontainer from "./widthcontainer";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

export default function DiscountBanner({ bannerData, productData }) {
   // console.log("bannerdata", bannerData);
   let navigate = useNavigate();
   const isResponsive = useMediaQuery({ query: "(max-width: 991px)" });
   return (
      <div className="discount_banner">
         <img id="background_wrapper" src={iphonebackground} />
         {/* <img id="background_wrapper" src={line1} /> */}

         {bannerData === undefined ? null : (
            <Widthcontainer>
               <div className="discount_container fixed-width75">
                  <img
                     src={`${process.env.REACT_APP_FILE_URL}/${bannerData.media}`}
                     alt="offer"
                     height="400px"
                  ></img>

                  {/* <div>
                <p className="iphone13">IPHONE 13 PRO MAX</p>
            </div> */}

                  <div style={isResponsive ? { marginTop: "25px" } : { marginTop: "154px" }}>
                     <p className="discount_title" style={{ maxWidth: "550px" }}>
                        {/* Get <span style={{ color: "#78D29D" }}>20%</span>{" "}
                            Off Our 50GB Data Plan <br />
                            With IPhone 13 Pro Max */}
                        {bannerData.caption}
                     </p>
                     <p className="discount_subtext">
                        {/* Pre-order now. Iphone 13 Pro Max. */}
                        {bannerData.sub_caption}
                     </p>
                     {bannerData?.button_type === "" ? (
                        <></>
                     ) : (
                        <>
                           <div className="discount_button">
                              <div>
                                 <Button
                                    variant="secondary"
                                    className="deal"
                                    onClick={() => navigate(bannerData.external_link)}
                                 >
                                    {bannerData.button_type}
                                 </Button>
                              </div>
                              <div>
                                 <Button className="explore" onClick={() => navigate("/store")}>
                                    Explore More
                                 </Button>
                              </div>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </Widthcontainer>
         )}
         {/* 
            CATEGORY */}
         <div style={{ marginTop: "80px" }}>
            <Widthcontainer style={{ marginTop: "50px" }}>
               <div className="product_container fixed-width75">
                  {Object.entries(productData).map(([key, value]) => {
                     // console.log("data", value);
                     return (
                        <Product
                           value={key}
                           img={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                           title={value.name}
                           subtitle={value.text_1}
                           price={value.price}
                           offer={value.text_2}
                           id={value._id}
                        />
                     );
                  })}
               </div>
            </Widthcontainer>
         </div>
      </div>
   );
}

export function Product(props) {
   let navigate = useNavigate();

   return (
      <div
         className={
            props.value === "0" || props.value === "1" || props.value === "2"
               ? "product-iphone"
               : "product-iphone2"
         }
      >
         <div
            className={
               props.value === "0"
                  ? "inner_product"
                  : props.value === "1"
                  ? "inner_product2"
                  : props.value === "2"
                  ? "inner_product3"
                  : "inner_product_none"
            }
         >
            <p className="title">{props.title}</p>
            <p className="subtitle">{props.subtitle}</p>
            <p className="cost">NRs. {props.price.toLocaleString("hi-IN")}</p>
            <p className="subtitle">{props.offer}</p>
         </div>
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
            }}
         >
            <div style={{ width: "90%" }}>
               <img width={"100%"} height="300px" src={props.img} alt=""></img>
            </div>

            <Button
               variant="secondary"
               className={
                  props.value === "0" || props.value === "1" || props.value === "2"
                     ? "iphone_button"
                     : "iphone_button_white"
               }
               onClick={() => {
                  navigate("/store/" + props.id);
               }}
            >
               Get This Deal
            </Button>
         </div>
      </div>
   );
}
