import React, { useEffect, useState } from "react";
import Layout from "../../components/include/layout";
import Loading from "../../components/customcomponents/loading";
import SeoTags from "../../utils/seoTags";
import {
   get_dishhome_go_package_banner,
   get_dishhome_go_package,
   get_dishhome_go_sub_package,
   get_dishhome_go_settings,
} from "../../data/api";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import HeroBanner from "../../components/customcomponents/herobanner";
import "../../scss/ott/dishhomegoPackages.scss";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "react-responsive";
import MediaIcon from "../../assets/media.png";
import { IoCartOutline, IoCheckmarkCircle } from "react-icons/io5";
import { Alert, Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
import Dropdown from "react-dropdown";
import Token from "../../utils/useToken";
import LoginModal from "../../components/pages/loginmodal";
import DishhomeGoCart from "./cart";
import detailsIcon from "../../assets/ott/detailsIcon.png";
import { FaInfoCircle } from "react-icons/fa";
import { TbArrowBackUp } from "react-icons/tb";
import { check_user_ott } from "../../data/protectedapi";
import MessageModal from "../../components/customcomponents/messageModal";

const DishhomegoPackages = () => {
   //UseStates
   const [loading, setLoading] = useState(true);
   const [bannerData, setBannerData] = useState("");
   const [pagination, setPagination] = useState("");
   const [goPackages, setGoPackages] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [page, setPage] = useState(1);
   const [show, setShow] = useState(false);
   const [isCartOpen, setIsCartOpen] = useState(false);
   const [updateCart, setUpdateCart] = useState(false);
   const [type, setType] = useState("");
   const [subpackages, setSubpackages] = useState([]);
   const [setsubPackageLoading, setSetsubPackageLoading] = useState(false);
   const [settings, setSettings] = useState({});

   const handleClickClosePanelFromOutside = (e) => {
      setIsCartOpen(false);

      // setMobileSearch(false);
   };

   useEffect(() => {
      if (isCartOpen === true) {
         document.body.addEventListener("click", handleClickClosePanelFromOutside);
         return () => {
            document.body.removeEventListener("click", handleClickClosePanelFromOutside);
         };
      }
   }, [isCartOpen]);

   //UseEffect

   useEffect(() => {
      async function fetchPackages() {
         const res = await get_dishhome_go_package(page);
         const response = res?.data?.packages;
         if (res?.status === 200) {
            setLoading(false);
            setGoPackages(response);
            setPagination(res?.data?.paging);
         }
      }

      async function fetchPackageBanner(){
         const bannerres = await get_dishhome_go_package_banner();
         if (bannerres?.status === 200) {
            setLoading(false);
            setSeoData(bannerres?.data?.seo);
            setBannerData(bannerres?.data?.banners);
         }
      }
      if(type === "packages"){
         fetchPackages();
      }
      fetchPackageBanner();
   }, [page, type]);

   useEffect(() => {
      async function getData() {
         const dhgoSettings = await get_dishhome_go_settings();
         const data = dhgoSettings?.data.data;
         setSettings(data);
         const tempType = data?.tabs && data.tabs.length > 0 ? data.tabs[0] : "";
         if (type == "") setType(tempType);
         setSetsubPackageLoading(true);
         const subPackageres = await get_dishhome_go_sub_package(page, type ? type : tempType);
         setPagination(subPackageres?.data?.paging);
         setSubpackages(subPackageres?.data?.data);
         setSetsubPackageLoading(false);
      }
      let gototop = document.getElementById("gototop");

      gototop && gototop.scrollIntoView({
         block: "nearest",
         behavior: "instant",
         inline: "end",
      });
      if(type !== "packages"){
         getData();
      }
   }, [type, page]);

   useEffect(() => {
      setPage(1);
      setPagination("");
   }, [type])

   const handlePageClick = (e) => {
      setPage(e.selected + 1);
   };

   return loading ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate} footer={"ott"}>
         {seoData &&
            Object.entries(seoData).map(([key, value]) => {
               if (value?.page === "dishhomego-packages") {
                  return SeoTags(
                     value?.title,
                     value?.title,
                     value?.description,
                     value?.keyword,
                     "",
                     `${process.env.REACT_APP_PUBLIC_URL}dishhome-go/packages`,
                  );
               } else {
                  return null;
               }
            })}
         {bannerData &&
            Object.entries(bannerData).map(([key, value]) => {
               if (value.page_location === "dishhomego-packages_top") {
                  return <HeroBanner data={value} />;
               } else return null;
            })}
         <LoginModal Show={show} setShow={setShow} link={"/dishhome-go/packages"} />
         <Widthcontainer>
            <GoPackages
               data={goPackages}
               page={pagination?.pages}
               onPageChange={handlePageClick}
               setShow={setShow}
               updateCart={updateCart}
               setUpdateCart={setUpdateCart}
               setType={setType}
               type={type}
               subpackages={subpackages}
               setsubPackageLoading={setsubPackageLoading}
               tabs = {settings?.tabs ? settings.tabs : []}
            />
         </Widthcontainer>
         <DishhomeGoCart
            setShow={setShow}
            isCartOpen={isCartOpen}
            setIsCartOpen={setIsCartOpen}
            updateCart={updateCart}
         />
      </Layout>
   );
};

export default DishhomegoPackages;

function GoPackages(props) {
   const data = props?.data;
   const page = props?.page;
   const onPageChange = props?.onPageChange;
   const setLoginShow = props?.setShow;
   const updateCart = props?.updateCart;
   const setUpdateCart = props?.setUpdateCart;
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
   const setType = props?.setType;
   const subpackages = props?.subpackages;
   const setsubPackageLoading = props?.setsubPackageLoading;
   const [activePriceData, setActivePriceData] = useState(null);
   const [alertShow, setAlertShow] = useState(false);
   const [error, setError] = useState("");
   const [errorMessage, setErrorMessage] = useState("");

   const [show, setShow] = useState(false);
   const [activeData, setActiveData] = useState({});

   const tab_selected = props?.tabs && props?.tabs || [];
   const initialCategory = tab_selected && tab_selected.length > 0 ? tab_selected[0] : '';
   const [selectedCategory, setselectedCategory] = useState(initialCategory);
   useEffect(() => { setselectedCategory(initialCategory)},[initialCategory]);

   const { userDetails } = Token();

   const addToCart = async(activeData) => {
      const response = await check_user_ott();
      if(response.data.success) {
         
         const localData = localStorage.getItem("goCart");

         if (!activePriceData) {
            setAlertShow(true);
            setTimeout(() => {
               setAlertShow(false);
            }, 5000);
            return;
         }

         if (!localData) {
            const data = {
               user: userDetails?.id,
               cart: [{ ...activeData, prices: activePriceData }],
            };
            localStorage.setItem("goCart", JSON.stringify(data));
         } else {
            const local = JSON.parse(localData);
            const cart = local.cart;
            const filtered = cart?.filter((d) => d?._id == activeData?._id);

            if (filtered?.length === 0) {
               const newData = { ...activeData, prices: activePriceData };
               cart?.push(newData);

               const data = {
                  user: local?.user,
                  cart: cart,
               };
               localStorage.setItem("goCart", JSON.stringify(data));
            } else {
               const newCart = cart?.map((d) =>
                  d?._id == activeData?._id ? { ...d, prices: activePriceData } : d,
               );
               const data = {
                  user: local?.user,
                  cart: newCart,
               };
               localStorage.setItem("goCart", JSON.stringify(data));
            }
         }
         setUpdateCart(!updateCart);
         setShow(false);
      } else {
         setErrorMessage(response?.data?.message);
         setError(true);
      }
   }

   const isMobileModal = useMediaQuery({ query: "(max-width: 993px)" });
   const isMobileRes = useMediaQuery({ query: "(max-width: 800px)" });

   return (
      <div className="fixed-width mt-5 mb-4 dishhomegoPackage_card d-flex flex-column">
         <MessageModal
            show={error}
            setShow={setError}
            heading="Failed to add item to cart"
            message={errorMessage}
            type={"error"}
            buttonText="OK"
         />
         <Modal
            onHide={() => {
               setShow(false);
               setActivePriceData(null);
            }}
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="dishhomego-packagedetails-modal"
            fullscreen={isMobileModal}
         >
            <Modal.Body>
               <div className="d-flex flex-column modal-container">
                  <h4 className="modal-custom-header ">{activeData.name}</h4>
                  <div
                     className={
                        isMobileRes
                           ? "mt-2 modal-content-container flex-column align-items-center"
                           : "mt-2 modal-content-container"
                     }
                  >
                     <img
                        src={`${process.env.REACT_APP_FILE_URL}/${activeData?.image}`}
                        className="modal-image"
                        alt=""
                     />
                     <div className="modal-details">
                        <p className="overview">Overview:</p>
                        <p
                           className="description_text"
                           dangerouslySetInnerHTML={{ __html: activeData?.description }}
                        />
                        <div className="bottomContainerSubPackage d-flex flex-column mt-4">
                           <div className="plan_heading_container">
                              <h5 className="plan_text-heading">Plans</h5>
                           </div>
                           <div className="buttonGroup d-flex">
                              {activeData?.prices &&
                                 Object.entries(activeData?.prices)?.map(([key, value]) => {
                                    return (
                                       <div
                                          className={
                                             activePriceData?.priceId === value?.priceId
                                                ? "subPackageCardPriceButton active"
                                                : "subPackageCardPriceButton"
                                          }
                                          onClick={() => setActivePriceData(value)}
                                       >
                                          Rs {value?.price}{" "}
                                          <sub>
                                             /{value?.duration}{" "}
                                             {value?.duration_type === "H"
                                                ? "hours"
                                                : value?.duration_type === "D"
                                                ? "days"
                                                : value?.duration_type === "M"
                                                ? "months"
                                                : "years"}
                                          </sub>
                                       </div>
                                    );
                                 })}
                           </div>
                        </div>

                        {/* <div className="btn-container">
                           {activeData?.prices &&
                              Object.entries(activeData?.prices)?.map(([key, value]) => {
                                 return (
                                    <div
                                       className={
                                          activePriceData?.priceId === value?.priceId
                                             ? "custom-btn active"
                                             : "custom-btn"
                                       }
                                       onClick={() => setActivePriceData(value)}
                                    >
                                       Rs.{value?.price}{" "}
                                       <sub>
                                          /{value?.duration}{" "}
                                          {value?.duration_type === "H"
                                             ? "Hours"
                                             : value?.duration_type === "D"
                                             ? "days"
                                             : value?.duration_type === "M"
                                             ? "months"
                                             : "Years"}
                                       </sub>
                                    </div>
                                 );
                              })}
                        </div> */}
                        {!activePriceData?.priceId && alertShow && (
                           <Alert
                              variant="primary"
                              className="p-2 "
                              style={isMobileRes ? { marginTop: "10px" } : { marginTop: "12px" }}
                           >
                              <p
                                 className="m-0 d-flex align-items-center"
                                 style={{ fontSize: "17px" }}
                              >
                                 {" "}
                                 <FaInfoCircle className="me-1" /> Please choose your desired plan.
                              </p>
                           </Alert>
                        )}
                        <div className="d-flex">
                           <button
                              className="btn btn-danger custom-btn mt-3 d-flex align-items-center justify-center"
                              onClick={() => {
                                 userDetails ? addToCart(activeData) : setLoginShow(true);
                              }}
                              // disabled={activePriceData ? false : true}
                           >
                              {" "}
                              <IoCartOutline fontSize={20} className="me-1" />
                              Add to Cart
                           </button>
                           <button
                              className="btn btn-secondary mt-3 ms-3 custom-btn d-flex align-items-center justify-center"
                              onClick={() => {
                                 setShow(false);
                              }}
                           >
                              <TbArrowBackUp fontSize={20} className="me-1" />
                              Go Back
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {/* <h3 className="text-center mb-5 dishhomego_text_heading">DishHome Go Packages</h3> */}
         <div className="btn-container-ott" style={{ paddingLeft: "10px", position: "relative" }}>
            <h1 id="gototop" style={{ position: "absolute", top: "-10rem" }}></h1>
            <div className="btns">

               {
                  tab_selected?.map((type,index) =>(
                     <button
                        className={`btn btn-ottpackagetype ${
                           selectedCategory === type ? "active" : "inactive"
                        }`}
                        onClick={() => {
                           setselectedCategory(type);
                           setType(type);
                        }}
                     >
                        {type=="movie" ? "Movies": type=="series" ? "Series" : type == "live" ? "Live" : "Packages"}
                     </button>
                  ))
               }
               {/* <button
                  className={`btn btn-ottpackagetype ${
                     selectedCategory === "movie" ? "active" : "inactive"
                  }`}
                  onClick={() => {
                     setselectedCategory("movie");
                     setType("movie");
                  }}
               >
                  Movies
               </button>
               <button
                  className={`btn btn-ottpackagetype ${
                     selectedCategory === "packages" ? "active" : "inactive"
                  }`}
                  onClick={() => setselectedCategory("packages")}
               >
                  Packages
               </button>
               <button
                  className={`btn btn-ottpackagetype ${
                     selectedCategory === "live" ? "active" : "inactive"
                  }`}
                  onClick={() => {
                     setselectedCategory("live");
                     setType("live");
                  }}
               >
                  Live
               </button>
               <button
                  className={`btn btn-ottpackagetype ${
                     selectedCategory === "series" ? "active" : "inactive"
                  }`}
                  onClick={() => {
                     setselectedCategory("series");
                     setType("series");
                  }}
               >
                  Series
               </button> */}

               {/* <button
                  className={`btn btn-ottpackagetype ${
                     selectedCategory === "ppv" ? "active" : "inactive"
                  }`}
                  onClick={() => setselectedCategory("ppv")}
               >
                  PPV
               </button> */}
            </div>
            {/* {selectedCategory !== "packages" && (
               <div className="dropdownContainer mt-3 ms-3">
                  <Dropdown placeholder={"Select Genre"} options={options} />
               </div>
            )} */}
         </div>

         <div
            className="packagesCollection"
         >
            {selectedCategory === "packages" ? (
               data &&
               Object.entries(data)?.map(([key, value]) => {
                  return (
                     <>
                        <div className="goPackageCard mb-4">
                           <img
                              src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                              alt=""
                              className="cover"
                           />
                           <h4 className="card-title mt-2 mb-0">{value?.name}</h4>
                           {/* <p className="card-text">
                              {value?.description?.substring(0, 35)}
                              {value?.description?.length > 130 && "..."}
                           </p> */}
                           <a
                              href={`/dishhome-go/packages/${value?._id}`}
                              className="btn btn-danger mt-2 custom-btn"
                           >
                              <img src={detailsIcon} alt="" />
                              View Details
                           </a>
                        </div>
                        {/* <div className="card">
                           <div className="card-body">
                              <img
                                 src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                 alt=""
                                 width={"100%"}
                              />
                              <h4 className="card-title mt-2 mb-0">{value?.name}</h4>
                              <p className="card-text">
                                 {value?.description?.substring(0, 133)}
                                 {value?.description?.length > 130 && "..."}
                              </p>
                              <a
                                 href={`/dishhome-go/packages/${value?._id}`}
                                 className="btn btn-danger"
                              >
                                 View Details
                              </a>
                           </div>
                        </div> */}
                     </>
                  );
               })
            ) : setsubPackageLoading ? (
               <div className="d-flex w-100 justify-content-center">
                  <ReactLoading type="cylon" color="#ed1c24" height={305} width={305} />
               </div>
            ) : subpackages?.length === 0 ? (
               <p
                  className="mt-4"
                  style={{ textAlign: "center", fontSize: "18px", fontWeight: "600", color: "red" }}
               >
                  No Packages Found !
               </p>
            ) : (
               subpackages &&
               subpackages
                  .filter((item) => item.type === selectedCategory) // Replace "packages" with the specific type you want to filter
                  .map((contentData, index) => (
                     <AddonCard
                        setShow={setShow}
                        setActiveData={setActiveData}
                        setLoginShow={setLoginShow}
                        contentData={contentData}
                        userDetails={userDetails}
                     />
                  ))
            )}
         </div>
         {page > 1 && (
            <ReactPaginate
               style={{ marginTop: "2rem" }}
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={page}
               onPageChange={onPageChange}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               containerClassName={"pagination dashboard_pagination mt-4 align-self-center"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {/* <FeatureComparisonTable /> */}
      </div>
   );
}

function FeatureComparisonTable() {
   return (
      <div className="dishhomeGoFeatureComparisonContainer mt-4">
         <div className="dishHomegoInfoCardContainer">
            <div className="dishHomegoInfoCard">
               <img src={MediaIcon} alt="" height={22} width={22} className="icon" />
               <div className="dishHomegoInfoCardHeader">Premium Plan</div>
               <div className="button">View Details</div>
            </div>
            <div className="dishHomegoInfoCard">
               <img src={MediaIcon} alt="" height={22} width={22} className="icon" />
               <div className="dishHomegoInfoCardHeader">Basic Package</div>
               <div className="button">View Details</div>
            </div>
            <div className="dishHomegoInfoCard">
               <img src={MediaIcon} alt="" height={22} width={22} className="icon" />
               <div className="dishHomegoInfoCardHeader">Silver Package</div>
               <div className="button">View Details</div>
            </div>
         </div>
         <div className="infoTableContainer">
            <table className="table table-striped">
               <tbody>
                  {["GO Live TV", "GO Movies", "GO Series", "A-la-carte"].map((name) => (
                     <tr>
                        <td>{name}</td>
                        <td width="206px">
                           <IoCheckmarkCircle color="D1006F" />
                        </td>
                        <td width="255px">
                           {" "}
                           <IoCheckmarkCircle color="D1006F" />
                        </td>

                        <td width="206px">
                           {" "}
                           <IoCheckmarkCircle color="D1006F" />
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}

function AddonCard({ setShow, setActiveData, setLoginShow, contentData, userDetails }) {
   // console.log("user",userDetails);
   return (
      <div className="card mb-4 goPackageCard">
         <img
            className="cover"
            src={`${process.env.REACT_APP_FILE_URL}/${contentData.image}`}
            alt={contentData.title}
         />
         <div className="details">
            <h5 className="card-title mb-1">{contentData.name}</h5>
            {/* <p className="card-text">Price: Rs.{contentData.price}</p> */}
            <a
               className="btn btn-danger mt-2 custom-btn"
               onClick={() => {userDetails ? (
                  <>
                  {setActiveData(contentData)}
                  {setShow(true)}
                  </>
               ) : setLoginShow(true);
               }}
            >
               <img src={detailsIcon} alt="" /> View Details
            </a>
         </div>
      </div>
   );
}