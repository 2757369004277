import React, { useEffect, useState } from "react";
import { get_single_offer } from "../../data/api";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import { Navigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Offerpage() {
   const [singleOffer, setSingleOffer] = useState("");
   let navigate = useNavigate();

   const parameter = useParams();
   const { id } = parameter;
   useEffect(() => {
      async function get_offers() {
         const res = await get_single_offer(id);
         setSingleOffer(res?.data?.data);
      }
      get_offers();
   }, []);
   return (
      <div>
         <Layout>
            <Widthcontainer>
               <div
                  className="fixed-width"
                  style={{
                     background: "#F2F1F6",
                     display: "flex",
                     flexDirection: "Column",
                     alignItems: "center",
                     padding: "40px 0px",
                     width: "100%",
                     justifyContent: "center",
                  }}
               >
                  <h3 className="aboutus__title" style={{ fontSize: "40px", marginBottom: "50px" }}>
                     {singleOffer?.title}
                  </h3>
                  <div
                     className="aboutus__section fixed-width"
                     style={{
                        // flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: "50px",
                     }}
                  >
                     <div className="aboutus__section_two__img">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${singleOffer?.image}`}
                           alt=""
                        />
                     </div>
                     <div className="aboutus__section_two__text">
                        <p className="aboutus__text">
                           {singleOffer?.description}
                           {/* Regardless of demographic, age or technical
                           discriminations, we want to build strong digital bridges so that people
                           can stay connected and familiar.{" "} */}
                        </p>
                     </div>
                  </div>
                  <Button
                     onClick={() => navigate(singleOffer?.link)}
                     style={{ marginTop: "20px", border: "none", backgroundColor: "red" }}
                  >
                     {singleOffer?.button_text}
                  </Button>

                  <div
                     style={{ marginTop: "40px", backgroundColor: "none" }}
                     dangerouslySetInnerHTML={{ __html: singleOffer?.content }}
                  ></div>
               </div>
            </Widthcontainer>
         </Layout>
      </div>
   );
}
