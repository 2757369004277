import React, { useState, useEffect } from "react";
import "../../../scss/internet/recommended.scss";
import smart from "../../../assets/internet/combine.png";
import girl from "../../../assets/internet/locateus.png";
import one from "../../../assets/internet/list.png";
import two from "../../../assets/internet/two.png";
import three from "../../../assets/internet/three.png";
import arrow from "../../../assets/internet/arrow_new.png";
import rocket from "../../../assets/internet/rocket.png";
import game from "../../../assets/internet/game.png";
import { Button } from "react-bootstrap";
import Widthcontainer from "../widthcontainer";
import { get_internet_recommended } from "../../../data/api";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";

export default function Recommended() {
   const [isEmpty, setIsEmpty] = useState(true);
   const [recommendedData, setRecommendedData] = useState("");

   useEffect(() => {
      async function fetchData() {
         const response = await get_internet_recommended();
         setRecommendedData(response?.data?.data);
      }
      fetchData();
   }, [isEmpty]);

   useEffect(() => {
      if (recommendedData === "" || recommendedData === undefined) {
         setIsEmpty(true);
      } else {
         setIsEmpty(false);
      }
   }, [recommendedData]);

   let navigate = useNavigate();

   return isEmpty ? null : (
      <Widthcontainer>
         <div className="recommended fixed-width75">
            <p className="recommended-title">
               Our <span className="green-text"> Recommendation.</span>
            </p>
            <p className="recommended-subtitle">
               Elevate your entertainment experience with DH Fibernet today.
            </p>
            <div>
               <div className="container" id="internet-mbps">
                  {Object.entries(recommendedData).map(([key, value]) => {
                     return (
                        <div>
                           <div
                              className="recommended-box cursor_pointer"
                              id={
                                 key === "1"
                                    ? "recommended_box1"
                                    : key === "2"
                                    ? "recommended_box2"
                                    : ""
                              }
                              // style={
                              //    key === "1"
                              //       ? { backgroundColor: "#FEF3F3" }
                              //       : key === "2"
                              //       ? { backgroundColor: "#E4FEEF" }
                              //       : null
                              // }
                              onClick={() => navigate(value.link)}
                           >
                              <div className="recommended-image">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                    alt=""
                                    height={80}
                                    width={80}
                                 />
                              </div>
                              <p className="recommended-text">{value.title}</p>
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
            <CheckAvailaibility />
         </div>
      </Widthcontainer>
   );
}

export function CheckAvailaibility() {
   const res = useMediaQuery({ query: "(max-width: 991px)" });
   const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

   return (
      <div className="availaibility ">
         <div className="girl-image">
            <img src={girl} height="180px" />
         </div>
         <div className="availaibility-description">
            <p className="availaibility-title">
               DH Fibernet Now <span className="green-text"> Available</span>
               <br />
               in Your City!
            </p>
            <p className="availaibility-subtitle">
               Wondering if DH Fibernet has reached your location?
            </p>
            {/* 
               <div
                  style={{
                     display: "flex",
                     width: "100%",
                     gap: "6px",
                     justifyContent: "flex-start",
                     paddingLeft: "12px",
                  }}
               >
                  <div>
                     <img src={one} className="li-image" />
                  </div>
                  <div>
                     <p className="availaibility-text">Enter Your Current Address</p>
                     <p className="avaialibility-answer">
                        We will check if we are available at your area.
                     </p>
                  </div>
               </div>
               <div
                  style={{
                     display: "flex",
                     width: "100%",
                     gap: "6px",
                     justifyContent: "flex-start",
                     paddingLeft: "12px",
                  }}
               >
                  <div>
                     <img src={two} className="li-image" />
                  </div>
                  <div>
                     <p className="availaibility-text">Enter Your Contact Number</p>
                     <p className="avaialibility-answer">Our team will contact you if required.</p>
                  </div>
               </div>
               <div
                  style={{
                     display: "flex",
                     width: "100%",
                     gap: "6px",
                     justifyContent: "flex-start",
                     paddingLeft: "12px",
                  }}
               >
                  <div>
                     <img src={three} className="li-image" />
                  </div>
                  <div>
                     <p className="availaibility-text">Check Availibility</p>
                     <p className="avaialibility-answer">
                        You can do this before ordering a package.
                     </p>
                  </div>
               </div> */}
            <div
               style={
                  res
                     ? {
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          width: "100%",
                          justifyContent: "center",
                       }
                     : {
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          width: "100%",
                       }
               }
            >
               <Button
                  className="availaibility-button"
                  variant="secondary"
                  onClick={() => {
                     window.open(
                        "https://www.google.com/maps/d/u/0/viewer?hl=en&hl=en&mid=1mejmhiV3MS0mQJAuTDhf0OEBxDwvElqT&ll=27.70771821939905%2C85.38900738217177&z=12",
                        "_blank",
                     );
                  }}
               >
                  Click here to check availability!
               </Button>
               {/* {!isMobile && (
                  <img
                     src={arrow}
                     style={
                        res
                           ? {
                                position: "absolute",
                                left: "480px",
                                top: "-25px",
                             }
                           : { position: "absolute", left: "240px", top: "-25px" }
                     }
                     alt=""
                  />
               )} */}
            </div>
         </div>
      </div>
   );
}
