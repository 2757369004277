import { React, useState, useEffect } from "react";
import Layout from "../../components/include/layout";
import HeroBanner from "../../components/customcomponents/herobanner";
import PackageCard from "./packagecard";
import Feature from "./feature";
import ExclusiveChannel from "../../components/customcomponents/exclusivechannel";
import Faq from "../../components/customcomponents/faq";
import { get_tv_components } from "../../data/api";
import Loading from "../../components/customcomponents/loading";
import SeoTags from "../../utils/seoTags";

export default function TVchannels() {
   //Variables
   const [tvData, setTvData] = useState("");
   const [tvPackage, setTvPackage] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [channelData, setChannelData] = useState("");
   const [faqData, setFaqData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   //Banner Variables
   let topBanner;
   let bottomBanner;
   //Fetching TV page Comanents
   useEffect(() => {
      async function fetchData() {
         const response = await get_tv_components();
         setTvData(response.data);
      }
      fetchData();
   }, [empty]);
   //Updating individual component variables
   useEffect(() => {
      setTvPackage(tvData?.tvPackages);
      setBannerData(tvData?.banners);
      setChannelData(tvData?.channels);
      setFaqData(tvData?.faq);
      setSeoData(tvData?.seo);
      if (tvData?.faq === undefined || tvData?.seo === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {/* Seo Data of TV page */}
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "tv") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}tv`,
               );
            } else {
               return null;
            }
         })}
         {/* setting banner value */}
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === "tv_top") {
               topBanner = value;
            } else if (value.page_location === "tv_bottom") {
               bottomBanner = value;
            }
         })}
         {/* T Page Top banner */}
         {topBanner === undefined ? null : (
            <HeroBanner data={topBanner} type="tv_top" muted={false} />
         )}
         {/* TV package Card Slider */}

         <PackageCard tvData={tvPackage} type="tv_page" />
         {/* DishHome Fatures Part */}
         <Feature />
         {/* Bottom Banner */}
         <div className="text_wrapper my-5">
            <h1>
               {/* <span>Ultra-HD</span>, Ultra-Simple <br /> DishHome TV. */}
               <span>Stay Entertained</span>, Stay Informed <br /> With Dishhome TV
            </h1>
         </div>
         {bottomBanner === undefined ? null : <HeroBanner data={bottomBanner} />}
         {/* Exclusive channer Bottom part */}
         <ExclusiveChannel data={channelData} />
         <Faq data={faqData} type="tv" />
      </Layout>
   );
}
