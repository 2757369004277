import React, { useState } from "react";
import {
   Modal,
   Button,
   Form,
   FormGroup,
   Accordion,
   useAccordionButton,
   Card,
} from "react-bootstrap";

const CookieConsentModal = ({ handleAccept, show, setShow, googleAnalyst, setGoogleAnalyst }) => {
   //useState for all statistics cookies
   const [statistics, setStatistics] = useState(true);

   //handle all statistics cookies
   const handleAllStatistics = (current) => {
      setStatistics(!current);
      if (current) setGoogleAnalyst(false);
      else setGoogleAnalyst(true);
   };

   return (
      <Modal show={show} onHide={() => setShow(false)}>
         <Modal.Header>
            <Modal.Title>Cookie Consent</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form>
               <Accordion>
                  <Accordion.Item eventKey="0">
                     <Accordion.Header className="cookie-consent-accordion">
                        <Form.Check
                           reverse
                           type="switch"
                           id="custom-switch"
                           label="Essential Cookies"
                           checked={true}
                           style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row-reverse",
                              accentColor: "red",
                              width: "100%",
                              marginRight: "10px",
                           }}
                           disabled
                        />
                     </Accordion.Header>
                     <Accordion.Body
                        style={{
                           accentColor: "red",
                           width: "100%",
                           fontSize: "14px",
                           textJustify: "auto",
                        }}
                     >
                        <p>
                           Essential cookies are required for the website to function and cannot be
                           switched off in our systems. They are usually only set in response to
                           actions made by you which amount to a request for services, such as
                           setting your privacy preferences, logging in or filling in forms. You can
                           set your browser to block or alert you about these cookies, but some
                           parts of the site will not then work. These cookies do not store any
                           personally identifiable information.
                        </p>
                        <p>
                           Policy:{" "}
                           <a href="/" target={"_blank"}>
                              https://www.dishhome.com.np/privacy-policy
                           </a>
                        </p>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                     <Accordion.Header className="cookie-consent-accordion">
                        <Form.Check
                           disabled
                           reverse
                           type="switch"
                           id="custom-switch"
                           label="External media"
                           checked={true}
                           style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row-reverse",
                              accentColor: "red",
                              width: "100%",
                              marginRight: "10px",
                              zIndex: "9999999",
                           }}
                        />
                     </Accordion.Header>

                     <Accordion.Body
                        style={{
                           accentColor: "red",
                           width: "100%",
                           fontSize: "14px",
                           textJustify: "auto",
                        }}
                     >
                        <p>
                           Content from video platforms and social media platforms is blocked by
                           default. If cookies from external media are accepted, access to this
                           content no longer requires manual consent.
                        </p>
                        <FormGroup>
                           <Form.Check
                              reverse
                              disabled
                              type="switch"
                              id="custom-switch"
                              label="Google reCAPTCHA Cookies"
                              checked={true}
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 flexDirection: "row-reverse",
                                 accentColor: "red",
                                 width: "100%",
                                 marginRight: "10px",
                              }}
                           />
                           <p>
                              The Google reCAPTCHA script is used to unlock the Captcha function.
                           </p>
                           <p>
                              Policy:{" "}
                              <a href="/" target={"_blank"}>
                                 https://policies.google.com/privacy
                              </a>
                           </p>
                        </FormGroup>
                     </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                     <Accordion.Header className="cookie-consent-accordion">
                        <Form.Check
                           reverse
                           type="switch"
                           id="custom-switch"
                           label="Statistics Cookies"
                           checked={statistics && googleAnalyst}
                           onChange={() => {
                              handleAllStatistics(statistics);
                           }}
                           style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row-reverse",
                              accentColor: "red",
                              width: "100%",
                              marginRight: "10px",
                              zIndex: "9999999",
                           }}
                        />
                     </Accordion.Header>

                     <Accordion.Body
                        style={{
                           accentColor: "red",
                           width: "100%",
                           fontSize: "14px",
                           textJustify: "auto",
                        }}
                     >
                        <p>
                           Statistics cookies help website owners to understand how visitors
                           interact with websites by collecting and reporting information
                           anonymously.
                        </p>
                        <FormGroup>
                           <Form.Check
                              reverse
                              disabled
                              type="switch"
                              id="custom-switch"
                              label="Facebook"
                              checked={true}
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 flexDirection: "row-reverse",
                                 accentColor: "red",
                                 width: "100%",
                                 marginRight: "10px",
                              }}
                           />

                           <p>
                              Policy:{" "}
                              <a href="/" target={"_blank"}>
                                 https://www.facebook.com/policies/cookies
                              </a>
                           </p>
                        </FormGroup>
                        <FormGroup>
                           <Form.Check
                              reverse
                              type="switch"
                              id="custom-switch"
                              label="Google Analytics"
                              checked={googleAnalyst}
                              onChange={() => setGoogleAnalyst(!googleAnalyst)}
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 flexDirection: "row-reverse",
                                 accentColor: "red",
                                 width: "100%",
                                 marginRight: "10px",
                              }}
                           />
                           <p>
                              The "_ga" and "_gid" cookies contain randomly generated user IDs to
                              recognize returning visitors. The "_gat_xxx" cookie ensures that
                              certain data is only sent to Google Analytics once a minute at most.
                              The "_gac_xxx" cookie is set when a user clicks on a Google ad to
                              access the website and when the website operator does not use Google
                              Ads, but has linked their Google Ads account to the Google Analytics
                              property. With this cookie, conversions, contact requests or other
                              events can be assigned to the ad. The "_gcl_au" cookie is used by
                              Google AdSense to optimize advertising efficiency on websites.
                           </p>
                           <p>
                              Policy:{" "}
                              <a href="/" target={"_blank"}>
                                 https://policies.google.com/privacy
                              </a>
                           </p>
                        </FormGroup>
                        {/* <FormGroup>
                           <Form.Check
                              reverse
                              type="switch"
                              id="custom-switch"
                              label="Google Ads"
                              checked={googleAnalyst}
                              onChange={() => setGoogleAnalyst(!googleAnalyst)}
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "space-between",
                                 flexDirection: "row-reverse",
                                 accentColor: "red",
                                 width: "100%",
                                 marginRight: "10px",
                              }}
                           />
                           <p>
                              The "IDE" cookie contains a randomly generated user ID to recognize
                              the user across domains and to display personalized advertising. It is
                              set if the website operator has activated the remarketing function,
                              demographic reports or Google Signals. The cookies "1P_Jar", "AID",
                              "ANID", "CONSENT", "DSID", "DV", "NID", "APISID", "HSID", "SAPISID",
                              "SID", "SIDCC" and "SSID" are used to support Google's advertising
                              services and to collect anonymous information about Google Ads
                              advertisements. The "test_cookie" cookie is used to check whether the
                              browser allows cookies to be set.
                           </p>
                           <p>
                              Policy:{" "}
                              <a href="/" target={"_blank"}>
                                 https://policies.google.com/privacy
                              </a>
                           </p>
                        </FormGroup> */}
                     </Accordion.Body>
                  </Accordion.Item>
               </Accordion>
            </Form>
         </Modal.Body>
         <Modal.Footer>
            <Button style={{ backgroundColor: "#ed1c24", border: "none" }} onClick={handleAccept}>
               Save Changes & Accept
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

export default CookieConsentModal;
