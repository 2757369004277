import React, { useEffect, useState } from "react";
import "../../scss/store/categories.scss";
import satellite from "../../assets/store/satellite.png";
import { get_all_category, get_popular_category } from "../../data/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import { useNavigate } from "react-router";
export default function Categories({ data }) {
   const handleDragStart = (e) => e.preventDefault();
   const [categorydata, setcategoryData] = useState("");

   useEffect(() => {
      async function get_category() {
         const res = await get_all_category();
         const response = res.data.data;
         setcategoryData(response);
      }
      get_category();
   }, []);

   const PreviousBtn = (props) => {
      const { className, onClick } = props;
      return (
         <div className={className} onClick={onClick}>
            <ArrowBackIos
               style={{
                  color: "#596579",
                  fontSize: "20px",
                  border: "1px solid #596579",
                  borderRadius: "25px",
               }}
            />
         </div>
      );
   };
   const NextBtn = (props) => {
      const { className, onClick } = props;
      return (
         <div className={className} onClick={onClick}>
            <ArrowForwardIos
               style={{
                  color: "#596579",
                  fontSize: "20px",
                  border: "1px solid #596579",
                  borderRadius: "25px",
               }}
            />
         </div>
      );
   };

   const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      cenerMode: true,
   };

   return (
      <div className="categories">
         <p className="categories_title">Shop by Categories.</p>
         <p className="categories_description">Get the Best Deals, Now!</p>
         <div className="categories_list  ">
            <Widthcontainer>
               <div className="slider_responsive fixed-width75">
                  {" "}
                  <Splide
                     options={{
                        type: "loop",
                        hasTrack: false,
                        perPage: 3,
                        autoHeight: true,
                        breakpoints: {
                           460: {
                              perPage: 1,
                              perMove: 1,
                           },
                           780: {
                              perPage: 2,
                              perMove: 2,
                           },
                           992: {
                              perPage: 2,
                              perMove: 2,
                           },
                           1200: {
                              perPage: 3,
                              perMove: 3,
                           },
                           1800: {
                              perPage: 4,
                              perMove: 4,
                           },
                        },
                     }}
                  >
                     {Object.entries(categorydata).map(([key, value]) => {
                        return (
                           <SplideSlide>
                              <CategoriesList
                                 id={value._id}
                                 img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 category_name={value.title}
                                 category_discount={value.seo_keywords}
                                 category_brief={value.seo_description}
                                 link={value.link}
                              />
                           </SplideSlide>
                        );
                     })}
                  </Splide>
               </div>
            </Widthcontainer>
         </div>
      </div>
   );
}
export function CategoriesList(props) {
   let navigate = useNavigate();

   return (
      <a
         href={props.link}
         style={{
            textDecoration: "none",
            color: "black",
         }}
      >
         <div className=" categories_item" style={{ textAlign: "center" }}>
            <img
               className="slider-category-image"
               width="100%"
               height="140px"
               src={props.img}
               alt="Satellite"
               style={{ objectFit: "contain" }}
            />
            <p className="categories_name">{props.category_name}</p>
            <p className="categories_discount">{props.category_discount}</p>
            <p className="categories_brief">{props.category_brief}</p>
         </div>
      </a>
   );
}
