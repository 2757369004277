import React, { useState, useEffect } from "react";
import { get_brand, get_colors, get_hot_deals, get_sales } from "../../data/api";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { Button, Form } from "react-bootstrap";
import products from "../../assets/store/Products.png";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import SeoTags from "../../utils/seoTags";
import { BreadContainer } from "../include/breadcrumb";

export default function Hotdealpage() {
   const [listview, setListview] = useState(false);
   const [hotdeals, setHotDeals] = useState("");
   let navigate = useNavigate();
   useEffect(() => {
      async function get_hotdeals() {
         const res = await get_hot_deals();
         const response = res?.data?.data;
         setHotDeals(response);
      }
      get_hotdeals();
   }, []);

   return (
      <div>
         <Layout>
            <BreadContainer mainpath={"store"} pathname={"HotDeals"} />

            {SeoTags("Hotdeals | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}

            <p className="categories_title" style={{ paddingTop: "20px" }}>
               Hot Deals.
            </p>
            <p className="categories_description">Take a look at what’s new, right now.</p>
            <Widthcontainer>
               <div className="fixed-width75">
                  <div style={{ flexBasis: "20%" }}>{/* <FilterSection /> */}</div>
                  <div style={{ flexBasis: "78%" }}>
                     {/* <Header listview={listview} /> */}
                     <div className="filterproducts">
                        <div
                           className={`${listview ? "category_box_list" : "category_box"}`}
                           id="category_products"
                        >
                           {Object.entries(hotdeals).map(([key, value]) => {
                              return (
                                 <div
                                    onClick={() => {
                                       navigate("/store/" + value._id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className={`${listview ? "list_view " : "product_view "}`}
                                 >
                                    <div className="category_imagewrapper">
                                       {value.sale.saleId !== null ? (
                                          <div id="sale-tag-category">
                                             <p>Sale offer</p>
                                          </div>
                                       ) : (
                                          <></>
                                       )}
                                       <img
                                          className="view_img"
                                          src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                                          alt=""
                                       />
                                    </div>

                                    <div className={`${listview ? "details_info " : "null"}`}>
                                       <div
                                          className={`${listview ? "brand-list " : "brand-grid "}`}
                                       >
                                          <img src={products} alt="" />
                                       </div>
                                       <p
                                          className={`${
                                             listview ? "listview-title " : "view_title "
                                          }`}
                                       >
                                          {value.name}
                                       </p>

                                       <p
                                          className={`${
                                             listview ? "categorylist_price " : "categorygrid_price"
                                          }`}
                                       >
                                          {value.tagline}
                                       </p>

                                       {value.sale.saleId !== null ? (
                                          <>
                                             <p
                                                className="arrival_cost"
                                                style={{ textDecoration: "line-through" }}
                                             >
                                                Rs. {value.price}
                                             </p>
                                             <p className="arrival_cost">
                                                Rs.{" "}
                                                {value?.sale?.sale_price.toLocaleString("hi-IN")}
                                             </p>
                                          </>
                                       ) : (
                                          <>
                                             <p className="arrival_cost">
                                                Rs. {value.price.toLocaleString("hi-IN")}{" "}
                                             </p>
                                          </>
                                       )}
                                       <div
                                          style={{
                                             display: "flex",
                                             justifyContent: "center",
                                             marginTop: "7px",
                                             marginBottom: "5px",
                                          }}
                                       >
                                          <p
                                             style={{
                                                display: "flex",
                                                gap: "4px",
                                                alignItems: "center",
                                             }}
                                          >
                                             {value?.reviews?.length > 0 && (
                                                <ReactStars
                                                   value={value?.rating}
                                                   count={5}
                                                   edit={false}
                                                   size={24}
                                                   activeColor="#ffd700"
                                                   color="#D0D0D0"
                                                />
                                             )}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                  </div>
               </div>
            </Widthcontainer>
         </Layout>
      </div>
   );
}

export function Header({ listview }) {
   return (
      <div>
         <div className="category-bar ">
            <div className="icon-div">
               <div className={`${listview ? "active-view " : "list "}`}>
                  <AiOutlineUnorderedList />
               </div>
               <div className={`${listview ? "grid " : "active-view "}`}>
                  <BsFillGrid3X3GapFill />
               </div>
            </div>

            <div className="form-category">
               <div>
                  <Form.Select aria-label="Default select example" id="form-div1">
                     <option value="5">Show 5 per page</option>
                     <option value="10">Show 10 per page</option>
                  </Form.Select>
               </div>
               <div>
                  <Form.Select aria-label="Default select example" id="form-div">
                     <option value="asc-price">Sort by price(low-high)</option>
                     <option value="dsc-price">Sort by price(high-low)</option>

                     <option value="asc-name">Sort by name(A-Z)</option>
                     <option value="dsc-name">Sort by name(Z-A)</option>
                  </Form.Select>
               </div>
            </div>
         </div>
      </div>
   );
}

export function FilterSection() {
   const [brandlist, setBrandList] = useState("");
   const [color, setColor] = useState("");
   const [minprice, setMinprice] = useState("");
   const [maxprice, setMaxprice] = useState("");

   useEffect(() => {
      async function get_newarrivals_pagination() {
         const res = await get_colors();

         const response = res.data.data;
         setColor(response);
      }
      get_newarrivals_pagination();
   }, []);
   useEffect(() => {
      async function get_filter_brand() {
         const res = await get_brand();

         setBrandList(res.data.data);
      }
      get_filter_brand();
   }, []);
   return (
      <div>
         <p className="slider-label">Price</p>

         <div>
            <Form className="price-container-category">
               <Form.Control
                  type="number"
                  value={minprice}
                  placeholder="min price"
                  id="filter-min-price"
                  onChange={(e) => setMinprice(e.target.value)}
               />
               <Form.Control
                  type="number"
                  placeholder="max price"
                  id="filter-min-price"
                  value={maxprice}
                  onChange={(e) => setMaxprice(e.target.value)}
               />
               <Button id="maxprice-filter">Go</Button>
            </Form>
            <div>
               <div>
                  <p className="slider-label">Manufacturer</p>

                  <div style={{ marginTop: "20px" }}>
                     {Object.entries(brandlist).map(([key, value]) => {
                        return (
                           <div>
                              <input type="checkbox" id="scales" name="scales" value={value.name} />
                              <label
                                 id="category-label"
                                 for="scales"
                                 style={{
                                    marginLeft: "5px",
                                 }}
                              >
                                 {value.name}
                              </label>
                           </div>
                        );
                     })}
                  </div>

                  <div style={{ marginTop: "20px" }}>
                     <p className="slider-label">Color</p>
                     <div className="color-container">
                        {Object.entries(color).map(([key, value]) => {
                           return (
                              <div id="color-filter" style={{ backgroundColor: `${value.hex}` }}>
                                 {/* <p>{value.name}</p> */}
                              </div>
                           );
                        })}
                     </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                     <p className="slider-label">Product Tag</p>

                     <div id="slider-container"></div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
