import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Loading from "../components/customcomponents/loading";
import { get_referals } from "../data/protectedapi";
import "../scss/pages/dashboardrefer.scss";

export default function Dashboardreferal() {
   const [referals, setReferals] = useState("");
   const [loading, setLoading] = useState(true);
   const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
   useEffect(() => {
      async function fetchData() {
         const response = await get_referals();
         setReferals(response?.data?.data);
      }
      fetchData();
   }, [loading]);

   useEffect(() => {
      if (referals === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [referals]);

   return loading ? (
      <Loading />
   ) : (
      <div className="container">
         <h1 className="wishlist-title">My Referrals</h1>
         {referals?.length > 0 ? (
            !isMobile && (
               <>
                  <div className="refer-title">
                     <div>
                        <p className="refer-para">Full name</p>
                     </div>
                     <div>
                        <p className="refer-para">Email</p>
                     </div>
                     <div>
                        <p className="refer-para">Contact number</p>
                     </div>
                     <div>
                        <p className="refer-para">Status</p>
                     </div>
                  </div>
               </>
            )
         ) : (
            <h3 style={{ color: "darkgrey" }}>No referrals</h3>
         )}
         <div className="refer__container">
            {referals &&
               Object.entries(referals)
                  ?.reverse()
                  ?.map(([key, value]) => {
                     return !isMobile ? (
                        <div className="refer-wrapper">
                           <div>
                              <p style={{ wordBreak: "break-word" }}>{value?.full_name}</p>
                           </div>
                           <div>
                              <p>{value?.email}</p>
                           </div>
                           <div>
                              <p id="refer-number">{value?.phone_number}</p>
                           </div>
                           <div>
                              <p id="refer-status">{value?.status?.toUpperCase()}</p>
                           </div>
                        </div>
                     ) : (
                        <div className="refer-mobile-wrapper">
                           <div>
                              <p>Name : {value?.full_name}</p>
                           </div>
                           <div>
                              <p>Email : {value?.email}</p>
                           </div>
                           <div>
                              <p id="refer-number">Phone Number : {value?.phone_number}</p>
                           </div>
                           <div>
                              <p id="refer-status">Status : {value?.status?.toUpperCase()}</p>
                           </div>
                        </div>
                     );
                  })}
         </div>
      </div>
   );
}
