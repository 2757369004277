import React, { useEffect, useState } from "react";
import ExclusiveChannel from "../components/customcomponents/exclusivechannel";
import Layout from "../components/include/layout";
import OttBanner from "./ottBanner";
import OttFeature from "./ottFeature";
import OttMoviesSlide from "./ottMoviesSlide";
import { get_Ott_components } from "../data/api";
import arrowvector from "../assets/ott/arrow.png";
import Loading from "../components/customcomponents/loading";
import Token from "../utils/useToken";
import SeoTags from "../utils/seoTags";

const Main = () => {
   //UseStates
   const [ott_components, setOtt_components] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   const [bannerData, setBannerData] = useState("");
   const [channelData, setChannelData] = useState("");
   const [ppvMovies, setPpvMovies] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);

   const { token } = Token();
   const UserDetails = token?.user;
   //UseEffect
   useEffect(() => {
      const fetchData = async () => {
         const response = await get_Ott_components();
         setOtt_components(response.data);
      };
      fetchData();
   }, [isLoading]);

   useEffect(() => {
      if (ott_components !== "") {
         setBannerData(ott_components?.banners);
         setChannelData(ott_components?.channels);
         setPpvMovies(ott_components?.ppvMovies);
         setSeoData(ott_components?.seo);
         setIsLoading(false);
      }
   });

   return isLoading ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate} footer={"ott"}>
         {seoData &&
            Object.entries(seoData).map(([key, value]) => {
               if (value?.page === "dishhomego") {
                  return SeoTags(
                     value?.title,
                     value?.title,
                     "",
                     value?.keyword,
                     "",
                     `${process.env.REACT_APP_PUBLIC_URL}dishhome-go`,
                  );
               } else {
                  return null;
               }
            })}

         {/* {UserDetails?.is_kyc_verified === false ? (
            <div
               className="ott_top"
               style={{
                  backgroundColor: "#1A63F4",
                  height: "fit-content",
                  textAlign: "center",
                  color: "#fff",
               }}
            >
               <p
                  style={{
                     color: "#FDF8F2",
                     fontSize: "14px",
                     fontWeight: "400",
                     lineHeight: "18px",
                     paddingTop: "15px",
                  }}
               >
                  Please fill the kyc form for using this OTT feature with DishHome. It will take 2
                  minutes to fill the form
               </p>
               <a
                  href="/dashboard?type=1"
                  style={{ textDecoration: "none", fontSize: "14px", color: "#fff" }}
               >
                  Do it now <img src={arrowvector} alt="arrow" />
               </a>
            </div>
         ) : null} */}

         {bannerData === undefined ? null : <OttBanner data={bannerData} muted={false} />}
         <ExclusiveChannel type={"ott"} data={channelData} />
         <OttFeature />
         {/* <OttMoviesSlide /> */}
      </Layout>
   );
};

export default Main;
