import React, { useRef, useState, useEffect } from "react";
import { cancleOrder, get_package_orders } from "../data/protectedapi";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import "../scss/components/order.scss";
import { handleOnlinePayment } from "../components/pages/payment/payment";
import KhaltiCheckout from "khalti-checkout-web";
import MessageModal from "../components/customcomponents/messageModal";
import khalti from "../assets/cart/khalti.png";
import esewa from "../assets/store/esewa.webp";
import ips from "../assets/store/ips.png";
import prabhu from "../assets/store/prabhu.png";
import ime from "../assets/store/ime.png";
import { Button, Modal } from "react-bootstrap";
import Token from "../utils/useToken";
import { get_company_details } from "../data/api";
import { MdOutlineDirections } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { capitalize } from "@material-ui/core";
import { AiOutlineDoubleRight } from "react-icons/ai";

export default function PackageOrder() {
   const categoryContainer = useRef(null);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 200);
   };
   //useStates
   const [order, setOrder] = useState("");
   const [pageCount, setPageCount] = useState("");
   const [selectedPage, setSelectedPage] = useState(1);
   const [orderId, setOrderId] = useState("");
   const [paymentModal, setPaymentModal] = useState(false);
   const [total, setTotal] = useState("");
   const [activeOrder, setActiveOrder] = useState();
   const [orderDetailsModal, setOrderDetailsModal] = useState(false);
   const [cancleModal, setCancleModal] = useState(false);
   const [cancleErrorModal, setCancleErrorModal] = useState(false);

   //useEffects
   useEffect(() => {
      async function fetchData() {
         const response = await get_package_orders(selectedPage);
         setOrder(response?.data?.data);
         setPageCount(response?.data?.paging?.pages);
      }
      fetchData();
   }, [selectedPage, cancleErrorModal, paymentModal, cancleModal]);

   const handlePageClick = (e) => {
      setSelectedPage(e.selected + 1);
      scrollDown(categoryContainer);
   };
   // console.log("order", activeOrder);
   return (
      <div className="container " style={{}} ref={categoryContainer}>
         <PaymentModal
            show={paymentModal}
            setShow={setPaymentModal}
            order_id={orderId?.toString()}
            total={total}
         />
         <OrderDetails
            activeOrder={activeOrder}
            orderDetailsModal={orderDetailsModal}
            setOrderDetailsModal={setOrderDetailsModal}
            setTotal={setTotal}
            setOrderId={setOrderId}
            setPaymentModal={setPaymentModal}
            setCancleModal={setCancleModal}
         />
         <CancleOrder
            show={cancleModal}
            setShow={setCancleModal}
            id={orderId}
            type={"package"}
            setOrderDetailsModal={setOrderDetailsModal}
            cancleErrorModal={cancleErrorModal}
            setCancleErrorModal={setCancleErrorModal}
         />
         {order &&
            order.map((value, index) => {
               // console.log("value", value);
               return (
                  <Order
                     order={value}
                     setTotal={setTotal}
                     setOrderId={setOrderId}
                     setPaymentModal={setPaymentModal}
                     setActiveOrder={setActiveOrder}
                     setOrderDetailsModal={setOrderDetailsModal}
                  />
               );
               //  (
               //    <div className="packageOrder__card mb-3 px-3 py-4" key={index}>
               //       <div className="packageOrder__card__header">
               //          <p className="orderId">Order Id: {value?.order_id?.toUpperCase()}</p>
               //          {value?.created_at && (
               //             <p className="orderDate">
               //                {/* {new Date(value?.paid_at)} */}
               //                Created at:{" "}
               //                {/* {format(new Date(value?.createdAt), "dd MMM yyyy H:mm:ss")} */}
               //                {format(new Date(value?.created_at), "dd MMM yyyy H:mm:ss")}
               //             </p>
               //          )}
               //       </div>
               //       <div className="packageOrder__card__body">
               //          <div className="details">
               //             <p className="title">{value?.package_name}</p>
               //             <p className="packageType">
               //                Type:{" "}
               //                {value?.package_type.charAt(0).toUpperCase() +
               //                   value?.package_type.slice(1)}
               //             </p>
               //             <p className="total">Total: Rs.{value?.total.toLocaleString("hi-IN")}</p>
               //          </div>
               //          <div className="status">
               //             {/* <p className="paymentStatus">
               //                Payment Status :{" "}
               //                {value?.is_paid ? <span>Paid</span> : <span>Not Paid</span>}
               //             </p> */}
               //             <p
               //                style={{
               //                   display: "flex",
               //                   flexDirection: "column",
               //                   alignItems: "center",
               //                }}
               //             >
               //                <span
               //                   className="px-2"
               //                   style={{
               //                      paddingBotton: "6px",
               //                      borderBottom: "1px solid black",
               //                   }}
               //                >
               //                   Status
               //                </span>
               //                <p>
               //                   {value?.is_paid ? (
               //                      <span style={{ color: "green", fontWeight: "bold" }}>
               //                         COMPLETED
               //                      </span>
               //                   ) : (
               //                      <span style={{ color: "#FF6F00", fontWeight: "bold" }}>
               //                         PENDING
               //                      </span>
               //                   )}
               //                </p>
               //             </p>

               //             {value?.paid_at && (
               //                <p className="paidAt">
               //                   Paid at: {format(new Date(value?.paid_at), "dd MMM yyyy H:mm:ss")}
               //                </p>
               //             )}
               //             {value?.payment_type !== "cod" && value?.is_paid === false && (
               //                <p
               //                   style={{
               //                      display: "flex",
               //                      padding: "2px 10px",
               //                      margin: "0px",
               //                      backgroundColor: "#ed1c24",
               //                      color: "#fff",
               //                      borderRadius: "10px",
               //                      cursor: "pointer",
               //                      whiteSpace: "nowrap",
               //                   }}
               //                   onClick={() => {
               //                      setOrderId(value?._id);
               //                      setPaymentModal(true);
               //                      setTotal(value?.total);
               //                   }}
               //                >
               //                   Pay Now
               //                </p>
               //             )}
               //          </div>
               //       </div>
               //    </div>
               // );
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {order?.length === 0 && (
            <div className="dashboardNotification_empty">
               <p className="dashboardNotification_empty_description">You have no orders</p>
            </div>
         )}
      </div>
   );
}

export function PaymentModal({ show, setShow, order_id, total }) {
   const [paymentMethod, setPaymentMethod] = useState("");
   const { userDetails } = Token();
   const [success, setSuccess] = useState(false);
   const handlePayment = async () => {
      if (
         // paymentMethod !== "khalti" &&
         paymentMethod !== ""
      ) {
         handleOnlinePayment({
            order_id: order_id,
            payment_method: paymentMethod,
            order_name: "package",
            total: total,
            uid: userDetails?.id,
         });
      }
      // else if (paymentMethod === "khalti") {
      //    const data = await handleOnlinePayment({
      //       order_id: order_id,
      //       payment_method: paymentMethod,
      //       order_name: "store",
      //       uid: userDetails?.id,
      //       setSuccess: setSuccess,
      //    });
      //    const checkout = new KhaltiCheckout(data);
      //    checkout.show({ amount: total * 100 });
      // }
   };
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);
   return (
      <>
         <MessageModal
            show={success}
            setShow={setSuccess}
            message="Payment Successful"
            link={"/"}
            buttonText="Go Home"
            reload="true"
         />
         {success && setShow(false)}
         <Modal show={show} onHide={() => setShow(false)}>
            <div style={{ padding: "20px" }} className="myorder_payment">
               <p className="payment_method-title">Payment Methods</p>
               <div className="payment_methods">
                  {contactDetails?.disable_imepay === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "imepay" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("imepay")}
                     >
                        <img src={ime} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_esewa === false && (
                     <div
                        className="payment_options"
                        style={
                           paymentMethod === "esewa"
                              ? { border: "2px solid red" }
                              : { border: "2px solid #E6EAF0" }
                        }
                        onClick={() => setPaymentMethod("esewa")}
                     >
                        <img src={esewa} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_khalti === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "khalti" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("khalti")}
                     >
                        <img src={khalti} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_connectips === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "connectips" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("connectips")}
                     >
                        <img src={ips} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_prabhupay === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "prabhupay" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("prabhupay")}
                     >
                        <img src={prabhu} alt="" />
                     </div>
                  )}
               </div>
               <button className="pay_now my-3" onClick={() => handlePayment()}>
                  Pay Now
               </button>
            </div>
         </Modal>
      </>
   );
}

export function Order({
   order,
   setTotal,
   setOrderId,
   setPaymentModal,
   setActiveOrder,
   setOrderDetailsModal,
}) {
   return (
      <>
         <div className="order_card">
            <div className="order_card_details">
               <p className="orderId">Order {order?.order_id?.toUpperCase()}</p>
               <p className="date">
                  <FaRegCalendarAlt size={19} color={"#596979"} />{" "}
                  <span>{format(new Date(order?.created_at), "dd MMM yyyy H:mm aa")}</span>
               </p>
               <p
                  className={
                     order?.payment_method !== "cod" &&
                     !order?.is_paid &&
                     order?.status !== "cancelled" &&
                     order?.status !== "completed"
                        ? "status"
                        : order?.status?.toLowerCase() === "completed"
                        ? "status delivered"
                        : order?.status?.toLowerCase() === "new"
                        ? "status pending"
                        : order?.status?.toLowerCase() === "cancelled"
                        ? "status cancelled"
                        : order?.status?.toLowerCase() === "processing"
                        ? "status confirmed"
                        : "status"
                  }
               >
                  <div className="status_indicator"></div>
                  {order?.payment_method !== "cod" &&
                  !order?.is_paid &&
                  order?.status?.toLowerCase() !== "cancelled" &&
                  order?.status?.toLowerCase() !== "completed" ? (
                     <p>
                        Payment Pending.{" "}
                        {process.env.REACT_APP_ONLINE_PAYMENT_STATUS === "true" && (
                           <span
                              className="cursor_pointer"
                              style={{ color: "#ED1C24" }}
                              onClick={() => {
                                 setTotal(order?.total);
                                 setOrderId(order?.order_id);
                                 setPaymentModal(true);
                              }}
                           >
                              {" "}
                              Pay Now
                           </span>
                        )}
                     </p>
                  ) : (
                     capitalize(order?.status?.toLowerCase())
                  )}
               </p>
            </div>

            <div
               className="order_card_view_order cursor_pointer"
               onClick={() => {
                  setActiveOrder(order);
                  setOrderDetailsModal(true);
               }}
            >
               <MdOutlineDirections size={22} />
               View Order
            </div>
         </div>
      </>
   );
}
export function InternetFeaturesTab({ title, desc }) {
   return (
      <div className="internet_item_tab">
         <p className="internet_item_tab_text">{title}</p>
         <p className="internet_item_tab_text">{desc}</p>
      </div>
   );
}
export function CancleOrder({ id, type, show, setShow, cancleErrorModal, setCancleErrorModal }) {
   const Cancle = async () => {
      const response = await cancleOrder(type, id);
      if (response.status === 200) setShow(false);
      else {
         setShow(false);
         setCancleErrorModal(true);
      }
   };
   return (
      <>
         <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body" style={{ textAlign: "center" }}>
               <p className="cartdelete-title">Do you want to cancel the order ?</p>
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               <Button
                  variant="primary"
                  onClick={() => {
                     Cancle();
                  }}
                  id="removecart"
               >
                  Yes
               </Button>
               <Button variant="secondary" onClick={() => setShow(false)} id="cancel">
                  No
               </Button>
            </Modal.Footer>
         </Modal>
         <MessageModal
            type={"error"}
            show={cancleErrorModal}
            setShow={setCancleErrorModal}
            heading={"Oops!! Something went Wrong"}
            buttonText={"Ok"}
         />
      </>
   );
}

export function OrderDetails({
   activeOrder,
   orderDetailsModal,
   setOrderDetailsModal,
   setTotal,
   setOrderId,
   setPaymentModal,
   setCancleModal,
}) {
   const [activeprice, setActiveprice] = useState("");
   let priceplan = null;
   activeOrder &&
      Object.entries(activeOrder?.package_id?.prices).map(([key, value]) => {
         if (value?.type === activeOrder?.payment_type) {
            // console.log(value);
            priceplan = value;
            // setActiveprice(value);
            return null;
         }
      });
   // console.log("price", activeOrder);
   let vatAmount;
   let totalinternetcharge;

   if (priceplan?.drop_wire || priceplan?.router) {
      totalinternetcharge =
         priceplan?.installation_charge +
         priceplan?.internet_price +
         priceplan?.router +
         priceplan?.drop_wire;
      if (priceplan?.itv_price) {
         totalinternetcharge =
            priceplan?.installation_charge +
            priceplan?.internet_price +
            priceplan?.router +
            priceplan?.drop_wire +
            priceplan?.itv_price;
      }
      if (priceplan?.itv_stb_charge) {
         totalinternetcharge =
            priceplan?.installation_charge +
            priceplan?.internet_price +
            priceplan?.router +
            priceplan?.drop_wire +
            priceplan?.itv_stb_charge;
      }
      if (priceplan?.itv_stb_charge && priceplan?.itv_price) {
         totalinternetcharge =
            priceplan?.installation_charge +
            priceplan?.internet_price +
            priceplan?.router +
            priceplan?.drop_wire +
            priceplan?.itv_stb_charge +
            priceplan?.itv_price;
      }
   } else {
      totalinternetcharge =
         priceplan?.installation_charge + priceplan?.price + priceplan?.settopbox_price;
   }
   // console.log("vat", totalinternetcharge);

   vatAmount = 0.13 * activeOrder?.sub_total;
   // console.log("vat", vatAmount);
   return (
      <Modal show={orderDetailsModal} onHide={() => setOrderDetailsModal(false)} size={"xl"}>
         <div className="orderdetails_modal">
            <div className="order_Id_details">
               <p className="m-0 d-flex align-items-center">
                  {" "}
                  Order ID :{" "}
                  <span className="id_red ms-1">{activeOrder?.order_id?.toUpperCase()}</span>
               </p>
               <div
                  className={
                     activeOrder?.status === "completed"
                        ? "status_text delivered"
                        : activeOrder?.status === "new"
                        ? "status_text pending"
                        : activeOrder?.status === "cancelled"
                        ? "status_text cancelled"
                        : activeOrder?.status === "processing"
                        ? "status_text confirmed"
                        : "status_text"
                  }
               >
                  {activeOrder?.status?.toUpperCase()}
               </div>
            </div>
            <div className="orderdetails_modal_body">
               <div className="ordersummaryanddelivery">
                  <div className="orderSummary">
                     <div className="orderSummary_Heading">Order Summary</div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Order Created</p>
                        <p className="details_row_description">
                           {activeOrder?.created_at &&
                              format(new Date(activeOrder?.created_at), "EE, MMM dd, yyyy")}
                        </p>
                     </div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Order Time </p>
                        <p className="details_row_description">
                           {activeOrder?.created_at &&
                              format(new Date(activeOrder?.created_at), "hh:mm aa")}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Payment Method </p>
                        <p className="details_row_description">
                           {" "}
                           {activeOrder?.payment_method?.toUpperCase()}{" "}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Payment Status </p>
                        <p
                           className={
                              activeOrder?.is_paid
                                 ? "details_row_description paid"
                                 : "details_row_description unpaid"
                           }
                        >
                           {activeOrder?.is_paid ? "Paid" : "Not Paid"}
                        </p>
                     </div>{" "}
                     {/* <div className="orderSummary_details_row">
                        <p className="details_row_title">Subtotal </p>
                        <p className="details_row_description">
                           {" "}
                           Rs. {activeOrder?.sub_total?.toLocaleString("hi-IN")}
                        </p>
                     </div>{" "} */}
                     {/* <div className="orderSummary_details_row">
                        <p className="details_row_title">Delivery Fee </p>
                        <p className="details_row_description">
                           {" "}
                           Rs. {activeOrder?.shipping_price?.toLocaleString("hi-IN")}
                        </p>
                     </div> */}
                  </div>
                  <div className="deliveryDetails">
                     <div className="deliveryDetails_Heading">Installation Address</div>
                     {/* <div className="delevery_details_row">
                        <p className="details_row_title">
                           Province: {activeOrder?.shipping_address?.province}
                        </p>
                     </div> */}
                     <div className="delevery_details_row">
                        {activeOrder?.shipping_address?.district && (
                           <p className="details_row_title">
                              District: {activeOrder?.shipping_address?.district}
                           </p>
                        )}
                     </div>
                     <div className="delevery_details_row">
                        {activeOrder?.shipping_address?.address && (
                           <p className="details_row_title">
                              Address: {activeOrder?.shipping_address?.address}
                           </p>
                        )}
                     </div>
                     {/* <div className="delevery_details_row">
                        <p className="details_row_title">
                           Landmark: {activeOrder?.shipping_address?.landmark}
                        </p>
                     </div> */}
                  </div>
               </div>
               <div className="orderdetails">
                  <div className="item_summary">
                     <div className="itemSummary_Heading">Items Summary</div>
                     <div className="internet_items">
                        <InternetFeaturesTab
                           title="Package Name"
                           desc={activeOrder?.package_name}
                        />
                        <InternetFeaturesTab
                           title="Package Type"
                           desc={activeOrder?.package_type && capitalize(activeOrder?.package_type)}
                        />
                        <InternetFeaturesTab
                           title="Duration"
                           desc={activeOrder?.payment_type && capitalize(activeOrder?.payment_type)}
                        />
                        {priceplan?.speed && (
                           <InternetFeaturesTab
                              title="Speed"
                              desc={
                                 activeOrder?.package_id?.speed && activeOrder?.package_id?.speed
                              }
                           />
                        )}
                        {/* {priceplan?.multiple ? (
                           <InternetFeaturesTab
                              title="No. of Tv"
                              desc={priceplan && priceplan?.multiple}
                           />
                        ) : null} */}
                        {activeOrder?.no_of_tv && activeOrder?.no_of_tv > 0 ? (
                           <InternetFeaturesTab
                              title="No. of Tv"
                              desc={activeOrder && activeOrder?.no_of_tv}
                           />
                        ) : null}

                        {activeOrder?.additional ? (
                           <InternetFeaturesTab
                              title="Additional Charge"
                              desc={activeOrder && activeOrder?.additional}
                           />
                        ) : null}

                        {priceplan?.drop_wire ? (
                           <InternetFeaturesTab
                              title="Drop wire Charge"
                              desc={priceplan && priceplan.drop_wire?.toLocaleString("hi-IN")}
                           />
                        ) : null}

                        {priceplan?.router ? (
                           <InternetFeaturesTab
                              title="Router rental Charge"
                              desc={priceplan && priceplan?.router?.toLocaleString("hi-IN")}
                           />
                        ) : null}
                        {priceplan?.internet_price ? (
                           <InternetFeaturesTab
                              title="Internet price"
                              desc={priceplan && priceplan?.internet_price?.toLocaleString("hi-IN")}
                           />
                        ) : null}
                        {priceplan?.settopbox_price ? (
                           <InternetFeaturesTab
                              title="Set Top Box Price"
                              desc={
                                 priceplan && priceplan?.settopbox_price?.toLocaleString("hi-IN")
                              }
                           />
                        ) : null}

                        {priceplan?.price ? (
                           <InternetFeaturesTab
                              title="Subscription Charge"
                              desc={priceplan && priceplan?.price?.toLocaleString("hi-IN")}
                           />
                        ) : null}

                        <InternetFeaturesTab
                           title="Installation Charge"
                           desc={
                              priceplan && priceplan?.installation_charge?.toLocaleString("hi-IN")
                           }
                        />
                        {priceplan?.itv_price ? (
                           <InternetFeaturesTab
                              title="TV Charge"
                              desc={priceplan && priceplan?.itv_price?.toLocaleString("hi-IN")}
                           />
                        ) : null}
                        {priceplan?.itv_stb_charge ? (
                           <InternetFeaturesTab
                              title="TV STB Charge"
                              desc={priceplan && priceplan?.itv_stb_charge?.toLocaleString("hi-IN")}
                           />
                        ) : null}
                        {activeOrder?.discount ? (
                           <InternetFeaturesTab
                              title="Discount"
                              desc={
                                 activeOrder &&
                                 Math.floor(activeOrder?.discount)?.toLocaleString("hi-IN")
                              }
                           />
                        ) : null}
                        <hr />
                        {activeOrder?.sub_total ? (
                           <InternetFeaturesTab
                              title="Sub Total"
                              desc={
                                 activeOrder &&
                                 Math.floor(activeOrder?.sub_total)?.toLocaleString("hi-IN")
                              }
                           />
                        ) : null}
                        <InternetFeaturesTab
                           title="VAT (13%)"
                           desc={Math.round(vatAmount)?.toLocaleString("hi-IN")}
                        />
                     </div>

                     {/* <div className="viewPackage">
                        <a
                           className="viewPakcageLink"
                           href={
                              activeOrder?.package_type?.toLowerCase() === "tv"
                                 ? `/tv/${activeOrder?.package_id}`
                                 : `/internet/${activeOrder?.package_id}`
                           }
                        >
                           View Package <AiOutlineDoubleRight style={{ marginLeft: "3px" }} />
                        </a>
                     </div> */}
                  </div>
                  <div className="totalandButtomGroup">
                     <div className="total">
                        Total:{" "}
                        <span>Rs. {Math.floor(activeOrder?.total)?.toLocaleString("hi-IN")}</span>
                     </div>
                     <div className="buttonGroup">
                        {!activeOrder?.is_paid &&
                           activeOrder?.payment_method !== "cod" &&
                           activeOrder?.status !== "cancelled" &&
                           activeOrder?.status !== "completed" &&
                           process.env.REACT_APP_ONLINE_PAYMENT_STATUS === "true" && (
                              <div
                                 className="button paynow"
                                 onClick={() => {
                                    setTotal(activeOrder?.total);
                                    setOrderId(activeOrder?.order_id);
                                    setPaymentModal(true);
                                 }}
                              >
                                 Pay Now
                              </div>
                           )}
                        {activeOrder?.status?.toLowerCase() === "new" && (
                           <div
                              className="button cancel_btn"
                              onClick={() => {
                                 setOrderId(activeOrder?.order_id);
                                 setCancleModal(true);
                                 setOrderDetailsModal(false);
                              }}
                           >
                              Cancel Order
                           </div>
                        )}
                        <div
                           className="button"
                           onClick={() => {
                              setOrderDetailsModal(false);
                           }}
                        >
                           Close
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   );
}
