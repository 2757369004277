import { React, useState, useEffect } from "react";
import Layout from "../../components/include/layout";
// import DhInfoBanner from "../../components/customcomponents/dhinfobanner";
// import Faq from "../../components/customcomponents/faq";
import CurrentMovies from "./currentmovies";
import MovieInfo from "./movieinfo";
import { useParams } from "react-router";
import { get_single_ppv_movie } from "../../data/api";
import Loading from "../../components/customcomponents/loading";
import Token from "../../utils/useToken";
import Error from "../../components/pages/404error";
import { IoIosArrowForward } from "react-icons/io";

const IndividualMovie = () => {
   const id = useParams().movieid;
   const [movie, setMovie] = useState("");
   const [empty, setEmpty] = useState(true);
   const { token } = Token();
   const UserDetails = token?.user;
   useEffect(() => {
      async function get_movie() {
         const response = await get_single_ppv_movie(id);
         setMovie(response?.data?.data);
      }
      get_movie();
   }, [empty, id]);

   useEffect(() => {
      if (movie === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   // console.log(movie);

   return empty ? (
      <Loading />
   ) : !movie ? (
      <Error />
   ) : (
      <Layout>
         {UserDetails?.is_kyc_verified === false ? (
            <div
               className="ott_top"
               style={{
                  backgroundColor: "#1A63F4",
                  height: "fit-content",
                  textAlign: "center",
                  color: "#fff",
               }}
            >
               <p
                  style={{
                     color: "#FDF8F2",
                     fontSize: "14px",
                     fontWeight: "400",
                     lineHeight: "16px",
                     paddingTop: "15px",
                     marginBottom: "8px",
                  }}
               >
                  Please Complete your information for using this PPV feature with DishHome. It will
                  take 2 minutes to fill the form
               </p>
               <a
                  href="/dashboard?type=1"
                  style={{
                     textDecoration: "none",
                     fontSize: "14px",
                     color: "#fff",
                     marginBottom: "6px",
                  }}
               >
                  Do it now <IoIosArrowForward />
               </a>
            </div>
         ) : null}
         <MovieInfo data={movie} />
         <CurrentMovies heading={"You May Like"} />
      </Layout>
   );
};

export default IndividualMovie;
