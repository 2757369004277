import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import "../../scss/components/quicklink.scss";
import dishhome from "../../assets/dishhome.webp";
import { Formik } from "formik";
import * as yup from "yup";
import { get_company_details, get_hot_deals, newsletter } from "../../data/api";
import buy from "../../assets/buy.webp";
import learn from "../../assets/learn.png";
import MessageModal from "./messageModal";
import Token from "../../utils/useToken";
import LoginModal from "../pages/loginmodal";
import { update_cart_items } from "../../data/protectedapi";
import Captcha from "../include/captcha";

export default function QuickLink({ cartupdate, setCartupdate, internetPackage, career }) {
   const [empty, setEmpty] = useState(true);
   const [email, setEmail] = useState("");
   const [show, setShow] = useState(false);
   const [message, setMessage] = useState("");
   const [error, setError] = useState(false);
   const [hotProduct, setHotProduct] = useState("");
   const [homeData, setHomeData] = useState("");
   const [loginModalState, setLoginModalState] = useState(false);
   const [heading, setHeading] = useState("");
   const handleShow = () => setShow(true);
   const reRef = useRef();

   const { userDetails, token } = Token();

   useEffect(() => {
      async function get_hotdeals() {
         const res = await get_hot_deals();
         const response = res?.data?.data;
         setHotProduct(response);
      }
      get_hotdeals();
   }, []);
   // useEffect(() => {
   //    setFeaturePrduct(homeData.product);
   // }, [featurePrduct]);
   // console.log(featurePrduct);
   const [newsletterSuccess, setNewsletterSuccess] = useState(false);
   const [newsletterError, setNewsletterError] = useState(false);
   const [newsLetterMessage, setNewsLetterMessage] = useState("");

   const handleSubmit = async (values) => {
      const captchaToken = await reRef?.current?.executeAsync();
      const response = await newsletter(values.email, captchaToken);
      if (response.status === 201) {
         setNewsletterSuccess(true);
         setEmail("");
         setNewsLetterMessage(response?.data?.message);
      } else if (response?.response?.status === 409) {
         // console.log("error", response?.response);
         setNewsLetterMessage(response?.response?.data?.message);

         setNewsletterError(true);
      } else {
         // console.log("error", response?.response);

         setNewsLetterMessage(response?.response?.data?.errors[0]?.messages[0]);
         setNewsletterError(true);
      }
   };
   const handleKeySubmit = async (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         const response = await newsletter(email);

         if (response.status === 201) {
            setNewsletterSuccess(true);
            setEmail("");
            setNewsLetterMessage(response?.data?.message);
         } else {
            // console.log("error", response?.response);
            setNewsLetterMessage(response?.response?.data?.errors[0]?.messages[0]);
            setNewsletterError(true);
         }
      }
   };
   const [qty, setQty] = useState(1);
   const [cartError, setCartError] = useState(false);
   const [cartMessage, setCartMessage] = useState("");
   const handleCart = async (id, stock_qty) => {
      if (stock_qty <= 0) {
         setError(true);

         setMessage("Out of Stock");
      } else {
         const response = await update_cart_items(id, qty);
         if (response?.status === 200) {
            setCartupdate(!cartupdate);
            setShow(true);
         } else {
            setCartError(true);
            setCartMessage(response?.response?.data?.message);
         }
      }
   };
   //formik
   const schema = yup.object().shape({
      email: yup.string().required("Email is required").email("Invalid Email"),
   });

   //React Router Hooks
   let navigate = useNavigate();

   //Store

   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);

   // console.log(internetPackage);

   return (
      <div className="quicklink">
         <Captcha reRef={reRef} />
         <MessageModal
            show={newsletterSuccess}
            setShow={setNewsletterSuccess}
            heading={newsLetterMessage ? "Newsletter Subscribed !" : "Newsletter Subscribed !"}
            message={"Thank You "}
            buttonText={"Ok"}
         />
         <MessageModal
            type={"error"}
            show={cartError}
            setShow={setCartError}
            message={cartMessage}
            buttonText={"Ok"}
            link="/login"
         />
         <MessageModal
            show={show}
            setShow={setShow}
            heading={message ? message : "Product Added to cart"}
            message={"Thank You "}
            buttonText={"Ok"}
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={heading}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         <MessageModal
            show={newsletterError}
            setShow={setNewsletterError}
            heading={heading}
            message={newsLetterMessage}
            buttonText={"Ok"}
            type={"error"}
         />
         <div className="container fixed-width">
            <div className="quicklink__container">
               <div className="footer__dishhome ">
                  <img src={dishhome} alt="dishhome" style={{ height: "230px", width: "250px" }} />
                  <p className="touch">Lets stay in touch!</p>
                  <p className="description">
                     Join our newsletter, so that we can reach out to you with our best news and
                     offers!
                  </p>
                  <Formik
                     validationSchema={schema}
                     initialValues={{
                        email: "",
                     }}
                     onSubmit={(values, actions) => {
                        handleSubmit(values);
                     }}
                  >
                     {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form
                           onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                 e.preventDefault();
                                 handleSubmit();
                              }
                           }}
                           style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                           }}
                        >
                           <input
                              // value={email}
                              // onChange={(e) => setEmail(e.target.value)}
                              onChange={handleChange}
                              name="email"
                              value={values?.email}
                              placeholder="Enter your email"
                              type="email"
                              // onKeyPress={(e) => handleKeySubmit(e)}
                              className={errors?.email ? "password_input error" : "Password_input "}
                              style={{ width: "100%" }}
                           />
                           {errors?.email ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.email}
                              </p>
                           ) : null}
                           <Button variant="secondary" className="subscribe" onClick={handleSubmit}>
                              Subscribe
                           </Button>
                        </Form>
                     )}
                  </Formik>
               </div>
               <div className="footer__links__container">
                  <div className="footer__links">
                     <ul className="quicklink_link">
                        <li className="link_title cursor_normal">Quick Links</li>
                        {contactDetails && contactDetails?.disable_store === false && (
                           <li
                              className="link_list cursor_pointer"
                              onClick={() => {
                                 navigate("/store");
                              }}
                           >
                              Store
                           </li>
                        )}
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/tv");
                           }}
                        >
                           TV Packages
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/internet/residential");
                           }}
                        >
                           Internet Packages
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/ppv-movies");
                           }}
                        >
                           PPV
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/dishhome-go");
                           }}
                        >
                           {" "}
                           DishHome Go
                        </li>
                     </ul>
                     <ul className="quicklink_link">
                        <li className="link_title cursor_normal">Services</li>
                        {/* <li className="link_list cursor_pointer" onClick={() => {
                              navigate("/pages/plansandpricing");
                           }}>Plans & Pricing</li> */}
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/privacy-policy");
                           }}
                        >
                           Privacy Policy
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => navigate("/pages/termsandcondition")}
                        >
                           Terms & Conditions
                        </li>
                        {/* <li className="link_list cursor_pointer">Packages</li> */}
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/internet/refer");
                           }}
                        >
                           Refer
                        </li>
                     </ul>
                  </div>
                  <div className="footer__links">
                     <ul className="quicklink_link">
                        <li className="link_title cursor_normal">Company</li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/company/about-us");
                           }}
                        >
                           About Us
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/video-tutorial");
                           }}
                        >
                           Video Tutorials
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              window.open("https://dishhome.rigojobs.com/", "_blank");
                           }}
                        >
                           Career {career > 0 && <span>We’re hiring!</span>}
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/company/faqs");
                           }}
                        >
                           FAQs
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/downloads");
                           }}
                        >
                           {" "}
                           Downloads
                        </li>
                     </ul>
                     <ul className="quicklink_link">
                        <li className="link_title cursor_normal">Contact</li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/pages/headoffice");
                           }}
                        >
                           Head Office
                        </li>
                        {/* {contactDetails && contactDetails?.disable_store === false && (
                           <li
                              className="link_list cursor_pointer"
                              onClick={() => {
                                 navigate("/store");
                              }}
                           >
                              Store
                           </li>
                        )} */}
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/company/support");
                           }}
                        >
                           Support
                        </li>
                        <li
                           className="link_list cursor_pointer"
                           onClick={() => {
                              navigate("/locate");
                           }}
                        >
                           Locate Dealer
                        </li>
                     </ul>
                  </div>
               </div>
               <div className="footer__offer">
                  <p className="latest_offer">Latest Offers</p>
                  <div className="footer__offer_img">
                     {contactDetails &&
                        contactDetails?.disable_store === false &&
                        hotProduct &&
                        Object.entries(hotProduct).map(([key, value]) => {
                           return (
                              <>
                                 <div
                                    className="d-flex flex-column"
                                    // style={{ height: "380px" }}
                                 >
                                    {key === "0" ? (
                                       <>
                                          <LoginModal
                                             Show={loginModalState}
                                             setShow={setLoginModalState}
                                             link={"/store/" + value._id}
                                          />
                                          <div>
                                             <img
                                                src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                                                height="200px"
                                                width="100%"
                                                alt="product"
                                                style={{
                                                   objectFit: "contain",
                                                   marginBottom: "8px",
                                                   marginTop: "8px",
                                                }}
                                             />
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                             <p id="quick-buy" className="mb-1 mt-3">
                                                Buy a {value.name} for
                                             </p>
                                             <p id="quick-price" className="mb-2">
                                                Rs. {value.price.toLocaleString("hi-IN")}
                                             </p>

                                             <img
                                                id="buynow_footer"
                                                src={buy}
                                                alt="buyicon"
                                                style={{
                                                   cursor: "pointer",
                                                   marginTop: "10px",
                                                   marginRight: "6px",
                                                }}
                                                onClick={() => {
                                                   if (userDetails === undefined) {
                                                      setLoginModalState(true);
                                                   } else {
                                                      handleCart(value._id, value.stock_quantity);
                                                   }
                                                }}
                                             />
                                             <img
                                                src={learn}
                                                alt="learnicon"
                                                id="learnmore_footer"
                                                style={{ marginTop: "10px", cursor: "pointer" }}
                                                onClick={() => {
                                                   navigate("/store/" + value._id);
                                                }}
                                             />
                                          </div>
                                       </>
                                    ) : null}
                                 </div>
                              </>
                           );
                        })}
                     {contactDetails &&
                        contactDetails?.disable_store === true &&
                        internetPackage && (
                           <div
                              className="d-flex flex-column"
                              //    style={{ height: "380px" }}
                           >
                              <LoginModal
                                 Show={loginModalState}
                                 setShow={setLoginModalState}
                                 link={`/internetpackage/order/${internetPackage[0]?._id}?type=${internetPackage[0]?.prices[3]?.type}`}
                              />
                              <div>
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${internetPackage[0]?.image}`}
                                    height="200px"
                                    width="100%"
                                    alt="product"
                                    style={{
                                       objectFit: "contain",
                                       marginBottom: "8px",
                                       marginTop: "8px",
                                    }}
                                 />
                              </div>
                              <div style={{ textAlign: "center" }}>
                                 <p id="quick-buy" className="mb-1 mt-3">
                                    Buy {internetPackage[0].title} for
                                 </p>
                                 <p id="quick-price" className="mb-2">
                                    Rs.{" "}
                                    {`${internetPackage[0]?.prices[3]?.internet_price.toLocaleString(
                                       "hi-IN",
                                    )}* / `}{" "}
                                    <sub> {internetPackage[0]?.prices[3]?.type}</sub>
                                 </p>
                                 <img
                                    id="buynow_footer"
                                    src={buy}
                                    alt="buyicon"
                                    style={{
                                       cursor: "pointer",
                                       marginTop: "10px",
                                       marginRight: "6px",
                                    }}
                                    onClick={() => {
                                       if (userDetails === undefined) {
                                          // console?.log("hello");
                                          setLoginModalState(true);
                                       } else {
                                          navigate(
                                             `/internetpackage/order/${internetPackage[0]?._id}?type=${internetPackage[0]?.prices[3]?.type}`,
                                          );
                                       }
                                    }}
                                 />
                                 <img
                                    src={learn}
                                    alt="learnicon"
                                    id="learnmore_footer"
                                    style={{ marginTop: "10px", cursor: "pointer" }}
                                    onClick={() => {
                                       navigate("/internet/" + internetPackage[0]?._id);
                                    }}
                                 />
                              </div>
                           </div>
                        )}
                  </div>
                  {/* <img src={iphonelink} width="100%" height="380px" alt="iphone"></img> */}
               </div>
            </div>
         </div>
      </div>
   );
}
