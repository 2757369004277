import React, { useEffect, useState } from "react";
// import { Row, Col } from "react-bootstrap";
import "../../scss/components/channels.scss";
import { get_all_channel } from "../../data/api";
import Widthcontainer from "./widthcontainer";

export default function Channels({ data }) {
   //useState
   const [channelsdata, setchannelsData] = useState("");

   //useEffet Hooks
   useEffect(() => {
      async function get_channels() {
         const res = await get_all_channel();
         const channeldata = res.data.data;
         setchannelsData(channeldata);
      }
      get_channels();
   }, []);
   //Console Log

   // console.log("Channel", data);

   return (
      <div className="channels mt-5">
         <p className="channels_title">
            Exclusive <span className="available">Channels</span> on DishHome.
         </p>
         {/* <div style={{ textAlign: "center" }}>
            <a href="/" className="channels_link">
               See All Channels <BsArrowRightShort size="24px" />
            </a>
         </div> */}
         <Widthcontainer>
            <div className="channel_container fixed-width " style={{ flexWrap: "wrap" }}>
               {Object.entries(data).map(([key, value]) => {
                  return (
                     // <div className="channel_child" style={key > 9 ? { opacity: "0.3" } : {}}>
                     <div className="channel_child">
                        <ChannelName img={value.image} />
                     </div>
                  );
               })}
            </div>
         </Widthcontainer>
         {/* <Widthcontainer>
            <div
               className="channel_container fixed-width "
               style={{ marginTop: "20px", marginBottom: "20px" }}
            >
               {Object.entries(channelsdata).map(([key,value]) => {
                  return (
                     <div>
                        <div className="channel_box">
                           {console.log(value.image)}
                           <img src={value.image} alt="img"></img>
                        </div>
                     </div>
                  );
               })}
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
            </div>
         </Widthcontainer>
         <Widthcontainer>
            <div className="channel_container fixed-width " style={{ opacity: "0.3" }}>
               {Object.entries(channelsdata).map(([key, value]) => {
                  return (
                     <div>
                        <div className="channel_box">
                           {console.log(value.image)}
                           <img src={value.image} alt="img"></img>
                        </div>
                     </div>
                  );
               })}

               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
               <div className="channel_child">
                  <ChannelName img={channel} />
               </div>
            </div>
         </Widthcontainer> */}
      </div>
   );
}

export function ChannelName(props) {
   return (
      <div className="channel_box">
         <img
            src={`${process.env.REACT_APP_FILE_URL}/${props.img}`}
            alt=""
            style={{ objectFit: "contain", width: "125px", height: "75px" }}
         ></img>
      </div>
   );
}
