import React, { useState, useEffect, useRef } from "react";
import { BiShareAlt } from "react-icons/bi";
import "../scss/components/shareBtnPopup.scss";
import {
   FacebookShareButton,
   FacebookIcon,
   TwitterShareButton,
   TwitterIcon,
   LinkedinShareButton,
   LinkedinIcon,
   TelegramShareButton,
   TelegramIcon,
   WhatsappShareButton,
   WhatsappIcon,
} from "react-share";

export const ShareBtnPopup = ({ text, type }) => {
   const [popupStatus, setPopupStatus] = useState(false);

   const ref = useRef(null);

   useEffect(() => {
      const handleClickOutside = (event) => {
         if (ref.current && !ref.current.contains(event.target)) {
            setPopupStatus(false);
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, [setPopupStatus]);

   return (
      <div
         className={
            type === "icon"
               ? "share_button_container _big_btn_icon_container"
               : "share_button_container"
         }
         ref={ref}
      >
         <div className={"share_icon"} onClick={() => setPopupStatus(!popupStatus)}>
            <BiShareAlt size={type === "icon" ? 24 : 18} style={{ marginRight: "4px" }} /> {text}
         </div>
         {/* {console.log("Window.href", window.location.href)} */}
         {popupStatus && (
            <div className="share_popup">
               <div className="share_popup_item">
                  <FacebookShareButton
                     url={window.location.href}
                     quote={"Share this post"}
                     hashtag="#DishHome #DishHomeNepal"
                  >
                     <FacebookIcon size={32} round />
                  </FacebookShareButton>
               </div>
               <div className="share_popup_item">
                  <TwitterShareButton
                     url={window.location.href}
                     quote={"Share this post"}
                     hashtag="#DishHome #DishHomeNepal"
                  >
                     <TwitterIcon size={32} round />
                  </TwitterShareButton>
               </div>
               <div className="share_popup_item">
                  <LinkedinShareButton
                     url={window.location.href}
                     quote={"Share this post"}
                     hashtag="#DishHome #DishHomeNepal"
                  >
                     <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
               </div>
               <div className="share_popup_item">
                  <TelegramShareButton
                     url={window.location.href}
                     quote={"Share this post"}
                     hashtag="#DishHome #DishHomeNepal"
                  >
                     <TelegramIcon size={32} round />
                  </TelegramShareButton>
               </div>
               <div className="share_popup_item">
                  <WhatsappShareButton
                     url={window.location.href}
                     quote={"Share this post"}
                     hashtag="#DishHome #DishHomeNepal"
                  >
                     <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
               </div>
            </div>
         )}
      </div>
   );
};
