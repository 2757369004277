import React from "react";
import Header from "./header";
import Layout from "../../../include/layout";
import SeoTags from "../../../../utils/seoTags";

export default function Wrapper() {
   return (
      <Layout>
         {SeoTags(
            "Compare | DishHome Nepal",
            "DishHome Nepal Compare",
            "",
            "Internet Compare",
            "",
            `${process.env.REACT_APP_PUBLIC_URL}internet/compare`,
         )}
         <Header />
         {/* <Pricingtable/> */}
      </Layout>
   );
}
