import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router";
import { check_balance, update_profile } from "../data/protectedapi";

export default function Token() {
   // let navigate = useNavigate();
   const [expired, setExpired] = useState(false);
   const [deleteUser, setDeleteUser] = useState(false);

   const getToken = () => {
      const flag = localStorage?.getItem("remember");
      let tokenString;
      if (flag === "true") {
         tokenString = localStorage.getItem("user");
      } else {
         tokenString = localStorage.getItem("user");
      }
      const userToken = JSON.parse(tokenString);
      const expire = new Date(userToken?.expiresIn);
      const now = new Date();

      if (now > expire) {
         userToken && setExpired(true);
         localStorage.removeItem("user");
         localStorage.removeItem("remember");
         // navigate("/login");
         return null;
      } else {
      }
      return userToken;
   };

   const [token, setToken] = useState(getToken());

   const saveToken = (token, flag) => {
      localStorage.setItem("remember", flag);
      if (flag === true) {
         localStorage.setItem("user", JSON.stringify(token));
      } else {
         localStorage.setItem("user", JSON.stringify(token));
      }
      setToken(token);
   };

   const clearToken = () => {
      localStorage.removeItem("user");
      sessionStorage.removeItem("user");
      localStorage.removeItem("remember");
      setToken(null);
   };

   const clearSessionToken = () => {
      const flag = localStorage?.getItem("remember");
      const sessions = localStorage.getItem("sessions");
      if (flag === "false" && sessions !== null && parseInt(sessions) < 1) {
         localStorage.removeItem("user");
         localStorage.removeItem("remember");
      }
      setToken(null);
   };

   const CheckUser = async () => {
      const response = await update_profile();
      // const response = await check_balance();
      if (response?.status === 200) {
         setDeleteUser(false);
      } else {
         clearToken();
         setDeleteUser(true);
      }
   };
   return {
      setToken: saveToken,
      token,
      clearToken,
      userDetails: token?.user,
      clearSessionToken: clearSessionToken,
      expired: expired,
      setExpired: setExpired,
      CheckUser: deleteUser,
      setCheckUser: setDeleteUser,
      isUser: CheckUser,
   };
}
