import React from "react";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import NextMovieCard from "../ppvmovies/pages/nextmoviecard";
import "../scss/ott/ottMoviesSlide.scss";

import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
// import Slider from "react-slick";

const OttMoviesSlide = () => {
   return (
      <Widthcontainer className={"ottmovieSlide__widthContainer"}>
         <div className="ottmovieSlide__container">
            <h2 className="ottmovieSlide__heading">PPV Movies</h2>
            <p className="ottmovieSlide__text">
               PPV Movies is a collection of Pay Per View movies which has 100+ Nepali and
               International movies
            </p>
            <OttMovieSlider />
         </div>
      </Widthcontainer>
   );
};

function OttMovieSlider() {
   const PreviousBtn = (props) => {
      const { className, onClick } = props;
      return (
         <div className={className} onClick={onClick}>
            <ArrowBackIos
               style={{
                  color: "#596579",
                  fontSize: "20px",
                  border: "1px solid #596579",
                  borderRadius: "25px",
               }}
            />
         </div>
      );
   };
   const NextBtn = (props) => {
      const { className, onClick } = props;
      return (
         <div className={className} onClick={onClick}>
            <ArrowForwardIos
               style={{
                  color: "#596579",
                  fontSize: "20px",
                  border: "1px solid #596579",
                  borderRadius: "25px",
               }}
            />
         </div>
      );
   };

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      innerWidth: "100%",
      outerWidth: "100%",
   };
   return (
      <div className="ottmovieSlide__slider">
         <NextMovieCard />
         <NextMovieCard />
         <NextMovieCard />
         <NextMovieCard />
      </div>
   );
}

export default OttMoviesSlide;
