import React, { useEffect } from "react";
import Layout from "../include/layout";
import ErrorPage from "./errorPage";
import Token from "../../utils/useToken";

const DevToolError = () => {
   const { clearToken } = Token();
   window.onload = () => {
      clearToken();
      // debugger;
   };
   return (
      <Layout>
         <ErrorPage />
      </Layout>
   );
};

export default DevToolError;
