import React, { useState, useEffect, useRef } from "react";
import { cancleOrder, get_orders, post_review } from "../data/protectedapi";
import "../scss/components/order.scss";
import Token from "../utils/useToken";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import ReactStars from "react-rating-stars-component";
import MessageModal from "../components/customcomponents/messageModal";
import khalti from "../assets/cart/khalti.png";
import esewa from "../assets/store/esewa.webp";
import ips from "../assets/store/ips.png";
import prabhu from "../assets/store/prabhu.png";
import ime from "../assets/store/ime.png";
import { handleOnlinePayment } from "../components/pages/payment/payment";
import KhaltiCheckout from "khalti-checkout-web";
import { get_company_details } from "../data/api";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineDirections } from "react-icons/md";
import { capitalize } from "@mui/material";
import Captcha from "../components/include/captcha";
import { useMediaQuery } from "react-responsive";

export default function ProductOrders() {
   const { userDetails } = Token();

   const id = userDetails.id;
   const [order, setOrder] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState("");
   const [productId, setProductId] = useState("");
   const [orderId, setOrderId] = useState("");
   const [show, setShow] = useState(false);
   const [selectedPage, setSelectedPage] = useState(0);
   const [messageModal, setMessageModal] = useState(false);
   const [paymentModal, setPaymentModal] = useState(false);
   const [activeOrder, setActiveOrder] = useState();
   const [total, setTotal] = useState("");
   const [orderDetailsModal, setOrderDetailsModal] = useState(false);
   const [cancleModal, setCancleModal] = useState(false);
   const [cancleErrorModal, setCancleErrorModal] = useState(false);

   const categoryContainer = useRef(null);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 200);
   };

   useEffect(() => {
      async function get_all_orders() {
         const res = await get_orders(selectedPage);
         setPageCount(res?.data?.paging?.pages);
         setOrder(res?.data?.data);
         // const offset = selectedPage * 6;
         // if (selectedPage > 0) {
         //    setOrder(response?.slice(offset, offset + 6));
         // }
      }
      get_all_orders();
   }, [id, selectedPage, show, paymentModal, cancleModal, cancleErrorModal]);
   // console.log("orders", order);

   useEffect(() => {
      if (order === undefined) {
         setLoading(true);
      } else {
         setLoading(false);
      }
      return null;
   });

   const handlePageClick = (e) => {
      setSelectedPage(e.selected + 1);
      scrollDown(categoryContainer);
   };

   return (
      <div className="container" style={{}} ref={categoryContainer}>
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading="Thank You ! "
            message={"Your review has been submitted successfully"}
            buttonText="OK"
            reload={false}
         />
         <ReviewForm
            show={show}
            setShow={setShow}
            orderId={orderId}
            productId={productId}
            setMessageModal={setMessageModal}
            setOrderDetailsModal={setOrderDetailsModal}
         />
         <PaymentModal
            show={paymentModal}
            setShow={setPaymentModal}
            order_id={orderId}
            total={total}
            setOrderDetailsModal={setOrderDetailsModal}
         />
         <OrderDetails
            activeOrder={activeOrder}
            orderDetailsModal={orderDetailsModal}
            setOrderDetailsModal={setOrderDetailsModal}
            setTotal={setTotal}
            setOrderId={setOrderId}
            setPaymentModal={setPaymentModal}
            setProductId={setProductId}
            setShow={setShow}
            setCancleModal={setCancleModal}
         />
         <CancleOrder
            show={cancleModal}
            setShow={setCancleModal}
            id={orderId}
            type={"product"}
            setOrderDetailsModal={setOrderDetailsModal}
            cancleErrorModal={cancleErrorModal}
            setCancleErrorModal={setCancleErrorModal}
         />
         {order &&
            Object.entries(order).map(([key, orders]) => {
               let status = orders.status;
               return orders?.order_items?.length === 0 ? null : (
                  <Order
                     order={orders}
                     setTotal={setTotal}
                     setOrderId={setOrderId}
                     setPaymentModal={setPaymentModal}
                     setActiveOrder={setActiveOrder}
                     setOrderDetailsModal={setOrderDetailsModal}
                  />
               );
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {order?.length === 0 && (
            <div className="dashboardNotification_empty">
               <p className="dashboardNotification_empty_description">You have no orders</p>
            </div>
         )}
      </div>
   );
}

export function ReviewForm({
   show,
   setShow,
   productId,
   orderId,
   setMessageModal,
   setOrderDetailsModal,
}) {
   const [rating, setRating] = useState(1);
   const [btnState, setBtnState] = useState(true);

   const schema = yup.object().shape({
      message: yup.string().required("Review is required"),
   });
   const ratingChanged = (newRating) => {
      setRating(newRating);
   };
   const reRef = useRef();
   const handleSubmit = async (values) => {
      setBtnState(false);
      const captchaToken = await reRef?.current?.executeAsync();
      const res = await post_review(orderId, productId, values?.message, rating, captchaToken);
      if (res?.status === 201) {
         setBtnState(true);
         setShow(false);
         setOrderDetailsModal(false);
         setMessageModal(true);
      } else {
         setBtnState(true);
      }
   };
   return (
      <Modal show={show} onHide={() => setShow(false)}>
         <Captcha reRef={reRef} />
         <div style={{ padding: "20px" }}>
            <Formik
               validationSchema={schema}
               initialValues={{
                  message: "",
               }}
               onSubmit={(values, actions) => {
                  setBtnState(false);
                  setTimeout(() => {
                     handleSubmit(values);
                     actions.setSubmitting(false);
                  }, 1000);
               }}
            >
               {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form id="dashboard-formwidth" onSubmit={handleSubmit}>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label id="dashboard-label">Review</Form.Label>
                        <Form.Control
                           as="textarea"
                           rows={3}
                           type="text"
                           name="message"
                           className="feedback-dashboard"
                           value={values.message}
                           onChange={handleChange}
                        />
                        {touched.message && errors.message && (
                           <p className="text-danger mb-0">Review is required</p>
                        )}
                     </Form.Group>
                     <p className="rating_title">Rating</p>
                     <ReactStars
                        count={5}
                        value={1}
                        onChange={ratingChanged}
                        size={24}
                        activeColor="#ffd700"
                        classNames={"ReviewStars"}
                     />
                     <div className="button_wrapper_modal">
                        <Button
                           variant="secondary"
                           id="dashboardform-btn-cancel"
                           type="button"
                           onClick={() => setShow(false)}
                        >
                           Cancel
                        </Button>
                        <Button
                           variant="secondary"
                           id={btnState ? "dashboardform-btn" : "dashboardform-btn-disable"}
                           type="submit"
                           disabled={btnState ? false : true}
                        >
                           Submit
                        </Button>
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
      </Modal>
   );
}

export function PaymentModal({ show, setShow, order_id, total, setOrderDetailsModal }) {
   const [paymentMethod, setPaymentMethod] = useState("");
   const { userDetails } = Token();
   const [success, setSuccess] = useState(false);
   const handlePayment = async () => {
      if (paymentMethod !== "") {
         handleOnlinePayment({
            order_id: order_id,
            payment_method: paymentMethod,
            order_name: "store",
            total: total,
            uid: userDetails?.id,
         });
      }
      // else if (paymentMethod === "khalti") {
      //    const data = await handleOnlinePayment({
      //       order_id: order_id,
      //       payment_method: paymentMethod,
      //       order_name: "store",
      //       uid: userDetails?.id,
      //       setSuccess: setSuccess,
      //    });
      //    const checkout = new KhaltiCheckout(data);
      //    checkout.show({ amount: total * 100 });
      // }
   };

   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);
   return (
      <>
         <MessageModal
            show={success}
            setShow={setSuccess}
            message="Payment Successful"
            link={"/"}
            buttonText="Go Home"
            reload="true"
         />
         {success && setShow(false)}
         {success && setOrderDetailsModal(false)}
         <Modal show={show} onHide={() => setShow(false)}>
            <div style={{ padding: "20px" }} className="myorder_payment">
               <p className="payment_method-title">Payment Methods</p>
               <div className="payment_methods">
                  {contactDetails?.disable_imepay === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "imepay" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("imepay")}
                     >
                        <img src={ime} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_esewa === false && (
                     <div
                        className="payment_options"
                        style={
                           paymentMethod === "esewa"
                              ? { border: "2px solid red" }
                              : { border: "2px solid #E6EAF0" }
                        }
                        onClick={() => setPaymentMethod("esewa")}
                     >
                        <img src={esewa} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_khalti === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "khalti" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("khalti")}
                     >
                        <img src={khalti} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_connectips === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "connectips" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("connectips")}
                     >
                        <img src={ips} alt="" />
                     </div>
                  )}
                  {contactDetails?.disable_prabhupay === false && (
                     <div
                        className="payment_options"
                        style={paymentMethod === "prabhupay" ? { border: "2px solid red" } : {}}
                        onClick={() => setPaymentMethod("prabhupay")}
                     >
                        <img src={prabhu} alt="" />
                     </div>
                  )}
               </div>
               <button className="pay_now my-3" onClick={() => handlePayment()}>
                  Pay Now
               </button>
            </div>
         </Modal>
      </>
   );
}

export function OrderDetails({
   activeOrder,
   orderDetailsModal,
   setOrderDetailsModal,
   setTotal,
   setOrderId,
   setPaymentModal,
   setProductId,
   setShow,
   setCancleModal,
}) {
   const isMobile = useMediaQuery({ query: "(max-width: 345px)" });

   return (
      <Modal show={orderDetailsModal} onHide={() => setOrderDetailsModal(false)} size={"xl"}>
         <div className="orderdetails_modal">
            <div className="order_Id_details">
               <p className="m-0 d-flex align-items-center">
                  {" "}
                  Order ID {isMobile ? "" : ":"}{" "}
                  <span className="id_red ms-1">{activeOrder?.order_id?.toUpperCase()}</span>
               </p>
               <div
                  className={
                     activeOrder?.is_delivered
                        ? "status_text delivered"
                        : activeOrder?.status === "PENDING"
                        ? "status_text pending"
                        : activeOrder?.status === "CANCELLED"
                        ? "status_text cancelled"
                        : activeOrder?.status === "CONFIRMED"
                        ? "status_text confirmed"
                        : "status_text"
                  }
               >
                  {activeOrder?.status}
               </div>
            </div>
            <div className="orderdetails_modal_body">
               <div className="ordersummaryanddelivery">
                  <div className="orderSummary">
                     <div className="orderSummary_Heading">Order Summary</div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Order Created</p>
                        <p className="details_row_description">
                           {activeOrder?.createdAt &&
                              format(new Date(activeOrder?.createdAt), "EE, MMM dd, yyyy")}
                        </p>
                     </div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Order Time </p>
                        <p className="details_row_description">
                           {activeOrder?.createdAt &&
                              format(new Date(activeOrder?.createdAt), "hh:mm aa")}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Payment Method </p>
                        <p className="details_row_description">
                           {" "}
                           {activeOrder?.payment_method?.toUpperCase()}{" "}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Payment Status </p>
                        <p
                           className={
                              activeOrder?.is_paid
                                 ? "details_row_description paid"
                                 : "details_row_description unpaid"
                           }
                        >
                           {activeOrder?.is_paid ? "Paid" : "Not Paid"}
                        </p>
                     </div>{" "}
                  </div>
                  <div className="deliveryDetails">
                     <div className="deliveryDetails_Heading">Delivery Address</div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           Address Name: {activeOrder?.shipping_address?.name}
                        </p>
                     </div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           Province: {activeOrder?.shipping_address?.province}
                        </p>
                     </div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           District: {activeOrder?.shipping_address?.district}
                        </p>
                     </div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           Street: {activeOrder?.shipping_address?.street}
                        </p>
                     </div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           Address: {activeOrder?.shipping_address?.address}
                        </p>
                     </div>
                     <div className="delevery_details_row">
                        <p className="details_row_title">
                           Landmark: {activeOrder?.shipping_address?.landmark}
                        </p>
                     </div>
                  </div>
               </div>
               <div className="orderdetails">
                  <div className="item_summary">
                     <div className="itemSummary_Heading">Items Summary</div>
                     <div className="items">
                        {activeOrder?.order_items &&
                           Object?.entries(activeOrder?.order_items)?.map(([key, value]) => {
                              return (
                                 <div className="item">
                                    <div className="item_details">
                                       <img
                                          src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                          alt="product"
                                          className="img"
                                       />
                                       <div className="product_details">
                                          <p className="title">{value?.name}</p>
                                          <p className="price">{`Rs. ${value?.price?.toLocaleString(
                                             "hi-IN",
                                          )} X ${value?.quantity}`}</p>
                                          {value?.is_linked && (
                                             <p className="deal_text">({value?.comment})</p>
                                          )}
                                       </div>
                                    </div>
                                    <div className="totalandreview">
                                       <div className="total">
                                          Rs.{" "}
                                          {(value?.price * value?.quantity)?.toLocaleString(
                                             "hi-IN",
                                          )}
                                       </div>
                                       {activeOrder?.status === "COMPLETED" &&
                                          activeOrder?.is_delivered &&
                                          !value?.isReviewed && (
                                             <div
                                                className="review"
                                                onClick={() => {
                                                   setProductId(value?.productId);
                                                   setOrderId(activeOrder._id);
                                                   setShow(true);
                                                }}
                                             >
                                                Review
                                             </div>
                                          )}
                                    </div>
                                 </div>
                              );
                           })}
                     </div>
                  </div>
                  <div className="priceSummary">
                     <div className="orderSummary_Heading">Amount Summary</div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Item Total </p>
                        <p className="details_row_description">
                           {" "}
                           Rs.{" "}
                           {activeOrder?.discount === 0
                              ? activeOrder?.sub_total?.toLocaleString("hi-IN")
                              : (activeOrder?.sub_total + activeOrder?.discount)?.toLocaleString(
                                   "hi-IN",
                                )}
                           {/* {activeOrder?.voucher?.voucherId
                              ? activeOrder?.sub_total
                              : activeOrder?.discount > 0
                              ? (
                                   activeOrder?.sub_total +
                                   activeOrder?.discount -
                                   activeOrder?.shipping_price
                                )?.toLocaleString("hi-IN")
                              : (activeOrder?.sub_total + activeOrder?.discount)?.toLocaleString(
                                   "hi-IN",
                                )} */}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Discount </p>
                        <p className="details_row_description">
                           {" "}
                           Rs. {activeOrder?.discount?.toLocaleString("hi-IN")}
                        </p>
                     </div>
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Sub Total </p>
                        <p className="details_row_description">
                           {" "}
                           Rs.{" "}
                           {/* {activeOrder?.voucher?.voucherId
                              ? activeOrder?.sub_total - activeOrder?.discount
                              : activeOrder?.discount > 0
                              ? (
                                   activeOrder?.sub_total - activeOrder?.shipping_price
                                )?.toLocaleString("hi-IN")
                              : (activeOrder?.sub_total + activeOrder?.discount)?.toLocaleString(
                                   "hi-IN",
                                )} */}
                           {activeOrder?.voucher?.voucherId !== null
                              ? activeOrder?.sub_total?.toLocaleString("hi-IN")
                              : activeOrder?.discount === 0
                              ? activeOrder?.sub_total?.toLocaleString("hi-IN")
                              : activeOrder?.sub_total?.toLocaleString("hi-IN")}
                        </p>
                     </div>{" "}
                     <div className="orderSummary_details_row">
                        <p className="details_row_title">Delivery Fee </p>
                        <p className="details_row_description">
                           {" "}
                           Rs. {activeOrder?.shipping_price?.toLocaleString("hi-IN")}
                        </p>
                     </div>{" "}
                  </div>
                  <div className="totalandButtomGroup">
                     <div className="total">
                        Total: <span>Rs. {activeOrder?.total?.toLocaleString("hi-IN")}</span>
                     </div>
                     <div className="buttonGroup">
                        {!activeOrder?.is_paid &&
                           activeOrder?.payment_method !== "cod" &&
                           activeOrder?.status !== "CANCELLED" &&
                           process.env.REACT_APP_STORE_ONLINE_PAYMENT_STATUS === "true" && (
                              <div
                                 className="button paynow"
                                 onClick={() => {
                                    setTotal(activeOrder?.total);
                                    setOrderId(activeOrder?._id);
                                    setPaymentModal(true);
                                 }}
                              >
                                 Pay Now
                              </div>
                           )}
                        {activeOrder?.status === "PENDING" && !activeOrder?.is_paid && (
                           <div
                              className="button cancel_btn"
                              onClick={() => {
                                 setOrderId(activeOrder?._id);
                                 setCancleModal(true);
                                 setOrderDetailsModal(false);
                              }}
                           >
                              Cancel Order
                           </div>
                        )}
                        <div
                           className="button"
                           onClick={() => {
                              setOrderDetailsModal(false);
                           }}
                        >
                           Close
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Modal>
   );
}

export function Order({
   order,
   setTotal,
   setOrderId,
   setPaymentModal,
   setActiveOrder,
   setOrderDetailsModal,
}) {
   // console.log("Thank", order);

   return (
      <>
         <div className="order_card">
            <div className="order_card_details">
               <p className="orderId">Order {order?.order_id?.toUpperCase()}</p>
               <p className="date">
                  <FaRegCalendarAlt size={19} color={"#596979"} />{" "}
                  <span>{format(new Date(order?.createdAt), "dd MMM yyyy H:mm aa")}</span>
               </p>
               <p
                  className={
                     order?.payment_method !== "cod" &&
                     !order?.is_paid &&
                     order?.status !== "CANCELLED"
                        ? "status"
                        : order?.is_delivered
                        ? "status delivered"
                        : order?.status === "PENDING"
                        ? "status pending"
                        : order?.status === "CANCELLED"
                        ? "status cancelled"
                        : order?.status === "CONFIRMED"
                        ? "status confirmed"
                        : "status"
                  }
               >
                  <div className="status_indicator"></div>
                  {order?.payment_method !== "cod" &&
                  !order?.is_paid &&
                  order?.status !== "CANCELLED" ? (
                     <p>
                        Payment Pending.{" "}
                        {process.env.REACT_APP_STORE_ONLINE_PAYMENT_STATUS === "true" && (
                           <span
                              className="cursor_pointer"
                              style={{ color: "#ED1C24" }}
                              onClick={() => {
                                 setTotal(order?.total);
                                 setOrderId(order?._id);
                                 setPaymentModal(true);
                              }}
                           >
                              {" "}
                              Pay Now
                           </span>
                        )}
                     </p>
                  ) : order?.is_delivered ? (
                     "Delivered"
                  ) : (
                     capitalize(order?.status?.toLowerCase())
                  )}
               </p>
            </div>
            <div className="order_card_images">
               {Object?.entries(order?.order_items)?.map(([key, value]) => {
                  // console.log("Thank You", value);
                  return order?.order_items?.length < 4 ? (
                     <div className="imageCard">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                           alt="Product"
                        />
                     </div>
                  ) : key < 2 ? (
                     <div className="imageCard">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                           alt="Product"
                        />
                     </div>
                  ) : null;
               })}
               {order?.order_items?.length > 3 ? (
                  <div className="numberCard">
                     <p className="imageNumber">+{order?.order_items?.length - 2}</p>
                     <p className="more">more</p>
                  </div>
               ) : null}
            </div>
            <div
               className="order_card_view_order cursor_pointer"
               onClick={() => {
                  setActiveOrder(order);
                  setOrderDetailsModal(true);
               }}
            >
               <MdOutlineDirections size={22} />
               View Order
            </div>
         </div>
      </>
   );
}

export function CancleOrder({ id, type, show, setShow, cancleErrorModal, setCancleErrorModal }) {
   const Cancle = async () => {
      const response = await cancleOrder(type, id);
      if (response.status === 200) setShow(false);
      else {
         setShow(false);
         setCancleErrorModal(true);
      }
   };
   return (
      <>
         <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body" style={{ textAlign: "center" }}>
               <p className="cartdelete-title">Do you want to cancel the order ?</p>
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               <Button
                  variant="primary"
                  onClick={() => {
                     Cancle();
                  }}
                  id="removecart"
               >
                  Yes
               </Button>
               <Button variant="secondary" onClick={() => setShow(false)} id="cancel">
                  No
               </Button>
            </Modal.Footer>
         </Modal>
         <MessageModal
            type={"error"}
            show={cancleErrorModal}
            setShow={setCancleErrorModal}
            heading={"Oops!! Something went Wrong"}
            buttonText={"Ok"}
         />
      </>
   );
}
// <div
// className="mb-3 py-4 px-2"
// style={{
//    backgroundColor: "#F4F7FB",
//    borderRadius: "16px",
//    display: "flex",
//    flexDirection: "column",
// }}
// >
// <div className="d-flex justify-content-between align-items-center">
//    <p className="mb-1 fs-5 ms-3">
//       Order Id : {orders?.order_id?.toUpperCase()}
//    </p>
//    {/* <p className="me-3 mb-0">
//       Status:{" "}
//       <span
//          style={
//             status === "CANCELLED"
//                ? { color: "red", fontWeight: "bold" }
//                : status === "PENDING"
//                ? { color: "#FF6F00", fontWeight: "bold" }
//                : status === "COMPLETED"
//                ? { color: "green", fontWeight: "bold" }
//                : { color: "green", fontWeight: "bold" }
//          }
//       >
//          {status}
//       </span>
//    </p> */}
// </div>

// <p className="mb-4  ms-3 orderDate">
//    Created at : {format(new Date(orders?.createdAt), "dd MMM yyyy H:mm:ss")}
// </p>
// {Object.entries(orders.order_items).map(([key, value]) => {
//    console.log("value_order", value);
//    return (
//       <div
//          className="orderCard mb-4 px-4"
//          style={{ display: "flex", justifyContent: "space-between" }}
//       >
//          <div
//             className="productCard"
//             style={{ display: "flex", alignItems: "center", gap: "25px" }}
//          >
//             <img
//                src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
//                alt=""
//                height={84}
//                width={84}
//             />
//             <div
//                className="details"
//                style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "flex-start",
//                }}
//             >
//                <p>Name: {value?.name}</p>
//                <p>Quantity: {value?.quantity}</p>
//                <p> Price: Rs.{value?.price?.toLocaleString("hi-IN")}</p>
//                {orders?.shipping_price > 0 && (
//                   <p>Shipping charge: Rs.{orders?.shipping_price}</p>
//                )}

//                {orders?.discount > 0 && (
//                   <p>
//                      Discount amount: Rs.
//                      {orders?.discount.toLocaleString("hi-IN")}
//                   </p>
//                )}

//                <p>
//                   {" "}
//                   Sub-total: {value?.quantity} X Rs.{" "}
//                   {value?.price.toLocaleString("hi-IN")}= Rs.{" "}
//                   {(value?.quantity * value?.price).toLocaleString("hi-IN")}
//                </p>
//                <p>
//                   {" "}
//                   Total: Rs.
//                   {(
//                      value?.quantity * value?.price +
//                      orders?.shipping_price -
//                      orders?.discount
//                   ).toLocaleString("hi-IN")}
//                </p>
//                <p>
//                   Payment Status :
//                   <span
//                      style={
//                         orders?.is_paid
//                            ? { color: "green" }
//                            : { color: "#FF6F00" }
//                      }
//                   >
//                      {orders?.is_paid ? " Paid" : " Pending"}
//                   </span>
//                </p>
//             </div>
//          </div>
//          <p style={{ display: "flex", flexDirection: "column" }}>
//             <span
//                className="px-2"
//                style={{
//                   paddingBotton: "6px",
//                   borderBottom: "1px solid black",
//                }}
//             >
//                Order Status
//             </span>
//             <p
//                style={
//                   status === "CANCELLED"
//                      ? { color: "red", fontWeight: "bold" }
//                      : status === "PENDING"
//                      ? { color: "#FF6F00", fontWeight: "bold" }
//                      : status === "COMPLETED"
//                      ? { color: "green", fontWeight: "bold" }
//                      : { color: "green", fontWeight: "bold" }
//                }
//             >
//                {status}
//             </p>
//             {value?.isReviewed !== true && status === "COMPLETED" ? (
//                <p
//                   className="red"
//                   style={{
//                      display: "flex",
//                      padding: "2px 10px",
//                      margin: "0px",
//                      backgroundColor: "#ed1c24",
//                      color: "#fff",
//                      borderRadius: "10px",
//                      cursor: "pointer",
//                   }}
//                   onClick={() => {
//                      setProductId(value?.productId);
//                      setOrderId(orders._id);
//                      setShow(true);
//                   }}
//                >
//                   Review
//                </p>
//             ) : null}
//             {value?.isReviewed && <p>Reviewed</p>}
//             {orders?.payment_method !== "cod" && orders?.is_paid === false ? (
//                <p
//                   style={{
//                      display: "flex",
//                      padding: "4px 10px",
//                      margin: "0px",
//                      backgroundColor: "#ed1c24",
//                      color: "#fff",
//                      borderRadius: "10px",
//                      cursor: "pointer",
//                      whiteSpace: "nowrap",
//                   }}
//                   onClick={() => {
//                      setPaymentModal(true);
//                      setOrderId(orders._id);
//                      setTotal(orders.total);
//                   }}
//                >
//                   Pay Now
//                </p>
//             ) : null}
//          </p>
//       </div>
//    );
// })}
// </div>
