import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../components/include/layout";
import Loading from "../../../components/customcomponents/loading";
import SeoTags from "../../../utils/seoTags";
import { get_dishhome_go_indv_package, get_company_details } from "../../../data/api";
import { useParams } from "react-router";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";
import { Alert, Button, Modal, ModalHeader } from "react-bootstrap";
import MessageModal from "../../../components/customcomponents/messageModal";
import khalti from "../../../assets/cart/khalti.png";
import esewa from "../../../assets/store/esewa.webp";
import ips from "../../../assets/store/ips.png";
import prabhu from "../../../assets/store/prabhu.png";
import ime from "../../../assets/store/ime.png";
import { handleOnlinePayment } from "../../../components/pages/payment/payment";
import Token from "../../../utils/useToken";
import KhaltiCheckout from "khalti-checkout-web";
import { check_balance, order_ott } from "../../../data/protectedapi";
import "../../../scss/ott/dishhomegoPackages.scss";
import { BreadContainer } from "../../../components/include/breadcrumb";
import { useLocation } from "react-router";
import LoginModal from "../../../components/pages/loginmodal";
import buyIcon from "../../../assets/ott/ott_buy.png";
import { useMediaQuery } from "react-responsive";
import TrackVisibility from "react-on-screen";
import DishhomeGoCart from "../cart";
import detailsIcon from "../../../assets/ott/detailsIcon.png";
import { FaInfoCircle } from "react-icons/fa";
import { TbArrowBackUp } from "react-icons/tb";

const DishhomegoPackage = () => {
   //UseStates
   const [isLoading, setIsLoading] = useState(true);
   const [packageData, setPackageData] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [purchaseID, setpurchaseID] = useState([]);
   const [buyModal, setBuyModal] = useState(false);
   const [selectedPackage, setSelectedPackage] = useState([]);
   const [loginModal, setLoginModal] = useState(false);
   const top = useRef(null);
   const [ispricecardVisible, setIspricecardVisible] = useState(false);
   const [isCartOpen, setIsCartOpen] = useState(false);
   const [updateCart, setUpdateCart] = useState(false);
   const [subpackages, setSubpackages] = useState([]);
   const [activePriceId, setActivePriceId] = useState("");
   const [total, setTotal] = useState(0);

   //params
   const params = useParams();
   const { id } = params;

   const { userDetails } = Token();

   //UseLocation
   let location = useLocation();

   const handleClickClosePanelFromOutside = (e) => {
      setIsCartOpen(false);

      // setMobileSearch(false);
   };

   useEffect(() => {
      if (isCartOpen === true) {
         document.body.addEventListener("click", handleClickClosePanelFromOutside);
         return () => {
            document.body.removeEventListener("click", handleClickClosePanelFromOutside);
         };
      }
   }, [isCartOpen]);

   //isMobile
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

   const scrollDown = (ref) => {
      console.log(ref.current);
      isMobile && window.scrollTo(0, ref.current.offsetTop + 1000);
      !isMobile && window.scrollTo(0, ref.current.offsetTop + 700);
   };

   //UseEffect
   useEffect(() => {
      async function fetchPackages() {
         const res = await get_dishhome_go_indv_package(id);
         const response = res?.data?.package;
         const subpackages = res?.data?.data;
         if (res?.status === 200) {
            setIsLoading(false);
            setPackageData(response);
            setSubpackages(subpackages);
         }
      }
      fetchPackages();
   }, [isLoading]);
   return isLoading ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate} footer={"ott"}>
         {seoData &&
            Object.entries(seoData).map(([key, value]) => {
               if (value?.page === "dishhomego-packages") {
                  return SeoTags(
                     value?.title,
                     value?.title,
                     "",
                     value?.keyword,
                     "",
                     `${process.env.REACT_APP_PUBLIC_URL}dishhome-go/packages`,
                  );
               } else {
                  return null;
               }
            })}
         <BreadContainer
            mainpath={"dishhome-go"}
            pathname={"packages"}
            categoryname={packageData?.alias}
         />
         <LoginModal Show={loginModal} setShow={setLoginModal} link={location?.pathname} />
         {/* <DishhomeGoCart
            setShow={setLoginModal}
            isCartOpen={isCartOpen}
            setIsCartOpen={setIsCartOpen}
            updateCart={updateCart}
         /> */}
         <Widthcontainer className={"PackageCardTop py-5"}>
            <PackageCard
               data={packageData}
               setActivePriceId={setActivePriceId}
               activePriceId={activePriceId}
               setShow={setBuyModal}
               setTotal={setTotal}
               setLoginModal={setLoginModal}
            />
         </Widthcontainer>
         {/* <Widthcontainer>
            <SubPackages
               data={packageData?.sub_packages}
               setpurchaseID={setpurchaseID}
               purchaseID={purchaseID}
               setBuyModal={setBuyModal}
               selectedPackage={selectedPackage}
               setSelectedPackage={setSelectedPackage}
               setLoginModal={setLoginModal}
               top={top}
               scrollDown={scrollDown}
               setIspricecardVisible={setIspricecardVisible}
            />
         </Widthcontainer> */}
         <Widthcontainer>
            <GoPackages
               setShow={setLoginModal}
               updateCart={updateCart}
               setUpdateCart={setUpdateCart}
               subpackages={subpackages}
               tabs={packageData.tab_selected}
            />
         </Widthcontainer>
         <PaymentModal
            show={buyModal}
            setShow={setBuyModal}
            AvailablePaymentMethod={packageData?.payment_method_enabled}
            total={total}
            price_id={activePriceId}
            package_id={packageData?.product_id}
         />
         {/* {!ispricecardVisible && purchaseID?.length > 0 && isMobile && (
            <div
               className="buyButton"
               style={{
                  position: "fixed",
                  bottom: "10%",
                  right: ".5em",
                  zIndex: "999",
                  cursor: "pointer",
                  backgroundColor: "#f50057",
                  padding: "0.5em",
                  borderRadius: "24px",
               }}
               onClick={() => {
                  scrollDown(top);
               }}
            >
 <AiOutlineArrowUp fontSize={24} fontWeight={600} color="#FFF" /> 
               <BiCameraMovie fontSize={24} fontWeight={600} color="#FFF" />
               <p
                  className="mb-0"
                  style={{
                     fontSize: "16px",
                     fontWeight: "500",
                     color: "#FFF",
                  }}
               >
                  Buy Packages
               </p>
            </div>
         )} */}
         {/* {!ispricecardVisible && purchaseID?.length > 0 && !isMobile && (
            <div
               className="buyButton"
               style={{
                  position: "fixed",
                  bottom: "20%",
                  right: "4em",
                  zIndex: "999",
                  cursor: "pointer",
                  backgroundColor: "#f50057",
                  padding: "0.5em",
                  borderRadius: "24px",
               }}
               onClick={() => {
                  scrollDown(top);
               }}
            >
               <AiOutlineArrowUp fontSize={24} fontWeight={600} color="#FFF" />
               <BiCameraMovie fontSize={24} fontWeight={600} color="#FFF" />
               <p
                  className="mb-0"
                  style={{
                     fontSize: "16px",
                     fontWeight: "500",
                     color: "#FFF",
                  }}
               >
                  Buy Packages
               </p>
            </div>
         )} */}
      </Layout>
   );
};
export default DishhomegoPackage;

function PackageCard({ data, setActivePriceId, activePriceId, setShow, setTotal, setLoginModal }) {
   const [alertShow, setAlertShow] = useState(false);

   const { userDetails } = Token();
   function showModal() {
      if (!activePriceId) {
         setAlertShow(true);
         setTimeout(() => {
            setAlertShow(false);
         }, 5000);
         return;
      } else {
         setShow(true);
      }
   }

   return (
      <div className="fixed-width mt-4 mb-3 responsiveDiv">
         <div className="row justify-content-start d-flex" style={{ gap: "12px" }}>
            <div style={{ flexBasis: "30%" }} className="ms-1">
               <img
                  src={`${process.env.REACT_APP_FILE_URL}/${data?.image}`}
                  alt=""
                  height={"480px"}
                  width={"360px"}
                  style={{ borderRadius: "4px" }}
               />
            </div>
            <div className="mt-1 d-flex flex-column ms-lg-5 DHGOContent" style={{ flex: "1" }}>
               <h1 className="title_text">{data?.alias}</h1>
               <p
                  className="description_text"
                  dangerouslySetInnerHTML={{ __html: data.description }}
               >
                  {/* {data?.description} */}
               </p>
               <div className="bottomContainerSubPackage d-flex flex-column mt-4">
                  <div className="plan_heading_container">
                     <h5 className="plan_text-heading">Plans</h5>
                  </div>
                  <div className="buttonGroup d-flex">
                     {data?.prices?.map((data) => {
                        return (
                           <div
                              className={
                                 data?.priceId === activePriceId
                                    ? "subPackageCardPriceButton active"
                                    : "subPackageCardPriceButton"
                              }
                              onClick={() => {
                                 setTotal(data?.price);
                                 setActivePriceId(data?.priceId);
                              }}
                           >
                              Rs {data?.price}{" "}
                              <sub>
                                 /{data?.duration}{" "}
                                 {data?.duration_type === "H"
                                    ? "hours"
                                    : data?.duration_type === "D"
                                    ? "days"
                                    : data?.duration_type === "M"
                                    ? "months"
                                    : "years"}
                              </sub>
                           </div>
                        );
                     })}
                  </div>
               </div>
               {alertShow && (
                  <Alert variant="primary" className="p-2 m-0" style={{ width: "fit-content" }}>
                     <p className="m-0 d-flex align-items-center" style={{ fontSize: "17px" }}>
                        {" "}
                        <FaInfoCircle className="me-1" /> Please choose your desired plan.
                     </p>
                  </Alert>
               )}
               <div
                  className="btn activateButton"
                  onClick={() => {
                     !userDetails ? setLoginModal(true) : showModal();
                  }}
               >
                  Buy Now
               </div>
            </div>
            {/* <div
               className="mt-1 d-flex flex-column"
               style={{ flex: "1", justifyContent: "space-between" }}
            >
               <div>
                  <h1 className="title_text">{data?.alias}</h1>
                  <p
                     className="description_text"
                     dangerouslySetInnerHTML={{ __html: data.description }}
                  >
                    
                  </p>
               </div>
               <div
                  className="bottomContainerSubPackage d-flex flex-column "
                  style={{ gap: "22px" }}
               >
                  {true ? (
                     <div className="buttonGroup d-flex">
                        <div className="subPackageCardPriceButton">
                           Rs 200 <sub>/month</sub>
                        </div>
                        <div className="subPackageCardPriceButton">
                           Rs 200 <sub>/month</sub>
                        </div>
                        <div className="subPackageCardPriceButton">
                           Rs 200 <sub>/month</sub>
                        </div>
                     </div>
                  ) : (
                     <div className="ActivateNowContainer">
                        <p className="text">Activate Now to get 3 months free access</p>
                        <div className="btn activateButton">Activate Now</div>
                     </div>
                  )}
                  <div className="btn learnMoreButton">Learn More</div>
               </div>
            </div> */}
         </div>
      </div>
   );
}

function SubPackages({
   data,
   setpurchaseID,
   purchaseID,
   setBuyModal,
   selectedPackage,
   setSelectedPackage,
   setLoginModal,
   top,
   scrollDown,
   setIspricecardVisible,
}) {
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
   const { userDetails } = Token();
   const [show, setShow] = useState(false);
   const [value, setValue] = useState("");
   useEffect(() => {
      //remove purchase id not included in purchaseID
      setSelectedPackage(
         selectedPackage.filter((item) => purchaseID.map((item) => item.price_id).includes(item)),
      );
   }, [purchaseID]);

   return (
      <div className="fixed-width mt-5 mb-1" style={{ position: "relative" }}>
         <div
            className="DishhomegoSubPackageWrapper d-flex justify-content-start"
            style={isMobile ? { flexDirection: "column" } : { gap: "24px" }}
         >
            <div
               className={
                  isMobile ? "d-flex flex-column w-100 border-bottom" : "d-flex flex-column w-100 "
               }
            >
               <h1 className="text-center mb-5 ottSubPackageTitleGradient">Sub Packages</h1>{" "}
               <div className="subPackageContainer mb-5">
                  {data &&
                     Object.entries(data).map(([key1, value1]) => {
                        return (
                           <div className="subPackageCardWrapper">
                              <div className="subPackageCardImage w-100">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value1?.image}`}
                                    alt=""
                                    height={"411px"}
                                    width={"100%"}
                                    style={{
                                       borderRadius: "6px",
                                       objectFit: "cover",
                                       // border: "1px solid rgba(230, 234, 240, 0.55)",
                                       // boxShadow: " 3px 8px 20px rgba(31, 49, 32, 0.04)",
                                    }}
                                 />
                              </div>
                              <p className="subPackageCardTitle">{value1?.alias}</p>
                              <div className="priceButtonWrapper px-3">
                                 {value1?.prices &&
                                    Object.entries(value1?.prices).map(([key, value]) => {
                                       return (
                                          <div
                                             className={
                                                //check if purchaseID array has price id
                                                purchaseID
                                                   ?.map((item) => item.price_id)
                                                   .includes(value?._id)
                                                   ? "subPackageCardPriceButton_active"
                                                   : "subPackageCardPriceButton"
                                             }
                                             style={{ whiteSpace: "nowrap" }}
                                             onClick={() => {
                                                setpurchaseID((oldArray) => {
                                                   return oldArray.filter(
                                                      (item) => item.alias !== value1?.alias,
                                                   );
                                                });
                                                setpurchaseID((oldArray) => [
                                                   ...oldArray,
                                                   {
                                                      alias: value1?.alias,
                                                      price: value?.price,
                                                      duration: value?.duration,
                                                      duration_type: value?.duration_type,
                                                      price_id: value?._id,
                                                      image: value1?.image,
                                                   },
                                                ]);
                                                //add all selected package price id in selectedPAckage Array
                                                if (!selectedPackage.includes(value?._id))
                                                   setSelectedPackage((oldArray) => [
                                                      ...oldArray,
                                                      value?._id,
                                                   ]);

                                                console.log(
                                                   "selectedPackage",
                                                   selectedPackage.includes(value?._id),
                                                );
                                             }}
                                          >
                                             Rs. {value?.price}
                                             <sub style={{ marginLeft: "2px" }}>
                                                / {value?.duration}{" "}
                                                {value?.duration_type === "D"
                                                   ? value?.duration === 1
                                                      ? "Day"
                                                      : "Days"
                                                   : value?.duration_type === "M"
                                                   ? value?.duration === 1
                                                      ? "Month"
                                                      : "Months"
                                                   : value?.duration === 1
                                                   ? "Year"
                                                   : "Years"}
                                             </sub>
                                          </div>
                                       );
                                    })}
                              </div>
                              <div className="d-flex h-100 align-items-end">
                                 <div
                                    className="subPackageCardLearnMoreButton"
                                    onClick={() => {
                                       setShow(true);
                                       setValue(value1);
                                    }}
                                 >
                                    Learn More
                                 </div>
                              </div>
                           </div>
                        );
                     })}
               </div>
            </div>
            {data?.length > 0 && (
               <div className="priceCardDetailsRefContainer" ref={top}>
                  <TrackVisibility offset={100}>
                     <PriceCard
                        selectedPackage={selectedPackage}
                        purchaseID={purchaseID}
                        setBuyModal={setBuyModal}
                        setLoginModal={setLoginModal}
                        userDetails={userDetails}
                        setSelectedPackage={setSelectedPackage}
                        setIspricecardVisible={setIspricecardVisible}
                     />
                  </TrackVisibility>
               </div>
            )}
         </div>
         <SubPackageDetails
            show={show}
            setShow={setShow}
            setpurchaseID={setpurchaseID}
            purchaseID={purchaseID}
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
            value1={value}
            scrollDown={scrollDown}
            top={top}
         />
      </div>
   );
}

export function PaymentModal({
   show,
   setShow,
   package_id,
   price_id,
   total,
   AvailablePaymentMethod,
}) {
   const [paymentMethod, setPaymentMethod] = useState("");
   const { userDetails } = Token();
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [btnLoading, setBtnLoading] = useState(false);

   const onlinePaymentMethod = ["esewa", "khalti", "connectips", "imepay"];

   const handlePayment = async (order_id, total) => {
      if (
         // paymentMethod !== "khalti" &&
         paymentMethod !== ""
      ) {
         handleOnlinePayment({
            order_id: order_id,
            payment_method: paymentMethod,
            order_name: "DishHome Go",
            total: total,
            uid: userDetails?.id,
         });
      }
      //  else if (paymentMethod === "khalti") {
      //    const data = await handleOnlinePayment({
      //       order_id: order_id,
      //       payment_method: paymentMethod,
      //       order_name: "DishHome Go",
      //       uid: userDetails?.id,
      //       setSuccess: setSuccess,
      //    });
      //    const checkout = new KhaltiCheckout(data);
      //    checkout.show({ amount: total * 100 });
      // }
   };

   const handleOrder = async () => {
      setBtnLoading(true);
      const response = await order_ott(paymentMethod, package_id, price_id, total, [], "package");
      setPaymentMethod("");
      if (response?.status === 200) {
         const data = response.data.data;
         if (onlinePaymentMethod?.includes(paymentMethod)) {
            setBtnLoading(false);
            handlePayment(data?.order_id, data?.total);
            setShow(false);
         } else {
            setBtnLoading(false);
            setShow(false);
            setPaymentMethod("");
            setSuccess(true);
         }
      } else {
         setBtnLoading(false);
         setPaymentMethod("");
         setErrorMessage(response?.response?.data?.message);
         setShow(false);
         setError(true);
      }
   };

   const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

   return (
      <>
         <MessageModal
            show={success}
            setShow={setSuccess}
            message="Payment Successful"
            link={"/"}
            buttonText="Go Home"
            reload={true}
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading="Payment Failed"
            message={errorMessage}
            type={"error"}
            buttonText="OK"
         />
         {success && setShow(false)}
         <Modal
            show={show}
            onHide={() => {
               setPaymentMethod("");
               setShow(false);
            }}
            size={"md"}
         >
            <div
               className="order_summary d-flex justify-content-between px-4"
               style={
                  isMobile
                     ? {
                          flexDirection: "column",
                          maxHeight: "520px",
                          minHeight: "auto",
                          msOverflowY: "auto",
                       }
                     : {}
               }
            >
               <div
                  style={isMobile ? { padding: "20px" } : { padding: "20px", minWidth: "420px" }}
                  className="myorder_payment"
               >
                  <p className="payment_method-title">Payment Methods</p>
                  {
                     <div className="payment_methods">
                        {AvailablePaymentMethod?.includes("imepay") && (
                           <div
                              className="payment_options"
                              style={paymentMethod === "imepay" ? { border: "2px solid red" } : {}}
                              onClick={() => setPaymentMethod("imepay")}
                           >
                              <img src={ime} alt="" />
                           </div>
                        )}
                        {AvailablePaymentMethod?.includes("esewa") && (
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "esewa"
                                    ? { border: "2px solid red" }
                                    : { border: "2px solid #E6EAF0" }
                              }
                              onClick={() => setPaymentMethod("esewa")}
                           >
                              <img src={esewa} alt="" />
                           </div>
                        )}
                        {AvailablePaymentMethod?.includes("khalti") && (
                           <div
                              className="payment_options"
                              style={paymentMethod === "khalti" ? { border: "2px solid red" } : {}}
                              onClick={() => setPaymentMethod("khalti")}
                           >
                              <img src={khalti} alt="" />
                           </div>
                        )}
                        {AvailablePaymentMethod?.includes("connectips") && (
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "connectips" ? { border: "2px solid red" } : {}
                              }
                              onClick={() => setPaymentMethod("connectips")}
                           >
                              <img src={ips} alt="" />
                           </div>
                        )}
                        {AvailablePaymentMethod?.includes("prabhupay") && (
                           <div
                              className="payment_options"
                              style={
                                 paymentMethod === "prabhupay" ? { border: "2px solid red" } : {}
                              }
                              onClick={() => setPaymentMethod("prabhupay")}
                           >
                              <img src={prabhu} alt="" />
                           </div>
                        )}
                     </div>
                  }
                  <button
                     className="btn btn-danger my-3"
                     style={{ width: "100%", borderRadius: "18px" }}
                     disabled={
                        (paymentMethod === "" || paymentMethod === "online" ? true : false) ||
                        btnLoading
                           ? true
                           : false
                     }
                     onClick={() => handleOrder()}
                  >
                     Pay Now
                  </button>
               </div>
            </div>
         </Modal>
      </>
   );
}

export function PriceCard({
   selectedPackage,
   userDetails,
   purchaseID,
   setSelectedPackage,
   setLoginModal,
   setBuyModal,
   top,
   isVisible,
   setIspricecardVisible,
}) {
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
   useEffect(() => {
      setIspricecardVisible(isVisible);
   }, [isVisible]);
   console.log(isVisible, "isVisible");
   return (
      <div
         className="priceCardContainer d-flex"
         style={isMobile ? { justifyContent: "center", marginBottom: "22px" } : { height: "100%" }}
      >
         {" "}
         <div
            className="d-flex flex-column justify-content-center align-items-center p-4"
            style={{
               // position: "absolute",
               // top: "0px",
               // right: "-40px",
               height: "fit-content",
               borderRadius: "10px",
               background: "#fff",
               background: "#ffffff",
               border: "1px solid #e6eaf0",
               width: "320px",
               position: "sticky",
               top: "0",
            }}
            ref={top}
         >
            <h4
               className="mb-4 "
               style={{
                  fontWeight: "600",
                  fontSize: "23px",
                  lineHeight: "42px",
                  width: "100%",
               }}
            >
               Package Cart
            </h4>

            {purchaseID?.length > 0 &&
               Object.entries(purchaseID).map(([key, value]) => {
                  return (
                     <div
                        className="item d-flex align-items-center py-1 mb-3 justify-content-between"
                        style={{ gap: "18px", width: "100%" }}
                     >
                        <div className="d-flex" style={{ gap: "14px" }}>
                           <img
                              src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                              alt=""
                              height={"60px"}
                              width={"60px"}
                              style={{ borderRadius: "10px" }}
                           />
                           <div className="price" style={{ color: "#6D6D6D" }}>
                              <h5>{value?.alias}</h5>
                              <p className="m-0">
                                 Rs. {value?.price}
                                 <sub>
                                    {" "}
                                    / {value?.duration}{" "}
                                    {value?.duration_type === "D"
                                       ? "Days"
                                       : value?.duration_type === "M"
                                       ? "Month"
                                       : "Year"}
                                 </sub>
                              </p>
                           </div>
                        </div>
                        <input
                           className="form-check-input p-0 ms-3 subPackageCheckBox"
                           type="checkbox"
                           checked={selectedPackage?.includes(value?.price_id) ? true : false}
                           name={`flexRadioDefault-Productselect-${key}`}
                           id={`flexRadioDefault-productselect-${key}`}
                           onChange={() => {
                              //remove selected package from array which is not included in purchaseID

                              if (selectedPackage?.includes(value?.price_id)) {
                                 setSelectedPackage(
                                    selectedPackage?.filter((item) => item !== value?.price_id),
                                 );
                              } else {
                                 setSelectedPackage((oldArray) => [...oldArray, value?.price_id]);
                              }
                           }}
                        />
                     </div>
                  );
               })}
            {console.log(selectedPackage)}

            {purchaseID?.length > 0 && (
               <div className="d-flex justify-content-end align-items-center w-100 mt-2">
                  <button
                     disabled={selectedPackage?.length === 0 ? true : false}
                     className="btn btn-danger  ms-5"
                     onClick={() => {
                        if (!userDetails) setLoginModal(true);
                        else setBuyModal(true);
                     }}
                  >
                     Buy Now
                  </button>
               </div>
            )}
         </div>
      </div>
   );
}

export function SubPackageDetails({
   show,
   setShow,
   value1,
   setpurchaseID,
   purchaseID,
   selectedPackage,
   setSelectedPackage,
   scrollDown,
   top,
}) {
   const isResponsive = useMediaQuery({ query: "(max-width: 991px)" });

   return (
      <Modal show={show} onHide={() => setShow(false)} size="lg">
         <ModalHeader closeButton>
            <h2 className="w-100 text-center">{value1?.alias}</h2>
         </ModalHeader>
         <div
            className={
               isResponsive
                  ? "d-flex justify-content-start m-5 mt-4 subPackageWrapper flex-column"
                  : "d-flex justify-content-start m-5 mt-4 subPackageWrapper "
            }
            style={{ gap: "34px" }}
         >
            <div className=" d-flex align-items-center justify-content-center">
               <img
                  src={`${process.env.REACT_APP_FILE_URL}/${value1?.image}`}
                  alt=""
                  width={"280px"}
                  height={"340px"}
                  style={{ borderRadius: "16px", objectFit: "cover" }}
               />
            </div>
            <div className="d-flex flex-column mt-1">
               <p
                  style={{
                     maxWidth: "620px",
                     fontStyle: "normal",
                     fontWeight: "500",
                     fontSize: "18px",
                     lineHeight: "26px",
                     /* or 133% */
                     color: "rgba(0, 0, 0, 0.6)",
                     maxHeight: "240px",
                     overflowY: "scroll",
                  }}
                  dangerouslySetInnerHTML={{ __html: value1?.description }}
               >
                  {/* {value1?.description} */}
               </p>
               <div className="SubPackageModalpriceButtonWrapper">
                  {value1?.prices &&
                     Object.entries(value1?.prices).map(([key, value]) => {
                        return (
                           <div
                              className={
                                 //check if purchaseID array has price id
                                 purchaseID?.map((item) => item.price_id).includes(value?._id)
                                    ? "subPackageCardPriceButton_active"
                                    : "subPackageCardPriceButton"
                              }
                              style={{ whiteSpace: "nowrap" }}
                              onClick={() => {
                                 setpurchaseID((oldArray) => {
                                    return oldArray.filter((item) => item.alias !== value1?.alias);
                                 });
                                 setpurchaseID((oldArray) => [
                                    ...oldArray,
                                    {
                                       alias: value1?.alias,
                                       price: value?.price,
                                       duration: value?.duration,
                                       duration_type: value?.duration_type,
                                       price_id: value?._id,
                                       image: value1?.image,
                                    },
                                 ]);
                                 //add all selected package price id in selectedPAckage Array
                                 if (!selectedPackage?.includes(value?._id))
                                    setSelectedPackage((oldArray) => [...oldArray, value?._id]);
                              }}
                           >
                              Rs. {value?.price}
                              <sub style={{ marginLeft: "2px" }}>
                                 / {value?.duration}{" "}
                                 {value?.duration_type === "D"
                                    ? "Days"
                                    : value?.duration_type === "M"
                                    ? "Month"
                                    : "Year"}
                              </sub>
                           </div>
                        );
                     })}
               </div>

               <Button
                  disabled={
                     purchaseID?.map((item) => item.alias).includes(value1?.alias) ? false : true
                  }
                  className="btn btn-danger continueButton"
                  onClick={() => {
                     scrollDown(top);
                     setShow(false);
                  }}
               >
                  Confirm
               </Button>
            </div>
         </div>
      </Modal>
   );
}

function GoPackages(props) {
   const setLoginShow = props?.setShow;
   const updateCart = props?.updateCart;
   const setUpdateCart = props?.setUpdateCart;
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
   const subpackages = props?.subpackages;
   const tab_selected = props?.tabs || [];
   const initialCategory = tab_selected && tab_selected.length > 0 ? tab_selected[0] : "";
   const [selectedCategory, setselectedCategory] = useState(initialCategory);
   useEffect(() => {
      setselectedCategory(initialCategory);
   }, [initialCategory]);
   // if(selectedCategory !== initialCategory) setselectedCategory(initialCategory);

   const [show, setShow] = useState(false);
   const [activeData, setActiveData] = useState({});

   const { userDetails } = Token();

   function addToCart(activeData) {
      const localData = localStorage.getItem("goCart");

      if (!localData) {
         const data = {
            user: userDetails?.id,
            cart: [activeData],
         };
         localStorage.setItem("goCart", JSON.stringify(data));
      } else {
         const local = JSON.parse(localData);
         const cart = local.cart;
         const filtered = cart?.filter((d) => d?.title == activeData?.title);
         console.log(filtered);
         if (filtered?.length === 0) {
            cart?.push(activeData);
            console.log(cart);
            const data = {
               user: local?.user,
               cart: cart,
            };
            localStorage.setItem("goCart", JSON.stringify(data));
         }
      }
      setUpdateCart(!updateCart);
      setShow(false);
   }
   const isMobileModal = useMediaQuery({ query: "(max-width: 993px)" });
   const isMobileRes = useMediaQuery({ query: "(max-width: 800px)" });
   return (
      <div className="fixed-width mt-5 mb-4 dishhomegoPackage_card d-flex flex-column">
         <Modal
            onHide={() => setShow(false)}
            show={show}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="dishhomego-packagedetails-modal"
            fullscreen={isMobileModal}
         >
            <Modal.Body>
               <div className="d-flex flex-column modal-container">
                  <h4 className="modal-custom-header ">{activeData.name}</h4>
                  <div
                     className={
                        isMobileRes
                           ? "mt-2 modal-content-container flex-column align-items-center"
                           : "mt-2 modal-content-container"
                     }
                  >
                     <img
                        src={`${process.env.REACT_APP_FILE_URL}/${activeData?.image}`}
                        className="modal-image"
                        alt=""
                     />{" "}
                     <div className={isMobileRes ? "modal-details w-100" : "modal-details "}>
                        <p className="overview">Overview:</p>
                        <p
                           style={{
                              maxWidth: "620px",
                              maxHeight: "240px",
                              overflowY: "auto",
                           }}
                           dangerouslySetInnerHTML={{ __html: activeData?.description }}
                        ></p>
                        <div className="d-flex w-100">
                           <button
                              className="btn btn-secondary mt-3 custom-btn d-flex align-items-center px-2 justify-center"
                              onClick={() => {
                                 setShow(false);
                              }}
                           >
                              <TbArrowBackUp fontSize={20} className="me-1" />
                              Go Back
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {/* <h3 className="text-center mb-5 dishhomego_text_heading">DishHome Go Packages</h3> */}
         <div className="btn-container-ott" style={{ paddingLeft: "10px", position: "relative" }}>
            <div className="btns">
               {console.log("inrender", selectedCategory)}
               {tab_selected?.map((tab, index) => (
                  <button
                     className={`btn btn-ottpackagetype ${
                        selectedCategory === tab ? "active" : "inactive"
                     }`}
                     onClick={() => setselectedCategory(tab)}
                  >
                     {tab == "movie" ? "Movies" : tab == "series" ? "Series" : "Live"}
                  </button>
               ))}
            </div>
            {/* {selectedCategory !== "packages" && (
               <div className="dropdownContainer mt-3 ms-3">
                  <Dropdown placeholder={"Select Genre"} options={options} />
               </div>
            )} */}
         </div>

         <div
            className="packagesCollection"
         >
            {selectedCategory != "" &&
               subpackages
                  .filter((item) => item.type === selectedCategory) // Replace "packages" with the specific type you want to filter
                  .map((contentData, index) => (
                     <AddonCard
                        setShow={setShow}
                        setActiveData={setActiveData}
                        contentData={contentData}
                     />
                  ))}
            {selectedCategory != "" &&
               subpackages.filter((item) => item.type === selectedCategory).length === 0 && (
                  <div>No content available yet.</div>
               )}
         </div>
      </div>
   );
}

function AddonCard({ setShow, setActiveData, contentData }) {
   return (
      <div className="card mb-4 goPackageCard">
         <img
            className="cover"
            src={`${process.env.REACT_APP_FILE_URL}/${contentData?.image}`}
            alt={contentData.name}
         />
         <div className="details">
            <h5 className="card-title mb-1">{contentData.name}</h5>
            {/* <p className="card-text">Price: Rs.{contentData.price}</p> */}
            <a
               className="btn btn-danger mt-2 custom-btn"
               onClick={() => {
                  setActiveData(contentData);
                  setShow(true);
               }}
            >
               <img src={detailsIcon} alt="" /> View Details
            </a>
         </div>
      </div>
   );
}
