import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import SlideBanner from "../../components/customcomponents/slidebanner";
import Layout from "../../components/include/layout";
import AdBanner from "./adbanner";
import Brand from "./brand";
import Categories from "./categories";
import Productvideo from "./productvideo";
import FeaturedProducts from "./featuredproducts";
import Hotdeals from "./hotdeals";
import NewArrivals from "./newarrivals";
import Productideo from "./productvideo";
import StreamingBanner from "./streamingbanner";
import Banner from "./banner";
import Cart from "./cart";
import ProductWrapper from "./specials/main";
import { get_store_components } from "../../data/api";
import Loading from "../../components/customcomponents/loading";
import GallerySlider from "./galleryslider";
import SeoTags from "../../utils/seoTags";

export default function Main() {
   const [storeData, setStoreData] = useState("");
   const [banner, setBanner] = useState("");
   const [empty, setEmpty] = useState(true);
   const [cartupdate, setCartupdate] = useState(false);
   const [seoData, setSeoData] = useState("");

   useEffect(() => {
      async function fetchData() {
         const response = await get_store_components();
         setStoreData(response.data);
      }
      fetchData();
   }, [empty]);

   useEffect(() => {
      setBanner(storeData?.banners);
      setSeoData(storeData?.seo);
      if (storeData?.banners === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });
   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "store") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}store`,
               );
            } else {
               return null;
            }
         })}
         <div style={{ height: "1px" }}></div>
         {banner.map((value, key) => {
            if (value?.page_location === "store") return <Banner data={value} muted={false} />;
            else return null;
         })}
         {/* <Productvideo /> */}
         <Categories />
         {banner.map((value, key) => {
            if (value?.page_location === "store_mid") return <StreamingBanner data={value} />;
            else return null;
         })}
         <NewArrivals />
         {banner.map((value, key) => {
            if (value?.page_location === "store_bottom") return <StreamingBanner data={value} />;
            else return null;
         })}
         {/* <AdBanner /> */}
         <Hotdeals />
         <Brand />
         <FeaturedProducts />
      </Layout>
   );
}
