import React, { useEffect, useState } from "react";
import "../../scss/store/featuredproduct.scss";
import { Col } from "react-bootstrap";
import { get_featured_product } from "../../data/api";
import ReactStars from "react-rating-stars-component";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import { useNavigate } from "react-router";

function FeaturedProducts(props) {
   const [featureddata, setfeaturedData] = useState("");
   useEffect(() => {
      async function get_featuredproducts() {
         const res = await get_featured_product();
         const response = res.data;

         setfeaturedData(response);
      }

      get_featuredproducts();
   }, []);
   // console.log("value", featureddata);
   let navigate = useNavigate();
   return (
      <Widthcontainer>
         <div className="featured fixed-width mb-5">
            <p className="categories_title">Featured Products.</p>
            <p className="categories_description">Take a look at what’s new, right now.</p>
            <div className="product-list">
               {/* {Object.entries(productitems.shop.products).map( */}
               {Object.entries(featureddata).map(([key, value]) => {
                  if (key < 3) {
                     return (
                        <div
                           id="flex-product"
                           onClick={() => {
                              navigate("/store/" + value._id);
                           }}
                        >
                           <Product
                              category={value?.categories[0]?.title}
                              title={value.name}
                              price={value.price}
                              sale={value.sale.sale_price}
                              img={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                              rating={value.rating}
                              review_count={value?.reviews?.length}
                              // onClick={()=>{history.push('/store/1')}}
                           />
                        </div>
                     );
                  } else {
                     return null;
                  }
               })}
            </div>
         </div>
      </Widthcontainer>
   );
}

export function Product(props) {
   return (
      <div className="product px-2 py-3">
         <div className="text" style={{ flexBasis: "40%", marginLeft: "10px" }}>
            <p className="category">{props.category}</p>
            <p
               style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
               }}
            >
               {/* <p style={{ margin: "0px" }}>({props?.reviews?.length})</p> */}
            </p>
            <p className="featured_title">{props.title}</p>
            {props?.sale ? (
               <>
                  <p
                     style={{ textDecoration: "line-through", color: "#7b8698" }}
                     className="product-cost"
                  >
                     Rs. {props.price.toLocaleString("hi-IN")}
                  </p>
                  <p className="product-cost">Rs. {props.sale.toLocaleString("hi-IN")}</p>
               </>
            ) : (
               <p className="product-cost">Rs. {props.price.toLocaleString("hi-IN")}</p>
            )}

            {props?.review_count > 0 && (
               <ReactStars
                  value={props?.rating}
                  count={5}
                  edit={false}
                  size={24}
                  activeColor="#ffd700"
                  color="#D0D0D0"
               />
            )}
         </div>
         <img
            width="125px"
            height="120px"
            style={{ marginTop: "20px" }}
            src={props.img}
            alt="product"
         />
      </div>
   );
}
export default FeaturedProducts;
