import React, { useEffect, useState } from "react";
import Home from "./components/pages/home";
import forSeo from "./utils/seoTags";

function App() {
   return (
      <>
         <Home />
         {forSeo("DishHome Nepal", "DishHome", "DishHome ", process.env.REACT_APP_PUBLIC_URL)}
      </>
   );
}

export default App;
