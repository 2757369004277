import React, { useState, useEffect } from "react";
import "../../scss/packages/infobanner.scss";
import InfoCard from "./infocard";
import HD from "../../assets/package/hd.png";
import global from "../../assets/package/globalnetwork.png";
import stream from "../../assets/package/livestreaming.png";
import giftbox from "../../assets/package/giftbox.png";
import { get_why_dishhome } from "../../data/api";

const DhInfoBanner = ({ page }) => {
   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await get_why_dishhome();
         const response = res?.data?.data;
         setBannerData(response);
      }
      get_navigation();
   }, []);
   // console.log(bannerdata);
   return (
      <div className="infobanner_wrapper my-5">
         {Object.entries(bannerdata).map(([key, value]) => {
            return (
               <>
                  {value.page === page ? (
                     <>
                        <div className="text fixed-width my-2">
                           <h2 className="title">{value.title}</h2>
                        </div>
                        <div className="info_card_wrapper mb-5 fixed-width">
                           {Object.entries(value.whyDishhome).map(([key, item]) => {
                              return (
                                 <InfoCard
                                    color={
                                       key == 0
                                          ? "red"
                                          : key == 1
                                          ? "green"
                                          : key == 2
                                          ? "yellow"
                                          : "blue"
                                    }
                                    src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                                    title={item.title}
                                    description={item.description}
                                 />
                              );
                           })}
                        </div>
                     </>
                  ) : null}
               </>
            );
         })}
      </div>
   );
};

export default DhInfoBanner;
