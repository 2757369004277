import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../scss/pathaoFest.scss";
import BG2 from "../../assets/pathaoFest/images/bg2.jpg";
import BG1 from "../../assets/pathaoFest/images/bg1.png";
import HDVideo from "../../assets/pathaoFest/images/videoHD.mp4";
import Pattern from "../../assets/pathaoFest/images/pattern.png";
import DishHome from "../../assets/pathaoFest/images/dishhome.png";
import DHgoapp from "../../assets/pathaoFest/images/dhgoapp.png";
import WIFIpng from "../../assets/pathaoFest/images/wifi.png";
import off from "../../assets/pathaoFest/images/off.png";
import playstore from "../../assets/pathaoFest/images/play-store.png";
import appstore from "../../assets/pathaoFest/images/appstore.png";
import DHgo from "../../assets/pathaoFest/images/dhgo.png";
import pathaofoods from "../../assets/pathaoFest/images/pathaofoods.png";
import coupon from "../../assets/pathaoFest/images/coupon.png";
import wifi from "../../assets/pathaoFest/images/wifi.png";
import logo from "../../assets/pathaoFest/images/logo.png";
import pathao from "../../assets/pathaoFest/images/Pathao-logo.svg";
import { get_internet_package } from "../../data/api";

function PathaoFest() {
   useEffect(() => {
      // Initialize AOS when the component mounts
      AOS.init({
         disable: false,
         startEvent: "DOMContentLoaded",
         initClassName: "aos-init",
         animatedClassName: "aos-animate",
         useClassNames: false,
         disableMutationObserver: false,
         debounceDelay: 50,
         throttleDelay: 99,
         offset: 120,
         delay: 0,
         duration: 1400,
         easing: "ease",
         once: false,
         mirror: false,
         anchorPlacement: "top-bottom",
      });

      // Optionally, if you want to refresh AOS on component updates
      AOS.refresh();
   }, []);

   const [dashainOffer, setDashainOffer] = useState();

   useEffect(() => {
      async function getData() {
         const res = await get_internet_package();
         // Remove items where the `prices` array does not contain an object with `type` equal to "24 Months."
         const data = res?.data?.data?.filter((item) => item?.dashain_offer === true);
         const filteredDashainOffer = data
            ?.map((item) => {
               const pricesWith24Months = item.prices.filter((price) => price.type == "12 Months");
               if (pricesWith24Months.length > 0) {
                  return item;
               }
               return null;
            })
            .filter((item) => item !== null);
         console.log("Filtered Data", filteredDashainOffer);
         setDashainOffer(filteredDashainOffer);
      }
      getData();
   }, []);

   function calculateTotal(data) {
      const totalPrice = data?.internet_price + data?.router;
      return totalPrice;
   }
   function returnPrices(data) {
      return data;
   }
   return (
      <>
         <header class="site-header site-header--transparent " style={{ zIndex: "1" }}>
            <div class="nav-top">
               <div class="container">
                  <div class="row align-items-center justify-content-center">
                     <div class="col-6 col-md-6 col-lg-6">
                        <div class="brand-logo d-flex justify-content-center align-items-center">
                           <a href="#" class="d-flex align-items-center">
                              <img class="logo-light" src={logo} alt="brand logo" />

                              <i class="fa-solid fa-xmark mx-3"></i>
                              <img class="logo-light" src={pathao} alt="brand logo" />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <main>
            <div id="landing">
               <div class="bg-landing-image" style={{ backgroundImage: BG2 }}></div>
               <video src={HDVideo} muted autoPlay playsInline loop />
               <div class="container position-relative">
                  <div class="row align-items-center">
                     <div class="col-md-6 position-relative">
                        <div class="landing-content position-relative" style={{ zIndex: "2" }}>
                           <h1>
                              Feast on Flavor, Surf with Speed{" "}
                              <span>Pathao and DHFibernet Unite for a Delectable Connection!</span>
                           </h1>

                           <br />
                           <a href="#section-packages" class="button-5 white-button" role="button">
                              View Internet Plans
                           </a>
                           <a href="#more-info" class="button-5 white-button" role="button">
                              Learn More
                           </a>
                        </div>
                     </div>
                     {/* <!--<div class="col-md-6">-->
                    <!--    <img src="./images/mainpic.png" class="img-fluid mw-100" alt="" data-aos="fade-up"-->
                    <!--        data-aos-delay="500">-->
                    <!--</div>--> */}
                  </div>
               </div>
            </div>

            <section id="section-packages" style={{ backgroundImage: Pattern }}>
               <div class="container">
                  <h2 class="text-center mb-5" data-aos="fade-up">
                     Get an{" "}
                     <span class="red-color">
                        <strong>ADDITIONAL 25% DISCOUNT</strong>
                     </span>{" "}
                     on
                     <small class="d-block">Special Internet Packages</small>
                  </h2>
                  <div class="row justify-content-center">
                     {dashainOffer &&
                        Object.entries(dashainOffer)?.map(([key, value]) => (
                           <div class="col-md-4 mb-2" style={{ height: "460px" }}>
                              <div
                                 class="card text-center"
                                 data-aos="fade-up"
                                 data-aos-delay="200"
                                 style={{ height: "100%" }}
                              >
                                 <div
                                    class="card-header position-relative"
                                    style={{ height: "200px" }}
                                 >
                                    <h3 class="package-name">{value?.title}</h3>
                                    <p>{value?.description?.substring(0, 95)}</p>
                                 </div>
                                 <div class="card-body d-flex flex-column justify-content-between">
                                    <ul>
                                       <li>
                                          <p>
                                             <i class="fa-solid fa-circle-check"></i>
                                             &nbsp;&nbsp;Internet Charge: Rs.{" "}
                                             {returnPrices(
                                                //return the price ot type = 12 Months
                                                value?.prices?.filter(
                                                   (item) => item?.type === "12 Months",
                                                )[0],
                                             )?.internet_price?.toLocaleString("hi-IN")}
                                          </p>
                                       </li>
                                       <li>
                                          <p>
                                             <i class="fa-solid fa-circle-check"></i>
                                             &nbsp;&nbsp;Router Rental Charge: Rs.{" "}
                                             {returnPrices(
                                                //return the price ot type = 12 Months
                                                value?.prices?.filter(
                                                   (item) => item?.type === "12 Months",
                                                )[0],
                                             )?.router?.toLocaleString("hi-IN")}
                                          </p>
                                       </li>
                                    </ul>
                                    <div class="card-bottom">
                                       <p>
                                          <strong>
                                             Rs.{" "}
                                             {calculateTotal(
                                                //return the price ot type = 12 Months
                                                value?.prices?.filter(
                                                   (item) => item?.type === "12 Months",
                                                )[0],
                                             )?.toLocaleString("hi-IN")}
                                             */12 Months
                                          </strong>
                                       </p>
                                       <a
                                          href={`/internet/${value?._id}?type=12 Months`}
                                          class="button-5 w-100"
                                          role="button"
                                       >
                                          Choose Plan
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        ))}
                  </div>
                  <div class="text-center mt-4" data-aos="fade-up">
                     <p>
                        <small>
                           <i>*Only applicable to pathao food customers with a valid coupon.</i>
                        </small>
                     </p>
                  </div>
               </div>
            </section>

            <section id="more-prizes">
               <div class="container">
                  <h2 class="text-center mb-5 aos-init aos-animate" data-aos="fade-up">
                     Special Offers & Prizes
                  </h2>
                  <div class="row align-items-stretch">
                     <div class="col-sm-3" data-aos="fade-up">
                        <div class="prize-card h-100">
                           <div class="prize-image">
                              <img src={DishHome} alt="" />
                           </div>
                           <p>
                              Order from Pathao Foods and Get a chance to win{" "}
                              <strong class="red-color">
                                 <br /> 1-month FREE{" "}
                              </strong>
                              DishHome Subscription.
                           </p>
                        </div>
                     </div>
                     <div class="col-sm-3" data-aos="fade-up">
                        <div class="prize-card h-100">
                           <div class="prize-image">
                              <img src={DHgoapp} alt="" />
                           </div>
                           <p>
                              Along with your tasty food, get{" "}
                              <strong class="red-color">3 months free subscription</strong> of{" "}
                              <strong class="red-color">DH GO App</strong>
                           </p>
                        </div>
                     </div>

                     <div class="col-sm-3" data-aos="fade-up">
                        <div class="prize-card h-100">
                           <div class="prize-image">
                              <img src={WIFIpng} alt="" />
                           </div>
                           <p>
                              Along with your order, get a chance to win{" "}
                              <strong class="red-color ">
                                 <br /> Free 6-months
                              </strong>{" "}
                              internet subscription on a new connection.
                           </p>
                        </div>
                     </div>

                     <div class="col-sm-3" data-aos="fade-up">
                        <div class="prize-card h-100">
                           <div class="prize-image">
                              <img src={off} alt="" />
                           </div>
                           <p>
                              Get <strong class="red-color"> an additional 25% discount</strong> on
                              new DH Fibernet Connection.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div class="text-center mt-4" data-aos="fade-up">
                     <p>
                        <small>
                           <i>*Only applicable to pathao food customers with a valid coupon.</i>
                        </small>
                     </p>
                  </div>
               </div>
            </section>

            <div id="dhgo" style={{ backgroundImage: BG1 }}>
               <div class="container">
                  <div class="row align-items-center justify-content-between">
                     <div class="col-sm-6">
                        <h2 data-aos="fade-up">
                           <span class="subtitle">Download Happiness</span>
                           Download <strong>DH GO App</strong>
                        </h2>
                        <p class="lead" data-aos="fade-up">
                           Unleash the power of on-the-go movie and TV show streaming, right at your
                           fingertips with DH GO App. Immerse yourself in a seamless viewing
                           experience anytime, anywhere, because your favorite content should always
                           be just a tap away!
                        </p>

                        <p class="text-white lead mt-3" data-aos="fade-up">
                           <strong>Download Now</strong>
                        </p>
                        <div
                           class="d-flex align-items-center justify-content-start gap-3"
                           data-aos="fade-up"
                        >
                           <a
                              href="https://play.google.com/store/apps/details?id=com.conax.golive.dishnepal&pcampaignid=web_share"
                              target="_blank"
                           >
                              <img
                                 src={playstore}
                                 alt=""
                                 class="img-fluid mw-100 mx-auto d-block"
                              />
                           </a>
                           <a
                              href="https://apps.apple.com/zm/app/dishhome-go/id1491775721?platform=iphone"
                              target="_blank"
                           >
                              <img src={appstore} alt="" class="img-fluid mw-100 mx-auto d-block" />
                           </a>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <img
                           src={DHgo}
                           alt=""
                           id="dhgo-app"
                           class="img-fluid mw-100"
                           data-aos="fade-up"
                        />
                     </div>
                  </div>
               </div>
            </div>

            <section id="more-info">
               <div class="container">
                  <h2 class="text-center" data-aos="fade-up">
                     #
                     <span class="red-color">
                        <strong>FoodFestConnect</strong>
                     </span>
                  </h2>
                  <div class="row">
                     <div class="col-md-4 text-center position-relative step s1" data-aos="fade-up">
                        <span class="number">1</span>
                        <img src={pathaofoods} alt="" class="img-fluid mw-100 shadows" />
                        <h4 class="mt-3">1. Order with Pathao Foods</h4>
                     </div>
                     <div class="col-md-4 text-center position-relative step s2" data-aos="fade-up">
                        <span class="number">2</span>
                        <img src={coupon} alt="" class="img-fluid mw-100 shadows" />
                        <h4 class="mt-3">2. Get The DishHome Coupon</h4>
                     </div>
                     <div class="col-md-4 text-center position-relative step " data-aos="fade-up">
                        <span class="number">3</span>
                        <img src={wifi} alt="" class="img-fluid mw-100 shadows" />
                        <h4 class="mt-3">3. Get DH Fibernet New Connection</h4>
                     </div>
                  </div>
               </div>
               <div class="container py-5">
                  <hr />
               </div>
               <div class="container ">
                  <div class="row">
                     <div class="col-md-6">
                        <p class="text-center mb-5" data-aos="fade-up">
                           Download <strong>the Pathao app</strong> now and unlock exclusive offers
                           on
                           <strong>DHFibernet</strong>. Savor the taste, surf with speed – it's a
                           combo worth indulging in! <br /> #
                           <span class="red-color">FoodFestConnect</span>"
                        </p>
                        <div class="row justify-content-center">
                           <div class="col-4 col-sm-2" data-aos="fade-up">
                              <a
                                 href="https://play.google.com/store/apps/details?id=com.pathao.user&hl=en"
                                 target="_blank"
                              >
                                 <img
                                    src={playstore}
                                    alt=""
                                    class="img-fluid mw-100 mx-auto d-block"
                                 />
                              </a>
                           </div>
                           <div class="col-4 col-sm-2" data-aos="fade-up">
                              <a
                                 href="https://itunes.apple.com/us/app/pathao/id1201700952?mt=8"
                                 target="_blank"
                              >
                                 <img
                                    src={appstore}
                                    alt=""
                                    class="img-fluid mw-100 mx-auto d-block"
                                 />
                              </a>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 mt-5 mt-lg-0">
                        <p class="text-center mb-5" data-aos="fade-up">
                           Download <strong class="red-color">the DH GO App</strong> now and stream
                           some of the best Nepali movies available exlusively on the{" "}
                           <strong> DH GO App</strong> <br /> #
                           <span class="red-color">FoodFestConnect</span>"
                        </p>
                        <div class="row justify-content-center">
                           <div class="col-4 col-sm-2" data-aos="fade-up">
                              <a
                                 href="https://play.google.com/store/apps/details?id=com.conax.golive.dishnepal&pcampaignid=web_share&pli=1"
                                 target="_blank"
                              >
                                 <img
                                    src={playstore}
                                    alt=""
                                    class="img-fluid mw-100 mx-auto d-block"
                                 />
                              </a>
                           </div>
                           <div class="col-4 col-sm-2" data-aos="fade-up">
                              <a
                                 href="https://apps.apple.com/zm/app/dishhome-go/id1491775721?platform=iphone"
                                 target="_blank"
                              >
                                 <img
                                    src={appstore}
                                    alt=""
                                    class="img-fluid mw-100 mx-auto d-block"
                                 />
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>
      </>
   );
}

export default PathaoFest;
