import { React, useState, useMemo, useEffect, useRef } from "react";
import Layout from "../../../components/include/layout";
import Logo from "../../../components/include/logo";
import "../../../scss/packages/order.scss";
import "../../../scss/pages/form.scss";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Token from "../../../utils/useToken";
import district from "../../../data/location/district";
import municipality from "../../../data/location/municipality";
import myKey from "../../../store/pages/khaltiKey";
import axios from "axios";
import { baseUrl } from "../../../constants/defaultValue";
import useToken from "../../../utils/useToken";

import province from "../../../data/location/province";
import Khalticonfig from "../../../store/pages/khaltiConfig";
import {
   get_tv_package,
   get_individual_package,
   get_shipping_charge,
   get_single_tv,
   get_company_details,
} from "../../../data/api";
import { check_footprint, get_bonus_list } from "../../../data/protectedapi";
import {
   get_redeem_point,
   get_voucher_discount,
   order_tv_package,
   recharge,
} from "../../../data/protectedapi";
import Loading from "../../../components/customcomponents/loading";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import esewa from "../../../assets/store/esewa.webp";
import ips from "../../../assets/store/ips.png";
import paypoint from "../../../assets/store/paypoint.png";
import prabhu from "../../../assets/store/prabhu.png";
import ime from "../../../assets/store/ime.png";
import khalti from "../../../assets/cart/khalti.png";
import { MapContainer, useMap, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import MessageModal from "../../../components/customcomponents/messageModal";
import { Alert, InputGroup } from "react-bootstrap";
import { MdError } from "react-icons/md";
import Dropdown from "react-dropdown";
import KhaltiCheckout from "khalti-checkout-web";
import { Button } from "react-bootstrap";
import RewardPoint from "../../../components/customcomponents/rewardPoint";
import RemainingReward from "../../../components/customcomponents/remainingReward";
import { handleOnlinePayment } from "../../../components/pages/payment/payment";
import { BiSearch, BiSearchAlt } from "react-icons/bi";
import { Tooltip } from "react-leaflet/Tooltip";
import { useMediaQuery } from "react-responsive";

const TVPackageOrder = () => {
   //use params
   const productParams = useParams();
   //use Query Function
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }

   //Variables
   const parameter = useParams();
   const { packagename } = parameter;

   const { userDetails } = Token();
   let query = useQuery();
   let type = query.get("type");
   let navigate = useNavigate();

   //use States
   const [name, setName] = useState(userDetails?.name);
   const [phone, setPhone] = useState(userDetails?.phone);
   const [email, setEmail] = useState(userDetails?.email);
   const [totaldiscount, setTotaldiscount] = useState(0);

   const [allPackage, setAllPackage] = useState("");
   const [indvPackage, setIndvPackage] = useState("");
   const [packageId, setPackageId] = useState(packagename);
   const [packageType, setPackageType] = useState(type);
   const [packagePrice, setPackagePrice] = useState();
   const [loading, setLoading] = useState(true);
   const [recharge, setRecharge] = useState(0);
   const [tvnumber, setTvnumber] = useState("1");
   const [userDistrict, setUserDistrict] = useState("");
   const [userProvince, setUserProvince] = useState("");
   const [userMunicipality, setUserMunicipality] = useState(municipality[0].value);
   const [address, setAddress] = useState("");
   const [shippingCharge, setShippingcharge] = useState("");
   const [redeemDiscount, setRedeemdiscount] = useState("");

   const [wardNo, setWardNo] = useState("");
   const [price, setPrice] = useState("");
   const [installationCharge, setInstallationCharge] = useState(0);
   const [settopboxPrice, setSettopboxPrice] = useState(0);
   const [packageTitle, setPackageTitle] = useState();
   const [loactionInput, setLoactionInput] = useState("location");
   const [paymentProcess, setPaymentProcess] = useState(false);
   const [paymentMethod, setPaymentMethod] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [error, setError] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [shippingDetails, setShippingDetails] = useState("");
   const [esewamodal, setEsewamodal] = useState(false);
   const [esewaStatus, setesewaStatus] = useState(false);
   const [prabhuStatus, setprabhuStatus] = useState(false);
   const [connectIpsStatus, setconnectIpsStatus] = useState(false);
   const [imeStatus, setimeStatus] = useState(false);
   const [khaltiStatus, setkhaltiStatus] = useState(false);
   const [paymentProceed, setPaymentproceed] = useState(false);
   const [packageloading, setpackageLoading] = useState(false);
   const [btnloading, setBtnloading] = useState(false);
   const [position, setPosition] = useState({ lat: 27.7172, lng: 85.324 });
   // const [bonusdata, setBonusData] = useState("");
   const [additional, setAdditional] = useState("");
   const [nooftv, setNooftv] = useState("");
   let rechargeoptions = ["0"];
   const [totalprice, setTotalprice] = useState();

   let defaultOption = rechargeoptions[0];
   // let tvoptions = [];
   const [tvoptions, setTvoptions] = useState("");

   let tvdefaultOption = tvoptions[0];

   const [paymentgateway, setPaymentgateway] = useState("");
   const [filterededitAddress, setFilterededitAddress] = useState([]);
   const [location, setLocation] = useState("");
   const [vouchername, setVouchername] = useState("");
   const [errorVoucher, seterrorVoucher] = useState(false);
   const [successVoucher, setsuccessVoucher] = useState(false);
   const [redeemAmounttoPay, setRedeemAmounttoPay] = useState(null);
   const [rememberMe, setRememberme] = useState(false);

   const [discountamount, setDiscountamount] = useState(0);
   const provinceoptions = ["1", "2", "3", "4"];
   let districtOption1 = [];
   let municipalityOption = [];
   const handleMunicipality = (option) => {
      setUserMunicipality(option.label);
   };
   useEffect(() => {
      async function shipping_charge() {
         const res = await get_shipping_charge();
         const response = res?.data?.data;

         setShippingDetails(response);
      }
      shipping_charge();
   }, []);
   useEffect(() => {
      [...shippingDetails].map((item) => {
         if (item.address == userDistrict && item.province == userProvince) {
            setShippingcharge(item.amount);
         } else {
         }
      });
   }, [userDistrict, userProvince]);
   useEffect(() => {
      let newfilterededitaddress = [];
      [...shippingDetails].map((item) => {
         if (item.province === userProvince) {
            newfilterededitaddress.push(item);
            setFilterededitAddress(newfilterededitaddress);
         } else {
            setFilterededitAddress(newfilterededitaddress);
         }
      });
   }, [userProvince]);
   const handleDistrict = (option) => {
      setUserDistrict(option.label);
   };

   //useEffect Hooks

   useEffect(() => {
      async function fetchData() {
         const res = await get_tv_package();
         setAllPackage(res?.data?.data);
      }
      fetchData();
   }, []);

   //recharge option
   // useEffect(() => {
   //    async function get_bonus() {
   //       const res = await get_bonus_list();
   //       const response = res?.data?.data;

   //       if (response === undefined) {
   //          setBonusData("");
   //       } else {
   //          setBonusData(response);
   //       }
   //    }
   //    get_bonus();
   // }, []);
   useEffect(() => {
      async function fetchData() {
         const response = await get_individual_package(packageId);
         setIndvPackage(response?.data?.data);
      }
      fetchData();
   }, [packageId]);

   const [vouchercode, setVouchercode] = useState("");
   const [vouchermessage, setVouchermessage] = useState("");
   const [vouchermessagesuccess, setVouchermessageSuccess] = useState("");
   const [isVatIncluded, setIsVatIncluded] = useState(false);

   const [voucherid, setVoucherid] = useState("");
   // console.log(
   //    "vouchername",
   //    price +
   //       installationCharge +
   //       parseInt(recharge) +
   //       additional * (tvnumber - 1) +
   //       settopboxPrice,
   // );
   const sendVoucher = async () => {
      setRememberme(false);
      const response = await get_voucher_discount(
         price +
            installationCharge +
            parseInt(recharge) +
            additional * (tvnumber - 1) +
            settopboxPrice,
         vouchername,
         "Tv",
         packageId,
      );
      if (response?.status === 200) {
         setVouchermessage(response?.data?.message);
         setDiscountamount(Math.round(response?.data?.data?.discountAmount));
         setVoucherid(response?.data?.data?.voucherId);
         setVouchercode(response?.data?.data?.voucherCode);
         setsuccessVoucher(true);
         seterrorVoucher(false);
      } else {
         setVouchermessage(response?.response?.data?.message);
         seterrorVoucher(true);
         setTimeout(() => {
            seterrorVoucher(false);
         }, 5000);
         setsuccessVoucher(false);
      }
   };
   useEffect(() => {
      if (discountamount >= 0) {
         setTotaldiscount(discountamount);
      }
      if (redeemDiscount >= 0) {
         setTotaldiscount(redeemDiscount);
      }
      if (discountamount >= 0 && redeemDiscount >= 0) {
         setTotaldiscount(discountamount + redeemDiscount);
      }
   }, [discountamount, redeemDiscount]);

   const handlevoucherChange = (e) => {
      setVouchername(e.target.value);
   };

   useEffect(() => {
      if (indvPackage === "") {
         setLoading(true);
      } else {
         setRedeem(0);
         setRedeemdiscount(0);
         setLoading(false);
         setPackagePrice(indvPackage?.prices);
         setPackageTitle(indvPackage?.title);
      }
   }, [indvPackage]);

   useEffect(() => {
      if (packagePrice === undefined) {
         setPrice("");
         // setInstallationCharge("");
         // setSettopboxPrice("");
      } else {
         Object.entries(packagePrice).map(([key, value]) => {
            if (value.type === packageType) {
               setRedeem(0);
               setRedeemdiscount(0);
               setPrice(value?.price);
               setInstallationCharge(value?.installation_charge);
               setSettopboxPrice(value?.settopbox_price);
               setAdditional(value?.additional);
               setNooftv(value?.multiple);
            }
         });
      }
   }, [packagePrice, packageType, installationCharge, settopboxPrice, nooftv]);

   let totalPricetoPay =
      price + installationCharge + settopboxPrice + additional * (tvnumber - 1) - discountamount;

   // const vatAmount = Math.floor((13 / 100) * totalPricetoPay);
   const [vatAmount, setVatAmount] = useState(0);
   const [initialVat, setInitialVat] = useState(0);

   useEffect(() => {
      {
         redeemAmounttoPay !== null && rememberMe === true ? (
            <>{setVatAmount(Math.round((13 / 100) * redeemAmounttoPay))}</>
         ) : (
            <>
               {setVatAmount(Math.round((13 / 100) * totalPricetoPay))}
               {setInitialVat(Math.round((13 / 100) * totalPricetoPay))}
            </>
         );
      }
   }, [redeemAmounttoPay, totalPricetoPay, rememberMe]);

   //Functions

   const [redeem, setRedeem] = useState("");
   const [redeemModal, setRedeemmodal] = useState(false);
   const [redeemErrorModal, setRedeemErrormodal] = useState(false);
   const [message, setMessage] = useState("");
   const handleReedem = async (e) => {
      setRememberme(e.target.checked);
      if (e.target.checked) {
         const response = await get_redeem_point(TotalPrice);

         if (response?.status === 200) {
            setRedeemdiscount(Math.floor(response?.data?.discount));
            setRedeem(response?.data.usedReward);
            setRedeemAmounttoPay(Math.round(response?.data?.additionalAmountToPay));
            setMessage(response?.data?.message);
         } else {
            setRedeemErrormodal(true);
            setMessage(response?.response?.data?.message);
         }
      }
   };

   useEffect(() => {
      if (rememberMe === false) {
         setRedeemdiscount(0);
         setRedeem(0);
         setRedeemAmounttoPay(null);
      }
   }, [rememberMe]);
   const handleProvince = (option) => {
      setUserProvince(option.label);
   };
   //get title
   const [tvTitle, setTvtitle] = useState("");
   useEffect(() => {
      async function get_single() {
         const res = await get_single_tv(packagename);
         const response = res?.data?.data;
         const companyProfile = await get_company_details();
         setIsVatIncluded(companyProfile?.data?.data?.include_tax?.includes("TV"));
         setTvtitle(response.title);
      }
      get_single();
   }, [packagename]);

   const handlePackageChange = (option) => {
      setRememberme(false);
      navigate("/tv/order/" + option.value + "?type=" + packageType);
      setVouchercode("");
      setDiscountamount(0);
      setVouchermessage("");
      // setPackageId(e.target.value);
      setPackageId(option.value);
   };
   const handlepackagetypeChange = (e) => {
      setRememberme(false);
      navigate("/tv/order/" + packageId + "?type=" + e.label);
      setVouchercode("");
      setDiscountamount(0);
      setVouchermessage("");
      setPackageType(e.label);
   };
   const totalPrice =
      price +
      installationCharge +
      settopboxPrice +
      Math.floor(recharge) +
      additional * (nooftv - 1) -
      discountamount;
   const subTotal = (totalPrice * 100) / 113;
   const tax = totalPrice - subTotal;
   const TotalPrice =
      price +
      installationCharge +
      settopboxPrice -
      discountamount +
      parseInt(recharge) +
      additional * (tvnumber - 1);

   let paymentMethods = query.get("method");
   //payment
   let totalAmount = price + installationCharge + settopboxPrice;
   var params = {
      amt:
         redeemAmounttoPay !== null && rememberMe === true
            ? parseInt(redeemAmounttoPay)
            : TotalPrice + parseInt(recharge),
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt:
         redeemAmounttoPay !== null && rememberMe === true
            ? parseInt(redeemAmounttoPay)
            : TotalPrice + parseInt(recharge),
      pid: Math.random().toString(36).slice(2),
      scd: "EPAYTEST",
      su: `${process.env.REACT_APP_PUBLIC_URL}tv/order/${productParams.packagename}?type=${type}&querydistrict=${userDistrict}&querymunicipality=${userMunicipality}&queryaddress=${address}&queryprovince=${userProvince}&queryward=${wardNo}&queryvoucher=${vouchercode}&queryvoucherid=${voucherid}&method=esewa`,
      fu: `${process.env.REACT_APP_PUBLIC_URL}failurepay`,
   };

   let oid = query.get("oid");
   let queryvoucher = query.get("queryvoucher");
   let queryvoucherid = query.get("queryvoucherid");

   // useEffect(() => {
   //    if (oid) {
   //       setpackageLoading(true);
   //    }
   // }, [oid]);
   // useEffect(() => {
   //    axios
   //       .get(
   //          `${process.env.REACT_APP_BASE_URL}v1/shop/payment/esewa/verify?oid=${oid}&amt=${amt}&refId=${refid}`,
   //       )

   //       .then((responseData) => {
   //          console.log(responseData.data);

   //          // navigate("/cart");
   //          if (responseData.status === 200 && packageTitle && price) {
   //             console.log(packageTitle);

   //             setEsewamodal(true);
   //             handleOrder();
   //          }
   //       })
   //       .catch((err) => {
   //          console.log(err);
   //       });
   // }, [esewamodal, packageTitle, price, installationCharge, settopboxPrice]);
   useEffect(() => {
      if (oid) {
         setpackageLoading(true);
      }
   }, [oid]);

   //footprint
   const [checkFootprint, setCheckFootprint] = useState(false);
   const [footprinterror, setfootprintError] = useState(false);
   const handleFootprint = async () => {
      setLoading(true);
      const res = await check_footprint(
         packageId,
         packageTitle,
         name,
         email,
         phone?.length > 10 ? (phone?.slice(0, 4) === "+977" ? phone?.slice(4, 18) : phone) : phone,
         recharge,
         tvnumber,
         name,
         address,
         userProvince ? userProvince : location?.Region,
         wardNo,
         userMunicipality ? userMunicipality : location?.Subregion,
         address ? address : location?.Address,
         price,
         installationCharge > 0 ? installationCharge : 0,
         redeemAmounttoPay !== null
            ? redeemAmounttoPay
            : price + installationCharge + settopboxPrice + additional * (tvnumber - 1),
         settopboxPrice > 0 ? settopboxPrice : 0,
         paymentMethods ? paymentMethods : paymentMethod,
         additional,
         position,
         "TV",
      );
      if (res?.statusText === "OK") {
         setLoading(false);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
         setPaymentProcess(true);

         setCheckFootprint(true);
      }
      if (res?.response?.statusText === "Forbidden") {
         setLoading(false);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
         setPaymentProcess(true);

         setCheckFootprint(false);
      }
      if (res?.response?.statusText === "Not Found") {
         setLoading(false);
         setfootprintError(true);
         setCheckFootprint(false);
      } else {
         setLoading(false);
      }
   };

   const handleOrder = async () => {
      setLoading(true);
      const res = await order_tv_package(
         packageId,
         packageTitle,
         name,
         phone,
         email,
         recharge,
         tvnumber,
         userDistrict ? userDistrict : location?.Subregion,
         userProvince ? userProvince : location?.Region,
         position,
         userMunicipality ? userMunicipality : location?.Subregion,
         address ? address : location?.Address,
         wardNo,
         totaldiscount ? Math.floor(totaldiscount) : 0,
         Math.round(
            redeemAmounttoPay !== null
               ? redeemAmounttoPay
               : price +
                    installationCharge +
                    settopboxPrice +
                    additional * (tvnumber - 1) -
                    discountamount,
         ),
         redeemAmounttoPay !== null
            ? redeemAmounttoPay + vatAmount
            : price +
                 installationCharge +
                 settopboxPrice +
                 parseInt(recharge) +
                 additional * (tvnumber - 1) +
                 vatAmount -
                 discountamount,
         price,
         redeem,
         vatAmount,
         queryvoucher ? queryvoucher : vouchercode ? vouchercode : null,
         queryvoucherid ? queryvoucherid : voucherid ? voucherid : null,
         installationCharge > 0 ? installationCharge : 0,
         settopboxPrice > 0 ? settopboxPrice : 0,
         paymentMethod,
         additional,
         type,
      );
      if (res.status === 201) {
         setMessage(res?.data?.message);
         setMessage(
            "Thank you for your purchase! You will get a call from our team shortly for confirmation and installation",
         );
         setBtnloading(false);
         // if (res?.data?.data?.payment_method === "khalti") {
         //    const data = await handleOnlinePayment({
         //       order_id: res?.data?.data?.orderId,
         //       payment_method: res?.data?.data?.payment_method,
         //       order_name: res?.data?.data?.order_name,
         //       uid: userDetails?.id,
         //       setSuccess: setMessageModal,
         //    });
         //    const checkout = new KhaltiCheckout(data);
         //    checkout.show({ amount: res?.data?.data?.total * 100 });
         //    setLoading(false);
         // } else
         if (res?.data?.data?.payment_method === "cod") {
            setLoading(false);
            setMessage(res?.data?.message);
            setMessageModal(true);
            setMessage(
               "Thank you for your purchase! You will get a call from our team shortly for confirmation and installation",
            );
         } else
            handleOnlinePayment({
               order_id: res?.data?.data?.orderId,
               payment_method: res?.data?.data?.payment_method,
               order_name: res?.data?.data?.order_name,
               total: res?.data?.data?.total,
               uid: userDetails?.id,
            });

         // setMessageModal(true);
         // setCartloading(false);
      } else {
         setLoading(false);
         setError(true);
         setMessage(res?.response?.data?.message);
         setpackageLoading(false);
         setBtnloading(false);
      }
   };

   const handleLocationInput = (event) => {
      setLoactionInput(event.target.value);
   };
   const handlePaymentprocess = () => {
      if (loactionInput === "map") {
         setPaymentProcess(true);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
      } else if (
         wardNo === "" ||
         address === "" ||
         userProvince === "" ||
         userDistrict === "" ||
         userMunicipality === ""
      ) {
         setPaymentProcess(false);
         setErrorMessage("Please fill all the fields");
      } else {
         setPaymentProcess(true);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
      }
   };
   const [errorPyament, setErrorPayment] = useState(false);
   const [checkPayment, setCheckpayment] = useState(false);
   const [selectOnlinePayment, setSelectOnlinePayment] = useState(false);

   const handlePaymentcheck = () => {
      if (checkPayment === false) {
         setErrorPayment(true);
      }
      if (checkPayment === false && selectOnlinePayment === false) {
         setErrorPayment(true);
      }
      if (checkPayment === true && selectOnlinePayment === false) {
         setErrorPayment(true);
      }
   };

   // Variables
   let packageOptions = [];
   let priceSection = [];

   useEffect(() => {
      setTvoptions("");
      setTvnumber(1);
      for (let i = 0; i < nooftv; i++) {
         setTvoptions((old) => [...old, { label: (i + 1).toString(), value: i + 1 }]);

         // tvoptions.push({ label: (i + 1).toString(), value: i + 1 });
      }
   }, [nooftv, type]);
   const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

   return loading || packageloading ? (
      <Loading />
   ) : (
      <Layout>
         <MessageModal
            show={footprinterror}
            setShow={setfootprintError}
            heading={"Not available"}
            message={
               "Sorry, we don't have service in your area yet. We will contact you once we are available there."
            }
            buttonText="OK"
            link="/tv/dishhome-dth"
            type={"error"}
         />
         <MessageModal
            show={messageModal}
            isNavigate={true}
            setShow={setMessageModal}
            heading="Purchase Successful"
            message="Thank you for your purchase! You will get a call from our team shortly for confirmation and installation."
            button2={"My Orders"}
            buttonText="Home Page"
            link="/"
            link2="/dashboard?type=9&option=package"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Purchase Failed"}
            message={"Something went wrong. Please try paying again from My Orders page."}
            button2={"My Orders"}
            buttonText="Home Page"
            link="/"
            link2="/dashboard?type=9&option=package"
            type={"error"}
         />
         <MessageModal
            show={redeemModal}
            isNavigate={true}
            setShow={setRedeemmodal}
            heading=""
            message={message}
            buttonText="OK"
         />
         <MessageModal
            show={redeemErrorModal}
            setShow={setRedeemErrormodal}
            heading={"Invalid"}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         <div className="tv_package_wrapper">
            {/* <div className="dh_banner">
                <Logo />
                <p className="dh_banner_text">
                    Get Your Super HD Package <span>Right Now</span>
                </p>
            </div> */}

            <div className="tvpackage_order_info py-5 px-5 fixed-width">
               {paymentProcess === true ? (
                  <PaymentMethod
                     checkFootprint={checkFootprint}
                     setErrorPayment={setErrorPayment}
                     setCheckpayment={setCheckpayment}
                     errorPyament={errorPyament}
                     checkPayment={checkPayment}
                     handlePaymentcheck={handlePaymentcheck}
                     handleOrder={handleOrder}
                     setPaymentMethod={setPaymentMethod}
                     esewaStatus={esewaStatus}
                     connectIpsStatus={connectIpsStatus}
                     prabhuStatus={prabhuStatus}
                     imeStatus={imeStatus}
                     khaltiStatus={khaltiStatus}
                     setPaymentgateway={setPaymentgateway}
                     setkhaltiStatus={setkhaltiStatus}
                     setconnectIpsStatus={setconnectIpsStatus}
                     setimeStatus={setimeStatus}
                     setprabhuStatus={setprabhuStatus}
                     setesewaStatus={setesewaStatus}
                     paymentMethod={paymentMethod}
                  />
               ) : (
                  <div className="order_info ">
                     <p className="text">Your Package Information</p>
                     <label htmlFor="package">Your Package</label>
                     {Object.entries(allPackage).map(([key, value]) => {
                        packageOptions.push({ value: value._id, label: value.title });
                        // return (
                        //    <option
                        //       value={value?._id}
                        //       selected={packageId === value?._id}
                        //       id={value?._id}
                        //    >
                        //       {value?.title}
                        //    </option>
                        // );
                     })}

                     <Dropdown
                        options={packageOptions}
                        onChange={handlePackageChange}
                        // value={packageId}
                        value={tvTitle}
                        placeholder="Select an option"
                     />
                     {/* <select name="package" id="package" onChange={(e) => handlePackageChange(e)}>
                       
                     </select> */}
                     <div className="input_container">
                        <div className="name_container">
                           <label htmlFor="name" id="name">
                              Full name
                           </label>
                           <input
                              type="text"
                              name="name"
                              className="name_input"
                              disabled
                              placeholder=""
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                           />
                        </div>
                        <div className="phone_number_container">
                           <label htmlFor="number" id="number">
                              Contact Number
                           </label>
                           <input
                              type="number"
                              name="number"
                              className="ph_number_input"
                              disabled
                              placeholder=""
                              value={phone}
                              onChange={(e) => {
                                 if (e?.nativeEvent?.data) {
                                    if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                       setPhone(e.target?.value);
                                    }
                                    if (e?.nativeEvent?.data === null) {
                                       setPhone(e?.target?.value);
                                    }
                                 } else {
                                    setPhone(e?.target?.value);
                                 }
                              }}
                           />
                        </div>
                     </div>
                     <div className="input_container">
                        {/* <div className="recharge_amount_container">
                           <label htmlFor="recharge">Recharge Amount</label>
                           {Object.entries(bonusdata)?.map(([key, value]) => {
                              rechargeoptions.push({
                                 value: value?.recharge_value,
                                 label: value?.recharge_value?.toString(),
                              });
                              return null;
                           })}
                           <Dropdown
                              id="input-dashboard"
                              options={rechargeoptions}
                              onChange={(e) => setRecharge(e.label)}
                              value={defaultOption}
                           />
                        </div> */}
                        <div className="television_no_container">
                           <label htmlFor="tv">Total No. of Television</label>

                           {/* {Object.entries(packagePrice)?.map(([key, value]) => {
                              tvoptions.push({
                                 // value: value?.recharge_value,
                                 label: value?.multiple,
                              });
                           })} */}

                           <Dropdown
                              id="input-dashboard"
                              options={tvoptions}
                              onChange={(e) => {
                                 setVoucherid("");
                                 setVouchercode("");
                                 setDiscountamount(0);
                                 setVouchermessage("");
                                 setTvnumber(e.label);
                                 setRememberme(false);
                              }}
                              value={tvdefaultOption}
                           />
                        </div>
                     </div>
                     <p className="text mt-3 mb-2">Installation Address</p>
                     {/* <Alert
                        variant="danger"
                        className={errorMessage === "" ? "Order_error_hide" : "Order_error_show"}
                     >
                        <MdError />
                        &nbsp;&nbsp;
                        {errorMessage}
                     </Alert> */}
                     {/* <FormControl style={{ marginBottom: "15px" }}>
                        <RadioGroup
                           row
                           aria-labelledby="demo-row-radio-buttons-group-label"
                           name="row-radio-buttons-group"
                           defaultValue="location"
                           onChange={handle
                           }
                        >
                           <FormControlLabel
                              value="location"
                              control={<Radio />}
                              label="Location"
                           />
                           <FormControlLabel
                              value="map"
                              control={<Radio />}
                              label="Choose from map"
                           />
                        </RadioGroup>
                     </FormControl> */}
                     {
                        <>
                           <MapWrapper
                              location={location}
                              setLocation={setLocation}
                              position={position}
                              setPosition={setPosition}
                              loactionInput={loactionInput}
                           />
                           {isMobile && (
                              <button
                                 style={{ marginTop: "15px", width: "106px" }}
                                 className="order_map_btn"
                                 // onClick={orderNow}
                                 onClick={handleFootprint}
                              >
                                 Continue
                              </button>
                           )}
                        </>

                        // : (
                        //       <>
                        //          {" "}
                        //          <div className="input_container">
                        //             <div className="zone_container">
                        //                {" "}
                        //                <div>
                        //                   <label>Province</label>
                        //                   <Dropdown
                        //                      options={provinceoptions}
                        //                      onChange={handleProvince}
                        //                      value={userProvince}
                        //                      // style={{ width: "250px" }}
                        //                      className={
                        //                         userProvince === "" && errorMessage !== ""
                        //                            ? "dropdown-color"
                        //                            : ""
                        //                      }
                        //                   />
                        //                </div>
                        //                {/* <label htmlFor="zone">Select Province</label>
                        //                <select
                        //                   name="zone"
                        //                   id="zone"
                        //                   onChange={(e) => setUserProvince(e.target.value)}
                        //                >
                        //                   {province.map((item, index) => {
                        //                      return <option value={item.value}>{item.label}</option>;
                        //                   })}
                        //                </select> */}
                        //             </div>
                        //             <div className="district_container">
                        //                <label>District</label>

                        //                {Object.entries(filterededitAddress).map(([key, value]) => {
                        //                   districtOption1.push({
                        //                      // value: value.value,
                        //                      label: value.address ? value.address : "",
                        //                   });
                        //                   return null;
                        //                })}
                        //                <Dropdown
                        //                   onChange={handleDistrict}
                        //                   options={districtOption1}
                        //                   value={userDistrict}
                        //                   className={
                        //                      userDistrict === "" && errorMessage !== ""
                        //                         ? "dropdown-color"
                        //                         : ""
                        //                   }
                        //                />
                        //                {/* <label htmlFor="district">Select District</label>
                        //                <select
                        //                   name="district"
                        //                   id="district"
                        //                   onChange={(e) => setUserDistrict(e.target.value)}
                        //                >
                        //                   {district.map((item, index) => {
                        //                      return <option value={item.value}>{item.label}</option>;
                        //                   })}
                        //                </select> */}
                        //             </div>
                        //          </div>
                        //          <div className="input_container">
                        //             <div className="municipality_container">
                        //                <label htmlFor="municipality" id="number">
                        //                   Municipality/VDC
                        //                </label>
                        //                {Object.entries(municipality).map(([key, value]) => {
                        //                   municipalityOption.push({
                        //                      value: value.value,
                        //                      label: value.label,
                        //                   });
                        //                })}
                        //                <Dropdown
                        //                   onChange={handleMunicipality}
                        //                   options={municipalityOption}
                        //                   value={userMunicipality}
                        //                   className={
                        //                      userMunicipality === "" && errorMessage !== ""
                        //                         ? "dropdown-color"
                        //                         : ""
                        //                   }
                        //                />
                        //                {/* <select
                        //                   name="municipality"
                        //                   id="municipality"
                        //                   onChange={(e) => setUserMunicipality(e.target.value)}
                        //                >
                        //                   {municipality.map((item, index) => {
                        //                      return <option value={item.value}>{item.label}</option>;
                        //                   })}
                        //                </select> */}
                        //                {/* <input
                        //               type="text"
                        //               name="municipality"
                        //               className="municipality_input"
                        //               placeholder=""
                        //           /> */}
                        //             </div>
                        //             <div className="ward_no_container">
                        //                <label htmlFor="ward_no" id="ward_no">
                        //                   Ward Number
                        //                </label>
                        //                <input
                        //                   type="number"
                        //                   name="ward_no"
                        //                   className="ward_no_input"
                        //                   placeholder=""
                        //                   value={wardNo}
                        //                   required
                        //                   style={
                        //                      wardNo === "" && errorMessage !== ""
                        //                         ? { border: "1px solid red" }
                        //                         : {}
                        //                   }
                        //                   onChange={(e) => {
                        //                      if (e.target?.value !== "") setWardNo(e.target.value);
                        //                      else if (e.target?.value === "") {
                        //                         setWardNo("");
                        //                      } else if (wardNo?.length === 1) setWardNo(e.target.value);
                        //                   }}
                        //                />
                        //             </div>
                        //          </div>
                        //          <label htmlFor="address" id="address">
                        //             Address
                        //          </label>
                        //          <input
                        //             type="text"
                        //             name="address"
                        //             className="address_input"
                        //             placeholder=""
                        //             value={address}
                        //             required
                        //             style={
                        //                address === "" && errorMessage !== ""
                        //                   ? { border: "1px solid red " }
                        //                   : {}
                        //             }
                        //             onChange={(e) => setAddress(e.target.value)}
                        //          />
                        //          <button className="order_btn" onClick={handlePaymentprocess}>
                        //             Continue
                        //          </button>
                        //       </>
                        //    )
                     }
                  </div>
               )}
               <div className="price_card">
                  <div className="pricecard_wrapper ">
                     <div className="title_wrapper py-3 px-3">
                        <p className="package_name pt-1">{indvPackage?.title}</p>
                        {Object.entries(packagePrice).map(([key, value]) => {
                           priceSection.push({
                              // value: value.value,
                              label: value.type ? value.type : "",
                           });
                        })}
                        <Dropdown
                           onChange={handlepackagetypeChange}
                           options={priceSection}
                           value={packageType}
                        />
                        {/* <select
                           name="month_select"
                           id="month"
                           className="month_select"
                           onChange={(e) => handlepackagetypeChange(e)}
                        >
                           {Object.entries(packagePrice).map(([key, value]) => {
                              return (
                                 <option value={value.type} selected={value.type === packageType}>
                                    {value.type}
                                 </option>
                              );
                           })}
                        </select> */}
                     </div>
                     {Object.entries(packagePrice).map(([key, value]) => {
                        if (value?.type === packageType) {
                           return (
                              <>
                                 <div className="price_div mt-4">
                                    <p className="without_install_text">Subscription Charge </p>
                                    <p className="without_install_price">
                                       Rs.&nbsp;
                                       {value.price.toLocaleString("hi-IN")}
                                    </p>
                                 </div>
                                 <div className="price_div">
                                    <p className="install_text">Installation Charge </p>
                                    <p className="install_price">
                                       Rs.&nbsp;
                                       {value.installation_charge.toLocaleString("hi-IN")}
                                    </p>
                                 </div>
                                 <div className="price_div">
                                    <p className="install_text">SetTop Box Price Charge </p>
                                    <p className="install_price">
                                       Rs.&nbsp;
                                       {value.settopbox_price.toLocaleString("hi-IN")}
                                    </p>
                                 </div>
                                 {nooftv > 1 && (
                                    <div className="price_div">
                                       <p className="install_text">Additional Tv Charge </p>
                                       <p className="install_price">
                                          Rs.&nbsp;
                                          {tvnumber === null ? (
                                             <> 0</>
                                          ) : (
                                             <> {value.additional * (tvnumber - 1)}</>
                                          )}
                                       </p>
                                    </div>
                                 )}
                                 <div className="price_div">
                                    <p className="install_text " id="bold-text">
                                       Total Tv Charge{" "}
                                    </p>
                                    <p className="install_price" id="bold-text">
                                       Rs.&nbsp;
                                       {Math.floor(
                                          value.price +
                                             value.installation_charge +
                                             value.additional * (tvnumber - 1) +
                                             value.settopbox_price,
                                       )?.toLocaleString("hi-IN")}
                                    </p>
                                 </div>

                                 <div className="price_div">
                                    <p className="install_text">Voucher Discount </p>
                                    <p className="install_price">
                                       Rs.&nbsp;
                                       {Math.floor(discountamount).toLocaleString("hi-IN")}
                                    </p>
                                 </div>
                                 {redeemDiscount > 0 && rememberMe === true && (
                                    <div className="price_div">
                                       <p className="total_text">Reward Discount </p>
                                       <p className="total_price">
                                          Rs. {Math.floor(redeemDiscount).toLocaleString("hi-IN")}
                                       </p>
                                    </div>
                                 )}
                                 <div className="price_div">
                                    <p className="install_text">Sub Total </p>
                                    <p className="install_price">
                                       Rs.&nbsp;
                                       {Math.floor(
                                          value.price +
                                             value.installation_charge +
                                             value.additional * (tvnumber - 1) +
                                             value.settopbox_price -
                                             totaldiscount,
                                       ).toLocaleString("hi-IN")}
                                    </p>
                                 </div>

                                 {vatAmount > 0 && (
                                    <div className="price_div">
                                       <p className="install_text">VAT(13%) </p>
                                       <p className="install_price">
                                          Rs.&nbsp;
                                          {vatAmount.toLocaleString("hi-IN")}
                                       </p>
                                    </div>
                                 )}

                                 <div className="price_div">
                                    <p className="total_text" id="bold-text">
                                       Total Amount{" "}
                                    </p>
                                    <p className="total_price" id="bold-text">
                                       Rs.&nbsp;
                                       {(redeemAmounttoPay !== null
                                          ? Math.floor(redeemAmounttoPay + vatAmount)
                                          : Math.floor(
                                               value.price +
                                                  value.installation_charge +
                                                  parseInt(recharge) +
                                                  value.additional * (tvnumber - 1) +
                                                  value.settopbox_price -
                                                  discountamount +
                                                  vatAmount,
                                            )
                                       )?.toLocaleString("hi-IN")}
                                       {/* {Math.floor(
                                          value.price +
                                             value.installation_charge +
                                             parseInt(recharge) +
                                             value.additional * (tvnumber - 1) +
                                             value.settopbox_price -
                                             discountamount +
                                             vatAmount,
                                       )} */}
                                    </p>
                                 </div>
                                 {recharge > 0 && (
                                    <div className="price_div">
                                       <p className="install_text">Recharge amount </p>
                                       <p className="install_price">
                                          Rs.&nbsp;
                                          {parseInt(recharge)}
                                       </p>
                                    </div>
                                 )}

                                 {/* {redeemAmounttoPay !== null && rememberMe === true ? (
                                    <>
                                       <div className="price_div">
                                          <p className="cart-container-redeem">
                                             New total after redeem{" "}
                                          </p>
                                          <p className="total_price" id="cart-container-price">
                                             Rs.{" "}
                                             {redeemAmounttoPay !== null && rememberMe === true
                                                ? Math.floor(redeemAmounttoPay + vatAmount)
                                                : 0}
                                          </p>
                                       </div>
                                    </>
                                 ) : (
                                    <></>
                                 )} */}
                              </>
                           );
                        }
                     })}
                  </div>
                  <div style={{ marginTop: "25px" }}>
                     <RemainingReward
                        totalPrice={
                           price +
                           installationCharge +
                           settopboxPrice +
                           parseInt(recharge) +
                           additional * (tvnumber - 1) +
                           vatAmount -
                           discountamount
                        }
                        newTotalprice={redeemAmounttoPay + vatAmount}
                        discountamount={discountamount}
                        rememberMe={rememberMe}
                     />
                     <InputGroup className="mb-2">
                        <input
                           style={{
                              width: "307px",
                              height: "60px",
                              marginBottom: "0px",
                           }}
                           placeholder="Enter voucher"
                           value={vouchername}
                           onChange={handlevoucherChange}
                           type="text"
                        ></input>

                        <Button
                           style={{ height: "60px" }}
                           variant="outline-secondary"
                           id="button-addon2"
                           className={vouchername === "" ? "apply-button" : "apply-button-green"}
                           onClick={() => {
                              if (vouchername !== "") sendVoucher();
                           }}
                        >
                           Apply
                        </Button>
                     </InputGroup>
                     {errorVoucher === true && (
                        <Alert variant="danger" style={{ width: "370px" }}>
                           <MdError />
                           &nbsp;&nbsp; {vouchermessage}
                        </Alert>
                     )}
                     {successVoucher === true && (
                        <Alert variant="success" style={{ width: "370px" }}>
                           <MdError />
                           &nbsp;&nbsp; {vouchermessage}
                        </Alert>
                     )}
                     {!paymentProcess && !isMobile && (
                        <button
                           style={{ width: "100%", marginBottom: "12px" }}
                           className="order_map_btn"
                           // onClick={orderNow}
                           onClick={handleFootprint}
                        >
                           Continue
                        </button>
                     )}
                  </div>
                  <RewardPoint
                     handleReedem={handleReedem}
                     totalPrice={TotalPrice}
                     rememberMe={rememberMe}
                  />
               </div>
            </div>
         </div>
      </Layout>
   );
};

export function MapWrapper({ setPosition, position, location, setLocation, locationInput }) {
   const markerRef = useRef(null);
   const [center, setCenter] = useState([27.7172, 85.324]);
   const [search, setSearch] = useState("");
   const [polyLine, setPolyLine] = useState("");
   useEffect(() => {
      document.getElementsByClassName("leaflet-control-attribution")[0].style.display = "none";
   }, []);
   const GEOCODE_URL =
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

   const [tooltipvisible, settooltipvisible] = useState(true);

   function LocationMarker({ position }) {
      // const [position, setPosition] = useState(null);
      const [bbox, setBbox] = useState([]);
      const map = useMap();
      useEffect(() => {
         if (locationInput === "map") {
            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(showPosition, showError);
            } else {
               alert("Geolocation is not supported by this browser.");
            }
         }
      }, [locationInput, position]);
      function showPosition(position) {}
      function showError(error) {
         switch (error.code) {
            case error.PERMISSION_DENIED:
               console.log("User denied the request for Geolocation.");
               break;
            case error.POSITION_UNAVAILABLE:
               console.log("Location information is unavailable.");
               break;
            case error.TIMEOUT:
               console.log("The request to get user location timed out.");
               break;
            case error.UNKNOWN_ERROR:
               console.log("An unknown error occurred.");
               break;
            default:
               console.log("An unknown error occurred.");
               break;
         }
      }

      useEffect(() => {
         map.locate().on("locationfound", function (e) {
            map.flyTo(position, map.getZoom());
            setBbox(e.bounds.toBBoxString().split(","));
         });
      }, [map]);
      const eventHandlers = useMemo(
         () => ({
            dragend() {
               const marker = markerRef.current;
               setPosition(marker.getLatLng());
               settooltipvisible(true);
            },
            dragstart() {
               settooltipvisible(false);
            },
            onClick() {
               const marker = markerRef.current;
               setPosition(marker.getLatLng());
            },
         }),
         [],
      );

      return position === null ? null : (
         <Marker position={position} draggable={true} ref={markerRef} eventHandlers={eventHandlers}>
            {/* <Popup>{location?.Match_addr}</Popup> */}
            {tooltipvisible && (
               <Tooltip direction={"top"} offset={[-15, -15]} permanent={tooltipvisible}>
                  {location?.Match_addr}
               </Tooltip>
            )}
         </Marker>
      );
   }
   useEffect(() => {
      if (position) {
         const latlng = position;
         const url = `${GEOCODE_URL} + ${latlng.lng},${latlng.lat}`;
         fetch(url)
            .then((res) => res.json())
            .then((data) => {
               const address = data?.address;
               setLocation(address);
            });
      }
   }, [position]);

   function getData(search) {
      axios
         .get(`https://nominatim.openstreetmap.org/search?q=${search}}&format=json`)
         .then((res) => {
            const data = res.data;
            setPosition({ lat: data[0].lat, lng: data[0].lon });
            setCenter({ lat: data[0].lat, lng: data[0].lon });
            setPolyLine(data[0]?.boundingbox);
         });
   }

   const [mapData, setMapData] = useState("");
   useEffect(() => {
      axios
         .get(`https://nominatim.openstreetmap.org/search?q=${search}}&format=json&countrycodes=NP`)
         .then((res) => {
            const data = res.data;
            search?.length > 2 && setMapData(data);
         });
   }, [search]);

   const handleClickClosePanelFromOutside = (e) => {
      if (e.target.className !== "searchResultItem") {
         setSearch("");
      }
   };

   useEffect(() => {
      if (search?.length >= 2) {
         document.body.addEventListener("click", handleClickClosePanelFromOutside);
         return () => {
            document.body.removeEventListener("click", handleClickClosePanelFromOutside);
         };
      }
   }, [search]);

   function setActiveMapData(data) {
      setPosition({ lat: data.lat, lng: data.lon });
      setCenter({ lat: data.lat, lng: data.lon });
      setPolyLine(data?.boundingbox);
   }

   return (
      <>
         <div className="searchContainer">
            <InputGroup>
               <InputGroup.Text id="search-icon">
                  <BiSearchAlt />
               </InputGroup.Text>
               <input
                  type="text"
                  style={{ marginBottom: "0px !important" }}
                  className="searchInput mb-0"
                  placeholder="Search.."
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                     if (e.charCode === 13) {
                        setSearch("");
                        getData(e.target.value);
                     }
                  }}
               />
            </InputGroup>
            {search?.length > 2 && (
               <div className="searchResult">
                  {mapData &&
                     mapData.map((data, index) => {
                        return (
                           <div
                              key={index}
                              className="searchResultItem"
                              onClick={() => {
                                 setActiveMapData(data);
                                 setSearch("");
                              }}
                           >
                              {data.display_name}
                           </div>
                        );
                     })}
               </div>
            )}
         </div>
         <MapContainer
            center={center}
            zoom={15}
            style={{ height: "50vh", width: "100%", zIndex: "1" }}
         >
            <TileLayer
               attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* {polyLine && <Polyline positions={polyLine} color="red" />} */}
            <LocationMarker position={position} />
         </MapContainer>
      </>
   );
}

export function PaymentMethod({
   errorPyament,
   checkPayment,
   handlePaymentcheck,
   setErrorPayment,
   setCheckpayment,
   checkFootprint,
   khaltiStatus,
   esewaStatus,
   handleOrder,
   setPaymentMethod,
   paymentMethod,
   setimeStatus,
   setprabhuStatus,
   connectIpsStatus,
   prabhuStatus,
   imeStatus,
   setconnectIpsStatus,
   setkhaltiStatus,
   setPaymentgateway,
   setesewaStatus,
}) {
   const [paymentstatus, setPaymentstatus] = useState(false);
   const [selectOnlinePayment, setSelectOnlinePayment] = useState(false);

   const [value, setValue] = useState("");
   const [cashActive, setCashActive] = useState(false);

   useEffect(() => {
      if (checkFootprint === false) {
         setValue("cod");
      } else {
         setValue("");
      }
   }, [checkFootprint]);
   const handleChange = (event) => {
      setCheckpayment(true);
      setErrorPayment(false);
      setValue(event.target.value);
      setPaymentMethod(event.target.value);
   };
   // useEffect(() => {
   //    if (value === "cod") {
   //       setCashActive(true);
   //    }
   //    if (value === "payment") setPaymentMethod("imepay");
   // }, [cashActive, value]);
   // useEffect(() => {
   //    if (checkFootprint === false) {
   //       setValue("cod");
   //    }
   // }, [value]);
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);

   return (
      <div className="payment-method-container">
         <p id="select-payment">Select payment method</p>
         <div>
            <FormControl style={{ marginBottom: "15px" }}>
               <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // defaultValue="payment"
                  onChange={handleChange}
               >
                  {
                     checkFootprint === true &&
                     process.env.REACT_APP_ONLINE_PAYMENT_STATUS === "true" &&
                     !(
                        contactDetails?.disable_imepay &&
                        contactDetails?.disable_esewa &&
                        contactDetails?.disable_khalti &&
                        contactDetails?.disable_connectips &&
                        contactDetails?.disable_prabhupay
                     ) ? (
                        <>
                           <FormControlLabel
                              value="payment"
                              control={<Radio />}
                              label="Online payment"
                           />
                        </>
                     ) : null
                     // <>
                     //    <FormControlLabel
                     //       disabled
                     //       value="payment"
                     //       control={<Radio />}
                     //       label="Online payment"
                     //    />
                     // </>
                  }
                  {value === "payment" ? (
                     <div className="payment_methods">
                        {contactDetails?.disable_imepay === false && (
                           <div>
                              <img
                                 onClick={() => {
                                    setPaymentMethod("imepay");
                                    setkhaltiStatus(false);
                                    setErrorPayment(false);
                                    setesewaStatus(false);
                                    setconnectIpsStatus(false);
                                    setSelectOnlinePayment(true);
                                    setprabhuStatus(false);
                                    setimeStatus(true);
                                 }}
                                 alt="imepay"
                                 className={`${imeStatus ? "payment_border" : "payment_hover"}`}
                                 src={ime}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_esewa === false && (
                           <div>
                              <img
                                 src={esewa}
                                 id={`${esewaStatus ? "payment_border" : "esewa-border"}`}
                                 onClick={() => {
                                    setPaymentMethod("esewa");
                                    setPaymentstatus(true);
                                    setSelectOnlinePayment(true);
                                    setkhaltiStatus(false);
                                    setesewaStatus(true);
                                    setErrorPayment(false);
                                    setimeStatus(false);
                                    setprabhuStatus(false);
                                    setconnectIpsStatus(false);
                                 }}
                                 alt="esewa"
                                 className={`${esewaStatus ? "payment_border" : "payment_hover"}`}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_khalti === false && (
                           <div>
                              <img
                                 src={khalti}
                                 onClick={() => {
                                    setPaymentMethod("khalti");
                                    setPaymentstatus(true);
                                    setSelectOnlinePayment(true);
                                    setkhaltiStatus(true);
                                    setesewaStatus(false);
                                    setesewaStatus(false);
                                    setimeStatus(false);
                                    setprabhuStatus(false);
                                    setErrorPayment(false);
                                    setconnectIpsStatus(false);
                                 }}
                                 alt="khalti"
                                 className={`${khaltiStatus ? "payment_border" : "payment_hover"}`}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_connectips === false && (
                           <div>
                              <img
                                 onClick={() => {
                                    setPaymentMethod("connectips");
                                    setPaymentstatus(true);
                                    setconnectIpsStatus(true);
                                    setSelectOnlinePayment(true);
                                    setkhaltiStatus(false);
                                    setesewaStatus(false);
                                    setesewaStatus(false);
                                    setimeStatus(false);
                                    setErrorPayment(false);
                                    setprabhuStatus(false);
                                 }}
                                 className={`${
                                    connectIpsStatus ? "payment_border" : "payment_hover"
                                 }`}
                                 src={ips}
                                 alt="ips"
                              />
                           </div>
                        )}
                        {/* <div>
                           <img src={paypoint} />
                        </div> */}
                        {contactDetails?.disable_prabhupay === false && (
                           <div>
                              <img
                                 onClick={() => {
                                    setPaymentMethod("prabhupay");
                                    setkhaltiStatus(false);
                                    setimeStatus(false);
                                    setesewaStatus(false);
                                    setconnectIpsStatus(false);
                                    setprabhuStatus(true);
                                    setErrorPayment(false);
                                    setSelectOnlinePayment(true);
                                 }}
                                 className={`${prabhuStatus ? "payment_border" : "payment_hover"}`}
                                 src={prabhu}
                                 alt="prabhupay"
                              />
                           </div>
                        )}
                     </div>
                  ) : null}
                  {checkFootprint === true ? (
                     <>
                        <FormControlLabel
                           value="cod"
                           control={<Radio />}
                           label="Cash on delivery"
                        />
                     </>
                  ) : (
                     <>
                        <FormControlLabel
                           checked
                           value="cod"
                           control={<Radio />}
                           label="Cash on delivery"
                        />
                     </>
                  )}
               </RadioGroup>
            </FormControl>
         </div>
         {errorPyament === true && (
            <p style={{ color: "red" }}>You must select one of the payment methods!</p>
         )}

         {/* {value === "payment" ? (
            <>
              
               <Button
                  className={paymentstatus === true ? "order_btn" : "order_btn_inactive"}
                  onClick={handlePaymentCart}
               >
                  Order Now
               </Button>
            </>
         ) : (
            <>
               <Button
                  className={cashActive ? "order_btn" : "order_btn_inactive"}
                  onClick={handleOrder}
               >
                  Order Now
               </Button>
            </>
         )} */}
         {value === "cod" || selectOnlinePayment ? (
            <Button
               // className={cashActive ? "order_btn" : "order_btn_inactive"}
               className={"order_btn"}
               onClick={handleOrder}
            >
               Order Now
            </Button>
         ) : (
            <Button
               // className={cashActive ? "order_btn" : "order_btn_inactive"}
               className={
                  ["imepay", "esewa", "khalti", "prabhupay", "connectips"]?.includes(paymentMethod)
                     ? "order_btn"
                     : "order_btn_inactive"
               }
               onClick={handlePaymentcheck}
            >
               Order Now
            </Button>
         )}
         {/* {value === "payment" ? <div className="payment_methods"></div> : null}
       
         {paymentstatus ? (
            <Button className={"order_btn"} onClick={handlePaymentCart}>
               Order Now
            </Button>
         ) : (
            <Button className="order_btn_inactive" onClick={handlePaymentCart}>
               Order Now
            </Button>
         )} 
      */}
      </div>
   );
}

export default TVPackageOrder;
