import React, { useState, useEffect } from "react";
import "../../../scss/internet/internetcard.scss";
import tick from "../../../assets/tick.svg";
import { Button } from "react-bootstrap";
import { get_internet_package } from "../../../data/api";
import ToggleButton from "react-toggle-button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import arrow from "../../../assets/internet/green_arrow.png";
import { duration } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Internetcard({ internetPackage, linked }) {
   //useState Hooks
   const [checked, setChecked] = useState(false);
   const [radioValue, setRadioValue] = useState("1");
   const [internetdata, setinternetData] = useState("");
   const [internetprice, setinternetPrice] = useState("");
   //true= yearly false=monthly
   const [durationToggle, setDurationToggle] = useState(true);

   //useEffect Hooks

   //To Fetch Internet All Packages
   useEffect(() => {
      async function get_inetrnetpackage() {
         const res = await get_internet_package();
         const response = res.data.data;
         setinternetData(response);
      }
      get_inetrnetpackage();
   }, []);
   const navigate = useNavigate();
   let totalPrice;
   return (
      <div className="internetcard">
         <p className="super-internet">
            Super Internet <span id="foryou">For You.</span>
         </p>
         <div className={durationToggle ? "internet_toggle mb-1" : "internet_toggle mb-5"}>
            <p>Monthly</p>
            <div className="togglebutton mx-3">
               <ToggleButton
                  inactiveLabel=""
                  activeLabel=""
                  colors={{
                     active: {
                        base: "#ED1C24",
                     },
                  }}
                  value={durationToggle}
                  onToggle={() => setDurationToggle(!durationToggle)}
                  className="toggle_button"
               />
            </div>
            <p>Yearly</p>
         </div>
         {durationToggle ? (
            <div className="saveText">
               <p className="text">Save 20%</p>
               <img src={arrow} alt="" />
            </div>
         ) : null}
         <div className="container fixed-width" id="styling">
            {Object.entries(linked).map(([key, value]) => {
               return (
                  <div>
                     <div className="best-package">
                        <div className={"pt-3 inside-internet" + key}>
                           <p className="internet-speed"> {value.speed}</p>
                           <p className="internet-description">{value.description}</p>
                        </div>

                        {Object.entries(value.prices).map(([key, item]) => {
                           const totalPrice =
                              item?.installation_charge +
                              item?.internet_price +
                              item?.drop_wire +
                              item?.itv_price +
                              item?.itv_stb_charge +
                              item?.router;
                           return (
                              <div>
                                 {item.type === "1 Month" && !durationToggle ? (
                                    <div>
                                       <div>
                                          <ul className="internet-li">
                                             {item?.no_of_itv === 0 ? (
                                                <li className="internet-feature">
                                                   {" "}
                                                   <img
                                                      style={{ marginRight: "8px" }}
                                                      src={tick}
                                                      alt="tick"
                                                   />
                                                   Dropwire Charge: Rs.{" "}
                                                   {item?.drop_wire.toLocaleString("hi-IN")}
                                                </li>
                                             ) : (
                                                <li className="internet-feature">
                                                   {" "}
                                                   <img
                                                      style={{ marginRight: "8px" }}
                                                      src={tick}
                                                      alt="tick"
                                                   />
                                                   No. of TV: {item?.no_of_itv}
                                                </li>
                                             )}

                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Installation Charge: Rs.{" "}
                                                {item?.installation_charge.toLocaleString("hi-IN")}{" "}
                                             </li>
                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Tv installatiion Charge: Rs.{" "}
                                                {item?.itv_stb_charge.toLocaleString("hi-IN")}
                                             </li>
                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Router Rental Charge: Rs.{" "}
                                                {item?.router.toLocaleString("hi-IN")}
                                             </li>
                                          </ul>
                                       </div>
                                       <p className="li-price">
                                          Rs. {totalPrice.toLocaleString("hi-IN")}*
                                          <span id="li-subprice">/month</span>
                                       </p>
                                       <div className="internet-button">
                                          <Button
                                             variant="secondary"
                                             className="choose-btn"
                                             onClick={() => {
                                                navigate(`/internet/${value._id}`);
                                             }}
                                          >
                                             Choose Plan
                                          </Button>
                                       </div>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                                 {item.type === "12 Months" && durationToggle ? (
                                    <div>
                                       <div>
                                          <ul className="internet-li">
                                             {item?.no_of_itv === 0 ? (
                                                <li className="internet-feature">
                                                   {" "}
                                                   <img
                                                      style={{ marginRight: "8px" }}
                                                      src={tick}
                                                      alt="tick"
                                                   />
                                                   Dropwire Charge: Rs.{" "}
                                                   {item?.drop_wire.toLocaleString("hi-IN")}
                                                </li>
                                             ) : (
                                                <li className="internet-feature">
                                                   {" "}
                                                   <img
                                                      style={{ marginRight: "8px" }}
                                                      src={tick}
                                                      alt="tick"
                                                   />
                                                   No. of TV: {item?.no_of_itv}
                                                </li>
                                             )}

                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Installation Charge: Rs.{" "}
                                                {item?.installation_charge.toLocaleString("hi-IN")}
                                             </li>
                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Tv installation Charge: Rs.{" "}
                                                {item?.itv_stb_charge.toLocaleString("hi-IN")}
                                             </li>
                                             <li className="internet-feature">
                                                {" "}
                                                <img
                                                   style={{ marginRight: "8px" }}
                                                   src={tick}
                                                   alt="tick"
                                                />
                                                Router Rental Charge: Rs.{" "}
                                                {item?.router.toLocaleString("hi-IN")}
                                             </li>
                                          </ul>
                                       </div>
                                       <p className="li-price">
                                          Rs. {totalPrice.toLocaleString("hi-IN")}*
                                          <span id="li-subprice">/year</span>
                                       </p>
                                       <div className="internet-button">
                                          <Button
                                             variant="secondary"
                                             className="choose-btn"
                                             onClick={() => {
                                                navigate(`/internet/${value._id}/?type=12 Months`);
                                             }}
                                          >
                                             Choose Plan
                                          </Button>
                                       </div>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           );
                        })}
                     </div>
                  </div>
               );
            })}
         </div>
      </div>
   );
}
