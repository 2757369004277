import React, { useEffect } from "react";
import "../scss/dashboard/dashboardItems.scss";
import { useNavigate } from "react-router";
import Token from "../utils/useToken";
import { useState } from "react";
import { check_balance, update_profile } from "../data/protectedapi";
import { get_company_details } from "../data/api";
import { useMediaQuery } from "react-responsive";
import { AiOutlineHeart, AiOutlineUserAdd, AiFillHeart } from "react-icons/ai";
import { MdOutlineMovieFilter, MdProductionQuantityLimits } from "react-icons/md";
import { FaRegHeart } from "react-icons/fa";
import { BiUserPlus } from "react-icons/bi";
import { MdNotificationsNone } from "react-icons/md";
import { PiTelevisionLight } from "react-icons/pi";
import axios from "axios";

const DashboardItems = ({ setItem, item, mobileClose }) => {
   const [balance, setBalance] = useState("");
   const { setToken, token, userDetails } = Token();
   const [otp, setOtp] = useState("");
   const UserDetails = token.user;
   const user = UserDetails;
   const customerId = user.customer_id;
   const [showbalance, setShowbalance] = useState("Loading...");
   const [errorbalance, setErrorbalance] = useState(false);
   const [companyDetails, setCompanyDetails] = useState("");
   const [empty, setEmpty] = useState(true);
   //check balance
   useEffect(() => {
      async function get_balance() {
         if (userDetails?.is_kyc_verified === true) {
            const res = await check_balance();
            const response = res?.data?.data;
            if (res?.status === 200) {
               // console.log("balance", response);
               setBalance(response);
               setShowbalance(response?.Balance);
            } else {
               setErrorbalance(true);
            }
         } else {
            setErrorbalance(true);
         }
      }
      get_balance();
   }, []);
   useEffect(() => {
      async function get_details() {
         const res = await get_company_details();
         setCompanyDetails(res?.data?.data);
      }
      get_details();
   }, [empty]);

   useEffect(() => {
      if (companyDetails === "" || companyDetails === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [companyDetails]);

   //get user profile
   const [userInfo, setUserInfo] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setUserInfo(res?.data);
      }
      get_navigation();
   }, []);

   const [isDTHUser, setIsDTHUser] = useState(false);
   useEffect(() => {
      // console.log(balance?.packages);
      balance?.packages?.map((data) => {
         // console.log(data?.type?.toLowerCase());
         if (data?.type?.toLowerCase() === "dth") {
            setIsDTHUser(true);
         }
      });
      // console.log("isDTHUser", isDTHUser);
   }, [balance]);

   // console.log("item", item);

   let navigate = useNavigate();
   const isMobileResponsive = useMediaQuery({ query: "(max-width: 1100px)" });
   return (
      <div className="dashboarditem__container">
         {/* <DashboardHeader
            customerId={customerId}
            errorbalance={errorbalance}
            balance={balance}
            showbalance={showbalance}
            userDetails={userDetails}
         /> */}
         <UserCard
            userDetails={userDetails}
            userInfo={userInfo}
            errorbalance={errorbalance}
            balance={balance}
            showbalance={showbalance}
         />
         <div className="dashboarditem__container__body">
            <div
               className={
                  item === "0"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  setItem("0");
                  navigate(`/dashboard?type=0`);
                  mobileClose && mobileClose();
               }}
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M3.78329 0.916687C2.19649 0.916687 0.916626 2.21675 0.916626 3.80085V6.64169C0.916626 8.2354 2.19859 9.52502 3.78329 9.52502H6.59996C8.19492 9.52502 9.46663 8.23346 9.46663 6.64169V3.80085C9.46663 2.21868 8.19701 0.916687 6.59996 0.916687H3.78329ZM2.41663 3.80085C2.41663 3.03329 3.03676 2.41669 3.78329 2.41669H6.59996C7.35291 2.41669 7.96663 3.03136 7.96663 3.80085V6.64169C7.96663 7.41657 7.355 8.02502 6.59996 8.02502H3.78329C3.03467 8.02502 2.41663 7.41464 2.41663 6.64169V3.80085ZM3.78329 10.4748C2.1982 10.4748 0.916626 11.7656 0.916626 13.3589V16.1998C0.916626 17.7832 2.19668 19.0831 3.78329 19.0831H6.59996C8.19682 19.0831 9.46663 17.7813 9.46663 16.1998V13.3589C9.46663 11.7675 8.1953 10.4748 6.59996 10.4748H3.78329ZM2.41663 13.3589C2.41663 12.5856 3.03505 11.9748 3.78329 11.9748H6.59996C7.35462 11.9748 7.96663 12.5837 7.96663 13.3589V16.1998C7.96663 16.9682 7.3531 17.5831 6.59996 17.5831H3.78329C3.03657 17.5831 2.41663 16.9663 2.41663 16.1998V13.3589ZM10.5334 3.80085C10.5334 2.21868 11.803 0.916687 13.4 0.916687H16.2167C17.8035 0.916687 19.0834 2.21675 19.0834 3.80085V6.64169C19.0834 8.2354 17.8014 9.52502 16.2167 9.52502H13.4C11.8051 9.52502 10.5334 8.23347 10.5334 6.64169V3.80085ZM13.4 2.41669C12.6471 2.41669 12.0334 3.03136 12.0334 3.80085V6.64169C12.0334 7.41657 12.645 8.02502 13.4 8.02502H16.2167C16.9653 8.02502 17.5834 7.41464 17.5834 6.64169V3.80085C17.5834 3.03329 16.9632 2.41669 16.2167 2.41669H13.4ZM13.4 10.4748C11.8047 10.4748 10.5334 11.7675 10.5334 13.3589V16.1998C10.5334 17.7813 11.8032 19.0831 13.4 19.0831H16.2167C17.8033 19.0831 19.0834 17.7832 19.0834 16.1998V13.3589C19.0834 11.7656 17.8018 10.4748 16.2167 10.4748H13.4ZM12.0334 13.3589C12.0334 12.5837 12.6454 11.9748 13.4 11.9748H16.2167C16.965 11.9748 17.5834 12.5856 17.5834 13.3589V16.1998C17.5834 16.9663 16.9634 17.5831 16.2167 17.5831H13.4C12.6469 17.5831 12.0334 16.9682 12.0334 16.1998V13.3589Z"
                  />
               </svg>
               Dashboard
            </div>
            <div
               className={
                  item === "1"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate(`/dashboard?type=1`);
                  setItem("1");
                  mobileClose && mobileClose();
               }}
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  className="svg-fill"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path d="M3.33301 18.332C3.33301 16.5639 4.03539 14.8682 5.28563 13.618C6.53587 12.3677 8.23156 11.6654 9.99967 11.6654C11.7678 11.6654 13.4635 12.3677 14.7137 13.618C15.964 14.8682 16.6663 16.5639 16.6663 18.332H14.9997C14.9997 17.0059 14.4729 15.7342 13.5352 14.7965C12.5975 13.8588 11.3258 13.332 9.99967 13.332C8.67359 13.332 7.40182 13.8588 6.46414 14.7965C5.52646 15.7342 4.99967 17.0059 4.99967 18.332H3.33301ZM9.99967 10.832C7.23717 10.832 4.99967 8.59453 4.99967 5.83203C4.99967 3.06953 7.23717 0.832031 9.99967 0.832031C12.7622 0.832031 14.9997 3.06953 14.9997 5.83203C14.9997 8.59453 12.7622 10.832 9.99967 10.832ZM9.99967 9.16536C11.8413 9.16536 13.333 7.6737 13.333 5.83203C13.333 3.99036 11.8413 2.4987 9.99967 2.4987C8.15801 2.4987 6.66634 3.99036 6.66634 5.83203C6.66634 7.6737 8.15801 9.16536 9.99967 9.16536Z" />
               </svg>
               Edit Account
            </div>
            {isDTHUser && (
               <div
                  className={
                     item === "8"
                        ? "dashboarditem__container__item active"
                        : "dashboarditem__container__item"
                  }
                  onClick={() => {
                     setItem("8");
                     navigate("/dashboard?type=8");
                     mobileClose && mobileClose();
                  }}
               >
                  <svg
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                     className="svg-stroke"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M7.49996 18.3332H12.5C16.6666 18.3332 18.3333 16.6665 18.3333 12.4998V7.49984C18.3333 3.33317 16.6666 1.6665 12.5 1.6665H7.49996C3.33329 1.6665 1.66663 3.33317 1.66663 7.49984V12.4998C1.66663 16.6665 3.33329 18.3332 7.49996 18.3332Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <path
                        d="M7.58496 9.99975V8.76642C7.58496 7.17475 8.70996 6.53308 10.085 7.32475L11.1516 7.94141L12.2183 8.55808C13.5933 9.34975 13.5933 10.6497 12.2183 11.4414L11.1516 12.0581L10.085 12.6747C8.70996 13.4664 7.58496 12.8164 7.58496 11.2331V9.99975Z"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                  </svg>
                  PPV{" "}
               </div>
            )}
            <div
               className={
                  item === "14"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  setItem("14");
                  navigate("/dashboard?type=14");
                  mobileClose && mobileClose();
               }}
            >
               <PiTelevisionLight color={item === "14" ? "#ed1c24" : "#374253"} />
               <span style={{ marginLeft: "6px" }}> IPTV PPV </span>
            </div>
            <div
               className={
                  item === "16"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  setItem("16");
                  navigate("/dashboard?type=16&option=activePackage");
                  mobileClose && mobileClose();
               }}
            >
               <MdOutlineMovieFilter color={item === "16" ? "#ed1c24" : "#374253"} />
               <span style={{ marginLeft: "6px" }}> DishHome Go </span>
            </div>
            <div
               className={
                  item === "9"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=9&option=product");
                  mobileClose && mobileClose();
                  setItem("9");
               }}
            >
               {/* <svg
                  width="14"
                  height="13"
                  viewBox="0 0 14 13"
                  className="svg-stroke"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M1 3.26667L7.23333 1L13.4667 3.26667L7.23333 5.53333L1 3.26667Z"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M13.4667 6.66797L7.23333 8.93463L1 6.66797"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M13.4667 10.0664L7.23333 12.3331L1 10.0664"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg> */}
               <MdProductionQuantityLimits
                  size={19}
                  color={item === "9" ? "#ed1c24" : "#374253"}
                  style={{ marginRight: "4px" }}
               />
               My Orders{" "}
            </div>
            {companyDetails && companyDetails?.disable_store === false && (
               <div
                  className={
                     item === "10"
                        ? "dashboarditem__container__item active"
                        : "dashboarditem__container__item"
                  }
                  onClick={() => {
                     navigate("/dashboard?type=10");
                     mobileClose && mobileClose();

                     setItem("10");
                  }}
               >
                  <FaRegHeart color={item === "10" ? "#ed1c24" : "#374253"} />
                  <span style={{ marginLeft: "6px" }}> My Wishlist </span>
               </div>
            )}
            <div
               className={
                  item === "11"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=11");
                  mobileClose && mobileClose();

                  setItem("11");
               }}
            >
               <BiUserPlus
                  size={22}
                  color={item === "11" ? "#ed1c24" : "#374253"}
                  style={{ marginLeft: "1px", marginRight: "2px" }}
               />
               <span style={{ marginLeft: "1px" }}> My Referals </span>
            </div>
            <div
               className={
                  item === "12"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=12");
                  mobileClose && mobileClose();
                  setItem("12");
               }}
            >
               <MdNotificationsNone
                  size={20}
                  style={{ marginRight: "3px" }}
                  color={item === "12" ? "#ed1c24" : "#374253"}
               />
               Notifications
            </div>
            {/* {userDetails?.customer_type !== "internetOnly" && (
               <div
                  className={
                     item === "2"
                        ? "dashboarditem__container__item active"
                        : "dashboarditem__container__item"
                  }
                  onClick={() => {
                     navigate("/dashboard?type=2");
                     mobileClose();

                     setItem("2");
                  }}
               >
                  <svg
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                     className="svg-stroke"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        d="M1.66675 7.08594H18.3334"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <path
                        d="M5 13.75H6.66667"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <path
                        d="M8.75 13.75H12.0833"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                     <path
                        d="M5.36675 2.91797H14.6251C17.5917 2.91797 18.3334 3.6513 18.3334 6.5763V13.418C18.3334 16.343 17.5917 17.0763 14.6334 17.0763H5.36675C2.40841 17.0846 1.66675 16.3513 1.66675 13.4263V6.5763C1.66675 3.6513 2.40841 2.91797 5.36675 2.91797Z"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                  </svg>
                  Billing History{" "}
               </div>
            )} */}
            <div
               className={
                  item === "2"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=2");
                  mobileClose && mobileClose();

                  setItem("2");
               }}
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="svg-stroke"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M1.66675 7.08594H18.3334"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M5 13.75H6.66667"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M8.75 13.75H12.0833"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M5.36675 2.91797H14.6251C17.5917 2.91797 18.3334 3.6513 18.3334 6.5763V13.418C18.3334 16.343 17.5917 17.0763 14.6334 17.0763H5.36675C2.40841 17.0846 1.66675 16.3513 1.66675 13.4263V6.5763C1.66675 3.6513 2.40841 2.91797 5.36675 2.91797Z"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               Billing History{" "}
            </div>
            {/* <div
               className={
                  item === "14"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=14&option=product");
                  mobileClose();

                  setItem("14");
               }}
            >
               <svg
                  width="20"
                  height="20"
                  className="svg-stroke"
                  version="1.1"
                  fill="#374253"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
               >
                  <path d="m473.6,256.6c0-99.3-67.1-185.9-163.3-210.6-9.2-2.4-18.6,3.2-20.9,12.4-2.4,9.2 3.2,18.6 12.4,20.9 80.9,20.7 137.4,93.7 137.4,177.3 0,73.7-43.9,139-109.6,167.7l15.9-37c3.7-8.7-0.3-18.8-9-22.6-8.7-3.7-18.8,0.3-22.6,9l-31,72.2c-3.7,8.7 0.3,18.8 9,22.6l72.2,31c10.8,3.8 19.8-2.5 22.6-9 3.7-8.7-0.3-18.8-9-22.6l-31.4-13.5c76.5-34.6 127.3-111.4 127.3-197.8z" />
                  <path d="m210.4,433.9c-80.9-20.8-137.4-93.7-137.4-177.3 0-72.7 42.7-137.2 106.9-166.5l-14.9,34.6c-3.7,8.7 0.1,19.2 9,22.6 10.4,3.9 19.2-0.8 22.6-9l31-72.3c3-7 2.9-17.5-9-22.6l-72.2-31c-8.7-3.7-18.8,0.3-22.6,9-3.7,8.7 0.3,18.8 9,22.6l33.6,14.4c-76.8,34.7-127.8,111.6-127.8,198.2 7.10543e-15,99.3 67.2,185.8 163.3,210.6 11.6,3 18.9-4.6 20.9-12.4 2.3-9.2-3.2-18.5-12.4-20.9z" />
                  <path d="m296.6,321.4c8.8-9.2 13.2-20.8 13.2-34.6 0-6.3-1.1-12.1-3.2-17.5-2.1-5.4-5.1-10-9-14-3.9-3.9-9-7.2-15.2-10-3.3-1.4-9.9-3.4-20-5.9v-57.4c6.5,1.3 11.6,4.1 15.4,8.3 3.7,4.2 6.2,10.2 7.4,18l20.6-3.1c-1.8-12.6-7-22.6-15.7-29.8-6.8-5.7-16-9.1-27.6-10.2v-9.5h-11.8v9.5c-13.2,1.3-23.2,5-29.9,10.9-10.1,8.8-15.1,20.3-15.1,34.5 0,8 1.7,15.1 5.2,21.4 3.4,6.3 8.3,11.2 14.7,14.7 8.6,4.8 17,8 25.2,9.5v63.5c-7.8-0.8-14.6-4.8-20.2-11.9-3.9-5-6.7-12.9-8.2-23.6l-20.1,3.8c0.7,10.3 3.4,19.1 7.9,26.3 4.5,7.2 9.9,12.4 16.2,15.6 6.2,3.2 14.4,5.4 24.3,6.7v19.9h11.8v-20.2c14-0.7 25.4-5.7 34.1-14.9zm-45.8-84.5c-9.1-2.7-15.5-6.4-19.3-11-3.8-4.6-5.7-10.3-5.7-17.1 0-6.9 2.2-12.8 6.7-17.8 4.5-5 10.6-8.1 18.3-9.3v55.2zm30.9,72.6c-5.1,5.8-11.5,9.1-19.2,10.1v-60.7c10.7,3.5 17.9,7.4 21.5,11.8 3.6,4.4 5.4,10.2 5.4,17.5-0.1,8.4-2.6,15.6-7.7,21.3z" />
               </svg>
               Transaction History{" "}
            </div> */}
            <div
               className={
                  item === "13"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=13");
                  mobileClose && mobileClose();
                  setItem("13");
               }}
            >
               <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20"
                  height="20"
                  className="svg-stroke"
               >
                  {" "}
                  <path d="M 6 2 C 4.9057453 2 4 2.9057453 4 4 L 4 20 C 4 21.094255 4.9057453 22 6 22 L 18 22 C 19.094255 22 20 21.094255 20 20 L 20 8 L 14 2 L 6 2 z M 6 4 L 13 4 L 13 9 L 18 9 L 18 20 L 6 20 L 6 4 z M 8 12 L 8 14 L 16 14 L 16 12 L 8 12 z M 8 16 L 8 18 L 16 18 L 16 16 L 8 16 z" />
               </svg>
               My Documents{" "}
            </div>
            <div
               className={
                  item === "3"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=3");
                  setItem("3");
                  mobileClose && mobileClose();
               }}
            >
               <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  className="svg-fill"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path d="M8.16699 19.657V16.657C3.99199 16.4404 0.666992 12.9737 0.666992 8.7487C0.666992 4.38203 4.21699 0.832031 8.58366 0.832031C12.9503 0.832031 16.5003 4.38203 16.5003 8.7487C16.5003 12.8737 13.6337 17.0237 9.35866 19.082L8.16699 19.657ZM8.58366 2.4987C5.13366 2.4987 2.33366 5.2987 2.33366 8.7487C2.33366 12.1987 5.13366 14.9987 8.58366 14.9987H9.83366V16.9154C12.867 14.9987 14.8337 11.8487 14.8337 8.7487C14.8337 5.2987 12.0337 2.4987 8.58366 2.4987ZM7.75033 12.082H9.41699V13.7487H7.75033V12.082ZM9.41699 10.832H7.75033C7.75033 8.1237 10.2503 8.33203 10.2503 6.66536C10.2503 5.7487 9.50033 4.9987 8.58366 4.9987C7.66699 4.9987 6.91699 5.7487 6.91699 6.66536H5.25033C5.25033 4.8237 6.74199 3.33203 8.58366 3.33203C10.4253 3.33203 11.917 4.8237 11.917 6.66536C11.917 8.7487 9.41699 8.95703 9.41699 10.832Z" />
               </svg>
               Support
            </div>
            <div
               className={
                  item === "4"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=4");
                  mobileClose && mobileClose();

                  setItem("4");
               }}
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  className="svg-fill"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path d="M5.83333 8.33284H16.6667C16.8877 8.33284 17.0996 8.42064 17.2559 8.57692C17.4122 8.7332 17.5 8.94516 17.5 9.16617V17.4995C17.5 17.7205 17.4122 17.9325 17.2559 18.0888C17.0996 18.245 16.8877 18.3328 16.6667 18.3328H3.33333C3.11232 18.3328 2.90036 18.245 2.74408 18.0888C2.5878 17.9325 2.5 17.7205 2.5 17.4995V9.16617C2.5 8.94516 2.5878 8.7332 2.74408 8.57692C2.90036 8.42064 3.11232 8.33284 3.33333 8.33284H4.16667V7.49951C4.16619 6.18509 4.60965 4.90904 5.42516 3.8782C6.24067 2.84736 7.38042 2.12216 8.65967 1.82014C9.93891 1.51813 11.2827 1.657 12.4731 2.21426C13.6635 2.77153 14.6309 3.7145 15.2183 4.89034L13.7275 5.63534C13.3079 4.79535 12.6169 4.12171 11.7665 3.72363C10.9161 3.32554 9.95622 3.22636 9.0424 3.44215C8.12858 3.65795 7.31444 4.17607 6.73196 4.91251C6.14949 5.64896 5.83283 6.56055 5.83333 7.49951V8.33284ZM4.16667 9.99951V16.6662H15.8333V9.99951H4.16667ZM8.33333 12.4995H11.6667V14.1662H8.33333V12.4995Z" />
               </svg>
               Security
            </div>
            {/* <div
               className={
                  item === "5"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=5");
                  setItem("5");
               }}
            >
               <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="svg-stroke"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M10.8334 18.3346H4.16675C2.50008 18.3346 1.66675 17.5013 1.66675 15.8346V9.16797C1.66675 7.5013 2.50008 6.66797 4.16675 6.66797H8.33342V15.8346C8.33342 17.5013 9.16675 18.3346 10.8334 18.3346Z"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M8.42508 3.33594C8.35841 3.58594 8.33341 3.86094 8.33341 4.16927V6.66927H4.16675V5.0026C4.16675 4.08594 4.91675 3.33594 5.83341 3.33594H8.42508Z"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M11.6667 6.66797V10.8346"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M15.0001 6.66797V10.8346"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M14.1667 14.168H12.5001C12.0417 14.168 11.6667 14.543 11.6667 15.0013V18.3346H15.0001V15.0013C15.0001 14.543 14.6251 14.168 14.1667 14.168Z"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M5 10.8359V14.1693"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M8.33337 15.8346V4.16797C8.33337 2.5013 9.16671 1.66797 10.8334 1.66797H15.8334C17.5 1.66797 18.3334 2.5013 18.3334 4.16797V15.8346C18.3334 17.5013 17.5 18.3346 15.8334 18.3346H10.8334C9.16671 18.3346 8.33337 17.5013 8.33337 15.8346Z"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               Nearest Outlet
            </div> */}
            <div
               className={
                  item === "6"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=6");
                  mobileClose && mobileClose();

                  setItem("6");
               }}
            >
               <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  className="svg-stroke"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M14.1667 14.3568H10.8334L7.12508 16.8234C6.99993 16.9069 6.85448 16.9548 6.70425 16.962C6.55402 16.9692 6.40464 16.9356 6.27204 16.8646C6.13945 16.7936 6.02862 16.6879 5.95137 16.5588C5.87412 16.4298 5.83335 16.2822 5.83341 16.1318V14.3568C3.33341 14.3568 1.66675 12.6901 1.66675 10.1901V5.1901C1.66675 2.6901 3.33341 1.02344 5.83341 1.02344H14.1667C16.6667 1.02344 18.3334 2.6901 18.3334 5.1901V10.1901C18.3334 12.6901 16.6667 14.3568 14.1667 14.3568Z"
                     stroke-width="1.5"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M10 8.46484V8.28984C10 7.72318 10.35 7.42318 10.7 7.18151C11.0417 6.94818 11.3834 6.64818 11.3834 6.09818C11.3834 5.33151 10.7667 4.71484 10 4.71484C9.23337 4.71484 8.6167 5.33151 8.6167 6.09818"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M9.99585 10.457H10.0058"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               Feedback
            </div>
            <div
               className={
                  item === "7"
                     ? "dashboarditem__container__item active"
                     : "dashboarditem__container__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=7");
                  mobileClose && mobileClose();

                  setItem("7");
               }}
            >
               <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="svg-stroke"
                  xmlns="http://www.w3.org/2000/svg"
               >
                  <path
                     d="M5.93347 5.04016C6.14014 2.64016 7.37347 1.66016 10.0735 1.66016H10.1601C13.1401 1.66016 14.3335 2.85349 14.3335 5.83349V10.1802C14.3335 13.1602 13.1401 14.3535 10.1601 14.3535H10.0735C7.39347 14.3535 6.16014 13.3868 5.94014 11.0268"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M10 8H2.41333"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M3.90008 5.76562L1.66675 7.99896L3.90008 10.2323"
                     stroke-width="1.5"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               Logout
            </div>
         </div>
      </div>
   );
};

export default DashboardItems;

const DashboardHeader = ({ customerId, errorbalance, balance, showbalance, userDetails }) => {
   return (
      <div className="dashboarditem__conrainer__header">
         <h3 className="casid">{customerId ? `Customer ID: ${customerId}` : "No Customer ID"}</h3>

         {errorbalance === true ? (
            <p style={{ marginLeft: "6px" }}>No Balance</p>
         ) : balance ? (
            <p className="balance" style={{ marginLeft: "6px" }}>
               Balance:{" "}
               {`Rs. ${(Math.round(showbalance) < 0
                  ? Math.round(showbalance) * -1
                  : Math.round(showbalance)
               ).toLocaleString("hi-IN")}`}
            </p>
         ) : (
            <p className="balance" style={{ marginLeft: "6px" }}>
               Balance: Loading...
            </p>
         )}
      </div>
   );
};

export const UserCard = ({ userDetails, errorbalance, balance, showbalance, userInfo }) => {
   const [imgError, setImgerror] = useState(false);

   const handleImg = async () => {
      setImgerror(true);
      // console.log(
      //    await axios?.get(`${process.env.REACT_APP_FILE_URL}/${userDetails?.profile_image}`),
      // );
   };
   return (
      <div className="userCard mb-3">
         <h2 className="details-name"> {userInfo?.name}</h2>

         {userDetails?.customer_id && (
            <h2 className="details">Customer ID: {userInfo?.customer_id}</h2>
         )}
         {errorbalance === true ? (
            <h2 className="details">No Balance</h2>
         ) : balance ? (
            <h2 className="details">
               Balance:{" "}
               {`Rs. ${(Math.round(showbalance) < 0
                  ? Math.round(Math?.abs(showbalance))
                  : Math.round(Math.abs(showbalance))
               ).toLocaleString("hi-IN")}`}
            </h2>
         ) : (
            <h2 className="details">Balance : Loading...</h2>
         )}
         <h2 className="details">Email: {userInfo?.email}</h2>
         <h2 className="details">Contact No: {userInfo?.phone}</h2>
      </div>
   );
};
