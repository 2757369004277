import { React, useRef, useState } from "react";
import Login_img from "../../assets/refer.png";
import "../../scss/pages/form.scss";
import "../../scss/pages/forgotpassword.scss";
import Layout from "../include/layout";
import { otp_login, verify_otp } from "../../data/api";
import { useNavigate } from "react-router";
import MessageModal from "../customcomponents/messageModal";
import useToken from "../../utils/useToken";
import Widthcontainer from "../customcomponents/widthcontainer";
import Captcha from "../include/captcha";

const OtpLogin = () => {
   let navigate = useNavigate();
   //Set password and username and show password use state
   const { setToken } = useToken();
   const [number, setNumber] = useState("");
   const [verify, setVerify] = useState(false);
   const [otp, setOtp] = useState("");
   const [show, setShow] = useState(false);
   const [message, setMessage] = useState("");
   const [error, setError] = useState("");
   const [errorShow, setErrorShow] = useState(false);
   const [fieldError, setFieldError] = useState(false);
   const [otpError, setOtpError] = useState(false);
   const [errorNumber, setErrorNumber] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");

   const reRef = useRef();

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (number === "") {
         setFieldError(true);
         setTimeout(() => {
            setFieldError(false);
         }, 5000);
      } else {
         const captchaToken = await reRef.current.executeAsync();
         const response = await otp_login(
            number?.length > 10
               ? number?.slice(0, 4) === "+977"
                  ? number?.slice(4, 18)
                  : number
               : number,
            captchaToken,
         );
         if (response?.data?.success === true) {
            // alert("OTP sent to your registered mobile number");
            setMessage(response?.data?.message);
            setShow(true);
            setVerify(true);
         } else {
            setErrorMessage(response?.response?.data?.message);
            setErrorNumber(true);
         }
      }
   };
   const handleKeySubmit = async (e) => {
      // e.preventDefault();
      if (e.key === "Enter") {
         if (number === "") {
            setFieldError(true);
            setTimeout(() => {
               setFieldError(false);
            }, 5000);
         } else {
            const captchaToken = await reRef.current.executeAsync();
            const response = await otp_login(
               number?.length > 10
                  ? number?.slice(0, 4) === "+977"
                     ? number?.slice(4, 18)
                     : number
                  : number,
               captchaToken,
            );
            if (response?.data?.success === true) {
               // alert("OTP sent to your registered mobile number");
               setMessage(response?.data?.message);
               setShow(true);
               setVerify(true);
            } else {
               setErrorMessage(response?.response?.data?.message);
               setErrorNumber(true);
            }
         }
      }
   };
   const handleKeyVerify = async (e) => {
      if (e.key === "Enter") {
         if (otp === "") {
            setOtpError(true);
            setTimeout(() => {
               setOtpError(false);
            }, 5000);
         } else {
            const captchaToken = await reRef.current.executeAsync();
            const response = await verify_otp(
               number?.length > 10
                  ? number?.slice(0, 4) === "+977"
                     ? number?.slice(4, 18)
                     : number
                  : number,
               otp,
               captchaToken,
            );
            if (response.statusText === "Created") {
               const accessToken = response?.data?.token.accessToken;
               const refreshToken = response?.data?.token.refreshToken;
               const expiresIn = response?.data?.token.expiresIn;
               const user = response?.data?.user;
               setToken({ accessToken, refreshToken, expiresIn, user });
               navigate(-1);
            } else if (response?.response?.statusText === "Unauthorized") {
               setErrorShow(true);
               setError(response?.response?.data?.message);
            } else {
               setErrorShow(true);
               setError(response?.response?.data?.message);
            }
         }
      }
   };
   const verifyOtp = async (e) => {
      e.preventDefault();
      if (otp === "") {
         setOtpError(true);
         setTimeout(() => {
            setOtpError(false);
         }, 5000);
      } else {
         const captchaToken = await reRef.current.executeAsync();
         const response = await verify_otp(
            number?.length > 10
               ? number?.slice(0, 4) === "+977"
                  ? number?.slice(4, 18)
                  : number
               : number,
            otp,
            captchaToken,
         );

         if (response.statusText === "Created") {
            const accessToken = response?.data?.token.accessToken;
            const refreshToken = response?.data?.token.refreshToken;
            const expiresIn = response?.data?.token.expiresIn;
            const user = response?.data?.user;
            setToken({ accessToken, refreshToken, expiresIn, user });
            navigate(-1);
         } else if (response?.response?.statusText === "Unauthorized") {
            setErrorShow(true);
            setError(response?.response?.data?.message);
         } else {
            setErrorShow(true);
            setError(response?.response?.data?.message);
         }
      }
   };

   return (
      <Layout>
         <MessageModal show={show} setShow={setShow} message={message} buttonText={"Ok"} />
         <MessageModal
            show={errorShow}
            type="error"
            setShow={setErrorShow}
            message={error}
            buttonText={"OK"}
         />
         <MessageModal
            show={errorNumber}
            type="error"
            setShow={setErrorNumber}
            message={errorMessage}
            buttonText={"OK"}
         />

         <Widthcontainer>
            <div className={"ForgotPassword_Wrapper fixed-width mt-4"}>
               {verify ? (
                  <>
                     <div className={"ForgotPassword_Form"}>
                        {/* <Logo /> */}
                        <div className={"ForgotPassword_Container"}>
                           <h1 className={"For'gotPassword_Header"}>Verify OTP</h1>
                           <h3 className={"ForgotPassword_Text"}>
                              Please enter the verification code sent to your registered mobile
                              number to complete registration.
                           </h3>
                           <label htmlFor="phone">Verification code</label>
                           <input
                              type="number"
                              name="otp"
                              onKeyPress={(e) => handleKeyVerify(e)}
                              className={"Phone_Input"}
                              placeholder=""
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                           />{" "}
                           {otpError === true && <p style={{ color: "red" }}>OTP is required!</p>}
                           <button className={"ForgotPassword_Button"} onClick={verifyOtp}>
                              Verify
                           </button>
                           <h3 className={"ForgotPassword_Text"}>
                              Didn't get verification code ?
                              <a style={{ cursor: "pointer" }} onClick={handleSubmit} href>
                                 {" "}
                                 Resend{" "}
                              </a>
                           </h3>
                        </div>
                     </div>
                  </>
               ) : (
                  <div className={"ForgotPassword_Form"}>
                     {/* <Logo /> */}
                     <div className={"ForgotPassword_Container"}>
                        <h1 className={"ForgotPassword_Header"}> Login with verification code</h1>
                        <h3 className={"ForgotPassword_Text"}>
                           Please enter your registered mobile number to get verification code!
                        </h3>
                        <label htmlFor="phone">Phone Number</label>
                        <input
                           type="tel"
                           name="phone"
                           className={"Phone_Input"}
                           maxLength="14"
                           placeholder=""
                           autoComplete="on"
                           onKeyPress={(e) => handleKeySubmit(e)}
                           value={number}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                    setNumber(e.target?.value);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    setNumber(e?.target?.value);
                                 }
                              } else {
                                 setNumber(e?.target?.value);
                              }
                           }}
                        />
                        {fieldError === true && (
                           <p style={{ color: "red" }}>Phone number is required!</p>
                        )}
                        <button className={"ForgotPassword_Button red"} onClick={handleSubmit}>
                           Send Verification Code
                        </button>
                        <h3 className={"ForgotPassword_Text"}>
                           Go back to <a href="/login"> sign in </a>
                        </h3>
                     </div>
                  </div>
               )}

               <div className={"Forgot_Image"}>
                  <img src={Login_img} alt="" />
               </div>
            </div>
         </Widthcontainer>
         <Captcha reRef={reRef} />
      </Layout>
   );
};

export default OtpLogin;
