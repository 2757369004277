import React, { useEffect, useState } from "react";
import "../scss/dashboard/dashboardaccount.scss";
import "../scss/components/kyc.scss";
import { Modal } from "react-bootstrap";

import "../scss/pages/form.scss";
import "../scss/packages/order.scss";
import { Button, FormControl, InputGroup, Form } from "react-bootstrap";
// import rounded from "../assets/dashboard/rounded.png";
import { Formik } from "formik";
import * as yup from "yup";
import Token from "../utils/useToken";

import { provinces, districts } from "../data/location/province-district";

import Checkbox from "@mui/material/Checkbox";
import { Avatar, FormGroup } from "@mui/material";
import { Alert } from "react-bootstrap";

import {
   updatekyc,
   updateprofile,
   get_customer_id,
   verify_otp,
   check_balance,
   change_primary_number,
   verify_primary_number,
   update_profile,
} from "../data/protectedapi";
import MessageModal from "../components/customcomponents/messageModal";
import { MdError } from "react-icons/md";
import Loading from "../components/customcomponents/loading";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Captcha from "../components/include/captcha";
import { useRef } from "react";

export default function DashboardAccount() {
   const [loading, setIsLoading] = useState(false);
   // function useQuery() {
   //    const { search } = useLocation();
   //    return React.useMemo(() => new URLSearchParams(search), [search]);
   // }
   // let query = useQuery();
   let municipalityOption = [];
   const { setToken, token, userDetails } = Token();
   let customerIdparams = userDetails?.customer_id;
   const UserDetails = token.user;
   const [File, setFile] = useState(
      `${process.env.REACT_APP_FILE_URL}/${UserDetails?.profile_image}`,
   );
   // console.log("user", File);
   const [FileName, setFileName] = useState("Upload picture");
   const [Filedata, setFiledata] = useState(null);
   const [imgError, setImgerror] = useState(false);
   const handleImg = () => {
      setImgerror(true);
   };
   // console.log("image", imgError);
   //validate input file type
   const fileValidation = (filename, file) => {
      const fileType = file.type;
      const validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/webp"];
      if (validImageTypes.includes(fileType)) {
         setImgerror(false);
         setFile(URL.createObjectURL(file));
         setFileName(filename);
         setFiledata(file);
      } else {
         alert("Please upload a valid image file (jpg, jpeg, png, gif)");
      }
   };
   // console.log("file", File);
   const removeImg = () => {
      setFile(`${process.env.REACT_APP_FILE_URL}/${UserDetails?.profile_image}`);
      setFileName("Upload picture");
      setImgerror(true);
      setFiledata(null);
   };
   return (
      <div style={{ width: "100%" }}>
         <div className="dashboard-profile">
            <div>
               {File === process.env.REACT_APP_BASE_URL + "v1/file/undefined" ? (
                  <Avatar
                     sx={{
                        bgcolor: "#C30C6B",
                        height: 35,
                        width: 35,
                     }}
                  >
                     {UserDetails?.name?.charAt(0)}
                  </Avatar>
               ) : imgError === false ? (
                  <img
                     src={File}
                     alt="profile"
                     onError={handleImg}
                     height={88}
                     width={88}
                     style={{ borderRadius: "50%" }}
                  />
               ) : (
                  <Avatar
                     sx={{
                        bgcolor: "#C30C6B",
                        height: 35,
                        width: 35,
                     }}
                  >
                     {UserDetails?.name?.charAt(0)}
                  </Avatar>
               )}
            </div>
            <div>
               <input
                  type="file"
                  name="profile_pic"
                  id="dashboard-upload"
                  title="Upload picture"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={(e) => fileValidation(e.currentTarget.value, e.currentTarget.files[0])}
               />
               <label for="dashboard-upload" id="dashboard-upload">
                  {/* {FileName}
                   */}
                  Upload picture
               </label>
               {/* <Button type="input" id="dashboard-upload">
                        Upload picture
                    </Button> */}
            </div>
            <div>
               <Button id="dashboard-remove" onClick={removeImg}>
                  Remove
               </Button>
            </div>
         </div>
         <DashboardInput
            setIsLoading={setIsLoading}
            loading={loading}
            customerIdparams={customerIdparams}
            user={UserDetails}
            Filedata={Filedata}
         />
      </div>
   );
}

export function DashboardInput({ user, setIsLoading, loading, Filedata, customerIdparams }) {
   const { setToken, token } = Token();
   const [messageModal, setMessageModal] = useState(false);
   const [error, setError] = useState(false);
   const [type, setType] = useState(null);
   const [newnumber, setNewnumber] = useState("");
   const [numberpassword, setNumberpassword] = useState();
   const [otp, setOtp] = useState();
   const [notifyNumber, setNotifynumber] = useState(false);
   const [message, setMessage] = useState("");
   const [wrongpassword, setWrongpassword] = useState(false);
   const [wrongmessage, setWrongmessage] = useState("");
   const [heading, setHeading] = useState("Profile Updated");
   const [reload, setReload] = useState(true);
   const [userShippingAddress, setUserShippingAddress] = useState("");
   const [shippingId, setShippingId] = useState("");
   //get user profile
   const [userInfo, setUserInfo] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setUserInfo(res?.data);
         setUserShippingAddress(res?.data?.shipping_address);
      }
      get_navigation();
   }, []);
   // ("user", userShippingAddress);
   useEffect(() => {
      Object.entries(userShippingAddress).map(([key, value]) => {
         if (value?.same_as_billing === true) {
            setShippingId(value?._id);
         }
      });
   }, [userShippingAddress]);
   const schema = yup.object().shape({
      fullName: yup
         .string()
         .required()
         .min(5)
         .max(30)
         .matches(/^([a-zA-Z0-9 _-]+)$/, "Special characters not allowed"),
      primarycontact: yup.string().required(),
      email: yup
         .string()
         .required("Email is required")
         .email("Invalid Email")
         .min(6, "Email must be 6 characters long")
         .matches(
            "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])",
            "Invalid Email",
         )
         .min(6)
         .max(60),
      secondarycontact: yup
         .string()
         .min(10, "Please Enter Valid Phone Number")
         .max(14, "Please Enter Valid Phone Number"),
   });
   // console.log("file", Filedata);
   const handleSubmit = async (values) => {
      const accessToken = token.accessToken;
      const refreshToken = token.refreshToken;
      const expiresIn = token.expiresIn;
      let number = values?.secondarycontact;
      if (values?.secondarycontact > 10) {
         if (values?.secondarycontact?.slice(0, 4) === "+977") {
            number = values?.secondarycontact?.slice(4, 15);
         }
      }
      const response = await updateprofile(
         Filedata,
         values.fullName,
         values.email,
         number,
         values.primarycontact,
      );
      if (response?.data?.success === true) {
         const user = response?.data?.data;
         setToken({ accessToken, refreshToken, expiresIn, user });
         setType(null);
         setHeading("Profile Updated");
         setMessage(null);
         setMessageModal(true);
         setReload(true);
      } else {
         setType("error");
         setMessageModal(true);
         setHeading(null);
         setReload(false);
         setMessage(response?.response?.data?.message);
      }
   };
   const [showmodal, setshowModal] = useState(false);
   const handlechangeNumber = () => {
      setshowModal(true);
   };

   const handleeditNumber = async () => {
      const res = await change_primary_number(
         newnumber?.length > 10
            ? newnumber?.slice(0, 4) === "+977"
               ? newnumber?.slice(4, 18)
               : newnumber
            : newnumber,
         numberpassword,
      );
      if (res?.data?.success === true) {
         setNotifynumber(true);
         setWrongpassword(false);
      } else {
         setWrongpassword(true);
         setWrongmessage(res?.response?.data?.message);
      }
   };

   const [otpError, setOtpError] = useState(false);
   const [otpErrorMessage, setOtpErrorMessage] = useState("");
   const verifyOtp = async () => {
      const accessToken = token.accessToken;
      const refreshToken = token.refreshToken;
      const expiresIn = token.expiresIn;
      const res = await verify_primary_number(
         newnumber?.length > 10
            ? newnumber?.slice(0, 4) === "+977"
               ? newnumber?.slice(4, 18)
               : newnumber
            : newnumber,
         otp,
      );
      // console.log("error", res?.response?.data?.message);
      if (res?.data?.success === true) {
         const user = res?.data?.data;
         setToken({ accessToken, refreshToken, expiresIn, user });
         // setMessageModal(true);
         setMessageModal(true);
         setType(null);
         setOtpError(false);
         setMessage("Phone number changed");
         setshowModal(false);
      } else {
         // console.log("error", res?.response);
         setOtpError(true);
         setOtpErrorMessage(res?.response?.data?.message);
      }
   };
   const [showPassword, setShowPassword] = useState(false);
   function togglePassword() {
      setShowPassword(!showPassword);
   }
   return (
      <div className="dashboard-form">
         <Modal
            show={showmodal}
            centered
            onHide={() => {
               setshowModal(false);
            }}
         >
            <div style={{ marginTop: "20px", marginLeft: "34px" }}>
               <h2>Change Number</h2>
               {wrongpassword ? (
                  <Alert variant="danger" style={{ width: "308px", marginTop: "13px" }}>
                     <MdError />
                     &nbsp;&nbsp; {wrongmessage ? wrongmessage : ""}
                  </Alert>
               ) : null}

               {otpError && (
                  <Alert variant="danger" style={{ width: "308px", marginTop: "13px" }}>
                     <MdError />
                     &nbsp;&nbsp; {otpErrorMessage ? otpErrorMessage : ""}
                  </Alert>
               )}
               <label htmlFor="phonenumber">Enter number</label>
               <br />
               <input
                  type="tel"
                  name="phonenumber"
                  autoComplete="off"
                  maxLength={14}
                  onChange={(e) => {
                     if (e?.nativeEvent?.data) {
                        if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                           setNewnumber(e.target?.value);
                        }
                        if (e?.nativeEvent?.data === null) {
                           setNewnumber(e?.target?.value);
                        }
                     } else {
                        setNewnumber(e?.target?.value);
                     }
                  }}
                  value={newnumber}
                  style={{ width: "300px" }}
               />
            </div>

            <div style={{ marginTop: "20px", marginLeft: "34px" }}>
               <label>Enter password</label>
               <br />
               <div style={{ width: "fit-content", position: "relative" }}>
                  <input
                     onChange={(e) => setNumberpassword(e.target.value)}
                     value={numberpassword}
                     autoComplete="off"
                     style={{ width: "300px", paddingRight: "35px" }}
                     type={!showPassword ? "password" : "text"}
                     onPaste={(e) => {
                        e.preventDefault();
                        return false;
                     }}
                  />
                  {!showPassword ? (
                     <AiOutlineEyeInvisible
                        size={19}
                        className="Pw_Icon"
                        onClick={togglePassword}
                        style={{ top: "16px" }}
                     />
                  ) : (
                     <AiOutlineEye
                        size={19}
                        className="Pw_Icon"
                        onClick={togglePassword}
                        style={{ top: "16px" }}
                     />
                  )}
               </div>
            </div>

            {notifyNumber ? (
               <>
                  <div style={{ marginTop: "20px", marginLeft: "34px" }}>
                     <label>Enter OTP</label>
                     <br />
                     <input
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                        style={{ width: "300px" }}
                        type="text"
                     />
                  </div>
               </>
            ) : null}
            <div
               className="buttonContainer d-flex align-items-center mb-3 mt-1"
               style={{ gap: "16px" }}
            >
               {notifyNumber ? (
                  <button
                     onClick={verifyOtp}
                     style={{
                        marginLeft: "36px",
                        width: "140px",
                     }}
                     id="Signin_Button"
                  >
                     Verify OTP
                  </button>
               ) : (
                  <button
                     style={{
                        marginLeft: "36px",
                        width: "140px",
                     }}
                     className="red"
                     onClick={handleeditNumber}
                     id="Signin_Button"
                     variant="secondary"
                  >
                     Change number
                  </button>
               )}
               <div
                  id="Signin_Button"
                  variant="secondary"
                  className="d-flex justify-content-center align-items-center px-3 red"
                  onClick={() => setshowModal(false)}
                  style={{ cursor: "pointer" }}
               >
                  Cancel
               </div>
            </div>
         </Modal>
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading={heading}
            message={message}
            buttonText="OK"
            reload={reload}
            type={type}
         />

         <Formik
            validationSchema={schema}
            enableReinitialize
            initialValues={{
               fullName: userInfo?.name,
               primarycontact: user?.phone,
               email: userInfo?.email,
               secondarycontact: user?.secondary_phone ? user?.secondary_phone : "",
            }}
            onSubmit={(values, actions) => {
               handleSubmit(values);
            }}
         >
            {({ handleSubmit, handleChange, values, touched, errors, isValid }) => (
               <Form id="dashboard-formwidth" noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId="validationFormik01">
                     <Form.Label id="dashboard-label">Full Name</Form.Label>
                     <Form.Control
                        id="input-dashboard"
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        minLength="2"
                        maxLength="30"
                        onChange={handleChange}
                        isValid={touched.fullName && !errors.fullName ? "is-invalid" : null}
                     />
                     {touched.fullName && errors.fullName && (
                        <p className="text-danger mb-0">{errors.fullName}</p>
                     )}
                  </Form.Group>

                  <Form.Group id="label-distance" controlId="validationFormik01">
                     <Form.Label id="dashboard-label">Email</Form.Label>
                     <Form.Control
                        id="input-dashboard"
                        type="text"
                        name="email"
                        minLength="6"
                        maxLength="60"
                        value={values.email}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.match("[0-9A-za-z- _.@]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        isValid={touched.email && !errors.email}
                     />
                     {touched.email && errors.email && (
                        <p className="text-danger mb-0">{errors.email}</p>
                     )}
                  </Form.Group>
                  <div id="form-account">
                     <div>
                        <Form.Group controlId="validationFormik01">
                           <Form.Label id="dashboard-label">Primary Contact Number</Form.Label>
                           <Form.Control
                              disabled
                              id="input-account"
                              type="number"
                              name="primarycontact"
                              value={values.primarycontact}
                              onChange={(e) => {
                                 !isNaN(e?.nativeEvent?.data) && handleChange(e);
                              }}
                              isValid={touched.primarycontact && !errors.primarycontact}
                           />
                           {touched.primarycontact && errors.primarycontact && (
                              <p className="text-danger mb-0">primary contact number is required</p>
                           )}
                        </Form.Group>
                     </div>
                     <div>
                        <Button
                           variant="secondary"
                           onClick={handlechangeNumber}
                           style={{ backgroundColor: "grey", border: "none", padding: "7px 5px" }}
                        >
                           Change primary number
                        </Button>
                     </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                     <Form.Group controlId="validationFormik01">
                        <Form.Label id="dashboard-label">Secondary Contact Number</Form.Label>
                        <Form.Control
                           id="input-account"
                           type="tel"
                           name="secondarycontact"
                           maxLength={14}
                           value={values.secondarycontact}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                    handleChange(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleChange(e);
                                 }
                              } else {
                                 handleChange(e);
                              }
                           }}
                           isValid={touched.secondarycontact && !errors.secondarycontact}
                        />
                        {touched.secondarycontact || error.secondarycontact ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors.secondarycontact}
                           </p>
                        ) : null}
                     </Form.Group>
                  </div>
                  <Button id="dashboardform-btn" type="submit">
                     Save
                  </Button>
               </Form>
            )}
         </Formik>
         <KycForm
            setIsLoading={setIsLoading}
            userInfo={userInfo}
            loading={loading}
            user={user}
            shippingId={shippingId}
            customerIdparams={customerIdparams}
         />
      </div>
   );
}

export function KycForm({ user, shippingId, userInfo, customerIdparams, loading, setIsLoading }) {
   const [checked, setChecked] = React.useState(false);
   const [kycotp, setKycotp] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [message, setMessage] = useState("");
   const [error, setError] = useState(false);
   const [btnStatus, setBtnStatus] = useState(false);
   const [buttonStatus, setButtonStatus] = useState(false);
   // const { token, setToken, userDetails } = Token();
   const { setToken, token } = Token();
   const userDetails = token.user;
   const handleKycChange = (event) => {
      setChecked(event.target.checked);
   };
   // console.log("user", shippingId);
   const reRef = useRef();

   const schema = yup.object().shape({
      // customer_id: yup.string().required(),
      name: yup
         .string()
         .required("Name is a required field")
         .min(2, "Name must be 2 characters long")
         .matches(/^([a-zA-Z0-9 _-]+)$/, "Special characters not allowed"),
      address: yup
         .string()
         .required("Landmark is a required field")
         .min(5, "Landmark must be 5 characters long"),
      street: yup
         .string()
         .required("Street is a required field")
         .min(5, "Street must be 5 characters long"),
      landmark: yup.string().required("Address is a required field"),
      district: yup.string().required(),
      province: yup.string().required(),
      shippingname: yup
         .string()
         .required("Shipping name is a required field")
         .min(2, "Name must be 5 characters long")
         .matches(/^([a-zA-Z0-9 _-]+)$/, "Special characters not allowed"),
      shippingaddress: yup
         .string()
         .required("Shipping landmark is a required field")
         .min(5, "Shipping landmark must be 5 characters long"),
      shippingstreet: yup
         .string()
         .required("Shipping street is a required field")
         .min(5, "Shipping street must be 5 characters long"),
      shippinglandmark: yup.string().required("Shipping address is a required field"),
      shippingdistrict: yup.string().required(),
      shippingprovince: yup.string().required(),
   });
   const [customerid, setCustomerid] = useState(user.customer_id ? user.customer_id : "");
   const [otp, setOtp] = useState();

   const [verifieddata, setVerifieddata] = useState("");
   const [balance, setBalance] = useState("");
   const [savedata, setSavedata] = useState("");
   const handleSubmit = async (values) => {
      const captchaToken = await reRef.current.executeAsync();
      // console.log("Token", captchaToken);
      const response = await updatekyc(
         user.customer_id ? user.customer_id : "",
         values.name,
         values.address,
         values.street,
         values.landmark,
         values.district,
         values.province,
         checked ? values.name : values.shippingname,
         checked ? values.address : values.shippingaddress,
         checked ? values.street : values.shippingstreet,
         checked ? values.landmark : values.shippinglandmark,
         checked ? values.district : values.shippingdistrict,
         checked ? values.province : values.shippingprovince,
         shippingId,
         captchaToken,
      );

      if (response?.status === 200) {
         const accessToken = token.accessToken;
         const refreshToken = token.refreshToken;
         const expiresIn = token.expiresIn;
         const user = response?.data?.data;
         setReload(true);
         setToken({ accessToken, refreshToken, expiresIn, user });
         setMessageModal(true);
         setMessage(response.data.message);
      } else {
         setError(true);
         setMessage("Process failed");
      }
   };

   //GET OTP
   const [emptyInput, setEmptyInput] = useState(false);
   const [emptyInput1, setEmptyInput1] = useState(false);
   const [showOtpfield, setShowOtpfield] = useState(false);
   const [otpModal, setOtpModal] = useState(false);
   const [reload, setReload] = useState(true);

   const handleOTP = async () => {
      if (customerid === "") {
         setEmptyInput1(true);
      } else {
         setEmptyInput1(false);
         setButtonStatus(true);
         const response = await get_customer_id(customerid);
         if (response?.status === 200) {
            setShowOtpfield(true);
            setVerifieddata(response.data.data);
            setReload(false);
            setButtonStatus(false);
            setMessage(
               `Please enter the verification code sent to mobile number of customer id ${customerid} (${response?.data?.maskedPhoneNumber}) to complete verification.`,
            );
            setMessageModal(true);
         } else {
            setCustomerid("");
            setReload(false);
            // console.log("error", customerid);
            setShowOtpfield(false);
            setError(true);
            setButtonStatus(false);
            setMessage(
               response?.response?.data?.ResponseDescription || response?.response?.data?.message,
            );
         }
      }
   };
   //VERIFY OTP
   const [kycmodal, setKycmodal] = useState(false);
   const [heading, setHeading] = useState("");
   const verifyOTP = async () => {
      const accessToken = token.accessToken;
      const refreshToken = token.refreshToken;
      const expiresIn = token.expiresIn;
      if (!otp) {
         setEmptyInput(true);
      } else {
         setEmptyInput(false);
         setBtnStatus(true);
         const response = await verify_otp(otp, customerid);
         if (response?.data?.success === true) {
            setVerifieddata(response?.data?.data);
            const user = response?.data?.user;
            setToken({ accessToken, refreshToken, expiresIn, user });
            setOtpModal(true);
            setMessage("Verified");
            setBtnStatus(false);
         } else {
            setBtnStatus(false);
            setError(true);
            setHeading("Verification Failed");
            setMessage(
               `Please enter the correct verification code sent to mobile number of customer id ${customerid} (${
                  response?.data?.phoneNumber.replace(/\d{8}$/, "") +
                  response?.data?.phoneNumber.replace(/^\d{7}/, "*****")
               }) to complete verification.`,
            );
         }
      }
   };
   //to prefill billing address
   useEffect(() => {
      async function get_balance() {
         if (userDetails?.is_kyc_verified === true) {
            const res = await check_balance();
            const response = res?.data?.data;
            setBalance(response);
         }
      }
      get_balance();
   }, [loading]);
   //check balance and get info

   const handleChange1 = (e) => {
      setOtp(e.target.value);
   };
   //user info
   const [billingAddress, setBillingAddress] = useState("");
   const [shippingAddress, setShippingAddress] = useState("");
   useEffect(() => {
      if (userInfo) {
         Object.entries(userInfo?.shipping_address).map(([key, value]) => {
            if (value?.same_as_billing === true) {
               setShippingAddress(value);
            }
         });
      }
   }, [userInfo]);

   const options = ["1", "2", "3", "4", "5", "6", "7"];

   return loading ? (
      <Loading />
   ) : (
      <div>
         <MessageModal
            show={kycmodal}
            setShow={setKycmodal}
            heading=""
            message={message}
            buttonText="Ok"
            reload={true}
         />
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading=""
            message={message}
            buttonText="Ok"
            reload={reload}
         />
         <MessageModal
            show={otpModal}
            setShow={setOtpModal}
            heading="Verification Successful"
            message={"Your account has been verified successfully. Thank you for using DishHome."}
            buttonText="Ok"
            reload={true}
            link="/dashboard?type=0"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={heading}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         <Captcha reRef={reRef} />
         <div id="kyc-text-wrapper">
            <p className="kyc-text"> Know Your Customer(KYC) Form</p>
         </div>
         <Form.Label id="dashboard-label">Customer Id</Form.Label> <br />
         <InputGroup className="mb-3 dashboard-InputGroup">
            <br />
            {user.is_kyc_verified === true ? (
               <FormControl
                  disabled
                  type="text"
                  aria-label="customer id"
                  aria-describedby="basic-addon2"
                  name="customer_id"
                  value={customerid}
                  onChange={(e) => setCustomerid(e.target.value)}
               />
            ) : (
               <FormControl
                  type="text"
                  aria-label="customer id"
                  aria-describedby="basic-addon2"
                  name="customer_id"
                  value={customerid}
                  onChange={(e) => setCustomerid(e.target.value)}
               />
            )}

            {userDetails?.is_kyc_verified === true ? null : (
               <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  className={buttonStatus ? "apply-cas-button disabled" : "apply-cas-button"}
                  onClick={handleOTP}
               >
                  Get OTP
               </Button>
            )}
         </InputGroup>
         {emptyInput1 ? (
            <Alert variant="danger" className={"Order_error_show"} style={{ width: "280px" }}>
               <MdError />
               &nbsp;&nbsp; Field can't be empty
            </Alert>
         ) : (
            ""
         )}
         {userDetails?.is_kyc_verified === true ? null : (
            <Form.Group>
               <Form.Label id="dashboard-label">Enter OTP</Form.Label>
               <InputGroup className="mb-3 dashboard-InputGroup">
                  <Form.Control
                     aria-describedby="basic-addon2"
                     name="otp"
                     type="text"
                     value={otp}
                     onChange={(e) => handleChange1(e)}
                  />
                  <Button
                     variant="outline-secondary"
                     id="button-addon2"
                     // className="apply-cas-button"
                     className={btnStatus ? "apply-cas-button disabled" : "apply-cas-button-otp"}
                     onClick={verifyOTP}
                  >
                     Verify OTP
                  </Button>
               </InputGroup>
               {emptyInput ? (
                  <Alert variant="danger" className={"Order_error_show"} style={{ width: "280px" }}>
                     <MdError />
                     &nbsp;&nbsp; Field can't be empty
                  </Alert>
               ) : null}
            </Form.Group>
         )}
         <div style={{ marginTop: "10px" }}>
            {
               <Formik
                  enableReinitialize
                  validationSchema={schema}
                  initialValues={{
                     name:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.name
                           : balance?.CustomerName
                           ? balance?.CustomerName
                           : "",
                     address:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.address
                           : "",
                     street:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.street
                           : balance?.Street
                           ? balance?.Street
                           : "",
                     landmark:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.landmark
                           : balance?.MunicipalityorVDC
                           ? balance?.MunicipalityorVDC
                           : "", //municipality,
                     district:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.district
                           : balance?.District
                           ? balance.District
                           : "Select District",
                     province:
                        userInfo?.billing_address &&
                        Object?.keys(userInfo?.billing_address)?.length !== 0
                           ? userInfo?.billing_address?.province
                           : "Select province",
                     shippingname: shippingAddress?.name ? shippingAddress?.name : "",
                     shippingaddress: shippingAddress?.address ? shippingAddress?.address : "",
                     shippingstreet: shippingAddress?.street ? shippingAddress?.street : "",
                     shippinglandmark: shippingAddress?.landmark ? shippingAddress?.landmark : "",
                     shippingdistrict: shippingAddress?.district
                        ? shippingAddress?.district
                        : "Select District",
                     shippingprovince: shippingAddress?.province
                        ? shippingAddress?.province
                        : "Select Province",
                  }}
                  onSubmit={(values, action) => {
                     handleSubmit(values);
                  }}
               >
                  {({ handleSubmit, handleChange, values, touched, errors, isValid }) => (
                     <Form id="dashboard-formwidth" noValidate onSubmit={(e) => handleSubmit(e)}>
                        <div>
                           {" "}
                           <div>
                              <p id="billing-title">Billing Address</p>

                              <Form.Group controlId="validationFormik01">
                                 <Form.Label id="dashboard-label">Name</Form.Label>
                                 <Form.Control
                                    id="input-dashboard"
                                    type="text"
                                    name="name"
                                    minLength={2}
                                    maxLength={50}
                                    value={values.name}
                                    onChange={handleChange}
                                    isValid={touched.name && !errors.name}
                                 />
                                 {touched.name && errors.name && (
                                    <p className="text-danger mb-0">{errors.name}</p>
                                 )}
                              </Form.Group>
                           </div>
                           <Form.Group controlId="validationFormik01" style={{ marginTop: "15px" }}>
                              <Form.Label id="dashboard-label">Province</Form.Label>
                              <Form.Select
                                 id="input-dashboard"
                                 type="text"
                                 name="province"
                                 // value={values.province}
                                 onChange={handleChange}
                                 isValid={touched.province && !errors.province}
                              >
                                 <option
                                    value={null}
                                    selected={values?.province === "Select Province"}
                                 >
                                    Select Province
                                 </option>
                                 {provinces.map((item) => (
                                    <option
                                       value={item.name}
                                       selected={values?.province === item?.name}
                                    >
                                       {item.name}
                                    </option>
                                 ))}
                                 {touched.province && errors.province && (
                                    <p className="text-danger mb-0">province is required</p>
                                 )}
                              </Form.Select>
                           </Form.Group>
                           <div className="input-wrapper-billing">
                              <div>
                                 <Form.Group id="label-distance" controlId="validationFormik01">
                                    <Form.Label id="dashboard-label">District</Form.Label>
                                    <Form.Select
                                       id="input-flex"
                                       type="text"
                                       name="district"
                                       // value={values.district}
                                       onChange={handleChange}
                                       isValid={touched.district && !errors.district}
                                    >
                                       <option
                                          value={null}
                                          selected={values?.district === "Select District"}
                                       >
                                          Select District
                                       </option>
                                       {districts.map(
                                          (item) =>
                                             values.province === item.province && (
                                                <option
                                                   value={item.name}
                                                   selected={
                                                      item?.name?.toUpperCase() ===
                                                      values?.district?.toUpperCase()
                                                   }
                                                >
                                                   {item?.name}
                                                </option>
                                             ),
                                       )}
                                       {touched.district && errors.district && (
                                          <p className="text-danger mb-0">district is required</p>
                                       )}
                                    </Form.Select>
                                 </Form.Group>
                              </div>
                              <div>
                                 <Form.Group controlId="validationFormik01">
                                    <Form.Label id="dashboard-label">Municipality</Form.Label>

                                    <Form.Control
                                       id="input-flex"
                                       type="text"
                                       name="landmark"
                                       value={values.landmark}
                                       onChange={handleChange}
                                       isValid={
                                          touched.landmark && !errors.landmark ? "is-invalid" : null
                                       }
                                    />
                                    {touched.landmark && errors.landmark && (
                                       <p className="text-danger mb-0">Municipality is required</p>
                                    )}
                                 </Form.Group>
                              </div>
                           </div>
                           <Form.Group id="label-distance" controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Street</Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 name="street"
                                 value={values.street}
                                 maxLength={350}
                                 onChange={handleChange}
                                 isValid={touched.street && !errors.street ? "is-invalid" : null}
                              />
                              {touched.street && errors.street && (
                                 <p className="text-danger mb-0">{errors.street}</p>
                              )}
                           </Form.Group>
                           <Form.Group id="label-distance" controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Landmark</Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 name="address"
                                 maxLength={350}
                                 value={values.address}
                                 onChange={handleChange}
                                 isValid={touched.address && !errors.address ? "is-invalid" : null}
                              />
                              {touched.address && errors.address && (
                                 <p className="text-danger mb-0">{errors.address}</p>
                              )}
                           </Form.Group>
                        </div>

                        {/* SHIPPING ADDRESS FORM */}
                        <div>
                           <div>
                              <p id="billing-title">Shipping Address</p>

                              <Form.Label style={{ cursor: "pointer" }} for="billing_address">
                                 Same as billing address
                              </Form.Label>
                              <Checkbox
                                 id="billing_address"
                                 onChange={handleKycChange}
                                 inputProps={{
                                    "aria-label": "controlled",
                                 }}
                              />
                              <Form.Group controlId="validationFormik01">
                                 <Form.Label id="dashboard-label">Name</Form.Label>
                                 <Form.Control
                                    id="input-dashboard"
                                    type="text"
                                    minLength={2}
                                    maxLength={50}
                                    name="shippingname"
                                    value={
                                       checked
                                          ? (values.shippingname = values.name)
                                          : values.shippingname
                                    }
                                    onChange={handleChange}
                                    isValid={
                                       checked
                                          ? touched.name && !errors.name
                                          : touched.shippingname && !errors.shippingname
                                    }
                                 />

                                 {!checked && touched.shippingname && errors.shippingname && (
                                    <p className="text-danger mb-0">{errors.shippingname}</p>
                                 )}
                              </Form.Group>
                           </div>
                           <Form.Group controlId="validationFormik01" style={{ marginTop: "15px" }}>
                              <Form.Label id="dashboard-label">Province</Form.Label>
                              <Form.Select
                                 id="input-dashboard"
                                 type="text"
                                 name="shippingprovince"
                                 // value={
                                 //    checked
                                 //       ? (values.shippingprovince = values.province)
                                 //       : values.shippingprovince
                                 // }
                                 onChange={handleChange}
                                 isValid={touched.shippingprovince && !errors.shippingprovince}
                              >
                                 <option
                                    value={null}
                                    selected={values?.shippingprovince === "Select Province"}
                                 >
                                    Select Province
                                 </option>
                                 {provinces.map((item) => (
                                    <>
                                       <option
                                          value={item.name}
                                          selected={
                                             checked
                                                ? item?.name?.toUpperCase() ===
                                                  values?.province?.toUpperCase()
                                                : item?.name?.toUpperCase() ===
                                                  values?.shippingprovince?.toUpperCase()
                                          }
                                       >
                                          {item.name}
                                       </option>
                                    </>
                                 ))}
                                 {touched.shippingprovince && errors.shippingprovince && (
                                    <p className="text-danger mb-0">province is required</p>
                                 )}
                              </Form.Select>
                           </Form.Group>
                           <div className="input-wrapper-billing">
                              <div>
                                 <Form.Group id="label-distance" controlId="validationFormik01">
                                    <Form.Label id="dashboard-label">District</Form.Label>
                                    <Form.Select
                                       id="input-flex"
                                       type="text"
                                       name="shippingdistrict"
                                       onChange={handleChange}
                                       isValid={
                                          touched.shippingdistrict && !errors.shippingdistrict
                                       }
                                    >
                                       <option
                                          value={null}
                                          selected={values?.shippingdistrict === "Select District"}
                                       >
                                          Select District
                                       </option>
                                       {districts.map((item) =>
                                          checked
                                             ? values?.province === item?.province && (
                                                  <option
                                                     value={item.name}
                                                     selected={
                                                        checked
                                                           ? item?.name?.toUpperCase() ===
                                                             values?.district?.toUpperCase()
                                                           : item?.name?.toUpperCase() ===
                                                             values?.shippingdistrict?.toUpperCase()
                                                     }
                                                  >
                                                     {item?.name}
                                                  </option>
                                               )
                                             : values.shippingprovince === item.province && (
                                                  <option
                                                     value={item.name}
                                                     selected={
                                                        checked
                                                           ? item?.name?.toUpperCase() ===
                                                             values?.district?.toUpperCase()
                                                           : item?.name?.toUpperCase() ===
                                                             values?.shippingdistrict?.toUpperCase()
                                                     }
                                                  >
                                                     {item?.name}
                                                  </option>
                                               ),
                                       )}
                                       {!checked &&
                                          touched.shippingdistrict &&
                                          errors.shippingdistrict && (
                                             <p className="text-danger mb-0">
                                                Shipping District is required
                                             </p>
                                          )}
                                    </Form.Select>
                                 </Form.Group>
                              </div>
                              <div>
                                 <Form.Group controlId="validationFormik01">
                                    <Form.Label id="dashboard-label">Municipality</Form.Label>
                                    <Form.Control
                                       id="input-flex"
                                       type="text"
                                       name="shippinglandmark"
                                       value={
                                          checked
                                             ? (values.shippinglandmark = values.landmark)
                                             : values.shippinglandmark
                                       }
                                       onChange={handleChange}
                                       isValid={
                                          touched.shippinglandmark && !errors.shippinglandmark
                                       }
                                    />
                                    {!checked &&
                                       touched.shippinglandmark &&
                                       errors.shippinglandmark && (
                                          <p className="text-danger mb-0">
                                             Shipping Municipality is required
                                          </p>
                                       )}
                                 </Form.Group>
                              </div>
                           </div>

                           <Form.Group id="label-distance" controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Street</Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 maxLength={350}
                                 type="text"
                                 name="shippingstreet"
                                 value={
                                    checked
                                       ? (values.shippingstreet = values.street)
                                       : values.shippingstreet
                                 }
                                 onChange={handleChange}
                                 isValid={
                                    touched.shippingstreet && !errors.shippingstreet
                                       ? "is-invalid"
                                       : null
                                 }
                              />
                              {!checked && touched.shippingstreet && errors.shippingstreet && (
                                 <p className="text-danger mb-0">{errors.shippingstreet}</p>
                              )}
                           </Form.Group>

                           <Form.Group id="label-distance" controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Landmark</Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 maxLength={350}
                                 name="shippingaddress"
                                 value={
                                    checked
                                       ? (values.shippingaddress = values.address)
                                       : values.shippingaddress
                                 }
                                 onChange={handleChange}
                                 isValid={
                                    touched.shippingaddress && !errors.shippingaddress
                                       ? "is-invalid"
                                       : null
                                 }
                              />
                              {!checked && touched.shippingaddress && errors.shippingaddress && (
                                 <p className="text-danger mb-0">{errors.shippingaddress}</p>
                              )}
                           </Form.Group>
                        </div>

                        <Button id="kycform-btn" type="submit">
                           Update KYC
                        </Button>
                     </Form>
                  )}
               </Formik>
            }
         </div>
      </div>
   );
}
