import React, { useState, useEffect, useRef } from "react";

import Layout from "../../../components/include/layout";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get_products_by_category, get_product_count, get_single_product } from "../../../data/api";

import "../../../scss/store/specials/productimage.scss";
import "../../../scss/store/specials/review.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import truck from "../../../assets/store/truck.png";
import message from "../../../assets/store/message.png";
import { Button, Col, Modal } from "react-bootstrap";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";
import Card from "react-bootstrap/Card";
import ReactStars from "react-rating-stars-component";
import success from "../../../assets/success.png";
import "../../../scss/components/messageModal.scss";
import // selectedProducts,

"../../../components/reducer/Shopping/shopping-action.js";

import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";

import Token from "../../../utils/useToken";
import axios from "axios";
import SeoTags from "../../../utils/seoTags";

import {
   get_wishlist_items,
   update_cart_items,
   update_wishlist_items,
} from "../../../data/protectedapi";
import LoginModal from "../../../components/pages/loginmodal";
import MessageModal from "../../../components/customcomponents/messageModal";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import Error from "../../../components/pages/404error";
import Loading from "../../../components/customcomponents/loading";
import BreadcrumbComponent, { BreadContainer } from "../../../components/include/breadcrumb";
import { ShareBtnPopup } from "../../../utils/shareBtnPopup";
import ImageMagnifier from "../../../utils/imageMagnify";

const ProductWrapper = () => {
   const parameter = useParams();

   const { id } = parameter;
   const { userDetails, token } = Token();
   const accessToken = token?.accessToken;
   // const dispatch=useDispatch()
   const [singleproductbrand, setsingleproductBrand] = useState("");
   const [singleproductcategories, setsingleproductCategories] = useState("");

   const [singleProduct, setSingleProduct] = useState("");
   const [productColor, setProductcolor] = useState("");
   const [loginModalState, setLoginModalState] = useState(false);
   const [bigimg, setBigimg] = useState("");
   const [cartitems, setcartItems] = useState("");
   const [quantityitems, setQuantityitems] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [wishState, setWishState] = useState(false);
   const [qty, setQty] = useState(1);
   const [selectedOption, setSelectedOption] = useState("description");
   const [loading, setLoading] = useState(false);
   const location = useLocation();
   // console.log("location", location.pathname);
   const config = {
      headers: {
         Authorization: `Bearer ${accessToken}`,
         "Content-Type": "application/x-www-form-urlencoded",
      },
   };
   const api = () => {
      const data = {
         baseURL: process.env.REACT_APP_BASE_URL + "v1/shop",
         headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
         },
      };
      return axios.create(data);
   };

   //view count
   useEffect(() => {
      async function get_count() {
         const res = await get_product_count(id);
         const response = res?.data?.data;
      }
      get_count();
   }, [id]);

   useEffect(() => {
      axios
         .get(process.env.REACT_APP_BASE_URL + "v1/shop/cart", config)
         .then((response) => {
            setcartItems(response?.data?.data?.cart_items);
         })
         .catch((error) => console.log(error));
   }, []);

   useEffect(() => {
      if (cartitems) {
         [...cartitems].filter((a) => {
            setQuantityitems(a?.name === singleProduct?.name);
         });
      }
   }, [quantityitems]);

   const handleDecrease = () => {
      if (qty > 1) {
         setQty(qty - 1);
      }
      if (singleProduct?.stock_quantity <= 0) {
         setError(true);
      }
   };
   //cart
   useEffect(() => {
      async function get_single() {
         const res = await get_single_product(id);
         const response = res?.data?.data;

         setSingleProduct(response);
         setsingleproductBrand(response?.brand);
         setsingleproductCategories(response?.categories);
         setProductcolor(response?.color);
         setThumbnail(response?.images);
         setBigimg(response?.featured_image);
      }
      get_single();
   }, [loading, id]);
   // console.log("single", singleProduct?.long_description);
   useEffect(() => {
      if (singleProduct === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [singleProduct]);

   const [thumbnail, setThumbnail] = useState("");
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [error, setError] = useState(false);
   //select color
   const [activeColor, setActiveColor] = useState("");
   // console.log("activecolor", activeColor);
   const handleColor = (colorname) => {
      setActiveColor(colorname);
   };
   const [cartError, setCartError] = useState(false);
   const [cartMessage, setCartMessage] = useState("");
   const handleCart = async (id, qty) => {
      if (singleProduct?.stock_quantity <= 0) {
         setError(true);
      } else {
         const response = await update_cart_items(id, qty, activeColor);
         if (response?.status === 200) {
            setCartupdate(!cartupdate);
            handleShow();
         } else {
            setCartError(true);
            setCartMessage(response?.response?.data?.message);
         }
      }
   };

   //increase cart item
   const increaseItem = () => {
      if (qty >= singleProduct.stock_quantity) {
         setQty(qty);
      }
      if (singleProduct?.stock_quantity <= 0) {
         setError(true);
      } else {
         setQty(qty + 1);
      }
   };
   //get wishlist
   const [wishlist, setWishlist] = useState("");
   //fetching wishlist product
   useEffect(() => {
      async function get_wishlist() {
         const response = await get_wishlist_items();
         setWishlist(response.data.data.wishlist);
      }
      accessToken && get_wishlist();
   }, []);

   //checking if the product is already in wishlist
   useEffect(() => {
      Object.entries(wishlist).map(([key, value]) => {
         if (singleProduct?.name === value?.productId.name) {
            return setWishState(true);
         }
      });
   }, [wishlist]);
   //adding to wishlist
   const handleWishlist = async (id, qty) => {
      if (singleProduct?.stock_quantity <= 0) {
         setError(true);
      } else {
         const response = await update_wishlist_items(id);
         setWishState(!wishState);
      }
   };
   const myContainer = useRef(null);
   const [isActive, setActive] = useState(false);
   const handleImage = (val, i) => {
      setActive(!isActive);
      setBigimg(val);
   };
   let navigate = useNavigate();

   return loading ? (
      <Loading />
   ) : singleProduct === undefined ? (
      <Error />
   ) : (
      <Layout
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
         wishState={wishState}
         setWishState={setWishState}
      >
         <MessageModal
            type={"error"}
            show={cartError}
            setShow={setCartError}
            message={cartMessage}
            buttonText={"Ok"}
            link="/login"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Failed"}
            message="Out of stock"
            buttonText={"Ok"}
            type={"error"}
         />
         <LoginModal Show={loginModalState} setShow={setLoginModalState} link={"/store/" + id} />
         <BreadContainer mainpath={"store"} pathname={singleProduct?.name} />
         {singleproductbrand !== undefined ? (
            <div>
               {SeoTags(
                  singleProduct?.name
                     ? `${singleProduct?.name} | DishHome Nepal`
                     : "DishHome Nepal",
                  singleProduct?.seo_title,
                  singleProduct?.seo_description,
                  singleProduct?.seo_keywords,
                  `${process.env.REACT_APP_FILE_URL}/${singleProduct?.featured_image}`,
                  ` ${process.env.REACT_APP_PUBLIC_URL}store/${id}`,
               )}
               <div
                  className="container fixed-width indvProduct_container"
                  style={{ display: "flex" }}
               >
                  <div className="row" style={{ flexBasis: "40%" }}>
                     <div
                        className="col-sm"
                        style={{ marginTop: "50px", display: "flex", justifyContent: "center" }}
                     >
                        <div>
                           <div className="original-image d-flex justify-content-center">
                              {singleProduct?.sale?.saleId !== null ? (
                                 <>
                                    <div id="sale-tag">
                                       <p>Sale offer</p>
                                    </div>
                                 </>
                              ) : (
                                 <></>
                              )}
                              {/* library for magnifying image */}
                              {/* <ReactImageMagnify
                                 {...{
                                    smallImage: {
                                       alt: "Wristwatch by Ted Baker London",
                                       src: `${process.env.REACT_APP_FILE_URL}/${bigimg}`,
                                       isFluidWidth: true,
                                       width: 100,
                                       height: 100,
                                    },
                                    largeImage: {
                                       alt: "",
                                       src: `${process.env.REACT_APP_FILE_URL}/${bigimg}`,
                                       width: 1200,
                                       height: 1800,
                                    },
                                    enlargedImageContainerStyle: {
                                       zIndex: "1500",
                                    },
                                    enlargedImageContainerDimensions: {
                                       width: "150%",
                                       height: "100%",
                                    },
                                 }}
                              /> */}
                              <ImageMagnifier
                                 src={`${process.env.REACT_APP_FILE_URL}/${bigimg}`}
                                 width="100%"
                                 height={"350px"}
                              />
                           </div>
                           <div id="thumbnail-image">
                              <Splide
                                 options={{
                                    type: "slide",
                                    hasTrack: false,
                                    perPage: 4,
                                    perMove: 1,
                                    pagination: false,
                                    autoHeight: true,
                                 }}
                              >
                                 <SplideSlide>
                                    <div
                                       className={
                                          bigimg === singleProduct?.featured_image
                                             ? "thumbnail-container-active"
                                             : "thumbnail-container"
                                       }
                                       ref={myContainer}
                                       onClick={(e) =>
                                          handleImage(singleProduct?.featured_image, 0)
                                       }
                                    >
                                       <img
                                          src={`${process.env.REACT_APP_FILE_URL}/${singleProduct?.featured_image}`}
                                          alt=""
                                       />
                                    </div>
                                 </SplideSlide>
                                 {Object.entries(thumbnail).map(([i, value]) => {
                                    return (
                                       <SplideSlide>
                                          <div
                                             className={
                                                bigimg === value
                                                   ? "thumbnail-container-active"
                                                   : "thumbnail-container"
                                             }
                                             ref={myContainer}
                                             onClick={(e) => handleImage(value, i + 1)}
                                          >
                                             <img
                                                src={`${process.env.REACT_APP_FILE_URL}/${value}`}
                                                alt=""
                                             />
                                          </div>
                                       </SplideSlide>
                                    );
                                 })}
                              </Splide>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm" style={{ marginTop: "50px" }}>
                     <p className="product_title">{singleProduct.name}</p>
                     <p className="product_option">
                        Availability:
                        {singleProduct.stock_quantity <= 0 ? (
                           <span id="not-availaibility-brand"> Not In stock</span>
                        ) : (
                           <span id="availaibility-brand"> In stock</span>
                        )}
                     </p>
                     {singleProduct?.brand && (
                        <p
                           className="product_option"
                           id={`${singleProduct?.color?.length > 0 ? "color-margin" : ""}`}
                        >
                           Brand:
                           <span
                              id="brand-span"
                              onClick={() => {
                                 navigate(
                                    "/store/category/" + "?type=" + singleProduct?.brand?._id,
                                 );
                              }}
                              style={{ cursor: "pointer", marginLeft: "30px" }}
                           >
                              <img
                                 width={"25px"}
                                 height="25px"
                                 src={`${process.env.REACT_APP_FILE_URL}/${singleproductbrand?.image}`}
                                 alt=""
                              />
                              {/* {singleproductbrand?.name} */}
                           </span>
                        </p>
                     )}

                     <p className="product_option d-flex align-items-center">
                        {singleProduct?.color?.length > 0 && (
                           <>
                              Colors:
                              {singleProduct.color ? (
                                 <div
                                    style={{
                                       display: "flex",
                                       gap: "5px",
                                       marginLeft: "6px",
                                    }}
                                 >
                                    {Object.entries(singleProduct.color).map(([key, value]) => {
                                       // console.log("singleProduct_color", singleProduct?.color[0]);
                                       return (
                                          <div
                                             id="color-filter"
                                             onClick={() => handleColor(value?.name)}
                                             className={
                                                activeColor.includes(value?.name) ||
                                                singleProduct?.color?.length === 1
                                                   ? "activeColor_category"
                                                   : ""
                                             }
                                             style={{
                                                backgroundColor: `${value.hex}`,
                                                marginTop: "8px",
                                                marginLeft: "18px",
                                             }}
                                          ></div>
                                       );
                                    })}
                                 </div>
                              ) : (
                                 "No colors"
                              )}
                           </>
                        )}
                     </p>
                     <p style={{ color: "chocolate" }}>
                        {singleProduct?.text_1 + " " + singleProduct?.text_2}
                     </p>
                     <p style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                        {singleProduct?.reviews?.length > 0 && (
                           <ReactStars
                              value={singleProduct?.rating}
                              count={5}
                              edit={false}
                              size={24}
                              activeColor="#ffd700"
                              color="#D0D0D0"
                           />
                        )}
                        {singleProduct?.reviews?.length > 0 && (
                           <p style={{ margin: "0px" }}>({singleProduct?.reviews?.length})</p>
                        )}
                     </p>
                     {singleProduct?.sale?.saleId !== null ? (
                        <>
                           <p
                              style={{
                                 textDecoration: "line-through",
                                 color: "#9e9e9e",
                                 fontSize: "18px",
                              }}
                              className="productimage_cost"
                           >
                              {" "}
                              Rs. {singleProduct?.price?.toLocaleString("hi-IN")}{" "}
                           </p>
                           <p className="productimage_cost">
                              {" "}
                              Rs. {singleProduct?.sale?.sale_price.toLocaleString("hi-IN")}{" "}
                           </p>
                        </>
                     ) : (
                        <>
                           <p className="productimage_cost">
                              {" "}
                              Rs. {singleProduct?.price?.toLocaleString("hi-IN")}{" "}
                           </p>
                        </>
                     )}
                     <hr />
                     <p className="productimage_description">{singleProduct.description}</p>
                     <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
                        <div>
                           <Button variant="secondary" className="number">
                              <div
                                 style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                 }}
                              >
                                 <div style={{ padding: "0 10px" }} onClick={handleDecrease}>
                                    -
                                 </div>
                                 <div>{qty}</div>

                                 <div
                                    style={{ padding: "0 10px" }}
                                    onClick={() => increaseItem(qty)}
                                 >
                                    +
                                 </div>
                              </div>
                           </Button>
                        </div>
                        <div>
                           <Button
                              className="add-cart m-0"
                              variant="secondary"
                              onClick={() => {
                                 if (userDetails === undefined) {
                                    setLoginModalState(true);
                                 } else {
                                    handleCart(id, qty);
                                 }
                              }}
                           >
                              Add to Cart
                           </Button>

                           <Modal
                              show={show}
                              onHide={() => setShow(false)}
                              dialogClassName={"MessageModal"}
                              centered
                           >
                              <Modal.Body>
                                 <div className="message_modal_wrapper">
                                    <img src={success} alt="" height={80} width={80} />
                                    <p className="add-to-cart-header">
                                       {" "}
                                       Product succesfully added to cart
                                    </p>
                                    <div id="modal-addcart-footer">
                                       <Button
                                          variant="secondary"
                                          id="addtocart-cart"
                                          className="red"
                                          onClick={() => navigate("/cart")}
                                       >
                                          Proceed to checkout
                                       </Button>
                                       <Button
                                          className="white"
                                          variant="secondary"
                                          id="continue-shopping"
                                          onClick={() => navigate("/store")}
                                       >
                                          Continue shopping
                                       </Button>
                                    </div>
                                 </div>
                              </Modal.Body>
                           </Modal>
                        </div>
                     </div>
                     <div
                        style={{
                           marginTop: "40px",
                           fontSize: "16px",
                           fontWeight: "400",
                           color: "#596579",
                           maxWidth: "fit-content",
                        }}
                     >
                        <div>
                           {(() => {
                              if (singleProduct?.stock_quantity <= 0) {
                                 return (
                                    <p style={{ marginBottom: "16px", color: "red" }}>
                                       No items remaining
                                    </p>
                                 );
                              }
                              if (singleProduct?.stock_quantity <= 10) {
                                 return (
                                    <p style={{ marginBottom: "16px", color: "red" }}>
                                       Only {singleProduct?.stock_quantity} items remaining
                                    </p>
                                 );
                              }
                              if (singleProduct?.stock_quantity >= 10) {
                                 return <p></p>;
                              }

                              return null;
                           })()}
                        </div>
                        <div style={{ display: "inline-flex", gap: "32px" }}>
                           {wishState ? (
                              <p
                                 onClick={() => {
                                    if (userDetails === undefined) {
                                       setLoginModalState(true);
                                    } else {
                                       handleWishlist(id);
                                    }
                                 }}
                                 className="cursor_pointer"
                                 style={{ display: "flex", alignItems: "center", margin: "0" }}
                              >
                                 {" "}
                                 <AiFillHeart id="wishlist-main" style={{ color: "red" }} /> Add to
                                 Wishlist
                              </p>
                           ) : (
                              <p
                                 onClick={() => {
                                    if (userDetails === undefined) {
                                       setLoginModalState(true);
                                    } else {
                                       handleWishlist(id, qty);
                                    }
                                 }}
                                 className="cursor_pointer"
                                 style={{ display: "flex", alignItems: "center", margin: "0" }}
                              >
                                 {" "}
                                 <AiOutlineHeart id="wishlist-main" /> Add to Wishlist{" "}
                              </p>
                           )}
                           <ShareBtnPopup text="Share" />
                        </div>
                     </div>
                     <hr style={{ margin: "20px 0px" }} />
                     <More />
                  </div>
               </div>
               <div className="container ProductDescriptionOptions fixed-width">
                  <p
                     className={
                        selectedOption === "description"
                           ? "ProductDescriptionOptions_title_active cursor_pointer"
                           : "ProductDescriptionOptions_title cursor_pointer"
                     }
                     onClick={() => setSelectedOption("description")}
                  >
                     Product Description
                  </p>
                  {singleProduct?.reviews?.length > 0 && (
                     <p
                        className={
                           selectedOption === "review"
                              ? "ProductDescriptionOptions_title_active cursor_pointer"
                              : "ProductDescriptionOptions_title cursor_pointer"
                        }
                        onClick={() => setSelectedOption("review")}
                     >
                        Reviews
                     </p>
                  )}
               </div>

               {selectedOption === "description" && (
                  <Description description={singleProduct?.long_description} />
               )}
               {selectedOption === "review" && <Review data={singleProduct.reviews} />}
               <ProductRelated id={singleproductcategories[0]?._id} />
            </div>
         ) : (
            <div style={{ textAlign: "center", fontSize: "24px", marginTop: "20px" }}>
               Description not available
            </div>
         )}
      </Layout>
   );
};

export default ProductWrapper;

export function ProductRelated({ id }) {
   const [indvcategory, setIndvcategory] = useState("");
   useEffect(() => {
      if (id) {
         async function get_category() {
            const res = await get_products_by_category(id);
            const response = res?.data?.data;
            setIndvcategory(response);
         }
         get_category();
      }
   }, [id]);
   // console.log("indv", indvcategory.length);
   let navigate = useNavigate();

   return (
      <div>
         {/* {indvcategory?.length > 1 ? (
            <p className="categories_title" style={{ marginTop: "40px" }}>
               Related Products{" "}
            </p>
         ) : (
            <p className="categories_title" style={{ marginTop: "40px" }}>
               Related Product{" "}
            </p>
         )}
         {indvcategory?.length > 1 ? (
            <p className="categories_description">You might like these products too.</p>
         ) : (
            <p className="categories_description">You might like this product too.</p>
         )} */}
         {indvcategory?.length > 1 ? (
            <>
               <p className="categories_title" style={{ marginTop: "40px" }}>
                  Related Products{" "}
               </p>
               <p className="categories_description">You might like these products too.</p>
            </>
         ) : (
            <></>
         )}

         <Widthcontainer>
            <div className="arrival_box fixed-width75 ">
               {indvcategory &&
                  Object.entries(indvcategory).map(([key, value]) => {
                     return (
                        <div
                           id="flex-item"
                           style={{ width: "100%", display: "flex", justifyContent: "center" }}
                        >
                           {indvcategory ? (
                              <>
                                 <Card
                                    className="arrival_container my-2 cursor_pointer "
                                    style={{
                                       minWidth: "180px",
                                       maxWidth: "200px",
                                       width: "200px",
                                       border: "1px solid #E6EAF0",
                                       borderRadius: "6px",
                                    }}
                                    onClick={() => {
                                       navigate("/store/" + value._id);
                                       window.location.reload();
                                    }}
                                 >
                                    {value.sale.saleId !== null ? (
                                       <div id="sale-tag">
                                          <p>Sale offer</p>
                                       </div>
                                    ) : (
                                       <></>
                                    )}
                                    <Card.Img
                                       className="arrival_img "
                                       variant="top"
                                       src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                                    />
                                    <Card.Body>
                                       <Card.Title>
                                          <div
                                             style={{
                                                display: "flex",
                                                justifyContent: "center",
                                             }}
                                          >
                                             <p id="products_image_Text_design">PRODUCTS</p>{" "}
                                          </div>
                                       </Card.Title>
                                       <Card.Text>
                                          <p className="arrival_title">{value.name}</p>
                                          {value.sale.saleId !== null ? (
                                             <>
                                                <p
                                                   className="arrival_cost"
                                                   style={{
                                                      textDecoration: "line-through",
                                                      lineHeight: "25px",
                                                   }}
                                                >
                                                   Rs. {value.price.toLocaleString("hi-IN")}
                                                </p>
                                                <p
                                                   className="arrival_cost"
                                                   style={{ lineHeight: "25px" }}
                                                >
                                                   Rs.{" "}
                                                   {value?.sale?.sale_price.toLocaleString("hi-IN")}
                                                </p>
                                             </>
                                          ) : (
                                             <>
                                                <p
                                                   className="arrival_cost"
                                                   style={{ lineHeight: "25px" }}
                                                >
                                                   {" "}
                                                   Rs. {value.price.toLocaleString("hi-IN")}{" "}
                                                </p>
                                             </>
                                          )}
                                          <div
                                             style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: "7px",
                                                marginBottom: "5px",
                                             }}
                                          >
                                             <p
                                                style={{
                                                   display: "flex",
                                                   gap: "4px",
                                                   alignItems: "center",
                                                }}
                                             >
                                                {value?.reviews?.length > 0 && (
                                                   <ReactStars
                                                      value={value?.rating}
                                                      count={5}
                                                      edit={false}
                                                      size={24}
                                                      activeColor="#ffd700"
                                                      color="#D0D0D0"
                                                   />
                                                )}
                                             </p>
                                          </div>
                                       </Card.Text>
                                    </Card.Body>
                                 </Card>
                              </>
                           ) : (
                              <>
                                 <p>No recent items</p>
                              </>
                           )}
                        </div>
                     );
                  })}
            </div>
         </Widthcontainer>
      </div>
   );
}

export function ProductImage(props) {
   return (
      <div>
         <div className="container">
            <div className="row">
               <div className="col-sm">
                  <div className="image_container">
                     <img height="500px" src={props.img} alt="product"></img>
                  </div>
               </div>
               <div className="col-sm">
                  <p className="product_title">{props.title}</p>

                  <p className="product_option" style={{ cursor: "pointer" }}>
                     Brand:{props.singleproductbrand}
                  </p>
                  <p className="product_option">Colors:</p>
                  <p className="productimage_cost">Rs. {props.price.toLocaleString("hi-IN")} </p>
                  <hr />
                  <p className="productimage_description">{props.description}</p>
                  <div className="row">
                     <div className="col-sm">
                        <Button className="number">- 1 +</Button>
                     </div>
                     <div className="col-sm">
                        <Button className="add-cart">Add to Cart</Button>
                     </div>
                     <Col></Col>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export function More() {
   return (
      <div>
         <div className="col-sm">
            <div className="row">
               <div className="col-sm">
                  <p className="more_description">
                     <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <div>
                           <img src={truck} alt="" />
                        </div>
                        <div>
                           <span>
                              {" "}
                              Get order delivery all over nepal,
                              <br /> or pick up available items at DishHome Store
                           </span>
                        </div>
                     </div>
                  </p>
               </div>
               <div className="col-sm">
                  <p className="more_description">
                     <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                        <div>
                           <img src={message} alt="" />
                        </div>
                        <div>
                           <span>
                              {" "}
                              Have questions about buying
                              <br /> an item?{" "}
                              <a
                                 href="http://m.me/dishhome"
                                 target={"_blank"}
                                 rel="noreferrer"
                                 className="cursor_pointer"
                                 style={{ color: "#1A63F4", textDecoration: "none" }}
                              >
                                 {" "}
                                 Chat with DishHome <br /> Specialist
                              </a>
                           </span>
                        </div>
                     </div>
                  </p>
               </div>
               <hr style={{ margin: "20px 0px" }}></hr>
            </div>
         </div>
      </div>
   );
}
export function Description(props) {
   return (
      <div className="container fixed-width" style={{ marginTop: "10px" }}>
         <div
            dangerouslySetInnerHTML={{ __html: props.description }}
            className="jobdescription"
         ></div>
      </div>
   );
}

//review section
export function Review(data) {
   const [allreviewData, setAllReviewData] = useState(data?.data);
   const [pageCount, setPageCount] = useState("");
   const [reviewData, setReviewData] = useState([]);
   const [selectedPage, setSelectedPage] = useState(0);

   const categoryContainer = useRef(null);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 200);
   };

   useEffect(() => {
      setPageCount(Math.ceil(allreviewData?.length / 6));
      setAllReviewData(data?.data?.reverse());
      const offset = selectedPage * 6;

      setReviewData(allreviewData?.slice(offset, offset + 6));
   }, [data?.data, selectedPage, allreviewData]);

   const handlePageClick = (e) => {
      setSelectedPage(e.selected);
      const offset = selectedPage * 6;
      const newArray = allreviewData.slice(offset, offset + 6);
      setReviewData(newArray);
      scrollDown(categoryContainer);
   };

   return (
      <div className="container fixed-width" style={{ marginTop: "10px" }} ref={categoryContainer}>
         {reviewData?.map((item) => {
            return (
               <div className="userReview row ">
                  <p className="user_name">
                     Reviewed By {item?.username} on{" "}
                     {format(new Date(item?.createdAt), "dd MMM,yyyy")}
                  </p>
                  <ReactStars
                     value={item?.rating}
                     count={5}
                     edit={false}
                     size={24}
                     activeColor="#ffd700"
                     color="#D0D0D0"
                  />
                  <p className="review_data">{item?.comment}</p>
               </div>
            );
         })}
         {allreviewData?.length === 0 ? <p>No reviews</p> : null}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
      </div>
   );
}
