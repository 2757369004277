import React, { useEffect, useState } from "react";
import DhInfoBanner from "../../components/customcomponents/dhinfobanner";
import Faq from "../../components/customcomponents/faq";
import Layout from "../../components/include/layout";
import CurrentMovies from "./currentmovies";
// import MovieBanner from "./moviebanner";
import MovieBannerSlide from "./moviebannerslide";
// import MovieInfo from "./movieinfo";
import NextMovies from "./nextmovies";
import { get_ppv_banner } from "../../data/api";
import Loading from "../../components/customcomponents/loading";
import SeoTags from "../../utils/seoTags";

const Main = () => {
   const [ppvData, setPpvData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [faqData, setFaqData] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);

   const [seoData, setSeoData] = useState("");
   useEffect(() => {
      async function fetchData() {
         const response = await get_ppv_banner();
         setPpvData(response.data);
      }
      fetchData();
   }, [empty]);

   useEffect(() => {
      setBannerData(ppvData?.banners);
      setFaqData(ppvData?.faq);
      setSeoData(ppvData?.seo);
      if (ppvData?.faq === undefined || ppvData?.seo === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });
   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "ppv") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}ppv-movies`,
               );
            } else {
               return null;
            }
         })}
         {/* <MovieBanner /> */}
         <MovieBannerSlide data={bannerData} />
         {/* <MovieInfo /> */}
         <CurrentMovies heading="Now Showing" />
         <NextMovies />
         <DhInfoBanner page={"PPV"} />
         <Faq data={faqData} type="ppv" />
      </Layout>
   );
};

export default Main;
