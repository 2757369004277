import { React, useState, useEffect } from "react";
import SlideBanner from "../customcomponents/slidebanner";
import AppCard from "../customcomponents/appcard";
import Banner from "../customcomponents/banner";
import CartoonBanner from "../customcomponents/cartoonbanner";
import InfoBanner from "../customcomponents/infobanner";
import DiscountBanner from "../customcomponents/discountbanner";
import Channels from "../customcomponents/channels";
import Layout from "../include/layout";
// import adbanner from "../../assets/adbanner.png";
// import infobanner from "../../assets/infobanner.png";
// import AppBanner from "../customcomponents/appbanner";
// import QuickLink from "../customcomponents/quicklink";
// import Payment from "../customcomponents/payment";
import { get_home_components } from "../../data/api";
import Loading from "../customcomponents/loading";
import WelcomeModal from "../../modal/welcomeModal";
import SeoTags from "../../utils/seoTags";

const Home = () => {
   //UseState for home pge
   const [homeData, setHomeData] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [tvPackage, setTvPackage] = useState("");
   const [internetPackage, setInternetPackage] = useState("");
   const [featurePrduct, setFeaturePrduct] = useState("");
   const [channels, setChannels] = useState("");
   const [empty, setEmpty] = useState(true);
   const [tvBannedData, setTvBannedData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [wishState, setWishState] = useState(false);
   const [seoData, setSeoData] = useState("");
   let slideBanner = [];
   let internetBanner;
   let midBanner;
   let cartoonBanner;
   let discountBanner;
   //use Effect to fetch home component
   useEffect(() => {
      async function fetchData() {
         const response = await get_home_components();
         setHomeData(response.data);
      }
      fetchData();
   }, [empty]);
   //updating the individual conpent data
   useEffect(() => {
      setBannerData(homeData?.banners);
      setTvPackage(homeData?.tvPackages);
      setInternetPackage(homeData?.internetPackage);
      setFeaturePrduct(homeData?.product);
      setChannels(homeData?.channels);
      setTvBannedData(homeData?.channelBanner);
      setSeoData(homeData?.seo);
      if (homeData?.seo === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   return empty ? (
      <Loading />
   ) : (
      <Layout
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
         wishState={wishState}
         setWishState={setWishState}
      >
         {/* Seo Data For Home Page */}
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "home") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}`,
               );
            } else {
               return null;
            }
         })}
         {/* Setting banner data of individual banners */}
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === "home") {
               slideBanner.push(value);
            } else if (value.page_location === "home_int") {
               internetBanner = value;
            } else if (value.page_location === "home_mid") {
               midBanner = value;
            } else if (value.page_location === "home_second") {
               cartoonBanner = value;
            } else if (value.page_location === "home_offer") {
               discountBanner = value;
            }
            return null;
         })}
         {/* {console?.log(featurePrduct)} */}
         {/* Welcome Modal which is seen first time opening website */}
         <WelcomeModal />
         {/* Top Bnner of Home Page */}
         <SlideBanner banners={slideBanner} />
         {/* Imfo Cards of home page and package section */}
         <AppCard tvData={tvPackage} internetData={internetPackage} />
         {/* TV Banner Section with different secton like Entertainemt Sports Entertainment*/}
         {tvBannedData && <Banner banner={midBanner} data={tvBannedData} />}
         {/* TV Like Banner with Dishhome 4k streamer */}
         {cartoonBanner && <CartoonBanner data={cartoonBanner} />}
         {/* info banner that shows internet details  */}
         {internetBanner && <InfoBanner data={internetBanner} />}
         {/* Exclusive Product Banner */}
         {discountBanner && featurePrduct && (
            <DiscountBanner bannerData={discountBanner} productData={featurePrduct} />
         )}
         {/* Channels Section of Home page */}
         <Channels data={channels} />
      </Layout>
   );
};
export default Home;
