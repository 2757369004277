import { format } from "date-fns";
import React from "react";
import noImage from "../assets/dashboard/noImage.png";
import moment from "moment-timezone";

function DHGOActivePackage({ activePackage, addons }) {
   // console.log(activePackage, addons);

   return (
      <div className="d-flex flex-column dishhomeGo_history">
         {activePackage?.length > 0 && (
            <>
               <h4 className="mb-2">Packages</h4>
               <div className="d-flex package_Container mb-4">
                  {activePackage?.map((data) => {
                     return (
                        <MovieCard name={data?.name} time={data?.expiryDate} image={data?.image} />
                     );
                  })}
               </div>
            </>
         )}
         {addons?.length > 0 && (
            <>
               <h4 className="mb-2">AlaCarte</h4>
               <div className="d-flex package_Container">
                  {addons?.map((data) => {
                     return (
                        <MovieCard name={data?.name} time={data?.expiryDate} image={data?.image} />
                     );
                  })}
               </div>
            </>
         )}
      </div>
   );
}

export default DHGOActivePackage;

function MovieCard({ name, time, image }) {
   return (
      <div className="dashboard-ppv__movieCard">
         <img src={image ? `${process.env.REACT_APP_FILE_URL}/${image}` : noImage} alt="" />
         <div className="dashboard-ppv__movieCard-info">
            <h3 className="dashboard-ppv__movieCard-info-title">{name}</h3>
            <h2 className="showtime cursor_normal">
               {format(new Date(time), "yyyy-MM-dd hh:mm a")}
            </h2>
         </div>
      </div>
   );
}
