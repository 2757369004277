import React, { useRef, useState, useEffect } from "react";
import Layout from "../include/layout";
import Widthcontainer from "../customcomponents/widthcontainer";
import "../../scss/pages/enterprise.scss";
import DhInfoBanner from "../customcomponents/dhinfobanner";
import { get_enterprise_banner, internet_requests } from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import SeoTags from "../../utils/seoTags";
import { Button, Form, Modal } from "react-bootstrap";
import { enterprise_data } from "../../data/api";
import Loading from "../customcomponents/loading";
import EnterpriseBanner from "../banner/enterprisebanner";
import { Formik } from "formik";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import Captcha from "../include/captcha";

export default function Enterprise() {
   const formSection = useRef(null);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 150);
   };
   const [show, setShow] = useState(false);
   const [showDescription, setShowDescription] = useState(false);

   const [messageModal, setMessageModal] = useState(false);
   const [enterpriseData, setEnterpriseData] = useState("");
   const [loading, setLoading] = useState(true);
   const [banner, setBanner] = useState("");
   const [seoData, setSeoData] = useState("");
   const [activeDescription, setActiveDescription] = useState("");
   const [activeTitle, setActiveTitle] = useState("");
   const [activeImg, setActiveImg] = useState("");

   useEffect(() => {
      //get interprise data
      const getEnterpriseData = async () => {
         const response = await enterprise_data();
         setEnterpriseData(response?.data?.data);
      };
      getEnterpriseData();
   }, [loading]);

   useEffect(() => {
      async function fetchData() {
         const response = await get_enterprise_banner();
         setBanner(response?.data?.banners);
         setSeoData(response?.data?.seo);
      }
      fetchData();
   }, [loading]);

   useEffect(() => {
      if (enterpriseData === "" || enterpriseData === undefined || banner === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });
   const isMobile = useMediaQuery({ query: "(max-width:600px)" });

   return loading ? (
      <Loading />
   ) : (
      <Layout>
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "enterprise") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}internet${value?.page}`,
               );
            } else {
               return null;
            }
         })}
         {Object.entries(banner).map(([key, value]) => {
            if (value.page_location === "enterprise_top") {
               return (
                  <EnterpriseBanner
                     data={value}
                     setShow={setShow}
                     muted={false}
                     setShowDescription={setShowDescription}
                  />
               );
            } else return null;
         })}

         <div style={{ height: "120px" }}></div>
         {Object.entries(enterpriseData?.info).map(([key, value]) => {
            // console.log("value_title", value);
            return (
               <>
                  {value?.description.length !== 0 && value?.status === true && (
                     <Widthcontainer className={"enterprise_section_container"}>
                        <EnterpriseSection
                           img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                           rowreverse={key % 2 === 0 ? false : true}
                           title={value.title}
                           description={value?.description}
                        />
                     </Widthcontainer>
                  )}
               </>
            );
         })}

         <h1 className="enterpriseHeading">
            #Grow Your <span> Business </span> Smarter.
         </h1>
         {Object.entries(enterpriseData?.grow_your_business).map(([key, value]) => {
            // console.log("value", value);
            return (
               <Widthcontainer className={"enterprise_section_container"}>
                  <EnterpriseSection2
                     rowreverse={key % 2 === 0 ? false : true}
                     title={value.title}
                     desc={value.description}
                     featured_img={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                     img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                     setShow={setShow}
                     setShowDescription={setShowDescription}
                     setActiveTitle={setActiveTitle}
                     setActiveImg={setActiveImg}
                     setActiveDescription={setActiveDescription}
                  />
               </Widthcontainer>
            );
         })}

         <DhInfoBanner page={"Enterprise"} />
         <Widthcontainer className={"enterprise_section_container margin_top_bottom"}>
            <Modal
               show={show}
               onHide={() => {
                  setShow(false);
               }}
               dialogClassName="enterprise_section_container"
               centered
            >
               <Modal.Body
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     borderRadius: "12px",
                     paddingTop: "20px",
                     paddingBottom: "20px",
                  }}
               >
                  <EnterpriseForm
                     section={formSection}
                     setModaldisplay={setMessageModal}
                     setShow={setShow}
                  />
               </Modal.Body>
            </Modal>
            {/* LEARN MORE MODAL */}
            <Modal
               size={"lg"}
               centered
               show={showDescription}
               onHide={() => {
                  setShowDescription(false);
               }}
            >
               <Modal.Body style={isMobile ? { padding: "40px 25px" } : { padding: "50px" }}>
                  <div style={{ position: "absolute", right: "22px", top: "20px" }}>
                     <AiFillCloseCircle
                        size={22}
                        onClick={() => setShowDescription(false)}
                        style={{ cursor: "pointer" }}
                     />
                  </div>
                  <p className="title_learnmore">{activeTitle}</p>
                  <div style={{ marginBottom: "20px" }}>
                     <img style={{ width: "100%", height: "300px" }} src={activeImg} />
                  </div>
                  <p className="description_learnmore">{activeDescription}</p>
                  <div className="button_wrapper_modal">
                     <Button
                        variant="secondary"
                        className="enterprise_button_learnmore_cancel"
                        type="button"
                        onClick={() => setShowDescription(false)}
                     >
                        Cancel
                     </Button>
                     <button
                        className="enterprise_button_learnmore"
                        onClick={() => {
                           setShowDescription(false);
                           setShow(true);
                        }}
                     >
                        Get a Quote
                     </button>
                  </div>
               </Modal.Body>
            </Modal>
            <MessageModal
               show={messageModal}
               setShow={setMessageModal}
               heading="Thank You"
               message="Our Representative will get back to you soon"
               isNavigate={false}
               buttonText="OK"
               type={"success"}
            />
         </Widthcontainer>
      </Layout>
   );
}

export const EnterpriseSection = ({ rowreverse, title, img, description }) => {
   return (
      <>
         <div
            className={
               rowreverse
                  ? "enterprise_section fixed-width75 row-reverse"
                  : "enterprise_section fixed-width75"
            }
         >
            <div className="enterprise_img">
               <img src={img} alt="top" />
            </div>

            <div className="enterprise_text_section">
               <h1 className="heading">{title}</h1>
               {Object.entries(description).map(([key, value]) => {
                  return (
                     <p className="bullet_point">
                        {" "}
                        <span>{value.title}</span> : {value.description}
                     </p>
                  );
               })}
            </div>
         </div>
      </>
   );
};

export const EnterpriseSection2 = ({
   rowreverse,
   title,
   featured_img,
   img,
   desc,
   setShow,
   setShowDescription,
   setActiveDescription,
   setActiveTitle,
   setActiveImg,
}) => {
   return (
      <div
         className={
            rowreverse
               ? "enterprise_section fixed-width row-reverse padding-top-bottom"
               : "enterprise_section fixed-width padding-top-bottom"
         }
      >
         <div className="enterprise_img">
            <img src={img} alt="top" />
         </div>
         <div className="enterprise_text_section">
            <h1 className="heading">{title}</h1>
            <p className="description">
               {desc}
               <br />
            </p>
            <div className="buttonGroup">
               <button className="enterprise_button" onClick={() => setShow(true)}>
                  Get a Quote
               </button>
               <button
                  className="enterprise_button__learnMore"
                  onClick={() => {
                     setActiveTitle(title);
                     setActiveImg(featured_img);
                     setActiveDescription(desc);
                     setShowDescription(true);
                  }}
               >
                  Learn More
               </button>
            </div>
         </div>
      </div>
   );
};

export const EnterpriseForm = ({ section, setModaldisplay, setShow }) => {
   const [btnState, setBtnState] = useState(false);
   const reRef = useRef();

   const handleSubmit = async (values) => {
      setBtnState(true);
      // e.preventDefault();
      let phone = values?.phone;
      if (values?.phone?.length > 10) {
         if (values?.phone?.charAt(0) === "0") {
            phone = phone.substring(1);
         } else if (values?.phone?.slice(0, 4) === "+977") {
            phone = phone?.slice(4, 18);
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      // console.log("Token", captchaToken);
      const response = await internet_requests(
         "Enterprise",
         values?.companyName,
         values?.contactName,
         values?.email,
         phone?.toString(),
         values?.requirement,
         values?.location,
         values?.remarks,
         captchaToken,
      );
      if (response?.status === 201) {
         setShow(false);
         setModaldisplay(true);
         setBtnState(false);
      } else {
         alert(response?.response?.data?.message);
         setBtnState(false);
      }
   };

   const schema = yup.object().shape({
      companyName: yup
         .string()
         .required("*Required")
         .min(2, "Company Name must be at least 2 characters")
         .max(100),
      contactName: yup
         .string()
         .required("*Required")
         .min(2, "Contact Name must be at least 2 characters")
         .max(50),
      email: yup.string().email("Email is invalid").required("Email is required").min(6).max(60),
      phone: yup
         .string()
         .min(10, "Please Enter Valid Phone Number")
         .max(14, "Please Enter Valid Phone Number")
         .required("Phone number is required"),
      requirement: yup
         .string()
         .required("Requirement is required")
         .min(5, "Requirement must me atleast 5 characters ")
         .max(350),
      location: yup
         .string()
         .required("Location is required")
         .min(5, "Location must me atleast 5 characters long")
         .max(350),
      remarks: yup
         .string()
         .required("Remarks is required")
         .min(5, "Remarks must me atleast 5 characters ")
         .max(350),
   });

   return (
      <div className="formContainer " ref={section}>
         <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
               companyName: "",
               contactName: "",
               email: "",
               phone: "",
               requirement: "",
               location: "",
               remarks: "",
            }}
         >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
               <Form className="formWrapper ps-4" onSubmit={handleSubmit}>
                  <div className="enterprise_text pb-3">
                     <h1>
                        Contact Us For <span> Enterprise </span>Inquiry
                     </h1>
                  </div>
                  <div className="inputGroup">
                     <div className="inputContainer">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                           type="text"
                           name="companyName"
                           minLength="2"
                           maxLength={"80"}
                           className={errors?.companyName ? "Name_Input error" : "Name_Input"}
                           value={values?.companyName}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    handleChange(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleChange(e);
                                 }
                              } else {
                                 handleChange(e);
                              }
                           }}
                           placeholder=""
                        />
                        {errors?.companyName ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.companyName}
                           </p>
                        ) : null}
                     </div>
                     <div className="inputContainer">
                        <label htmlFor="contactName">Contact Name</label>
                        <input
                           type="text"
                           name="contactName"
                           minLength="2"
                           maxLength={"50"}
                           className={errors?.contactName ? "Name_Input error" : "Name_Input"}
                           value={values?.contactName}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    handleChange(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleChange(e);
                                 }
                              } else {
                                 handleChange(e);
                              }
                           }}
                           placeholder=""
                        />
                        {errors?.contactName ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.contactName}
                           </p>
                        ) : null}
                     </div>
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="email">Email</label>
                     <input
                        type="email"
                        name="email"
                        minLength="6"
                        maxLength={"60"}
                        className={errors?.email ? "Email_Input error" : "Email_Input"}
                        value={values?.email}
                        onChange={handleChange}
                        placeholder=""
                     />
                     {errors?.email ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.email}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="phone">Phone</label>
                     <input
                        type="tel"
                        name="phone"
                        maxLength={14}
                        className={errors?.phone ? "Phone_Input error" : "Phone_Input"}
                        value={values?.phone}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                     />
                     {errors?.phone ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.phone}
                        </p>
                     ) : null}
                  </div>

                  <div className="inputContainer">
                     <label htmlFor="location">Location</label>
                     <input
                        type="text"
                        name="location"
                        className={errors?.location ? "Location_Input error" : "Location_Input"}
                        value={values?.location}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9A-Za-z, -]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        minLength="5"
                        maxLength={"350"}
                        placeholder=""
                     />
                     {errors?.location ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.location}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer mb-2">
                     <label htmlFor="requirement">Requirement</label>
                     <textarea
                        type="textarea"
                        name="requirement"
                        className={
                           errors?.requirement ? "Requirement_Input error" : "Requirement_Input"
                        }
                        value={values?.requirement}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9A-Za-z, \b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        placeholder=""
                        minLength="5"
                        maxLength={"350"}
                     />
                     {errors?.requirement ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.requirement}
                        </p>
                     ) : null}
                  </div>
                  <div className="inputContainer">
                     <label htmlFor="remark">Remark</label>
                     <textarea
                        type="textarea"
                        name="remarks"
                        className={errors?.remarks ? "Remark_Input error" : "Remark_Input"}
                        value={values?.remarks}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9A-Za-z, \b]")) {
                                 handleChange(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleChange(e);
                              }
                           } else {
                              handleChange(e);
                           }
                        }}
                        minLength="5"
                        maxLength={"350"}
                        placeholder=""
                     />
                     {errors?.remarks ? (
                        <p className="error_message" style={{ color: "#ed1c24" }}>
                           {errors?.remarks}
                        </p>
                     ) : null}
                  </div>
                  <div className="button_wrapper_modal">
                     <Button
                        variant="secondary"
                        className={"cancel_enterprise "}
                        type="button"
                        onClick={() => setShow(false)}
                        disabled={btnState}
                     >
                        Cancel
                     </Button>
                     <button
                        className={btnState ? "enterprise_button loading" : "enterprise_button "}
                        type="submit"
                        disabled={btnState}
                     >
                        {btnState ? "Loading ..." : "Submit"}
                     </button>
                  </div>
                  <Captcha reRef={reRef} />
               </Form>
            )}
         </Formik>
      </div>
   );
};
