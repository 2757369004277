import React from "react";
import "../../scss/components/infobanner.scss";
import woman from "../../assets/woman.png";
import packagebutton from "../../assets/packagebutton.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";

// import Widthcontainer from "./widthcontainer";

const ComponentToTrack = (props) => {
   let navigate = useNavigate();
   return (
      <div className="infoBanner__player-wrapper">
         <ReactPlayer
            url={`${process.env.REACT_APP_FILE_URL}/${props.data.media}`}
            playing={props?.isVisible}
            loop={true}
            width="100%"
            height="100%"
            className="react-player"
            muted={true}
            style={{
               position: "absolute",
               top: "0",
               right: "0",
               zIndex: "1",
               pointerEvents: "none",
               objectFit: "cover",
            }}
            config={{
               youtube: {
                  playerVars: {
                     autoplay: 1,
                     rel: 0,
                     controls: 0,
                     showinfo: 0,
                     disablekb: 1,
                     modestbranding: 1,
                  },
               },
            }}
            playsinline
         />
      </div>
   );
};

const InfoBanner = ({ data }) => {
   // console.log("internet_mid", data);
   //React Hook for routing
   let navigate = useNavigate();

   return (
      <div className="info_banner ">
         <div className="infobanner_container fixed-width ">
            <div className="infobanner_text">
               <p className="infobanner_heading">
                  {/* High Speed Internet <br /> for Web Browsing */}
                  {data.caption}
               </p>
               <p className="infobanner_para">
                  {/* Watch on Smart TVs, Playstation, Xbox,
                        <br /> Chromecast, HD TV, Blu-ray players, and more. */}
                  {data.sub_caption}
               </p>
               <a className="packages_link" href={data.external_link}>
                  {data.button_type}&nbsp;&nbsp;
                  <AiOutlineArrowRight />
               </a>
            </div>
            <div className="infobanner_image">
               {data.media_type === "video" ? (
                  <TrackVisibility>
                     <ComponentToTrack data={data} />
                  </TrackVisibility>
               ) : (
                  <img
                     src={`${process.env.REACT_APP_FILE_URL}/${data.media}`}
                     height="450px"
                     alt="woman"
                  />
               )}
            </div>
         </div>
      </div>
   );
};
export default InfoBanner;
