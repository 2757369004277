import { React, useState, useEffect } from "react";
import "../../scss/ppvmovies/movieinfo.scss";
import { format } from "date-fns";
import { AiFillStar, AiOutlineClose, AiOutlineClockCircle } from "react-icons/ai";
import LoginModal from "../../components/pages/loginmodal";
import Token from "../../utils/useToken";
import { useNavigate } from "react-router";
import CurrentMovies from "./currentmovies";
import { Offcanvas } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import MessageModal from "../../components/customcomponents/messageModal";
import { check_balance } from "../../data/protectedapi";

const MovieInfo = ({ data }) => {
   //usestate
   const [showTimeOpen, setShowTimeOpen] = useState(false);
   const [Show, setShow] = useState(false);
   const ShowData = data?.showtimes[0]?.shows;
   //variables
   const [date, setDate] = useState([]);
   const { token, userDetails } = Token();
   const [isDTHUser, setIsDTHUser] = useState(false);

   // console.log(date, "date");
   const [activeDate, setActiveDate] = useState(null);
   // console.log(activeDate, "showdata");
   const [selectedMovie, setSelectedMovie] = useState("");
   const [activeTime, setActiveTime] = useState();
   const [readMore, setReadMore] = useState(false);

   useEffect(() => {
      Object.entries(ShowData).map(([key, value]) => {
         if (!date?.includes(format(new Date(key), "MMMM dd"))) {
            date.push(format(new Date(key), "MMMM dd"));
            date.sort();
         }
         // console.log(date, "date");
      });
      setActiveDate(date[0]);
   }, [data]);

   //react router variables
   const navigate = useNavigate();

   //Functions
   const handleOrder = async () => {
      if (token === null) {
         setShow(true);
      } else {
         navigate(`/ppv-movies/order/${data?._id}?showtimeId=${selectedMovie?.Id}`);
      }
   };

   //    console.log("data", format(new Date(Object.keys(data?.showtimes[0]?.shows)[0]), "MMMM dd"));
   //    console.log(
   //       "data",
   //       format(new Date(Object.keys(data?.showtimes[0]?.shows).sort()[0]), "MMMM dd"),
   //    );

   const [mobileshow, setmobileShow] = useState(false);

   const handleClose = () => setmobileShow(false);
   const handleShow = () => setmobileShow(true);

   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

   useEffect(() => {
      async function getData() {}
      userDetails && getData();
   }, [userDetails]);
   return (
      <>
         <LoginModal Show={Show} setShow={setShow} link={`/ppv-movies/${data?._id}`} />
         <div className="movieinfo_container py-5">
            <div className="movie_container fixed-width">
               <div className="movie_img">
                  <img src={`${process.env.REACT_APP_FILE_URL}/${data.thumbnail}`} alt="" />
               </div>
               <div className="movie_info">
                  <h2 className="title pt-0">{data.title}</h2>
                  <div className="movierating_wrapper">
                     <p className="moviedefination me-4">{data?.ppv_type}</p>
                     <p className="movierating me-4">
                        {" "}
                        <AiFillStar />
                        &nbsp;{data.rating}
                     </p>
                     <p className="movieduration me-4">
                        {" "}
                        <AiOutlineClockCircle /> &nbsp;{data?.duration} min
                     </p>
                  </div>
                  <div className="moviegerna_wrapper mt-2 mb-4">
                     {data.genre.map((genre) => {
                        return <button className="gernabutton me-3">{genre}</button>;
                     })}
                  </div>
                  <div className="movie_info_text pb-4">{data.description}</div>
                  <div className="showtime_box">
                     {date.map((value, key) => {
                        return key < 3 ? (
                           <button
                              className={
                                 activeDate === value ? "showtime_date active" : "showtime_date"
                              }
                              onClick={() => {
                                 setActiveDate(value);
                                 setActiveTime(null);
                              }}
                           >
                              {key === 0 ? "Today" : null}
                              {key === 1 ? "Tommorow" : null}
                              {key === 2 ? "Day After" : null}
                              <br />
                              {value}
                           </button>
                        ) : null;
                     })}
                  </div>
                  <div className="time_container">
                     {activeDate === null ? null : (
                        <>
                           {Object.entries(ShowData).map(([key, value]) => {
                              const format_date = format(new Date(key), "MMMM dd");
                              if (activeDate === format_date) {
                                 return (
                                    <>
                                       {Object.entries(value).map(([key, value]) => {
                                          // console.log(
                                          //     "data",
                                          //     value,
                                          // );
                                          let expired = false;
                                          const now = new Date();
                                          const showtime = new Date(value.EventStartDate);
                                          const ExpireTime = new Date(value.SellToDate);
                                          let offset = 60000 * 345;
                                          let newtime = showtime?.getTime();
                                          let newExpireTime = ExpireTime?.getTime();
                                          let time = newtime + offset;
                                          let Expires = newExpireTime + offset;
                                          let currentTime = new Date(Expires);
                                          if (currentTime < now) {
                                             expired = true;
                                          }

                                          return (
                                             <div
                                                className={
                                                   activeTime === key
                                                      ? "showtime active"
                                                      : expired
                                                      ? "showtime expired"
                                                      : "showtime inactive"
                                                }
                                                onClick={() => {
                                                   setSelectedMovie(value);
                                                   !expired && setShowTimeOpen(true);
                                                   !expired && setActiveTime(key);
                                                   !expired && setmobileShow(true);
                                                }}
                                             >
                                                {format(new Date(time), "hh:mm a")}
                                             </div>
                                          );
                                       })}
                                    </>
                                 );
                              } else {
                                 return null;
                              }
                           })}
                        </>
                     )}
                  </div>
               </div>
            </div>
            <div className="dishhomecommand_text fixed-width pt-4">
               <h2 className="title mb-3">DishHome Command</h2>
               <p className="text">
                  {readMore ? data.longDescription : data.longDescription.substring(0, 250)}{" "}
                  <span className="cursor_pointer" onClick={() => setReadMore(!readMore)}>
                     {" "}
                     {readMore ? "Show less" : "Read more"}
                  </span>
               </p>
            </div>
            {isMobile ? (
               <Offcanvas
                  show={mobileshow}
                  onHide={handleClose}
                  style={{
                     width: "400px",
                     background: "rgba(15, 24, 37, 0.5)",
                     boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                     backdropFilter: "blur(14px)",
                     transition: "transform 1s",
                  }}
                  placement="end"
               >
                  <Offcanvas.Body>
                     <ShowtimeContainerMobile
                        showTimeOpen={mobileshow}
                        setShowTimeOpen={setmobileShow}
                        selectedMovie={selectedMovie}
                        handleOrder={handleOrder}
                        isDTHUser={isDTHUser}
                     />
                  </Offcanvas.Body>
               </Offcanvas>
            ) : null}

            <ShowtimeContainer
               showTimeOpen={showTimeOpen}
               setShowTimeOpen={setShowTimeOpen}
               selectedMovie={selectedMovie}
               handleOrder={handleOrder}
               isDTHUser={isDTHUser}
            />
         </div>
      </>
   );
};

export default MovieInfo;

export const ShowtimeContainer = ({
   showTimeOpen,
   setShowTimeOpen,
   selectedMovie,
   handleOrder,
   isDTHUser,
}) => {
   const [kycModal, setKycModal] = useState(false);
   const [modal, setModal] = useState(false);
   const [loginModal, setLoginModal] = useState(false);
   const { userDetails } = Token();
   const [isLoading, setisLoading] = useState(false);

   let showtime = new Date(selectedMovie?.EventStartDate);
   let offset = 60000 * 345;
   let newtime = showtime?.getTime();
   let time = newtime + offset;

   let sellFromDate = new Date(selectedMovie?.SellFromDate);
   let newsellfromtime = sellFromDate?.getTime();
   let sellfromtime = newsellfromtime + offset;

   let sellToDate = new Date(selectedMovie?.SellToDate);
   let newselltotime = sellToDate?.getTime();
   let selltotime = newselltotime + offset;

   let ShowStartTime = new Date(selectedMovie?.EventStartDate);
   let newShowStartTime = ShowStartTime?.getTime();
   let showstarttime = newShowStartTime + offset;

   let ShowEndTime = new Date(selectedMovie?.EventStartEndDate);
   let newShowEndTime = ShowEndTime?.getTime();
   let showendtime = newShowEndTime + offset;

   const checkDTHuser = async () => {
      setisLoading(true);
      const data = await check_balance();
      const packageData = data?.data?.data?.packages || [];
      console.log(data);
      const isDTHUser = packageData.some((d) => d.type?.includes("DTH"));
      if (!isDTHUser) {
         setModal(true);
      } else {
         handleOrder();
      }
      setisLoading(false);
   };

   return (
      <div className={showTimeOpen ? "showtime_container" : "showtime_container container_hide"}>
         <MessageModal
            type="error"
            show={kycModal}
            setShow={setKycModal}
            message="Please complete your information to book a ticket"
            buttonText="Complete Profile"
            link="/dashboard?type=1"
         />
         <MessageModal
            type="error"
            show={modal}
            setShow={setModal}
            message="You are an internet only customer. Please subscribe to DishHome TV to watch PPV movies."
            buttonText="OK"
            link="/dashboard?type=1"
         />
         <button className="close" onClick={() => setShowTimeOpen(false)}>
            <AiOutlineClose />
         </button>
         <div className="showtime_content">
            <div className="showtime_content_time">
               <div className="_title">Time :</div>
               <div className="_content">
                  {selectedMovie === "" ? null : format(new Date(time), "hh:mm a")}
               </div>
            </div>
            <div className="showtime_content_time">
               <div className="_title">Price :</div>
               <div className="_content">
                  Rs.&nbsp;
                  {selectedMovie === "" ? null : selectedMovie?.PriceAmount.toLocaleString("hi-IN")}
               </div>
            </div>
            <div className="showtime_content_time">
               <div className="_title">Movie Channel :</div>
               <div className="_content">
                  {selectedMovie === "" ? null : selectedMovie?.PPVDisplayTitle}
               </div>
            </div>
         </div>
         <div className="movie_info_date ">
            <p className="movie_booking_text">Booking</p>
            <p className="booking_date">
               Starts <br />
               <span>
                  {" "}
                  {selectedMovie === ""
                     ? null
                     : format(new Date(sellfromtime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
            <p className="booking_date">
               Expires
               <br />
               <span>
                  {" "}
                  {selectedMovie === "" ? null : format(new Date(selltotime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
         </div>
         {/* <div className="movie_info_date ">
            <p className="movie_booking_text">Show</p>
            <p className="booking_date">
               Starts <br />
               <span>
                  {" "}
                  {selectedMovie === ""
                     ? null
                     : format(new Date(showstarttime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
            <p className="booking_date">
               Expires
               <br />
               <span>
                  {" "}
                  {selectedMovie === "" ? null : format(new Date(showendtime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
         </div> */}
         <div
            className="movie_button me-3"
            style={isLoading ? { backgroundColor: "grey" } : {}}
            onClick={() =>
               !isLoading && !userDetails
                  ? handleOrder()
                  : userDetails?.is_kyc_verified
                  ? checkDTHuser()
                  : setKycModal(true)
            }
         >
            Book Now &nbsp; <span>▶</span>
         </div>
         <div
            className="movie_button me-3"
            style={{ background: "grey" }}
            onClick={() => setShowTimeOpen(false)}
         >
            Back
         </div>
         {/* <h2 className="showtime_title">
                        <AiOutlineClockCircle /> &nbsp;&nbsp; Show Time
                    </h2>

                    <div className="channelname_date_container mt-5">
                        <p className="channel_name pe-5">CINEMAGHAR 999</p>
                        <button className="time_button" onClick={handleShow}>
                            01:00 PM
                        </button>
                        <button className="time_button">01:00 PM</button>
                    </div> */}
      </div>
   );
};

export const ShowtimeContainerMobile = ({
   showTimeOpen,
   setShowTimeOpen,
   selectedMovie,
   handleOrder,
   isDTHUser,
}) => {
   const [kycModal, setKycModal] = useState(false);
   const [modal, setModal] = useState(false);
   const { userDetails } = Token();
   let showtime = new Date(selectedMovie?.EventStartDate);
   let offset = 60000 * 345;
   let newtime = showtime?.getTime();
   let time = newtime + offset;

   let sellFromDate = new Date(selectedMovie?.SellFromDate);
   let newsellfromtime = sellFromDate?.getTime();
   let sellfromtime = newsellfromtime + offset;

   let sellToDate = new Date(selectedMovie?.SellToDate);
   let newselltotime = sellToDate?.getTime();
   let selltotime = newselltotime + offset;

   return (
      <div className="showtime_container ms-2 mt-5">
         <MessageModal
            type="error"
            show={kycModal}
            setShow={setKycModal}
            message="Please complete your information to book a ticket"
            buttonText="Complete Profile"
            link="/dashboard?type=1"
         />
         <MessageModal
            type="error"
            show={modal}
            setShow={setModal}
            message="You are an internet only customer. Please subscribe to DishHome TV to watch PPV movies."
            buttonText="OK"
            link="/dashboard?type=1"
         />
         <button
            className="close"
            onClick={() => setShowTimeOpen(false)}
            style={{
               position: "absolute",
               top: "10px",
               right: "8px",
               background: "transparent",
               border: "none",
               color: "#fff",
               fontSize: "20px",
            }}
         >
            <AiOutlineClose />
         </button>

         <div
            className="showtime_content"
            style={{
               display: "flex",
               flexDirection: "column",
               gap: "10px",
               width: "100%",
            }}
         >
            <div className="showtime_content_time">
               <div className="_title">Time :</div>
               <div className="_content">
                  {selectedMovie === "" ? null : format(new Date(time), "hh:mm a")}
               </div>
            </div>
            <div className="showtime_content_time">
               <div className="_title">Price :</div>
               <div className="_content">
                  Rs. &nbsp;
                  {selectedMovie === "" ? null : selectedMovie?.PriceAmount.toLocaleString("hi-IN")}
               </div>
            </div>
            <div className="showtime_content_time">
               <div className="_title">Movie Channel :</div>
               <div className="_content">
                  {selectedMovie === "" ? null : selectedMovie?.PPVDisplayTitle}
               </div>
            </div>
         </div>
         <div className="movie_info_date ">
            <p className="movie_booking_text">Booking</p>
            <p className="booking_date">
               Starts
               <br />
               <span>
                  {" "}
                  {selectedMovie === ""
                     ? null
                     : format(new Date(sellfromtime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
            <p className="booking_date">
               Expires
               <br />
               <span>
                  {" "}
                  {selectedMovie === "" ? null : format(new Date(selltotime), "MM/dd/yyyy h:mm a")}
               </span>
            </p>
         </div>
         <div
            className="movie_button me-3"
            onClick={() =>
               userDetails?.is_kyc_verified
                  ? isDTHUser
                     ? handleOrder()
                     : setModal(true)
                  : setKycModal(true)
            }
         >
            Book Now &nbsp; <span>▶</span>
         </div>
         {/* <h2 className="showtime_title">
                        <AiOutlineClockCircle /> &nbsp;&nbsp; Show Time
                    </h2>

                    <div className="channelname_date_container mt-5">
                        <p className="channel_name pe-5">CINEMAGHAR 999</p>
                        <button className="time_button" onClick={handleShow}>
                            01:00 PM
                        </button>
                        <button className="time_button">01:00 PM</button>
                    </div> */}
      </div>
   );
};
