import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
   key: "root",
   storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const composedEnhancer = compose(sayHiOnDispatch, includeMeaningOfLife)

// const store = createStore(persistedReducer, composeWithDevTools());
const store = createStore(persistedReducer);
let persistor = persistStore(store);

export { persistor };
export default store;
