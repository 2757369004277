import React, { useState, useMemo, useEffect } from "react";
import ProductOrders from "./productOrder";
import "../scss/components/order.scss";
import PackageOrder from "./packageOrder";
import { useLocation, useNavigate } from "react-router";
import { get_company_details } from "../data/api";

const DashboardOrderHistory = () => {
   const [companyDetails, setCompanyDetails] = useState("");
   const [empty, setEmpty] = useState(true);
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let option = query.get("option");
   let navigate = useNavigate();

   const [activeCategory, setActiveCategory] = useState(option ? option : "product");

   useEffect(() => {
      async function get_details() {
         const res = await get_company_details();
         setCompanyDetails(res?.data?.data);
      }
      get_details();
   }, [empty]);

   useEffect(() => {
      if (companyDetails === "" || companyDetails === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [companyDetails]);
   return (
      <div className="dashboardOrderHistory__Wrapper">
         <h1 className="ms-2 header">Orders</h1>
         <p className="ms-2 subheading">Find all of your orders in one place</p>
         {companyDetails && companyDetails?.disable_store === false && (
            <div className="ms-2 mt-3 mb-3 order_options">
               <div
                  className={
                     activeCategory === "product"
                        ? "order_options__item active"
                        : "order_options__item"
                  }
                  onClick={() => {
                     navigate("/dashboard?type=9&option=product");
                     setActiveCategory("product");
                  }}
               >
                  Product Orders
               </div>
               <div
                  className={
                     activeCategory === "package"
                        ? "order_options__item active"
                        : "order_options__item"
                  }
                  onClick={() => {
                     navigate("/dashboard?type=9&option=package");
                     setActiveCategory("package");
                  }}
               >
                  Package Orders
               </div>
            </div>
         )}
         {companyDetails && companyDetails?.disable_store === true ? (
            <PackageOrder />
         ) : activeCategory === "product" ? (
            <ProductOrders />
         ) : (
            <PackageOrder />
         )}
      </div>
   );
};

export default DashboardOrderHistory;
