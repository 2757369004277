import React, { useEffect, useRef, useMemo } from "react";
import "../../scss/pages/form.scss";
import "../../scss/pages/signup.scss";
import Login_img from "../../assets/login.png";
import { useState } from "react";
import { register } from "../../data/api";
import { Formik } from "formik";
import * as yup from "yup";

import Layout from "../include/layout";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import { Alert, Form } from "react-bootstrap";
import { MdError } from "react-icons/md";
import { useNavigate, useLocation } from "react-router";
import Token from "../../utils/useToken";
import SeoTags from "../../utils/seoTags";
import Widthcontainer from "../customcomponents/widthcontainer";
import ReactGA from "react-ga4";
import Captcha from "../include/captcha";

const SignUp = () => {
   //UseState for Error and eye Icon
   const [showPassword, setshowPassword] = useState(false);
   const [showConfPassword, setshowConfPassword] = useState(false);
   const [ErrorMessage, setErrorMessage] = useState("");
   const [ErrorStatus, setErrorStatus] = useState(false);
   const [PasswordError, setPasswordError] = useState("");
   //For checking wether user is logged n or not
   const { token } = Token();
   const reRef = useRef();
   //Query Hook
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let source = query.get("source");

   //Function of react router for routing
   let navigate = useNavigate();
   //Functions for password show button toggle
   const togglePassword = () => {
      setshowPassword(!showPassword);
   };
   const toggleConfPassword = () => {
      setshowConfPassword(!showConfPassword);
   };
   //Checking wether rhe user is already logged in and redirecting to home page
   useEffect(() => {
      if (token) {
         navigate("/");
      }
   }, []);

   //For Registering the user
   const handleSubmit = async (values) => {
      const Name = values?.fname + " " + values?.lname;
      let phone = values?.phone;
      if (values?.phone?.length > 10) {
         if (values?.phone?.charAt(0) === "0") {
            phone = phone.substring(1);
         } else if (values?.phone?.slice(0, 4) === "+977") {
            phone = phone?.slice(4, 18);
         } else {
            setErrorMessage("Please Enter Valid Phone Number");
            setErrorStatus(true);
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      // console.log("Token", captchaToken);
      const response = await register(
         Name,
         phone?.toString(),
         values?.password,
         values?.confpassword,
         values?.email,
         captchaToken,
      );
      if (response.status === 200) {
         // ReactGA.event({
         //    category: "User",
         //    action: "USer Registered",
         //    label: "User register in tracking", // optional
         //    nonInteraction: false, // optional, true/false
         //    transport: "xhr",
         // });
         if (source === "dishhomego") {
            //save password in local storage
            localStorage.setItem("_go", values?.password);
            navigate(`/otp-verify/${phone}?source=dishhomego`);
         } else navigate("/otp-verify/" + phone);
      } else {
         setErrorStatus(true);
         setErrorMessage(response.response.data.message);
      }
   };
   //Validation Schema for variables
   const schema = yup.object().shape({
      fname: yup
         .string()
         .required("First Name is required")
         .min(2, "First name must be 2 characters long")
         .max(50, "First name mustn't exceed  50 characters ")
         .matches(/^([a-zA-Z0-9 _-]+)$/, "Special characters not allowed"),
      lname: yup
         .string()
         .required("Last Name is required")
         .min(2, "Last Name must be 2 characters long")
         .max(50, "Last name mustn't exceed  50 characters ")
         .matches(/^([a-zA-Z0-9 _-]+)$/, "Special characters not allowed"),
      email: yup.string().required("Email is required").email("Invalid Email").min(6).max(60),
      phone: yup
         .string()
         .required("Phone Number is required")
         .min(10, "Please Enter Valid Phone Number")
         .max(14, "Please Enter Valid Phone Number"),
      password: yup
         .string()
         .required("New Password is required")
         .matches(
            /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
            "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character and no whitespace",
         ),
      confpassword: yup
         .string()
         .required("Confirm Password is required")
         .oneOf([yup.ref("password")], "Passwords do not match"),
   });
   return (
      <Layout>
         {/* Seo Data */}
         {SeoTags(
            "Register | DishHome nepal",
            "DishHome Account Registration",
            "Register to DishHome",
            "Register to DishHome",
            "Register to DishHome",
            `${process.env.REACT_APP_PUBLIC_URL}login`,
         )}
         <Captcha reRef={reRef} />

         <Widthcontainer>
            <div className={"Signup_Wrapper fixed-width my-2"}>
               <Formik
                  validationSchema={schema}
                  initialValues={{
                     fname: "",
                     lname: "",
                     email: "",
                     phone: "",
                     password: "",
                     confpassword: "",
                  }}
                  onSubmit={(values, actions) => {
                     setTimeout(() => {
                        actions.setSubmitting(false);
                        handleSubmit(values);
                        actions.resetForm();
                     });
                  }}
               >
                  {({ handleSubmit, handleChange, values, touched, errors }) => (
                     <Form
                        className={"Signup_Form"}
                        noValidate
                        onSubmit={handleSubmit}
                        autoComplete="false"
                     >
                        {/* <Logo /> */}

                        <div className={"Signup_Container"}>
                           <h1 className={"Login_Header"}>Sign up to continue</h1>
                           <h3 className={"Register_Header"}>
                              Already have an account? <a href="/login">Login</a>
                           </h3>
                           <div className={"Name_Container"}>
                              <div className={"Name"}>
                                 <label htmlFor="fname">First Name</label>
                                 <input
                                    aria-autocomplete="none"
                                    required
                                    type="text"
                                    name="fname"
                                    className={
                                       errors?.fname && touched.fname
                                          ? "password_input error"
                                          : "Password_input "
                                    }
                                    placeholder=""
                                    onChange={handleChange}
                                    value={values.fname}
                                    minLength="2"
                                    maxLength={"50"}
                                    isValid={touched.fname && !errors.fname}
                                 />
                                 {errors?.fname && touched.fname ? (
                                    <p className="error_message" style={{ color: "#ed1c24" }}>
                                       {errors?.fname}
                                    </p>
                                 ) : null}
                              </div>
                              <div className={"Name"}>
                                 <label htmlFor="lname">Last Name</label>
                                 <input
                                    required
                                    type="text"
                                    name="lname"
                                    minLength="2"
                                    maxLength={"50"}
                                    className={
                                       errors?.lname && touched.lname
                                          ? "password_input error"
                                          : "Password_input "
                                    }
                                    placeholder=""
                                    onChange={handleChange}
                                    value={values?.lname}
                                 />
                                 {errors?.lname && touched.lname ? (
                                    <p className="error_message" style={{ color: "#ed1c24" }}>
                                       {errors?.lname}
                                    </p>
                                 ) : null}
                              </div>
                           </div>{" "}
                           <label htmlFor="email">Email Address</label>
                           <input
                              type="email"
                              name="email"
                              minLength="6"
                              maxLength={"60"}
                              className={
                                 errors?.email && touched.email
                                    ? "password_input error"
                                    : "Password_input "
                              }
                              placeholder=""
                              onChange={handleChange}
                              value={values?.email}
                           />{" "}
                           {errors?.email && touched.email ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.email}
                              </p>
                           ) : null}
                           <label htmlFor="phone">Phone Number</label>
                           <input
                              type="tel"
                              name="phone"
                              className={
                                 errors?.phone && touched.phone
                                    ? "password_input error"
                                    : "Password_input "
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => {
                                 if (e?.nativeEvent?.data) {
                                    if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                       handleChange(e);
                                    }
                                    if (e?.nativeEvent?.data === null) {
                                       handleChange(e);
                                    }
                                 } else {
                                    handleChange(e);
                                 }
                              }}
                              value={values?.phone}
                           />{" "}
                           {(touched.phone && errors?.phone) ||
                           (touched.phone && values.phone !== "") ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.phone}
                              </p>
                           ) : null}
                           <div className={"Password_Container"}>
                              <label htmlFor="password">Password</label>
                              <input
                                 type={showPassword ? "text" : "password"}
                                 name="password"
                                 autoComplete="off"
                                 className={
                                    errors?.password && touched.password
                                       ? "password_input error"
                                       : "Password_input "
                                 }
                                 placeholder=""
                                 onChange={handleChange}
                                 value={values?.password}
                                 onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                 }}
                              />{" "}
                              {!showPassword && (
                                 <AiOutlineEyeInvisible
                                    size={19}
                                    className={"Pw_Icon"}
                                    onClick={togglePassword}
                                    style={{}}
                                 />
                              )}
                              {showPassword && (
                                 <span
                                    className={"Pw_Icon"}
                                    onClick={togglePassword}
                                    style={{ top: "30px" }}
                                 >
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                          fill="#374253"
                                       />
                                    </svg>{" "}
                                 </span>
                              )}
                              {/* <span className={"Pw_Icon"} onClick={togglePassword}>
                                 <i class="fa-regular fa-eye"></i>{" "}
                              </span> */}
                              {(touched.password && errors?.password) || values.password !== "" ? (
                                 <p className="error_message" style={{ color: "#ed1c24" }}>
                                    {errors?.password}
                                 </p>
                              ) : null}
                           </div>
                           <div className={"Password_Container"}>
                              <label htmlFor="confirm_pw">Confirm Password</label>
                              <input
                                 type={showConfPassword ? "text" : "password"}
                                 name="confpassword"
                                 autoComplete="off"
                                 className={
                                    errors?.confpassword && touched.confpassword
                                       ? "password_input error"
                                       : "Password_input "
                                 }
                                 placeholder=""
                                 onChange={handleChange}
                                 value={values?.confpassword}
                                 onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                 }}
                              />{" "}
                              {!showConfPassword && (
                                 <AiOutlineEyeInvisible
                                    size={19}
                                    className={"Pw_Icon"}
                                    onClick={toggleConfPassword}
                                    style={{}}
                                 />
                              )}
                              {showConfPassword && (
                                 <span
                                    className={"Pw_Icon"}
                                    onClick={toggleConfPassword}
                                    style={{ top: "30px" }}
                                 >
                                    <svg
                                       width="16"
                                       height="16"
                                       viewBox="0 0 16 16"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                    >
                                       <path
                                          d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                          fill="#374253"
                                       />
                                    </svg>{" "}
                                 </span>
                              )}
                              {/* <span className={"Pw_Icon"} onClick={toggleConfPassword}>
                                 <i class="fa-regular fa-eye"></i>{" "}
                              </span> */}
                              {(touched.confpassword && errors?.confpassword) ||
                              values.confpassword !== "" ? (
                                 <p className="error_message" style={{ color: "#ed1c24" }}>
                                    {errors?.confpassword}
                                 </p>
                              ) : null}
                              {/* {console.log(
                                 "Error",
                                 errors?.confpassword || values.confpassword !== "",
                              )} */}
                           </div>
                           <Alert
                              variant="danger"
                              className={"Signup_Alert" + (ErrorStatus ? "_show" : "")}
                           >
                              <MdError />
                              &nbsp;&nbsp;
                              {PasswordError === "" ? ErrorMessage : PasswordError}
                           </Alert>
                           <button className={"Signin_Button"} type="submit">
                              Register
                           </button>
                           <h3 className={"Register_Header"}>
                              By clicking register, you agree to our
                              <a href="/pages/termsandcondition" target="_blank">
                                 {" "}
                                 Terms &amp; Condition
                              </a>{" "}
                              and{" "}
                              <a href="/privacy-policy" target="_blank">
                                 Privacy Policy
                              </a>
                              .
                           </h3>
                        </div>
                     </Form>
                  )}
               </Formik>
               <div className={"Signup_Image"}>
                  <img src={Login_img} alt="" />
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};
export default SignUp;
