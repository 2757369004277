import React, { useState, useEffect } from "react";
import "../../scss/packages/feature.scss";
import Image from "../../assets/package/rectangle.png";
import { get_why_dishhome } from "../../data/api";

const Feature = () => {
   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_banner() {
         const res = await get_why_dishhome();
         const response = res.data.data;
         setBannerData(response);
      }
      get_banner();
   }, []);
   return (
      <>
         {Object.entries(bannerdata).map(([key, value]) => {
            // console.log("value", value);
            return (
               <>
                  {value.page === "Tv" ? (
                     <>
                        <div className="feature_wrapper">
                           <div className="feature_header my-5 fixed-width">
                              <h1>{value.title}</h1>
                           </div>
                           <div className="feature_container mb-3 fixed-width">
                              <div className="feature_image p-3">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="Feature"
                                 />
                              </div>
                              <div className="feature_text px-5 py-5">
                                 {Object.entries(value.whyDishhome).map(([key, item]) => {
                                    return (
                                       <div className="text_section">
                                          <h1>{item.title}</h1>
                                          <p>{item.description}</p>
                                       </div>
                                    );
                                 })}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : (
                     <></>
                  )}
               </>
            );
         })}
      </>
   );
};

export default Feature;
