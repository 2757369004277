import React, { useState, useEffect, useRef } from "react";
import { Modal, Alert, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import BannerImg from "../assets/ott_banner.png";
import tick from "../assets/success.png";
import { MdError } from "react-icons/md";

import { MdErrorOutline } from "react-icons/md";
import { Formik } from "formik";
import * as yup from "yup";
import Token from "../utils/useToken";
import MessageModal from "../components/customcomponents/messageModal";
import { change_ott_password, get_ott, renew_ott, update_profile } from "../data/protectedapi";
import "../scss/ott/ottBanner.scss";
import ReactPlayer from "react-player";
import LoginModal from "../components/pages/loginmodal";
import Loading from "../components/customcomponents/loading";
import { AiOutlineClose, AiOutlineEyeInvisible } from "react-icons/ai";
import TrackVisibility from "react-on-screen";
import Captcha from "../components/include/captcha";
import goLogo from "../assets/gologo.webp";

import googleplay from "../assets/googleplay.webp";
import apple from "../assets/appstore.webp";
import { get_footer_banner, get_dishhome_go_settings } from "../data/api";

const OttBanner = ({ data, muted }) => {
   const [show, setShow] = useState(false);
   const [showpassword, setpasswordShow] = useState(false);
   const [customerId, setCustomerId] = useState("");
   const [password, setPassword] = useState("");
   const [error, setError] = useState(false);
   const [message, setMessage] = useState("");
   const [ottInfo, setOttinfo] = useState("");
   const [ErrorMessage, setErrorMessage] = useState("Validation error");
   const [ErrorStatus, setErrorStatus] = useState(false);
   const [btnState, setBtnState] = useState(false);
   const [loginModalState, setLoginModalState] = useState(false);
   const [getloading, setgetLoading] = useState(false);
   const [renewloading, setrenewLoading] = useState(false);
   const [passwordloading, setpasswordLoading] = useState(false);
   const [showPassword, setshowPassword] = useState(false);
   const [showrequest, setShowrequest] = useState(false);
   const [showConfPassword, setShowConfPassword] = useState(false);
   const [settings, setSettings] = useState({});
   const reRef = useRef();

   const togglePassword = () => {
      setshowPassword(!showPassword);
   };

   const toggleConfirmPassword = () => {
      setShowConfPassword(!showConfPassword);
   };

   //get user details
   const { setToken, token } = Token();
   const UserDetails = token?.user;
   let navigate = useNavigate();
   const handleShow = () => setShow(false);
   const [ottmodal, setOttmodal] = useState(false);
   const modalclose = () => {
      setOttmodal(false);
   };
   const handleOttModal = async (values) => {
      // setBtnState(true);
      setgetLoading(true);
      const res = await get_ott(UserDetails?.phone, values?.password);
      console.log(res?.response);
      if (res?.status == 200) {
         setShowrequest(false);
         setgetLoading(false);
         setBtnState(false);
         setOttinfo(res?.data);
         setOttmodal(true);
         setMessage(res?.data?.Remarks);
      } else {
         setgetLoading(false);
         setError(true);
         setMessage(res?.response?.data?.responseDescription);
      }
   };
   const handleOttRenew = async () => {
      // setBtnState(true);
      setrenewLoading(true);
      const res = await renew_ott(UserDetails?.phone);
      if (res?.data.Status === true) {
         setBtnState(false);
         setOttinfo(res.data);
         setShow(true);
         setMessage("OTT Renwed");
         setrenewLoading(false);
      } else {
         setrenewLoading(false);
         setBtnState(false);
         setError(true);
         setMessage(
            res?.response?.data?.message ? res?.response?.data?.message : "OTT Renew Failed",
         );
      }
   };
   const handleOttpassword = () => {
      setpasswordShow(true);
   };
   const [passworderror, setPassworderror] = useState(false);
   const handleSubmit = async (values) => {
      setpasswordLoading(true);
      const captchaToken = await reRef?.current?.executeAsync();
      const res = await change_ott_password(UserDetails?.phone, values.password, captchaToken);
      if (res?.status == 200) {
         setShow(true);
         setpasswordLoading(false);
         setpasswordShow(false);
         setMessage("Password changed Successfully!");
      } else {
         setpasswordLoading(false);
         setError(true);
         setpasswordShow(false);
         setMessage(res?.response?.data?.responseDescription);
      }

      setCustomerId("");
      setPassword("");
   };
   const schema = yup.object().shape({
      customerId: yup.string().required(),
      password: yup
         .string()
         .required("Password is required")
         .matches(
            /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
            "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character and no whitespace",
         ),
      confirmpassword: yup
         .string()
         .required("Confirm Password is required")
         .oneOf([yup.ref("password")], "Passwords do match"),
   });
   //user Profile
   const [profile, setProfile] = useState("");

   useEffect(() => {
      async function getdata() {
         const response = await update_profile();
         setProfile(response?.data);
      }
      UserDetails && getdata();
   }, []);

   const ComponentToTrack = (props) => {
      let navigate = useNavigate();
      return (
         <div
            className="infoBanner__player-wrapper"
            style={{
               height: "25px",
            }}
         >
            <ReactPlayer
               url={`${process.env.REACT_APP_FILE_URL}/${props.data.media}`}
               playing={props?.isVisible}
               loop={true}
               width="100%"
               height="100%"
               className="react-player"
               muted={props?.data?.mute}
               style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: "1",
                  pointerEvents: "none",
                  objectFit: "cover",
               }}
               config={{
                  youtube: {
                     playerVars: {
                        autoplay: 1,
                        rel: 0,
                        controls: 0,
                        showinfo: 0,
                        disablekb: 1,
                        modestbranding: 1,
                     },
                  },
               }}
               playsinline
            />
         </div>
      );
   };
   const [unverifiedUser, setUnverifiedUser] = useState(false);

   const [isEmpty, setisEmpty] = useState(true);
   const [footerData, setfooterData] = useState("");
   const [ottlink, setOttlink] = useState("");

   useEffect(() => {
      async function getSettings() {
         const dhgoSettings = await get_dishhome_go_settings();
         setSettings(dhgoSettings?.data.data);
         console.log("settings", dhgoSettings?.data.data);
      }
      getSettings();
   }, []);

   useEffect(() => {
      async function getData() {
         const response = await get_footer_banner();
         setfooterData(response?.data);
      }
      getData();
   }, [isEmpty]);

   useEffect(() => {
      if (footerData === "" || footerData === undefined) {
         setisEmpty(true);
      } else {
         setisEmpty(false);
         setOttlink(footerData?.ott_link);
      }
   }, [footerData]);

   return (
      <div>
         <Modal
            show={showpassword}
            onHide={() => {
               setpasswordShow(false);
            }}
         >
            <Formik
               validationSchema={schema}
               initialValues={{
                  customerId: UserDetails?.phone,
                  password: "",
                  confirmpassword: "",
               }}
               onSubmit={(values, actions) => {
                  handleSubmit(values);
               }}
            >
               {({ handleSubmit, handleChange, values, touched, errors, handleReset }) => (
                  <Modal.Body className="px-4 py-5">
                     {/* <div
                        className="close_btn cursor_pointer"
                        style={{ position: "absolute", top: "10px", right: "12px" }}
                        onClick={() => setpasswordShow(false)}
                     >
                        <AiOutlineClose />
                     </div> */}
                     <div className={"Login_Wrapper mt-2"}>
                        <div className={"Login_Modal"}>
                           <div>
                              <h1 className={"Login_Header"}>Change password</h1>
                              <h3 className={"Register_Header"}>Change your OTT password now</h3>
                              {/* {passworderror ? (
                                 <Alert variant="danger" style={{ width: "370px" }}>
                                    <MdError />
                                    &nbsp;&nbsp; Password doesn't match
                                 </Alert>
                              ) : (
                                 ""
                              )} */}
                              <Form
                                 onSubmit={(values, actions) => {
                                    handleSubmit(values);
                                 }}
                              >
                                 <Form.Label id="dashboard-label">Phone Number</Form.Label>
                                 <Form.Control
                                    disabled
                                    rows={3}
                                    type="text"
                                    name="customerId"
                                    className="mb-3"
                                    value={values.customerId}
                                    onChange={handleChange}
                                 />

                                 {touched.customerId && errors.customerId && (
                                    <p className="text-danger mb-0">Customer id is required</p>
                                 )}
                                 <div className={"Password_Container"}>
                                    <Form.Label id="dashboard-label">Enter new password</Form.Label>

                                    <input
                                       type={showPassword ? "text" : "password"}
                                       name="password"
                                       autoComplete="off"
                                       className={"Password_Input"}
                                       style={
                                          touched.password && errors?.password
                                             ? { border: "1px solid red", paddingRight: "34px" }
                                             : { marginBottom: "12px", paddingRight: "34px" }
                                       }
                                       value={values.password}
                                       onChange={handleChange}
                                       onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                       }}
                                    />
                                    {!showPassword && (
                                       <AiOutlineEyeInvisible
                                          size={19}
                                          className={"Pw_Icon"}
                                          onClick={togglePassword}
                                          style={{ top: "38px" }}
                                       />
                                    )}
                                    {showPassword && (
                                       <span
                                          className={"Pw_Icon"}
                                          style={{ top: "32px" }}
                                          onClick={togglePassword}
                                       >
                                          <svg
                                             width="16"
                                             height="16"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                          >
                                             <path
                                                d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                fill="#374253"
                                             />
                                          </svg>{" "}
                                       </span>
                                    )}

                                    {touched.password && errors.password && (
                                       <p className="text-danger mb-3">{errors.password}</p>
                                    )}
                                 </div>
                                 <div className={"Password_Container"}>
                                    <Form.Label id="dashboard-label">Confirm password</Form.Label>

                                    <input
                                       type={showConfPassword ? "text" : "password"}
                                       name="confirmpassword"
                                       autoComplete="off"
                                       className={"Password_Input"}
                                       style={
                                          touched.password && errors?.password
                                             ? { border: "1px solid red", paddingRight: "34px" }
                                             : { marginBottom: "12px", paddingRight: "34px" }
                                       }
                                       value={values.confirmpassword}
                                       onChange={handleChange}
                                       onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                       }}
                                    />
                                    {!showConfPassword && (
                                       <AiOutlineEyeInvisible
                                          size={19}
                                          className={"Pw_Icon"}
                                          onClick={toggleConfirmPassword}
                                          style={{ top: "38px" }}
                                       />
                                    )}
                                    {showConfPassword && (
                                       <span className={"Pw_Icon"} onClick={toggleConfirmPassword}>
                                          <svg
                                             width="16"
                                             height="16"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                          >
                                             <path
                                                d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                fill="#374253"
                                             />
                                          </svg>{" "}
                                       </span>
                                    )}
                                    {touched.confirmpassword && errors.confirmpassword && (
                                       <p className="text-danger mb-3">{errors?.confirmpassword}</p>
                                    )}
                                 </div>
                                 <div style={{ display: "flex", gap: "18px" }}>
                                    <div
                                       id="Signin_Button_cancel"
                                       className="d-flex justify-content-center align-items-center px-4 cursor_pointer white"
                                       onClick={() => setpasswordShow(false)}
                                    >
                                       Cancel
                                    </div>
                                    <button
                                       type="submit"
                                       className="red"
                                       id={
                                          passwordloading
                                             ? "Signin_Button_disabled"
                                             : "Signin_Button"
                                       }
                                    >
                                       Change password
                                    </button>
                                 </div>
                              </Form>
                              <Captcha reRef={reRef} />
                           </div>
                        </div>
                     </div>
                  </Modal.Body>
               )}
            </Formik>
         </Modal>
         <Modal
            show={showrequest}
            onHide={() => {
               setShowrequest(false);
            }}
         >
            <Formik
               validationSchema={schema}
               initialValues={{
                  customerId: UserDetails?.phone,
                  password: "",
                  confirmpassword: "",
               }}
               onSubmit={(values, actions) => {
                  handleOttModal(values);
               }}
            >
               {({ handleSubmit, handleChange, values, touched, errors, handleReset }) => (
                  <Modal.Body className="px-4 py-5">
                     {/* <div
                        className="close_btn cursor_pointer"
                        style={{ position: "absolute", top: "10px", right: "12px" }}
                        onClick={() => setpasswordShow(false)}
                     >
                        <AiOutlineClose />
                     </div> */}
                     <div className={"Login_Wrapper mt-2"}>
                        <div className={"Login_Modal"}>
                           <div>
                              <h1 className={"Login_Header"}>Request Ott</h1>
                              {/* {passworderror ? (
                                 <Alert variant="danger" style={{ width: "370px" }}>
                                    <MdError />
                                    &nbsp;&nbsp; Password doesn't match
                                 </Alert>
                              ) : (
                                 ""
                              )} */}
                              <Form
                                 onSubmit={(values, actions) => {
                                    handleSubmit(values);
                                 }}
                              >
                                 <Form.Label id="dashboard-label">Phone Number</Form.Label>
                                 <Form.Control
                                    disabled
                                    rows={3}
                                    type="text"
                                    name="customerId"
                                    className="mb-3"
                                    value={values.customerId}
                                    onChange={handleChange}
                                 />

                                 {touched.customerId && errors.customerId && (
                                    <p className="text-danger mb-0">Customer id is required</p>
                                 )}
                                 <div className={"Password_Container"}>
                                    <Form.Label id="dashboard-label">Enter new password</Form.Label>

                                    <input
                                       type={showPassword ? "text" : "password"}
                                       name="password"
                                       autoComplete="off"
                                       className={"Password_Input"}
                                       style={
                                          touched.password && errors?.password
                                             ? { border: "1px solid red", paddingRight: "34px" }
                                             : { marginBottom: "12px", paddingRight: "34px" }
                                       }
                                       value={values.password}
                                       onChange={handleChange}
                                       onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                       }}
                                    />
                                    {!showPassword && (
                                       <AiOutlineEyeInvisible
                                          size={19}
                                          className={"Pw_Icon"}
                                          onClick={togglePassword}
                                          style={{ top: "38px" }}
                                       />
                                    )}
                                    {showPassword && (
                                       <span
                                          className={"Pw_Icon"}
                                          style={{ top: "32px" }}
                                          onClick={togglePassword}
                                       >
                                          <svg
                                             width="16"
                                             height="16"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                          >
                                             <path
                                                d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                fill="#374253"
                                             />
                                          </svg>{" "}
                                       </span>
                                    )}

                                    {touched.password && errors.password && (
                                       <p className="text-danger mb-3">{errors.password}</p>
                                    )}
                                 </div>
                                 <div className={"Password_Container"}>
                                    <Form.Label id="dashboard-label">Confirm password</Form.Label>

                                    <input
                                       type={showConfPassword ? "text" : "password"}
                                       name="confirmpassword"
                                       autoComplete="off"
                                       className={"Password_Input"}
                                       style={
                                          touched.password && errors?.password
                                             ? { border: "1px solid red", paddingRight: "34px" }
                                             : { marginBottom: "12px", paddingRight: "34px" }
                                       }
                                       value={values.confirmpassword}
                                       onChange={handleChange}
                                       onPaste={(e) => {
                                          e.preventDefault();
                                          return false;
                                       }}
                                    />
                                    {!showConfPassword && (
                                       <AiOutlineEyeInvisible
                                          size={19}
                                          className={"Pw_Icon"}
                                          onClick={toggleConfirmPassword}
                                          style={{ top: "38px" }}
                                       />
                                    )}
                                    {showConfPassword && (
                                       <span className={"Pw_Icon"} onClick={toggleConfirmPassword}>
                                          <svg
                                             width="16"
                                             height="16"
                                             viewBox="0 0 16 16"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                          >
                                             <path
                                                d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                fill="#374253"
                                             />
                                          </svg>{" "}
                                       </span>
                                    )}
                                    {touched.confirmpassword && errors.confirmpassword && (
                                       <p className="text-danger mb-3">{errors?.confirmpassword}</p>
                                    )}
                                 </div>
                                 <div style={{ display: "flex", gap: "18px" }}>
                                    <div
                                       id="Signin_Button_cancel"
                                       className="d-flex justify-content-center align-items-center px-4 cursor_pointer white"
                                       onClick={() => setShowrequest(false)}
                                    >
                                       Cancel
                                    </div>
                                    <button
                                       type="submit"
                                       className="red px-2"
                                       id={
                                          passwordloading
                                             ? "Signin_Button_disabled"
                                             : "Signin_Button"
                                       }
                                    >
                                       Request
                                    </button>
                                 </div>
                              </Form>
                              <Captcha reRef={reRef} />
                           </div>
                        </div>
                     </div>
                  </Modal.Body>
               )}
            </Formik>
         </Modal>
         <Modal
            centered
            onHide={modalclose}
            show={ottmodal}
            animation={false}
            id="deletecart-modal"
         >
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body ">
               <div className="d-flex flex-column align-items-center mb-2">
                  <img src={tick} alt="" height={65} width={65} className="mb-3" />
                  <p className="cartdelete-title mb-3 text-center">
                     {ottInfo?.ottCustomerCreate?.remarks}
                  </p>
                  {/* <p className="mt-1 mb-1 fs-6 fw-normal">
                     <span className="fw-bolder"> Username: </span>{" "}
                     {ottInfo?.ottCustomerCreate?.ottUserId}
                  </p>
                  <p className="mb-3 fs-6 fw-normal">
                     <span className="fw-bolder"> Password: </span>{" "}
                     {ottInfo?.ottCustomerCreate?.ottPassword}
                  </p> */}

                  <div className="app_div mt-2 d-flex align-items-center" style={{ gap: "6px" }}>
                     <img src={goLogo} alt="" className="dishhomego_logo" height={64} />
                     <img
                        src={googleplay}
                        style={{ cursor: "pointer" }}
                        height="45px"
                        width="130px"
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.google_play_go, "_blank");
                        }}
                     />
                     <img
                        src={apple}
                        height="45px"
                        width="130px"
                        style={{ marginLeft: "10px" }}
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.app_store_go, "_blank");
                        }}
                     />
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         <MessageModal
            show={show}
            setShow={setShow}
            message={message}
            // heading={("Username" - ottInfo.username, "Password" - ottInfo.password)}
            buttonText={"Ok"}
         />
         <MessageModal
            show={unverifiedUser}
            setShow={setUnverifiedUser}
            message={
               "Your free account cannot be renewed. Please subscribe to DishHome to get unlimited access to DishHome Go"
            }
            heading={"Error"}
            buttonText={"OK"}
            type={"error"}
         />
         <MessageModal
            show={error}
            setShow={setError}
            // heading={"Invalid"}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         {Object.entries(data).map(([key, value]) => {
            if (value?.page_location === "dishhomego_top") {
               return (
                  <div
                     className="ottbanner__wrapper"
                     style={
                        value?.media_type === "video"
                           ? {
                                backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${value.thumbnail})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                             }
                           : {
                                backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${value.media})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                             }
                     }
                  >
                     <TrackVisibility>
                        <ComponentToTrack data={value} />
                     </TrackVisibility>
                     <div className="ott__text fixed-width">
                        <h1
                           className="ottbanner_heading"
                           style={
                              value?.text_color === "light" ? { color: "#FFF" } : { color: "#000" }
                           }
                        >
                           {value?.caption}
                        </h1>
                        <p
                           className="ottbanner_text"
                           style={
                              value?.text_color === "light" ? { color: "#FFF" } : { color: "#000" }
                           }
                        >
                           {value?.sub_caption}
                        </p>
                        <div style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
                           <LoginModal
                              Show={loginModalState}
                              setShow={setLoginModalState}
                              link={"/dishhome-go"}
                           />
                           {value?.button_type === "" ? null : (
                              <>
                                 {profile?.got_ott === true ? null : (
                                    settings?.get_dishhome_go && (
                                       <button
                                          className={
                                             getloading ? "ottbanner_disabled_btn" : "ottbanner_btn"
                                          }
                                          onClick={() => {
                                             if (UserDetails === undefined) {
                                                navigate("/signup?source=dishhomego");
                                                setLoginModalState(true);
                                             } else if (UserDetails?.is_kyc_verified) {
                                                setShowrequest(true);
                                             } else {
                                                setShowrequest(true);
                                                // console.log("navigate");
                                             }
                                          }}
                                       >
                                          Get DishHome Go
                                       </button>
                                          )
                                 )}
                                 {settings?.change_password && (
                                    <button
                                       className="ottbanner_btn"
                                       onClick={() => {
                                          if (UserDetails === undefined) {
                                             setLoginModalState(true);
                                          } else {
                                             handleOttpassword();
                                          }
                                       }}
                                       style={
                                          value?.text_color === "light"
                                             ? { color: "#FFF" }
                                             : { color: "#FFF" }
                                       }
                                    >
                                       Change Password
                                    </button>
                                 )}
                                 {settings?.renew_dh_go && (
                                    <button
                                       className={
                                          renewloading ? "ottbanner_disabled_btn" : "ottbanner_btn"
                                       }
                                       // onClick={handleOttRenew}
                                       onClick={() => {
                                          if (UserDetails === undefined) {
                                             setLoginModalState(true);
                                          } else if (UserDetails?.is_kyc_verified) {
                                             handleOttRenew();
                                          } else {
                                             setUnverifiedUser(true);
                                          }
                                       }}
                                       style={
                                          value?.text_color === "light"
                                             ? { color: "#FFF" }
                                             : { color: "#FFF" }
                                       }
                                    >
                                       Renew DishHome Go
                                    </button>
                                 )}
                                 {settings?.buy_packages && (
                                    <button
                                       className={"ottbanner_btn"}
                                       // onClick={handleOttRenew}
                                       onClick={() => {
                                          navigate("/dishhome-go/packages");
                                       }}
                                       style={
                                          value?.text_color === "light"
                                             ? { color: "#FFF" }
                                             : { color: "#FFF" }
                                       }
                                    >
                                       Buy Packages
                                    </button>
                                 )}
                              </>
                           )}
                        </div>
                     </div>
                     {/* <div className="ott__img">
                        <img
                           src={
                              value.media_type === "video"
                                 ? `${process.env.REACT_APP_FILE_URL}/${value?.thumbnail}`
                                 : value.media === undefined
                                 ? BannerImg
                                 : `${process.env.REACT_APP_FILE_URL}/${value?.media}`
                           }
                           alt=""
                           srcset=""
                        />
                     </div> */}
                  </div>
               );
            } else {
               return null;
            }
         })}
      </div>
   );
};

export default OttBanner;
