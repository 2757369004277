import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

function DHGOHistory({ data }) {
   // console.log(data);
   const [pageCount, setPageCount] = useState();
   const [transactionData, settransactionData] = useState("");

   useEffect(() => {
      setPageCount(Math.ceil(data?.length / 15));
      settransactionData(data?.slice(0, 15));
   }, [pageCount, data]);

   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 15;
      const newArray = data.slice(offset, offset + 15);
      settransactionData(newArray);
   };

   return (
      <div style={{ width: "100%" }} className="dashboardBilling_Wrapper">
         {data?.length !== 0 && (
            <div className="billing-table">
               <div>
                  <p id="bill-header">Title</p>
               </div>
               <div>
                  <p id="bill-header">Type</p>
               </div>
               <div>
                  <p id="bill-header">Bill Date</p>
               </div>
               <div>
                  <p id="bill-header">Amount</p>
               </div>
               {/* <div>
         <p id="bill-header">Status</p>
      </div> */}
            </div>
         )}
         {transactionData &&
            Object.entries(transactionData).map(([key, value]) => {
               return (
                  <BillingData
                     title={value?.alias}
                     date={value?.createdOn}
                     amount={value?.prices}
                     priceId={value?.PricingId}
                     type={value?.type}
                  />
               );
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {data?.length === 0 && <h6 style={{ color: "darkgrey" }}>No Billing History</h6>}
      </div>
   );
}

export const BillingData = ({ title, date, amount, priceId, type }) => {
   const price = amount?.filter((data) => data?.priceId !== priceId);
   // console.log(price[0]?.price);
   return (
      <div className="billing-features">
         <div>
            <p>{title}</p>
         </div>
         <div>
            <p>{type ? type : "package"}</p>
         </div>
         <div>
            <p> {format(new Date(date), "dd LLL, yyyy")}</p>
         </div>
         <div>
            <p>Rs. {price[0]?.price?.toLocaleString("hi-IN")}</p>
         </div>
      </div>
   );
};

export default DHGOHistory;
