import React, { useEffect, useState } from "react";
import "../../scss/store/arrivals.scss";
import Card from "react-bootstrap/Card";
import ReactStars from "react-rating-stars-component";
import { get_new_arrival } from "../../data/api";
import stars from "../../assets/store/stars.png";
import products from "../../assets/store/Products.png";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import { useNavigate } from "react-router";
// import { Col, Row } from "react-bootstrap";

export default function NewArrivals() {
   const [newarrivalsdata, setnewarrivalsData] = useState("");
   //get all newly arrived data
   useEffect(() => {
      async function get_newarrivals() {
         const res = await get_new_arrival();
         const response = res.data;
         setnewarrivalsData(response);
      }
      get_newarrivals();
   }, []);

   let navigate = useNavigate();
   return (
      <div className="categories">
         <p className="categories_title">New Arrivals.</p>
         <p className="categories_description">Take a look at what’s new, right now.</p>
         <Widthcontainer>
            <div className="arrival_box fixed-width">
               {Object.entries(newarrivalsdata).map(([key, value]) => {
                  // console.log("value", value);
                  return (
                     key < 12 && (
                        <div
                           id="flex-item"
                           style={{ width: "100%", display: "flex", justifyContent: "center" }}
                        >
                           <Card
                              className="arrival_container my-2 cursor_pointer "
                              style={{
                                 minWidth: "180px",
                                 maxWidth: "200px",
                                 border: "1px solid #E6EAF0",
                                 borderRadius: "6px",
                              }}
                              onClick={() => {
                                 navigate("/store/" + value._id);
                              }}
                           >
                              {value.sale.saleId !== null ? (
                                 <div id="sale-tag">
                                    <p>Sale offer</p>
                                 </div>
                              ) : (
                                 <></>
                              )}

                              <Card.Img
                                 className="arrival_img "
                                 variant="top"
                                 src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                              />
                              <Card.Body>
                                 <Card.Title>
                                    <div
                                       style={{
                                          display: "flex",
                                          justifyContent: "center",
                                       }}
                                    >
                                       <p id="products_image_Text_design">
                                          {value?.categories[0]?.title?.toUpperCase()}
                                       </p>
                                    </div>
                                 </Card.Title>
                                 <Card.Text>
                                    <p className="arrival_title">{value.name}</p>

                                    {value.sale.saleId !== null ? (
                                       <>
                                          <p
                                             className="arrival_cost mt-2"
                                             style={{ textDecoration: "line-through" }}
                                          >
                                             Rs. {value?.price?.toLocaleString("hi-IN")}
                                          </p>
                                          <p className="arrival_cost mt-1">
                                             Rs. {value?.sale?.sale_price.toLocaleString("hi-IN")}
                                          </p>
                                       </>
                                    ) : (
                                       <>
                                          <p className="arrival_cost mt-2">
                                             Rs. {value.price.toLocaleString("hi-IN")}{" "}
                                          </p>
                                       </>
                                    )}
                                    <div
                                       style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          marginTop: "7px",
                                       }}
                                    >
                                       <p
                                          style={{
                                             display: "flex",
                                             gap: "4px",
                                             alignItems: "center",
                                          }}
                                       >
                                          {value?.reviews?.length > 0 && (
                                             <ReactStars
                                                value={value?.rating}
                                                count={5}
                                                edit={false}
                                                size={24}
                                                activeColor="#ffd700"
                                                color="#D0D0D0"
                                             />
                                          )}
                                          {/* <p style={{ margin: "0px" }}>
                                             ({value?.reviews?.length})
                                          </p> */}
                                       </p>
                                    </div>
                                 </Card.Text>
                              </Card.Body>
                           </Card>
                        </div>
                     )
                  );
               })}
            </div>
         </Widthcontainer>
      </div>
   );
}
export function ArrivalList(props) {
   return (
      <div>
         <Card className="arrival_container" style={{ width: "236px", height: "320px" }}>
            <Card.Img className="arrival_img" variant="top" src={props.img} height={200} />
            <Card.Body>
               <Card.Title>
                  <p className="products">{props.product}</p>
               </Card.Title>
               <Card.Text>
                  <p className="arrival_name">{props.product_name}</p>
                  <img src={stars} />
                  <p className="arrival_cost">RS.{props.price.toLocaleString("hi-IN")}</p>
               </Card.Text>
            </Card.Body>
         </Card>
      </div>
   );
}
