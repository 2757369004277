import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router";
// import React from "react";
// import { useLocation } from "react-router";
import myKey from "../../../store/pages/khaltiKey";
// import KhaltiCheckout from "khalti-checkout-web";

export const base = () => {
   const flag = localStorage?.getItem("remember");
   let tokenString;
   if (flag === "true") {
      tokenString = localStorage.getItem("user");
   } else {
      tokenString = localStorage.getItem("user");
   }
   const userToken = JSON.parse(tokenString);
   const access = userToken.accessToken;
   const data = {
      baseURL: process.env.REACT_APP_BASE_URL + "v1",
      headers: {
         "content-type": "application/json",
         Authorization: `Bearer ${access}`,
         Token: process.env.REACT_APP_API_SECRET_TOKEN,
      },
   };
   return axios.create(data);
};

export const baseWithoutToken = () => {
   const data = {
      baseURL: process.env.REACT_APP_BASE_URL + "v1",
      headers: {
         "content-type": "application/json",
      },
   };
   return axios.create(data);
};

export const EsewaPayment = (order_id, total) => {
   var params = {
      amt: total,
      psc: 0,
      pdc: 0,
      txAmt: 0,
      tAmt: total,
      pid: order_id,
      scd: `${process.env.REACT_APP_ESEWA_SCD}`,
      su: `${process.env.REACT_APP_PUBLIC_URL}order/verify`,
      fu: `${process.env.REACT_APP_PUBLIC_URL}order/verify`,
   };
   var form = document.createElement("form");
   form.setAttribute("method", "POST");
   form.setAttribute("action", `${process.env.REACT_APP_ESEWA_URL}`);
   for (var key in params) {
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("value", params[key]);
      form.appendChild(hiddenField);
   }
   document.body.appendChild(form);
   form.submit();
};

//imePay
export const handleIMEPay = (order_id, total) => {
   var data = {
      total: total,
      refId: `${order_id}`,
   };
   base()
      .post("/shop/payment/ime/generate-token", data)
      .then((response) => {
         if (response?.data?.data?.ResponseCode === 0) {
            var form = document.createElement("form");
            var formElements = [
               { name: "Method", value: "Get" },
               { name: "TranAmount", value: response.data.data.Amount },
               { name: "RefId", value: response.data.data.RefId },
               { name: "MerchantCode", value: process.env.REACT_APP_IMEPAY_MERCHANT_CODE },
               { name: "CancelUrl", value: process.env.REACT_APP_IMEPAY_FAIL_URL },
               { name: "RespUrl", value: process.env.REACT_APP_IMEPAY_SUCCESS_URL },
               { name: "TokenId", value: response.data.data.TokenId },
            ];
            form.method = "POST";
            form.action = process.env.REACT_APP_IMEPAY_CHECKOUT_URL;
            for (var i = 0; i < formElements.length; i++) {
               var element = formElements[i];
               var input = document.createElement("input");
               input.type = "hidden";
               input.name = element.name;
               input.value = element.value;
               form.appendChild(input);
            }
            document.body.appendChild(form);
            //console.log(form);
            form.submit();
         } else {
         }
      })
      .catch((error) => {});
};

export const handlePrabhuPay = async (order_id, total) => {
   try {
      const requestParameter = {
         totalAmount: total,
         merchantId: process.env.REACT_APP_PRABHUPAY_MERCHANT_ID,
         password: process.env.REACT_APP_PRABHUPAY_PASSWORD,
         invoiceNo: `${order_id}`,
         productDetails: [],
         remarks: `REM-${Math.floor(Math.random() * 10000)}`,
         returnUrl: process.env.REACT_APP_PRABHUPAY_RETURN_URL,
      };
      let url = process.env.REACT_APP_PRABHUYPAY_INITIATE_URL;
      baseWithoutToken()
         .post(url, requestParameter)
         .then((res) => {
            if (res.data.success) {
               window.location.replace(res.data.data.redirectionUrl);
            } else {
               window.location.replace(`${process.env.REACT_APP_PUBLIC_URL}order/verify`);
            }
         })
         .catch((err) => {});
   } catch (error) {}
};

export const handleConnectIPS = async (uid, order_id, order_name, total) => {
   try {
      const paymentData = {
         userId: uid,
         // MERCHANTID: process.env.REACT_APP_CONNECTIPS_MERCHANT_ID,
         // APPID: process.env.REACT_APP_CONNECTIPS_APP_ID,
         // APPNAME: process.env.REACT_APP_CONNECTIPS_APP_NAME,
         // TXNID: `${Math.random().toString(36).slice(2)}`,
         // TXNDATE: format(new Date(), "dd-MM-yyyy"),
         // TXNCRNCY: "NPR",
         // TXNAMT: total * 100,
         // REFERENCEID: `REF-${Math.floor(Math.random() * 100)}`,
         // REMARKS: order_id, //`RMKS-${Math.floor(Math.random() * 100)}`,
         // PARTICULARS: `PART-${Math.floor(Math.random() * 100)}`,
         // TOKEN: "TOKEN",
         total: total,
         orderId: order_id,
         orderName: order_name,
      };

      base()
         .post(
            `${process.env.REACT_APP_BASE_URL}v1/shop/payment/connectips/generate-token`,
            paymentData,
         )
         .then((res) => {
            let form = document.createElement("form");
            let formElements = [
               { name: "MERCHANTID", value: res.data.MERCHANTID },
               { name: "APPID", value: res.data.APPID },
               { name: "APPNAME", value: res.data.APPNAME },
               { name: "TXNID", value: res.data.TXNID },
               { name: "TXNDATE", value: res.data.TXNDATE },
               { name: "TXNCRNCY", value: res.data.TXNCRNCY },
               { name: "TXNAMT", value: res.data.TXNAMT },
               { name: "REFERENCEID", value: res.data.REFERENCEID },
               { name: "REMARKS", value: res.data.REMARKS },
               { name: "PARTICULARS", value: res.data.PARTICULARS },
               { name: "TOKEN", value: res.data.TOKEN },
            ];
            form.method = "POST";
            form.action = res.data.LOGIN_URL;

            for (let i = 0; i < formElements.length; i++) {
               let element = formElements[i];
               let input = document.createElement("input");
               input.type = "hidden";
               input.name = element.name;
               input.value = element.value;
               form.appendChild(input);
            }

            document.body.appendChild(form);

            form.submit();

            return;
         })
         .catch((err) => {});
   } catch (error) {}
};
//khalti payment
export const handleKhaltiPayment = (order_id, order_name, uid, total) => {
   // let Khalticonfig = {
   //    publicKey: process.env.REACT_APP_KHALTI_PUBLIC_KEY,
   //    productIdentity: order_id,
   //    productName: order_name,
   //    productUrl: process.env.REACT_APP_PUBLIC_URL,
   //    eventHandler: {
   //       onSuccess(payload) {
   //          let data = {
   //             token: payload?.token,
   //             amount: payload.amount,
   //             userId: uid,
   //             orderId: order_id,
   //             orderName: order_name,
   //          };
   //          base()
   //             .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/khalti/verify`, data)
   //             .then((response) => {
   //                if (response.status === 200) {
   //                   setSuccess(true);
   //                } else {
   //                   setSuccess(false);
   //                }
   //             })
   //             .catch((error) => {
   //                setSuccess(false);
   //             });
   //       },
   //    },
   //    paymentPreference: ["KHALTI", "EBANKING", "MOBILE_BANKING", "CONNECT_IPS", "SCT"],
   // };
   // return Khalticonfig;
   try {
      const data = JSON.stringify({
         orderId: order_id,
         payment_method: "khalti",
         orderName: order_name,
         userId: uid,
         total: total,
      });
      base()
         .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/khalti/initiate-payment`, data)
         .then((res) => {
            if (res.status === 200) {
               window.location.href = res.data.payment_url;
            }
         })
         .catch((err) => {});
   } catch (e) {}
};

export const handleOnlinePayment = async (data) => {
   localStorage.setItem("orderRef", JSON.stringify(data));
   switch (data?.payment_method) {
      case "esewa":
         EsewaPayment(data?.order_id, data?.total);
         break;
      case "imepay":
         handleIMEPay(data?.order_id, data?.total);
         break;
      case "prabhupay":
         handlePrabhuPay(data?.order_id, data?.total);
         break;
      case "connectips":
         handleConnectIPS(data?.uid, data?.order_id, data?.order_name, data?.total);
         break;
      case "khalti":
         return handleKhaltiPayment(data?.order_id, data?.order_name, data?.uid, data?.total);
      default:
         break;
   }
};

///Verify

export const handleVerifyEsewa = async (oid, amt, refid, uid, order_id, order_name, setSuccess) => {
   base()
      .get(
         `${process.env.REACT_APP_BASE_URL}v1/shop/payment/esewa/verify?oid=${oid}&amt=${amt}&refId=${refid}&userId=${uid}&orderId=${order_id}&orderName=${order_name}`,
      )
      .then((responseData) => {
         // navigate("/cart");
         // console.log(responseData);
         if (responseData.status === 200) {
            setSuccess(true);
         } else {
            setSuccess(false);
         }
      })
      .catch((err) => {
         setSuccess(false);
         return err;
      });
};

export const handleVerifyIMEPay = async (imeData, uid, order_id, order_name, total, setSuccess) => {
   const decodedString = atob(imeData).split("|");
   const postBody = {
      MerchantCode: process.env.REACT_APP_IMEPAY_MERCHANT_CODE,
      RefId: decodedString[4],
      TokenId: decodedString[6],
      TransactionId: decodedString[3],
      Msisdn: decodedString[2],
      userId: uid,
      orderId: order_id,
      orderName: order_name,
      total: total,
   };

   base()
      .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/ime/verify`, postBody)
      .then((res) => {
         if (res.status === 200) {
            setSuccess(true);
         } else {
            setSuccess(false);
         }
      })
      .catch((err) => {
         setSuccess(false);
      });
};

export const handleVerifyPrabhuPay = async (
   transactionId,
   uid,
   order_id,
   order_name,
   total,
   setSuccess,
) => {
   const prabhubody = {
      transactionId: transactionId,
      userId: uid,
      orderId: order_id,
      orderName: order_name,
      total: total,
   };
   base()
      .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/prabhupay/verify`, prabhubody)
      .then((response) => {
         if (response.status === 200) {
            setSuccess(true);
         } else {
            setSuccess(false);
         }
      })
      .catch((err) => {
         setSuccess(false);
      });
};

export const handleVerifyConnectIPS = async (TXNID, uid, order_id, order_name, setSuccess) => {
   const ipsbody = {
      txnId: TXNID,
      userId: uid,
      orderId: order_id,
      orderName: order_name,
   };
   base()
      .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/connectips/verify`, ipsbody)
      .then((res) => {
         if (res.status === 200) {
            setSuccess(true);
         } else {
            setSuccess(false);
         }
      })
      .catch((err) => {
         // console?.log("Err", err?.response);
         setSuccess(false);
      });
};

export const handleVerifyKhalti = async (uid, order_id, order_name, amount, setSuccess) => {
   const khaltibody = {
      userId: uid,
      orderId: order_id,
      orderName: order_name,
      amount: amount,
   };
   base()
      .post(`${process.env.REACT_APP_BASE_URL}v1/shop/payment/khalti/verify`, khaltibody)
      .then((response) => {
         if (response.status === 200) {
            setSuccess(true);
         } else {
            setSuccess(false);
         }
      })
      .catch((error) => {
         setSuccess(false);
      });
};

export const handleVerifyPayment = async (data) => {
   const orderRef = localStorage.getItem("orderRef");
   const orderData = JSON.parse(orderRef);
   switch (orderData?.payment_method) {
      case "esewa":
         handleVerifyEsewa(
            data?.oid,
            data?.amt,
            data?.refid,
            data?.uid,
            orderData?.order_id,
            orderData?.order_name,
            data?.setSuccess,
         );
         break;
      case "imepay":
         handleVerifyIMEPay(
            data?.imeData,
            data?.uid,
            orderData?.order_id,
            orderData?.order_name,
            orderData?.total,
            data?.setSuccess,
         );
         break;
      case "prabhupay":
         handleVerifyPrabhuPay(
            data?.transactionId,
            data?.uid,
            orderData?.order_id,
            orderData?.order_name,
            orderData?.total,
            data?.setSuccess,
         );
         break;
      case "connectips":
         handleVerifyConnectIPS(
            data?.TXNID,
            data?.uid,
            orderData?.order_id,
            orderData?.order_name,
            data?.setSuccess,
         );
         break;
      case "khalti":
         handleVerifyKhalti(
            data?.uid,
            orderData?.order_id,
            orderData?.order_name,
            orderData?.total,
            data?.setSuccess,
         );
      default:
         break;
   }
};
