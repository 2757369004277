import React, { useState, useEffect } from "react";
import "../scss/dashboard/dashboardbillings.scss";
import dash from "../assets/dashboard/dash.png";
import { get_completed_package_orders, get_transaction_history } from "../data/protectedapi";
import Token from "../utils/useToken";
import Loading from "../components/customcomponents/loading";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import ErrorPage from "../components/pages/errorPage";

export default function PackageackageTransaction() {
   const [pageCount, setPageCount] = useState();
   const { userDetails } = Token();
   //get transaction data
   const [order, setOrder] = useState("");
   const [selectedPage, setSelectedPage] = useState(0);

   const handlePageClick = (e) => {
      setSelectedPage(e.selected + 1);
   };
   useEffect(() => {
      async function get_all_orders() {
         const res = await get_completed_package_orders(selectedPage);
         const response = res?.data?.data;
         setOrder(response);
         setPageCount(res?.data?.paging?.pages);
      }
      get_all_orders();
   }, [selectedPage]);

   return userDetails?.is_kyc_verified === false ? (
      <h6 style={{ color: "darkgrey" }}>Please Complete your profile to get billing history</h6>
   ) : order === undefined || order === "" ? (
      <Loading />
   ) : userDetails?.customer_type === "internetOnly" ? (
      <div className="mt-5">
         <ErrorPage />
      </div>
   ) : (
      <div style={{ width: "100%" }} className="dashboardBilling_Wrapper">
         {order?.length !== 0 && (
            <div className="billing-table">
               <div>
                  <p id="bill-header">Bill Type</p>
               </div>
               <div>
                  <p id="bill-header">Bill Date</p>
               </div>
               <div>
                  <p id="bill-header">Amount</p>
               </div>
               {/* <div>
               <p id="bill-header">Status</p>
            </div> */}
            </div>
         )}
         {order &&
            Object.entries(order).map(([key, value]) => {
               return (
                  <BillingData
                     title={value?.payment_method}
                     date={value?.paid_at}
                     amount={value?.total}
                  />
               );
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {order?.length === 0 && <h6 style={{ color: "darkgrey" }}>No Billing History</h6>}
      </div>
   );
}

export const BillingData = ({ title, date, amount }) => {
   return (
      <div className="billing-features">
         <div>
            <p>{title}</p>
         </div>
         <div>
            <p> {date && format(new Date(date), "dd LLL, yyyy")}</p>
         </div>
         <div>
            <p>Rs. {amount.toLocaleString("hi-IN")}</p>
         </div>
      </div>
   );
};
