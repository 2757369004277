import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "../../../../scss/internet/compare/header.scss";
import { get_internet_package } from "../../../../data/api";
import "../../../../scss/internet/compare/pricingtable.scss";
import Widthcontainer from "../../widthcontainer";
import Internetpackage from "../internetpackage";
import { get_single_internet } from "../../../../data/api";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import Dropdown from "react-dropdown";

export default function Header() {
   const [internetdata, setinternetData] = useState([]);
   const [internettitle, setInternetTitle] = useState();
   const [selectValue1, setSelectValue1] = useState("");
   const [selectValue2, setSelectValue2] = useState("");
   const [selectValue3, setSelectValue3] = useState("");
   const [singleinternet1, setSingleInternet1] = useState("");
   const [singleinternet1price, setSingleInternet1price] = useState("");
   const [singleinternet2, setSingleInternet2] = useState("");
   const [singleinternet2price, setSingleInternet2price] = useState(" ");
   const [singleinternet3, setSingleInternet3] = useState("");
   const [singleinternet3price, setSingleInternet3price] = useState("");

   //Media Query
   const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
   const isMobileTitle = useMediaQuery({ query: "(max-width: 600px)" });

   useEffect(() => {
      async function get_internetpackage() {
         const res = await get_internet_package();
         const response = res.data.data;
         setinternetData(response);
      }
      get_internetpackage();
   }, []);
   useEffect(() => {
      internetdata[0] && setSelectValue1(internetdata[0]?._id);
      internetdata[1] && setSelectValue2(internetdata[1]?._id);
      internetdata[2] && setSelectValue3(internetdata[2]?._id);
   }, [internetdata]);

   const [firstvalue, setFirstvalue] = useState("");
   const [secondvalue, setSecondvalue] = useState("");
   const [thirdvalue, setThirdvalue] = useState("");

   useEffect(() => {
      [...internetdata].map((item) => {
         if (selectValue1 === item._id) {
            setFirstvalue(item.title);
         }
         if (selectValue2 === item._id) {
            setSecondvalue(item.title);
         }
         if (selectValue3 === item._id) {
            setThirdvalue(item.title);
         }
      });
   }, [selectValue1, firstvalue, internetdata]);
   const navigate = useNavigate();
   const handlePackage1 = (e) => {
      setSelectValue1(e.target.value);
   };

   const handlePackage2 = (e) => {
      setSelectValue2(e.target.value);
   };
   const handlePackage3 = (e) => {
      setSelectValue3(e.target.value);
   };

   //package selection by id
   useEffect(() => {
      if (selectValue1) {
         async function get_single() {
            const res = await get_single_internet(selectValue1);
            const response = res.data;
            setSingleInternet1(response);
            setSingleInternet1price(response.data.prices);
         }
         get_single();
      }
   }, [selectValue1]);

   useEffect(() => {
      if (selectValue1) {
         async function get_single2() {
            const res = await get_single_internet(selectValue2);
            const response = res.data;
            setSingleInternet2(response);
            setSingleInternet2price(response.data.prices);
         }
         get_single2();
      }
   }, [selectValue2]);

   useEffect(() => {
      if (selectValue3) {
         async function get_single2() {
            const res = await get_single_internet(selectValue3);
            const response = res.data;
            setSingleInternet3(response);
            setSingleInternet3price(response.data.prices);
         }
         get_single2();
      }
   }, [selectValue3]);

   let internetOptions = [];
   let internetOptions1 = [];
   let internetOptions2 = [];

   const handlePackageChange = (option) => {
      setSelectValue1(option.value);
   };
   const handlePackageChange1 = (option) => {
      setSelectValue2(option.value);
   };
   const handlePackageChange2 = (option) => {
      setSelectValue3(option.value);
   };
   return (
      <div>
         <div className="compare-header ">
            <div className="header__container fixed-width">
               <div className="ps-2">
                  <h1 className="header-heading">Compare Plans</h1>
                  <p className="compare-text">
                     Get the Internet speed you need and the TV entertainment you love.
                  </p>
               </div>
               <div className="dropdown-wrapper">
                  <div className="drop-container">
                     <div>
                        <p className="select-package">Select Package</p>
                     </div>
                     <div className="form-wrapper">
                        {Object.entries(internetdata).map(([key, value]) => {
                           internetOptions.push({ value: value._id, label: value.title });
                        })}

                        <Dropdown
                           value={firstvalue}
                           onChange={handlePackageChange}
                           options={internetOptions}
                        />

                        {Object.entries(internetdata).map(([key, value]) => {
                           internetOptions1.push({ value: value._id, label: value.title });
                        })}

                        <Dropdown
                           value={secondvalue}
                           onChange={handlePackageChange1}
                           options={internetOptions1}
                        />

                        {Object.entries(internetdata).map(([key, value]) => {
                           internetOptions2.push({ value: value._id, label: value.title });
                        })}

                        {!isMobile && (
                           <Dropdown
                              value={thirdvalue}
                              onChange={handlePackageChange2}
                              options={internetOptions2}
                           />
                        )}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Widthcontainer>
            <div className="proporties__container fixed-width">
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           width: "fit-content",
                           borderBottom: "2px solid #ed1c24",
                           color: "#ed1c24",
                        }}
                     >
                        Price
                     </p>
                  </div>
               )}
               <div className="proporties-wrapper ">
                  {!isMobileTitle && (
                     <div className="properties-title">
                        <p className="properties-title">Price</p>
                     </div>
                  )}

                  <div>
                     {Object.entries(singleinternet1price).map(([key, value]) => {
                        return (
                           <div>
                              {value.type === "1 Month" ? (
                                 <div>
                                    <p className="properties-description">
                                       <span id="plan-mo">Rs.</span>
                                       <span id="plan-price">
                                          {" "}
                                          {(
                                             value?.installation_charge +
                                             value?.internet_price +
                                             value?.drop_wire +
                                             value?.itv_price +
                                             value?.itv_stb_charge +
                                             value?.router
                                          ).toLocaleString("hi-IN")}
                                          *
                                       </span>
                                       <span id="plan-mo">/mo</span> <br />
                                       <p id="plan-des">
                                          Installation Charge Rs. {value.installation_charge} only.
                                       </p>
                                       <Button
                                          variant="secondary"
                                          onClick={() => {
                                             navigate(`/internet/${singleinternet1.data._id}`);
                                          }}
                                          className="plan-button"
                                       >
                                          Choose This Plan
                                       </Button>
                                    </p>
                                 </div>
                              ) : (
                                 ""
                              )}
                           </div>
                        );
                     })}
                  </div>

                  <div>
                     {Object.entries(singleinternet2price).map(([key, value]) => {
                        return (
                           <div>
                              {value.type === "1 Month" ? (
                                 <div>
                                    <p className="properties-description">
                                       <span id="plan-mo">Rs.</span>
                                       <span id="plan-price">
                                          {" "}
                                          {(
                                             value?.installation_charge +
                                             value?.internet_price +
                                             value?.drop_wire +
                                             value?.itv_price +
                                             value?.itv_stb_charge +
                                             value?.router
                                          ).toLocaleString("hi-IN")}
                                          *
                                       </span>
                                       <span id="plan-mo">/mo</span> <br />
                                       <p id="plan-des">
                                          Installation Charge Rs. {value.installation_charge} only.
                                       </p>
                                       <Button
                                          variant="secondary"
                                          onClick={() => {
                                             navigate(`/internet/${singleinternet2.data._id}`);
                                          }}
                                          className="plan-button"
                                       >
                                          Choose This Plan
                                       </Button>
                                    </p>
                                 </div>
                              ) : (
                                 ""
                              )}
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3price).map(([key, value]) => {
                           return (
                              <div>
                                 {value.type === "1 Month" ? (
                                    <div>
                                       <p className="properties-description">
                                          <span id="plan-mo">Rs.</span>
                                          <span id="plan-price">
                                             {" "}
                                             {(
                                                value?.installation_charge +
                                                value?.internet_price +
                                                value?.drop_wire +
                                                value?.itv_price +
                                                value?.itv_stb_charge +
                                                value?.router
                                             ).toLocaleString("hi-IN")}
                                             *
                                          </span>
                                          <span id="plan-mo">/mo</span> <br />
                                          <p id="plan-des">
                                             Installation Charge Rs. {value.installation_charge}{" "}
                                             only.
                                          </p>
                                          <Button
                                             variant="secondary"
                                             onClick={() => {
                                                navigate(`/internet/${singleinternet3.data._id}`);
                                             }}
                                             className="plan-button"
                                          >
                                             Choose This Plan
                                          </Button>
                                       </p>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           borderBottom: "2px solid #ed1c24",
                           width: "fit-content",
                           color: "#ed1c24",
                        }}
                     >
                        Title
                     </p>
                  </div>
               )}
               <div className="proporties-wrapper ">
                  {!isMobileTitle && (
                     <div className="properties-title">
                        <p className="properties-title">Title</p>
                     </div>
                  )}

                  <div>
                     {Object.entries(singleinternet1).map(([key, value]) => {
                        return (
                           <div>
                              <p className="properties-description">{value.title} </p>
                           </div>
                        );
                     })}
                  </div>

                  <div>
                     {Object.entries(singleinternet2).map(([key, value]) => {
                        return (
                           <div>
                              <p className="properties-description">{value.title}</p>
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3).map(([key, value]) => {
                           return (
                              <div>
                                 <p className="properties-description">{value.title}</p>
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           width: "fit-content",
                           borderBottom: "2px solid #ed1c24",
                           color: "#ed1c24",
                        }}
                     >
                        Speed
                     </p>
                  </div>
               )}
               <div className="proporties-wrapper ">
                  {!isMobileTitle && (
                     <div>
                        <p className="properties-title">Speed</p>
                     </div>
                  )}
                  <div>
                     {Object.entries(singleinternet1).map(([key, value]) => {
                        return (
                           <div>
                              <p className="properties-description">{value.speed} </p>
                           </div>
                        );
                     })}
                  </div>

                  <div>
                     {Object.entries(singleinternet2).map(([key, value]) => {
                        return (
                           <div>
                              <p style={{ textAlign: "center" }}>{value.speed}</p>
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3).map(([key, value]) => {
                           return (
                              <div>
                                 <p className="properties-description">{value.speed}</p>
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           width: "fit-content",
                           borderBottom: "2px solid #ed1c24",
                           color: "#ed1c24",
                        }}
                     >
                        Details
                     </p>
                  </div>
               )}
               <div className="proporties-wrapper ">
                  {!isMobileTitle && (
                     <div>
                        <p className="properties-title">Details</p>
                     </div>
                  )}
                  <div>
                     {Object.entries(singleinternet1).map(([key, value]) => {
                        return (
                           <div className="side-border">
                              <p className="properties-description">{value.ont_type} </p>
                           </div>
                        );
                     })}
                  </div>

                  <div>
                     {Object.entries(singleinternet2).map(([key, value]) => {
                        return (
                           <div className="side-border">
                              <p className="properties-description">{value.ont_type}</p>
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3).map(([key, value]) => {
                           return (
                              <div className="side-border">
                                 <p className="properties-description">{value.ont_type}</p>
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           borderBottom: "2px solid #ed1c24",
                           width: "fit-content",
                           color: "#ed1c24",
                        }}
                     >
                        Description
                     </p>
                  </div>
               )}
               <div
                  className="proporties-wrapper "
                  style={isMobileTitle ? { paddingTop: "0px" } : {}}
               >
                  {!isMobileTitle && (
                     <div>
                        <p className="properties-title">Description</p>
                     </div>
                  )}
                  <div>
                     {Object.entries(singleinternet1).map(([key, value]) => {
                        return (
                           <div className="side-border">
                              <p className="properties-description">{value.description} </p>
                           </div>
                        );
                     })}
                  </div>

                  <div>
                     {Object.entries(singleinternet2).map(([key, value]) => {
                        return (
                           <div className="side-border">
                              <p className="properties-description">{value.description}</p>
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3).map(([key, value]) => {
                           return (
                              <div className="side-border">
                                 <p className="properties-description">{value.description}</p>
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
               {isMobileTitle && (
                  <div>
                     <p
                        className="properties-title px-2 mt-2 ms-2 mb-0 font-weight-bold"
                        style={{
                           textAlign: "left",
                           fontSize: "18px",
                           fontWeight: "600",
                           borderBottom: "2px solid #ed1c24",
                           width: "fit-content",
                           color: "#ed1c24",
                        }}
                     >
                        TV
                     </p>
                  </div>
               )}
               <div
                  className="proporties-wrapper "
                  style={isMobileTitle ? { paddingTop: "0px" } : {}}
               >
                  {!isMobileTitle && (
                     <div>
                        <p className="properties-title">TV</p>
                     </div>
                  )}
                  <div>
                     {Object.entries(singleinternet1price).map(([key, value]) => {
                        return (
                           value?.type === "12 Months" && (
                              <div className="side-border">
                                 <p className="properties-description">
                                    {singleinternet1?.data?.combo_type === "Fibernet"
                                       ? "NA"
                                       : "upto " + value.no_of_itv}{" "}
                                 </p>
                              </div>
                           )
                        );
                     })}
                  </div>
                  <div>
                     {Object.entries(singleinternet2price).map(([key, value]) => {
                        return (
                           value?.type === "12 Months" && (
                              <div className="side-border">
                                 <p className="properties-description">
                                    {singleinternet2?.data?.combo_type === "Fibernet"
                                       ? "NA"
                                       : "upto " + value.no_of_itv}{" "}
                                 </p>
                              </div>
                           )
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3price).map(([key, value]) => {
                           return (
                              value?.type === "12 Months" && (
                                 <div className="side-border">
                                    <p className="properties-description">
                                       {singleinternet3?.data?.combo_type === "Fibernet"
                                          ? "NA"
                                          : "upto " + value.no_of_itv}{" "}
                                    </p>
                                 </div>
                              )
                           );
                        })}
                     </div>
                  )}
               </div>
               <div className="button-wrapper ">
                  {!isMobileTitle && <div></div>}
                  <div>
                     {Object.entries(singleinternet1).map(([key, value]) => {
                        return (
                           <div>
                              {value.title != null ? (
                                 <Button
                                    className="plan-button"
                                    variant="secondary"
                                    onClick={() => {
                                       navigate(`/internet/${singleinternet1.data._id}`);
                                    }}
                                 >
                                    Choose This Plan
                                 </Button>
                              ) : null}
                           </div>
                        );
                     })}
                  </div>
                  <div>
                     {Object.entries(singleinternet2).map(([key, value]) => {
                        return (
                           <div>
                              {value.title != null ? (
                                 <Button
                                    variant="secondary"
                                    className="plan-button"
                                    onClick={() => {
                                       navigate(`/internet/${singleinternet2.data._id}`);
                                    }}
                                 >
                                    Choose This Plan
                                 </Button>
                              ) : null}
                           </div>
                        );
                     })}
                  </div>
                  {!isMobile && (
                     <div>
                        {Object.entries(singleinternet3).map(([key, value]) => {
                           return (
                              <div>
                                 {value.title != null ? (
                                    <Button
                                       variant="secondary"
                                       className="plan-button"
                                       onClick={() => {
                                          navigate(`/internet/${singleinternet3.data._id}`);
                                       }}
                                    >
                                       Choose This Plan
                                    </Button>
                                 ) : null}
                              </div>
                           );
                        })}
                     </div>
                  )}
               </div>
            </div>
         </Widthcontainer>
      </div>
   );
}
