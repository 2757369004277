import React, { useState, useEffect } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import "../../scss/pages/support.scss";
import "../../scss/pages/form.scss";
import { Formik } from "formik";
import Token from "../../utils/useToken";
import * as yup from "yup";
import { Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SupportImg from "../../assets/support.png";
import phoneIcon from "../../assets/phone.png";
import viberIcon from "../../assets/viber.png";
import whatsappIcon from "../../assets/whatsapp.png";
import whatsappScan from "../../assets/whatsappScan.png";
import ViberScan from "../../assets/ViberScan.png";
import { post_support } from "../../data/protectedapi";
import MessageModal from "../customcomponents/messageModal";
import { useMediaQuery } from "react-responsive";
import seoTags from "../../utils/seoTags";
import SupportForm from "../customcomponents/supportForm";

const Support = () => {
   const { userDetails } = Token();

   const [errorMessage, setErrorMessage] = useState("");
   const [name, setName] = useState(userDetails?.name);
   const [email, setEmail] = useState(userDetails?.email);
   const [customerId, setCustomerId] = useState(userDetails?.customer_id);
   const [support, setSupport] = useState("");
   const [message, setMessage] = useState("");
   const [show, setShow] = useState(false);
   const [errorShow, setErrorShow] = useState(false);
   const [contactDetails, setcontactDetails] = useState("");
   const [kycModal, setKycModal] = useState(false);
   const [cartupdate, setCartupdate] = useState(false);

   const isMobile = useMediaQuery({ query: "max-width:998px" });
   const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await post_support(name, email, customerId.toString(), support, message);
      if (response.status === 201) {
         setSupport("");
         setMessage("");
         setShow(true);
      } else {
         setSupport("");
         setMessage("");
         setErrorShow(true);
         setErrorMessage(response?.response?.data?.message);
      }
   };
   const [loginStatus, setLoginStatus] = useState(false);
   useEffect(() => {
      if (userDetails) {
         if (userDetails?.is_kyc_verified === true) {
            setKycModal(false);
         } else {
            setKycModal(true);
         }
      } else {
         setLoginStatus(true);
      }
   }, [userDetails]);

   return (
      <Layout
         setContactdetails={setcontactDetails}
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
      >
         {seoTags("Support | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}

         <Widthcontainer>
            <MessageModal
               show={show}
               setShow={setShow}
               heading="Support Registered"
               message="Thank you for contacting us"
               link="/"
               buttonText="Go Home"
            />
            <MessageModal
               show={errorShow}
               setShow={setErrorShow}
               heading="Error Occoured"
               message={errorMessage}
               link="/company/support"
               type="error"
               buttonText="Go Home"
            />
            <MessageModal
               type="error"
               show={kycModal}
               setShow={setKycModal}
               message="If you are already a customer, then please complete your profile information first to generate a support ticket. For queries other than support, please contact us."
               buttonText="Complete Profile"
               link="/dashboard?type=1"
               isNavigate={true}
               button2={"Contact Us"}
               link2="/company/contact-us"
            />
            <MessageModal
               show={loginStatus}
               setShow={setLoginStatus}
               message="Please Login to Continue"
               link="/"
               type="error"
               buttonText="Go Home"
               button2={"Login"}
               link2="/login"
               isNavigate={true}
            />
            <div className="support_container fixed-width">
               <SupportForm customerType={userDetails?.customer_type} />
               <div className="img__container">
                  <img src={SupportImg} alt="support" />
                  <p className="contact_heading">Prefer email?</p>
                  <p className="contact_subheading">
                     You can reach us at:
                     <a href={`mailto:${contactDetails?.email}`}> {contactDetails?.email}</a>
                  </p>
                  <p className="contact_heading">Prefer call?</p>
                  <p className="contact_subheading">
                     You can also reach us via:{" "}
                     <a
                        href={"tel: " + contactDetails?.support?.helpdesk?.contact_1}
                        style={{ whiteSpace: "nowrap" }}
                     >
                        {contactDetails?.support?.helpdesk?.contact_1}
                     </a>
                  </p>
               </div>
            </div>
         </Widthcontainer>
         <div className="contact_tab__container">
            <OverlayTrigger
               key={"left"}
               placement={"left"}
               overlay={
                  !isMobile && (
                     <Tooltip id={"tooltip-left"}>
                        <div
                           className="phone_details"
                           style={{
                              padding: "12px 12px",
                              backgroundColor: "#ed1c24",
                           }}
                        >
                           <p>Please call our Customer helpdesk</p>
                           <p>Toll Free No. {contactDetails?.support?.helpdesk?.tollfree}</p>
                           <p>or {contactDetails?.support?.helpdesk?.contact_1}</p>
                           <p>or {contactDetails?.support?.helpdesk?.contact_2}</p>
                        </div>
                     </Tooltip>
                  )
               }
            >
               <div
                  className="contact_tab__item phone"
                  onClick={() =>
                     window.open("tel: " + contactDetails?.support?.helpdesk?.contact_1, "_self")
                  }
               >
                  {" "}
                  {/* <a
                     className="text"
                     href={"tel: +" + contactDetails?.support?.helpdesk?.contact_1}
                  ></a> */}
                  <a className="text">Helpdesk: {contactDetails?.support?.helpdesk?.contact_1}</a>
                  <img src={phoneIcon} alt="" />
               </div>
            </OverlayTrigger>
            <OverlayTrigger
               key={"left"}
               placement={"left"}
               overlay={
                  isMobile ? null : (
                     <Tooltip id={"tooltip-left"}>
                        <div className="phone_details viber">
                           <p>Viber Support</p>
                           <img
                              src={`${process.env.REACT_APP_FILE_URL}/${contactDetails?.support?.viber?.qr}`}
                              alt=""
                           />
                           <p>
                              Scan qr code for support or viber on{" "}
                              {contactDetails?.support?.viber?.contact}
                           </p>
                        </div>
                     </Tooltip>
                  )
               }
            >
               <div
                  onClick={() =>
                     window.open("tel: " + contactDetails?.support?.viber?.contact, "_self")
                  }
                  className="contact_tab__item viber"
               >
                  <a className="text">Viber: {contactDetails?.support?.viber?.contact}</a>
                  <img src={viberIcon} alt="" />
               </div>
            </OverlayTrigger>
            <OverlayTrigger
               key={"left"}
               placement={"left"}
               overlay={
                  !isMobile && (
                     <Tooltip id={"tooltip-left"}>
                        <div className="phone_details whatsapp">
                           <p>Whatsapp Support</p>
                           <img
                              src={`${process.env.REACT_APP_FILE_URL}/${contactDetails?.support?.whatsapp?.qr}`}
                              alt=""
                              style={{ objectFit: "contain", height: "130px", width: "200px" }}
                           />
                           Scan qr code for support or whatsapp{" "}
                           {contactDetails?.support?.whatsapp?.contact}
                        </div>
                     </Tooltip>
                  )
               }
            >
               <div
                  className="contact_tab__item whatsapp"
                  onClick={() =>
                     window.open("tel: " + contactDetails?.support?.whatsapp?.contact, "_self")
                  }
               >
                  <a className="text">Whatsapp: {contactDetails?.support?.whatsapp?.contact}</a>
                  <img src={whatsappIcon} alt="" />
               </div>
            </OverlayTrigger>
            {/* */}
            {/* 
            <div className="contact_tab__item whatsapp">
               <p className="text">Whatsapp: 9860182623</p>
               <img src={whatsappIcon} alt="" />
            </div> */}
         </div>
      </Layout>
   );
};

export default Support;
