import React from "react";
import arrow from "../../assets/cart/arrow.png";
import home from "../../assets/cart/home.png";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";

export default function BreadcrumbComponent(props) {
   return (
      <div>
         <Breadcrumbs
            style={{ display: "flex", alignItems: "center" }}
            separator={<img src={arrow} />}
            aria-label="breadcrumb"
         >
            <Link
               underline="hover"
               sx={{ display: "flex", alignItems: "center" }}
               color="inherit"
               href="/"
            >
               <img src={home} />
            </Link>
            <Link id="link-breadcrumb">{props.pathname.replace("/", " ")}</Link>,
            {/* <span>{breadcrumbs.map(({ breadcrumb }) => breadcrumb[1])}</span> */}
         </Breadcrumbs>
      </div>
   );
}

export function BreadContainer(props) {
   return (
      <div className="bread-container">
         <Breadcrumbs
            style={{ display: "flex", alignItems: "center" }}
            separator={<img src={arrow} style={{ marginTop: "4px" }} />}
            aria-label="breadcrumb"
         >
            <Link
               underline="hover"
               sx={{ display: "flex", alignItems: "center" }}
               color="inherit"
               href="/"
            >
               <img src={home} />
            </Link>
            <Link href={`/${props?.mainpath}`} id="mainlink-breadcrumb">
               {props?.mainpath === "dishhome-go"
                  ? "DishHome Go"
                  : props?.mainpath.charAt(0).toUpperCase() +
                    props?.mainpath?.split(" ")[0].slice(1)}
            </Link>
            ,
            <Link
               id={props?.categoryname ? "mainlink-breadcrumb" : "redlink-breadcrumb"}
               href={`/${props?.mainpath}/${props?.pathname}`}
            >
               {props?.pathname?.charAt(0).toUpperCase() + props?.pathname?.split(" ")[0].slice(1)}
            </Link>
            ,{props?.categoryname && <Link id="redlink-breadcrumb">{props?.categoryname}</Link>}
            {/* <span>{breadcrumbs.map(({ breadcrumb }) => breadcrumb[1])}</span> */}
         </Breadcrumbs>
      </div>
   );
}
