import React, { useState, useEffect } from "react";
// import { Col } from "react-bootstrap";
import "../../scss/include/footer.scss";
// import fb from "../../assets/fb.png";
import { BsTwitter, BsInstagram, BsYoutube, BsFacebook } from "react-icons/bs";
import QuickLink from "../customcomponents/quicklink";
import AppBanner from "../customcomponents/appbanner";
import Payment from "../customcomponents/payment";
import fb from "../../assets/facebook.webp";
import twitter from "../../assets/twitter.png";
import instagram from "../../assets/instagram.png";
import youtube from "../../assets/youtube.png";
import { get_home_components } from "../../data/api";
// import appbanner from "../../assets/appbanner.png";
import { useNavigate } from "react-router-dom";
import Features from "../customcomponents/features";
const Footer = ({ cartupdate, setCartupdate, appBanner, career }) => {
   let navigate = useNavigate();
   const [homeData, setHomeData] = useState("");
   const [empty, setEmpty] = useState(true);
   useEffect(() => {
      async function fetchData() {
         const response = await get_home_components();
         setHomeData(response.data);
      }
      fetchData();
   }, [empty]);

   useEffect(() => {
      if (homeData === "" || homeData === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [homeData]);

   return (
      <div className="footer-wrapper">
         {appBanner !== "dashain" && <Features />}
         <AppBanner data={appBanner} />
         {appBanner !== "dashain" && (
            <QuickLink
               cartupdate={cartupdate}
               setCartupdate={setCartupdate}
               internetPackage={homeData?.internetPackage}
               career={career}
            />
         )}
         <Payment option={appBanner} />
         <div className="icon-footer">
            <div
               className="footericon-list cursor_pointer"
               onClick={() => {
                  window.open("https://www.facebook.com/dishhome ", "_blank");
               }}
            >
               <BsFacebook title="Facebook" />
               {/* <img src={fb} alt="Facebook" title="Facebook" /> */}
            </div>
            <div
               className="footericon-list cursor_pointer"
               onClick={() => {
                  window.open("https://twitter.com/dishhome", "_blank");
               }}
            >
               <BsTwitter title="Twitter" />
            </div>
            <div
               className="footericon-list cursor_pointer"
               onClick={() => {
                  window.open("https://www.instagram.com/dishhome.official/", "_blank");
               }}
            >
               <BsInstagram title="Instagram" />
            </div>
            <div
               className="footericon-list cursor_pointer"
               onClick={() => {
                  window.open("https://www.youtube.com/channel/UCNKKyb9-Emqvp7efxoY1yjA", "_blank");
               }}
            >
               <BsYoutube title="Youtube" />
            </div>
         </div>
         <div className="footer ">
            <div
               className="fixed-width75"
               style={{ display: "flex", justifyContent: "space-between" }}
            >
               <div className="first-footer">
                  <li
                     className="footer-text"
                     onClick={() => {
                        navigate("/pages/termsandcondition");
                     }}
                  >
                     Term of Use
                  </li>

                  <li
                     className="footer-text"
                     onClick={() => {
                        // window.open("https://blog.dishhome.com.np/?page_id=3", "_blank");
                        navigate("/privacy-policy");
                     }}
                  >
                     Privacy Policy
                  </li>
                  <li
                     onClick={() => {
                        // window.open("https://blog.dishhome.com.np/?page_id=3", "_blank");
                        navigate("/pages/refundpolicy");
                     }}
                     className="footer-text"
                  >
                     Refund Policy
                  </li>
                  <li
                     onClick={() => {
                        // window.open("https://blog.dishhome.com.np/?page_id=3", "_blank");
                        navigate("/pages/paymentpartners");
                     }}
                     className="footer-text"
                  >
                     Payment Partners{" "}
                  </li>
               </div>
               <div className="icon-footer">
                  <div
                     className="footericon-list cursor_pointer"
                     onClick={() => {
                        window.open("https://www.facebook.com/dishhome ", "_blank");
                     }}
                  >
                     <img id="footer_social_icon" src={fb} title="Facebook" alt="Facebook" />
                     {/* <BsFacebook /> */}
                  </div>
                  <div
                     className="footericon-list cursor_pointer"
                     onClick={() => {
                        window.open("https://twitter.com/dishhome", "_blank");
                     }}
                  >
                     {/* <BsTwitter /> */}
                     <img id="footer_social_icon" src={twitter} title="Twitter" alt="Twitter" />
                  </div>
                  <div
                     className="footericon-list cursor_pointer"
                     onClick={() => {
                        window.open("https://www.instagram.com/dishhome.official/", "_blank");
                     }}
                  >
                     <img
                        id="footer_social_icon"
                        src={instagram}
                        title="Instagram"
                        alt="Instagram"
                     />

                     {/* <BsInstagram /> */}
                  </div>
                  <div
                     className="footericon-list cursor_pointer"
                     onClick={() => {
                        window.open(
                           "https://www.youtube.com/channel/UCNKKyb9-Emqvp7efxoY1yjA",
                           "_blank",
                        );
                     }}
                  >
                     <img id="footer_social_icon" src={youtube} alt="Youtube" title="Youtube" />
                     {/* <BsYoutube /> */}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Footer;
