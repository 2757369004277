import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../scss/components/payment.scss";
import esewa from "../../assets/esewa.webp";
import prabhupay from "../../assets/prabhupay.png";
import paypoint from "../../assets/paypoint.png";
import khalti from "../../assets/khalti.png";
import connectips from "../../assets/connectips.webp";
import imepay from "../../assets/imepay.png";
import { get_company_details } from "../../data/api";
import { format } from "date-fns";
import Logo from "../../assets/offer/image/files/logo.png";

export default function Payment({ option }) {
   let navigate = useNavigate();
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);
   // console.log(contactDetails);
   return (
      <div className="payment_container mb-3 ">
         <div className="container fixed-width">
            <div className="payment">
               <div className="payment_child1">
                  {option === "dashain" ? (
                     <img src={Logo} style={{ marginBottom: "8px" }} />
                  ) : (
                     <p
                        className="payment_title cursor_pointer"
                        onClick={() => {
                           navigate("/");
                        }}
                     >
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${contactDetails?.image}`}
                           alt="logo"
                           width="40px"
                        />{" "}
                        <span style={{ color: "#192638" }}> Dish</span>Home
                     </p>
                  )}
                  {/* <p className="payment_description">Entertainment and ISP Private Company</p> */}
                  <p className="payment_description">
                     © DishHome {format(new Date(), "yyyy")}, Made with ❤️ in Nepal
                  </p>
               </div>

               <div className="payment_wrapper">
                  <p className="payment_text">Payment Partners</p>
                  <div className="payment_partners">
                     {!contactDetails?.disable_imepay && (
                        <div
                           className="payment_option"
                           onClick={() => {
                              window.open("https://www.imepay.com.np/", "_blank");
                           }}
                        >
                           <img id="payment_hover" src={imepay} alt="payment" />
                        </div>
                     )}
                     {!contactDetails?.disable_esewa && (
                        <div
                           className="payment_option"
                           onClick={() => {
                              window.open("https://esewa.com.np/", "_blank");
                           }}
                        >
                           <img id="payment_hover" src={esewa} alt="payment" />
                        </div>
                     )}
                     {!contactDetails?.disable_prabhupay && (
                        <div
                           className="payment_option"
                           onClick={() => {
                              window.open("https://www.prabhupay.com/", "_blank");
                           }}
                        >
                           <img id="payment_hover" src={prabhupay} alt="payment" />
                        </div>
                     )}
                     {/* {!contactDetails?.disable_p <div
                        className="payment_option"
                        onClick={() => {
                           window.open("https://paypoint.com.np/", "_blank");
                        }}
                     >
                        <img src={paypoint} alt="payment" />
                     </div>} */}
                     {!contactDetails?.disable_khalti && (
                        <div
                           className="payment_option"
                           onClick={() => {
                              window.open("https://khalti.com/", "_blank");
                           }}
                        >
                           <img id="payment_hover" src={khalti} alt="payment" />
                        </div>
                     )}
                     {!contactDetails?.disable_connectips && (
                        <div
                           className="payment_option"
                           onClick={() => {
                              window.open("https://www.connectips.com/", "_blank");
                           }}
                        >
                           <img id="payment_hover" src={connectips} alt="payment" />
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
