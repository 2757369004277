import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Loading from "../components/customcomponents/loading";
import { check_enterprise_user, get_support_list } from "../data/protectedapi";
import "../scss/pages/supportHistory.scss";
import ReactPaginate from "react-paginate";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { get_enterprise_issue_type } from "../data/api";

const DashboardSupports = () => {
   let navigate = useNavigate();
   const [allSupportData, setAllSupportData] = useState("");
   const [supportData, setSupportData] = useState("");
   const [loading, setLoading] = useState(true);
   const [pageCount, setPageCount] = useState(0);
   const [flag, setFlag] = useState(false);
   const [issueTypes, setIssueTypes] = useState();
   useEffect(() => {
      async function fetchData() {
         const response = await check_enterprise_user();
         setFlag(response?.data?.isEnterpriseCustomer);
      }
      async function getData() {
         const response = await get_support_list(false);
         setAllSupportData(response?.data?.data?.result?.reverse());
         setPageCount(Math.ceil(response?.data?.data?.result?.length / 10));
         setSupportData(response?.data?.data?.result?.slice(0, 10));
      }
      async function getEnterpriseData() {
         const response = await get_support_list(true);
         setAllSupportData(response?.data?.data);
         setPageCount(Math.ceil(response?.data?.data?.length / 10));
         setSupportData(response?.data?.data?.slice(0, 10));
      }
      async function fetchIssueTypes() {
         const response = await get_enterprise_issue_type();
         setIssueTypes(response?.data?.data);
      }
      fetchData();
      flag && fetchIssueTypes();
      flag ? getEnterpriseData() : getData();
   }, [flag]);

   useEffect(() => {
      if (supportData === "" || supportData === undefined) {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [supportData]);

   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 10;
      const newArray = allSupportData.slice(offset, offset + 10);
      setSupportData(newArray);
   };
   return loading ? (
      <Loading />
   ) : (
      <div className="support_history_wrapper">
         <div id="support-request">
            <Button
               variant="secondary"
               id="dashboardform-btn"
               onClick={() => navigate("/company/support")}
            >
               Request Ticket
            </Button>
         </div>
         <p className="support_history_title">Support History</p>
         <p className="support_sub_heading">See all of your support request </p>
         {allSupportData?.length !== 0 &&
            (flag ? (
               <div className="support_heading">
                  <p className="support_heading_title">Appointment Date</p>
                  <p className="support_heading_title">Customer ID</p>
                  <p className="support_heading_title">Ticket Status</p>
                  <p className="support_heading_title">Ticket Category</p>
               </div>
            ) : (
               <div className="support_heading">
                  <p className="support_heading_title">Date</p>
                  <p className="support_heading_title">Ticket No.</p>
                  <p className="support_heading_title">Ticket Status</p>
                  <p className="support_heading_title">Ticket Category</p>
               </div>
            ))}
         {Object.entries(supportData).map(([key, value]) => {
            return flag ? (
               <div className="support_history_item">
                  <p className="support_heading_title">
                     {value?.enterpriseSupportDetails?.appointmentDate &&
                        format(
                           new Date(value?.enterpriseSupportDetails?.appointmentDate),
                           "yyyy-MM-dd HH:mm",
                        )}
                  </p>
                  <p className="support_heading_title">{value?.customer_id}</p>
                  <p className="support_heading_title">{value?.status}</p>
                  <p className="support_heading_title">
                     {
                        issueTypes?.find(
                           (item) =>
                              item?.Id === parseInt(value?.enterpriseSupportDetails?.issueTopic),
                        )?.IssueName
                     }
                  </p>
               </div>
            ) : (
               <div className="support_history_item">
                  <p className="support_heading_title">
                     {format(new Date(value?.TicketCreatedDate), "yyyy-MM-dd")}
                  </p>
                  <p className="support_heading_title">{value?.TicketNo}</p>
                  <p className="support_heading_title">{value?.TicketStatus}</p>
                  <p className="support_heading_title">{value?.TicketSubcategory}</p>
               </div>
            );
         })}
         {allSupportData?.length === 0 && (
            <div className="no_support_history">
               <p className="no_support_history_text">No support history found</p>
            </div>
         )}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
      </div>
   );
};

export default DashboardSupports;
