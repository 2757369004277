import React from "react";
import { Navigate, useNavigate } from "react-router";
import HeroImage from "../../assets/hero_img.png";
import "../../scss/components/herobanner.scss";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";
import { useMediaQuery } from "react-responsive";

const HeroBanner = ({ data, type, muted }) => {
   //For Change Banner Style
   let banner_change = false;
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

   //Condition for Banner Change
   if (data === undefined) {
      banner_change = false;
   } else if (data.page_location === "tv_bottom") {
      banner_change = true;
   }
   let navigate = useNavigate();

   const ComponentToTrack = (props) => {
      let navigate = useNavigate();
      const mobile = useMediaQuery({ query: "(max-width: 767px)" });

      return (
         <div
            className="slideBanner__player-wrapper"
            style={{
               height: "25px",
            }}
         >
            <ReactPlayer
               url={`${process.env.REACT_APP_FILE_URL}/${
                  props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                     ? props?.data?.mobile_media
                     : props?.data?.media
               }`}
               playing={props?.isVisible}
               loop={true}
               width="100%"
               height="100%"
               className="react-player"
               muted={
                  props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                     ? props?.data?.mobile_mute
                     : props?.data?.mute
               }
               volume={"0.5"}
               style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: "1",
                  pointerEvents: "none",
                  objectFit: "cover",
               }}
               config={{
                  youtube: {
                     playerVars: {
                        autoplay: 1,
                        rel: 0,
                        controls: 0,
                        showinfo: 0,
                        disablekb: 1,
                        modestbranding: 1,
                     },
                  },
               }}
               playsinline
            />
         </div>
      );
   };

   return (
      <div
         className="wrapper"
         style={{ position: "relative" }}
         // style={
         //    data?.media_type === "video" && data?.mobile_media_type !== "image"
         //       ? {
         //            backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${data.thumbnail})`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         //       : data?.media === undefined
         //       ? {
         //            backgroundImage: `url(${HeroImage})`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         //       : {
         //            backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${
         //               isMobile && data?.mobile_media ? data?.mobile_media : data.media
         //            })`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         // }
      >
         <img
            src={
               data?.media_type === "video" && data?.mobile_media_type !== "image"
                  ? `${process.env.REACT_APP_FILE_URL}/${data.thumbnail}`
                  : data?.media === undefined
                  ? HeroImage
                  : `${process.env.REACT_APP_FILE_URL}/${
                       isMobile && data?.mobile_media ? data?.mobile_media : data.media
                    }`
            }
            style={{ width: "100%", position: "absolute", height: "100%" }}
            alt=""
         />
         {!isMobile && (
            <TrackVisibility>
               <ComponentToTrack data={data} />
            </TrackVisibility>
         )}
         <div class="container col-xxl-8 py-4 fixed-width">
            {isMobile && (
               <TrackVisibility>
                  <ComponentToTrack data={data} />
               </TrackVisibility>
            )}
            <div class="row flex-lg-row-reverse align-items-center g-5 pb-5 px-2 ">
               <div
                  class="col"
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     zIndex: "1",
                     alignItems:
                        data?.text_align == "left"
                           ? "flex-start"
                           : data?.text_align == "right"
                           ? "flex-end"
                           : "center",
                  }}
               >
                  <h1
                     class="display-5 fw-bold lh-1 mb-3"
                     style={{ maxWidth: "460px", color: data?.text_color }}
                  >
                     {/* Save up to 25% in our HD Premium Packages */}
                     {data === undefined ? "We Provide Super HD TV Package For You." : data.caption}
                  </h1>
                  <p
                     class="lead"
                     style={{
                        maxWidth: "450px",
                        color: data?.text_color,
                     }}
                  >
                     {/* 50+ HD channels with all new features with 24x7
                            support by our awesome teams */}
                     {data === undefined
                        ? "50+ HD channels with all new features with 24x7 support by our awesome teams"
                        : data.sub_caption}
                  </p>
                  <div class="d-grid gap-2 d-md-flex justify-content-md-start py-4">
                     {data?.button_type !== "" && (
                        <button
                           type="button"
                           class="btn  btn-lg px-4 me-md-2"
                           style={
                              banner_change
                                 ? {
                                      color: "#fff",
                                      background: "#ED1C24",
                                      borderRadius: "12px",
                                      width: "fit-content",
                                   }
                                 : {
                                      backgroundColor: data?.button_bg_color,
                                      color: data?.button_text_color,
                                      width: "fit-content",
                                   }
                           }
                           onClick={() => {
                              data === undefined ? navigate("/") : navigate(data.external_link);
                           }}
                        >
                           {data === undefined ? "Order Now" : data.button_type}{" "}
                        </button>
                     )}
                     {/* {banner_change || type === "tv_top" ? (
                        <button
                           type="button"
                           class="btn btn-primary btn-lg px-4 me-md-2"
                           style={
                              banner_change
                                 ? {
                                      color: "#374253",
                                      background: "#fff",
                                      border: "1.2px solid #374253",
                                      borderRadius: "12px",
                                   }
                                 : type === "tv_top"
                                 ? {
                                      backgroundColor: "transparent",
                                      color: "#FFF",
                                      border: "1px solid #FFFFFF",
                                      borderRadius: "60px",
                                   }
                                 : {}
                           }
                        >
                           Learn More
                        </button>
                     ) : null} */}
                  </div>
               </div>
            </div>
         </div>
         <div></div>
      </div>
   );
};

export default HeroBanner;
