import React from "react";
import HD from "../../assets/package/hd.png";
import "../../scss/components/infocard.scss";

const InfoCard = ({ color, src, title, description }) => {
   return (
      <div className={"infocard_wrapper px-2 py-4 my-4 " + color}>
         <div className="img">
            <img src={src} alt="" />
         </div>
         <h4 className="title">{title}</h4>
         <p className="description">{description}</p>
      </div>
   );
};

export default InfoCard;
