import { valueToPercent } from "@mui/base";
import React from "react";
// import { Col, Row } from "react-bootstrap";
import man from "../../assets/store/sitting.png";
import "../../scss/store/streamingbanner.scss";
import { useNavigate } from "react-router";

export default function StreamingBanner({ data }) {
   let navigate = useNavigate();
   return (
      <div className="streaming_banner">
         <div className="container fixed-width">
            <div
               className="streaming_wrapper"
               onClick={() => {
                  navigate(`${data.external_link}`);
               }}
            >
               <div style={{ paddingLeft: "33px" }}>
                  {/* <p id="streaming-watch">{data.button_type}</p> */}
                  <p id="streaming-title">
                     {/* Popular Tv Show <span style={{ color: "red" }}>Sports & Live</span> Streaming */}
                     {data.caption}
                  </p>
                  <p id="streaming-subtitle">{data.sub_caption}</p>
               </div>
               <div style={{ flexBasis: "40%" }}>
                  <img
                     src={`${process.env.REACT_APP_FILE_URL}/${data.media}`}
                     style={{ width: "100%" }}
                     alt="streaming"
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
