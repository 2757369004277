import React, { useState, useEffect, useRef, useMemo } from "react";
import Layout from "../../../components/include/layout";
import {
   get_company_details,
   get_internet_package,
   get_shipping_charge,
   get_single_internet,
} from "../../../data/api";
import { check_footprint, get_bonus_list, recharge } from "../../../data/protectedapi";
import Dropdown from "react-dropdown";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import useToken from "../../../utils/useToken";
import { Alert, InputGroup } from "react-bootstrap";
import { MdError } from "react-icons/md";
import RadioGroup from "@mui/material/RadioGroup";
import myKey from "../../../store/pages/khaltiKey";
import FormControl from "@mui/material/FormControl";
import municipality from "../../../data/location/municipality.json";
import "../../../scss/pages/form.scss";
import "../../../scss/packages/order.scss";
import { MapContainer, useMap, Marker, Popup, TileLayer, useMapEvent } from "react-leaflet";
import axios from "axios";
import L from "leaflet";
import KhaltiCheckout from "khalti-checkout-web";
import esewa from "../../../assets/store/esewa.webp";
import ips from "../../../assets/store/ips.png";
import paypoint from "../../../assets/store/paypoint.png";
import prabhu from "../../../assets/store/prabhu.png";
import ime from "../../../assets/store/ime.png";
import khalti from "../../../assets/cart/khalti.png";
import Radio from "@mui/material/Radio";
import { Button, Form } from "react-bootstrap";
import MessageModal from "../../../components/customcomponents/messageModal";
import Loading from "../../../components/customcomponents/loading";
import { border } from "@mui/system";
import { FormControlLabel } from "@mui/material";
import SeoTags from "../../../utils/seoTags";
import { get_redeem_point, get_voucher_discount } from "../../../data/protectedapi";
import RewardPoint from "../../../components/customcomponents/rewardPoint";
import RemainingReward from "../../../components/customcomponents/remainingReward";
import { handleOnlinePayment } from "../../../components/pages/payment/payment";
import { BiSearchAlt } from "react-icons/bi";
import { Tooltip } from "react-leaflet/Tooltip";
import { useMediaQuery } from "react-responsive";

export default function InternetPackageOrder() {
   //value from query params
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let period = query.get("type");
   let navigate = useNavigate();
   const { token } = useToken();
   const { userDetails } = useToken();
   const parameter = useParams();
   const { id } = parameter;

   //acessToken
   const accessToken = token.accessToken;
   const mobileno = userDetails.phone.toString();
   //useStates
   const [discountamount, setDiscountamount] = useState(0);
   const [totaldiscount, setTotaldiscount] = useState(0);
   const [internetdata, setinternetData] = useState("");
   const [singleinternet, setSingleinternet] = useState("");
   const [dataprice, setDataprice] = useState(0);
   const [order, setOrder] = useState(false);
   const [messageModal, setMessageModal] = useState(false);
   const [message, setMessage] = useState("");
   const [redeemAmounttoPay, setRedeemAmounttoPay] = useState(0);

   const [error, setError] = useState(false);
   const [paymentmodal, setpaymentmodal] = useState(false);
   const [paymentProceed, setPaymentproceed] = useState(false);
   const [datainstallation, setDatainstallation] = useState("");
   const [wireCharge, setWireCharge] = useState("");
   const [routerRentalCharge, setRouterRentalcharge] = useState("");

   const [shippingCharge, setShippingcharge] = useState("");
   const [priceid, setPriceid] = useState();
   const [rememberMe, setRememberme] = useState(false);
   const [redeem, setRedeem] = useState("");
   const [packageType, setPackageType] = useState(period);

   const [iptv, setIptv] = useState();
   const [heading, setHeading] = useState("");
   const [packagetitle, setPackagetitle] = useState("");
   const [packageId, setPackageId] = useState("");
   const [packageName, setPackageName] = useState("");
   const [tvName, setTvName] = useState("0");
   const [rechargeamount, setRechargeAmount] = useState(0);

   const [value, setValue] = useState("location");
   const [errorMessage, setErrorMessage] = useState("");
   const [position, setPosition] = useState({ lat: 27.7172, lng: 85.324 });
   const [vouchername, setVouchername] = useState("");
   const mapLat = position?.lat;
   const mapLng = position?.lng;
   const [voucherdetails, setVoucherDetails] = useState("");

   const [billingname, setBillingName] = useState(
      userDetails.billing_address.name ? userDetails.billing_address.name : "",
   );
   const [provincename, setProvincename] = useState("");
   const [filterededitAddress, setFilterededitAddress] = useState([]);
   const [shippingDetails, setShippingDetails] = useState("");
   const [address, setAddress] = useState("");
   const [landmark, setLandmark] = useState("");
   const [billingaddress, setBillingAddress] = useState("");
   const [street, setStreet] = useState(
      userDetails.billing_address.street ? userDetails.billing_address.street : "",
   );
   const [redeemDiscount, setRedeemdiscount] = useState("");

   const [vouchercode, setVouchercode] = useState("");
   const [voucherid, setVoucherid] = useState("");
   const [totalPrice, setTotalprice] = useState(0);
   const [price, setPrice] = useState("");
   const [btnloading, setBtnloading] = useState(false);
   const [esewaStatus, setesewaStatus] = useState(false);
   const [prabhuStatus, setprabhuStatus] = useState(false);
   const [connectIpsStatus, setconnectIpsStatus] = useState(false);
   const [imeStatus, setimeStatus] = useState(false);

   const [khaltiStatus, setkhaltiStatus] = useState(false);
   const [paymentgateway, setPaymentgateway] = useState("");
   const [loading, setIsLoading] = useState(false);
   const [numbertv, setNumbertv] = useState("");
   const [cashActive, setCashActive] = useState(false);
   const [BeforeDiscount, setBeforeDiscount] = useState(0);
   //react dropdown variables
   let internetOptions = [];
   let rechargeoptions = ["0"];
   const defaultOption = rechargeoptions[0];
   // let internetTv = [];
   const [internetTv, setInternetTv] = useState("");

   const tvdefaultOption = internetTv[0];

   let districtOption1 = [];
   let municipalityOption = [];

   //calculation of total ans subtotal

   //remaining states
   //recharge option
   // const [bonusdata, setBonusData] = useState("");
   // useEffect(() => {
   //    async function get_bonus() {
   //       const res = await get_bonus_list();
   //       const response = res?.data?.data;
   //       if (response === undefined) {
   //          setBonusData("");
   //       } else {
   //          setBonusData(response);
   //       }
   //    }
   //    get_bonus();
   // }, []);

   const [packageplan, setPackageplan] = useState(period);

   //useEffects

   useEffect(() => {
      async function shipping_charge() {
         const res = await get_shipping_charge();
         const response = res?.data?.data;

         setShippingDetails(response);
      }
      shipping_charge();
   }, []);

   const provinceoptions = ["1", "2", "3", "4", "5", "6", "7"];

   useEffect(() => {
      async function get_internetpackage() {
         const res = await get_internet_package();
         const response = res?.data?.data;
         setinternetData(response);
      }
      get_internetpackage();
   }, []);

   //select no, of tv
   const [nooftv, setNooftv] = useState("1");
   useEffect(() => {
      setInternetTv("");
      setTvName(1);
      for (let i = 0; i < nooftv; i++) {
         setInternetTv((old) => [...old, { label: (i + 1).toString(), value: i + 1 }]);

         // tvoptions.push({ label: (i + 1).toString(), value: i + 1 });
      }
   }, [nooftv, period]);
   console.log("tv_inter", internetTv.length);
   useEffect(() => {
      async function get_single() {
         setRedeem(0);
         setRedeemdiscount(0);
         const res = await get_single_internet(id);
         const response = res?.data?.data;
         setPrice(response?.prices);
         setPackagetitle(response.title);
         setSingleinternet(response);
      }
      get_single();
   }, [id]);
   const [displaylocation, setDisplayLocation] = useState("");

   useEffect(() => {
      if (position) {
         const latlng = position;
         const GEOCODE_URL =
            "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";
         const url = `${GEOCODE_URL} + ${latlng.lng},${latlng.lat}`;

         fetch(url)
            .then((res) => res.json())
            .then((data) => {
               const address = data?.address;

               if (address) setDisplayLocation(address);
            });
      }
   }, [position]);
   // console.log("loc", displaylocation);

   useEffect(() => {
      let newfilterededitaddress = [];
      [...shippingDetails].map((item) => {
         if (item.province === provincename) {
            newfilterededitaddress.push(item);
            setFilterededitAddress(newfilterededitaddress);
         } else {
            setFilterededitAddress(newfilterededitaddress);
         }
         return null;
      });
   }, [provincename]);

   const [additionalcharge, setAdditionalcharge] = useState(0);
   const [additionalstbcharge, setAdditionalstbcharge] = useState(0);
   const [stbcharge, setstbcharge] = useState(0);
   const [charge, setcharge] = useState(0);

   const [routercharge, setRoutercharge] = useState(0);
   let totalinternetcharge;
   let totaltvcharge;
   let total;

   useEffect(() => {
      [...price].map((item) => {
         // console.log("item", item);
         if (item.type === packageplan) {
            // setDataprice(item?.price);
            setDataprice(item?.internet_price);
            setDatainstallation(item?.installation_charge);
            setWireCharge(item?.drop_wire);
            setRouterRentalcharge(item?.router);
            setAdditionalcharge(item?.additional_itv_price);
            setNooftv(item?.no_of_itv);
            setAdditionalstbcharge(item?.additional_itv_stb_cost);
            setcharge(item?.itv_price);
            setstbcharge(item?.itv_stb_charge);
            setIptv(item?.iptv_installation_charge);
            setPriceid(item?._id);
            setRoutercharge(item?.router);
            // total = item.price + item.installation_charge;
            total =
               item?.itv_price +
               item?.itv_stb_charge +
               item?.installation_charge +
               item?.internet_price +
               item?.drop_wire +
               item?.router;
            setBeforeDiscount(total);
            setTotalprice(totaldiscount ? total - totaldiscount : total);
         }
      });
   }, [price, packageplan, totaldiscount, nooftv, tvName]);
   // console.log("price", total);
   console.log("before", BeforeDiscount);
   const costOfTv = additionalcharge * (tvName - 1);
   const costofSTB = additionalstbcharge * (tvName - 1);

   const subtotal = totalPrice + parseInt(rechargeamount) + costOfTv + costofSTB;
   // const totalPricetoPay = totalPrice + costOfTv + routercharge;
   const totalPricetoPay = totalPrice + costOfTv + costofSTB;

   const totalPriceBeforeDiscount = BeforeDiscount + costOfTv + costofSTB;

   //calculation of vat
   const [vatAmount, setVatAmount] = useState(0);
   const [initialVat, setInitialVat] = useState(0);
   useEffect(() => {
      rememberMe === true && redeemAmounttoPay !== null ? (
         // <>{setVatAmount((13 / 100) * redeemAmounttoPay)}</>
         <>{setVatAmount(Math.round((13 / 100) * redeemAmounttoPay))}</>
      ) : (
         <>
            {setVatAmount(Math.round((13 / 100) * totalPricetoPay))}
            {setInitialVat(Math.round((13 / 100) * totalPricetoPay))}
         </>
      );
   }, [totalPricetoPay, redeemAmounttoPay, rememberMe]);

   console.log("vat", vatAmount);
   console.log("redeem", rememberMe);

   const totalPricetoPayAfterRecharge = totalPricetoPay + Math.floor(rechargeamount);
   console.log("total_price", redeemDiscount);
   console.log("total_price", additionalcharge);

   //payment integration

   const subTotal = (totalPrice * 100) / 113;
   const tax = totalPrice - subTotal;

   const totalAmount = tax + subTotal;

   //functions
   const handlevoucherChange = (e) => {
      setVouchername(e.target.value);
   };
   const orderNow = () => {
      if (value === "map") {
         // setOrder(true);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
      }
      if (billingname === "" || billingaddress === "" || street === "") {
         setErrorMessage("Please fill all the fields");
      }
      if (value === "map") {
         // setOrder(true);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
      } else if (
         billingname === "" ||
         billingaddress === "" ||
         street === "" ||
         provincename === "" ||
         address === "" ||
         landmark === ""
      ) {
         setErrorMessage("Please fill all the fields");
      } else {
         // setOrder(true);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
      }
   };

   const handlePackageplan = (option) => {
      setRememberme(false);
      navigate(`/internetpackage/order/${id}?type=${option.label}`);
      setVouchercode("");
      setVoucherid("");
      setVouchermessage("");
      setDiscountamount(0);
      setPackageplan(option.label);
   };
   console.log("voucher", totalPriceBeforeDiscount);
   const [errorVoucher, seterrorVoucher] = useState(false);
   const [successVoucher, setsuccessVoucher] = useState(false);
   const [vouchermessage, setVouchermessage] = useState("");
   const [voucherActive, setVoucheractive] = useState(false);

   const sendVoucher = async () => {
      console.log("voucher", totalPriceBeforeDiscount);
      const response = await get_voucher_discount(
         totalPriceBeforeDiscount,
         vouchername,
         "Internet",
         id,
      );
      setRememberme(false);
      if (response?.status === 200) {
         setVoucheractive(true);
         setVoucherDetails(response);
         setVoucherid(response?.data?.data?.voucherId);
         setVouchercode(response?.data?.data?.voucherCode);
         setDiscountamount(Math.round(response?.data?.data?.discountAmount));
         setVouchermessage(response?.data?.message);

         setsuccessVoucher(true);
         setTimeout(() => {
            setsuccessVoucher(false);
         }, 5000);
         seterrorVoucher(false);
      } else {
         setVoucheractive(false);
         seterrorVoucher(true);
         setVouchermessage(response?.response?.data?.message);
         setTimeout(() => {
            seterrorVoucher(false);
         }, 5000);
         setsuccessVoucher(false);
      }
   };

   const handleChangePackage = (option) => {
      setRememberme(false);
      setVoucherDetails("");
      setVoucherid("");
      setVouchercode("");
      setDiscountamount(0);
      setVouchermessage("");
      setPackageId(option.value);
      setPackageName(option.label);
      navigate("/internetpackage/order/" + option.value);
   };
   const handlePlans = (option) => {
      setRechargeAmount(option.label);
   };
   const handleChangePackage1 = (option) => {
      setVoucherDetails("");
      setVoucherid("");
      setVouchercode("");
      setDiscountamount(0);
      setVouchermessage("");
      setRememberme(false);
      setTvName(option.label);
   };
   const handleChange = (event) => {
      setValue(event.target.value);
   };
   console.log("des", discountamount);
   const handleProvince = (option) => {
      setProvincename(option.label);
   };
   const handleDistrict = (option) => {
      setAddress(option.label);
   };
   const handleLandmark = (option) => {
      setLandmark(option.label);
   };

   const [location, setLocation] = useState("");
   //reward point
   const [redeemModal, setRedeemmodal] = useState(false);
   const [redeemErrorModal, setRedeemErrormodal] = useState(false);
   const handleReedem = async (e) => {
      setRememberme(e.target.checked);
      if (e.target.checked) {
         const response = await get_redeem_point(totalPricetoPayAfterRecharge);

         if (response?.status === 200) {
            // setTotalprice("");
            setRedeem(response?.data?.usedReward);
            setRedeemAmounttoPay(Math.round(response?.data?.additionalAmountToPay));
            setRedeemdiscount(Math.floor(response?.data?.discount));
            setMessage(response?.data?.message);
         } else {
            setRedeemErrormodal(true);
            setMessage(response?.response?.data?.message);
         }
      }
   };
   useEffect(() => {
      if (rememberMe === false) {
         setRedeemdiscount(0);
         setRedeem(0);
         setRedeemAmounttoPay(0);
      }
   }, [rememberMe]);
   console.log("me", rememberMe);

   useEffect(() => {
      if (discountamount >= 0) {
         setTotaldiscount(discountamount);
      }
      if (redeemDiscount >= 0) {
         setTotaldiscount(redeemDiscount);
      }
      if (discountamount >= 0 && redeemDiscount >= 0) {
         setTotaldiscount(discountamount + redeemDiscount);
      }
   }, [discountamount, redeemDiscount]);
   console.log("sub", totalPricetoPayAfterRecharge, totaldiscount);

   console.log("remember", totaldiscount, redeemDiscount, discountamount, rememberMe);
   console.log("remember", vatAmount);

   const [errorPyament, setErrorPayment] = useState(false);
   const [checkPayment, setCheckpayment] = useState(false);

   const handleOrder = () => {
      setIsLoading(true);
      const numberrecharge = Math.floor(rechargeamount);
      const config = {
         headers: { Authorization: `Bearer ${accessToken}` },
      };

      let bodyParameters = {
         package_name: packagetitle,
         package_id: id,
         full_name: userDetails.name,
         email: userDetails.email,
         phone: mobileno.toString(),
         recharge_amount: numberrecharge,
         vat: vatAmount,
         no_of_tv: internetTv?.length > 0 ? tvName : 0,
         is_paid: false,
         billing_address: {
            name: billingname,
            address: displaylocation?.Address ? displaylocation?.Address : displaylocation?.City,
            street: street,
            landmark: landmark,
            district: address,
            province: provincename,
            coordinates: position,
         },
         voucher: {
            name: vouchercode ? vouchercode : null,
            voucherId: voucherid ? voucherid : null,
         },
         shipping_address: {
            name: billingname,
            address: displaylocation?.Address ? displaylocation?.Address : displaylocation?.City,
            street: street,
            landmark: landmark,
            district: address ? address : location?.Subregion,
            province: provincename,
            coordinates: position,
         },
         payment_type: packageplan,
         reward: redeem,

         package_type: "internet",
         sub_total:
            redeemAmounttoPay !== 0
               ? Math.round(redeemAmounttoPay)
               : Math.round(totalPricetoPayAfterRecharge),
         discount: Math.floor(totaldiscount),
         price: dataprice,
         installation_charge: datainstallation,
         drop_wire: wireCharge,
         router: routerRentalCharge,
         settopbox_price: iptv,
         additional: costOfTv + costofSTB,
         additional_itv_price: charge,
         additional_itv_stb_cost: stbcharge,
         total:
            redeemAmounttoPay !== 0
               ? Math.floor(redeemAmounttoPay + vatAmount)
               : Math.floor(totalPricetoPayAfterRecharge + vatAmount),
         payment_method: paymentgateway ? paymentgateway : "cod",
         status: "new",
      };

      //get user current location

      const bodyFormData = new FormData();
      bodyFormData.append("data", bodyParameters);
      axios
         .post(
            process.env.REACT_APP_BASE_URL + "v1/package/order/order-package",
            bodyParameters,
            config,
         )
         .then(async (res) => {
            if (res.status === 201) {
               // if (res?.data?.data?.payment_method === "khalti") {
               //    setMessage(
               //       "Thank you for your purchase! You will get a call from our team shortly for installation",
               //    );
               //    setHeading("Purchase Successful");
               //    const data = await handleOnlinePayment({
               //       order_id: res?.data?.data?.orderId,
               //       payment_method: res?.data?.data?.payment_method,
               //       order_name: res?.data?.data?.order_name,
               //       uid: userDetails?.id,
               //       total: res?.data?.data?.total,
               //       setSuccess: setMessageModal,
               //    });
               //    const checkout = new KhaltiCheckout(data);
               //    checkout.show({ amount: res?.data?.data?.total * 100 });
               //    setIsLoading(false);
               // } else
               if (res?.data?.data?.payment_method === "cod") {
                  setIsLoading(false);
                  setMessageModal(true);
                  setMessage(
                     "Thank you for your purchase! You will get a call from our team shortly for confirmation and installation",
                  );
                  setHeading("Purchase Successful");
               } else
                  handleOnlinePayment({
                     order_id: res?.data?.data?.orderId,
                     payment_method: res?.data?.data?.payment_method,
                     order_name: res?.data?.data?.order_name,
                     total: res?.data?.data?.total,
                     uid: userDetails?.id,
                  });

               // setMessageModal(true);
               // setCartloading(false);
            } else {
               setIsLoading(false);
               setBtnloading(false);
               setError(true);
            }
         })
         .catch((err) => {
            setIsLoading(false);
            setBtnloading(false);
            setMessage(err?.response?.data?.message);
            setError(true);
         });
   };
   const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

   //check footprint
   const [checkFootprint, setCheckFootprint] = useState(false);
   const [footprinterror, setfootprintError] = useState(false);
   const handleFootprint = async () => {
      setIsLoading(true);
      const res = await check_footprint(
         id,
         packagetitle,
         userDetails.name,
         userDetails.email,
         mobileno.toString(),
         "0",
         numbertv ? numbertv : 0,
         billingname,
         billingaddress,
         provincename,
         street,
         landmark,
         address,
         dataprice,
         datainstallation,
         redeemAmounttoPay !== null ? redeemAmounttoPay : subtotal,
         iptv,
         paymentgateway ? paymentgateway : "cod",
         additionalcharge,
         position,
         "Internet",
      );
      if (res?.statusText === "OK") {
         setIsLoading(false);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
         setOrder(true);
         setCheckFootprint(true);
      }
      if (res?.response?.statusText === "Forbidden") {
         setIsLoading(false);
         window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
         });
         setOrder(true);
         setCheckFootprint(false);
      }
      if (res?.response?.statusText === "Not Found") {
         setIsLoading(false);
         setfootprintError(true);
         setCheckFootprint(false);
      } else {
         setIsLoading(false);
      }
   };

   return loading ? (
      <Loading />
   ) : (
      <Layout>
         {SeoTags(
            singleinternet?.title === undefined
               ? `DishHome Nepal`
               : `${singleinternet.title} | DishHome Nepal`,
            singleinternet?.seo_title,
            singleinternet?.seo_description,
            singleinternet?.seo_keywords,
            "DishHome",
            ` ${process.env.REACT_APP_PUBLIC_URL}internet/enterprise`,
         )}
         <MessageModal
            show={messageModal}
            isNavigate={true}
            setShow={setMessageModal}
            heading={heading}
            message={message}
            button2={"My Orders"}
            buttonText="Home Page"
            link="/"
            link2="/dashboard?type=9&option=package"
         />
         <MessageModal
            show={redeemModal}
            isNavigate={true}
            setShow={setRedeemmodal}
            heading=""
            message={message}
            buttonText="OK"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Purchase Failed"}
            message={"Something went wrong. Please try paying again from My Orders page."}
            button2={"My Orders"}
            buttonText="Home Page"
            link="/"
            link2="/dashboard?type=9&option=package"
            type={"error"}
         />
         <MessageModal
            show={footprinterror}
            setShow={setfootprintError}
            heading={"Not available"}
            message={
               "Sorry, we don't have service in your area yet. We will contact you once we are available there."
            }
            buttonText="OK"
            link="/internet/plans"
            type={"error"}
         />
         <MessageModal
            show={redeemErrorModal}
            setShow={setRedeemErrormodal}
            heading={"Invalid"}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         <div className="tv_package_wrapper">
            <div className="tvpackage_order_info fixed-width py-5 px-5">
               {order ? (
                  <>
                     <PaymentMethod
                        setErrorPayment={setErrorPayment}
                        checkFootprint={checkFootprint}
                        setCheckpayment={setCheckpayment}
                        errorPyament={errorPyament}
                        checkPayment={checkPayment}
                        setpayentmodal={setpaymentmodal}
                        btnloading={btnloading}
                        cashActive={cashActive}
                        setCashActive={setCashActive}
                        handleOrder={handleOrder}
                        esewaStatus={esewaStatus}
                        khaltiStatus={khaltiStatus}
                        connectIpsStatus={connectIpsStatus}
                        prabhuStatus={prabhuStatus}
                        imeStatus={imeStatus}
                        totalPrice={totalPrice}
                        setPaymentgateway={setPaymentgateway}
                        setkhaltiStatus={setkhaltiStatus}
                        setimeStatus={setimeStatus}
                        setprabhuStatus={setprabhuStatus}
                        setconnectIpsStatus={setconnectIpsStatus}
                        setesewaStatus={setesewaStatus}
                        internetdata={singleinternet}
                        paymentMethod={paymentgateway}
                     />
                  </>
               ) : (
                  <>
                     <div className="order_info ">
                        <p className="text">Your Package Information</p>
                        <label htmlFor="package">Your Package</label>
                        {Object.entries(internetdata).map(([key, value]) => {
                           internetOptions.push({ value: value._id, label: value.title });
                           return null;
                        })}

                        <Dropdown
                           value={singleinternet.title}
                           onChange={handleChangePackage}
                           options={internetOptions}
                        />

                        <div className="input_container">
                           <div className="name_container">
                              <label htmlFor="name" id="name">
                                 Full name
                              </label>
                              <input
                                 type="text"
                                 name="name"
                                 value={userDetails.name}
                                 className="name_input"
                                 placeholder=""
                                 disabled
                              />
                           </div>
                           <div className="phone_number_container">
                              <label htmlFor="number" id="number">
                                 Contact Number
                              </label>
                              <input
                                 type="number"
                                 name="number"
                                 value={userDetails.phone}
                                 className="ph_number_input"
                                 placeholder=""
                                 disabled
                              />
                           </div>
                        </div>
                        {(singleinternet?.combo_type !== "Fibernet" ||
                           userDetails?.customer_type !== "internetOnly") &&
                        (singleinternet?.combo_type === "DTH Fibernet Combo" ||
                           singleinternet?.combo_type === "iTV Fibernet Combo" ||
                           singleinternet?.combo_type === "T2 Fibernet Combo") ? (
                           <>
                              <div className="input_container">
                                 {nooftv !== 0 && (
                                    <>
                                       <div className="television_no_container">
                                          <label htmlFor="tv">Total No. of Television</label>

                                          <Dropdown
                                             value={tvdefaultOption}
                                             onChange={handleChangePackage1}
                                             options={internetTv}
                                          />
                                       </div>
                                    </>
                                 )}
                              </div>
                           </>
                        ) : null}

                        <p className="text" style={{ marginTop: "10px" }}>
                           Installation Address
                        </p>

                        <>
                           <MapWrapper
                              location={location}
                              setLocation={setLocation}
                              value={value}
                              position={position}
                              setPosition={setPosition}
                           />
                           {isMobile && (
                              <button
                                 style={{ marginTop: "15px", width: "106px" }}
                                 className="order_map_btn"
                                 // onClick={orderNow}
                                 onClick={handleFootprint}
                              >
                                 Continue
                              </button>
                           )}
                        </>
                     </div>
                  </>
               )}

               <PriceCard
                  totaldiscount={totaldiscount}
                  total={totalPrice}
                  routercharge={routercharge}
                  costOfTv={costOfTv}
                  costofSTB={costofSTB}
                  additionalcharge={additionalcharge}
                  totalPricetoPay={totalPricetoPay}
                  rechargeamount={rechargeamount}
                  vatAmount={vatAmount}
                  initialVat={initialVat}
                  tvName={tvName}
                  vouchermessage={vouchermessage}
                  singleinternet={singleinternet}
                  handlePackageplan={handlePackageplan}
                  price={price}
                  redeemAmounttoPay={redeemAmounttoPay}
                  type={period}
                  rememberMe={rememberMe}
                  redeemDiscount={redeemDiscount}
                  packageplan={packageplan}
                  discountamount={discountamount}
                  totalPrice={totalPrice}
                  packagetitle={packagetitle}
                  handlevoucherChange={handlevoucherChange}
                  sendVoucher={sendVoucher}
                  voucherActive={voucherActive}
                  errorVoucher={errorVoucher}
                  successVoucher={successVoucher}
                  vouchername={vouchername}
                  handleReedem={handleReedem}
                  totalPricetoPayAfterRecharge={totalPricetoPayAfterRecharge}
                  totaltvcharge={totaltvcharge}
                  totalinternetcharge={totalinternetcharge}
                  // checkout={checkout}
                  handleFootprint={handleFootprint}
                  isMobile={isMobile}
                  paymentProcess={checkFootprint}
               />
            </div>
         </div>
      </Layout>
   );
}

export function PriceCard({
   totaldiscount,
   total,
   routercharge,
   totalPricetoPayAfterRecharge,
   costOfTv,
   costofSTB,
   totalPricetoPay,
   additionalcharge,
   setRecharge,
   rechargeamount,
   vatAmount,
   initialVat,
   setPackageType,
   singleinternet,
   handlevoucherChange,
   tvName,
   vouchermessage,
   successVoucher,
   sendVoucher,
   voucherActive,
   discountamount,
   redeemAmounttoPay,
   redeemDiscount,
   rememberMe,
   errorVoucher,
   vouchername,
   handlePackageplan,
   price,
   type,
   packageplan,
   totalPrice,
   packagetitle,
   handleReedem,
   totaltvcharge,
   totalinternetcharge,
   isMobile,
   handleFootprint,
   paymentProcess,
}) {
   let priceSection = [];
   console.log(totalPricetoPayAfterRecharge - totaldiscount, "des");
   return (
      <div className="price_card">
         <div className="pricecard_wrapper ">
            <div className="internet-title_wrapper py-3 px-3">
               <p className="internet-package_name ps-2 " style={{ color: "#fff" }}>
                  {packagetitle}
               </p>
               {Object.entries(price).map(([key, value]) => {
                  priceSection.push({
                     // value: value.value,
                     label: value.type ? value.type : "",
                  });
               })}
               <Dropdown onChange={handlePackageplan} options={priceSection} value={packageplan} />
            </div>
            {Object.entries(price).map(([key, value]) => {
               totalinternetcharge =
                  value?.installation_charge +
                  value?.internet_price +
                  value?.drop_wire +
                  value?.router;

               totaltvcharge = value?.itv_price + value?.itv_stb_charge + costOfTv + costofSTB;
               if (value.type === packageplan) {
                  console.log("itv", value);
                  return (
                     <div class="internet_price_div">
                        <div className="price_div mt-4">
                           <p className="without_install_text">Internet Charge </p>
                           <p className="without_install_price">
                              Rs. {value?.internet_price?.toLocaleString("hi-IN")}
                           </p>
                        </div>
                        <div className="price_div">
                           <p className="install_text">Internet Installation Charge </p>
                           <p className="install_price">
                              Rs. {value?.installation_charge?.toLocaleString("hi-IN")}
                           </p>
                        </div>
                        <div className="price_div">
                           <p className="install_text">Router Rental Charge </p>
                           <p className="install_price">
                              Rs. {value?.router?.toLocaleString("hi-IN")}
                           </p>
                        </div>
                        <div className="price_div">
                           <p className="install_text">Drop Wire Charge </p>
                           <p className="install_price">
                              Rs. {value?.drop_wire?.toLocaleString("hi-IN")}
                           </p>
                        </div>
                        <div className="price_div">
                           <p className="total_text" id="bold-text">
                              {" "}
                              Total Internet Charge{" "}
                           </p>
                           <p className={"total_price"} id="bold-text">
                              Rs. {totalinternetcharge.toLocaleString("hi-IN")}
                           </p>
                        </div>

                        {singleinternet?.combo_type !== "Fibernet" && (
                           <div className="price_div">
                              <p className="install_text">TV Charge </p>
                              <p className="install_price">
                                 Rs. {value?.itv_price?.toLocaleString("hi-IN")}
                              </p>
                           </div>
                        )}
                        {singleinternet?.combo_type !== "Fibernet" && (
                           <div className="price_div">
                              <p className="install_text">TV STB Charge </p>
                              <p className="install_price">
                                 Rs. {value?.itv_stb_charge.toLocaleString("hi-IN")}
                              </p>
                           </div>
                        )}
                        {value?.additional_itv_price > 0 &&
                           singleinternet?.combo_type !== "Fibernet" && (
                              <div className="price_div">
                                 <p className="install_text">Additional TV Charge </p>
                                 <p className="install_price">
                                    Rs.&nbsp;
                                    {costOfTv.toLocaleString("hi-IN")}
                                 </p>
                              </div>
                           )}
                        {value?.additional_itv_stb_cost > 0 &&
                           singleinternet?.combo_type !== "Fibernet" && (
                              <div className="price_div">
                                 <p className="install_text">Additional TV STB Charge </p>
                                 <p className="install_price">
                                    Rs.&nbsp;
                                    {costofSTB.toLocaleString("hi-IN")}
                                 </p>
                              </div>
                           )}
                        {singleinternet?.combo_type !== "Fibernet" && (
                           <div className="price_div">
                              <p className="total_text" id="bold-text">
                                 {" "}
                                 Total TV Charge{" "}
                              </p>
                              <p className={"total_price"} id="bold-text">
                                 Rs. {totaltvcharge.toLocaleString("hi-IN")}
                              </p>
                           </div>
                        )}

                        <div className="price_div">
                           <p className="total_text">Voucher Discount </p>
                           <p className="total_price">Rs. {Math.floor(discountamount)}</p>
                        </div>
                        {redeemDiscount > 0 && rememberMe === true && (
                           <div className="price_div">
                              <p className="total_text">Reward Discount </p>
                              <p className="total_price">Rs. {Math.floor(redeemDiscount)}</p>
                           </div>
                        )}
                        <div className="price_div">
                           <p className="total_text">Sub Total </p>
                           <p className="total_price">
                              {" "}
                              Rs. {Math.floor(totalPricetoPayAfterRecharge).toLocaleString("hi-IN")}
                           </p>
                        </div>
                        {vatAmount > 0 && (
                           <div className="price_div">
                              <p className="total_text">VAT(13%) </p>
                              <p className="total_price">Rs. {Math.floor(vatAmount)}</p>
                           </div>
                        )}

                        <div className="price_div">
                           <p className="total_text" id="bold-text">
                              {" "}
                              Total Amount{" "}
                           </p>
                           <p id="bold-text" className={"total_price"}>
                              Rs.{" "}
                              {(redeemAmounttoPay !== null && rememberMe === true
                                 ? Math.floor(redeemAmounttoPay + vatAmount)
                                 : Math.floor(totalPricetoPayAfterRecharge + initialVat)
                              ).toLocaleString("hi-IN")}
                           </p>
                        </div>
                        {rechargeamount > 0 && (
                           <div className="price_div">
                              <p className="total_text">Recharge Amount </p>
                              <p className="total_price">Rs. {parseInt(rechargeamount)}</p>
                           </div>
                        )}

                        {/* {redeemAmounttoPay !== null && rememberMe === true ? (
                           <>
                              {" "}
                              <div className="price_div">
                                 <p className="cart-container-redeem">New total after redeem </p>
                                 <p className="total_price" id="cart-container-price">
                                    Rs.{" "}
                                    {redeemAmounttoPay !== null && rememberMe === true
                                       ? Math.floor(redeemAmounttoPay + vatAmount)
                                       : 0}
                                 </p>
                              </div>
                           </>
                        ) : (
                           <></>
                        )} */}
                     </div>
                  );
               }
            })}
         </div>

         <div style={{ marginTop: "25px" }}>
            {additionalcharge ? (
               <>
                  {console.log("total", additionalcharge)}
                  <RemainingReward
                     totalPrice={totalPricetoPayAfterRecharge + initialVat}
                     newTotalprice={redeemAmounttoPay + vatAmount}
                     discountamount={discountamount}
                     rememberMe={rememberMe}
                  />
               </>
            ) : (
               <>
                  {console.log("total", total)}
                  <RemainingReward
                     totalPrice={totalPricetoPayAfterRecharge + initialVat}
                     newTotalprice={redeemAmounttoPay + vatAmount}
                     discountamount={discountamount}
                     rememberMe={rememberMe}
                  />
               </>
            )}

            <InputGroup className="mb-2">
               {/* <FormControl
                  placeholder="Enter voucher code"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  value="hello"
                  name="vouchername"
               /> */}
               <input
                  style={{
                     width: "307px",
                     height: "60px",
                     marginBottom: "0px",
                  }}
                  placeholder="Enter voucher"
                  value={vouchername}
                  onChange={handlevoucherChange}
                  type="text"
               ></input>

               <Button
                  style={{ height: "60px" }}
                  variant="outline-secondary"
                  id="button-addon2"
                  className={vouchername === "" ? "apply-button" : "apply-button-green"}
                  onClick={() => {
                     if (vouchername !== "") sendVoucher();
                  }}
               >
                  Apply
               </Button>
            </InputGroup>
            {errorVoucher ? (
               <Alert variant="danger" style={{ width: "370px" }}>
                  <MdError />
                  &nbsp;&nbsp; {vouchermessage}
               </Alert>
            ) : (
               ""
            )}
            {successVoucher ? (
               <Alert variant="success" style={{ width: "370px" }}>
                  <MdError />
                  &nbsp;&nbsp; {vouchermessage}
               </Alert>
            ) : (
               ""
            )}
            {!paymentProcess && !isMobile && (
               <button
                  style={{ marginBottom: "12px", width: "100%" }}
                  className="order_map_btn"
                  // onClick={orderNow}
                  onClick={handleFootprint}
               >
                  Continue
               </button>
            )}
            {additionalcharge <= 0 ? (
               <>
                  {" "}
                  <div>
                     <RewardPoint
                        handleReedem={handleReedem}
                        totalPrice={total}
                        vat={vatAmount}
                        rememberMe={rememberMe}
                     />
                  </div>
               </>
            ) : (
               <>
                  <div>
                     <RewardPoint
                        rememberMe={rememberMe}
                        handleReedem={handleReedem}
                        totalPrice={totalPricetoPayAfterRecharge}
                     />
                  </div>
               </>
            )}
         </div>
      </div>
   );
}

export function PaymentMethod({
   errorPyament,
   setErrorPayment,
   checkFootprint,
   setCheckpayment,
   handleEsewa,
   handleOrder,
   khaltiStatus,
   setpayentmodal,
   paymentmodal,
   btnloading,
   esewaStatus,
   paymentgateway,
   setCashActive,
   setPaymentgateway,
   setkhaltiStatus,
   setesewaStatus,
   setimeStatus,
   setprabhuStatus,
   connectIpsStatus,
   prabhuStatus,
   imeStatus,
   setconnectIpsStatus,
   paymentMethod,
   cashActive,
   checkout,
   totalPrice,
   checkPayment,
   internetdata,
}) {
   const [checked, setisChecked] = useState("");
   const [value, setValue] = React.useState("");
   const [selectOnlinePayment, setSelectOnlinePayment] = useState(false);
   const [paymentstatus, setPaymentstatus] = useState(false);

   useEffect(() => {
      if (checkFootprint === false) {
         setValue("cod");
      } else {
         setValue("");
      }
   }, [checkFootprint]);

   const handlePaymentcheck = () => {
      if (checkPayment === false) {
         setErrorPayment(true);
      }
      if (selectOnlinePayment === false && setOnlineStatus === false) {
         setErrorPayment(true);
      }

      if (value === "payment" && selectOnlinePayment === false) {
         setErrorPayment(true);
      }
      if (checkPayment === true && selectOnlinePayment === false) {
         setErrorPayment(true);
      }
   };
   const handleChange = (event) => {
      setCheckpayment(true);
      setErrorPayment(false);
      setValue(event.target.value);
      setPaymentgateway(event.target.value);
   };
   console.log("hell", value);
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);

   // useEffect(() => {
   //    if (checkFootprint === false) {
   //       setValue("cod");
   //    }
   // }, [value]);
   // useEffect(() => {
   //    if (value === "cod") {
   //       setCashActive(true);
   //    }
   // }, [cashActive, value]);
   const [onlineStatus, setOnlineStatus] = useState(false);
   console.log("online", selectOnlinePayment);
   console.log("value", value);
   console.log("footprint", checkFootprint);
   console.log("footprint", internetdata);

   return (
      <div className="payment-method-container">
         <p id="select-payment">Select payment method</p>
         <div>
            <FormControl style={{ marginBottom: "15px" }} onChange={(e) => handleChange(e)}>
               <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
               >
                  {
                     checkFootprint === true &&
                     process.env.REACT_APP_ONLINE_PAYMENT_STATUS === "true" &&
                     !(
                        contactDetails?.disable_imepay &&
                        contactDetails?.disable_esewa &&
                        contactDetails?.disable_khalti &&
                        contactDetails?.disable_connectips &&
                        contactDetails?.disable_prabhupay
                     ) ? (
                        <>
                           <FormControlLabel
                              value="payment"
                              control={<Radio />}
                              label="Online payment"
                           />
                        </>
                     ) : null
                     // <>
                     //    <FormControlLabel
                     //       disabled
                     //       value="payment"
                     //       control={<Radio />}
                     //       label="Online payment"
                     //    />
                     // </>
                  }

                  {value === "payment" ? (
                     <div className="payment_methods">
                        {contactDetails?.disable_imepay === false && (
                           <div>
                              <img
                                 onClick={() => {
                                    setOnlineStatus(true);
                                    setPaymentgateway("imepay");
                                    setkhaltiStatus(false);
                                    setesewaStatus(false);
                                    setErrorPayment(false);

                                    setSelectOnlinePayment(true);
                                    setconnectIpsStatus(false);
                                    setprabhuStatus(false);
                                    setimeStatus(true);
                                 }}
                                 alt="ime"
                                 className={`${imeStatus ? "payment_border" : "payment_hover"}`}
                                 src={ime}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_esewa === false && (
                           <div>
                              <img
                                 src={esewa}
                                 id={`${esewaStatus ? "payment_border" : "esewa-border"}`}
                                 onClick={() => {
                                    setPaymentgateway("esewa");
                                    setErrorPayment(false);
                                    setOnlineStatus(true);
                                    setPaymentstatus(true);
                                    setkhaltiStatus(false);
                                    setSelectOnlinePayment(true);

                                    setesewaStatus(true);
                                    setpayentmodal(!paymentmodal);
                                    setimeStatus(false);
                                    setprabhuStatus(false);
                                    setconnectIpsStatus(false);
                                 }}
                                 alt="esewa"
                                 className={`${esewaStatus ? "payment_border" : "payment_hover"}`}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_khalti === false && (
                           <div>
                              <img
                                 src={khalti}
                                 onClick={() => {
                                    setPaymentgateway("khalti");
                                    setPaymentstatus(true);
                                    setErrorPayment(false);
                                    setOnlineStatus(true);
                                    setkhaltiStatus(true);
                                    setesewaStatus(false);
                                    setSelectOnlinePayment(true);

                                    setesewaStatus(false);
                                    setimeStatus(false);
                                    setprabhuStatus(false);
                                    setconnectIpsStatus(false);
                                    setpayentmodal(!paymentmodal);
                                 }}
                                 alt="khalti"
                                 className={`${khaltiStatus ? "payment_border" : "payment_hover"}`}
                              />
                           </div>
                        )}
                        {contactDetails?.disable_connectips === false && (
                           <div>
                              <img
                                 className={`${
                                    connectIpsStatus ? "payment_border" : "payment_hover"
                                 }`}
                                 src={ips}
                                 onClick={() => {
                                    setPaymentgateway("connectips");
                                    setkhaltiStatus(false);
                                    setesewaStatus(false);
                                    setErrorPayment(false);
                                    setOnlineStatus(true);
                                    setimeStatus(false);
                                    setSelectOnlinePayment(true);

                                    setprabhuStatus(false);
                                    setconnectIpsStatus(true);
                                 }}
                                 alt="connectips"
                              />
                           </div>
                        )}
                        {/* <div>
                           <img src={paypoint} />
                        </div> */}
                        {contactDetails?.disable_prabhupay === false && (
                           <div>
                              <img
                                 className={`${prabhuStatus ? "payment_border" : "payment_hover"}`}
                                 src={prabhu}
                                 onClick={() => {
                                    setPaymentgateway("prabhupay");
                                    setkhaltiStatus(false);
                                    setimeStatus(false);
                                    setErrorPayment(false);
                                    setOnlineStatus(true);
                                    setSelectOnlinePayment(true);

                                    setesewaStatus(false);
                                    setconnectIpsStatus(false);
                                    setprabhuStatus(true);
                                 }}
                                 alt="prabhupay"
                              />
                           </div>
                        )}
                     </div>
                  ) : null}

                  {!internetdata?.dashain_offer &&
                     (checkFootprint === true ? (
                        <>
                           <FormControlLabel
                              value="cod"
                              control={<Radio />}
                              label="Cash on delivery"
                           />
                        </>
                     ) : (
                        <>
                           <FormControlLabel
                              checked
                              value="cod"
                              control={<Radio />}
                              label="Cash on delivery"
                           />
                        </>
                     ))}
               </RadioGroup>
            </FormControl>
         </div>
         {errorPyament === true && (
            <p style={{ color: "red" }}>You must select one of the payment methods!</p>
         )}

         {value === "cod" || selectOnlinePayment ? (
            <Button
               variant="secondary"
               // className={cashActive ? "order_btn" : "order_btn_inactive"}
               className={"order_btn red"}
               onClick={handleOrder}
            >
               Order Now
            </Button>
         ) : (
            <>
               <Button
                  // className={cashActive ? "order_btn" : "order_btn_inactive"}
                  // className={"order_btn red"}
                  className={
                     ["imepay", "esewa", "khalti", "prabhupay", "connectips"]?.includes(
                        paymentMethod,
                     )
                        ? "order_btn red"
                        : "order_btn_inactive"
                  }
                  variant="secondary"
                  onClick={handlePaymentcheck}
               >
                  Order Now
               </Button>
            </>
         )}
      </div>
   );
}

export function MapWrapper({ setPosition, value, position, location, setLocation }) {
   const markerRef = useRef(null);
   const [center, setCenter] = useState([27.7172, 85.324]);
   const [search, setSearch] = useState("");
   const [polyLine, setPolyLine] = useState("");
   const GEOCODE_URL =
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";
   // function showPosition(position) {
   //    console.log("Latitude", position.coords.latitude);
   // }

   useEffect(() => {
      if (value === "map") {
         if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
         } else {
            alert("Geolocation is not supported by this browser.");
         }
      }
   }, [value, position]);
   function showPosition(position) {
      console.log(position.coords.latitude);
   }

   function showError(error) {
      switch (error.code) {
         case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
         case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
         case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
         case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.");
            break;
         default:
            console.log("An unknown error occurred.");
      }
   }
   const [tooltipvisible, settooltipvisible] = useState(true);

   function LocationMarker({ position }) {
      const [bbox, setBbox] = useState([]);
      const map = useMap();
      useEffect(() => {
         map.locate().on("locationfound", function (e) {
            map.flyTo(position, map.getZoom());
            setBbox(e.bounds.toBBoxString().split(","));
         });
      }, [map]);

      const eventHandlers = useMemo(
         () => ({
            dragstart() {
               settooltipvisible(false);
            },
            dragend() {
               const marker = markerRef.current;
               setPosition(marker.getLatLng());
               settooltipvisible(true);
            },
         }),
         [],
      );

      //reverese geocode
      // async reverseGeoCoding(coordinates) {
      //    // Here the coordinates are in LatLng Format
      //    // if you wish to use other formats you will have to change the lat and lng in the fetch URL
      //    const data = await (await fetch(GEOCODE_URL + `${coordinates.lng},${coordinates.lat}`)).json();
      //    console.log(data.address);

      //    const addressLabel = data.address !== undefined ? data.address.LongLabel : "Unknown";
      //    this.setState({ address: addressLabel });
      //  };
      return position === null ? null : (
         <Marker position={position} draggable={true} ref={markerRef} eventHandlers={eventHandlers}>
            {/* <Popup>{location?.Match_addr}</Popup> */}
            {tooltipvisible && (
               <Tooltip direction={"top"} offset={[-15, -15]} permanent={tooltipvisible}>
                  {location?.Match_addr}
               </Tooltip>
            )}
         </Marker>
      );
   }
   useEffect(() => {
      if (position) {
         const latlng = position;
         const url = `${GEOCODE_URL} + ${latlng.lng},${latlng.lat}`;
         fetch(url)
            .then((res) => res.json())
            .then((data) => {
               const address = data?.address;
               if (address) setLocation(address);
            });
      }
   }, [position]);

   function getData(search) {
      axios
         .get(`https://nominatim.openstreetmap.org/search?q=${search}}&format=json`)
         .then((res) => {
            const data = res.data;
            setPosition({ lat: data[0].lat, lng: data[0].lon });
            setCenter({ lat: data[0].lat, lng: data[0].lon });
            setPolyLine(data[0]?.boundingbox);
         });
   }
   const [mapData, setMapData] = useState("");
   useEffect(() => {
      document.getElementsByClassName("leaflet-control-attribution")[0].style.display = "none";
   }, []);
   useEffect(() => {
      axios
         .get(`https://nominatim.openstreetmap.org/search?q=${search}}&format=json&countrycodes=NP`)
         .then((res) => {
            const data = res.data;
            search?.length > 2 && setMapData(data);
         });
   }, [search]);

   function setActiveMapData(data) {
      setPosition({ lat: data.lat, lng: data.lon });
      setCenter({ lat: data.lat, lng: data.lon });
      setPolyLine(data?.boundingbox);
   }
   return (
      <>
         <div className="searchContainer">
            <InputGroup>
               <InputGroup.Text id="search-icon">
                  <BiSearchAlt />
               </InputGroup.Text>
               <input
                  type="text"
                  className="searchInput"
                  placeholder="Search.."
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyPress={(e) => {
                     if (e.charCode === 13) {
                        setSearch("");
                        getData(e.target.value);
                     }
                  }}
               />
            </InputGroup>
            {search?.length > 2 && (
               <div className="searchResult">
                  {mapData &&
                     mapData.map((data, index) => {
                        return (
                           <div
                              key={index}
                              className="searchResultItem"
                              onClick={() => {
                                 setActiveMapData(data);
                                 setSearch("");
                              }}
                           >
                              {data.display_name}
                           </div>
                        );
                     })}
               </div>
            )}
         </div>
         <MapContainer center={center} zoom={15} style={{ height: "50vh", zIndex: "1" }}>
            <TileLayer
               attributionControl={false}
               // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
               url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <LocationMarker position={position} />
         </MapContainer>
      </>
   );
}
