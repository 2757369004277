import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ reRef }) => {
   return (
      <div className="d-flex mt-2 mb-1" style={{ alignItems: "flex-start", width: "100%" }}>
         <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
            size={"invisible"}
            ref={reRef}
            badge="bottomleft"
         />
      </div>
   );
};

export default Captcha;
