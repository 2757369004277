import React, { useState, useEffect } from "react";
import "../../../scss/internet/internetpackage.scss";
import triangle from "../../../assets/internet/triangle.png";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Button, Col, NavItem } from "react-bootstrap";
import { get_internet_package, get_why_dishhome } from "../../../data/api";
import Widthcontainer from "../widthcontainer";
import { BiChevronRight } from "react-icons/bi";
export default function Internetpackage({ internetPackage }) {
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let duration = query.get("type");

   // console.log(duration);
   //useState Hooks
   const [internetdata, setinternetData] = useState([]);
   const [internetprice, setinternetPrice] = useState("");

   //useEffect Hooks

   useEffect(() => {
      async function get_inetrnetpackage() {
         const res = await get_internet_package();
         const response = res.data.data;
         setinternetData(response);
      }
      get_inetrnetpackage();
   }, []);
   let navigate = useNavigate();
   return (
      <div>
         <Widthcontainer>
            <div className="internetpackage-wrapper">
               {Object.entries(internetPackage).map(([key, value]) => {
                  return (
                     <div style={{ marginBottom: "17px" }}>
                        <div className="internet" id={key == 1 ? "middle-div" : ""}>
                           {key === "1" && <img id="triangle-img" src={triangle} alt="wifi" />}

                           <div className="wifi">
                              <img
                                 src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 alt="wifi"
                              />
                           </div>

                           <p className="mbps" id={key == 1 ? "middle-mbps" : ""}>
                              {value.title}
                           </p>
                           <p className="plans" id={key == 1 ? "middle-mbps" : ""}>
                              {value.description?.substring(0, 60)}
                              {
                                 //show ... if description is more than 60 characters
                                 value.description?.length > 60 ? "..." : ""
                              }
                           </p>
                           <div id={key == 1 ? "middle-mbps" : ""}>
                              {Object.entries(value.prices).map(([key, item]) => {
                                 const totalPrice =
                                    item?.installation_charge +
                                    item?.internet_price +
                                    item?.drop_wire +
                                    item?.itv_price +
                                    item?.itv_stb_charge +
                                    item?.router;
                                 return (
                                    <div>
                                       {item.type === "1 Month" ? (
                                          <div>
                                             <p
                                                className="month"
                                                id={key == 1 ? "middle-mbps" : ""}
                                             >
                                                Rs.{" "}
                                                <span
                                                   className="unique"
                                                   id={key == 1 ? "middle-mbps" : ""}
                                                >
                                                   {item?.internet_price.toLocaleString("hi-IN")}*
                                                </span>
                                                /month
                                             </p>
                                          </div>
                                       ) : (
                                          ""
                                       )}
                                    </div>
                                 );
                              })}
                           </div>

                           <div className="wrapper-button">
                              {key === "1" ? (
                                 <Button
                                    onClick={() => {
                                       navigate(`/internet/${value._id}`);
                                    }}
                                    variant="secondary"
                                    className="choose_middle"
                                 >
                                    <span id="btn-text"> Choose</span>
                                 </Button>
                              ) : (
                                 <Button
                                    onClick={() => {
                                       navigate(`/internet/${value._id}`);
                                    }}
                                    // onClick={() => {
                                    //    navigate(`/internetpackage/order/${value._id}?type=1 Month`);
                                    // }}
                                    className="choose"
                                    variant="secondary"
                                 >
                                    Choose
                                 </Button>
                              )}
                           </div>
                        </div>
                     </div>
                  );
               })}
            </div>
         </Widthcontainer>
         <div
            className="container"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
         >
            <p className="internet-para">Choose the internet packages that best suit your needs.</p>
            <p className="view_more_plan" onClick={() => navigate("/internet/plans")}>
               View More Plans <BiChevronRight />
            </p>
         </div>
         <div>
            {/* <img src={dishbanner} width="100%" style={{ marginTop: "60px" }} alt="dishbanner" /> */}
            <InternetBanner />
         </div>
      </div>
   );
}

export function InternetBanner() {
   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await get_why_dishhome();
         const response = res.data.data;
         setBannerData(response);
      }
      get_navigation();
   }, []);
   return (
      <Widthcontainer className={"internet-banner-bg"}>
         {Object.entries(bannerdata).map(([key, value]) => {
            return (
               <>
                  {value.page === "Internet" ? (
                     <div className="internet-banner-wrapper fixed-width ">
                        <div id="internet-one">
                           {/* <p id="fastest">Fastest Internet</p> */}
                           <p id="why-dishhome">{value.title}</p>
                           <p id="internet-para">{value.description}</p>
                           <div className="category-wrapper">
                              {" "}
                              {Object.entries(value.whyDishhome).map(([key, item]) => {
                                 return (
                                    <div className="internet-feature-banner">
                                       <div>
                                          <img
                                             src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                                             height="40px"
                                          />
                                          <p id="internet-feature-banner-title">{item.title}</p>
                                          <p id="internet-feature-banner-subtitle">
                                             {item.description}
                                          </p>
                                       </div>
                                    </div>
                                 );
                              })}
                           </div>
                        </div>

                        <div className="img" style={{ marginTop: "150px" }}>
                           <img src={`${process.env.REACT_APP_FILE_URL}/${value.image}`} />
                        </div>
                     </div>
                  ) : (
                     <div>{/* <img src={inter} />{" "} */}</div>
                  )}
               </>
            );
         })}
      </Widthcontainer>
   );
}
