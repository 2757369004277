import React, { useState, useEffect } from "react";
import "../../scss/store/hotdeals.scss";
import play from "../../assets/store/play.png";
import { get_hot_deals } from "../../data/api";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import SeoTags from "../../utils/seoTags";
export default function Hotdeals() {
   const [hotdeals, setHotDeals] = useState("");
   let navigate = useNavigate();
   useEffect(() => {
      async function get_hotdeals() {
         const res = await get_hot_deals();
         const response = res?.data?.data;
         setHotDeals(response);
      }
      get_hotdeals();
   }, []);
   return (
      <Widthcontainer>
         <div className="hot-deals fixed-width">
            <p className="hot_deals_title">Hot Deals.</p>
            <p className="hot_deals_description">Deals you will find nowhere else.</p>

            <div className="hot_deals">
               <HotdealsProducts hotdeals={hotdeals} />
            </div>
            <p
               className="hot_deals_shopnow cursor_pointer mt-2"
               onClick={() => navigate("/store/hotdeals")}
            >
               Shop More &nbsp; <AiOutlineArrowRight />
            </p>
         </div>
      </Widthcontainer>
   );
}

export function HotdealsProducts({ hotdeals }) {
   let navigate = useNavigate();
   return (
      <div id="hot_deals_wrapper">
         {Object.entries(hotdeals).map(([key, value]) => {
            return key < 3 ? (
               <div>
                  <div
                     className="hot_deals_container"
                     onClick={() => {
                        navigate("/store/" + value._id);
                     }}
                  >
                     <div className="hot_deals_text_wrapper">
                        <p className="hot_deals_container_brand">{value?.categories[0]?.title}</p>

                        <p className="hot_deals_container_name">{value.name}</p>
                        <p className="hot_deals_container_price">
                           Rs. {value.price.toLocaleString("hi-IN")}
                        </p>
                     </div>
                     <div className="hot_deals_img">
                        <img
                           className="apple"
                           src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                        ></img>
                        <p className="shop-now hot_deals_text_wrapper">
                           Shop Now <img className="play" src={play} />{" "}
                        </p>
                     </div>
                  </div>
               </div>
            ) : null;
         })}
      </div>
   );
}
