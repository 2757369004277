import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import "../../scss/pages/custom.scss";
import { get_internet_package } from "../../data/api";
import "../../assets/offer/css/style.css";
import "../../assets/offer/css/demo.css";
import "../../assets/offer/css/custom.css";
import firework1 from "../../assets/offer/image/files/firework1.png";
import sectiondividerblack from "../../assets/offer/image/files/svg/section-devider-black.svg";
import MainImg from "../../assets/offer/image/files/main.png";
import manImg from "../../assets/offer/image/files/manimage.png";
import womanImg from "../../assets/offer/image/files/woman.png";
import wifiGif from "../../assets/offer/image/files/icons8-wifi.gif";
import kite from "../../assets/offer/image/files/svg/kite.svg";
import sample1 from "../../assets/offer/image/files/sample1.png";
import sample2 from "../../assets/offer/image/files/sample2.png";
import kite3 from "../../assets/offer/image/files/kite3.png";
import kite4 from "../../assets/offer/image/files/kite4.png";
import tagline from "../../assets/offer/image/files/tagline.png";

function Custom() {
   const [dashainOffer, setDashainOffer] = useState();
   var subArrays = [];
   useEffect(() => {
      async function getData() {
         const res = await get_internet_package();
         setDashainOffer(res?.data?.data?.filter((item) => item?.dashain_offer === true));
      }
      getData();
   }, []);
   if (dashainOffer?.length > 0) {
      for (let i = 0; i < dashainOffer?.length; i += 4) {
         subArrays.push(dashainOffer?.slice(i, i + 4));
      }
   }
   function calculateTotal(data) {
      // console.log(data);
      const totalPrice = data?.internet_price + data?.itv_price;
      return totalPrice;
   }
   return (
      <html lang="en">
         <head>
            <link
               rel="stylesheet"
               href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"
               integrity="sha512-1sCRPdkRXhBV2PBLUdRb4tMg1w2YPf37qatUFeS7zlBy7jJI8Lf4VHwWfZZfpXtYSLy85pkm9GaYVYMfw5BC1A=="
               crossorigin="anonymous"
               referrerpolicy="no-referrer"
            />
         </head>

         <body>
            <div class="page-wrapper custom-page-wrapper overflow-hidden">
               {/* <header class="site-header site-header--transparent site-header--sticky">
                     <div class="container">
                        <nav class="navbar site-navbar">
                           <div class="menu-block-wrapper">
                              <div class="menu-overlay"></div>
                              <nav class="menu-block" id="append-menu-header"></nav>
                           </div>
                        </nav>
                     </div>
                  </header> */}

               <div class="home-8_hero-section" id="hero">
                  <div class="particles">
                     <img src={firework1} alt="" class="particle-1" />
                  </div>
                  <div class="section-divider">
                     <img src={sectiondividerblack} alt=" section divider" />
                  </div>
                  <div class="container position-relative">
                     <div class="row row--custom">
                        <div class="col-lg-8 col-md-9">
                           <div class="home-8_hero-content-block">
                              <div class="home-8_hero-text-block">
                                 <img src={MainImg} alt="" class="img-fluid" />
                                 <p>
                                    <span class="nepali-font">यो चाडपर्व नयाँ </span>
                                    <span class="text-red">connection</span>
                                    <span class="nepali-font">जोड्नुहोस्</span>
                                    <br />
                                    <span class="nepali-font">र उही</span>
                                    <span class="text-red">price</span>
                                    <span class="nepali-font">मा दोब्बर</span>
                                    <span class="text-red">speed</span>
                                    <span class="nepali-font">पाउनुहोस्</span>
                                 </p>
                              </div>
                              <div class="home-8_hero-button-group">
                                 <a href="" class="nav-telbutton">
                                    <span class="icon">
                                       <i class="fa fa-phone"></i>
                                    </span>
                                    <span class="button-content">
                                       <span class="text"> For New Connections </span>
                                       <span class="offer_number"> 01-5970365 </span>
                                    </span>
                                 </a>
                                 <a href="" class="nav-telbutton">
                                    <span class="icon">
                                       <i class="fa-solid fa-boxes-stacked"></i>
                                    </span>
                                    <span class="button-content">
                                       <span class="text"> Exciting Holiday Offer </span>
                                       <span class="offer_number"> View Offers </span>
                                    </span>
                                 </a>
                              </div>
                              <div class="home-8_hero-content-shape">
                                 <img src={firework1} alt="image alt" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="image-man">
                     <img src={manImg} alt="" />
                  </div>
                  <div class="image-woman">
                     <img src={womanImg} alt="" />
                  </div>
                  <a href="#offers" id="scroll-down">
                     <span>
                        <img src={wifiGif} alt="" />
                     </span>
                  </a>
               </div>

               <div class="home-8_stats-section" id="offers">
                  <div class="container position-relative" style={{ zIndex: 2 }}>
                     <div class="row">
                        <div class="col-lg-10 offset-lg-1 position-relative">
                           {dashainOffer?.length > 0 && (
                              <Splide
                                 options={{
                                    pagination: false,

                                    interval: 1000,
                                 }}
                                 hasTrack={false}
                              >
                                 <SplideTrack>
                                    {subArrays?.map((items) => {
                                       return (
                                          <SplideSlide>
                                             <div>
                                                <div class="pricing-card-6">
                                                   {items.map((item) => {
                                                      return (
                                                         <div class="pricing-card-6__head">
                                                            <span class="pricing-card-6__plan">
                                                               {/* 50 Mbps{" "}
                                                            <span class="nepali-font">अब</span>
                                                            <span class="text-red">
                                                               <strong>&nbsp;100</strong>
                                                            </span>
                                                            Mbps */}
                                                               {item?.title}
                                                            </span>
                                                            <h2 class="pricing-card-6__price-plan">
                                                               <span class="pricing-card-6__price">
                                                                  {calculateTotal(
                                                                     //return the price ot type = 12 Months
                                                                     item?.prices?.filter(
                                                                        (item) =>
                                                                           item?.type ===
                                                                           "12 Months",
                                                                     )[0],
                                                                  )}
                                                               </span>
                                                               <span class="pricing-card-6__time">
                                                                  / Year *
                                                               </span>
                                                            </h2>
                                                            <p>{item?.description}</p>
                                                            <a
                                                               href={`/internet/${item?._id}?type=12 Months`}
                                                               class="btn-masco btn-primary-l02 d-inline-block mt-4"
                                                            >
                                                               Choose Plan
                                                            </a>
                                                         </div>
                                                      );
                                                   })}
                                                </div>
                                                <div class="text-center">
                                                   <a
                                                      href="/internet/plans"
                                                      class="btn-masco btn-primary-l02 d-inline-block mt-4"
                                                   >
                                                      View All Plans &nbsp;&nbsp;
                                                      <i class="fa fa-arrow-right"></i>
                                                   </a>
                                                </div>
                                             </div>
                                          </SplideSlide>
                                       );
                                    })}
                                 </SplideTrack>

                                 <div className="splide__arrows">
                                    <button className="splide__arrow splide__arrow--prev plan-button-prev">
                                       <i class="fa fa-chevron-left"></i>
                                    </button>
                                    <button className="splide__arrow splide__arrow--next plan-button-next">
                                       <i class="fa fa-chevron-right"></i>
                                    </button>
                                 </div>
                              </Splide>
                           )}

                           {/* <div class="plan-button-next">
                             
                           </div>
                           <div class="plan-button-prev">
                              <i class="fa fa-chevron-left"></i>
                           </div> */}
                        </div>
                     </div>
                  </div>
                  <div
                     class="kite1"
                     style={{
                        backgroundImage: `url(
                              ${kite3}
                           )`,
                     }}
                  ></div>
                  <div
                     class="kite2"
                     style={{
                        backgroundImage: ` url(
                              ${kite4}
                           )`,
                     }}
                  ></div>
               </div>

               <div class="ticker-01_section" id="ticker">
                  <div class="ticker-01_wrapper">
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                     <div class="ticker-01_content">
                        <div class="ticker-item">
                           <p>
                              Connection ko <span class="nepali-font">दोब्बर</span> Utsav
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">यो चाडपर्व नयाँ </span> connection
                              <span class="nepali-font">जोड्नुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                        <div class="ticker-item">
                           <p>
                              <span class="nepali-font">र उही</span> price
                              <span class="nepali-font">मा दोब्बर</span> speed
                              <span class="nepali-font">पाउनुहोस्</span>
                           </p>
                           <p>
                              <img src={kite} height="24px" alt="" />
                           </p>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="cta-home-3">
                  <div class="container">
                     <div class="row justify-content-center">
                        <div class="col-12 col-sm-8 col-lg-6 col-xl-5">
                           <div class="cta-home-3__inner">
                              <div class="cta-home-3__content-block">
                                 <div class="cta-text-block">
                                    <h2 class="cta-title text-black">
                                       Don't Miss out on this <br />
                                       <span class="nepali-font text-red">दोब्बर</span>
                                       opportunity !!
                                    </h2>
                                    <h3>
                                       <span class="nepali-font">यो चाडपर्व नयाँ </span>
                                       <span class="text-red">connection</span>
                                       <span class="nepali-font">जोड्नुहोस्</span> <br />
                                       <span class="nepali-font">र उही</span> price
                                       <span class="nepali-font">मा दोब्बर</span> speed
                                       <span class="nepali-font">पाउनुहोस्</span>
                                    </h3>
                                 </div>
                                 <div class="cta-button-group">
                                    <a href="" class="nav-telbutton">
                                       <span class="icon">
                                          <i class="fa fa-phone"></i>
                                       </span>
                                       <span class="button-content">
                                          <span class="text"> For New Connections </span>
                                          <span class="offer_number"> 01-5970365 </span>
                                       </span>
                                    </a>
                                    <a href="" class="nav-telbutton">
                                       <span class="icon">
                                          <i class="fa-solid fa-boxes-stacked"></i>
                                       </span>
                                       <span class="button-content">
                                          <span class="text"> Exciting Holiday Offer </span>
                                          <span class="offer_number"> View Offers </span>
                                       </span>
                                    </a>
                                 </div>
                              </div>

                              <div class="sample1">
                                 <img src={sample1} alt="" />
                              </div>
                              <div class="sample2">
                                 <img src={sample2} alt="" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="home-8_content-section-1" id="about">
                  <div class="container position-relative" style={{ zIndex: 2 }}>
                     <div class="row row--custom">
                        <div class="col-xxl-5 col-lg-5 col-md-12">
                           <div class="home-8_content-image-1-block">
                              <div class="home-8_content-image-1 content-image--mobile-width">
                                 <img src={tagline} alt="" class="img-fluid" />
                              </div>
                              <div class="home-8_content-image-1-card">
                                 <img src={MainImg} alt="" class="img-fluid" />
                              </div>
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                           <div class="content">
                              <div class="content-text-block">
                                 <h2 class="content-title text-black-2">
                                    <span class="nepali-font"> अझै नजिकिन्छ सम्बन्ध </span>
                                    <br />
                                    <span class="nepali-font">जब दुरी मेटिन्छ </span>
                                    <span class="text-red">&nbsp;DH Fibernet&nbsp;</span>
                                    <span class="nepali-font"> संग</span>
                                 </h2>
                                 <p>
                                    Celebrate this Dashain together with your family no matter where
                                    they are - our lightning fast internet speeds ensures your
                                    connection to your loved ones is never broken.
                                 </p>
                                 <div class="content-divider"></div>
                                 <p>
                                    Enjoy uninterrupted hours of gaming with friends and create
                                    lasting memories with your family through jokes and laughter.{" "}
                                    <br />
                                    <br />
                                    <strong>
                                       This Dashain, connect with
                                       <span class="text-red"> Dish Home Fibernet</span>.
                                    </strong>
                                 </p>
                              </div>
                              <div class="content-button-block">
                                 <a href="#" class="btn-masco btn-primary-l02 d-inline-block mt-0">
                                    View All Plans
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="swings"></div>
               </div>
            </div>
         </body>
      </html>
   );
}

export default Custom;
