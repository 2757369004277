import { React, useState, useEffect, useRef } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import "../../scss/pages/contactus.scss";
import "../../scss/pages/form.scss";
import PhoneIcon from "../../assets/phone_icon.png";
import HelpdeskIcon from "../../assets/helpdesk_icon.png";
import ChatIcon from "../../assets/chat_icon.webp";
import EmailIcon from "../../assets/email_icon.webp";
import AltEmailIcon from "../../assets/alt_email_icon.webp";
import { get_email_list, post_contact_us } from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import { Alert, Form } from "react-bootstrap";
import { MdError } from "react-icons/md";
import SeoTags from "../../utils/seoTags";
import HeroBanner from "../customcomponents/herobanner";
import { get_contact_us_banner } from "../../data/api";
import { Formik } from "formik";
import * as yup from "yup";
import Captcha from "../include/captcha";

const ContactUs = () => {
   const [contactDetails, setcontactDetails] = useState("");
   const [seoData, setSeoData] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      async function fetchData() {
         const response = await get_contact_us_banner();
         setBannerData(response?.data?.banners);
         setSeoData(response?.data?.seo);
      }
      fetchData();
   }, [loading]);

   //get email list
   const [emaillist, setEmaillist] = useState("");
   useEffect(() => {
      async function getEmail() {
         const response = await get_email_list();
      }
      getEmail();
   }, []);
   useEffect(() => {
      if (
         seoData === "" ||
         seoData === undefined ||
         bannerData === "" ||
         bannerData === undefined
      ) {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, []);
   return (
      <Layout setContactdetails={setcontactDetails}>
         {/* <Widthcontainer className={"contact_us_width_container"}>
            <div className="contact_us_banner fixed-width">
               <div className="contact_us_banner_text">
                  <p className="contact_us">Contact Us </p>
                  <h1 className="contact_us_header">
                     Get in touch with us <span>today</span>!
                  </h1>
                  <p className="contact_us_sub_header">
                     Learn everything about Dishhome. If you run into any problems at all, feel free
                     to contact us anytime
                  </p>
               </div>
               <div className="contact_us_banner_img">
                  <img src={Img} alt="top" />
               </div>
            </div>
         </Widthcontainer> */}
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "contact") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}`,
               );
            } else {
               return null;
            }
         })}
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === "contact_top") {
               return <HeroBanner data={value} />;
            } else return null;
         })}
         <Widthcontainer>
            <div className="contact_us_container fixed-width mb-5">
               <div className="contact_us_form_container">
                  <h1 className="caption">Drop Us a Line.</h1>
                  <p className="sub_caption mb-3">
                     Our entire team ensures you’re receiving the best information possible. We're
                     here to help!
                  </p>
                  <ContactUsForm />
               </div>
               <div className="contact_us_details">
                  <div className="contact_us_details_card">
                     <h1 className="caption">Call Us</h1>
                     <p className="sub_caption">
                        Questions about plans, pricing, or availability? Just have your people call
                        our people.
                     </p>
                     <div className="details">
                        <img src={PhoneIcon} alt="" className="icon" />
                        <p className="details_text">
                           Toll free:{" "}
                           <a href={"tel: " + contactDetails?.contact}>
                              {" "}
                              {contactDetails?.contact}
                           </a>
                        </p>
                     </div>
                     <div className="details">
                        <img src={HelpdeskIcon} alt="" className="icon" />
                        <p className="details_text">
                           Help Desk:
                           <a href={"tel: " + contactDetails?.support?.helpdesk.contact_1}>
                              {" "}
                              {contactDetails?.support?.helpdesk?.contact_1}
                           </a>
                           ,
                           <a href={"tel: " + contactDetails?.support?.helpdesk?.contact_2}>
                              {" "}
                              {contactDetails?.support?.helpdesk?.contact_2}
                           </a>
                        </p>
                     </div>
                  </div>
                  <div className="contact_us_details_card">
                     <h1 className="caption">Chat with Us</h1>
                     {/* <p className="sub_caption">
                        Live Chat means instant messaging with our team. This feature is available 9
                        AM - 8 PM (GMT+5:45).
                     </p> */}
                     <p className="sub_caption">
                        Live Chat means instant messaging with our team. This feature is available
                        24/7.
                     </p>
                     <div className="details">
                        <img src={ChatIcon} alt="" className="icon" />
                        <p className="details_text">
                           <a href="http://m.me/dishhome" target="_blank" rel="noreferrer">
                              Live Chat Now
                           </a>
                        </p>
                     </div>
                  </div>
                  <div className="contact_us_details_card">
                     <h1 className="caption">Email</h1>
                     {/* <p className="sub_caption">
                        Live Chat means instant messaging with our team. This feature is available 9
                        AM - 8 PM (GMT+5:45).
                     </p> */}
                     <p className="sub_caption">You can email us your enquires at</p>
                     <div className="details">
                        <img src={EmailIcon} alt="" className="icon" />
                        <p className="details_text">
                           <a href="mailto: support@dishhome.com.np">support@dishhome.com.np</a>
                        </p>
                     </div>
                     <div className="details">
                        <img src={AltEmailIcon} alt="" className="icon" />
                        <p className="details_text">
                           <a href={"mailto: " + contactDetails.email}>{contactDetails.email}</a>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export function ContactUsForm() {
   const [message, setMessage] = useState("");
   const [btnStatus, setBtnStatus] = useState(true);
   const [show, setShow] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const reRef = useRef();

   const handelSubmit = async (values) => {
      setErrorMessage("");
      setBtnStatus(false);
      // e.preventDefault();
      let phone = values?.phone;
      if (values?.phone?.length > 10) {
         if (values?.phone?.charAt(0) === "0") {
            phone = phone.substring(1);
         } else if (values?.phone?.slice(0, 4) === "+977") {
            phone = phone?.slice(4, 18);
         } else {
            setErrorMessage("Please Enter Valid Phone Number");
            setBtnStatus(true);
            return;
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      const response = await post_contact_us(
         values?.nature,
         values?.name,
         values?.email,
         phone?.toString(),
         values?.message,
         captchaToken,
      );
      if (response?.data?.success) {
         setShow(true);
         setBtnStatus(true);
      } else {
         // console.log(response?.response?.data?.message);
         setErrorMessage(response?.response?.data?.message);
         setBtnStatus(true);
      }
   };

   const schema = yup.object().shape({
      name: yup
         .string()
         .required("Name is required")
         .min(2, "Name must be 2 characters long")
         .max(50),
      email: yup.string().email("Invalid email").required("Email is required").min(6).max(60),
      phone: yup
         .string()
         .required("Phone Number is required")
         .min(10, "Please Enter a valid Phone Number"),
      message: yup.string().required("Message is required"),
   });
   return (
      <div className="contact_us_form">
         <Captcha reRef={reRef} />
         <MessageModal
            show={show}
            setShow={setShow}
            heading={"Thank you for contacting us"}
            message={"Our team will get back to you shortly"}
            link={"/"}
            buttonText={"Go to home"}
         />
         <Alert variant="danger" className={"Signup_Alert" + (errorMessage !== "" ? "_show" : "")}>
            <MdError />
            &nbsp;&nbsp;
            {errorMessage}
         </Alert>
         <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
               handelSubmit(values);
               setSubmitting(false);
            }}
            initialValues={{
               nature: "Internet Package",
               name: "",
               email: "",
               phone: "",
               message: "",
            }}
         >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
               <Form onSubmit={handleSubmit} className="contact_us_form">
                  <label htmlFor="package">Nature of Inquiry</label>
                  <select
                     name="nature"
                     id="package"
                     placeholder="Select your Inquiry"
                     onChange={handleChange}
                  >
                     <option value="Internet Package">Internet Package</option>
                     <option value="TV Package">TV Package</option>
                  </select>
                  <div className="input_container">
                     <div className="name_container">
                        <label htmlFor="name" id="name">
                           Full Name
                        </label>
                        <input
                           type="text"
                           name="name"
                           id="name"
                           className={!errors?.name ? "name_input" : "name_input error"}
                           placeholder=""
                           value={values?.name}
                           minLength="2"
                           maxLength={"50"}
                           onChange={handleChange}
                        />
                        {errors?.name ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.name}
                           </p>
                        ) : null}
                     </div>
                     <div className="email_container">
                        <label htmlFor="email" id="email">
                           Email Address
                        </label>
                        <input
                           minLength="6"
                           maxLength={"60"}
                           type="email"
                           name="email"
                           className={!errors?.email ? "email_input" : "email_input error"}
                           placeholder=""
                           value={values?.email}
                           onChange={handleChange}
                        />
                        {errors?.email ? (
                           <p className="error_message" style={{ color: "#ed1c24" }}>
                              {errors?.email}
                           </p>
                        ) : null}
                     </div>
                  </div>
                  <label htmlFor="phone" id="phone">
                     Phone Number
                  </label>
                  <input
                     type="tel"
                     name="phone"
                     className={!errors?.phone ? "ph_number_input" : "ph_number_input error"}
                     placeholder=""
                     maxLength={14}
                     value={values?.phone}
                     onChange={(e) => {
                        if (e?.nativeEvent?.data) {
                           if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                              handleChange(e);
                           }
                           if (e?.nativeEvent?.data === null) {
                              handleChange(e);
                           }
                        } else {
                           handleChange(e);
                        }
                     }}
                  />
                  {errors?.phone ? (
                     <p className="error_message" style={{ color: "#ed1c24" }}>
                        {errors?.phone}
                     </p>
                  ) : null}
                  <label htmlFor="subject" id="subject">
                     Subject
                  </label>
                  <textarea
                     type="textarea"
                     name="message"
                     className={!errors?.message ? "subject_input" : "subject_input error"}
                     placeholder="How can we help you ?"
                     value={values?.message}
                     onChange={handleChange}
                  />
                  {message?.name ? (
                     <p className="error_message" style={{ color: "#ed1c24" }}>
                        {errors?.message}
                     </p>
                  ) : null}
                  <button
                     className={btnStatus ? "contact_us_btn" : "contact_us_btn disabled"}
                     type="submit"
                  >
                     {btnStatus ? "Submit" : "Sending ..."}
                  </button>
               </Form>
            )}
         </Formik>
      </div>
   );
}

export default ContactUs;
