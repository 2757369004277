import React, { useState, useEffect } from "react";
import Layout from "../include/layout";
import "../../scss/components/recharge.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useLocation } from "react-router-dom";

import { Formik } from "formik";
import pay from "../../assets/pay-recharge.png";
import ips from "../../assets/store/ips.png";
import prabhupay from "../../assets/store/prabhu.png";
import imepay from "../../assets/store/ime.png";
import KhaltiCheckout from "khalti-checkout-web";

import khalti from "../../assets/cart/khalti.png";
import { Alert, Button, Form, InputGroup, Tab, Table } from "react-bootstrap";
import * as yup from "yup";
import {
   check_min_balance,
   get_customer_id,
   get_recharge_list,
   get_reward_recharge,
   get_voucher_discount,
   pin,
   recharge,
   reward_recharge,
   update_profile,
} from "../../data/protectedapi";
import Token from "../../utils/useToken";
import { get_bonus_list } from "../../data/protectedapi";
import MessageModal from "../customcomponents/messageModal";
import Dropdown from "react-dropdown";
import axios from "axios";
import myKey from "../../store/pages/khaltiKey";
import Loading from "../customcomponents/loading";
import RemainingReward from "../customcomponents/remainingReward";
import RewardPoint from "../customcomponents/rewardPoint";
import { handleOnlinePayment } from "./payment/payment";
import { get_company_details } from "../../data/api";
import { MdError } from "react-icons/md";

export default function Recharge() {
   const { token } = Token();
   const UserDetails = token?.user;
   const options = [];
   let defaultOption = options[0];
   //FORM DATA

   const [cas_id, setCasid] = useState("");
   const [paymentactive, setPaymentactive] = useState(false);
   const [optionStatus, setOptionstatus] = useState(false);
   const [paymethod, setPaymethod] = useState("");
   const [rechargeamount, setRechargeamount] = useState(0);
   const [voucher, setVoucher] = useState("");
   const [bonusdata, setBonusData] = useState("");
   const [bonusamount, setBonusamount] = useState("");
   const [value, setValue] = React.useState("pin");
   const [bonusTotal, setBonusTotal] = useState(0);
   const [bonusprice, setBonusPrice] = useState("");
   const [show, setShow] = useState(false);
   const [message, setMessage] = useState("");
   const [error, setError] = useState(false);
   const [bonuscharge, setBonuscharge] = useState(0);
   const [bonusupdate, setBonusupdate] = useState(false);
   const recharge_amount = Math.round(rechargeamount);
   const [btnState, setBtnState] = useState(false);
   const [paymentstatus, setPaymentstatus] = useState(false);
   const [esewaStatus, setesewaStatus] = useState(false);
   const [khaltiStatus, setkhaltiStatus] = useState(false);
   const [customerId, setCustomerId] = useState(
      UserDetails?.customer_type?.includes("Combo") &&
         process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
         ? ""
         : UserDetails?.customer_id?.toString(),
   );
   const [loading, setLoading] = useState(false);
   const [paymentgateway, setPaymentgateway] = useState("");
   const [pinError, setPinError] = useState(false);
   const [rechargeAmountError, setRechargeamountError] = useState(false);
   const [rechargeAmountRewardError, setRechargeamountRewardError] = useState(false);

   const [selectStatus, setSelectStatus] = useState(false);
   const [khaltiId, setKhaltiId] = useState("");
   const [vouchername, setVouchername] = useState("");
   const [vouchermessage, setVouchermessage] = useState("");
   const [voucherid, setVoucherid] = useState("");
   const [vouchercode, setVouchercode] = useState("");
   const [errorVoucher, seterrorVoucher] = useState(false);
   const [discountamount, setDiscountamount] = useState(0);
   const [successVoucher, setsuccessVoucher] = useState(false);
   const [IsRegular, setIsRegular] = useState(false);
   const [DurationType, setDurationType] = useState("");
   const [rechargeLable, setRechargeLable] = useState("");
   const [rechargeType, setRechargeType] = useState(
      UserDetails?.customer_type?.includes("Combo") &&
         process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
         ? "other"
         : "self",
   );
   const [isOtherUserFetch, setIsOtherUserFetch] = useState(false);

   //BONSU API

   const sendVoucher = async () => {
      const response = await get_voucher_discount(rechargeamount, vouchername, "Recharge");
      if (response?.status === 200) {
         setVouchermessage(response?.data?.message);
         setDiscountamount(Math.round(response?.data?.data?.discountAmount));
         setVoucherid(response?.data?.data?.voucherId);
         setVouchercode(response?.data?.data?.voucherCode);
         setsuccessVoucher(true);
         seterrorVoucher(false);
      } else {
         setVouchermessage(response?.response?.data?.message);
         seterrorVoucher(true);
         setTimeout(() => {
            seterrorVoucher(false);
         }, 5000);
         setsuccessVoucher(false);
      }
   };
   const [serverError, setServerError] = useState(false);
   const [serverMessage, setServerMessage] = useState("");
   useEffect(() => {
      async function get_bonus() {
         const res = await get_recharge_list({
            rechargeType: rechargeType,
            customer_id: customerId,
         });
         // console.log(res?.response?.data?.message);
         if (rechargeType == "other" && !res?.data?.data) {
            setIsOtherUserFetch(false);
            setLoading(false);
            setMessage(
               "Recharge option not available for the provided customer ID. Please try again or contact ",
            );
            setError(true);
         } else if (res?.response?.data?.message) {
            setLoading(false);
            setServerError(true);
            setServerMessage(
               "There was a problem during recharge process. Please try again or contact",
            );
            setRechargeType("other");
         }
         const response = res?.data?.data;
         setBonusData(response ? response : []);
         setLoading(false);
      }
      rechargeType == "self" ? get_bonus() : isOtherUserFetch && get_bonus();
   }, [khaltiStatus, esewaStatus, isOtherUserFetch, rechargeType]);

   //calculate reward for users
   const [rewardDropdown, setRewardDropdown] = useState("");
   // useEffect(() => {
   //    async function get_bonus() {
   //       const res = await get_recharge_list({
   //          rechargeType: rechargeType,
   //          customer_id: customerId,
   //          type: "reward",
   //       });
   //       if (rechargeType == "other" && !res?.data?.data) {
   //          setIsOtherUserFetch(false);
   //          setLoading(false);
   //          setMessage(
   //             "Recharge Option Not Available for the provided Customer ID. please try again or contact ",
   //          );
   //          setError(true);
   //       } else if (res?.response?.data?.message) {
   //          setLoading(false);
   //          setServerError(true);
   //          setServerMessage(
   //             "There was a problem during recharge process. Please try again or contact",
   //          );
   //          setRechargeType("other");
   //       }
   //       const response = res?.data?.data;
   //       setRewardDropdown(response ? response : []);
   //       setLoading(false);
   //    }
   //    rechargeType == "self" ? get_bonus() : isOtherUserFetch && value == "reward" && get_bonus();
   // }, [isOtherUserFetch, rechargeType]);

   const [recharge_id, setRechargeid] = useState("");
   const [customerIDError, setCustomerIDError] = useState(false);
   useEffect(() => {
      Object.entries(bonusdata).map(([key, value]) => {
         const lable = `[${value?.Description}] - ${value?.Price?.toString()}`;
         if (lable == rechargeLable) {
            // console.log(rechargeLable);
            setBonusamount(value);
            setBonuscharge(value?.Bonus);
            setIsRegular(value?.IsRegular);
            setDurationType(value?.DurationType);
         }
      });
   }, [recharge_amount, bonusdata, bonusamount]);

   //BONUS TABLE Total

   let total = recharge_amount + bonuscharge;

   const handleRechargeChange = (event) => {
      setValue(event.target.value);
   };

   const schema = yup.object().shape({
      customer_id: yup.string().required(),
      amount: yup.string().required(),
   });

   const enterCasid = (e) => {
      setCasid(e.target.value);
   };
   //esewa verification
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let oid = query.get("oid");
   let refid = query.get("refId");
   let transid = query.get("transid");

   const handleCustomerId = async () => {
      const response = await get_customer_id(cas_id);
      setBtnState(true);
      if (response.data.CustomerId) {
         setCustomerId(response.data.CustomerId);
      } else {
         setMessage(response?.data?.message);

         setError(true);
         // setCustomerId("Invalid cas id");
      }
      setBtnState(false);
   };

   const handlePin = async (e) => {
      // setLoading(true);
      e.preventDefault();
      if (voucher === "") {
         setPinError(true);
         setTimeout(() => {
            setPinError(false);
         }, 5000);
      } else {
         const response = await pin(customerId, voucher, rechargeType);
         // console.log("voucher", voucher);

         if (response?.data?.ResponseCode === 10500) {
            setLoading(false);
            setError(true);
            setVoucher("");
            // setMessage(response?.response?.data?.message);
            setMessage("There was a problem during recharge process. Please try again or contact ");
         }
         if (response?.response?.status === 403) {
            setLoading(false);
            setError(true);
            setVoucher("");
            setMessage("There was a problem during recharge process. Please try again or contact");
            // setMessage(response?.response?.data?.message);
         } else if (response?.response?.status === 400) {
            setLoading(false);
            setError(true);
            setVoucher("");
            setMessage(`There was a problem during recharge process. Please try again or contact`);
            // setMessage(response?.response?.data?.message);
         } else if (response?.status === 200) {
            setLoading(false);
            setShow(true);
            // setMessage(response?.data?.message);
            setMessage(
               `Your account balance has been recharged with Rs. ${response?.data?.data?.total}. Thank you for using DishHome.`,
            );
            setVoucher("");
         }
         // setCustomerId("");
         // setVoucher("");
      }
   };
   useEffect(() => {
      if (oid) {
         setLoading(true);
      }
   }, [oid]);

   const handlePayment = async (e) => {
      e.preventDefault();
      setLoading(true);
      const response = await recharge({
         // recharge_id,
         recharge_amount: rechargeamount,
         customer_id: customerId,
         bonus_amount: bonuscharge,
         payment_method: paymentgateway,
         type: rechargeType,
         voucherid: voucherid,
         vouchercode: vouchercode,
         discount: discountamount,
         IsRegular: IsRegular,
         DurationType: DurationType,
      });
      if (response.status === 201) {
         // console.log("Recharge", response.data);
         if (paymentgateway !== "khalti" && paymentgateway !== "") {
            handleOnlinePayment({
               order_id: response?.data?.data?.orderId,
               payment_method: response?.data?.data?.payment_method,
               order_name: response?.data?.data?.order_name,
               total: response?.data?.data?.total,
               uid: UserDetails?.id,
            });
         } else if (paymentgateway === "khalti") {
            setMessage(`Your account balance has been recharged with Rs. ${response?.data?.data?.total}. Thank
            you for using DishHome.`);
            const data = await handleOnlinePayment({
               order_id: response?.data?.data?.orderId,
               payment_method: response?.data?.data?.payment_method,
               order_name: response?.data?.data?.order_name,
               total: response?.data?.data?.total,
               uid: UserDetails?.id,
               setSuccess: setShow,
            });
            const checkout = new KhaltiCheckout(data);
            setLoading(false);
            checkout.show({ amount: response?.data?.data?.total * 100 });
         }
      } else {
         setLoading(false);
         setError(true);
         setMessage(response?.response?.data?.message);
      }
   };

   const transactionId = refid;
   const [thirdparty, setThirdparty] = useState("");

   //check reward point
   const [reward, setReward] = useState(0);
   const [rewardThreshold, setRewardThreshold] = useState("");

   useEffect(() => {
      async function get_rewardpoint() {
         const res = await update_profile();
         // console.log("res", res);
         setReward(res?.data?.reward);
      }
      get_rewardpoint();
   }, []);

   useEffect(() => {
      async function getCompanyDetails() {
         const response = await check_min_balance();
         setRewardThreshold(response?.data?.data?.redeem_threshold);
      }
      getCompanyDetails();
   }, []);

   useEffect(() => {
      if (refid) {
         setThirdparty(transid);
      }
      if (khaltiId) {
         setThirdparty(khaltiId);
      }
   }, [transactionId, khaltiId, transid]);

   //recharge using reward

   const handlerechargeReward = async (e) => {
      e.preventDefault();
      if (rechargeamount <= 0) {
         setRechargeamountRewardError(true);
      } else {
         setLoading(true);
         const response = await reward_recharge(
            rechargeamount,
            customerId,
            bonuscharge,
            IsRegular,
            DurationType,
            rechargeType,
         );
         if (response?.status === 200) {
            // console.log("pay", response);
            setLoading(false);
            setShow(true);
            setMessage(
               `Your account balance has been recharged with Rs. ${response?.data?.recharge?.recharge_amount}. Thank you for using DishHome.`,
            );
            // setMessage(response?.response?.data?.message);
         } else {
            setLoading(false);
            setError(true);
            setMessage("There was a problem during recharge process. Please try again or contact.");

            // setMessage(response?.response?.data?.message);
         }
      }
   };

   const handleRecharge = (option) => {
      setRechargeamount(option.value);
      setRechargeLable(option.label);
      setOptionstatus(true);
   };
   //kyc modal
   const [kycModal, setKycModal] = useState(false);
   // console.log("status", selectStatus);
   // console.log("status", paymentactive);

   useEffect(() => {
      if (UserDetails?.is_kyc_verified === false) {
         setKycModal(true);
      }
   }, [UserDetails?.is_kyc_verified]);

   const [internetuser, setInternetuser] = useState(
      UserDetails?.customer_id?.charAt(0) === "0" ? true : false,
   );

   const [comboUser, setComboUser] = useState(
      UserDetails?.customer_type?.includes("Combo") &&
         process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
         ? true
         : false,
   );
   const [contactDetails, setContactDetails] = useState("");

   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);
   return loading ? (
      <Loading />
   ) : (
      <Layout>
         <MessageModal
            type="error"
            show={kycModal}
            setShow={setKycModal}
            heading="Please complete your profile for Recharge!"
            buttonText="Click Here"
            link="/dashboard?type=1"
            isNavigate={true}
         />
         <MessageModal
            show={show}
            setShow={setShow}
            // heading={"Recharged Succesfully"}
            message={message}
            buttonText={"Ok"}
            link="/"
            isNavigate={true}
         />
         <MessageModal
            show={error}
            setShow={setError}
            linkedLink="/company/support"
            linkedText={"DishHome customer support."}
            heading={"Recharge failed"}
            message={message}
            buttonText={"Ok"}
            type={"error"}
         />
         <MessageModal
            show={serverError}
            setShow={setServerError}
            linkedLink="/company/support"
            linkedText={"DishHome customer support."}
            heading={"Server Error"}
            message={serverMessage}
            buttonText={"Ok"}
            link={"/company/support"}
            type={"error"}
         />
         {/* <MessageModal
            type={"error"}
            show={internetuser}
            setShow={setInternetuser}
            message="Please recharge using Esewa or IME Pay for your account. You can also contact our customer service to recharge account."
            buttonText={"Home"}
            button2={"Contact Us"}
            link="/"
            link2="/company/contact-us"
            isNavigate={true}
         /> */}
         <MessageModal
            type={"error"}
            show={comboUser}
            setShow={setComboUser}
            message="The account you have tried to recharge is combo package and cannot be recharged via website. Please contact nearest dealer dai."
            buttonText={"Recharge Other Account"}
            button2={"Contact Us"}
            link2="/company/contact-us"
            linkedLink="/company/support"
            linkedText={"DishHome customer support."}
            isNavigate={false}
         />
         <div className="recharge-wrapper mb-5">
            <p
               style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
               }}
            >
               DishHome Recharge
            </p>
            <div className="buttonGroup_recharge_self_other">
               <Button
                  className={`buttonGroup_recharge_self ${
                     rechargeType === "self" && "recharge_active"
                  }`}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                     setComboUser(
                        UserDetails?.customer_type?.includes("Combo") &&
                           process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
                           ? true
                           : false,
                     );
                     setRechargeType(
                        UserDetails?.customer_type?.includes("Combo") &&
                           process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
                           ? "other"
                           : "self",
                     );
                     setCustomerId(
                        UserDetails?.customer_type?.includes("Combo") &&
                           process.env.REACT_APP_DISABLE_COMBO_USER_RECHARGE
                           ? ""
                           : UserDetails?.customer_id,
                     );
                     setIsOtherUserFetch(false);
                  }}
               >
                  Self
               </Button>
               <Button
                  className={`buttonGroup_recharge_other ${
                     rechargeType === "other" && "recharge_active"
                  }`}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                     setRechargeType("other");
                     setIsOtherUserFetch(false);
                     setCustomerId("");
                  }}
               >
                  Other
               </Button>
            </div>
            <FormControl>
               <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="pin"
                  name="radio-buttons-group"
                  value={value}
                  onChange={handleRechargeChange}
               >
                  <FormControlLabel
                     id="recharge-radio"
                     value="pin"
                     control={<Radio />}
                     label="Recharge using PIN"
                  />
                  <FormControlLabel value="online" control={<Radio />} label="Online Recharge" />
                  {/* {reward >= rewardThreshold && rewardDropdown?.length > 0 && (
                     <FormControlLabel
                        value="reward"
                        control={<Radio />}
                        label="Recharge by reward"
                     />
                  )} */}
               </RadioGroup>
            </FormControl>
            {(value === "online" || value === "reward") &&
               rechargeType == "other" &&
               !isOtherUserFetch && (
                  <>
                     <Form.Group>
                        <Form.Label id="dashboard-label">Customer ID</Form.Label>
                        <Form.Control
                           disabled={rechargeType === "self" ? true : false}
                           id="input-dashboard"
                           onChange={(e) => {
                              setCustomerIDError(false);
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[0-9\b]")) {
                                    setCustomerId(e.target.value);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    setCustomerId(e.target.value);
                                 }
                              } else {
                                 setCustomerId(e.target.value);
                              }
                           }}
                           type="tel"
                           name="customer_id"
                           value={customerId}
                        />
                     </Form.Group>
                     {/* Error */}
                     {customerIDError && (
                        <p className="py-1 fs-6 text-danger">Customer ID is required!</p>
                     )}
                     <Button
                        id="recharge-btn"
                        variant="secondary"
                        className="red"
                        type="submit"
                        onClick={() => {
                           customerId == "" && setCustomerIDError(true);
                           customerId !== "" && setIsOtherUserFetch(true);
                           customerId !== "" && setLoading(true);
                        }}
                     >
                        Continue
                     </Button>
                  </>
               )}
            <Form id="dashboard-formwidth">
               {(isOtherUserFetch || rechargeType == "self" || value === "pin") && (
                  <Form.Group>
                     <Form.Label id="dashboard-label">Customer ID</Form.Label>
                     <Form.Control
                        disabled={rechargeType == "other" && value === "pin" ? false : true}
                        id="input-dashboard"
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9\b]")) {
                                 setCustomerId(e.target.value);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 setCustomerId(e.target.value);
                              }
                           } else {
                              setCustomerId(e.target.value);
                           }
                        }}
                        type="tel"
                        name="customer_id"
                        value={customerId}
                     />
                  </Form.Group>
               )}
               {value === "pin" && (
                  <Form.Group style={{ marginTop: "20px" }}>
                     <Form.Label id="dashboard-label">Voucher PIN</Form.Label>
                     <Form.Control
                        id="input-dashboard"
                        type="string"
                        name="voucher_pin"
                        value={voucher}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                 setVoucher(e.target?.value);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 setVoucher(e?.target?.value);
                              }
                           } else {
                              setVoucher(e?.target?.value);
                           }
                        }}
                     />
                  </Form.Group>
               )}
               {pinError === true && (
                  <p style={{ color: "red", marginTop: "10px" }}>Voucher pin is required</p>
               )}
               {value === "online" && (isOtherUserFetch || rechargeType == "self") && (
                  <div>
                     <div className="recharge-dropdown">
                        <label style={{ marginTop: "20px" }} id="dashboard-label">
                           {" "}
                           Choose recharge amount <span>(NRs.)</span>
                        </label>{" "}
                        <br />
                        {Object.entries(bonusdata)?.map(([key, value]) => {
                           options.push({
                              value: value?.Price,
                              label: `[${value?.Description}] - ${value?.Price?.toString()}`,
                           });
                        })}
                        <Dropdown
                           id="input-dashboard"
                           options={options}
                           onChange={handleRecharge}
                           value={defaultOption}
                        />
                        {rechargeAmountError === true && (
                           <p style={{ marginTop: "10px", color: "red" }}>
                              Recharge amount is required!
                           </p>
                        )}
                     </div>

                     {/* <Table bordered size="sm" style={{ marginTop: "20px", width: "100%" }}>
                        <tbody>
                           <tr>
                              <td id="table-1">Voucher</td>
                              <td id="table-1">Bonus</td>
                              {discountamount > 0 && <td id="table-1">Discount</td>}
                              <td id="table-1">Amount you get</td>
                           </tr>
                           <tr>
                              <td id="table-recharge">
                                 Rs. {rechargeamount.toLocaleString("hi-IN")}
                              </td>
                              <td id="table-bonus">Rs. {bonuscharge.toLocaleString("hi-IN")}</td>
                              {discountamount > 0 && (
                                 <td id="table-bonus">
                                    Rs.{discountamount?.toLocaleString("hi-IN")}
                                 </td>
                              )}

                              <td id="table-bonus">Rs. {total.toLocaleString("hi-IN")}</td>
                           </tr>
                        </tbody>
                     </Table> */}
                     {/* <RemainingReward totalPrice={rechargeamount} /> */}
                     {discountamount > 0 && (
                        <p
                           style={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                           }}
                        >
                           Amount to pay:{" "}
                           <span className="text-success">
                              Rs. {rechargeamount - discountamount}
                           </span>
                        </p>
                     )}
                     <div style={{ marginTop: "20px" }}>
                        <p id="recharge-payment">Select Payment Method</p>
                        <div id="recharge-pay-wrapper">
                           {contactDetails?.disable_imepay === false && (
                              <div>
                                 <img
                                    src={imepay}
                                    alt="IME Pay"
                                    onClick={() => {
                                       setPaymentstatus(true);
                                       setPaymentactive(true);
                                       setSelectStatus(true);
                                       setPaymentgateway("imepay");
                                    }}
                                    className={`${
                                       paymentgateway === "imepay" ? "payment_border" : ""
                                    }`}
                                 />
                              </div>
                           )}
                           {contactDetails?.disable_esewa === false && (
                              <div>
                                 <img
                                    id={paymentgateway === "esewa" ? "" : "esewa-border"}
                                    src={pay}
                                    onClick={() => {
                                       setPaymentstatus(true);
                                       setPaymentactive(true);
                                       setSelectStatus(true);
                                       setPaymentgateway("esewa");
                                    }}
                                    alt="esewa"
                                    className={`${
                                       paymentgateway === "esewa" ? "payment_border" : ""
                                    }`}
                                 />
                              </div>
                           )}
                           {contactDetails?.disable_khalti === false && (
                              <div>
                                 <img
                                    src={khalti}
                                    onClick={() => {
                                       setPaymentstatus(true);
                                       setPaymentactive(true);
                                       setSelectStatus(true);
                                       setPaymentgateway("khalti");
                                    }}
                                    alt="khalti"
                                    className={`${
                                       paymentgateway === "khalti" ? "payment_border" : ""
                                    }`}
                                 />
                              </div>
                           )}
                           {contactDetails?.disable_connectips === false && (
                              <div>
                                 <img
                                    src={ips}
                                    alt="connect IPS"
                                    onClick={() => {
                                       setPaymentstatus(true);
                                       setSelectStatus(true);
                                       setPaymentactive(true);
                                       setPaymentgateway("connectips");
                                    }}
                                    className={`${
                                       paymentgateway === "connectips" ? "payment_border" : ""
                                    }`}
                                 />
                              </div>
                           )}
                           {contactDetails?.disable_prabhupay === false && (
                              <div>
                                 <img
                                    src={prabhupay}
                                    alt="Prabhu Pay"
                                    onClick={() => {
                                       setPaymentstatus(true);
                                       setSelectStatus(true);
                                       setPaymentactive(true);
                                       setPaymentgateway("prabhupay");
                                    }}
                                    className={`${
                                       paymentgateway === "prabhupay" ? "payment_border" : ""
                                    }`}
                                 />
                              </div>
                           )}

                           {/* <div>
                                 <img src={paypoint} />
                              </div> */}
                        </div>
                     </div>
                  </div>
               )}

               {value === "reward" && (isOtherUserFetch || rechargeType == "self") && (
                  <>
                     <div className="recharge-dropdown">
                        <label style={{ marginTop: "20px" }} id="dashboard-label">
                           {" "}
                           Choose recharge amount
                        </label>{" "}
                        <br />
                        {Object.entries(rewardDropdown).map(([key, value]) => {
                           options?.push({
                              value: value?.Price,
                              label: `[${value?.Description}] - ${value?.Price?.toString()}`,
                           });
                           return null;
                        })}
                        <Dropdown
                           id="input-dashboard"
                           options={options}
                           onChange={handleRecharge}
                           value={defaultOption}
                        />
                        {rechargeAmountRewardError === true && (
                           <p style={{ marginTop: "10px", color: "red" }}>
                              Recharge amount is required!
                           </p>
                        )}
                     </div>

                     <Table bordered size="sm" style={{ marginTop: "20px", width: "100%" }}>
                        <tbody>
                           <tr>
                              <td id="table-1">Voucher</td>
                              <td id="table-1">Bonus</td>
                              <td id="table-1">Amount you get</td>
                           </tr>
                           <tr>
                              <td id="table-recharge">
                                 Rs. {rechargeamount.toLocaleString("hi-IN")}
                              </td>

                              <td id="table-bonus">Rs. {bonuscharge.toLocaleString("hi-IN")}</td>
                              <td id="table-bonus">Rs. {total.toLocaleString("hi-IN")}</td>
                           </tr>
                        </tbody>
                     </Table>
                     <RewardPoint />
                  </>
               )}
               {value === "pin" && (
                  <>
                     <Button
                        id="recharge-btn"
                        variant="secondary"
                        className="red"
                        type="submit"
                        onClick={(e) => handlePin(e)}
                     >
                        Recharge
                     </Button>
                  </>
               )}
               {value === "online" && (isOtherUserFetch || rechargeType == "self") && (
                  <>
                     <InputGroup className="mt-4">
                        <input
                           style={{ height: "48px" }}
                           placeholder="Enter voucher"
                           value={vouchername}
                           onChange={(e) => setVouchername(e.target.value)}
                           type="text"
                        ></input>

                        <Button
                           style={{ height: "48px" }}
                           variant="outline-secondary"
                           id="button-addon2"
                           className={vouchername === "" ? "apply-button" : "apply-button-green"}
                           onClick={() => {
                              if (vouchername !== "") sendVoucher();
                           }}
                        >
                           Apply
                        </Button>
                     </InputGroup>
                     {errorVoucher === true && (
                        <Alert variant="danger" style={{ width: "100%", marginTop: "8px" }}>
                           <MdError />
                           &nbsp;&nbsp; {vouchermessage}
                        </Alert>
                     )}
                     {successVoucher === true && (
                        <Alert variant="success" style={{ width: "100%", marginTop: "8px" }}>
                           <MdError />
                           &nbsp;&nbsp; {vouchermessage}
                        </Alert>
                     )}
                     {(isOtherUserFetch || rechargeType == "self") && (
                        <Button
                           variant="secondary"
                           id={
                              optionStatus === true && paymentactive === true
                                 ? "recharge-btn"
                                 : "recharge-btn-inactive"
                           }
                           disabled={optionStatus === true && paymentactive === true ? false : true}
                           type="submit"
                           className="red"
                           onClick={(e) => handlePayment(e)}
                        >
                           Recharge
                        </Button>
                     )}
                  </>
               )}
               {value === "reward" && (isOtherUserFetch || rechargeType == "self") && (
                  <>
                     <Button
                        variant="secondary"
                        id={"recharge-btn"}
                        type="submit"
                        className="red"
                        onClick={(e) => handlerechargeReward(e)}
                     >
                        Recharge
                     </Button>
                  </>
               )}
               {isOtherUserFetch && (
                  <Button
                     variant="link"
                     type="submit"
                     className="features_link"
                     style={{
                        textAlign: "center",
                        color: "#0d6efd",
                        fontSize: "12px",
                        width: "100%",
                        marginTop: "6px",
                     }}
                     onClick={(e) => setIsOtherUserFetch(false)}
                  >
                     Change Customer ID
                  </Button>
               )}
            </Form>
            {/* )}
            </Formik> */}
         </div>
      </Layout>
   );
}
