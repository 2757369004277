import React, { useState, useEffect } from "react";
import Layout from "../include/layout";
import WidthContainer from "../customcomponents/widthcontainer";
import lines from "../../assets/aboutus_lines.webp";
import "../../scss/pages/aboutus.scss";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { get_aboutus, bod_message } from "../../data/api";
import Loading from "../customcomponents/loading";
import seoTags from "../../utils/seoTags";

const AboutUS = () => {
   const [aboutus, setAboutus] = useState("");
   const [bodMessage, setBodMessage] = useState("");
   const [cartupdate, setCartupdate] = useState(false);

   const [loading, setLoading] = useState(true);
   useEffect(() => {
      async function get_about() {
         const res = await get_aboutus();
         const response = res?.data?.data;
         setAboutus(response);
      }
      async function get_bod() {
         const res = await bod_message();
         const response = res?.data?.data;
         setBodMessage(response);
      }
      get_about();
      get_bod();
   }, [loading]);
   useEffect(() => {
      if (
         bodMessage === "" ||
         bodMessage === undefined ||
         aboutus === "" ||
         aboutus === undefined
      ) {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [aboutus, bodMessage]);
   return loading ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {seoTags("About Us | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}
         <WidthContainer>
            <div className="aboutus_wrapper">
               <div
                  className="aboutus__section fixed-width"
                  style={{ flexDirection: "column", marginTop: "60px" }}
               >
                  <h3 className="aboutus__title">
                     About Us
                     <img
                        src={lines}
                        height="34px"
                        width="25px"
                        alt=""
                        className="aboutus__lines"
                     />{" "}
                  </h3>
                  <div className="about_us_second">
                     <img
                        src={`${process.env.REACT_APP_FILE_URL}/${aboutus?.image}`}
                        alt=""
                        className="aboutus__image"
                     />
                  </div>

                  <div className="aboutus__client_info">
                     <div className="aboutus__client_info__item">
                        <h4 className="aboutus__client_info__title">
                           {aboutus?.numbers?.clients}+
                        </h4>
                        <p className="aboutus__client_info__text">Satisfied Clients</p>
                     </div>
                     <div className="aboutus__client_info__item">
                        <h4 className="aboutus__client_info__title">{aboutus?.numbers?.team}+</h4>
                        <p className="aboutus__client_info__text">Team Members</p>
                     </div>
                     <div className="aboutus__client_info__item">
                        <h4 className="aboutus__client_info__title">{aboutus?.numbers?.branch}+</h4>
                        <p className="aboutus__client_info__text">Branch All over Nepal</p>
                     </div>
                     <div className="aboutus__client_info__item">
                        <h4 className="aboutus__client_info__title">
                           {aboutus?.numbers?.dealers}+
                        </h4>
                        <p className="aboutus__client_info__text">Dealers</p>
                     </div>
                  </div>
                  <p className="aboutus__text">
                     {aboutus.description_1}
                     <br />
                     <br />
                     {aboutus.description_2}
                  </p>
               </div>
               <div
                  style={{
                     background: "#F2F1F6",
                     display: "flex",
                     flexDirection: "Column",
                     alignItems: "center",
                     padding: "100px 0px",
                     width: "100%",
                     justifyContent: "center",
                  }}
               >
                  <div
                     className="aboutus__section fixed-width"
                     style={{
                        // flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "50px",
                     }}
                  >
                     <div className="aboutus__section_two__img">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${aboutus?.mission?.image}`}
                           alt=""
                        />
                     </div>
                     <div className="aboutus__section_two__text">
                        <h3 className="aboutus__title">Our Mission & Visions.</h3>
                        <p className="aboutus__text">
                           <b> Mission –</b> {aboutus?.mission?.mission}
                        </p>
                        <p className="aboutus__text">
                           <b>Vision –</b> {aboutus?.mission?.vision}
                        </p>
                        {/* <p className="aboutus__text">
                           <b>Goal – </b> {aboutus?.mission?.goal}
                        </p> */}
                     </div>
                  </div>
               </div>
               <div
                  className="aboutus__section fixed-width"
                  style={{ flexDirection: "row", marginTop: "100px" }}
               >
                  <div className="aboutus__section_three mb-5">
                     <div className="aboutus__section_three__item">
                        <h4 className="aboutus__section_three__title">Our Values.</h4>
                        <div className="aboutus__section_three__card_container">
                           {Object.entries(aboutus?.values).map(([key, value]) => {
                              return (
                                 <div className="aboutus__section_three__card">
                                    <img
                                       src={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                       alt=""
                                       style={{
                                          marginBottom: "10px",
                                          heigth: "48px",
                                          width: "48px",
                                       }}
                                    />
                                    <h3 className="aboutus__section_three__card_title">
                                       {value?.title}
                                    </h3>
                                    <p className="aboutus__section_three__card_text">
                                       {value?.description}
                                    </p>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                     <div className="aboutus__section_three__img">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${aboutus?.values_image}`}
                           alt=""
                           style={{ width: "100%", height: "250px" }}
                        />
                     </div>
                  </div>
               </div>

               <AboutUsSlier bodMessage={bodMessage} aboutus={aboutus} />
            </div>
         </WidthContainer>
      </Layout>
   );
};

export const AboutUsSlier = ({ bodMessage, aboutus }) => {
   const [imgindex, setImgindex] = useState(0);

   return (
      <div className="aboutus_slider_container fixed-width">
         <div className="aboutUsSlider__img">
            <img src={`${process.env.REACT_APP_FILE_URL}/${bodMessage[imgindex]?.image}`} alt="" />
            <div className="slider">
               <Splide
                  options={{
                     type: "loop",
                     hasTrack: false,
                     perPage: 1,
                     perMove: 1,
                     autoHeight: true,
                     autoWidth: true,
                     speed: 1000,
                  }}
                  onMove={(splide, destIndex) => {
                     setImgindex(destIndex);
                  }}
               >
                  {Object.entries(bodMessage).map(([key, value]) => {
                     return (
                        <SplideSlide>
                           <SliderCard
                              text={value?.description}
                              name={value?.name}
                              position={value?.designation}
                           />
                        </SplideSlide>
                     );
                  })}
               </Splide>
            </div>
         </div>
         <div className="aboutUsSlider__text">
            <h2 className="heading">{aboutus?.message?.title}</h2>
            <p className="text">{aboutus?.message?.description}</p>
         </div>
      </div>
   );
};

export const SliderCard = ({ text, name, position }) => {
   return (
      <div className="slider__container">
         <p className="text">{text} </p>
         <p className="name">{name}</p>
         <p className="position">{position}</p>
      </div>
   );
};

export default AboutUS;
