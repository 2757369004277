import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import * as yup from "yup";
import { Alert, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "../scss/dashboard/dashboardpassword.scss";
import { change_password } from "../data/protectedapi";
import { MdError } from "react-icons/md";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import MessageModal from "../components/customcomponents/messageModal";
import { useNavigate } from "react-router";
import Captcha from "../components/include/captcha";

export default function DashboardPassword() {
   let navigate = useNavigate();

   const [message, setMessage] = useState("");
   const [errorform, setErrorform] = useState(false);
   const [showPassword, setshowPassword] = useState(false);
   const [showNewpassword, setshowNewpassword] = useState(false);
   const [showconfirmPassword, setshowconfirmPassword] = useState(false);
   const [messageModal, setMessageModal] = useState(false);
   const [error, setError] = useState(false);
   const [heading, setHeading] = useState("");

   const reRef = useRef();

   const schema = yup.object().shape({
      currentpassword: yup.string().required("Current Password is required"),
      newpassword: yup
         .string()
         .required("New Password is required")
         .matches(
            /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
            "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character and no whitespace",
         )
         .test("newpassword", "New password cannot be same as current password", function (value) {
            return this.parent.currentpassword !== value;
         }),
      confirmpassword: yup
         .string()
         .required("Confirm Password is required")
         .oneOf([yup.ref("newpassword")], "Passwords do not match"),
   });
   const confirmPassword = () => {
      setshowconfirmPassword(!showconfirmPassword);
   };
   const newPassword = () => {
      setshowNewpassword(!showNewpassword);
   };
   const togglePassword = () => {
      setshowPassword(!showPassword);
   };
   const handleSubmit = async (data) => {
      const captchaToken = await reRef?.current?.executeAsync();
      const res = await change_password(
         data.currentpassword,
         data.newpassword,
         data.confirmpassword,
         captchaToken,
      );
      if (res.status === 200) {
         setMessageModal(true);
         setMessage("Your password has been changed successfully. Thank you for using DishHome");
         setHeading("Verification Successful");
      } else {
         setError(true);
         setHeading("Verification Failed");
         setMessage("Please Enter the correct current password");
         setErrorform(true);
         setTimeout(() => {
            setErrorform(false);
         }, 5000);
      }
   };
   return (
      <div>
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading={heading}
            message={message}
            buttonText="OK"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={heading}
            message={message}
            buttonText="OK"
            type={"error"}
         />
         <h2 id="billings">Change Password</h2>
         <p id="billings-details">A secure password helps to protect your account</p>
         <ToastContainer />
         <Formik
            validationSchema={schema}
            initialValues={{
               currentpassword: "",
               newpassword: "",
               confirmpassword: "",
            }}
            onSubmit={(values, actions) => {
               setTimeout(() => {
                  handleSubmit(values);
                  actions.resetForm();
               });
            }}
         >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
               <Form id="dashboard-formwidth" noValidate onSubmit={handleSubmit}>
                  {errorform ? (
                     <Alert variant="danger" style={{ width: "249px" }}>
                        <MdError />
                        &nbsp;&nbsp; {message}
                     </Alert>
                  ) : null}
                  <Form.Group controlId="validationFormik01">
                     <div className={"Password_Container"}>
                        <Form.Label id="dashboard-label">Current Password</Form.Label>
                        <Form.Control
                           id="input-dashboard currentpw"
                           type={showPassword ? "text" : "password"}
                           name="currentpassword"
                           controlId="validationFormik01"
                           value={values.currentpassword}
                           onChange={handleChange}
                           style={{ paddingRight: "38px" }}
                           isValid={touched.currentpassword && !errors.currentpassword}
                           onPaste={(e) => {
                              e.preventDefault();
                              return false;
                           }}
                        />
                        {!showPassword && (
                           <AiOutlineEyeInvisible
                              size={19}
                              className={"Pw_Icon"}
                              onClick={togglePassword}
                           />
                        )}
                        {showPassword && (
                           <span className={"Pw_Icon"} onClick={togglePassword}>
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                    fill="#374253"
                                 />
                              </svg>{" "}
                           </span>
                        )}
                     </div>

                     <p className="error">{errors.currentpassword}</p>
                  </Form.Group>
                  <Form.Group id="label-distance" controlId="validationFormik01">
                     <div className={"Password_Container"}>
                        <Form.Label id="dashboard-label">New password</Form.Label>
                        <Form.Control
                           id="input-dashboard newpw"
                           type={showNewpassword ? "text" : "password"}
                           name="newpassword"
                           controlId="validationFormik01"
                           value={values.newpassword}
                           onChange={handleChange}
                           style={{ paddingRight: "38px" }}
                           isValid={touched.newpassword && !errors.newpassword}
                           onPaste={(e) => {
                              e.preventDefault();
                              return false;
                           }}
                        />
                        {!showNewpassword && (
                           <AiOutlineEyeInvisible
                              size={19}
                              className={"Pw_Icon"}
                              onClick={newPassword}
                           />
                        )}
                        {showNewpassword && (
                           <span className={"Pw_Icon"} onClick={newPassword}>
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                    fill="#374253"
                                 />
                              </svg>{" "}
                           </span>
                        )}
                     </div>
                     {errors.newpassword && values?.newpassword !== "" && (
                        <p className="error">{errors.newpassword}</p>
                     )}
                  </Form.Group>
                  <Form.Group id="label-distance" controlId="validationFormik01">
                     <div className={"Password_Container"}>
                        <Form.Label id="dashboard-label">Confirm New Password</Form.Label>
                        <Form.Control
                           id="input-dashboard confpw"
                           type={showconfirmPassword ? "text" : "password"}
                           name="confirmpassword"
                           value={values.confirmpassword}
                           onChange={handleChange}
                           style={{ paddingRight: "38px" }}
                           isValid={touched.confirmpassword && !errors.confirmpassword}
                           onPaste={(e) => {
                              e.preventDefault();
                              return false;
                           }}
                        />
                        {!showconfirmPassword && (
                           <AiOutlineEyeInvisible
                              size={19}
                              className={"Pw_Icon"}
                              onClick={confirmPassword}
                           />
                        )}
                        {showconfirmPassword && (
                           <span className={"Pw_Icon"} onClick={confirmPassword}>
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                    fill="#374253"
                                 />
                              </svg>{" "}
                           </span>
                        )}

                        {errors?.confirmpassword && values?.confirmpassword !== "" && (
                           <p className="error">{errors.confirmpassword}</p>
                        )}
                     </div>
                  </Form.Group>
                  <div className="d-flex" style={{ gap: "16px" }}>
                     <Button variant="secondary" id="dashboardform-btn" type="submit">
                        Save Changes
                     </Button>
                     <div
                        id="dashboardform-btn"
                        className="btn btn-primary d-flex align-items-center justify-content-center px-3"
                        onClick={() => navigate("/dashboard?type=0")}
                     >
                        Cancel
                     </div>
                  </div>
                  <Captcha reRef={reRef} />
               </Form>
            )}
         </Formik>
      </div>
   );
}
