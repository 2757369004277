import React, { useState } from "react";
import "../../scss/ppvmovies/currentmoviecard.scss";
import { format } from "date-fns";
import movie from "../../assets/ppvmovies/movie.png";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";

const CurrentMovieCard = (props) => {
   let Navigate = useNavigate();
   const [show, setShow] = useState(false);
   const slug = props.trailer_link.substring(props.trailer_link.indexOf("=") + 1);
   const [showChannel, setShowChannel] = useState("");

   return (
      <>
         <Modal show={show} onHide={() => setShow(false)} centered dialogClassName="trailer_modal">
            <Modal.Body>
               <iframe
                  width="850"
                  height="500"
                  src={"https://www.youtube.com/embed/" + slug + "?autoplay=1"}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
               ></iframe>
            </Modal.Body>
         </Modal>
         <div className="currentmoviecard_wrapper mx-2 m-1 mb-5">
            <div className="currentmoviecard_img">
               <img src={props.image} alt="Movie" className="movie_image" />
               <div className="currentmoviecard_info">
                  <button
                     className="currentmoviecard_button"
                     onClick={() => {
                        // window.location = props.trailer_link;
                        setShow(true);
                     }}
                  >
                     Trailer
                  </button>
                  <button
                     className="currentmoviecard_button"
                     onClick={() => {
                        Navigate("/ppv-movies/" + props.id);
                     }}
                  >
                     Watch Now
                  </button>
               </div>
            </div>
            <div className="currentmoviecard_text ps-2">
               <h4>{props.title}</h4>
               {/* <p>Show Channel : {showChannel}</p> */}
            </div>
            {/* <div className="currentmoviecard_showtime ps-2">
               {Object.entries(props.showtime).map(([key, value]) => {
                  var date1 = new Date(key);
                  var currentdate = new Date();
                  // console.log(currentdate);
                  // console.log(value);
                  const shows = value;

                  if (currentdate.getDate() === date1.getDate()) {
                     return (
                        <>
                           {shows.map((show) => {
                              var showtime = new Date(show.EventStartDate);
                              showChannel === "" && setShowChannel(show?.PPVDisplayTitle);
                              let offset = 60000 * 345;
                              let newtime = showtime?.getTime();
                              let time = newtime + offset;
                              return (
                                 <button className="showtime">
                                    {format(new Date(time), "hh:mm a")}
                                 </button>
                              );
                           })}
                        </>
                     );
                  }
               })}
            </div> */}
         </div>
      </>
   );
};

export default CurrentMovieCard;
