import { React, useState, useEffect } from "react";
import Internetpackage from "../customcomponents/internet/internetpackage";
import Recommended from "../customcomponents/internet/recommended";
import Internetcard from "../customcomponents/internet/internetcard";
import Internetbanner from "../customcomponents/internet/internetbanner";
import Layout from "../include/layout";
import { get_internet_components } from "../../data/api";
import Loading from "../customcomponents/loading";
import Widthcontainer from "../customcomponents/widthcontainer";
import internetFooter from "../../assets/internetfooter2.png";
import "../../scss/pages/internet.scss";
import { useNavigate } from "react-router";
import SeoTags from "../../utils/seoTags";

export default function Internet() {
   const [internetData, setInternetData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [internetPackage, setInternetPackage] = useState("");
   const [banner, setBanner] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [linkedInternetbanner, setlinkedInternetbanner] = useState("");

   //get all internet data
   useEffect(() => {
      async function fetchData() {
         const response = await get_internet_components();
         setInternetData(response.data);
      }
      fetchData();
   }, [empty]);

   //retreive internet data from internet banner
   useEffect(() => {
      setInternetPackage(internetData?.internetPackage);
      setlinkedInternetbanner(internetData?.linkedInternetPackage);
      setBanner(internetData?.banners);
      setSeoData(internetData?.seo);
      if (internetData?.seo === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {
            Object.entries(banner).map(([key, value]) => {
               if (value.page_location === "internet_top") {
                  return <Internetbanner data={value} muted={false} />;
               } else return null;
            })
            // banner[0] === undefined ? null : <Internetbanner data={banner[0]} />}
         }
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "internet") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}internet`,
               );
            } else {
               return null;
            }
         })}
         <Internetpackage internetPackage={internetPackage} />
         <Recommended />
         <Internetcard internetPackage={internetPackage} linked={linkedInternetbanner} />
         <InternetFooter />
      </Layout>
   );
}

export const InternetFooter = () => {
   let navigate = useNavigate();
   return (
      <Widthcontainer>
         <div className="internetFooter fixed-width75 ">
            <div className="img" style={{ flexBasis: "47%" }}>
               <img src={internetFooter} width="100%" alt="" />
            </div>
            <div className="text" style={{ marginTop: "45px" }}>
               <h3 className="heading">
                  {/* Want Our */}
                  Your Internet, Your Choice!
                  <br />
                  <span> Dish Home Plus Internet Bundle</span>
               </h3>
               <p className="subheading">
                  {/* DishHome Offers variety of packages as per
                  <br /> your need. */}
                  Your Gateway to Seamless Entertainment.
               </p>
               <button
                  className="btn"
                  onClick={() => {
                     navigate("/internet/plans");
                  }}
               >
                  See All
               </button>
            </div>
         </div>
      </Widthcontainer>
   );
};
