import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import "../scss/components/iptv.scss";
import { get_active_iptv_movies, get_iptv_order_history } from "../data/protectedapi";
import Loading from "../components/customcomponents/loading";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";

export default function Iptvppv() {
   let navigate = useNavigate();
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let option = query.get("option");
   const [activeCategory, setActiveCategory] = useState(option ? option : "active");

   return (
      <div className="dashboard-ppv">
         <div className="dashboard-ppv__header">
            <h2 className="dashboard-ppv__header-title">IPTV PPV</h2>
            <h2 className="dashboard-ppv__header-text">
               Find out detailed billing and subscription information here.
            </h2>
         </div>
         <div className="ms-2 mt-3 mb-3 order_options">
            <div
               className={
                  activeCategory === "active" ? "order_options__item active" : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=14&option=active");
                  setActiveCategory("active");
               }}
            >
               Active Movies
            </div>
            <div
               className={
                  activeCategory === "order" ? "order_options__item active" : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=14&option=order");
                  setActiveCategory("order");
               }}
            >
               Order History
            </div>
         </div>
         {activeCategory === "order" ? <OrderHistory /> : <ActiveMovies />}
      </div>
   );
}

function OrderHistory() {
   const [order, setOrder] = useState("");
   const [pageCount, setPageCount] = useState("");
   const [selectedPage, setSelectedPage] = useState(1);
   const [loading, setLoading] = useState(true);

   const handlePageChange = (data) => {
      setSelectedPage(data.selected + 1);
   };

   useEffect(() => {
      async function get_all_orders() {
         const res = await get_iptv_order_history(selectedPage);
         if (res?.status === 200) {
            const response = res?.data?.data;
            setOrder(response);
            setPageCount(res?.data?.paging?.pages);
            setLoading(false);
         } else {
            setLoading(false);
         }
      }
      get_all_orders();
   }, [loading, selectedPage]);

   // console.log(order);
   return loading ? (
      <Loading />
   ) : (
      <div>
         <div className="billing-table">
            <div>
               <p id="bill-header">Name</p>
            </div>
            <div>
               <p id="bill-header">Order ID</p>
            </div>
            <div>
               <p id="bill-header">Duration</p>
            </div>
            <div>
               <p id="bill-header">Amount</p>
            </div>
            <div>
               <p id="bill-header">Payment Method</p>
            </div>
            <div>
               <p id="bill-header">Payment Status</p>
            </div>
         </div>
         {order &&
            Object.entries(order).map(([key, value]) => {
               // console.log(value);
               return <BillingFeatures data={value} />;
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"← Previous"}
               nextLabel={"Next →"}
               pageCount={pageCount}
               onPageChange={handlePageChange}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {order?.length === 0 && <h6 style={{ color: "darkgrey" }}>No Order History Found</h6>}
      </div>
   );
}
function BillingFeatures({ data }) {
   return (
      <div className="billing-features">
         <div>
            <p>{data?.name}</p>
         </div>
         <div>
            <p> {data?.order_id}</p>
         </div>
         <div>
            <p>{`${data?.duration} ${data?.duration_type}`}</p>
         </div>
         <div>
            <p>Rs. {data?.total} </p>
         </div>
         <div>
            <p style={{ textTransform: "capitalize" }}>{data?.payment_method}</p>
         </div>
         <div>
            <p>
               {data?.is_paid == true ? (
                  <span style={{ color: "green" }}>Paid</span>
               ) : (
                  <span style={{ color: "red" }}>Unpaid</span>
               )}
            </p>
         </div>
      </div>
   );
}

function ActiveMovies() {
   const [ActiveMovies, setActiveMovies] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      async function get_movies() {
         const data = await get_active_iptv_movies();
         if (data?.status === 200) {
            setActiveMovies(data?.data?.data);
            setLoading(false);
         } else {
            setLoading(false);
         }
      }
      get_movies();
   }, [loading]);
   // console.log(ActiveMovies);
   return loading ? (
      <Loading />
   ) : (
      <div className="d-flex flex-wrap" style={{ gap: "24px" }}>
         {ActiveMovies?.map((movie) => {
            return (
               <div className="iptv-movie-card">
                  <div className="iptv-movie-card__image">
                     <img src={movie?.movie_logo} alt="" />
                  </div>
                  <div className="iptv-movie-card__title  mb-0 ms-1">
                     <p className="m-0 mt-2">{movie?.name}</p>
                  </div>
                  <div className="iptv-movie-card__price m-0 ms-1">
                     <p className="m-0 mt-2">
                        <span className="spam_expiery">
                           {" "}
                           {moment(movie?.expiry_date).format("YYYY-MM-DD H:mm")}
                        </span>
                     </p>
                  </div>
               </div>
            );
         })}
      </div>
   );
}
