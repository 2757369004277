import React, { useState, useEffect } from "react";
import "../scss/dashboard/activePackage.scss";
import tick from "../assets/tick.svg";
import credit from "../assets/credit.webp";
import { PieChart } from "react-minimal-pie-chart";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Token from "../utils/useToken";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
   change_package,
   check_balance,
   get_credit_extension,
   update_profile,
} from "../data/protectedapi";
import { Button, Modal } from "react-bootstrap";
import MessageModal from "../components/customcomponents/messageModal";
import { upgrade_package } from "../data/protectedapi";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { format, parse } from "date-fns";
import Loading from "../components/customcomponents/loading";
import { UserCard } from "./dashboardItems";
import { FaSatelliteDish } from "react-icons/fa";
import { MdRouter } from "react-icons/md";
import { BiWalletAlt } from "react-icons/bi";
import IPTV from "../assets/dashboard/iptv.png";

const ActivePackage = () => {
   const { token } = Token();
   const userDetails = token.user;
   const [show, setShow] = useState(false);
   const [error, setError] = useState(false);
   const [message, setMessage] = useState("");
   const [balance, setBalance] = useState("");
   const [creditmodal, setCreditmodal] = useState(false);
   const [packagemodal, setpackagemodal] = useState(false);
   const [packageoption, setPackageoption] = useState("");

   const [reward, setReward] = useState(0);
   const customerId = userDetails.customer_id;
   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setReward(res?.data?.reward);
      }
      get_navigation();
   }, [reward]);
   const modalclose = () => {
      setCreditmodal(false);
   };
   const closePackage = () => {
      setpackagemodal(false);
      setCreditmodal(false);
   };
   //balance information
   useEffect(() => {
      async function get_balance() {
         if (userDetails?.is_kyc_verified === true) {
            const res = await check_balance();
            const response = res?.data?.data;
            setBalance(response);
         }
      }
      get_balance();
   }, [show]);
   // console.log("userDetails", userDetails);
   const openModal = () => {
      setCreditmodal(true);
   };
   const openPackage = () => {
      setpackagemodal(true);
   };
   const handleCredit = async () => {
      setCreditmodal(false);
      const response = await get_credit_extension(customerId);
      if (response?.response?.success === true) {
         setShow(true);
         setMessage(response?.response?.data.message);
      } else {
         setError(true);
         setMessage(response?.response?.data.message);
      }
   };
   const [packageUpgraddeData, setpackageUpgraddeData] = useState("");
   const handlePackage = async (type) => {
      // console.log(type);
      setPackageoption("");
      setpackageUpgraddeData("");
      setpackagemodal(true);
      const response = await upgrade_package(type);
      if (response?.status === 200) {
         // console.log("response", response?.data?.data);
         //filter type if there is response?.data?.data
         const data = response?.data?.data;
         setpackageUpgraddeData(data);
         setPackageoption(data);

         // setMessage("Credit upgraded");
      }
   };
   //upgrade
   const [value, setValue] = useState("");
   const handleChange = (event) => {
      setValue(event.target.value);
   };
   const changePackage = async () => {
      const data = JSON.parse(value);
      const res = await change_package(data?.packageName, data?.CategoryId);
      if (res.status === 200) {
         setShow(true);
         setMessage("Package Upgraded");
         setpackagemodal(false);
      } else {
         // console.log("error", res?.response?.data?.ResponseDescription);
         setError(true);
         setMessage(res?.response?.data?.ResponseDescription);
         setpackagemodal(false);
      }
   };

   return (
      <div className="dashboardview__wrapper">
         <Modal
            centered
            show={packagemodal}
            onHide={closePackage}
            animation={false}
            id="deletecart-modal"
         >
            {/* <Modal.Header closeButton id="modal-header"></Modal.Header> */}
            <Modal.Body id="modal-body">
               {packageoption?.length !== 0 ? (
                  <div>
                     <h2 style={{ marginBottom: "26px" }}>Select package to upgrade</h2>
                     <div
                        style={{
                           display: "flex",
                           width: "100%",
                           marginLeft: "18px",
                        }}
                     >
                        <div
                           className="package_radiolabel"
                           style={{ width: "100%", marginRight: "24px" }}
                        >
                           <FormControl style={{ display: "flex", widht: "100%" }}>
                              <RadioGroup
                                 aria-labelledby="demo-controlled-radio-buttons-group"
                                 name="controlled-radio-buttons-group"
                                 value={value}
                                 onChange={handleChange}
                                 style={{ width: "100%" }}
                              >
                                 {packageoption.map((val, idx) => (
                                    <span
                                       style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                       }}
                                    >
                                       <span
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                             alignItems: "flex-start",
                                             flexBasis: "60%",
                                          }}
                                       >
                                          <FormControlLabel
                                             value={JSON.stringify(val)}
                                             control={<Radio />}
                                             label={val?.title}
                                             style={{ marginBottom: "0px" }}
                                          />
                                          <a
                                             href={val?.link}
                                             target="_blank"
                                             style={{
                                                color: "#606BE0",
                                                textDecoration: "none",
                                                fontSize: "12px",
                                                marginTop: "-8px",
                                                marginBottom: "12px",
                                                marginLeft: "28px",
                                             }}
                                          >
                                             View Package <AiOutlineArrowRight />
                                          </a>
                                       </span>
                                       <span
                                          style={{
                                             display: "flex",
                                             alignItems: "center",
                                             marginBottom: "6px",
                                             marginLeft: "12px",
                                          }}
                                       >
                                          Rs.{" "}
                                          {val?.prices[0].price
                                             ? val?.prices[0].price?.toLocaleString("hi-IN")
                                             : val?.prices[0]?.internet_price.toLocaleString(
                                                  "hi-IN",
                                               )}
                                          *<sub>/{val?.prices[0]?.type} </sub>
                                          <br />
                                       </span>
                                       {/* <br /> */}
                                    </span>
                                 ))}
                              </RadioGroup>
                           </FormControl>
                        </div>
                        {/* <div>
                           {packageprice
                              .toString()
                              .split(",")
                              .map((val, idx) => (
                                 <p
                                    style={{
                                       height: "48px",
                                       margin: "0px",
                                       display: "flex",
                                       alignItems: "center",
                                    }}
                                 >
                                    Rs. {val.toLocaleString("hi-IN")}
                                 </p>
                              ))}
                        </div> */}
                     </div>
                  </div>
               ) : packageUpgraddeData === "" ? (
                  <div
                     style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                     }}
                  >
                     <ReactLoading type="cylon" color="#ed1c24" height={305} width={305} />
                  </div>
               ) : (
                  <h4 style={{ textAlign: "center" }}>No Package to Upgrade</h4>
               )}
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               {!(packageUpgraddeData?.length === 0 || value === "") && (
                  <Button
                     style={{ backgroundColor: "red", color: "white" }}
                     onClick={() =>
                        packageUpgraddeData?.length === 0 ? closePackage() : changePackage()
                     }
                     variant="secondary"
                     id="cancel"
                     disabled={packageUpgraddeData?.length === 0 || value === "" ? true : false}
                  >
                     OK
                  </Button>
               )}
               <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => closePackage()}
                  variant="secondary"
                  id="cancel"
               >
                  Close
               </Button>
               {/* <Button
                  variant="primary"
                  onClick={() => {
                     handlePackage();
                  }}
                  id="removecart"
               >
                  Yes
               </Button> */}
            </Modal.Footer>
         </Modal>
         <Modal
            centered
            show={creditmodal}
            onHide={modalclose}
            animation={false}
            id="deletecart-modal"
         >
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body">
               <div>
                  <img src="" alt="" />
               </div>
               <p className="cartdelete-title" style={{ textAlign: "center" }}>
                  Are you sure?
               </p>
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               <Button variant="secondary" onClick={() => closePackage()} id="cancel">
                  Cancel
               </Button>
               <Button
                  variant="primary"
                  onClick={() => {
                     handleCredit();
                  }}
                  id="removecart"
               >
                  Yes
               </Button>
            </Modal.Footer>
         </Modal>
         <MessageModal
            show={show}
            setShow={setShow}
            message={message}
            heading={"Success"}
            buttonText={"Ok"}
            link="/dashboard"
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Failed"}
            message={message}
            buttonText={"Ok"}
            type={"error"}
            link="/dashboard"
         />
         <h1 className="welcome__text">
            Welcome,{" "}
            {token?.user?.name?.charAt(0).toUpperCase() + token?.user?.name?.split(" ")[0].slice(1)}
         </h1>
         <p className="welcome_subtext">Here’s what’s happening in your DishHome account today</p>
         <div className="rewardpoint">
            <img src={credit} alt="" height={23} width={23} />
            {reward ? ` ${Math.round(reward)} Reward Points` : "No reward collected"}
         </div>
         {userDetails?.is_kyc_verified ? (
            <>
               {balance ? (
                  <PackageCard
                     balance={balance}
                     openModal={openModal}
                     handlePackage={handlePackage}
                  />
               ) : (
                  <Loading />
               )}
            </>
         ) : (
            <>
               <hr></hr>
               <p className="ps-2">Please Complete your information for full details</p>
            </>
         )}
      </div>
   );
};

export function PackageCard({ balance, openModal, handlePackage }) {
   const { userDetails } = Token();
   let navigate = useNavigate();

   let expired = false;
   const now = new Date();
   const expieryDate = new Date(balance?.ExpiryDate);
   if (expieryDate < now) {
      expired = true;
   }
   // console.log("Hello", balance?.Package);

   // userDetails?.customer_id?.charAt(0) === "0" ? (
   //    <>
   //       {balance?.Package?.map((data) => {
   //          let expired = false;
   //          const now = new Date();
   //          const expieryDate = new Date(data?.deactivation_date);
   //          if (expieryDate < now) {
   //             expired = true;
   //          }
   //          return (
   //             <div className="plan__wrapper">
   //                <div className="plan__item__details">
   //                   <div className="Plan__item__heading">
   //                      <h3>Current Active Plan</h3>
   //                      <p>{data?.plan === "" ? "NA" : data?.plan}</p>
   //                   </div>
   //                   <div className="Plan__item__features">
   //                      <ItemFeature heading="Status" value={data?.status} />
   //                      {data?.status === "active" && data?.deactivation_date && (
   //                         <ExpiryDate heading="Expiry Date" value={data?.deactivation_date} />
   //                      )}
   //                      <ItemFeature
   //                         heading="Subscription Cost"
   //                         value={`Rs. ${data?.price.toLocaleString("hi-IN")}*`}
   //                      />
   //                   </div>
   //                   <div
   //                      style={{
   //                         display: "flex",
   //                         gap: "35px",
   //                         marginTop: "34px",
   //                         justifyContent: "space-around",
   //                      }}
   //                   >
   //                      {/* {data?.status === "active" && expired && (
   //                         <Button
   //                            onClick={openModal}
   //                            style={{ backgroundColor: "red", color: "white", border: "none" }}
   //                         >
   //                            Credit extension
   //                         </Button>
   //                      )} */}
   //                      {/* <Button
   //                         onClick={handlePackage}
   //                         style={{ backgroundColor: "red", color: "white", border: "none" }}
   //                      >
   //                         Upgrade package
   //                      </Button> */}
   //                   </div>
   //                </div>
   //             </div>
   //          );
   //       })}
   //    </>
   // );

   return (
      <>
         <div className="plan__wrapper mb-3">
            <div className="plan__item__details">
               <h3 className="current_active_plan">Current Active Plan</h3>
               {balance?.packages?.map((data) => {
                  return data?.type == "IPTV" ? null : (
                     <div className="Plan__item__heading">
                        <p>
                           <div
                              style={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 borderRadius: "50%",
                                 padding: "8px",
                                 boxShadow: "0 0 6px 3px rgba(0, 0, 0, 0.1)",
                              }}
                           >
                              {
                                 data?.type == "DTH" ? (
                                    <FaSatelliteDish fontSize={20} color="#f00" />
                                 ) : data?.type == "Internet" ? (
                                    <MdRouter fontSize={22} color="#f00" />
                                 ) : (
                                    <img src={IPTV} height={24} width={22} />
                                 )
                                 // (
                                 //    balance?.packages?.map((d) => {
                                 //       return d?.type == "IPTV" ? (
                                 //          <img src={IPTV} height={24} width={22} />
                                 //       ) : d?.type == "Internet" ? (
                                 //          <MdRouter fontSize={22} />
                                 //       ) : null;
                                 //    }))
                              }
                           </div>
                           {data?.name}
                        </p>
                        <div
                           className="btn custombtn"
                           onClick={() =>
                              handlePackage(
                                 data?.type == "DTH"
                                    ? "tv"
                                    : data?.type == "Internet"
                                    ? "internet"
                                    : "",
                              )
                           }
                        >
                           Upgrade Package
                        </div>
                     </div>
                  );
               })}
               <div className="Plan__item__features">
                  <ItemFeature heading="Status" value={balance?.CustomerStatus} />
                  {balance?.CustomerStatus === "Prepaid On" && (
                     <ExpiryDate heading="Expiry Date" value={balance?.ExpiryDate} />
                  )}
                  <ItemFeature
                     heading="Subscription Cost"
                     value={`Rs. ${balance?.SubscriptionCost.toLocaleString("hi-IN")}*`}
                  />
               </div>

               <div className="button_container_activepackage">
                  {balance?.CustomerStatus === "Prepaid Off" && expired && (
                     <Button
                        onClick={openModal}
                        className="red"
                        style={{
                           backgroundColor: "red",
                           color: "white",
                           border: "none",
                           fontSize: "17px",
                           borderRadius: "35px",
                           padding: "10px 16px",
                        }}
                     >
                        <p
                           className="m-0"
                           style={{
                              fontSize: "17px",
                           }}
                        >
                           Credit extension
                        </p>
                     </Button>
                  )}
                  {/* {balance?.CustomerStatus === "Prepaid Off" && <></>} */}

                  <Button
                     className="red"
                     onClick={() => navigate("/recharge")}
                     style={{
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        fontSize: "17px",
                        borderRadius: "35px",
                        padding: "10px 16px",
                     }}
                  >
                     <BiWalletAlt fontSize={20} />
                     <p
                        className="m-0"
                        style={{
                           fontSize: "17px",
                        }}
                     >
                        Recharge
                     </p>
                  </Button>
               </div>
            </div>

            {/* <div className="Plan__chart">
                     <p className="piechart_title">Disconnection Date</p>
                     <PieChart
                        data={[{ title: "Channels", value: 20, color: "#3E7CF6" }]}
                        lineWidth={18}
                        totalValue={30}
                        background="#E9F0FE"
                        viewBoxSize={[100, 100]}
                        radius={40}
                        center={[50, 43]}
                        label={({ dataEntry }) => dataEntry.value + "\n days"}
                        labelPosition={0}
                        labelStyle={{
                           fontSize: "8px",
                           fill: "#0E9E49",
                        }}
                     />
                  </div> */}
         </div>
      </>
   );
}

export function ItemFeature(props) {
   return (
      <div className="item__feature">
         <h2>
            <img src={tick} height={18} width={18} alt="tick" />
            &nbsp;&nbsp;{props.heading} :
         </h2>
         <p style={{ textTransform: "capitalize" }}>{props.value}</p>
      </div>
   );
}
export function ExpiryDate(props) {
   let expired = false;
   const { userDetails } = Token();
   const now = new Date();
   const expieryDate = new Date(props.value);
   const newExpieryDate = parse(props.value, "dd-MM-yyyy", new Date());
   if (newExpieryDate < now) {
      expired = true;
   }
   if (expieryDate < now) {
      expired = true;
   }
   // console.log(isNaN(now - newExpieryDate) ? now - expieryDate : now - newExpieryDate);
   return (isNaN(now - newExpieryDate) ? now - expieryDate : now - newExpieryDate) >
      999999999999 ? null : (
      <div className="item__feature">
         <h2>
            <img src={tick} height={18} width={18} alt="tick" />
            &nbsp;&nbsp;{props.heading} :
         </h2>
         <p style={expired ? { color: "#ed1c24" } : {}}>
            {expired
               ? "Expired"
               : userDetails?.customer_id?.charAt(0) !== "0"
               ? format(new Date(props?.value), "dd MMM yyyy")
               : format(newExpieryDate, "dd MMM yyyy")}
         </p>
      </div>
   );
}

export default ActivePackage;
