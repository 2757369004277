import React, { useState, useEffect } from "react";
import "../scss/dashboard/dashboardPpv.scss";
import { get_customer_ppv } from "../data/protectedapi";
import noMovie from "../assets/dashboard/noMovie.png";
import noImage from "../assets/dashboard/noImage.png";
// import CurrentMovieCard from "../ppvmovies/pages/currentmoviecard";
import format from "date-fns/format";
import ReactPaginate from "react-paginate";

const DashboardPpv = () => {
   //use States
   const [ppv, setPpv] = useState("");
   const [isEmpty, setIsEmpty] = useState(true);
   const [pageCount, setPageCount] = useState("");
   const [displayPPV, setDisplayPPV] = useState([]);

   //UseEffect
   useEffect(() => {
      async function get_ppv() {
         const response = await get_customer_ppv();
         const ppv = response?.data?.data?.reverse();
         setPpv(ppv);
         setPageCount(Math.ceil(ppv?.length / 6));
         setDisplayPPV(ppv?.slice(0, 6));
      }
      get_ppv();
   }, [isEmpty]);

   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 6;
      const newArray = ppv.slice(offset, offset + 6);
      setDisplayPPV(newArray);
   };

   useEffect(() => {
      if (ppv?.length === 0 || ppv === undefined) {
         setIsEmpty(true);
      } else {
         setIsEmpty(false);
      }
   }, [ppv]);

   return (
      <div className="dashboard-ppv">
         <div className="dashboard-ppv__header">
            <h2 className="dashboard-ppv__header-title">PPV</h2>
            <h2 className="dashboard-ppv__header-text">
               Find out detailed billing and subscription information here.
            </h2>
         </div>
         <div className="dashboard-ppv__body mb-5">
            {isEmpty ? (
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "600px",
                     width: "100%",
                  }}
               >
                  <img src={noMovie} alt="" />
               </div>
            ) : (
               Object.entries(displayPPV).map(([key, value]) => {
                  console.log(value);
                  return (
                     <DashboardPpvCard
                        name={value?.ppv_id?.title}
                        showtime={value.showtime}
                        image={value?.ppv_id?.image}
                     />
                  );
               })
            )}
         </div>
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination mt-5"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
      </div>
   );
};

export function DashboardPpvCard({ name, image, showtime }) {
   //useState

   let offset = 60000 * 345;
   let newtime = new Date(showtime)?.getTime();
   let time = newtime + offset;

   return (
      <>
         <div className="dashboard-ppv__movieCard">
            <img src={image ? `${process.env.REACT_APP_FILE_URL}/${image}` : noImage} alt="" />
            <div className="dashboard-ppv__movieCard-info">
               <h3 className="dashboard-ppv__movieCard-info-title">{name}</h3>
               <h2 className="showtime cursor_normal">
                  {format(new Date(time), "yyyy-MM-dd hh:mm a")}
               </h2>
            </div>
         </div>
      </>
   );
}

export default DashboardPpv;
