import React, { useState, useEffect } from "react";
import "../scss/dashboard/dashboardbillings.scss";
import dash from "../assets/dashboard/dash.png";
import { get_transaction_history } from "../data/protectedapi";
import Token from "../utils/useToken";
import Loading from "../components/customcomponents/loading";
import ReactPaginate from "react-paginate";
import { format } from "date-fns";
import ErrorPage from "../components/pages/errorPage";

export default function DashboardBillings() {
   const [transactionData, settransactionData] = useState("");
   const [allData, setAllData] = useState([]);
   const [pageCount, setPageCount] = useState();
   const { userDetails } = Token();
   //get transaction data
   useEffect(() => {
      async function getTransactionData() {
         const response = await get_transaction_history();
         setAllData(response?.data?.History);
         setPageCount(Math.ceil(response?.data?.History?.length / 15));
         settransactionData(response?.data?.History?.slice(0, 15));
      }
      getTransactionData();
   }, [userDetails, pageCount]);

   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 15;
      const newArray = allData.slice(offset, offset + 15);
      settransactionData(newArray);
   };

   return userDetails?.is_kyc_verified === false ? (
      <h6 style={{ color: "darkgrey" }}>Please Complete your profile to get billing history</h6>
   ) : allData === undefined || allData === "" ? (
      <Loading />
   ) : (
      <div style={{ width: "100%" }} className="dashboardBilling_Wrapper">
         <h2 id="billings">Billings</h2>
         <p id="billings-details">Find out detailed billing and subscription information here.</p>

         {allData !== null && (
            <div className="billing-table">
               <div>
                  <p id="bill-header">Bill Type</p>
               </div>
               <div>
                  <p id="bill-header">Bill Date</p>
               </div>
               <div>
                  <p id="bill-header">Amount</p>
               </div>
               {/* <div>
               <p id="bill-header">Status</p>
            </div> */}
            </div>
         )}
         {transactionData &&
            Object.entries(transactionData).map(([key, value]) => {
               return (
                  <BillingData
                     title={value?.TransactionType}
                     date={value?.TransactionDate}
                     amount={value?.Amount}
                  />
               );
            })}
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {allData === null && <h6 style={{ color: "darkgrey" }}>No Billing History</h6>}
      </div>
   );
}

export const BillingData = ({ title, date, amount }) => {
   return (
      <div className="billing-features">
         <div>
            <p>{title}</p>
         </div>
         <div>
            <p> {format(new Date(date), "dd LLL, yyyy")}</p>
         </div>
         <div>
            <p>Rs. {(amount * -1).toLocaleString("hi-IN")}</p>
         </div>
      </div>
   );
};
