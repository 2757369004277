import React, { useEffect, useState } from "react";
import "../../../scss/packages/channellist.scss";
import tv from "../../../assets/package/tv.png";
import exclusive from "../../../assets/package/exclusive.png";
import TvChannelCard from "./tvchannelcard";
import CategoryCard from "./categorycard";
import { get_individual_package } from "../../../data/api";
import { useParams } from "react-router";
import Loading from "../../../components/customcomponents/loading";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";

const ChannelList = () => {
   const [packageData, setPackageData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [channelData, setChannelData] = useState("");
   const [activeCategory, setActiveCategory] = useState("");
   const [activeCategoryData, setActiveCategoryData] = useState("");

   let params = useParams();
   let { packagename } = params;
   // console.log(params);
   useEffect(() => {
      async function fetchData() {
         const response = await get_individual_package(packagename);
         // console.log("res", response);
         setPackageData(response?.data);
         // console.log("Package Data:", response.data.data);
         packageData &&
            setActiveCategory(packageData?.packageChannelTypeChannelpackage[0]?._id?.title);
      }
      fetchData();
   }, [empty]);
   // console.log("packagedata", packageData);
   useEffect(() => {
      if (packageData === "" || packageData === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
         setChannelData(packageData?.packageChannelTypeChannelpackage);
      }
   }, [packageData]);
   // console.log("channeldata", channelData);
   useEffect(() => {
      if (activeCategory === "") {
         setActiveCategoryData("");
      } else {
         for (const key in channelData) {
            if (channelData[key]?._id?.title === activeCategory) {
               setActiveCategoryData(channelData[key]?.channels);
            }
         }
      }
   }, [activeCategory]);
   // console.log("channel", channelData);
   return empty ? null : (
      <Widthcontainer>
         <div className="channellist_wrapper fixed-width">
            <div className="channellist_text my-5">
               <p className="mb-4">Best in Nepal</p>
               <h1>
                  The Dish<span>Home</span> TV Channels
               </h1>
            </div>
            <div className="channellist_category pb-4 mb-4">
               {channelData &&
                  Object?.entries(channelData).map(([key, value]) => {
                     // console.log("channels", value);
                     return (
                        <CategoryCard
                           classname={value?._id?.title === activeCategory ? "active" : "inactive"}
                           img={
                              value?._id?.image
                                 ? `${process.env.REACT_APP_FILE_URL}/${value?._id?.image}`
                                 : exclusive
                           }
                           text={value?._id?.title}
                           setActiveCategory={setActiveCategory}
                        />
                     );
                  })}
            </div>
            <div className="channellist_channelcontainer mt-2">
               {Object.entries(activeCategoryData).map(([key, value]) => {
                  return (
                     <TvChannelCard
                        img={value?.channel_id?.image}
                        channelName={value?.channel_id?.name}
                     />
                  );
               })}
            </div>
         </div>
      </Widthcontainer>
   );
};

export default ChannelList;
