import React from "react";

export default function Widthcontainer({ children, className }) {
   return (
      <div
         className={className}
         style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         {children}
      </div>
   );
}
