import React, { useState, useMemo, useEffect } from "react";

import { useLocation, useNavigate } from "react-router";
import "../scss/components/order.scss";
import RechargeTransaction from "./rechargeTransaction";
import PackageackageTransaction from "./packageTransaction";
import ProductTransaction from "./productTransaction";
import { get_company_details } from "../data/api";
import PpvTransactions from "./PpvTransaction";

export default function DashboardTransaction() {
   const [companyDetails, setCompanyDetails] = useState("");
   const [empty, setEmpty] = useState(true);

   useEffect(() => {
      async function get_details() {
         const res = await get_company_details();
         setCompanyDetails(res?.data?.data);
      }
      get_details();
   }, [empty]);
   let navigate = useNavigate();
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let option = query.get("option");
   const [activeCategory, setActiveCategory] = useState(option ? option : "product");
   // console.log("active", activeCategory);
   return (
      <div className="dashboardOrderHistory__Wrapper">
         <h2 id="billings">Billings</h2>
         <p id="billings-details">Find out detailed billings information here.</p>
         <div className="ms-2 mt-3 mb-3 order_options">
            <div
               className={
                  activeCategory === "product"
                     ? "order_options__item active"
                     : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=2&option=product");
                  setActiveCategory("product");
               }}
            >
               Product Orders
            </div>
            <div
               className={
                  activeCategory === "package"
                     ? "order_options__item active"
                     : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=2&option=package");
                  setActiveCategory("package");
               }}
            >
               Package Orders
            </div>
            <div
               className={
                  activeCategory === "ppv" ? "order_options__item active" : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=2&option=ppv");
                  setActiveCategory("ppv");
               }}
            >
               PPV Orders
            </div>
            <div
               className={
                  activeCategory === "recharge"
                     ? "order_options__item active"
                     : "order_options__item"
               }
               onClick={() => {
                  navigate("/dashboard?type=2&option=recharge");
                  setActiveCategory("recharge");
               }}
            >
               Recharge History
            </div>
         </div>
         {companyDetails && companyDetails?.disable_store === true ? (
            <PackageackageTransaction />
         ) : activeCategory === "product" ? (
            <ProductTransaction />
         ) : activeCategory === "ppv" ? (
            <PpvTransactions />
         ) : activeCategory === "recharge" ? (
            <RechargeTransaction />
         ) : (
            <PackageackageTransaction />
         )}
      </div>
   );
}
