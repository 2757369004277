import React, { useState } from "react";
import movie from "../../assets/ppvmovies/movie.png";
import "../../scss/ppvmovies/nextmoviecard.scss";
import { Modal } from "react-bootstrap";

const NextMovieCard = ({ img, title, trailer_link }) => {
   const [show, setShow] = useState(false);

   const slug = trailer_link.substring(trailer_link.indexOf("=") + 1);

   return (
      <>
         <Modal show={show} onHide={() => setShow(false)} centered dialogClassName="trailer_modal">
            <Modal.Body>
               <iframe
                  width="850"
                  height="500"
                  src={"https://www.youtube.com/embed/" + slug + "?autoplay=1"}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
               ></iframe>
            </Modal.Body>
         </Modal>
         <div className="nextmoviecard_wrapper m-1 mb-5">
            <div className="nextmoviecard_img ">
               <img
                  src={img === undefined ? movie : `${process.env.REACT_APP_FILE_URL}/${img}`}
                  alt="Movie"
                  height={370}
                  width={250}
                  className="nextmoviecard_img_img"
               />
               <div className="nextmoviecard_info">
                  <button
                     className="nextmoviecard_button"
                     onClick={() => {
                        // window.location = props.trailer_link;
                        setShow(true);
                     }}
                  >
                     Trailer
                  </button>
               </div>
            </div>
            <div className="nextmoviecard_text ps-2">
               <p>{title === undefined ? "Bloodshot" : title}</p>
            </div>
         </div>
      </>
   );
};

export default NextMovieCard;
