import React, { useMemo, useState, useEffect } from "react";
import Layout from "../../components/include/layout";
import "../../scss/ppvmovies/moviepayment.scss";
import { useLocation, useParams, useNavigate } from "react-router";
import Token from "../../utils/useToken";
import { get_single_ppv_movie } from "../../data/api";
import Loading from "../../components/customcomponents/loading";
import { format } from "date-fns";
import { order_ppv_movies } from "../../data/protectedapi";
import MessageModal from "../../components/customcomponents/messageModal";
import { get_stb_number } from "../../data/protectedapi";
import { Alert, Form } from "react-bootstrap";
import esewa from "../../assets/store/esewa.webp";
import ips from "../../assets/store/ips.png";
import ime from "../../assets/store/ime.png";
import khalti from "../../assets/cart/khalti.png";
import arrowvector from "../../assets/ott/arrow.png";

import { MdError } from "react-icons/md";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import KhaltiCheckout from "khalti-checkout-web";

import axios from "axios";
import { handleOnlinePayment } from "../../components/pages/payment/payment";
import { AiOutlineArrowRight } from "react-icons/ai";

const MoviePayment = () => {
   //params
   const productParams = useParams();
   //use Query params
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   // React Router parameters
   const parameters = useParams();
   const { movieid } = parameters;
   const { userDetails } = Token();
   let query = useQuery();
   let showtime_id = query.get("showtimeId");
   let querystbid = query.get("querystbid");
   let querycustomerID = query.get("querycustomerID");
   let navigate = useNavigate();

   //useState
   const [MovieData, setMovieData] = useState("");
   const [Showdata, setShowdata] = useState("");
   const [loading, setLaoding] = useState(true);
   const [ShowtimeData, setShowtimeData] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [message, setMessage] = useState("");
   const [errorMessage, seterrorMessage] = useState(false);
   const [customerid, setCustomerid] = useState(
      querycustomerID ? querycustomerID : userDetails?.customer_id,
   );
   const [stbid, setStbid] = useState(querystbid ? querystbid : "");
   const [stbdata, setStbdata] = useState("");
   const [errorstatus, setErrorstatus] = useState(false);
   const [value, setValue] = React.useState("");
   const [paymentgateway, setPaymentgateway] = useState("");
   const [isloading, setIsLoading] = useState(false);
   const [paymentStatus, setPaymentStatus] = useState("");
   const [btnState, setBtnState] = useState(true);
   const [couponState, setCouponState] = useState(false);

   const [couponCode, setCouponCode] = useState("");

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   function findCommonElements(arr1, arr2) {
      return arr1 && arr2 && arr1?.some((item) => arr2?.includes(item));
   }

   const paymentMethods = ["esewa", "connectips", "ime", "khalti"];

   //useEffect
   useEffect(() => {
      async function get_movie() {
         const response = await get_single_ppv_movie(movieid);
         setMovieData(response.data.data);
      }
      get_movie();
   }, [movieid]);

   useEffect(() => {
      async function get_showtime() {
         if (MovieData === "") {
            setLaoding(true);
         } else {
            setShowdata(MovieData?.showtimes[0]?.shows);
            for (const key in Showdata) {
               // console.log(key, Showtimedata[key]);
               for (const key2 in Showdata[key]) {
                  // console.log(key2, Showtimedata[key][key2].Id);
                  if (Showdata[key][key2].Id.toString() === showtime_id) {
                     // console.log(Showdata[key][key2]);
                     setShowtimeData(Showdata[key][key2]);
                     // console.log(key);
                  }
               }
            }
         }
      }

      get_showtime();
   });

   useEffect(() => {
      if (ShowtimeData !== "") {
         setLaoding(false);
      }
   }, [ShowtimeData, customerid]);

   useEffect(() => {
      const getStbData = async () => {
         if (customerid !== null) {
            const response = await get_stb_number(customerid);
            setStbdata(response?.data?.data);
            setStbid(response?.data?.data[0]);
         }
      };
      getStbData();
   }, [customerid]);
   //Console Logs
   // console.log(MovieData);
   // console.log(movieid);
   // console.log(showtime_id);
   // console.log(userDetails);
   // console.log(Showdata);
   // console.log(ShowtimeData);

   const subTotal = (ShowtimeData?.PriceAmount * 100) / 113;
   const tax = ShowtimeData?.PriceAmount - subTotal;
   const totalAmount = tax + subTotal;

   //Functions

   const [packageLoading, setpackageLoading] = useState(false);

   const handleOrder = async (type) => {
      setBtnState(false);
      // setLaoding(true);
      setErrorstatus(false);
      const response = await order_ppv_movies(
         movieid,
         MovieData?.title,
         ShowtimeData?.PriceAmount.toString(),
         ShowtimeData?.EventStartDate,
         showtime_id,
         ShowtimeData?.PriceAmount.toString(),
         type === "coupon" ? "coupon" : paymentgateway,
         customerid,
         stbid,
         couponCode,
      );
      if (response) {
         setIsLoading(false);
         setBtnState(true);
      }
      console.log(response);
      const ppvMovie = response?.data?.data;
      if (response?.status === 201) {
         const onlinePayment = ["khalti", "esewa", "imepay", "connectips"];
         if (onlinePayment.includes(ppvMovie?.payment_method)) {
            // if (ppvMovie?.payment_method === "khalti") {
            //    const data = await handleOnlinePayment({
            //       order_id: ppvMovie?.orderId,
            //       payment_method: ppvMovie?.payment_method,
            //       order_name: ppvMovie?.order_name,
            //       uid: userDetails?.id,
            //       setSuccess: setMessageModal,
            //    });
            //    const checkout = new KhaltiCheckout(data);
            //    checkout.show({
            //       amount: ppvMovie?.total * 100,
            //    });
            //    return;
            // } else {
            handleOnlinePayment({
               order_id: ppvMovie?.orderId,
               payment_method: ppvMovie?.payment_method,
               order_name: ppvMovie?.order_name,
               uid: userDetails?.id,
               total: ppvMovie?.total,
            });
            // }
         } else {
            setBtnState(true);
            setpackageLoading(false);
            // setLaoding(false);
            // setIsLoading(false);
            // setBtnloading(false);
            setMessageModal(true);
         }
      } else {
         setBtnState(true);
         setpackageLoading(false);
         setMessage(response?.response?.data?.message);
         seterrorMessage(true);
      }
   };
   let showtime = new Date(ShowtimeData?.EventStartDate);
   let offset = 60000 * 345;
   let newtime = showtime?.getTime();
   let time = newtime + offset;

   return loading || packageLoading ? (
      <Loading />
   ) : (
      <Layout>
         {stbdata.length !== 0 ? (
            <></>
         ) : (
            <>
               <div
                  className="ott_top"
                  style={{
                     backgroundColor: "#ED1C24",
                     height: "fit-content",
                     textAlign: "center",
                     color: "#fff",
                  }}
               >
                  <p
                     style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "16px",
                        paddingTop: "15px",
                     }}
                  >
                     Please install DTH TV package to watch PPV movies
                  </p>
                  <a
                     href="/company/contact-us"
                     style={{ textDecoration: "none", fontSize: "14px", color: "#fff" }}
                  >
                     Do it now <img src={arrowvector} />
                  </a>
               </div>
            </>
         )}

         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading="Movie Purchased"
            message="Thank you for your purchase! You will get an email and SMS shortly regarding your purchase."
            buttonText="Go to Home Page"
            link="/"
            isNavigate={true}
         />
         <MessageModal
            show={errorMessage}
            setShow={seterrorMessage}
            heading="Something Went wrong"
            message={message}
            buttonText="Ok"
            type={"error"}
         />
         {/* {userDetails?.is_kyc_verified === false ? (
            <div
               className="ott_top"
               style={{
                  backgroundColor: "#1A63F4",
                  height: "76px",
                  textAlign: "center",
                  color: "#fff",
               }}
            >
               <p
                  style={{
                     color: "#FDF8F2",
                     fontSize: "14px",
                     fontWeight: "400",
                     lineHeight: "6px",
                     paddingTop: "15px",
                  }}
               >
                  Please fill the kyc form for using this OTT feature with Dishhome. It will take
                  2 minutes to fill the form
               </p>
               <a
                  href="/dashboard?type=1"
                  style={{ textDecoration: "none", fontSize: "14px", color: "#fff" }}
               >
                  Do it now <img src={arrowvector} />
               </a>
            </div>
         ) : (
            ""
         )} */}

         <div className="movie_payment_wrapper">
            <div className="movie_payment_container my-5 fixed-width">
               <div>
                  <div className="movie_payment_stbNumber mb-5">
                     <h2 className="stb_title mb-2">Select STB </h2>
                     <Alert variant="danger" className={"stb_Alert" + (errorstatus ? "_show" : "")}>
                        <MdError />
                        &nbsp;&nbsp; Please Enter Valid STB Number
                     </Alert>
                     <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                           <label className="movie_payment_stbNumber_label" htmlFor="stb">
                              Customer ID
                           </label>
                           <input
                              disabled
                              className="movie_payment_stbNumber_input"
                              type="text"
                              name="stb"
                              id="stb"
                              required
                              value={customerid}
                              onChange={(e) => setCustomerid(e.target.value)}
                           />
                        </div>
                        {/* <button className="stb_btn" onClick={getStbData}>
                           Get STB Number
                        </button> */}
                     </div>
                     <label className="movie_payment_stbNumber_label mt-3" htmlFor="stb">
                        STB Number
                     </label>
                     <select
                        className="movie_payment_stbNumber_input"
                        name="stb"
                        id="stb"
                        onChange={(e) => setStbid(e.target.value)}
                     >
                        {stbdata === ""
                           ? null
                           : stbdata.map((item) => {
                                return <option value={item}>{item}</option>;
                             })}
                     </select>
                  </div>
                  <div className="payment_method">
                     <h2 className="payment_title mb-3 ps-3">Select Payment Method</h2>
                     <div className="walletPayment ps-4">
                        {MovieData?.payment_method_enabled?.includes("wallet") && (
                           <Form.Check
                              type="radio"
                              label="Wallet"
                              name="paymentMethod"
                              id="wallet"
                              className="walletPayment_radio mb-2 ms-3"
                              style={{ padding: "0" }}
                              checked={paymentgateway === "user_balance"}
                              onChange={() => {
                                 setPaymentStatus(false);
                                 setPaymentgateway("user_balance");
                              }}
                           />
                        )}
                        {findCommonElements(paymentMethods, MovieData?.payment_method_enabled) && (
                           <Form.Check
                              type="radio"
                              label="Online Payment"
                              name="paymentMethod"
                              id="online"
                              className="onlinePayment_radio mb-2 ms-3"
                              style={{ padding: "0" }}
                              onChange={() => {
                                 setPaymentStatus(true);
                                 setPaymentgateway("");
                              }}
                           />
                        )}
                     </div>

                     {paymentStatus && (
                        <div className="payment_methods ms-4">
                           {MovieData?.payment_method_enabled?.includes("esewa") && (
                              <div>
                                 <img
                                    src={esewa}
                                    onClick={() => {
                                       setPaymentStatus(true);
                                       setPaymentgateway("esewa");
                                    }}
                                    className={`${
                                       paymentgateway === "esewa" ? "payment_border" : ""
                                    }`}
                                    style={
                                       paymentgateway === "esewa"
                                          ? {}
                                          : { border: "1px solid #E6EAF0", borderRadius: "16px" }
                                    }
                                 />
                              </div>
                           )}
                           {MovieData?.payment_method_enabled?.includes("khalti") && (
                              <div>
                                 <img
                                    src={khalti}
                                    className={`${
                                       paymentgateway === "khalti" ? "payment_border" : ""
                                    }`}
                                    onClick={() => {
                                       setPaymentStatus(true);
                                       setPaymentgateway("khalti");
                                    }}
                                 />
                              </div>
                           )}
                           {MovieData?.payment_method_enabled?.includes("connectips") && (
                              <div>
                                 <img
                                    src={ips}
                                    className={`${
                                       paymentgateway === "connectips" ? "payment_border" : ""
                                    }`}
                                    onClick={() => {
                                       setPaymentStatus(true);
                                       setPaymentgateway("connectips");
                                    }}
                                 />
                              </div>
                           )}
                           {MovieData?.payment_method_enabled?.includes("imepay") && (
                              <div>
                                 <img
                                    src={ime}
                                    className={`${
                                       paymentgateway === "imepay" ? "payment_border" : ""
                                    }`}
                                    onClick={() => {
                                       setPaymentStatus(true);
                                       setPaymentgateway("imepay");
                                    }}
                                 />
                              </div>
                           )}
                        </div>
                     )}
                  </div>
               </div>
               <div className="summary_wrapper ">
                  <h5 className="summary_wrapper_text mb-3">Summary</h5>
                  <div className="summary_movie_details ">
                     <div className="img_container">
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${MovieData.thumbnail}`}
                           alt=""
                        />
                     </div>
                     <div className="movie_details ">
                        <p className="movie_title">Movie : {MovieData.title}</p>
                        <p className="showtime">Show time : {format(new Date(time), "hh:mm a")}</p>
                        <p className="channel_name">Air at : {ShowtimeData?.PPVDisplayTitle}</p>
                        <p className="amount">
                           Amount : Rs. {ShowtimeData?.PriceAmount.toLocaleString("hi-IN")}
                        </p>
                     </div>
                  </div>
                  <div className="summary_price mt-3">
                     <p className="total_text">Total</p>
                     <p className="price">
                        Rs. {ShowtimeData?.PriceAmount.toLocaleString("hi-IN")}
                     </p>
                  </div>
                  {MovieData?.coupon_applicable && (
                     <>
                        <Form.Group>
                           <input
                              type="text"
                              placeholder="Enter Coupon Code"
                              id="input-dashbaord"
                              className="w-100 mb-4"
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                           />
                        </Form.Group>
                        <button
                           className="pay_now mb-3"
                           onClick={
                              btnState && couponCode !== "" ? () => handleOrder("coupon") : null
                           }
                           disabled={
                              stbdata.length !== 0 || btnState || couponCode === "" ? false : true
                           }
                           style={btnState && couponCode !== "" ? {} : { backgroundColor: "grey" }}
                        >
                           {btnState ? "Redeem Now" : "Loading..."}
                        </button>
                        <p className="or_text text-center">OR</p>
                     </>
                  )}
                  <button
                     className="pay_now mb-4"
                     onClick={btnState ? () => handleOrder("normal") : null}
                     disabled={stbdata.length !== 0 && btnState && paymentgateway ? false : true}
                     style={btnState && paymentgateway ? {} : { backgroundColor: "grey" }}
                  >
                     {btnState ? "Subscribe Now" : "Loading..."}
                  </button>
               </div>
            </div>
         </div>
      </Layout>
   );
};

export default MoviePayment;
