import React, { useEffect, useState, useMemo } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import Img from "../../assets/faq_top.webp";
import "../../scss/pages/faq.scss";
import { get_faq_category, get_faq, get_faq_banner } from "../../data/api";
import Loading from "../customcomponents/loading";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useLocation } from "react-router";
import { AiOutlineUnorderedList, AiOutlineClose } from "react-icons/ai";
import { Offcanvas } from "react-bootstrap";
import seoTags from "../../utils/seoTags";
import HeroBanner from "../customcomponents/herobanner";

const Faq = () => {
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let type = query.get("type");
   const [faqCategory, setFaqCategory] = useState("");
   const [activeTitle, setActiveTitle] = useState("");
   const [empty, setEmpty] = useState(true);
   const [faqData, setFaqData] = useState("");
   const [activeId, setActiveId] = useState("");
   const [faqBanner, setFaqBanner] = useState("");
   const [cartupdate, setCartupdate] = useState(false);

   //banner
   useEffect(() => {
      //get refer data
      const getFaqData = async () => {
         const res = await get_faq_banner();
         setFaqBanner(res?.data?.banners);
         // setSeoData(res?.data?.seo);
         // setReferData(response?.data?.data);
         // setLoading(false);
      };
      getFaqData();
   }, []);

   useEffect(() => {
      async function fetchData() {
         const response = await get_faq_category();
         const faq_res = await get_faq();
         console?.log(response?.data?.data);
         let _FOUND = 0;
         for (var i = 0; i < response?.data?.data?.length; i++) {
            if (response?.data?.data[i]?.title?.toUpperCase() === type?.toUpperCase()) {
               // __FOUND is set to the index of the element
               _FOUND = i;
               break;
            }
         }
         setFaqCategory(response?.data?.data);
         setActiveTitle(response.data.data[_FOUND]?.title);
         setActiveId(response.data.data[_FOUND]?._id);
         setFaqData(faq_res.data.data);
      }
      fetchData();
   }, [empty, type]);
   let navigate = useNavigate();

   useEffect(() => {
      if (faqData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   return empty ? (
      <Loading />
   ) : (
      <Layout t cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {seoTags("FAQ's | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}
         {Object.entries(faqBanner).map(([key, value]) => {
            if (value.page_location === "faq_top") {
               return <HeroBanner data={value} />;
            } else return null;
         })}{" "}
         {/* <Widthcontainer className={"faq_width_container"}>
            <div className="faq_banner fixed-width">
               <div className="faq_banner_text">
                  <p className="faq">FAQs </p>
                  <h1 className="faq_header">
                     Have any <span>Questions?</span>
                  </h1>
                  <p className="faq_sub_header">
                     Get to know questions asked by our customers If you run into any problems at
                     all, feel free to contact us anytime
                  </p>
               </div>
               <div className="faq_banner_img">
                  <img src={Img} alt="top" />
               </div>
            </div>
         </Widthcontainer> */}
         <Widthcontainer>
            <div className="faq_container fixed-width">
               <div className="faq_container_category">
                  {Object.entries(faqCategory).map(([key, value]) => {
                     return (
                        <button
                           className={
                              activeTitle === value.title
                                 ? "category_button active"
                                 : "category_button"
                           }
                           onClick={() => {
                              navigate(`/company/faqs?type=${value.title}`);
                           }}
                        >
                           {value.title}
                        </button>
                     );
                  })}
               </div>

               <div className="faq_container_content">
                  <div className="table_of_content_btn" onClick={handleShow}>
                     <AiOutlineUnorderedList />
                     Table of Contents
                  </div>
                  <Offcanvas show={show} onHide={handleClose} style={{ width: "230px" }}>
                     <Offcanvas.Body style={{ backgroundColor: "#ED1C24", color: "#FFFFFF" }}>
                        <div className="table_of_content">
                           <div
                              className="close_btn"
                              style={{
                                 display: "flex",
                                 marginTop: "16px",
                                 fontSize: "20px",
                                 fontWeight: "600",
                                 justifyContent: "flex-end",
                                 marginRight: "6px",
                              }}
                              onClick={handleClose}
                           >
                              <AiOutlineClose />
                           </div>

                           {Object.entries(faqCategory).map(([key, value]) => {
                              return (
                                 <div
                                    className="table_of_content_item"
                                    style={{
                                       display: "flex",
                                       height: "38px",
                                       fontStyle: "normal",
                                       fontWeight: "500",
                                       fontSize: "14px",
                                       lineHeight: "22px",
                                       alignItems: "center",
                                       cursor: "pointer",
                                    }}
                                    onClick={() => {
                                       navigate(`/company/faqs?type=${value.title}`);
                                       handleClose();
                                    }}
                                 >
                                    <div
                                       className="activeStatus"
                                       style={
                                          activeTitle === value.title
                                             ? {
                                                  borderRadius: "8px",
                                                  width: "6px",
                                                  height: "22px",
                                                  backgroundColor: "#FFF",
                                                  marginRight: "10px",
                                               }
                                             : {
                                                  borderRadius: "8px",
                                                  width: "6px",
                                                  height: "22px",
                                                  backgroundColor: "#ED1C24",
                                                  marginRight: "10px",
                                               }
                                       }
                                    ></div>
                                    {value.title}
                                 </div>
                              );
                           })}
                        </div>
                     </Offcanvas.Body>
                  </Offcanvas>

                  <h1 className="active_title">{activeTitle}</h1>
                  <Accordion>
                     {Object.entries(faqData).map(([key, value]) => {
                        if (value.category._id === activeId) {
                           return (
                              <Accordion.Item eventKey={key}>
                                 <Accordion.Header>{value.question}</Accordion.Header>
                                 <Accordion.Body>{value.answer}</Accordion.Body>
                              </Accordion.Item>
                           );
                        } else {
                           return null;
                        }
                     })}
                  </Accordion>
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export default Faq;
