import { Avatar } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import Token from "../utils/useToken";
import "../scss/dashboard/dashboardbillings.scss";
import { FcDocument } from "react-icons/fc";

import axios from "axios";
import { remove_document, update_profile, upload_document } from "../data/protectedapi";
import MessageModal from "../components/customcomponents/messageModal";
import { get_download_file } from "../data/api";
import fileDownload from "js-file-download";
import deleteicon from "../assets/cart/delete.png";
import Form from "react-bootstrap/Form";
import Loading from "../components/customcomponents/loading";

export default function DashboardDocument() {
   const [Filedata, setFiledata] = useState(null);
   const { token, setToken, userDetails } = Token();
   const [documentError, setDocumentError] = useState(false);
   const [message, setMessage] = useState("");
   const [loading, setLoading] = useState(false);
   const [FileName, setFileName] = useState("Upload Identification Document");
   const [removeDocumentName, setRemoveDocumentName] = useState("");
   const fileValidation = (filename, file) => {
      const fileType = file.type;
      const validImageTypes = ["image", "image/jpeg", "image/png", "application/pdf"];
      if (validImageTypes.includes(fileType)) {
         setFileName(file?.name);
         setFiledata(file);
      } else {
         setDocumentError(true);
      }
   };

   const removeDocument = async () => {
      setDeleteshow(false);
      const response = await remove_document(removeDocumentName);
      if (response?.data?.success === true) {
         setMessage("Document Deleted");
         setShow(true);
      } else {
      }
   };
   const [show, setShow] = useState(false);
   const [error, setError] = useState(false);
   const [renameFile, setRenameFile] = useState("");
   const handleRename = (e) => {
      if (e.target.value.indexOf(" ") === 0) {
         setRenameFile("");
      } else {
         setRenameFile(e.target.value);
      }
   };

   useEffect(() => {
      if (FileName) {
         setRenameFile(FileName);
      }
   }, [FileName]);
   const [emptyRename, setEmptyrename] = useState(false);
   const uploadDocument = async () => {
      if (renameFile === "") {
         setEmptyrename(true);
      } else {
         setLoading(true);
         const response = await upload_document(Filedata, renameFile.split(".")[0]);
         if (response?.data?.success === true) {
            const user = response?.data?.data;
            setMessage("Document Updated");
            setLoading(false);
            setShow(true);
         } else {
            setLoading(false);
            setError(true);
            setMessage("Cannot Upload");
         }
      }

      // setShow(true);
   };
   const handleDownload = async (filename) => {
      const response = await get_download_file(filename);
      fileDownload(response.data, filename);
   };
   const [deleteshow, setDeleteshow] = useState(false);
   const closeModal = () => {
      setDeleteshow(false);
   };
   const deleteDocumentmodal = (name) => {
      setRemoveDocumentName(name);
      setDeleteshow(true);
   };
   //users profile
   const [userDocument, setUserdocument] = useState("");
   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setUserdocument(res?.data?.documents);
      }
      get_navigation();
   }, []);

   //autofocus
   const autoFocusFn = useCallback((element) => (element ? element.focus() : null), []);

   return loading ? (
      <Loading />
   ) : (
      <div>
         <Modal
            show={deleteshow}
            onHide={closeModal}
            centered
            animation={false}
            id="deletecart-modal"
         >
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body" style={{ textAlign: "center" }}>
               <div>
                  <img src={deleteicon} width="40px" alt="" />
               </div>
               <p className="cartdelete-title">Are you sure?</p>
               <p className="cartdelete-sub">This document will be removed from your profile</p>
            </Modal.Body>
            <Modal.Footer id="modal-footer">
               <Button onClick={closeModal} variant="secondary" id="cancel">
                  Cancel
               </Button>
               <Button
                  onClick={() => removeDocument()}
                  variant="secondary"
                  className="red"
                  id="removecart"
               >
                  Remove
               </Button>
            </Modal.Footer>
         </Modal>
         <MessageModal
            show={show}
            setShow={setShow}
            heading={message}
            buttonText={"Ok"}
            reload={true}
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Upload failed"}
            buttonText={"Ok"}
            type={"error"}
            link="/"
         />
         <MessageModal
            show={documentError}
            setShow={setDocumentError}
            heading={"Upload failed"}
            message="Please upload a valid image file (jpg, jpeg, png,pdf)"
            buttonText={"Ok"}
            type={"error"}
         />
         <h2>My Documents</h2>
         <div
            style={{
               display: "flex",
               gap: "10px",
               alignItems: "baseline",
               marginTop: "25px",
            }}
         >
            <div>
               {FileName === "Upload Identification Document" ? (
                  <>
                     <input
                        type="file"
                        name="profile_pic"
                        id="dashboard-upload"
                        title="Upload Identification Document"
                        style={{ display: "none" }}
                        onChange={(e) =>
                           fileValidation(e.currentTarget.value, e.currentTarget.files[0])
                        }
                     />
                     <label
                        for="dashboard-upload"
                        id="dashboard-upload-label"
                        style={{
                           width: "fit-content",
                           padding: "1px 12px",
                        }}
                     >
                        {FileName}
                     </label>
                  </>
               ) : (
                  <>
                     <input
                        onChange={(e) => handleRename(e)}
                        type="text"
                        ref={autoFocusFn}
                        maxLength={25}
                        minLength={2}
                        autoFocus="autofocus"
                        value={renameFile}
                        defaultValue={FileName.split(".")[0]}
                     />
                     {emptyRename && renameFile === "" && (
                        <p style={{ color: "red", marginTop: "5px" }}>Field can't be empty!</p>
                     )}
                  </>
               )}
            </div>
            {FileName === "Upload Identification Document" ? (
               <></>
            ) : (
               <div>
                  <Button
                     onClick={uploadDocument}
                     id="dashboard-remove"
                     className="ms-3 mt-2 bg-danger text-white red"
                  >
                     Upload
                  </Button>
               </div>
            )}
         </div>
         <div>
            {userDocument &&
               Object.entries(userDocument).map(([key, value]) => {
                  return (
                     <div className="documment_container">
                        <div>
                           {value.toString().split(".").pop() !== "pdf" ? (
                              <>
                                 <div>
                                    <img
                                       id="preview-image"
                                       src={`${process.env.REACT_APP_FILE_URL}/${value}`}
                                       alt=""
                                    />
                                 </div>
                              </>
                           ) : (
                              <>
                                 {" "}
                                 <div id="preview-image">
                                    <FcDocument size={50} />
                                 </div>
                                 {/* <p id="thumbnail-text"> {value?.split("-")[0]}</p> */}
                              </>
                           )}
                        </div>
                        <div>
                           <div>
                              <p id="thumbnail-text">{value?.split("-")[0]}</p>
                           </div>
                           <div className="documment_button_container">
                              <div>
                                 <Button
                                    style={{ backgroundColor: "#0e9e49", color: "#ffff" }}
                                    onClick={() => handleDownload(value)}
                                    className="green"
                                    id="dashboard-remove"
                                    variant="secondary"
                                 >
                                    Download
                                 </Button>
                              </div>
                              <div>
                                 <Button
                                    style={{ background: "#ed1c24", color: "#ffff" }}
                                    onClick={() => deleteDocumentmodal(value)}
                                    id="dashboard-remove"
                                    variant="secondary"
                                    className="red"
                                    // className=" bg-danger text-white"
                                 >
                                    Remove
                                 </Button>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
         </div>
         <div className="dashboard-profile" style={{ marginTop: "20px" }}>
            {/* {userDetails?.split(",").map((val, idx) => (
               <p>{val}</p>
            ))} */}
         </div>
      </div>
   );
}
