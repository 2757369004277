import React, { useState, useEffect } from "react";
import { check_min_balance } from "../../data/protectedapi";
import { update_profile } from "../../data/protectedapi";

export default function RemainingReward({
   totalPrice,
   newTotalprice,
   shippingcharge,
   rememberMe,
   discountamount,
}) {
   //rewardfactor
   const [rewardfactor, setRewardfactor] = useState("");
   const [reward, setReward] = useState(0);
   const [minbalance, setMinbalance] = useState("");

   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setReward(res?.data?.reward);
      }
      get_navigation();
   }, []);

   useEffect(() => {
      async function getCompanyDetails() {
         const response = await check_min_balance();

         setRewardfactor(response?.data?.data?.reward_factor);
         setMinbalance(response?.data?.data?.min_bill_amt);
      }
      getCompanyDetails();
   }, []);
   // console.log("totalprice", totalPrice);
   let rewardPoint = (rewardfactor * totalPrice) / 100;
   let newrewardPoint = newTotalprice;
   // console.log(newTotalprice);
   let calculatedRewardpoint = (rewardfactor * newrewardPoint) / 100;
   // console.log(calculatedRewardpoint);
   // console.log(totalPrice);
   // console.log(rewardPoint);
   // console.log(Math.floor(rewardPoint), "factor");
   // console.log(minbalance);
   return (
      <div>
         <>
            {newTotalprice >= 0 && rememberMe === true ? (
               <>
                  <p style={{ textAlign: "center", color: "forestgreen" }}>
                     You will get {Math.floor(calculatedRewardpoint)} reward points
                  </p>
               </>
            ) : (
               <>
                  <p style={{ textAlign: "center", color: "forestgreen" }}>
                     You will get {Math.floor(rewardPoint)} reward points <br />
                  </p>
               </>
            )}
         </>
      </div>
   );
}
