import React, { useState, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AboutUs from "../components/pages/aboutus";
import Faq from "../components/pages/faq";
import Support from "../components/pages/support";
import ContactUs from "../components/pages/contactus";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../components/pages/login";
import SignUp from "../components/pages/signup";
import ForgotPassword from "../components/pages/fogotpassword";
import OtpVerify from "../components/pages/otpverify";
import App from "../App";
import Store from "../store/pages/Main";
// import main from "../store/pages/specials/main";
import TVchannels from "../packages/pages/Main";
import IndividualPackage from "../packages/pages/individualpackage/Main";
import PpvMovies from "../ppvmovies/pages/Main";
import IndividualMovie from "../ppvmovies/pages/individualmovie";
import MoviePayment from "../ppvmovies/pages/payment";
import Category from "../store/pages/category";
import Internet from "../components/pages/internet";
import TVPackageOrder from "../packages/pages/order/Main";
import Error from "../components/pages/404error";
import Careers from "../components/pages/careers";
import Wrapper from "../components/customcomponents/internet/compare/wrapper";
import Plan from "../components/customcomponents/internet/viewplans.js/plan";
import IndividualCategory from "../packages/pages/category/Main";
// import LoginModal from "../components/pages/loginmodal";
import Refer from "../components/pages/refer";
import Cart from "../store/pages/cart";
import ProductWrapper from "../store/pages/specials/main";
import VideoTutorial from "../components/pages/videoTutorial";
// import { Product } from "../components/customcomponents/discountbanner";
import ScrollToTop from "../utils/ScrollToTop";
import Downloads from "../components/pages/downloads";
import Dashboard from "../dashboard/Main";
import Ott from "../ott/Main";
import DishhomegoPackages from "../ott/packages/Main";
import DishhomegoIndividualPackage from "../ott/packages/individualpackage/Main";
import ProtectedRoute, { RouteWithoutLogin } from "./protected.routes";
import InternetPackageOrder from "../packages/pages/order/internetPackage";
import LocateUs from "../components/pages/locateUs";
import Recharge from "../components/pages/recharge";
// import Sale from "../components/pages/sale";
import Hotdealpage from "../components/pages/hotdealpage";
import Sme from "../components/pages/sme";
import Enterprise from "../components/pages/enterprise";
import SuccessPay from "../store/pages/successPay";
import Failure from "../store/pages/failurePay";
import Searchresult from "../components/pages/searchResult";
import { get_all_static_page, get_company_details } from "../data/api";
import StaticPage from "../components/pages/staticPage.js";
import { Beforeunload } from "react-beforeunload";
import Token from "../utils/useToken";
import Offerpage from "../components/pages/offerpage";
import OtpLogin from "../components/pages/otpLogin";
import IndividualInternetpakcage from "../packages/pages/individualpackage/individualInternetpakcage";
import { check_status } from "../data/api";
import ServerError from "../components/pages/serverError";
import DevToolError from "../components/pages/devToolError.js";
import CustomerIDLogin from "../components/pages/customeridLogin";
import DHiTV from "../dh-itv/Main";
import DHitvIndividual from "../dh-itv/Individual.js";

const RoutePath = () => {
   //get all static routes
   const [staticPages, setStaticPages] = useState("");
   const [empty, setEmpty] = useState(true);
   const [storeStatus, setStoreStatus] = useState(true);
   const [status, setStatus] = useState(false);

   useEffect(() => {
      async function fetchData() {
         const response = await get_all_static_page();
         const res = await get_company_details();
         setStaticPages(response?.data?.data);
         setStoreStatus(res?.data?.data);
      }
      fetchData();
   }, [empty]);

   useEffect(() => {
      if (staticPages?.length === 0 || staticPages === undefined) {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [staticPages]);

   useEffect(() => {
      async function fetchData() {
         const response = await check_status();
         if (response?.status === 200) {
            setStatus(true);
         } else {
            setStatus(false);
         }
      }
      fetchData();
   }, []);

   // console.log("status", status);

   const { clearSessionToken } = Token();

   return staticPages === "" ? null : (
      <Beforeunload
         onBeforeunload={() => {
            clearSessionToken();
            const sessions = localStorage.getItem("sessions");
            if (sessions !== null && sessions > 0) {
               localStorage.setItem("sessions", parseInt(sessions) - 1);
            } else {
               localStorage?.removeItem("sessions");
            }
         }}
      >
         {!status ? (
            <Router>
               <ScrollToTop>
                  <Routes>
                     <Route path="/*" element={<ServerError />} />
                     <Route path="/error" element={<ServerError />} />
                  </Routes>
               </ScrollToTop>
            </Router>
         ) : (
            <Router>
               <ScrollToTop>
                  <Routes>
                     {!empty &&
                        staticPages &&
                        Object.entries(staticPages).map(([key, value]) => {
                           // console.log("static", value);
                           if (value?.link === "privacy-policy")
                              return (
                                 <Route
                                    exact
                                    path={`/${value?.link}`}
                                    element={<StaticPage data={value} />}
                                    key={key}
                                 />
                              );
                           else
                              return (
                                 <Route
                                    exact
                                    path={`/pages/${value?.link}`}
                                    element={<StaticPage data={value} />}
                                    key={key}
                                 />
                              );
                        })}
                     <Route path="/error" element={<DevToolError />} />
                     <Route path="/*" element={<Error />} />
                     <Route path="/store/*" element={<Error />} />
                     <Route exact path="/" element={<App />} />
                     <Route exact path="/company/about-us" element={<AboutUs />} />
                     <Route exact path="/company" element={<AboutUs />} />
                     <Route exact path="/offer/:id" element={<Offerpage />} />
                     <Route exact path="/company/faqs" element={<Faq />} />
                     <Route exact path="/company/support" element={<Support />} />
                     <Route exact path="/video-tutorial" element={<VideoTutorial />} />
                     <Route exact path="/company/contact-us" element={<ContactUs />} />
                     <Route exact path="/internet/enterprise" element={<Enterprise />} />
                     <Route exact path="/internet/sme" element={<Sme />} />
                     <Route exact path="/company/careers" element={<Careers />} />
                     <Route exact path="/downloads" element={<Downloads />} />
                     <Route
                        exact
                        path="/login"
                        element={<RouteWithoutLogin element={<Login />} />}
                     />
                     {/* <Route exact path="/successpay" element={<SuccessPay />} /> */}
                     <Route exact path="/failurepay" element={<Failure />} />
                     {storeStatus && storeStatus?.disable_store === false && (
                        <Route exact path="/store/:id" element={<ProductWrapper />} />
                     )}
                     {storeStatus && storeStatus?.disable_store === false && (
                        <Route exact path="/store/hotdeals" element={<Hotdealpage />} />
                     )}
                     {storeStatus && storeStatus?.disable_store === false && (
                        <Route exact path="/store" element={<Store />} />
                     )}
                     {storeStatus && storeStatus?.disable_store === false && (
                        <Route exact path="/store/category" element={<Category />} />
                     )}

                     {storeStatus && storeStatus?.disable_store === false && (
                        <Route path="/cart" element={<ProtectedRoute element={<Cart />} />} />
                     )}

                     {/* <Route exact path="/sale" element={<Sale />} /> */}
                     <Route path="/tv" element={<TVchannels />} />
                     <Route path="/tv/dishhome-dth" element={<TVchannels />} />
                     <Route exact path="/tv/:category/:id" element={<IndividualCategory />} />
                     <Route exact path="/tv/:packagename" element={<IndividualPackage />} />
                     <Route exact path="/internet/:id" element={<IndividualInternetpakcage />} />
                     <Route exact path="/ppv-movies" element={<PpvMovies />} />
                     <Route exact path="/ppv-movies/:movieid" element={<IndividualMovie />} />
                     <Route exact path="ppv-dh" element={<DHiTV />} />
                     <Route exact path="ppv-dh/:id" element={<DHitvIndividual />} />
                     <Route exact path="/searchresult" element={<Searchresult />} />

                     <Route exact path="/internet/residential" element={<Internet />} />
                     <Route path="internet/compare" element={<Wrapper />} />
                     <Route path="/internet/plans" element={<Plan />} />
                     <Route path="/dishhome-go" element={<Ott />} />
                     <Route exact path="/dishhome-go/packages" element={<DishhomegoPackages />} />
                     <Route
                        exact
                        path="/dishhome-go/packages/:id"
                        element={<DishhomegoIndividualPackage />}
                     />

                     <Route path="/locate" element={<LocateUs />} />
                     {/* Route Without Login */}
                     <Route
                        exact
                        path="/forgot-password"
                        element={<RouteWithoutLogin element={<ForgotPassword />} />}
                     />
                     <Route
                        exact
                        path="/otp-login"
                        element={<RouteWithoutLogin element={<OtpLogin />} />}
                     />
                     <Route
                        exact
                        path="/customer-id-login"
                        element={<RouteWithoutLogin element={<CustomerIDLogin />} />}
                     />
                     <Route
                        exact
                        path="/otp-verify/:number"
                        element={<RouteWithoutLogin element={<OtpVerify />} />}
                     />
                     <Route
                        exact
                        path="/signup"
                        element={<RouteWithoutLogin element={<SignUp />} />}
                     />
                     {/* Protected Routes */}
                     <Route
                        exact
                        path="/dashboard"
                        element={<ProtectedRoute element={<Dashboard />} />}
                     />
                     <Route path="/recharge" element={<ProtectedRoute element={<Recharge />} />} />
                     <Route
                        exact
                        path="/internet/refer"
                        element={<ProtectedRoute element={<Refer />} />}
                     />
                     <Route
                        exact
                        path="/ppv-movies/order/:movieid"
                        element={<ProtectedRoute element={<MoviePayment />} />}
                     />
                     <Route
                        exact
                        path="/tv/order/:packagename"
                        element={<ProtectedRoute element={<TVPackageOrder />} />}
                     />
                     <Route
                        exact
                        path="/internetpackage/order/:id"
                        element={<ProtectedRoute element={<InternetPackageOrder />} />}
                     />
                     <Route
                        exact
                        path="/order/verify"
                        element={<ProtectedRoute element={<SuccessPay />} />}
                     />
                  </Routes>
               </ScrollToTop>
            </Router>
         )}
      </Beforeunload>
   );
};

export default RoutePath;
