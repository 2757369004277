import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import Layout from "../components/include/layout";
import { get_itv_ppv_movies_by_id } from "../data/api";
import Loading from "../components/customcomponents/loading";
import Error from "../components/pages/404error";
import LoginModal from "../components/pages/loginmodal";
import { AiFillCalendar, AiOutlineClockCircle } from "react-icons/ai";
import ReactHtmlParser from "react-html-parser";
import "../scss/ppvmovies/iptvindv.scss";
import Token from "../utils/useToken";
import MessageModal from "../components/customcomponents/messageModal";
import { Modal } from "react-bootstrap";
import esewa from "../assets/store/esewa.webp";
import khalti from "../assets/cart/khalti.png";
import imepay from "../assets/store/ime.png";
import connectips from "../assets/store/ips.png";
import { purchase_iptv_movie } from "../data/protectedapi";
import { handleOnlinePayment } from "../components/pages/payment/payment";
import KhaltiCheckout from "khalti-checkout-web";
import { useMediaQuery } from "react-responsive";

function Individual() {
   const id = useParams().id;
   //query

   const [movie, setMovie] = useState();
   const [isLoading, setIsLoading] = useState(true);
   const [error, setError] = useState(false);
   const [activePrice, setActivePrice] = useState({});
   const [purchaseModal, setPurchaseModal] = useState(false);

   useEffect(() => {
      async function getMovies() {
         const res = await get_itv_ppv_movies_by_id(id);
         if (res.status === 200) {
            setMovie(res?.data?.data);
            setIsLoading(false);
         } else {
            setIsLoading(false);
            setError(true);
         }
      }
      getMovies();
   }, []);
   console.log("movie", movie);
   return isLoading ? (
      <Loading />
   ) : error ? (
      <Error />
   ) : (
      <Layout>
         <MovieInfo
            movie={movie}
            activePrice={activePrice}
            setActivePrice={setActivePrice}
            setPurchaseModal={setPurchaseModal}
         />
         <PaymentModal
            movie={movie}
            activePrice={activePrice}
            setShow={setPurchaseModal}
            show={purchaseModal}
         />
      </Layout>
   );
}

const MovieInfo = ({ movie, activePrice, setActivePrice, setPurchaseModal }) => {
   const [show, setShow] = useState(false);
   const { userDetails } = Token();
   return (
      <>
         <LoginModal Show={show} setShow={setShow} link={`/ppv-dh/${movie?.unique_id}`} />
         <div className="movieinfo_container py-5">
            <div className="movie_container fixed-width">
               <div className="movie_img">
                  <img src={movie?.movie_logo} alt={movie?.name} />
               </div>
               <div className="movie_info">
                  <h2 className="title pt-0">{movie?.name}</h2>
                  <div className="movierating_wrapper">
                     {movie?.featured && <p className="moviedefination me-4">Featured</p>}
                     <p className="movierating me-4">
                        <AiFillCalendar />
                        &nbsp;{movie?.released_date}
                     </p>
                     <p className="movieduration me-4">
                        {" "}
                        <AiOutlineClockCircle /> &nbsp;{movie?.duration} min
                     </p>
                  </div>
                  <div className="moviegerna_wrapper mt-2 mb-4">
                     {movie.genre.map((genre) => {
                        return <button className="gernabutton me-3">{genre}</button>;
                     })}
                  </div>
                  <div
                     className="movie_info_text pb-4"
                     dangerouslySetInnerHTML={{ __html: ReactHtmlParser(movie?.description) }}
                  ></div>
                  <div className="iptv_price_list mt-4">
                     {movie?.prices?.map((price) => {
                        return (
                           <div
                              className={`price ${activePrice === price ? "active" : null}`}
                              onClick={() => setActivePrice(price)}
                           >
                              Rs. {price?.price}
                              <sub> / {`${price?.duration} ${price?.time_span}`}</sub>
                           </div>
                        );
                     })}
                  </div>
                  {activePrice?.price && (
                     <div className="iptv_buy_now_btn mt-4">
                        <button
                           className="buy_now_btn"
                           onClick={() => {
                              !userDetails ? setShow(true) : setPurchaseModal(true);
                           }}
                        >
                           Buy Now
                        </button>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

const PaymentModal = ({ show, setShow, movie, activePrice }) => {
   //Query
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const queryMacAddress = queryParams.get("macAddress");
   const isResponsive = useMediaQuery({ query: "(max-width: 992px)" });
   const [error, setError] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [activePaymentMethod, setActivePaymentMethod] = useState("");
   const [macAddress, setMacAddress] = useState(queryMacAddress || "");
   const [messageModal, setMessageModal] = useState(false);
   const paymentMethods = ["esewa", "khalti", "imepay", "connectips"];
   const { userDetails } = Token();

   const handlePayment = async () => {
      if (activePaymentMethod === "") return;
      const data = {
         name: movie?.name,
         product_id: movie?.id?.toString(),
         mac_address: macAddress,
         // mac_address: macAddress || "8882791F1E8E" || "8882795f3e41",
         duration: activePrice?.duration,
         duration_type: activePrice?.time_span,
         total: activePrice?.price,
         payment_method: activePaymentMethod,
      };
      const response = await purchase_iptv_movie(data);
      console.log(response);
      if (response.status === 200) {
         const order = response?.data?.data;
         // if (order?.payment_method === "khalti") {
         //    const data = await handleOnlinePayment({
         //       order_id: order?.orderId,
         //       payment_method: order?.payment_method,
         //       order_name: order?.order_name,
         //       uid: userDetails?.id,
         //       setSuccess: setMessageModal,
         //    });
         //    const checkout = new KhaltiCheckout(data);
         //    checkout.show({
         //       amount: order?.total * 100,
         //    });
         // } else
         handleOnlinePayment({
            order_id: order?.orderId,
            payment_method: order?.payment_method,
            order_name: order?.order_name,
            uid: userDetails?.id,
            total: order?.total,
         });
      } else {
         setError(true);
         setErrorMessage(response?.response?.data?.message);
      }
   };
   return (
      <>
         <MessageModal
            show={error}
            setShow={setError}
            heading="Payment Failed"
            message={errorMessage}
            type={"error"}
            buttonText="OK"
         />
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading="Movie Purchased"
            message="Thank you for your purchase! "
            buttonText="Go to Home Page"
            link="/"
            isNavigate={true}
         />
         <Modal
            show={show}
            onHide={() => {
               setActivePaymentMethod("");
               setShow(false);
            }}
            size="lg"
         >
            <div
               className={
                  isResponsive
                     ? "d-flex flex-column p-4 iptv_payment_modal justify-content-center align-items-center"
                     : "d-flex flex-row p-4 iptv_payment_modal"
               }
            >
               <div
                  className="order_summary "
                  style={
                     isResponsive
                        ? {
                             width: "100%",
                             marginBottom: "10px",
                             borderBottom: "1px solid #E6EAF0",
                             paddingBottom: "10px",
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                             justifyContent: "center",
                          }
                        : { width: "40%", maxWidth: "270px" }
                  }
               >
                  <h3 className="order_title">Order Summary</h3>
                  <div className="order_summary_wrapper">
                     <img
                        src={movie?.movie_logo}
                        alt={movie?.name}
                        style={{ width: "200px", height: "240px", marginBottom: "10px" }}
                     />
                     <p className="mb-0 ps-1" style={{ fontSize: "20px", fontWeight: "700" }}>
                        {" "}
                        {movie?.name}
                     </p>
                     <p
                        className="mb-0 ps-1"
                        style={{
                           fontSize: "16px",
                           fontWeight: "650",
                           // color: "#3F9E4B",
                        }}
                     >
                        Total:
                        <span style={{ color: "rgb(33, 37, 41)", fontWeight: "600" }}>
                           {" "}
                           Rs. {activePrice?.price}/
                           <sub>
                              {activePrice?.duration} {activePrice?.time_span}
                           </sub>
                        </span>
                     </p>
                  </div>
               </div>

               <div className="payment_method">
                  <h3>Mac Address</h3>
                  <input
                     type="text"
                     className=" my-2"
                     placeholder="Enter MAC Address"
                     value={macAddress}
                     style={{ padding: "10px 15px" }}
                     onChange={(e) => setMacAddress(e.target.value)}
                  />
                  <h3 className="order_title mt-2">Payment Method</h3>
                  <div className="payment_method_wrapper">
                     {paymentMethods.map((method) => {
                        return (
                           <div
                              className="payment_options"
                              onClick={() => {
                                 setActivePaymentMethod(method);
                              }}
                              style={
                                 activePaymentMethod === method
                                    ? { border: "2px solid red" }
                                    : { border: "2px solid #E6EAF0" }
                              }
                           >
                              <img
                                 src={
                                    method === "esewa"
                                       ? esewa
                                       : method === "khalti"
                                       ? khalti
                                       : method === "imepay"
                                       ? imepay
                                       : connectips
                                 }
                                 alt={method}
                              />
                           </div>
                        );
                     })}
                  </div>
                  <div className="buy_now_btn_wrapper mt-4">
                     <button
                        className="buy_now_btn"
                        onClick={() => {
                           activePaymentMethod !== "" && macAddress !== "" && handlePayment();
                        }}
                        style={
                           activePaymentMethod === "" || macAddress === ""
                              ? { opacity: "0.5", cursor: "not-allowed" }
                              : null
                        }
                     >
                        Buy Now
                     </button>
                  </div>
               </div>
            </div>
         </Modal>
      </>
   );
};

export default Individual;
