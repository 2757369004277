import React from "react";
import { Helmet } from "react-helmet";

export default function SeoTags(title, seo_title, description, keywords, image, url) {
   return (
      <Helmet>
         <title>{title}</title>
         <meta name="description" content={description} />
         <meta property="og:title" content={seo_title} />
         <meta property="og:description" content={description} />
         <meta property="keywords" content={keywords} />
         <meta property="og:image" content={image} />
         <meta property="og:url" content={url} />
         <meta property="og:type" content="website" />
         <meta property="og:site_name" content="DishHome" />
         <meta property="og:locale" content="en_US" />
      </Helmet>
   );
}
