import React from "react";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import { useNavigate } from "react-router";

function ItvMovies({ movies }) {
   return (
      <Widthcontainer>
         <div className="currentmovies_wrapper py-5 fixed-width">
            <div className="currentmovies_text">
               <h2>Available Movies</h2>
            </div>
            <div className="dh-itv">
               {movies.map((movie) => (
                  <MovieCard movie={movie} />
               ))}
            </div>
         </div>
      </Widthcontainer>
   );
}

const MovieCard = ({ movie }) => {
   console.log("movie", movie);
   let Navigate = useNavigate();
   return (
      <div className="moviecard">
         <div className="moviecard_img">
            <img src={movie?.movie_logo} alt={movie?.name} />

            <div className="moviecard_info">
               {/* <button className="moviecard_button">Trailer</button> */}
               <button
                  className="moviecard_button"
                  onClick={() => {
                     Navigate(`/ppv-dh/${movie?.unique_id}`);
                  }}
               >
                  Buy Now
               </button>
            </div>
         </div>
         <div className="moviecard_text">
            <h3>{movie?.name}</h3>
            <div className="genre_container">
               {movie?.genre?.map((genre) => (
                  <span className="genre">{genre}</span>
               ))}
            </div>
         </div>
      </div>
   );
};

export default ItvMovies;
