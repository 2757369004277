import React, { useState } from "react";
import SeoTags from "../../utils/seoTags";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import "../../scss/pages/staticPage.scss";
import { useLocation } from "react-router";
import Custom from "./custom";
import DoubleOfferPage from "./DoubleOfferPage";
import PathaoFest from "./pathaoFest";

const StaticPage = ({ data }) => {
   //html file url
   const [cartupdate, setCartupdate] = useState(false);
   const location = useLocation();

   return (
      <Layout
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
         footer={
            location?.pathname?.includes("/dashain-offer") ||
            location?.pathname?.includes("/double-offer") ||
            location?.pathname?.includes("/pathao-fest")
               ? "dashain"
               : ""
         }
      >
         {SeoTags(
            `${data?.title} | DishHome Nepal`,
            data.seo_title,
            data?.seo_description,
            data?.seo_keywords,
            null,
            `${process.env.REACT_APP_PUBLIC_URL}/pages/${data?.link}`,
         )}
         {location?.pathname?.includes("/pathao-fest") ? (
            <PathaoFest />
         ) : location?.pathname?.includes("/dashain-offer") ? (
            <Custom />
         ) : location?.pathname?.includes("/double-offer") ? (
            <DoubleOfferPage />
         ) : (
            <Widthcontainer>
               <div className="fixed-width staticPageWrapper">
                  <div
                     dangerouslySetInnerHTML={{ __html: data?.content }}
                     className="jobdescription"
                  ></div>
               </div>
            </Widthcontainer>
         )}
      </Layout>
   );
};

export default StaticPage;
