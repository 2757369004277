import React from "react";
import { useState, useEffect } from "react";
import "../scss/dashboard/dashboardNotifications.scss";
import { get_all_notifiaciton } from "../data/protectedapi";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default function DashboardNotifications() {
   const [notifications, setNotifications] = useState([]);
   const [allNotification, setAllNotifiaction] = useState([]);
   const [pageCount, setPageCount] = useState("");
   let navigate = useNavigate();

   useEffect(() => {
      //get all notification
      async function getNotificaiton() {
         const response = await get_all_notifiaciton();
         setPageCount(Math.ceil(response?.data?.data?.length / 6));
         setAllNotifiaction(response?.data?.data);
         setNotifications(response?.data?.data?.slice(0, 6));
      }
      getNotificaiton();
   }, [pageCount]);

   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 6;
      const newArray = allNotification.slice(offset, offset + 6);
      setNotifications(newArray);
   };

   return (
      <div className="dashboardNotifiaciton_Wrapper">
         <h2 className="dashboardNotifiaciton_Header">Notifications</h2>
         <p className="dashboardNotification_subHeading">Find out your Notifications here</p>
         <div className="dashboardNotificaiton_container">
            {Object.entries(notifications).map(([key, value]) => {
               return (
                  <div
                     className="dashboardNotification cursor_pointer"
                     onClick={() => {
                        navigate(value?.link);
                     }}
                  >
                     <h1 className="notification_title">
                        {value.sender}{" "}
                        <span>
                           {formatDistanceToNowStrict(new Date(value?.createdAt)) + " ago"}
                        </span>
                     </h1>
                     <p className="notification_description">{value.message}</p>
                  </div>
               );
            })}
         </div>
         {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )}
         {notifications?.length === 0 && (
            <div className="dashboardNotification_empty">
               <p className="dashboardNotification_empty_description">You have no notifications</p>
            </div>
         )}
      </div>
   );
}
