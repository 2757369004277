import ReactDOM from "react-dom";
import React from "react";
import "./scss/app.scss";
import RoutePath from "./routes/routes";
import { Provider } from "react-redux";
import store, { persistor } from "./components/reducer/store";
import { PersistGate } from "redux-persist/integration/react";
// import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
// import { check_status } from "./data/api";
// import DisableDevtool from "disable-devtool";

const rootElement = document.getElementById("root");

window.addEventListener("mousemove", (event) => {
   const sessions = localStorage.getItem("sessions");
   if (sessions !== null) {
      localStorage.setItem("sessions", parseInt(sessions) + 1);
   } else if (sessions <= 0) {
      localStorage.setItem("sessions", 1);
   } else {
      localStorage.setItem("sessions", 0);
   }
});

// DisableDevtool({
//    disableMenu: false,
//    ondevtoolopen(type, next) {
//       if (window.location.pathname !== "/error") window.location.href = "/error";
//    },
// });

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <PersistGate persistor={persistor}>
            <RoutePath />
         </PersistGate>
      </Provider>
   </React.StrictMode>,
   rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
