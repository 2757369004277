// import { Splide, SplideSlide } from "@splidejs/react-splide";
import Slider from "react-slick";
import React, { useState } from "react";
import "../../scss/ppvmovies/moviebannerslide.scss";
import { useNavigate } from "react-router";

const MovieBannerSlide = ({ data }) => {
   let navigate = useNavigate();
   const [activeSlide, setactiveSlide] = useState(0);
   const setting = {
      dots: true,
      infinite: true,
      arrows: true,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (current) => setactiveSlide(current),
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
   };
   let items = [];
   // console.log(activeSlide);
   return (
      <div className="slide_container">
         {
            //#region Splide
            /* <Splide
                options={{
                    type: "loop",
                    height: "790px",
                    padding: "240px",
                }}
            >
                <SplideSlide>
                    <div className="imgcontainer">
                        <img
                            src={MovieImage}
                            alt="Movie"
                            className="bannerimg"
                        />
                    </div>
                </SplideSlide>
                <SplideSlide>
                    <img src={MovieImage} alt="Movie" className="bannerimg" />
                </SplideSlide>{" "}
                <SplideSlide>
                    <img src={MovieImage} alt="Movie" className="bannerimg" />
                </SplideSlide>
            </Splide> */
            //#endregion
         }
         <Slider {...setting}>
            {data.map((item, index) => {
               if (item.page_location === "ppv_top") {
                  items.push(item);
                  return (
                     <div
                        className={
                           data?.length === 1
                              ? "imgcontainer single-movie-banner"
                              : index === activeSlide
                              ? "imgcontainer"
                              : "imgcontainer overlay-black-inactiveslide"
                        }
                        onClick={() => {
                           navigate(item?.external_link);
                        }}
                     >
                        <img
                           src={`${process.env.REACT_APP_FILE_URL}/${item.media}`}
                           alt="Movie"
                           className="bannerimg"
                        />
                     </div>
                  );
               }
               return null;
            })}

            {/* <div className="imgcontainer">
               <img src={MovieImage} alt="Movie" className="bannerimg" />
            </div> */}
         </Slider>
         {/* <div className="background_blur">
            <div className="text_container  mb-2">
               <p className="title">{items[activeSlide]?.caption}</p>
               <p
                  className="more cursor_pointer"
                  onClick={() => navigate(items[activeSlide]?.external_link)}
               >
                  {items[activeSlide]?.button_type}
               </p>
            </div>
         </div> */}
      </div>
   );
};

export default MovieBannerSlide;
