import React from "react";
// import { ChannelName } from "../../components/customcomponents/channels";
import "../../scss/packages/exclusivechannel.scss";
import TvChannelCard from "../../packages/pages/individualpackage/tvchannelcard";
import { ChannelName } from "./channels";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "react-responsive";

const ExclusiveChannel = ({ data, type }) => {
   const isMobile = useMediaQuery({ query: "(max-width:600px)" });

   return (
      <div className="exclusive_channel_wrapper py-5 my-4">
         <div className="wrapper_heading pb-5">
            {/* <p>Exclusive 100+ Channels on DishHome</p> */}
            <p>Exclusive Channels on DishHome.</p>
            {/* <p>A World of Entertainment</p> */}
         </div>
         <div
            className="exclusive_channel_container"
            style={type === "ott" ? { display: "flex", gap: "80px", justifyContent: "center" } : {}}
         >
            {type === "ott" ? (
               isMobile ? (
                  <Splide
                     options={{
                        type: "loop",
                        drag: "free",
                        autoWidth: true,
                        pagination: false,
                        autoplay: true,
                        interval: 1000,
                        speed: 8000,
                        easing: "linear",
                        arrows: false,
                        pauseOnHover: true,
                        waitForTransition: false,
                     }}
                     className={"ottChannel_slide"}

                     // options={{
                     //    type: "loop",
                     //    drag: "free",
                     //    autoWidth: true,
                     //    pagination: true,
                     // }}
                  >
                     {data &&
                        Object.entries(data).map(([key, value]) => {
                           return (
                              <SplideSlide>
                                 <ChannelName img={value.image} />
                              </SplideSlide>
                           );
                        })}
                  </Splide>
               ) : (
                  // <>
                  //    {Object.entries(data).map(([key, value]) => {
                  //       if (key < 6) {
                  //          return <ChannelName img={value.image} />;
                  //       } else {
                  //          return null;
                  //       }
                  //    })}
                  // </>
                  <Splide
                     options={{
                        type: "loop",
                        drag: "free",
                        autoWidth: true,
                        pagination: false,
                        autoplay: true,
                        interval: 1000,
                        speed: 8000,
                        easing: "linear",
                        arrows: false,
                        pauseOnHover: true,
                        waitForTransition: false,
                     }}
                     className={"ottChannel_slide"}

                     // options={{
                     //    type: "loop",
                     //    drag: "free",
                     //    autoWidth: true,
                     //    pagination: true,
                     // }}
                  >
                     {data &&
                        Object.entries(data).map(([key, value]) => {
                           return (
                              <SplideSlide>
                                 <ChannelName img={value.image} />
                              </SplideSlide>
                           );
                        })}
                  </Splide>
               )
            ) : (
               <Splide
                  options={{
                     type: "loop",
                     drag: "free",
                     autoWidth: true,
                     pagination: false,
                     autoplay: true,
                     interval: 1000,
                     speed: 8000,
                     easing: "linear",
                     arrows: false,
                     pauseOnFocus: true,
                     pauseOnHover: true,
                     waitForTransition: false,
                  }}
                  // options={{
                  //    type: "loop",
                  //    drag: "free",
                  //    autoWidth: true,
                  //    pagination: true,
                  // }}
               >
                  {data &&
                     Object.entries(data).map(([key, value]) => {
                        return (
                           <SplideSlide>
                              <TvChannelCard img={value.image} channelName={value.name} />
                           </SplideSlide>
                        );
                     })}
               </Splide>
            )}
         </div>
      </div>
   );
};

export default ExclusiveChannel;
