import React, { useState, useEffect } from "react";
import IndvPackage from "../../components/customcomponents/indv_package";
import "../../scss/packages/packagecard.scss";
import { get_tv_package } from "../../data/api";
import rectangle from "../../assets/Rectangle.png";
import ToggleButton from "react-toggle-button";
// import { get_all_navigation } from "../../data/api";
// import { Col } from "react-bootstrap";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const PackageCard = ({ type }) => {
   const [packagedata, setpackageData] = useState("");

   useEffect(() => {
      async function get_packages() {
         const res = await get_tv_package();
         const response = res.data.data;
         setpackageData(response);
      }
      get_packages();
   }, []);
   //true = yearly false==monthly
   const [toggle, setToggle] = useState(true);
   return (
      <div className="package_wrapper my-5">
         <div className="package_text mt-3 mb-4">
            {/* <p className="package_table">Package Table</p> */}
            <h1 className="package_header">
               Amazing Packages.
               {/* Empowering Your Choice
               <br />
               Ideal TV Packages for Every Home */}
            </h1>
         </div>
         <div className="package_container px-3 py-5">
            <div className="package_toggle mb-5">
               <p>Watch Monthly</p>
               <div className="togglebutton mx-3">
                  <ToggleButton
                     inactiveLabel=""
                     activeLabel=""
                     colors={{
                        active: {
                           base: "#ED1C24",
                        },
                     }}
                     value={toggle}
                     onToggle={() => setToggle(!toggle)}
                     className="toggle_button"
                  />
               </div>
               <p>Watch Yearly</p>
            </div>
            <div className="package_card_wrapper">
               <Splide
                  options={{
                     type: "slide",
                     hasTrack: false,
                     perPage: 3,
                     perMove: 3,
                     breakpoints: {
                        780: {
                           perPage: 1,
                           perMove: 1,
                        },
                        992: {
                           perPage: 2,
                           perMove: 1,
                        },
                        1200: {
                           perPage: 3,
                           perMove: 3,
                        },
                        1800: {
                           perPage: 3,
                           perMove: 3,
                        },
                     },
                  }}
               >
                  {packagedata &&
                     Object.entries(packagedata).map(([key, value]) => (
                        <SplideSlide>
                           <IndvPackage
                              img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                              package_name={value.title}
                              description={value.description}
                              link={"/tv/" + value._id}
                              price={toggle ? value?.prices[3]?.price : value?.prices[0]?.price}
                              duration={toggle ? "Year" : "Month"}
                              feature0={
                                 toggle
                                    ? "Installation Charge: Rs.  " +
                                      value?.prices[3]?.installation_charge?.toLocaleString("hi-IN")
                                    : "Installation Charge: Rs.  " +
                                      value?.prices[0]?.installation_charge?.toLocaleString("hi-IN")
                              }
                              feature1={
                                 toggle
                                    ? "Set Top Box: Rs. " +
                                      value?.prices[3]?.settopbox_price.toLocaleString("hi-IN")
                                    : "Set Top Box: Rs. " +
                                      value?.prices[0]?.settopbox_price.toLocaleString("hi-IN")
                              }
                              feature2={
                                 toggle
                                    ? "No. of TV: upto " + value?.prices[3]?.multiple
                                    : "No. of TV: upto " + value?.prices[0]?.multiple
                              }
                              feature3={
                                 toggle
                                    ? "Additional Charge: Rs. " +
                                      value?.prices[3]?.additional.toLocaleString("hi-IN") +
                                      " per TV"
                                    : "Additional Charge: Rs.  " +
                                      value?.prices[0]?.additional.toLocaleString("hi-IN") +
                                      " per TV"
                              }
                              type={type}
                              keys={key}
                           />
                        </SplideSlide>
                     ))}
               </Splide>
            </div>
         </div>
      </div>
   );
};

export default PackageCard;
