import React, { useState, useEffect } from "react";
import { check_min_balance } from "../../data/protectedapi";

import { get_redeem_point, update_profile } from "../../data/protectedapi";

export default function RewardPoint({
   totalPrice,
   discountamount,
   redeemTotal,
   handleReedem,
   rememberMe,
}) {
   //user profile
   const [reward, setReward] = useState(0);
   const [redeemAmounttoPay, setRedeemAmounttoPay] = useState(null);
   const [checkbalance, setCheckbalance] = useState("");
   const [minbalance, setMinbalance] = useState("");
   const [rewardfactor, setRewardfactor] = useState("");
   const [rewardDiscount, setrewardDiscount] = useState("");
   const [usedReward, setUsedreward] = useState("");
   const [minRedeemPoint, setMinRedeemPoint] = useState("");

   //get reedem discount
   // console.log("total", totalPrice);
   useEffect(() => {
      async function getRewarddiscoint() {
         if (totalPrice && reward >= minRedeemPoint) {
            const response = await get_redeem_point(totalPrice);
            setrewardDiscount(response?.data?.discount);
            setUsedreward(response?.data?.usedReward);
         }
      }
      getRewarddiscoint();
   }, [totalPrice, reward]);
   // console.log(rewardDiscount);
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await check_min_balance();
         // console.log("response", response);
         setMinRedeemPoint(response?.data?.data?.redeem_threshold);
         setCheckbalance(response);
         setMinbalance(response?.data?.data?.min_bill_amt);
         setRewardfactor(response?.data?.data?.reward_factor);
      }
      getCompanyDetails();
   }, []);

   //get reward point
   let rewardPoint = (rewardfactor * totalPrice) / 100;
   // console.log(rewardPoint);
   // console.log(rewardfactor);
   useEffect(() => {
      async function get_navigation() {
         const res = await update_profile();
         setReward(res?.data?.reward);
      }
      get_navigation();
   }, []);
   // console.log("checked", rememberMe);

   return (
      <div>
         {(reward >= minRedeemPoint && totalPrice >= minbalance) || rememberMe ? (
            <div
               style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "baseline",
                  marginLeft: "55px",
               }}
               className="_checkbox"
            >
               <input
                  type="checkbox"
                  name="remember"
                  className="CheckBox"
                  id="remember"
                  checked={rememberMe}
                  onChange={(e) => {
                     handleReedem(e);
                  }}
               />
               <label
                  htmlFor="remember"
                  style={{ width: "219px", lineHeight: "21px", cursor: "pointer" }}
               >
                  Redeem Reward (Get Rs. {Math.floor(rewardDiscount)} Discount for{" "}
                  {Math.floor(usedReward)} Reward Points)
               </label>
            </div>
         ) : (
            ""
         )}
      </div>
   );
}
