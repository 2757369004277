import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Form } from "react-bootstrap";
import Token from "../../utils/useToken";
import "../../scss/dashboard/dashboardsupport.scss";
import {
   get_enterprise_issue_type,
   get_support_category,
   get_support_sub_category,
} from "../../data/api";
import {
   check_enterprise_user,
   post_enterprise_support,
   post_support,
} from "../../data/protectedapi";
import Loading from "./loading";
import MessageModal from "./messageModal";
import Captcha from "../include/captcha";

export default function SupportForm({ customerType }) {
   const { userDetails } = Token();
   // const schema = yup.object().shape({
   //    fullName: yup.string().required(),
   //    description: yup.string().required(),
   //    customerId: yup.string().required(),
   // });
   // const flag = customerType?.includes("Combo") ? true : false;
   const [supportCategoryData, setSupportCategoryData] = useState("");
   const [supportCategory, setSupportCategory] = useState("");
   const [supportSubCategoryData, setSupportSubCategoryData] = useState("");
   const [supportSubCategory, setSupportSubCategory] = useState("");
   const [isloading, setIsloading] = useState(false);
   const [description, setDescription] = useState("");
   const [show, setShow] = useState(false);
   const [errorShow, setErrorShow] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [issueTypes, setIssueTypes] = useState("");
   const [issueType, setIssueType] = useState("");
   const [flag, setFlag] = useState("");
   const reRef = useRef();
   useEffect(() => {
      async function fetchData() {
         const response = await get_support_category();
         setSupportCategoryData(response?.data?.result);
         const res =
            supportCategoryData &&
            (await get_support_sub_category(
               supportCategory === "" || supportCategory === undefined
                  ? response?.data?.result[0]?.Value
                  : supportCategory,
            ));
         setSupportSubCategoryData(res?.data?.result);
      }
      async function fetchIssueTypes() {
         const response = await get_enterprise_issue_type();
         setIssueTypes(response?.data?.data);
         setIssueType(response?.data?.data[0]?.Id);
         // console.log(
         //    "supportCategoryData",
         //    flag,
         //    supportCategoryData,
         //    supportSubCategoryData,
         //    issueTypes,
         // );
      }
      flag ? fetchIssueTypes() : fetchData();
   }, [isloading, supportCategory]);

   useEffect(() => {
      if (flag) {
         issueType && setIsloading(false);
      } else if (supportCategoryData === undefined || supportSubCategoryData === undefined) {
         setIsloading(true);
      } else {
         setIsloading(false);
         !supportCategory && setSupportCategory(supportCategoryData[0]?.Value);
         setSupportSubCategory(supportSubCategoryData[0]?.Value);
      }
   }, [supportCategoryData, supportSubCategoryData, issueType]);

   const handleSubmit = async (values) => {
      const captchaToken = await reRef.current.executeAsync();
      const response = await post_support(
         values.fullName,
         userDetails?.email,
         values.customerId.toString(),
         values?.description,
         supportCategory,
         supportSubCategory,
         captchaToken,
      );
      if (response?.response?.data?.status === false) {
         setErrorMessage(response?.response?.data?.message);
         setErrorShow(true);
      } else {
         setShow(true);
      }
   };

   const handleEnterpriseSubmit = async (values) => {
      // console.log("values", values);
      const captchaToken = await reRef.current.executeAsync();
      const response = await post_enterprise_support({
         companyName: values.companyName,
         email: userDetails?.email,
         customerId: values.customerId,
         circuitId: values.circuitId ? values.circuitId : "",
         POD: values.POD ? values.POD : "",
         issueTopic: issueType,
         desc: values.description,
         appointmentDate: values.appoinmentDate,
         captchaToken: captchaToken,
      });
      // console.log("response", response?.response?.data?.status);

      if (response?.response?.data?.message) {
         setErrorMessage(response?.response?.data?.message);
         setErrorShow(true);
      } else {
         setShow(true);
      }
   };

   const schema = yup.object().shape({
      fullName: yup.string().required(),
      description: yup.string().required(),
   });

   const EnterpriseSchema = yup.object().shape({
      companyName: yup.string().required("Company Name is required"),
      customerId: yup.string().required("Customer Id is required"),
      // circuitId: yup.string().required("Circuit Id is required"),
      // POD: yup.string().required("POD is required"),
      description: yup.string().required("Description is required"),
      appoinmentDate: yup
         .date()
         .required("Appointment Date is required")
         .min(new Date(Date.now() - 60 * 60 * 24 * 1000), "Date must be after today"),
   });

   useEffect(() => {
      async function fetchData() {
         const response = await check_enterprise_user();
         setFlag(response?.data?.isEnterpriseCustomer);
      }
      userDetails && fetchData();
   }, [flag, isloading]);

   // Min Date today for appointment date
   var today = new Date();
   today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
   const minDate = today.toISOString().slice(0, 16);

   useEffect(() => {
      async function fetchData() {
         const response = await check_enterprise_user();
         setFlag(response?.data?.isEnterpriseCustomer);
      }
      userDetails && fetchData();
   }, [flag, isloading]);
   return isloading ? (
      <Loading />
   ) : (
      <div style={{ width: "100%" }}>
         <MessageModal
            show={show}
            setShow={setShow}
            heading={"Support Request Submitted"}
            message={"Your support ticket has been submitted successfully"}
            link={"/dashboard?type=3"}
            buttonText={"Ok"}
         />
         <MessageModal
            show={errorShow}
            setShow={setErrorShow}
            heading={"Error Occoured"}
            message={errorMessage}
            buttonText={"Ok"}
            type="error"
         />
         {flag === "" ? (
            <Loading />
         ) : flag ? (
            <Formik
               validationSchema={EnterpriseSchema}
               onSubmit={handleEnterpriseSubmit}
               initialValues={{
                  companyName: userDetails?.name,
                  customerId: userDetails?.customer_id,
                  circuitId: "",
                  POD: "",
                  description: "",
                  appoinmentDate: "",
               }}
            >
               {({ handleSubmit, setFieldValue, handleChange, values, touched, errors }) => (
                  <Form
                     id="dashboard-formwidth "
                     noValidate
                     onSubmit={handleSubmit}
                     className=" support-form-container mt-2"
                  >
                     <div id="form-contact">
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">
                                 Company Name
                                 <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 disabled
                                 name="companyName"
                                 value={values.companyName}
                                 onChange={handleChange}
                                 isValid={touched.companyName && !errors.companyName}
                              />
                           </Form.Group>
                        </div>
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">
                                 Customer ID
                                 <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 disabled
                                 id="input-dashboard"
                                 type="text"
                                 name="customerId"
                                 value={values.customerId}
                                 onChange={handleChange}
                                 isValid={touched.customerId && !errors.customerId}
                              />
                           </Form.Group>
                        </div>
                     </div>
                     {/* <div id="form-contact">
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">
                                 Circuit ID
                                 <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 name="circuitId"
                                 value={values.circuitId}
                                 className={
                                    touched.circuitId && errors.circuitId ? "is-invalid" : null
                                 }
                                 onChange={handleChange}
                                 isValid={touched.circuitId && !errors.circuitId}
                              />
                              {touched.circuitId && errors.circuitId && (
                                 <p className="text-danger mb-0">{errors.circuitId}</p>
                              )}
                           </Form.Group>
                        </div>
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">
                                 POD
                                 <span className="text-danger">*</span>
                              </Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 name="POD"
                                 value={values.POD}
                                 onChange={handleChange}
                                 isValid={touched.POD && !errors.POD}
                              />
                              {touched.POD && errors.POD && (
                                 <p className="text-danger mb-0">{errors.POD}</p>
                              )}
                           </Form.Group>
                        </div>
                     </div> */}
                     <div id="form-contact">
                        <div>
                           <Form.Label id="dashboard-label">
                              Issue Topic
                              <span className="text-danger">*</span>
                           </Form.Label>
                           <Form.Select
                              aria-label="Default select example"
                              id="input-dashboard"
                              onChange={(e) => {
                                 setIssueType(e.target.value);
                              }}
                           >
                              {issueTypes &&
                                 Object.entries(issueTypes).map(([key, value]) => (
                                    <option key={key} value={value?.Id}>
                                       {value?.IssueName}
                                    </option>
                                 ))}
                              {/* {supportCategoryData?.map((item) => (
                              <option key={item?.Value} value={item?.Value}>
                                 {item?.Text}
                              </option>
                           ))} */}
                           </Form.Select>
                        </div>
                        <div>
                           <Form.Label id="dashboard-label">
                              Appointment Date
                              <span className="text-danger">*</span>
                           </Form.Label>
                           {/* Date Picker */}
                           <Form.Control
                              id="input-dashboard"
                              type="date"
                              name="appoinmentDate"
                              style={{ paddingRight: "8px" }}
                              value={values.appoinmentDate}
                              onChange={(e) => setFieldValue("appoinmentDate", e.target.value)}
                              min={new Date().toISOString().split("T")[0]}
                              isValid={touched.appoinmentDate && !errors.appoinmentDate}
                           />
                           {touched.appoinmentDate && errors.appoinmentDate && (
                              <p className="text-danger mb-0">{errors.appoinmentDate}</p>
                           )}
                        </div>
                     </div>
                     <div style={{ marginTop: "20px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label id="dashboard-label">
                              Description
                              <span className="text-danger">*</span>
                           </Form.Label>

                           <Form.Control
                              as="textarea"
                              name="description"
                              rows={3}
                              className="textarea-dashboard"
                              value={values?.description}
                              onChange={handleChange}
                           />
                           {touched.description && errors.description && (
                              <p className="text-danger mb-0">{errors.description}</p>
                           )}
                        </Form.Group>
                     </div>
                     <Button
                        variant="secondary"
                        id="dashboardform-btn"
                        type="submit"
                        className="mb-5"
                     >
                        Request Ticket
                     </Button>
                     <Captcha reRef={reRef} />
                  </Form>
               )}
            </Formik>
         ) : (
            <Formik
               validationSchema={schema}
               onSubmit={handleSubmit}
               initialValues={{
                  fullName: userDetails?.name,
                  customerId: userDetails?.customer_id,
                  primarycontact: userDetails?.phone,
                  secondarycontact: "",
                  description: "",
               }}
            >
               {({ handleSubmit, handleChange, values, touched, errors }) => (
                  <Form
                     id="dashboard-formwidth "
                     noValidate
                     onSubmit={handleSubmit}
                     className=" support-form-container mt-2"
                  >
                     <div id="form-contact">
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Full Name</Form.Label>
                              <Form.Control
                                 id="input-dashboard"
                                 type="text"
                                 name="primary"
                                 disabled
                                 value={values.fullName}
                                 onChange={handleChange}
                                 isValid={touched.fullName && !errors.fullName}
                              />
                           </Form.Group>
                        </div>
                        <div>
                           <Form.Group controlId="validationFormik01">
                              <Form.Label id="dashboard-label">Customer ID</Form.Label>
                              <Form.Control
                                 disabled
                                 id="input-dashboard"
                                 type="text"
                                 name="secondary"
                                 value={values.customerId}
                                 onChange={handleChange}
                                 isValid={touched.customerId && !errors.customerId}
                              />
                           </Form.Group>
                        </div>
                     </div>
                     <div id="form-contact">
                        <div>
                           <Form.Label id="dashboard-label">Issue Category</Form.Label>
                           <Form.Select
                              aria-label="Default select example"
                              id="input-dashboard"
                              onChange={(e) => {
                                 setSupportCategory(e.target.value);
                              }}
                           >
                              {supportCategoryData &&
                                 Object.entries(supportCategoryData).map(([key, value]) => (
                                    <option key={key} value={value?.Value}>
                                       {value?.Text}
                                    </option>
                                 ))}
                              {/* {supportCategoryData?.map((item) => (
                              <option key={item?.Value} value={item?.Value}>
                                 {item?.Text}
                              </option>
                           ))} */}
                           </Form.Select>
                        </div>
                        <div>
                           <Form.Label id="dashboard-label">Issue Topic</Form.Label>
                           <Form.Select
                              aria-label="Default select example"
                              id="input-dashboard"
                              onChange={(e) => {
                                 setSupportSubCategory(e.target.value);
                              }}
                           >
                              {supportSubCategoryData &&
                                 Object.entries(supportSubCategoryData).map(([key, value]) => (
                                    <option key={key} value={value?.Value}>
                                       {value?.Text}
                                    </option>
                                 ))}
                              {/* {supportSubCategoryData?.map((item) => (
                              <option key={item?.Value} value={item?.Value}>
                                 {item?.Text}
                              </option>
                           ))} */}
                           </Form.Select>
                        </div>
                     </div>
                     <div style={{ marginTop: "20px" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                           <Form.Label id="dashboard-label">Description</Form.Label>

                           <Form.Control
                              as="textarea"
                              name="description"
                              rows={3}
                              className="textarea-dashboard"
                              value={values?.description}
                              onChange={handleChange}
                           />
                           <p
                              style={
                                 values?.description.length > 0
                                    ? { display: "none" }
                                    : {
                                         display: "block",
                                         color: "red",
                                         marginLeft: "8px",
                                         marginTop: "4px",
                                      }
                              }
                           >
                              {"Required"}
                           </p>
                        </Form.Group>
                     </div>
                     <Button
                        variant="secondary"
                        id="dashboardform-btn"
                        type="submit"
                        className="mb-5"
                     >
                        Request Ticket
                     </Button>
                     <Captcha reRef={reRef} />
                  </Form>
               )}
            </Formik>
         )}
      </div>
   );
}
