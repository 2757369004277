import React from "react";
import "../../scss/components/features.scss";
import media from "../../assets/media.png";
import global from "../../assets/global.webp";
import time from "../../assets/time.png";
import Widthcontainer from "./widthcontainer";

export default function Features() {
   return (
      <div className="features__container ">
         <div className="features fixed-width">
            <FeaturesList
               img={media}
               title={"Entertainment"}
               name="Explore"
               description={
                  "Enhance Your Viewing Experience with DishHome TV Packages - Find Yours!"
               }
               link="/tv/dishhome-dth"
            />
            <FeaturesList
               img={global}
               title={"Seamless Connectivity"}
               name="Stream Now"
               description={
                  "Seamless Web Adventures Await: Choose Your Perfect Internet Plan Today!"
               }
               link="/internet/residential"
            />
            <FeaturesList
               img={time}
               title={"24/7 Support"}
               name="Contact Us"
               description={"Your Access to Expert Assistance Anytime, Anywhere."}
               link="/company/contact-us"
            />
         </div>
      </div>
   );
}

export function FeaturesList({ img, title, name, description, link }) {
   return (
      <div className="features_container">
         <div className="icon__container">
            <img src={img} alt="media" />
         </div>
         <div>
            <p className="features_title"> {title}</p>
            <p className="features_description" style={{ maxWidth: "240px" }}>
               {description}
            </p>
            <a href={link} className="features_link">
               {name} <span style={{ marginLeft: "7px" }}>{">"}</span>
            </a>
         </div>
      </div>
   );
}
