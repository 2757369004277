import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router";
import "../../scss/packages/faq.scss";
import Widthcontainer from "./widthcontainer";

const Faq = ({ data, type }) => {
   let navigate = useNavigate();
   return (
      <Widthcontainer>
         <div className="faq_wrapper my-5 fixed-width">
            <div className="faq_text px-4 pt-5">
               <h1>Frequently Asked Questions</h1>
               <p>
                  Didn't find the Answer you were looking for? Please{" "}
                  <a href={`/company/faqs?type=${type}`}>see all FAQ's </a>or{" "}
                  <a href="/company/contact-us">Contact Us.</a>
               </p>
            </div>
            <div className="faq_question">
               <Accordion>
                  {data === undefined
                     ? null
                     : Object.entries(data).map(([key, value]) => {
                          return (
                             key < 6 && (
                                <Accordion.Item eventKey={key}>
                                   <Accordion.Header>{value.question}</Accordion.Header>
                                   <Accordion.Body>{value.answer}</Accordion.Body>
                                </Accordion.Item>
                             )
                          );
                       })}
               </Accordion>
               {data?.length > 5 && (
                  <div
                     className="showMore_button cursor_pointer"
                     onClick={() => {
                        navigate(`/company/faqs?type=${type}`);
                     }}
                  >
                     {" "}
                     Show More
                  </div>
               )}
            </div>
         </div>
      </Widthcontainer>
   );
};

export default Faq;
