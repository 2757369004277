import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
// import axios from "axios";
import Token from "../../utils/useToken";
import Layout from "../../components/include/layout";
import successIcon from "../../assets/success.png";
import errorIcon from "../../assets/error2.webp";

// import { ESEWA_TEST_PID, ESEWA_URL, ESEWA_SCD } from "./payconfig";
// import { baseUrl } from "../../constants/defaultValue";
import { handleVerifyPayment } from "../../components/pages/payment/payment";
import Loading from "../../components/customcomponents/loading";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import "../../scss/pages/successPay.scss";

export default function SuccessPay() {
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   // const [res, setRes] = useState(null);
   let query = useQuery();
   //Esewa
   let oid = query.get("oid");
   let amt = query.get("amt");
   let refid = query.get("refId");
   //Ime
   let imeData = query.get("data");
   //Prabhu
   let successprabhu = query.get("success");
   let transactionId = query.get("transactionId");
   let prabhustatus = query.get("status");
   let prabhumessage = query.get("message");
   let TXNID = query.get("TXNID");
   const { userDetails } = Token();

   //success Status
   const [success, setSuccess] = useState("");
   const orderRef = localStorage.getItem("orderRef");
   const orderData = JSON.parse(orderRef);

   useEffect(() => {
      async function payment() {
         await handleVerifyPayment({
            uid: userDetails?.id,
            oid: oid,
            amt: amt,
            refid: refid,
            imeData: imeData,
            successprabhu: successprabhu,
            transactionId: transactionId,
            TXNID: TXNID,
            setSuccess: setSuccess,
         });
      }
      payment();

      // setRes(res);
   }, []);

   //Navigate
   const navigate = useNavigate();

   // console.log("Res: ", res);'
   // console.log("succ", success);
   return success === "" ? (
      <Loading />
   ) : (
      <Layout>
         <Widthcontainer>
            <div className="successMessage fixed-width75">
               <div className="successMessage-image mb-3">
                  <img src={success ? successIcon : errorIcon} alt="success" />
               </div>
               <div className="successMessage-header mb-1 ">
                  {success && orderData?.order_name === "recharge" ? (
                     <h1>Recharged Succesfully</h1>
                  ) : success ? (
                     <h1>Payment Successful</h1>
                  ) : orderData?.order_name === "recharge" ? (
                     <h1>Recharge failed</h1>
                  ) : (
                     <h1>We couldn't complete your payment</h1>
                  )}
               </div>
               <div className="successMessage-body mb-2">
                  {success && orderData?.order_name === "recharge" ? (
                     <p>
                        Your account balance has been recharged with Rs. {orderData?.total}. Thank
                        you for using DishHome.
                     </p>
                  ) : success ? (
                     <p>Your payment has been successfully done.</p>
                  ) : orderData?.order_name === "recharge" ? (
                     <p>
                        There was a problem during recharge process. Please try again or contact
                        <a href="/company/support"> DishHome customer support</a>.
                     </p>
                  ) : (
                     <p
                        style={{
                           maxWidth: "600px",
                           textAlign: "center",
                        }}
                     >
                        Sorry, We detected some problem with the payment system and were unable to
                        complete your payment. Please visit My orders page to continue the payment
                        or{" "}
                        <a
                           href="/company/contact-us"
                           style={{
                              textDecoration: "none",
                           }}
                        >
                           Contact us
                        </a>
                     </p>
                  )}
               </div>

               <div className="successMessage-button">
                  {success ? (
                     <button
                        className="successMessage-button-button"
                        onClick={() => {
                           navigate("/", { replace: true });
                        }}
                     >
                        Go to Home
                     </button>
                  ) : orderData?.order_name === "recharge" ? (
                     <div style={{ display: "flex", gap: "20px" }}>
                        <button
                           className="successMessage-button-button"
                           onClick={() => {
                              navigate("/");
                           }}
                        >
                           Home Page
                        </button>
                     </div>
                  ) : (
                     <div style={{ display: "flex", gap: "20px" }}>
                        <button
                           className="successMessage-button-button"
                           onClick={() => {
                              navigate(
                                 orderData?.order_name === "package"
                                    ? "/dashboard?type=9&option=package"
                                    : "/dashboard?type=9&option=product",
                                 { replace: true },
                              );
                           }}
                        >
                           My Orders
                        </button>
                        {/* <button
                           className="successMessage-button-button"
                           onClick={() => {
                              navigate("/");
                           }}
                        >
                           Home Page
                        </button> */}
                     </div>
                  )}
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
}
