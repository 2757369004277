import { React, useState, useEffect } from "react";
import Layout from "../include/layout";
import Widthcontainer from "../customcomponents/widthcontainer";
import Img from "../../assets/download.webp";
import "../../scss/pages/downloads.scss";
import { get_download_item, get_download_file } from "../../data/api";
import Loading from "../customcomponents/loading";
import fileDownload from "js-file-download";
import ReactPaginate from "react-paginate";

import axios from "axios";

const Downloads = () => {
   const [DownloadData, setDownloadData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [pageCount, setPageCount] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);
   const [cartupdate, setCartupdate] = useState(false);

   useEffect(() => {
      async function fetchData() {
         const response = await get_download_item(currentPage);
         setDownloadData(response.data.data);
         setPageCount(response?.data?.paging?.pages);
      }
      fetchData();
   }, [empty, pageCount, currentPage]);

   const handlePageClick = async (e) => {
      setCurrentPage(e.selected + 1);
   };

   useEffect(() => {
      if (DownloadData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });

   return empty ? (
      <Loading />
   ) : (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         <Widthcontainer className={"downloads_width_container"}>
            <div className="downloads_banner fixed-width">
               <div className="downloads_banner_text">
                  <p className="downloads">Downloads </p>
                  <h1 className="downloads_header">
                     Thankyou for <span>Downloading! </span>
                  </h1>
                  <p className="downloads_sub_header">
                     Download our tutorial files and videos here.The provided information is all
                     about DishHome products and services.
                  </p>
               </div>
               <div className="downloads_banner_img">
                  <img
                     src={Img}
                     alt="top"
                     style={{ height: "300px", marginTop: "60px", marginRight: "55px" }}
                  />
               </div>
            </div>
         </Widthcontainer>
         <Widthcontainer>
            <div className="downloads_container fixed-width mb-4">
               <div className="downloads_container_title_container mb-5">
                  <div className="titleContainer">
                     <h2 className="title">Downloads</h2>
                     <p className="sub_caption">
                        All details information about DishHome internet, Tv, MDU etc
                     </p>
                  </div>
               </div>
               <div className="downloads_item_container mb-4">
                  {Object.entries(DownloadData).map(([key, value]) => {
                     return (
                        <DownloadItem
                           img={`${process.env.REACT_APP_FILE_URL}/${value.thumbnail}`}
                           heading={value.title}
                           sub_heading={value.description}
                           file={`${process.env.REACT_APP_FILE_URL}/${value?.file}`}
                           filename={value.file}
                        />
                     );
                  })}
               </div>
               {pageCount > 1 && (
                  <ReactPaginate
                     previousLabel={"Previous"}
                     nextLabel={"Next"}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={3}
                     onPageChange={handlePageClick}
                     containerClassName={"pagination dashboard_pagination"}
                     pageClassName={"page-item dashboard_page_item"}
                     pageLinkClassName={"page-link dashboard_page_link"}
                     previousClassName={"page-item"}
                     previousLinkClassName={"page-link"}
                     nextClassName={"page-item"}
                     nextLinkClassName={"page-link"}
                     breakClassName={"page-item"}
                     breakLinkClassName={"page-link"}
                     activeClassName={"active dashboard_active"}
                  />
               )}
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export function DownloadItem(props) {
   const handleDownload = async (filename) => {
      const response = await get_download_file(filename);
      fileDownload(response.data, filename);
   };

   return (
      <div className="downloads_item">
         <img src={props.img} alt="" className="download_item_thumbnail" />
         <h1 className="download_item_heading">{props.heading}</h1>
         <p className="download_item_sub_heading">{props.sub_heading}</p>
         <button
            className="download_item_button cursor_pointer"
            onClick={() => {
               handleDownload(props.filename);
            }}
         >
            Download
         </button>
      </div>
   );
}

export default Downloads;
