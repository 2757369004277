import React, { useState, useEffect } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import NextMovieCard from "./nextmoviecard";
import "../../scss/ppvmovies/nextmovies.scss";
import { get_all_ppv_movies } from "../../data/api";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

const NextMovies = () => {
   const [Toggle, setToggle] = useState(true);
   //true= Next Change false = Comming soon

   //UseState
   const [movies, setMovies] = useState("");
   const [loading, setLoading] = useState(true);

   //useEffect
   useEffect(() => {
      async function get_movies() {
         const response = await get_all_ppv_movies();
         setMovies(response?.data?.data);
      }
      get_movies();
   }, []);

   useEffect(() => {
      if (movies === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   }, [movies]);

   useEffect(() => {
      movies && setMovies((movies) => movies?.filter((m) => m?.type === "Upcoming"));
   }, [loading]);
   // console.log(movies);

   return (
      movies?.length > 0 && (
         <div className="nextmovies_wrapper py-5">
            <div className="nextmovies_text mb-5 ms-2">
               <ButtonGroup>
                  <button
                     className={"date_button"}
                     // onClick={() => {
                     //    setToggle(true);
                     // }}
                  >
                     Coming Soon
                  </button>
                  {/* <Button
                  className={Toggle ? "date_button" : "date_button active"}
                  onClick={() => {
                     setToggle(false);
                  }}
               >
                  Comming Soon
               </Button> */}
               </ButtonGroup>
            </div>
            {loading ? (
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     height: "450px",
                     marginTop: "80px",
                     marginBottom: "80px",
                  }}
               >
                  <ReactLoading type="spin" color="#ED1C24" height={205} width={205} />
               </div>
            ) : (
               <>
                  {Toggle ? (
                     <div className="nextmovies_moviecontainer">
                        <Splide
                           options={{
                              type: "slide",
                              hasTrack: false,
                              perPage: 3,
                              perMove: 3,
                              autoHeight: true,
                              autoWidth: true,
                              gap: "65px",
                              breakpoints: {
                                 780: {
                                    perPage: 1,
                                    perMove: 1,
                                 },
                                 992: {
                                    perPage: 2,
                                    perMove: 1,
                                 },
                                 1200: {
                                    perPage: 3,
                                    perMove: 3,
                                 },
                                 1800: {
                                    perPage: 3,
                                    perMove: 3,
                                 },
                              },
                           }}
                        >
                           {movies &&
                              Object.entries(movies).map(([key, value]) => {
                                 if (value.type === "Upcoming") {
                                    return (
                                       <SplideSlide>
                                          <NextMovieCard
                                             img={value.thumbnail}
                                             title={value.title}
                                             trailer_link={value.trailer_link}
                                          />
                                       </SplideSlide>
                                    );
                                 } else {
                                    return null;
                                 }
                              })}
                        </Splide>
                     </div>
                  ) : (
                     <div className="nextmovies_moviecontainer">
                        <Splide
                           options={{
                              type: "slide",
                              hasTrack: false,
                              perPage: 3,
                              perMove: 3,
                              autoHeight: true,
                              autoWidth: true,
                              gap: "65px",
                              breakpoints: {
                                 780: {
                                    perPage: 1,
                                    perMove: 1,
                                 },
                                 992: {
                                    perPage: 2,
                                    perMove: 1,
                                 },
                                 1200: {
                                    perPage: 3,
                                    perMove: 3,
                                 },
                                 1800: {
                                    perPage: 3,
                                    perMove: 3,
                                 },
                              },
                           }}
                        >
                           {movies &&
                              Object.entries(movies).map(([key, value]) => {
                                 if (value.type === "Comming Soon") {
                                    return (
                                       <SplideSlide>
                                          <NextMovieCard
                                             img={value.image}
                                             title={value.title}
                                             trailer_link={value.trailer_link}
                                          />
                                       </SplideSlide>
                                    );
                                 } else {
                                    return null;
                                 }
                              })}
                        </Splide>
                     </div>
                  )}
               </>
            )}
         </div>
      )
   );
};

export default NextMovies;
