import { React, useRef, useState } from "react";
import img from "../../assets/refer.png";
import "../../scss/pages/form.scss";
import "../../scss/pages/forgotpassword.scss";
import Layout from "../include/layout";
import { forgot_password, forgot_password_verify_otp } from "../../data/api";
import { useNavigate } from "react-router";
import { forgot_password_change_pw } from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import Widthcontainer from "../customcomponents/widthcontainer";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import Captcha from "../include/captcha";

const ForgotPassword = () => {
   let navigate = useNavigate();
   const [number, setNumber] = useState("");
   const [verify, setVerify] = useState(false);
   const [otp, setOtp] = useState("");
   const [resetToken, setResetToken] = useState("");
   const [password, setPassword] = useState("");
   const [show, setShow] = useState(false);
   const [confPassword, setConfPassword] = useState("");
   const [message, setMessage] = useState("");
   const [error, setError] = useState("");
   const [errorShow, setErrorShow] = useState(false);
   const [messageModalType, setMessageModalType] = useState("success");
   const [successMessage, setSuccessMessage] = useState(false);
   const reRef = useRef();
   const handleSubmit = async (e) => {
      e.preventDefault();
      let phone = number;
      if (number?.length > 10) {
         if (number?.charAt(0) === "0") {
            phone = number.substring(1);
         } else if (number?.slice(0, 4) === "+977") {
            phone = number?.slice(4, 18);
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      const response = await forgot_password(phone, captchaToken);
      if (response?.data?.success === true) {
         // alert("OTP sent to your registered mobile number");
         setMessageModalType("success");
         setMessage("Verification code has been  sent to your registered mobile number");
         setShow(true);
         setVerify(true);
      } else {
         setMessageModalType("error");
         setMessage(response?.response?.data?.message);
         setShow(true);
         setShow(true);
      }
   };

   const verifyOtp = async (e) => {
      e.preventDefault();
      let phone = number;
      if (number?.length > 10) {
         if (number?.charAt(0) === "0") {
            phone = number.substring(1);
         } else if (number?.slice(0, 4) === "+977") {
            phone = number?.slice(4, 18);
         }
      }
      const captchaToken = await reRef.current.executeAsync();
      const response = await forgot_password_verify_otp(phone, otp, captchaToken);
      if (response?.response?.data?.code) {
         setErrorShow(true);
         setError(response?.response?.data?.message);
      } else {
         setResetToken(response?.data?.data?.resetToken);
      }
   };

   const handleChange = async (values) => {
      const captchaToken = await reRef.current.executeAsync();

      const response = await forgot_password_change_pw(
         resetToken,
         values?.password,
         values?.confirmpassword,
         captchaToken,
      );
      if (response.status === 200) {
         setSuccessMessage(true);
         setMessage("Password changed successfully");
      } else {
         setMessageModalType("error");
         setMessage(response?.response?.data?.message);
         setShow(true);
      }
   };

   const schema = yup.object().shape({
      password: yup
         .string()
         .required("Password is required")
         .matches(
            /^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
            "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character and no whitespace",
         ),
      confirmpassword: yup
         .string()
         .required("Confirm Password is required")
         .oneOf([yup.ref("password")], "Passwords do not match"),
   });
   //Show Pw

   const [showConfPassword, setShowConfPassword] = useState(false);
   const [showPassword, setshowPassword] = useState(false);
   const [showNewpassword, setshowNewpassword] = useState(false);

   const togglePassword = () => {
      setshowPassword(!showPassword);
   };
   const newPassword = () => {
      setshowNewpassword(!showNewpassword);
   };
   return (
      <Layout>
         <MessageModal
            show={show}
            setShow={setShow}
            message={message}
            type={messageModalType}
            buttonText={"Ok"}
         />
         <MessageModal
            show={errorShow}
            type="error"
            heading="Verification Failed"
            setShow={setErrorShow}
            message={"Please enter the correct verification code sent to your phone."}
            buttonText={"OK"}
         />
         <MessageModal
            show={successMessage}
            type="success"
            setShow={setSuccessMessage}
            heading="Verification Successful"
            message={"Your password has been changed successfully. Thank you for using DishHome."}
            buttonText={"OK"}
            link="/dashboard"
            isNavigate={true}
            replace={true}
         />
         <Widthcontainer>
            <div className={"ForgotPassword_Wrapper fixed-width my-4"}>
               {verify ? (
                  <>
                     {resetToken === "" ? (
                        <div className={"ForgotPassword_Form"}>
                           {/* <Logo /> */}
                           <div className={"ForgotPassword_Container"}>
                              <h1 className={"For'gotPassword_Header"}>Verify OTP</h1>
                              <h3 className={"ForgotPassword_Text"}>
                                 Please enter the verification code sent to your registered mobile
                                 number to complete registration.
                              </h3>
                              <label htmlFor="phone">OTP</label>
                              <input
                                 type="number"
                                 name="otp"
                                 className={"Phone_Input"}
                                 placeholder=""
                                 value={otp}
                                 onChange={(e) => setOtp(e.target.value)}
                              />{" "}
                              <button className={"ForgotPassword_Button"} onClick={verifyOtp}>
                                 Verify
                              </button>
                              <h3 className={"ForgotPassword_Text"}>
                                 Didn't get verification code ?
                                 <a style={{ cursor: "pointer" }} onClick={handleSubmit} href>
                                    {" "}
                                    Resend{" "}
                                 </a>
                              </h3>
                           </div>
                           <Captcha reRef={reRef} />
                        </div>
                     ) : (
                        <div className={"ForgotPassword_Form"}>
                           {/* <Logo /> */}
                           <Formik
                              validationSchema={schema}
                              onSubmit={handleChange}
                              initialValues={{
                                 password: "",
                                 confirmpassword: "",
                              }}
                           >
                              {({ handleSubmit, handleChange, values, touched, errors }) => (
                                 <Form
                                    id="dashboard-formwidth"
                                    className={"ForgotPassword_Container"}
                                    noValidate
                                    onSubmit={handleSubmit}
                                 >
                                    <h1 className={"ForgotPassword_Header"}>
                                       Change Your Password
                                    </h1>
                                    <Form.Group controlId="validationFormik01">
                                       <div className={"Password_Container"}>
                                          <Form.Label id="dashboard-label"> Password</Form.Label>
                                          <Form.Control
                                             type={showPassword ? "text" : "password"}
                                             id="input-dashboard currentpw"
                                             name="password"
                                             className={"Email_Input"}
                                             autoComplete="off"
                                             controlId="validationFormik01"
                                             style={{ paddingRight: "38px" }}
                                             onChange={handleChange}
                                             onPaste={(e) => {
                                                e.preventDefault();
                                                return false;
                                             }}
                                             value={values?.password}
                                          />{" "}
                                          {!showPassword && (
                                             <AiOutlineEyeInvisible
                                                size={19}
                                                className={"Pw_Icon"}
                                                onClick={togglePassword}
                                             />
                                          )}
                                          {showPassword && (
                                             <span className={"Pw_Icon"} onClick={togglePassword}>
                                                <svg
                                                   width="16"
                                                   height="16"
                                                   viewBox="0 0 16 16"
                                                   fill="none"
                                                   xmlns="http://www.w3.org/2000/svg"
                                                >
                                                   <path
                                                      d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                      fill="#374253"
                                                   />
                                                </svg>{" "}
                                             </span>
                                          )}
                                          {errors?.password ? (
                                             <p
                                                className="error_message"
                                                style={{ color: "#ed1c24" }}
                                             >
                                                {errors?.password}
                                             </p>
                                          ) : null}
                                       </div>
                                    </Form.Group>

                                    <div className="Password_Container">
                                       <label htmlFor="confirmpassword">Confirm Password</label>
                                       <input
                                          type={showNewpassword ? "text" : "password"}
                                          name="confirmpassword"
                                          autoComplete="off"
                                          min={"0"}
                                          style={{ paddingRight: "38px" }}
                                          className={
                                             errors?.confirmpassword ? "" : "Password_Input"
                                          }
                                          placeholder=""
                                          value={values?.confirmpassword}
                                          onPaste={(e) => {
                                             e.preventDefault();
                                             return false;
                                          }}
                                          onChange={handleChange}
                                       />{" "}
                                       {!showNewpassword && (
                                          <AiOutlineEyeInvisible
                                             size={19}
                                             className={"Pw_Icon"}
                                             onClick={newPassword}
                                          />
                                       )}
                                       {showNewpassword && (
                                          <span className={"Pw_Icon"} onClick={newPassword}>
                                             <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                             >
                                                <path
                                                   d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                                   fill="#374253"
                                                />
                                             </svg>{" "}
                                          </span>
                                       )}
                                       {errors?.confirmpassword ? (
                                          <p className="error_message" style={{ color: "#ed1c24" }}>
                                             {errors?.confirmpassword}
                                          </p>
                                       ) : null}
                                    </div>
                                    <button className={"ForgotPassword_Button"} type="submit">
                                       Change Password
                                    </button>
                                 </Form>
                              )}
                           </Formik>
                           <Captcha reRef={reRef} />
                        </div>
                     )}
                  </>
               ) : (
                  <div className={"ForgotPassword_Form"}>
                     {/* <Logo /> */}
                     <div className={"ForgotPassword_Container"}>
                        <h1 className={"ForgotPassword_Header"}>Forgot Password</h1>
                        <h3 className={"ForgotPassword_Text"}>
                           Don't worry! We got your back. Enter your registered mobile number to get
                           veification code.
                        </h3>
                        <label htmlFor="number">Phone Number</label>
                        <input
                           type="tel"
                           name="number"
                           className={"Phone_Input"}
                           placeholder=""
                           value={number}
                           autoComplete="on"
                           maxLength={14}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                    setNumber(e?.target?.value);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    setNumber(e?.target?.value);
                                 }
                              } else {
                                 setNumber(e?.target?.value);
                              }
                           }}
                        />{" "}
                        <button className={"ForgotPassword_Button red"} onClick={handleSubmit}>
                           Send Verification Code
                        </button>
                        <h3 className={"ForgotPassword_Text"}>
                           Go back to
                           <a href="/login"> sign in </a>
                        </h3>
                     </div>
                     <Captcha reRef={reRef} />
                  </div>
               )}
               <div className={"Forgot_Image"}>
                  <img src={img} alt="" />
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export default ForgotPassword;
