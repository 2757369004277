import { Navigate, Route, useLocation } from "react-router-dom";
import Token from "../utils/useToken";

const ProtectedRoute = ({ path, redirectPath, element }) => {
   const { token } = Token();
   let location = useLocation();

   if (
      token === null &&
      location?.pathname !== "/dashboard" &&
      location?.pathname !== "/recharge" &&
      location?.pathname !== "/internet/refer"
   ) {
      return <Navigate to="/login" replace={true} />;
   }

   if (
      token === null &&
      (location?.pathname === "/dashboard" ||
         location?.pathname === "/recharge" ||
         location?.pathname === "/internet/refer")
   ) {
      return <Navigate to="/login" />;
   }
   return element;
   // if (token === null) {
   //     return <Navigate to={redirectPath} replace />;
   // } else {
   //     return <Route exact path={path} element={element} />;
   // }
};

export default ProtectedRoute;

export const RouteWithoutLogin = ({ path, redirectPath, element }) => {
   const { token } = Token();
   if (token !== null) {
      return <Navigate to="/" replace={true} />;
   }
   return element;
};
