import React, { useEffect, useRef, useState } from "react";
import Navigation from "../../components/include/navbar";
import "../../scss/store/specials/category.scss";
// import menu from "../../assets/categories/menu.png";
// import sort from "../../assets/categories/sort.png";
import search from "../../assets/search.png";
import { Button, Form, FormControl, InputGroup, Offcanvas } from "react-bootstrap";
import {
   filter_product,
   get_active_sale,
   get_all_category,
   get_all_category_product,
   get_all_products,
   get_brand,
   get_colors,
   get_new_arrival,
   get_products_by_category,
   get_product_tag,
   get_single_category,
   search_product,
} from "../../data/api";
// import Card from "react-bootstrap/Card";
import Widthcontainer from "../../components/customcomponents/widthcontainer";
import ReactStars from "react-rating-stars-component";
import products from "../../assets/store/Products.png";
// import { ContactSupportOutlined, Filter } from "@material-ui/icons";
// import RangeSlider from "react-bootstrap-range-slider";
import { useLocation } from "react-router-dom";
// import Slider from "@mui/material/Slider";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router";
// import Loading from "../../components/customcomponents/loading";
// import { ControlsStrategy } from "react-alice-carousel";
// import { FormControlUnstyled } from "@mui/base";
import Layout from "../../components/include/layout";
import Error from "../../components/pages/404error";
import Loading from "../../components/customcomponents/loading";
import SeoTags from "../../utils/seoTags";
import { BreadContainer } from "../../components/include/breadcrumb";
export default function Category() {
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let querybrand = query.get("type");
   let saletype = query.get("sale");

   let brandcategory = query.get("category");

   const [sorting, setSorting] = useState("asc-price");
   const [newarrivalsdata, setnewarrivalsData] = useState("");

   const [value, setValue] = useState([0, 0]);
   const [brand, setBrand] = useState([]);
   const [category, setCategory] = useState([]);
   const [tag, setTag] = useState("");
   const [limit, setLimit] = useState(10);
   const [cName, setClassName] = useState("GridView");
   const [listview, setListview] = useState(false);
   const [pageCount, setpageCount] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);

   const [color, setColor] = useState([]);
   const [brandcolor, setBrandColor] = useState([]);
   const [brandtag, setBrandTag] = useState([]);

   const [sendprice, setSendprice] = useState(false);
   const [maxprice, setMaxprice] = useState("");
   const [checked, setChecked] = useState(false);
   const [minprice, setMinprice] = useState("");
   const [finalminprice, setFinalminprice] = useState("");
   const [finalmaxprice, setFinalmaxprice] = useState("");
   const [showcategory, setShowcategory] = useState("");
   const [indvcategory, setIndvcategory] = useState("");
   const [sortupdate, setSortupdate] = useState(false);
   const [productsearch, setProductsearch] = useState("");
   const [productresult, setProductresult] = useState("");
   const ratingChanged = (newRating) => {};

   //search products

   useEffect(() => {
      async function get_all_products() {
         const res = await search_product(productsearch, currentPage, limit);
         setnewarrivalsData(res?.data?.data);
         // setpageCount(res?.data?.paging?.pages);
      }
      get_all_products();
   }, [productsearch, currentPage]);

   useEffect(() => {
      if (productsearch) {
         setCurrentPage("1");
      }
   });

   //get active sale

   const [saleItem, setSaleItem] = useState([]);
   const [saleName, setSaleName] = useState([]);
   const [categoryTitle, setCategoryTitle] = useState("");
   useEffect(() => {
      async function get_category_name() {
         const res = await get_single_category(category[0]);
         setCategoryTitle(res?.data?.message?.title);
      }
      category?.length === 1 && get_category_name();
      category?.length === 0 && setCategoryTitle("");
      category?.length > 1 && setCategoryTitle("");
   }, [category]);
   // console.log("category_name", categoryTitle);
   useEffect(() => {
      async function get_tag() {
         const res = await get_active_sale();

         setSaleItem(res?.data?.data);
      }
      get_tag();
   }, []);

   //get product tag
   const [productTag, setProductTag] = useState([]);

   useEffect(() => {
      async function get_tag() {
         const res = await get_product_tag();
         setProductTag(res?.data?.data);
         // console.log("product_tag", res);
      }
      get_tag();
   }, []);
   //handle sale click
   const handleSale = (val) => {
      setChecked(!checked);
      setSaleName(val);
   };
   useEffect(() => {
      if (saletype) {
         setSaleName(saletype);
      } else {
         setSaleName("");
      }
   }, [saletype]);

   //filter  product

   useEffect(() => {
      async function get_filter_products() {
         const res = await filter_product(
            limit,
            currentPage,
            category,
            brand,
            brandcolor,
            brandtag,
            minprice,
            maxprice,
            saleName,
            sorting?.includes("name") ? "name" : "price",
            sorting?.includes("dsc") ? "desc" : "asc",
         );

         setpageCount(res?.data?.paging?.pages);
         setnewarrivalsData(res?.data?.data);
      }
      get_filter_products();
   }, [
      brand,
      brandcolor,
      brandtag,
      finalmaxprice,
      finalminprice,
      limit,
      category,
      pageCount,
      currentPage,
      saleName,
      sorting,
   ]);

   // useEffect(() => {
   //    axios
   //       .get(
   //          `${process.env.REACT_APP_BASE_URL}v1/shop/get?limit=${limit}page=${currentPage}&name=&category=${category}&brand=${brand}&color=${brandcolor}&minPrice=${minprice}&maxPrice=${maxprice}&sale=${saleName}`,
   //       )

   //       .then((res) => {
   // console.log(res);
   // setpageCount(res?.data?.paging?.pages);
   // setnewarrivalsData(res?.data?.data);
   // console.log("new_arrivals", res?.data?.data);
   //       })

   //       .catch((err) => console.log(err));
   // }, [brand, brandcolor, finalmaxprice, finalminprice, limit, category, pageCount, saleName]);
   // console.log("brand_list", brand);

   //category from store page
   useEffect(() => {
      if (brandcategory) {
         setCategory([...category, brandcategory]);
      }
   }, [brandcategory]);

   //brand from store page
   useEffect(() => {
      if (querybrand) {
         setBrand([...brand, querybrand]);
      }
   }, [brandcategory]);

   useEffect(() => {
      async function get_category() {
         const res = await get_all_category();
         const response = res?.data?.data;
         setShowcategory(response);
      }
      get_category();
   }, []);

   //colors
   useEffect(() => {
      async function get_all_colors() {
         const res = await get_colors();

         const response = res.data.data;
         setColor(response);
      }
      get_all_colors();
   }, []);

   const handleSorting = (e) => {
      setSorting(e.target.value);
   };

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };
   //pagination

   const handleBrand = (val) => {
      // setSorting("brand");
      // setChecked(!checked);
      // setBrand(val);
      // setBrandColor("");
   };

   const [activeColor, setActiveColor] = useState(false);

   const handleColor = (value) => {
      if (brandcolor.includes(value)) {
         setBrandColor(brandcolor.filter((brandcolor) => brandcolor !== value));
      } else {
         setBrandColor([...brandcolor, value]);
      }
   };

   const handleProductTag = (value) => {
      if (brandtag.includes(value)) {
         setBrandTag(brandtag.filter((brandtag) => brandtag !== value));
      } else {
         setBrandTag([...brandtag, value]);
      }
   };

   const myContainer = useRef(null);

   const handleListview = (e) => {
      e.preventDefault();

      setListview(true);
   };

   const handleGridview = (e) => {
      e.preventDefault();
      setListview(false);
   };

   const handlePriceFilter = () => {
      if (maxprice || minprice) {
         setSendprice(true);
         setFinalminprice(minprice);
         setFinalmaxprice(maxprice);
      }
   };
   const handlePageClick = (data) => {
      let currentPage = data.selected + 1;
      setCurrentPage(currentPage);
   };

   const [isEmpty, setIsEmpty] = useState("");

   useEffect(() => {
      if (newarrivalsdata === "") {
         setIsEmpty(true);
      } else {
         setIsEmpty(false);
      }
   }, [newarrivalsdata]);
   const location = useLocation();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   return isEmpty ? (
      <Loading />
   ) : !newarrivalsdata ? (
      <Error />
   ) : (
      <Layout>
         <BreadContainer mainpath={"store"} pathname={"Category"} categoryname={categoryTitle} />
         {SeoTags("Category | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}
         <Widthcontainer>
            <div className="category-products fixed-width" style={{ marginTop: "50px" }}>
               <div className="canvasopen" onClick={handleShow}>
                  Shop By
               </div>
               <Offcanvas show={show} onHide={handleClose} style={{ width: "340px" }}>
                  <Offcanvas.Body>
                     {/* sidebar of category */}
                     <FilterSection
                        saleName={saleName}
                        setSaleName={setSaleName}
                        saleItem={saleItem}
                        productTag={productTag}
                        brandtag={brandtag}
                        setBrandTag={setBrandTag}
                        setProductTag={setProductTag}
                        activeColor={activeColor}
                        handleBrand={handleBrand}
                        handleSale={handleSale}
                        setCategory={setCategory}
                        handleColor={handleColor}
                        handleProductTag={handleProductTag}
                        color={color}
                        brandcolor={brandcolor}
                        minprice={minprice}
                        setProductsearch={setProductsearch}
                        setMinprice={setMinprice}
                        setMaxprice={setMaxprice}
                        maxprice={maxprice}
                        sendprice={sendprice}
                        brand={brand}
                        brandcategory={brandcategory}
                        category={category}
                        showcategory={showcategory}
                        setBrand={setBrand}
                        setBrandColor={setBrandColor}
                        querybrand={querybrand}
                        saletype={saletype}
                        handlePriceFilter={(e) => handlePriceFilter(e)}
                        // handleTag={handleTag}
                     />
                  </Offcanvas.Body>
               </Offcanvas>
               <div style={{ flexBasis: "20%" }} className="filter__section">
                  <FilterSection
                     saleItem={saleItem}
                     saleName={saleName}
                     setSaleName={setSaleName}
                     productTag={productTag}
                     setProductTag={setProductTag}
                     brandtag={brandtag}
                     setBrandTag={setBrandTag}
                     // value={value}
                     // newarrivalsdata={newarrivalsdata}
                     handleBrand={handleBrand}
                     setCategory={setCategory}
                     handleColor={handleColor}
                     handleProductTag={handleProductTag}
                     color={color}
                     brandcolor={brandcolor}
                     minprice={minprice}
                     setProductsearch={setProductsearch}
                     setMinprice={setMinprice}
                     setMaxprice={setMaxprice}
                     maxprice={maxprice}
                     sendprice={sendprice}
                     brand={brand}
                     brandcategory={brandcategory}
                     category={category}
                     showcategory={showcategory}
                     setBrand={setBrand}
                     setBrandColor={setBrandColor}
                     querybrand={querybrand}
                     saletype={saletype}
                     handlePriceFilter={(e) => handlePriceFilter(e)}
                     // handleTag={handleTag}
                  />
               </div>
               <div style={{ flexBasis: "78%", paddingLeft: "8px", paddingRight: "8px" }}>
                  {/* section containing dropdown and sorting */}
                  <Header
                     listview={listview}
                     handleListview={handleListview}
                     handleGridview={handleGridview}
                     setLimit={setLimit}
                     handleSorting={handleSorting}
                  />
                  {newarrivalsdata?.length !== 0 ? (
                     <div className="filterproducts">
                        <div
                           className={`${listview ? "category_box_list" : "category_box"}`}
                           id="category_products"
                        >
                           {Object.entries(newarrivalsdata)?.map(([key, value]) => {
                              return (
                                 <div
                                    onClick={() => {
                                       window.location = "/store/" + value._id;
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className={`${listview ? "list_view " : "product_view "}`}
                                 >
                                    {value.sale.saleId !== null ? (
                                       <div className="category_imagewrapper">
                                          <div id={listview ? "sale-tag2" : "sale-tag-category"}>
                                             <p>Sale offer</p>
                                          </div>

                                          <img
                                             className="view_img"
                                             src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                                             alt=""
                                          />
                                       </div>
                                    ) : (
                                       <div className="category_imagewrapper">
                                          <img
                                             className="view_img"
                                             src={`${process.env.REACT_APP_FILE_URL}/${value.featured_image}`}
                                             alt=""
                                          />
                                       </div>
                                    )}

                                    <div className={`${listview ? "details_info " : "null"}`}>
                                       <div
                                          className={`${listview ? "brand-list " : "brand-grid "}`}
                                       >
                                          <img src={products} alt="" />
                                       </div>

                                       <p
                                          className={`${
                                             listview ? "listview-title " : "view_title "
                                          }`}
                                       >
                                          {value.name}
                                       </p>

                                       {value.sale.saleId !== null ? (
                                          <p
                                             className={`${
                                                listview
                                                   ? "categorylist_price "
                                                   : "categorygrid_price"
                                             }`}
                                             style={{ textDecoration: "line-through" }}
                                          >
                                             Rs. {value.price}
                                             <br />
                                          </p>
                                       ) : (
                                          <p
                                             className={`${
                                                listview
                                                   ? "categorylist_price "
                                                   : "categorygrid_price"
                                             }`}
                                          >
                                             Rs. {value.price} <br />
                                          </p>
                                       )}

                                       <p
                                          className={`${
                                             listview ? "categorylist_price " : "categorygrid_price"
                                          }`}
                                       >
                                          {" "}
                                          {value.sale.sale_price ? "Rs. " : ""}{" "}
                                          {value.sale.sale_price}
                                       </p>
                                       <div className={`${listview ? "null" : "star-grid "}`}>
                                          <p
                                             style={{
                                                display: "flex",
                                                gap: "4px",
                                                alignItems: "center",
                                             }}
                                          >
                                             {value?.reviews?.length > 0 && (
                                                <ReactStars
                                                   value={value?.rating}
                                                   count={5}
                                                   edit={false}
                                                   size={24}
                                                   activeColor="#ffd700"
                                                   color="#D0D0D0"
                                                />
                                             )}
                                          </p>
                                       </div>
                                       <p
                                          className={`${
                                             listview ? "list_description " : "list_grid "
                                          }`}
                                       >
                                          {value.description}
                                       </p>
                                    </div>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                  ) : (
                     <h1 style={{ textAlign: "center" }}>No results found</h1>
                  )}
               </div>
            </div>
         </Widthcontainer>
         <div
            style={{
               marginTop: "20px",
               display: "flex",
               justifyContent: "center",
            }}
         >
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination justify-content-center"}
               pageClassName={"page-item"}
               pageLinkClassName={"page-link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active"}
            />
         </div>
      </Layout>
   );
}

//sorting and filtering section
export function Header({ listview, handleListview, handleGridview, setLimit, handleSorting }) {
   return (
      <div>
         <div className="category-bar ">
            <div className="icon-div">
               <div className={`${listview ? "active-view " : "list "}`}>
                  <AiOutlineUnorderedList onClick={(e) => handleListview(e)} size={19} />
               </div>
               <div className={`${listview ? "grid " : "active-view "}`}>
                  <BsFillGrid3X3GapFill onClick={(e) => handleGridview(e)} />
               </div>
            </div>

            <div className="form-category">
               <div>
                  <Form.Select
                     aria-label="Default select example"
                     id="form-div1"
                     onChange={(e) => setLimit(e.target.value)}
                  >
                     <option value="5">Show 5 per page</option>
                     <option value="10" selected>
                        Show 10 per page
                     </option>
                  </Form.Select>
               </div>
               <div>
                  <Form.Select
                     onChange={(e) => handleSorting(e)}
                     aria-label="Default select example"
                     id="form-div"
                  >
                     <option value="asc-price">Sort by price(low-high)</option>
                     <option value="dsc-price">Sort by price(high-low)</option>
                     <option value="asc-name">Sort by name(A-Z)</option>
                     <option value="dsc-name">Sort by name(Z-A)</option>
                  </Form.Select>
               </div>
            </div>
         </div>
      </div>
   );
}

//sidebar containing all the filters

export function FilterSection({
   saleItem,
   setSaleItem,
   productTag,
   setProductTag,
   brandtag,
   setBrandTag,
   saleName,
   setSaleName,
   color,
   handleColor,
   handleProductTag,
   setProductsearch,
   value,
   newarrivalsdata,
   showcategory,
   handleBrand,
   handlePriceFilter,
   handleSale,
   minprice,
   maxprice,
   setCategory,
   sendprice,
   checked,
   brand,
   category,
   setBrand,
   setBrandColor,
   setMaxprice,
   setMinprice,
   brandcolor,
   handleTag,
   querybrand,
   saletype,
   activeColor,
   brandcategory,
}) {
   const [brandlist, setBrandList] = useState("");
   const [brandtype, setBrandtype] = useState("");
   let navigate = useNavigate();

   useEffect(() => {
      async function get_filter_brand() {
         const res = await get_brand();

         setBrandList(res?.data?.data);
      }
      get_filter_brand();
   }, []);

   return (
      <div>
         <div className="section_price_search">
            <InputGroup>
               <InputGroup.Text id="search-locate-icon">
                  <img src={search} />
               </InputGroup.Text>
               <FormControl
                  id="search-locate"
                  aria-label="Small"
                  placeholder="Search for products"
                  onChange={(e) => setProductsearch(e.target.value)}
                  aria-describedby="inputGroup-sizing-sm"
               />
            </InputGroup>

            <p className="slider-label">Price</p>
            <Form className="price-container-category">
               <Form.Control
                  type="tel"
                  min="0"
                  value={minprice}
                  placeholder="min price"
                  id="filter-min-price"
                  onChange={(e) => {
                     if (e?.nativeEvent?.data) {
                        if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                           setMinprice(e.target?.value);
                        }
                        if (e?.nativeEvent?.data === null) {
                           setMinprice(e?.target?.value);
                        }
                     } else {
                        setMinprice(e?.target?.value);
                     }
                  }}
               />
               <Form.Control
                  type="tel"
                  placeholder="max price"
                  id="filter-min-price"
                  value={maxprice}
                  // onChange={(e) => {
                  //    // console.log("phone", isNaN(e.nativeEvent?.data));
                  //    !isNaN(e?.nativeEvent?.data) && setMaxprice(e.target.value);
                  // }}
                  onChange={(e) => {
                     if (e?.nativeEvent?.data) {
                        if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                           setMaxprice(e.target?.value);
                        }
                        if (e?.nativeEvent?.data === null) {
                           setMaxprice(e?.target?.value);
                        }
                     } else {
                        setMaxprice(e?.target?.value);
                     }
                  }}
               />
               <Button variant="secondarys" id="maxprice-filter" onClick={handlePriceFilter}>
                  Go
               </Button>
            </Form>
         </div>
         <div>
            <div>
               <p className="slider-label">Manufacturer</p>
               {brandlist ? (
                  <div style={{ marginTop: "20px" }}>
                     {Object.entries(brandlist).map(([key, value]) => {
                        return (
                           <div>
                              <input
                                 defaultChecked={querybrand === value._id}
                                 type="checkbox"
                                 id={value._id}
                                 name="manufacturer"
                                 value={value._id}
                                 onChange={(e) => {
                                    // add to list
                                    if (e.target.checked) {
                                       navigate("/store/category");
                                       setBrand([...brand, e.target.value]);
                                    } else {
                                       // remove from list
                                       setBrand(brand?.filter((item) => item !== value._id));
                                    }
                                 }}
                              />
                              <label
                                 id="category-label"
                                 for={value._id}
                                 style={{
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                 }}
                              >
                                 {value.name}
                              </label>
                           </div>
                        );
                     })}
                  </div>
               ) : (
                  <div style={{ marginTop: "20px" }}>No brands available</div>
               )}

               <p className="slider-label">Categories</p>
               {brandlist ? (
                  <div style={{ marginTop: "20px" }}>
                     {Object.entries(showcategory).map(([key, value]) => {
                        return (
                           <div>
                              <input
                                 defaultChecked={brandcategory === value._id}
                                 type="checkbox"
                                 // id="scales"
                                 id={value._id}
                                 name="categories"
                                 value={value._id}
                                 onChange={(e) => {
                                    // add to list
                                    if (e.target.checked) {
                                       // navigate("/store/category");
                                       setCategory([...category, e.target.value]);
                                    } else {
                                       // remove from list
                                       setCategory(category.filter((item) => item !== value._id));
                                    }
                                 }}
                              />
                              <label
                                 id="category-label"
                                 // for="scales"
                                 for={value._id}
                                 style={{
                                    marginLeft: "5px",
                                    cursor: "pointer",
                                 }}
                              >
                                 {value.title}
                              </label>
                           </div>
                        );
                     })}
                  </div>
               ) : (
                  <div style={{ marginTop: "20px" }}>No categories available</div>
               )}

               <div style={{ marginTop: "20px" }}>
                  <p className="slider-label">Color</p>
                  <div className="color-container">
                     {Object.entries(color).map(([key, value]) => {
                        return (
                           <div
                              id="color-filter"
                              style={{ backgroundColor: `${value.hex}` }}
                              className={
                                 brandcolor.includes(value._id) ? "activeColor_category" : ""
                              }
                              onClick={() => handleColor(value._id)}
                           >
                              {/* <p>{value.name}</p> */}
                           </div>
                        );
                     })}
                  </div>
               </div>
               <div style={{ marginTop: "20px" }}>
                  <ProductTag
                     productTag={productTag}
                     setProductTag={setProductTag}
                     brandtag={brandtag}
                     setBrandTag={setBrandTag}
                     handleProductTag={handleProductTag}
                  />

                  <div id="slider-container">
                     {/* {Object.entries(newarrivalsdata).map(([key, value]) => {
                           return (
                              <div id="tv-box">
                                 <div id="inside-tv">
                                    <a>
                                       {" "}
                                       <p
                                          onClick={() => handleTag(value.seo_keywords)}
                                          id="seo-word"
                                       >
                                          {value.seo_keywords}
                                       </p>
                                    </a>
                                 </div>
                              </div>
                           );
                        })} */}
                  </div>
               </div>
               <div style={{ marginTop: "20px" }}>
                  {/* <RangeSlider
                            value=""
                            onChange={(changeEvent) =>
                                setValue(
                                    changeEvent.target.value,
                                )
                            }
                        /> */}

                  <Sale
                     saleName={saleName}
                     setSaleName={setSaleName}
                     navigate={navigate}
                     saleItem={saleItem}
                     setSaleItem={setSaleItem}
                     saletype={saletype}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export function Sale({ saleItem, saletype, navigate, setSaleItem, saleName, setSaleName }) {
   return (
      <div style={{ marginTop: "20px" }}>
         <p className="slider-label">Sale</p>
         {saleItem ? (
            <div style={{ marginTop: "20px" }}>
               {Object.entries(saleItem).map(([key, value]) => {
                  return (
                     <div>
                        <input
                           defaultChecked={saletype === value._id}
                           type="checkbox"
                           id={value._id}
                           name="manufacturer"
                           value={value._id}
                           onChange={(e) => {
                              // add to list
                              if (e.target.checked) {
                                 // navigate("/store/category");
                                 setSaleName([...saleName, e.target.value]);
                              } else {
                                 // remove from list
                                 setSaleName(saleName?.filter((item) => item !== value._id));
                              }
                           }}
                        />
                        <label
                           id="category-label"
                           for={value._id}
                           style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                           }}
                        >
                           {value.name}
                        </label>
                     </div>
                  );
               })}
            </div>
         ) : (
            <div style={{ marginTop: "20px" }}>No sales available</div>
         )}
      </div>
   );
}
export function ProductTag({ productTag, brandtag, handleProductTag }) {
   return (
      <div style={{ marginTop: "20px" }}>
         <p className="slider-label">Product Tag</p>

         {productTag ? (
            <div className="producttag_wrapper" style={{ marginTop: "20px" }}>
               {Object.entries(productTag).map(([key, value]) => {
                  return (
                     <div
                        className={brandtag.includes(value._id) ? "activeTag_category" : ""}
                        onClick={() => handleProductTag(value._id)}
                     >
                        <p id="producttag_title">{value?.title}</p>
                     </div>
                  );
               })}
            </div>
         ) : (
            <div style={{ marginTop: "20px" }}>No tag available</div>
         )}
      </div>
   );
}
