import React, { useState, useEffect, useRef } from "react";
import HeroBanner from "../../../components/customcomponents/herobanner";
import Layout from "../../../components/include/layout";
import { get_tv_components, get_category_data } from "../../../data/api";
import { useNavigate, useParams } from "react-router-dom";
import Widthcontainer from "../../../components/customcomponents/widthcontainer";
import IndvPackage from "../../../components/customcomponents/indv_package";
import Loading from "../../../components/customcomponents/loading";
import "../../../scss/packages/category.scss";
import ReactPaginate from "react-paginate";
import Dropdown from "react-dropdown";
import SeoTags from "../../../utils/seoTags";
import Error from "../../../components/pages/404error";
import Feature from "../feature";
import FeatureSection from "./featureSection";
import { InternetBanner } from "../../../components/customcomponents/internet/internetpackage";
const Main = () => {
   const params = useParams();
   const { id, category } = params;
   //Variables
   const [tvData, setTvData] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [categoryData, setCategoryData] = useState("");
   const [allPackageData, setAllPackageData] = useState("");
   const [packageData, setPackageData] = useState("");
   const [pageCount, setPageCount] = useState("");
   const [sortOptions, setSortOptions] = useState("Sort by price(high to low)");
   //For Auto Scroll
   const categoryContainer = useRef(null);
   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 200);
   };
   //check catgeory
   const [checkCategory, setCheckCategory] = useState(false);

   useEffect(() => {
      if (
         category === "dishhome-dth" ||
         category === "4k-streamer" ||
         category === "itv" ||
         category === "dvb-t2"
      ) {
         setCheckCategory(false);
      } else {
         setCheckCategory(true);
      }
   }, [category]);
   useEffect(() => {
      async function fetchData() {
         const response = await get_tv_components();
         const res = await get_category_data(id);
         setCategoryData(res?.data?.data);
         setTvData(response.data);
      }
      fetchData();
   }, [empty, id, sortOptions]);
   useEffect(() => {
      setBannerData(tvData.banners);
      setAllPackageData(categoryData?.packages);
      setPageCount(Math.ceil(categoryData?.packages?.length / 6));
   }, [tvData, categoryData, sortOptions, id, empty]);

   useEffect(() => {
      if (allPackageData !== "") {
         if (sortOptions === "Sort by price(high to low)") {
            setAllPackageData(
               allPackageData?.sort((a, b) => b?.prices[0]?.price - a.prices[0]?.price),
            );
         }
         if (sortOptions === "Sort by price(low to high)") {
            setAllPackageData(
               allPackageData?.sort((a, b) => a.prices[0]?.price - b.prices[0]?.price),
            );
         }
      }
      setPackageData(allPackageData?.slice(0, 6));
   }, [sortOptions, empty, id, allPackageData]);

   //Sort Options
   const sortoptions = ["Sort by price(high to low)", "Sort by price(low to high)"];
   const sortingOption = sortoptions[0];

   //Sort the package data by price
   useEffect(() => {
      if (tvData === "" || categoryData === "" || packageData === "" || allPackageData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   }, [categoryData, tvData, packageData, allPackageData]);
   const handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * 6;
      const newArray = allPackageData.slice(offset, offset + 6);
      setPackageData(newArray);
      scrollDown(categoryContainer);
   };

   return empty ? (
      <Loading />
   ) : tvData === undefined || categoryData === undefined || categoryData === null ? (
      <Error />
   ) : (
      <Layout>
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === `tv_${category}`) {
               return <HeroBanner type={"indv_top"} data={value} muted={false} />;
            } else return null;
         })}
         {category === "dishhome-dth" && <FeatureSection category="dishhome-dth" />}
         {category === "4k-streamer" && <FeatureSection category="4k" />}
         {category === "itv" && <FeatureSection category="itv" />}
         {SeoTags(
            `${categoryData?.title} | DishHome Nepal`,
            categoryData?.seo_title,
            categoryData?.seo_description,
            categoryData?.seo_keywords,
         )}
         {category === "dvb-t2" && (
            <Widthcontainer>
               <div className="Category__wrapper fixed-width75">
                  <div className="Category__heading">
                     <img
                        alt="category icon"
                        className="category__img"
                        src={`${process.env.REACT_APP_FILE_URL}/${categoryData.image}`}
                     />
                     <h1 className={"category__title"}>{categoryData?.title}</h1>
                  </div>
                  {packageData?.length > 1 && (
                     <Dropdown
                        options={sortoptions}
                        onChange={(option) => {
                           setSortOptions(option.value);
                        }}
                        value={sortingOption}
                        placeholder="Select an option"
                     />
                  )}
                  {/* {console.log(packageData?.length)} */}
                  <div className="category_card_wrapper" ref={categoryContainer}>
                     {Object.entries(packageData).map(([key, value]) => {
                        return (
                           <>
                              <IndvPackage
                                 img={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                 package_name={value.title}
                                 description={value.description}
                                 link={"/tv/" + value._id}
                                 price={value.prices[0].price}
                                 duration={"Month"}
                                 feature0={
                                    "Installation Charge : Rs. " +
                                    value.prices[0].installation_charge.toLocaleString("hi-IN")
                                 }
                                 feature1={
                                    "Set Top Box: Rs. " +
                                    value.prices[0].settopbox_price.toLocaleString("hi-IN")
                                 }
                                 feature2={
                                    "No. of TV : Rs. " +
                                    value.prices[0].multiple.toLocaleString("hi-IN")
                                 }
                                 feature3={
                                    "Additional Charge : Rs. " +
                                    value.prices[0].additional.toLocaleString("hi-IN") +
                                    " per TV"
                                 }
                                 type={"type"}
                                 keys={key}
                              />
                           </>
                        );
                     })}
                  </div>
               </div>
            </Widthcontainer>
         )}

         {category === "dvb-t2" && <FeatureSection category={"dvb"} />}
         {category === "dvb-t2" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
               {pageCount > 1 && (
                  <ReactPaginate
                     previousLabel={"Previous"}
                     nextLabel={"Next"}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     marginPagesDisplayed={2}
                     pageRangeDisplayed={3}
                     onPageChange={handlePageClick}
                     containerClassName={"pagination dashboard_pagination"}
                     pageClassName={"page-item dashboard_page_item"}
                     pageLinkClassName={"page-link dashboard_page_link"}
                     previousClassName={"page-item"}
                     previousLinkClassName={"page-link"}
                     nextClassName={"page-item"}
                     nextLinkClassName={"page-link"}
                     breakClassName={"page-item"}
                     breakLinkClassName={"page-link"}
                     activeClassName={"active dashboard_active"}
                  />
               )}
            </div>
         )}
      </Layout>
   );
};

export default Main;
