import * as actionTypes from "./shopping-types";

const INITIAL_STATE = {
   products: [],
   cart: [],
   wishlist: [],
   currentItem: null,
};

const shopReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case actionTypes.SET_PRODUCTS:
         return {
            ...state,
            products: action.payload.products,
         };
      case actionTypes.SET_CART:
         return {
            ...state,
            products: action.payload.products,
         };
      case actionTypes.ADD_TO_CART:
         const item = state.products.find((product) => product._id === action.payload.id);

         // Check if Item is in cart already
         const inCart = state.cart.find((item) => (item._id === action.payload.id ? true : false));
         return {
            ...state,
            cart: inCart
               ? state.cart.map((item) =>
                    item._id === action.payload.id ? { ...item, qty: item.qty + 1 } : item,
                 )
               : [...state.cart, { ...item, qty: 1 }],
         };
      case actionTypes.ADD_TO_WISHLIST:
         const items = state.products.find((product) => product._id === action.payload.id);

         // Check if Item is in cart already
         const inWishlist = state.wishlist.find((items) =>
            items._id === action.payload.id ? true : false,
         );
         return {
            ...state,
            cart: inWishlist
               ? state.wishlist.map((item) =>
                    items._id === action.payload.id ? { ...items, qty: item.qty + 1 } : item,
                 )
               : [...state.cart, { ...items, qty: 1 }],
         };
      case actionTypes.REMOVE_FROM_CART:
         return {
            ...state,
            cart: state.cart.filter((item) => item._id !== action.payload.id),
         };
      case actionTypes.ADJUST_ITEM_QTY:
         return {
            ...state,
            cart: state.cart.map((item) =>
               item._id === action.payload.id ? { ...item, qty: +action.payload.qty } : item,
            ),
         };
      case actionTypes.LOAD_CURRENT_ITEM:
         return {};
      default:
         return state;
   }
};

export default shopReducer;
