import React, { useRef, useState } from "react";
import Widthcontainer from "../components/customcomponents/widthcontainer";
import Layout from "../components/include/layout";
import DashboardItems from "./dashboardItems";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import "../scss/dashboard/dashboardfeedback.scss";
import Token from "../utils/useToken";
import MessageModal from "../components/customcomponents/messageModal";
import { feedback } from "../data/protectedapi";
import Captcha from "../components/include/captcha";

export default function DashboardFeedback() {
   const { token } = Token();
   const UserDetails = token.user;
   const username = UserDetails.phone;
   const [name, setName] = useState(UserDetails.name);
   const [phone, setPhone] = useState(UserDetails.phone);
   const [email, setEmail] = useState(UserDetails.email);
   const [errorShow, setErrorShow] = useState(false);
   const [show, setShow] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const reRef = useRef();

   const schema = yup.object().shape({
      message: yup.string().required(),
   });
   const handleSubmit = async (values) => {
      const captchaToken = await reRef?.current?.executeAsync();
      const response = await feedback(
         UserDetails?.id,
         name,
         phone,
         email,
         values.message,
         captchaToken,
      );
      if (response.status === 201) {
         setShow(true);
      } else {
         setErrorShow(true);
         setErrorMessage(response?.response?.data?.message);
      }
   };

   return (
      <div>
         <MessageModal
            show={show}
            setShow={setShow}
            heading={"Feedback Submitted"}
            message={"Thank You For Your Feedback"}
            link={"/dashboard?type=6"}
            buttonText={"Ok"}
         />
         <MessageModal
            show={errorShow}
            setShow={setErrorShow}
            heading={"Error"}
            message={errorMessage}
            link={"/dashboard?type=6"}
            type="error"
            buttonText={"Ok"}
         />

         <h2 id="billings">Feedback</h2>
         <p id="billings-details">Do you have a suggestion? Let us know in the field below.</p>
         <Formik
            validationSchema={schema}
            initialValues={{
               message: "",
            }}
            onSubmit={(values, actions) => {
               handleSubmit(values);
               actions.resetForm();
            }}
         >
            {({ handleSubmit, handleChange, values, touched, errors, handleReset }) => (
               <Form id="dashboard-formwidth" onSubmit={(e) => handleSubmit(e)}>
                  <div style={{ marginTop: "20px" }}>
                     <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label id="dashboard-label">Describe your issues</Form.Label>

                        <Form.Control
                           as="textarea"
                           rows={3}
                           type="text"
                           name="message"
                           className="feedback-dashboard"
                           value={values.message}
                           onChange={handleChange}
                        />
                        {touched.message && errors.message && (
                           <p className="text-danger mb-0">Feedback is required</p>
                        )}
                     </Form.Group>
                  </div>
                  <Button variant="secondary" id="dashboardform-btn" type="submit">
                     Submit
                  </Button>
                  <Captcha reRef={reRef} />
               </Form>
            )}
         </Formik>
      </div>
   );
}
