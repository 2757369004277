import { React, useEffect, useState, useMemo } from "react";
import img from "../../assets/login.png";
import "../../scss/pages/form.scss";
import "../../scss/pages/forgotpassword.scss";
import { Modal } from "react-bootstrap";
// import Logo from "../include/logo";
import Layout from "../include/layout";
import { useParams } from "react-router";
import { useNavigate, useLocation } from "react-router-dom";
import { verify_otp, resend_otp, get_footer_banner } from "../../data/api";
import MessageModal from "../customcomponents/messageModal";
import Token from "../../utils/useToken";
import Widthcontainer from "../customcomponents/widthcontainer";
import axios from "axios";
import goLogo from "../../assets/gologo.webp";
import googleplay from "../../assets/googleplay.webp";
import apple from "../../assets/appstore.webp";
import tick from "../../assets/success.png";

const OtpVerify = () => {
   let navigate = useNavigate();
   const { number } = useParams();
   const { token } = Token();
   const [otp, setOtp] = useState("");
   const [show, setShow] = useState(false);
   const [resendotp, setResendotp] = useState(false);
   const [error, setError] = useState(false);
   const [message, setMessage] = useState("");
   const [heading, setHeading] = useState("");
   const [ottmodal, setOttmodal] = useState(false);
   const [ottInfo, setOttInfo] = useState("");
   //Query Hook
   function useQuery() {
      const { search } = useLocation();
      return useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let source = query.get("source");

   const verifyOtp = async (e) => {
      e.preventDefault();
      const response = await verify_otp(number, otp);
      // console.log("response", response);
      if (response.status === 201) {
         if (source === "dishhomego") {
            //get _go from localstorage
            const _go = localStorage.getItem("_go");
            //axios call to get ott details with bearer token in header and with number as query param
            await axios
               .post(
                  `${process.env.REACT_APP_BASE_URL}v1/ott/register-ott-user`,
                  {
                     name: response?.data?.user?.name,
                     ottPassword: _go,
                     email: response?.data?.user?.email,
                     phone: response?.data?.user?.phone,
                  },
                  {
                     headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${response?.data?.token?.accessToken}`,
                        Token: process.env.REACT_APP_API_SECRET_TOKEN,
                     },
                  },
               )
               .then((res) => {
                  // console.log("res", res);
                  if (res?.data?.responseCode === 200) {
                     setOttInfo(res?.data?.ottCustomerCreate);
                     //clear _go from localstorage
                     localStorage.removeItem("_go");
                     setOttmodal(true);
                  } else {
                     setError(true);
                     setMessage(res?.data?.responseDescription);
                  }
               })
               .catch((err) => {
                  console.log("err", err);
                  setError(true);
                  setMessage(err?.response?.data?.responseDescription);
               });
         } else setShow(true);
      } else {
         setError(true);
         setMessage("Please enter the correct verification code sent to your phone.");
         setHeading(response?.response?.data?.message);
         // alert(response?.data.message);
      }
   };
   const resendOtp = async () => {
      const response = await resend_otp(number);
      if (response.status==200) {
         setResendotp(true);
      } else {
         setError(true);
         console.log(response)
         setMessage(response?.response?.data?.message);
      }
   };
   useEffect(() => {
      if (token) {
         navigate("/");
      }
   }, []);

   const [isEmpty, setisEmpty] = useState(true);
   const [footerData, setfooterData] = useState("");
   const [ottlink, setOttlink] = useState("");

   useEffect(() => {
      async function getData() {
         const response = await get_footer_banner();
         setfooterData(response?.data);
      }
      getData();
   }, [isEmpty]);

   useEffect(() => {
      if (footerData === "" || footerData === undefined) {
         setisEmpty(true);
      } else {
         setisEmpty(false);
         setOttlink(footerData?.ott_link);
      }
   }, [footerData]);
   return (
      <Layout>
         <Modal
            centered
            onHide={() => {
               navigate("/");
               setOttmodal(false);
            }}
            show={ottmodal}
            animation={false}
            id="deletecart-modal"
         >
            <Modal.Header closeButton id="modal-header"></Modal.Header>
            <Modal.Body id="modal-body ">
               <div className="d-flex flex-column align-items-center mb-2">
                  <img src={tick} alt="" height={65} width={65} className="mb-3" />
                  <p className="cartdelete-title mb-3 text-center">{ottInfo?.remarks}</p>
                  {/* <p className="mt-1 mb-1 fs-6 fw-normal">
                     <span className="fw-bolder"> Username: </span> {ottInfo.ottUserId}
                  </p> */}
                  {/* <p className="mb-3 fs-6 fw-normal">
                     <span className="fw-bolder"> Password: </span> {ottInfo.password}
                  </p> */}

                  <div className="app_div mt-2 d-flex align-items-center" style={{ gap: "6px" }}>
                     <img src={goLogo} alt="" className="dishhomego_logo" height={64} />
                     <img
                        src={googleplay}
                        style={{ cursor: "pointer" }}
                        height="45px"
                        width="130px"
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.google_play_go, "_blank");
                        }}
                     />
                     <img
                        src={apple}
                        height="45px"
                        width="130px"
                        style={{ marginLeft: "10px" }}
                        alt=""
                        onClick={() => {
                           window.open(ottlink?.app_store_go, "_blank");
                        }}
                     />
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         <MessageModal
            show={show}
            setShow={setShow}
            heading={"Verification Successful"}
            message={"Your phone has been verified successfully. Thank you for using DishHome."}
            link={"/login"}
            buttonText={"Ok"}
            isNavigate={true}
            replace={true}
         />
         <MessageModal
            show={resendotp}
            setShow={setResendotp}
            heading={"Verification Code"}
            message={"Verification code  has been sent to your number"}
            link={`/otp-verify/${number}${source ? `?source=${source}` : ""}`}
            buttonText={"Ok"}
         />
         <MessageModal
            show={error}
            setShow={setError}
            heading={"Verification Failed"}
            message={message}
            type={"error"}
            buttonText={"Ok"}
         />
         <Widthcontainer>
            <div className={"ForgotPassword_Wrapper fixed-width mt-2"}>
               <div className={"ForgotPassword_Form"}>
                  {/* <Logo /> */}
                  <div className={"ForgotPassword_Container"}>
                     <h1 className={"ForgotPassword_Header"}>Verify OTP</h1>
                     <h3 className={"ForgotPassword_Text"}>
                        Please enter the verification code sent to your registered mobile number to
                        complete registration
                     </h3>
                     <label htmlFor="phone">OTP</label>
                     <input
                        type="number"
                        name="phone"
                        className={"Phone_Input"}
                        placeholder=""
                        onChange={(e) => setOtp(e.target.value)}
                     />{" "}
                     <button className={"ForgotPassword_Button"} onClick={verifyOtp}>
                        Verify
                     </button>
                     <h3 className={"ForgotPassword_Text"}>
                        Didn't get OTP ?
                        <a style={{ cursor: "pointer" }} onClick={resendOtp}>
                           {" "}
                           Resend{" "}
                        </a>
                     </h3>
                  </div>
               </div>
               <div className={"Forgot_Image"}>
                  <img src={img} alt="" />
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export default OtpVerify;
