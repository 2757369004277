import React, { useState, useEffect, useRef } from "react";
import { get_completed_orders, get_customer_ppv } from "../data/protectedapi";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import Token from "../utils/useToken";

export default function PpvTransactions() {
   const [order, setOrder] = useState("");
   const [pageCount, setPageCount] = useState("");
   const [selectedPage, setSelectedPage] = useState(0);
   const categoryContainer = useRef(null);

   const { userDetails } = Token();

   const scrollDown = (ref) => {
      window.scrollTo(0, ref.current.offsetTop - 200);
   };

   const handlePageClick = (e) => {
      setSelectedPage(e.selected + 1);
      scrollDown(categoryContainer);
   };
   useEffect(() => {
      async function get_all_orders() {
         const res = await get_customer_ppv(selectedPage);
         const response = res?.data?.data;
         setOrder(response);
         // setOrder(response?.slice(offset, offset + 6));
         //  setPageCount(res?.data?.paging?.pages);
      }
      get_all_orders();
   }, []);
   // console.log(order);
   return userDetails?.is_kyc_verified === false ? (
      <h6 style={{ color: "darkgrey" }}>Please Complete your profile to get billing history</h6>
   ) : (
      <div style={{ width: "100%" }} className="dashboardBilling_Wrapper">
         <div className="billing-table">
            <div>
               <p id="bill-header">Bill Type</p>
            </div>
            <div>
               <p id="bill-header">Bill Date</p>
            </div>
            <div>
               <p id="bill-header">Amount</p>
            </div>
         </div>

         {order &&
            Object.entries(order).map(([key, value]) => {
               // console.log(value);
               return (
                  value?.is_paid === true && (
                     <TransactionData
                        title={value?.payment_method}
                        date={value?.createdAt}
                        amount={value?.total}
                        status={value?.is_paid}
                     />
                  )
               );
            })}
         {/* {pageCount > 1 && (
            <ReactPaginate
               previousLabel={"Previous"}
               nextLabel={"Next"}
               breakLabel={"..."}
               pageCount={pageCount}
               marginPagesDisplayed={2}
               pageRangeDisplayed={3}
               onPageChange={handlePageClick}
               containerClassName={"pagination dashboard_pagination"}
               pageClassName={"page-item dashboard_page_item"}
               pageLinkClassName={"page-link dashboard_page_link"}
               previousClassName={"page-item"}
               previousLinkClassName={"page-link"}
               nextClassName={"page-item"}
               nextLinkClassName={"page-link"}
               breakClassName={"page-item"}
               breakLinkClassName={"page-link"}
               activeClassName={"active dashboard_active"}
            />
         )} */}
         {order?.length === 0 && (
            <div className="dashboardNotification_empty">
               <p className="dashboardNotification_empty_description">No transaction history</p>
            </div>
         )}
      </div>
   );
}
export const TransactionData = ({ title, date, amount, status }) => {
   return (
      <div className="billing-features">
         <div>
            <p>{title}</p>
         </div>
         <div>
            <p> {format(new Date(date), "dd LLL, yyyy")}</p>
         </div>
         <div>
            <p>Rs. {amount.toLocaleString("hi-IN")}</p>
         </div>
      </div>
   );
};
