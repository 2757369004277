import axios from "axios";
// import Token from "../utils/useToken";

export const base = () => {
   const flag = localStorage?.getItem("remember");
   let tokenString;
   if (flag === "true") {
      tokenString = localStorage.getItem("user");
   } else {
      tokenString = localStorage.getItem("user");
   }
   const userToken = JSON.parse(tokenString);
   const access = userToken?.accessToken;
   const data = {
      baseURL: process.env.REACT_APP_BASE_URL + "v1",
      headers: {
         "content-type": "application/json",
         Authorization: `Bearer ${access}`,
         Token: process.env.REACT_APP_API_SECRET_TOKEN,
      },
   };
   return axios.create(data);
};

//get cart items
export const get_cart_items = async () => {
   return await base()
      .get("/shop/cart")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Delete Cart Item

export const delete_cart_items = async (id) => {
   return await base()
      .delete("/shop/cart/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//move all wishlist
export const move_all_wishlist_items = async (id) => {
   return await base()
      .get("/shop/wishlist/move")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//delete wishlist
export const delete_wishlist_items = async (id) => {
   return await base()
      .delete("/shop/wishlist/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//Update Cart
export const update_cart_items = async (id, qty, color) => {
   return await base()
      .patch(
         "shop/cart/" + id,
         JSON.stringify({
            quantity: qty,
            color: color,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//add to wishlist
export const update_wishlist_items = async (id) => {
   return await base()
      .post("shop/wishlist/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//wishlist cart
export const update_wishlist_cart = async (id) => {
   return await base()
      .patch("shop/wishlist/" + id + "/move")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//upload document
export const upload_document = async (document, name) => {
   const formdata = new FormData();
   formdata.append("documents", document);

   return await base()
      .patch(`users/upload-document?name=${name}`, formdata)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//get all wishlist
export const get_wishlist_items = async () => {
   return await base()
      .get("/shop/wishlist")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//get orders
export const get_orders = async (page) => {
   return await base()
      .get(`shop/order/my-orders?page=${page}&limit=7`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

// get OTT Order
export const get_ott_orders = async (page) => {
   return await base()
      .get(`ott-order/get?page=${page}&limit=7`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Make OTT Order Payment

export const make_ott_order_payment = async (id, payment_method, voucher_pin) => {
   return await base()
      .post(`ott-order/make-payment`, {
         order_id: id,
         payment_method: payment_method,
         voucher_pin: voucher_pin,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get completed order
export const get_completed_orders = async (page) => {
   return await base()
      .get(`shop/order/my-orders?page=${page}&limit=20&status=completed`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get package order
export const get_package_orders = async (page) => {
   return await base()
      .get(`package/order/user-billing-info?page=${page}&limit=10`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get package order
export const get_completed_package_orders = async (page) => {
   return await base()
      .get(`package/order/user-billing-info?page=${page}&limit=20&status=completed`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get ott order
export const get_completed_ott_orders = async (page) => {
   return await base()
      .get(`ott-order/user-billing-info?page=${page}&limit=20&status=completed`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//Increase Cart

export const increase_cart_items = async (id, qty) => {
   return await base()
      .patch(
         "shop/cart/" + id,
         JSON.stringify({
            quantity: qty,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//increase product in cart page
export const increase_cart_page = async (id, qty) => {
   return await base()
      .post("shop/cart/" + id + "/increase")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//increase product in cart page
export const decrease_cart_page = async (id, qty) => {
   return await base()
      .post("shop/cart/" + id + "/decrease")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Decrease Cart Item
export const decrease_cart_items = async (id, qty) => {
   return await base()
      .patch(
         "shop/cart/" + id,
         JSON.stringify({
            quantity: qty,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Apply voucher

export const apply_voucher = async (subTotal, voucher) => {
   return await base()
      .post(
         "/shop/checkout/apply-voucher",
         JSON.stringify({ subTotal: subTotal, voucher: voucher }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get shipping address

export const get_shipping_address = async () => {
   return await base()
      .get("shop/checkout/address")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Add shipping address

export const add_shipping_address = async (
   shipping_name,
   shipping_address,
   shipping_street,
   shipping_landmark,
   shipping_district,
   shipping_province,
) => {
   return await base()
      .post(
         "/shop/checkout/address/add",
         JSON.stringify({
            shipping_name: shipping_name,
            shipping_address: shipping_address,
            shipping_street: shipping_street,
            shipping_landmark: shipping_landmark,
            shipping_district: shipping_district,
            shipping_province: shipping_province,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Delete Shipping Address

export const delete_shipping_address = async (id) => {
   return await base()
      .delete("/shop/checkout/address/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Edit Shipping Address

export const edit_shipping_address = async (
   id,
   shipping_name,
   shipping_address,
   shipping_street,
   shipping_landmark,
   shipping_district,
   shipping_province,
) => {
   return await base()
      .patch(
         "/shop/checkout/address/" + id,
         JSON.stringify({
            shipping_name: shipping_name,
            shipping_address: shipping_address,
            shipping_street: shipping_street,
            shipping_landmark: shipping_landmark,
            shipping_district: shipping_district,
            shipping_province: shipping_province,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//change Password

export const change_password = async (old_pw, new_pw, conf_pw, recaptcha) => {
   return await base()
      .post(
         "/users/change-password",
         JSON.stringify({
            current_password: old_pw,
            password: new_pw,
            confirm_password: conf_pw,
            recaptcha: recaptcha,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};
//otp for set password
export const otp_set_password = async (phone) => {
   return await base()
      .get("users/set-password/request-otp")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//verify otp users
export const verify_otp_users = async (otp) => {
   return await base()
      .put("users/set-password/verify-otp", JSON.stringify({ otp: parseInt(otp) }))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//change OTP user Password

export const change_otp_userpassword = async (token, new_pw, conf_pw) => {
   return await base()
      .put(
         "/users/set-password",
         JSON.stringify({
            resetToken: token,
            password: new_pw,
            confirm_password: conf_pw,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

//order Tv Package
export const order_tv_package = async (
   id,
   packagetitle,
   name,
   phone,
   email,
   recharge,
   tvnumber,
   userDistrict,
   userProvince,
   position,
   userMunicipality,
   address,
   wardNo,
   discountamount,
   sub_total,
   total,
   price,
   redeem,
   vat,
   vouchername,
   voucherid,
   installationCharge,
   settopbox_charge,
   payment_method,
   additional,
   duration,
) => {
   return await base()
      .post(
         "/package/order/order-package",
         JSON.stringify({
            package_name: packagetitle,
            package_id: id,
            full_name: name,
            email: email,
            phone: phone.toString(),
            recharge_amount: parseInt(recharge),
            no_of_tv: tvnumber,
            billing_address: {
               name: name,
               address: address,
               street: userMunicipality,
               landmark: "landmark",
               district: userDistrict,
               province: userProvince,
               coordinates: position,
            },
            shipping_address: {
               name: name,
               address: address,
               street: userMunicipality,
               landmark: "landmark",
               district: userDistrict,
               province: userProvince,
               coordinates: position,
            },
            voucher: {
               name: vouchername,
               voucherId: voucherid,
            },
            payment_type: duration,
            package_type: "TV",
            sub_total: sub_total,
            total: total,
            discount: discountamount,
            price: price,
            reward: redeem,
            vat: vat,
            installation_charge: installationCharge,
            settopbox_price: settopbox_charge,
            additional: (tvnumber - 1) * additional,
            payment_method: payment_method,
            status: "new",
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

//order Internet Package
export const order_internet_package = async (
   id,
   packagetitle,
   name,
   phone,
   rechage,
   tvnumber,
   userDistrict,
   userProvince,
   userMunicipality,
   address,
   wardNo,
   price,
   installationCharge,
   dropWire,
   routerCharge,
   settopbox_charge,
   payment_method,
   additional,
) => {
   return await base()
      .post(
         "/package/order/order-package",
         JSON.stringify({
            package_name: packagetitle,
            package_id: id,
            name: name,
            email: "aa@gmail.com",
            phone: phone.toString(),
            recharge_amount: parseInt(recharge),
            no_of_tv: tvnumber,
            billing_address: {
               name: name,
               address: address,
               street: userMunicipality,
               landmark: "landmark",
               district: userDistrict,
               province: userProvince,
            },
            shipping_address: {
               name: name,
               address: address,
               street: userMunicipality,
               landmark: "landmark",
               district: userDistrict,
               province: userProvince,
            },
            payment_type: payment_method,
            package_type: "Internet",
            price: price,
            installation_charge: installationCharge,
            drop_wire: dropWire,
            router: routerCharge,
            settopbox_price: settopbox_charge,
            additional: (tvnumber - 1) * additional,
            total: price + installationCharge + settopbox_charge + 100,
            payment_method: payment_method,
            status: "new",
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

//check footprint
export const check_footprint = async (
   id,
   packagetitle,
   name,
   email,
   phone,
   recharge,
   tvnumber,
   // userDistrict,
   billingname,
   billingaddress,
   userProvince,
   street,
   userLandmark,
   address,
   price,
   installationCharge,
   total,
   settopbox_charge,
   payment_method,
   additional,
   position,
   package_type,
) => {
   return await base()
      .post(
         "/unavailable-orders/check-footprint",
         JSON.stringify({
            package_name: packagetitle,
            package_id: id,
            full_name: name,
            email: email,
            phone: phone.toString(),
            recharge_amount: parseInt(recharge),
            no_of_tv: tvnumber,
            billing_address: {
               name: name,
               address: billingaddress,
               street: street,
               landmark: userLandmark,
               district: address,
               province: userProvince,
               coordinates: position,
            },
            shipping_address: {
               name: billingname,
               address: billingaddress,
               street: street,
               landmark: "landmark",
               district: address,
               province: userProvince,
               coordinates: position,
            },

            payment_type: payment_method,
            package_type: package_type,
            price: price,
            installation_charge: installationCharge,
            settopbox_price: settopbox_charge,
            additional: (tvnumber - 1) * additional,
            total: total,
            payment_method: "esewa",
            is_paid: "true",
            paid_at: "2022-05-28",
            status: "new",
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

//order PPV movies

export const order_ppv_movies = async (
   ppv_id,
   movie_name,
   price,
   showtime,
   showtime_id,
   total,
   payment_method,
   customerid,
   stbid,
   coupon,
) => {
   return await base()
      .post(
         "/ppv-order/new-create",
         JSON.stringify({
            ppv_id: ppv_id,
            movie_name: movie_name,
            price: price,
            showtime: showtime,
            showtime_id: showtime_id,
            total: total,
            payment_method: payment_method,
            coupon: coupon,
            customerId: customerid,
            stbNumber: stbid,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

export const updateprofile = async (profile_image, name, email, secondary_phone, phone) => {
   const formdata = new FormData();
   formdata.append("profile_image", profile_image);
   formdata.append("name", name);
   formdata.append("email", email);
   formdata.append("secondary_phone", secondary_phone);
   formdata.append("phone", phone);
   return await base()
      .post("/users/update-user-basic-info", formdata)
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

export const updatekyc = async (
   customer_id,
   billing_name,
   billing_address,
   billing_street,
   billing_landmark,
   billing_district,
   billing_province,
   shipping_name,
   shipping_address,
   shipping_street,
   shipping_landmark,
   shipping_district,
   shipping_province,
   shipping_id,
   recaptcha,
) => {
   return await base()
      .post(
         "/users/update-kyc-info",
         JSON.stringify({
            customer_id: customer_id,
            billing_name: billing_name,
            billing_address: billing_address,
            billing_street: billing_street,
            billing_landmark: billing_landmark,
            billing_district: billing_district,
            billing_province: billing_province,
            shipping_name: shipping_name,
            shipping_address: shipping_address,
            shipping_street: shipping_street,
            shipping_landmark: shipping_landmark,
            shipping_district: shipping_district,
            shipping_province: shipping_province,
            shipping_id: shipping_id,
            same_as_billing: true,
            recaptcha: recaptcha,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
   // console.log(
   //     user_id,
   //     ppv_id,
   //     movie_name,
   //     full_name,
   //     email,
   //     phone,
   //     price,
   //     showtime,
   //     showtime_id,
   //     total,
   //     payment_method,
   //     is_paid,
   // );
};

//get customer or otp id for kyc
export const get_customer_id = async (customer_id) => {
   return await base()
      .post(
         "/users/get-customer-id",
         JSON.stringify({
            customer_id: customer_id,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};
export const verify_otp = async (otp, customer_id) => {
   return await base()
      .post(
         "/users/verify-otp",
         JSON.stringify({
            otp: parseInt(otp),
            customer_id: customer_id,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         // return error;
         return error;
      });
};
//verify OTP
//recharge pin
export const pin = async (customer_id, pin, type) => {
   return await base()
      .post(
         `/recharge/recharge/pin${type === "other" ? "/other" : "/self"}`,
         JSON.stringify({
            customer_id: customer_id,
            pin: pin,
         }),
         // {
         //    params: {
         //       type: type,
         //    },
         // },
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//Recharge
export const recharge = async ({
   // recharge_id,
   recharge_amount,
   customer_id,
   bonus_amount,
   payment_method,
   type,
   voucherid,
   vouchercode,
   discount,
   IsRegular,
   DurationType,
}) => {
   return await base()
      .post(
         `/recharge/new-recharge/online${type === "other" ? "/other" : "/self"}`,
         JSON.stringify({
            // recharge_id: recharge_id,
            recharge_amount: recharge_amount,
            customer_id: customer_id.toString(),
            bonus_amount: bonus_amount,
            payment_method: payment_method,
            transaction_id: "12345",
            voucher: {
               name: vouchercode,
               voucherId: voucherid,
            },
            discount_amount: discount,
            IsRegular: IsRegular,
            DurationType: DurationType,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//recharge reward
export const reward_recharge = async (
   recharge_amount,
   customer_id,
   bonus_amount,
   IsRegular,
   DurationType,
   type,
) => {
   return await base()
      .post(
         `/recharge/new-recharge/reward${type === "other" ? "/other" : "/self"}`,

         JSON.stringify({
            recharge_amount: recharge_amount,
            customer_id: customer_id.toString(),
            bonus_amount: bonus_amount,
            IsRegular: IsRegular,
            DurationType: DurationType,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//feedback
export const feedback = async (userId, name, phone, email, message, recaptcha) => {
   return await base()
      .post("/feedback", {
         userId: userId,
         name: name,
         phone: parseInt(phone, 10),
         email: email,
         message: message,
         recaptcha: recaptcha,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
export const get_customer_ppv = async () => {
   return await base()
      .get("/ppv-order/get")
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

export const refferal = async (name, phone, email, location, packageType, position, recaptcha) => {
   return await base()
      .post(
         "/referal/refer",
         JSON.stringify({
            full_name: name,
            email: email,
            phone_number: phone,
            location: location?.LongLabel,
            package: packageType,
            packageType: "Internet",
            coordinates: position,
            recaptcha: recaptcha,
         }),
      )
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};

//locate dealer
export const locate_dealer = async (name, address, type, page) => {
   return await base().get(
      `agent/get?&page=${page}&limit=5&name=${name}&phone=&address=${address}&type=${type}`,
   );
};

//check balance
export const check_balance = async () => {
   return await base()
      .post("/users/user-balance")
      .then(function (res) {
         return res;
      })
      .catch(function (error) {
         return error;
      });
};
//referals
export const get_referals = async () => {
   return await base()
      .get("referal/get-referred-list")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_initial_notifiaciton = async () => {
   return await base()
      .get("notification/get-my-initial")
      .then(async function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
export const get_all_notifiaciton = async () => {
   return await base()
      .get("notification/user-get-all")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const mark_notifiaciton_read = async (id) => {
   if (id) {
      return await base()
         .patch(`notification/mark/${id}`)
         .then(function (response) {
            return response;
         })
         .catch(function (error) {
            return error;
         });
   } else {
      return await base()
         .patch("notification/mark-read")
         .then(function (response) {
            return response;
         })
         .catch(function (error) {
            return error;
         });
   }
};

//remove document
export const remove_document = async (name) => {
   return await base().delete(`users/delete-document?document_name=${name}`);
};

//post support
export const post_support = async (
   name,
   email,
   customer_id,
   message,
   category,
   sub_category,
   recaptcha,
) => {
   return await base()
      .post(
         `support/create-support?category_id=${category}&sub_category_id=${sub_category}`,
         JSON.stringify({
            name: name,
            email: email,
            customer_id: customer_id,
            message: message,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//check enterprise user
export const check_enterprise_user = async () => {
   return await base()
      .get("/support/enterprise-user-validation")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//post Enterprise support
export const post_enterprise_support = async ({
   companyName,
   email,
   customerId,
   issueTopic,
   appointmentDate,
   desc,
   POD,
   circuitId,
}) => {
   // console.log("post_enterprise_support", {
   //    companyName,
   //    customerId,
   //    issueTopic,
   //    appointmentDate,
   //    desc,
   // });
   return await base()
      .post(
         `support/create-enterprise-support`,
         JSON.stringify({
            companyName: companyName,
            email: email,
            customerId: customerId,
            issueTopic: issueTopic,
            appointmentDate: appointmentDate,
            description: desc,
            POD: POD,
            circuitId: circuitId,
            source: "mobile",
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get transation history for user
export const get_transaction_history = async () => {
   return await base()
      .get(`/package/order/dmn-transaction`)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         return err;
      });
};

//credit extension
export const get_credit_extension = async () => {
   return await base()
      .get("/users/credit-extension")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//upgrade package
export const upgrade_package = async (type) => {
   return await base()
      .get(`/package/new-package-options?type=${type}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//change package
export const change_package = async (value, id) => {
   return await base()
      .post(`/package/new-package-change`, {
         package_name: value,
         categoryId: id,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//users profile
export const update_profile = async () => {
   return await base()
      .get(`/users/profile`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//change primary
export const change_primary_number = async (number, password) => {
   return await base()
      .patch(`/users/change-primary-phone`, {
         phone: number,
         password: password,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//VERIFY PRIMARY NUMBER
export const verify_primary_number = async (number, otp) => {
   return await base()
      .patch(`/users/verify-change-phone`, {
         phone: parseInt(number),
         otp: parseInt(otp),
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get reedem point

export const get_redeem_point = async (sub_total) => {
   return await base()
      .post(`/shop/checkout/redeem-reward`, {
         sub_total: sub_total,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//send voucher
export const get_voucher_discount = async (subTotal, voucher, module, modules) => {
   return await base()
      .post(`/shop/checkout/apply-voucher`, {
         subTotal: subTotal,
         voucher: voucher,
         module: module,
         modules: modules,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const post_review = async (orderId, productId, comment, rating, recaptcha) => {
   return await base()
      .post(
         `shop/review/order/${orderId}/product/${productId}`,
         JSON.stringify({
            comment: comment,
            rating: rating,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//BONUS LIST
export const get_bonus_list = async () => {
   return await base()
      .get("/bonus")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_recharge_list = async ({ rechargeType, customer_id, type }) => {
   return await base()
      .get(
         `/recharge/list-recharge-amount?rechargeType=${rechargeType}&customer_id=${customer_id}&type=${type}`,
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//recharge using reward
export const get_reward_recharge = async () => {
   return await base()
      .get("/bonus?type=reward")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
export const get_support_list = async (enterprise_user) => {
   return await base()
      .get(`/support/user-support?enterprise_user=${enterprise_user}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const purchase_iptv_movie = async (data) => {
   return await base()
      .post("/dh-itv/order", data)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_active_iptv_movies = async (macAddress) => {
   return await base()
      .get(`/dh-itv/active-movies`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_iptv_order_history = async (selectedPage) => {
   return await base()
      .get(`/dh-itv/user-order?limit=10&page=${selectedPage}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//check minimum balance
export const check_min_balance = async () => {
   return await base()
      .get("/reward-setting/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//GET VOUCHER

export const get_discount_voucher = async () => {
   return await base()
      .get("/shop/voucher")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get Ott
export const get_ott = async (customer_id, password) => {
   return await base()
      .post(`ott/request-ott/` + customer_id, {
         ottPassword: password,
      })
      // .get(`ott/request-ott/5558364`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//renew ott
export const renew_ott = async (customer_id) => {
   return await base()
      .post(`ott/renew/${customer_id}`)
      // .post(`ott/renew/5558364`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//change ott password
export const change_ott_password = async (customerId, password, recaptcha) => {
   return await base()
      .post(`ott/change-password`, {
         customerId: customerId,
         password: password,
         recaptcha: recaptcha,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Check if user exists and has subscribed to any package
export const check_user_ott = async () => {
   return await base()
      .post(`ott-order/check-user`, {
         type: "addon",
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Order OTT
export const order_ott = async (paymentmethod, product_id, price_id, total, addons, type) => {
   return await base()
      .post(`ott-order`, {
         payment_method: paymentmethod,
         price_id: price_id,
         product_id: product_id,
         total: total,
         addons: addons,
         type: type,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get stb number

export const get_stb_number = async (id) => {
   return await base()
      .post("/ppv-order/get-stb-number", JSON.stringify({ customerId: id }))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//cancle Order

export const cancleOrder = async (type, orderId) => {
   return await base()
      .put(`/shop/order/cancel-order/${type}/${orderId}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const getDishhomeGoOrder = async () => {
   return await base()
      .get(`/ott-order/get-user-details?phone=9818719493`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
