import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { get_welcome_page } from "../data/api";
import "../scss/components/welcomemodal.scss";
import { useNavigate } from "react-router-dom";
import { LaptopWindows } from "@material-ui/icons";
import { AiOutlineClose } from "react-icons/ai";

export default function WelcomeModal() {
   let navigate = useNavigate();
   const [show, setShow] = useState(true);
   const [welcomedata, setWelcomedata] = useState("");
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   useEffect(() => {
      async function get_welcome() {
         const res = await get_welcome_page();
         const response = res?.data?.data;
         setWelcomedata(response);
      }
      get_welcome();
   }, []);
   return welcomedata ? (
      <div>
         <Modal show={show} onHide={handleClose} id="modal-body-welcome" size="lg" centered>
            {/* <Modal.Header closeButton id="modal-header">
                           <Modal.Title>{value.title}</Modal.Title>
                        </Modal.Header> */}
            <Modal.Body id="welcome-body">
               <p
                  style={{
                     fontSize: "24px",
                     position: "absolute",
                     right: "10px",
                     top: "-40px",
                     cursor: "pointer",
                     margin: "0px",
                     color: "#FFF",
                     fontWeight: "900",
                  }}
                  onClick={handleClose}
               >
                  <AiOutlineClose />
               </p>
               <img
                  className="img-fluid"
                  src={`${process.env.REACT_APP_FILE_URL}/${welcomedata?.image}`}
                  alt="feature"
                  onClick={() => {
                     window.open(welcomedata?.external_link, "_blank");
                  }}
                  width="100%"
                  style={{ cursor: "pointer" }}
               />
            </Modal.Body>
         </Modal>
      </div>
   ) : null;
}
