import React, { useEffect, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import Loading from "../../components/customcomponents/loading";
import CurrentMovieCard from "./currentmoviecard";
import "../../scss/ppvmovies/currentmovies.scss";
import { get_all_ppv_movies } from "../../data/api";
import movie from "../../assets/ppvmovies/movie.png";
import ReactLoading from "react-loading";

import Cookies from "js-cookie";

const CurrentMovies = (props) => {
   const [movies, setMovies] = useState("");
   const [loading, setLoading] = useState(true);
   let count = 0;

   useEffect(() => {
      async function get_movies() {
         const response = await get_all_ppv_movies();
         setMovies(response?.data?.data);
      }
      get_movies();
   }, []);

   useEffect(() => {
      if (movies === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });

   return (
      <div className="currentmovies_wrapper py-5">
         <div className="currentmovies_text mb-5 ms-2">
            <h2>{props.heading}</h2>
            {/* <ButtonGroup>
                    <Button className="date_button active">Today</Button>
                    <Button className="date_button">Today</Button>
                    <Button className="date_button">Today</Button>
                </ButtonGroup> */}
         </div>
         {loading ? (
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "450px",
                  marginTop: "80px",
                  marginBottom: "80px",
               }}
            >
               <ReactLoading type="spin" color="#ED1C24" height={205} width={205} />
            </div>
         ) : (
            <div className="currentmovies_moviecontainer">
               {movies &&
                  Object.entries(movies).map(([key, value]) => {
                     let showtimecount = 0;
                     // console.log("current Movies", value?.showtimes);
                     if (value.type === "Now Showing") {
                        value?.showtimes?.map((showtime) => {
                           if (JSON.stringify(showtime?.shows) !== "{}") {
                              count++;
                              showtimecount++;
                           }
                           return null;
                        });
                        return (
                           showtimecount > 0 && (
                              <CurrentMovieCard
                                 title={value.title}
                                 image={`${process.env.REACT_APP_FILE_URL}/${value.thumbnail}`}
                                 trailer_link={value.trailer_link}
                                 id={value._id}
                                 showtime={value.showtimes[0].shows}
                              />
                           )
                        );
                     } else return null;
                  })}
               {count === 0 && (
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: " 20px",
                        lineHeight: " 28px",
                        color: "#666",
                     }}
                  >
                     No movies are currently showing. Please check back later.
                  </p>
               )}
            </div>
         )}
      </div>
   );
};

export default CurrentMovies;
