import { Button, FormControl, FormLabel, InputGroup, NavLink } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../../scss/pages/cart.scss";
import addlocation from "../../assets/cart/addlocation.png";
import tick from "../../assets/success.png";
import { provinces, districts } from "../../data/location/province-district";
import { handleOnlinePayment } from "../../components/pages/payment/payment";

import Modal from "react-bootstrap/Modal";

import KhaltiCheckout, { required } from "khalti-checkout-web";
import { Alert } from "react-bootstrap";
import { MdError } from "react-icons/md";
import Token from "../../utils/useToken";
import MessageModal from "../../components/customcomponents/messageModal";
import Form from "react-bootstrap/Form";
import deleteicon from "../../assets/cart/delete.png";

import khalti from "../../assets/cart/khalti.png";
import esewa from "../../assets/store/esewa.webp";
import ips from "../../assets/store/ips.png";
import prabhu from "../../assets/store/prabhu.png";
import ime from "../../assets/store/ime.png";
import Dropdown from "react-dropdown";
import { get_company_details, get_shipping_charge } from "../../data/api";
import cartdelete from "../../assets/cart/cartdelete.png";

import * as yup from "yup";
import {
   get_cart_items,
   delete_cart_items,
   add_shipping_address,
   get_shipping_address,
   edit_shipping_address,
   delete_shipping_address,
   apply_voucher,
   increase_cart_page,
   decrease_cart_page,
   get_redeem_point,
   update_profile,
   get_voucher_discount,
} from "../../data/protectedapi";

import { useLocation } from "react-router-dom";

import Layout from "../../components/include/layout";
import Khalticonfig from "./khaltiConfig";
import { Navigate, resolvePath, useNavigate } from "react-router";

import Loading from "../../components/customcomponents/loading";

import RewardPoint from "../../components/customcomponents/rewardPoint";
import RemainingReward from "../../components/customcomponents/remainingReward";
import SeoTags from "../../utils/seoTags";
import ReactGA from "react-ga4";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import BreadcrumbComponent from "../../components/include/breadcrumb";
const Cart = () => {
   //states and useStates

   const [totalItems, setTotalItems] = useState("");
   const [stockError, setStockError] = useState(false);
   const [message, setMessage] = useState("");
   const [heading, setHeading] = useState("");
   const [cartitems, setcartItems] = useState("");
   const [shipaddress, setshipAddress] = useState("");
   const [singleshipAddress, setsingleShipAddress] = useState("");
   const [totalPrice, setTotalPrice] = useState(0);
   const [totalPay, setTotalpay] = useState("");
   const [cartloading, setCartloading] = useState(false);
   const [vouchername, setVouchername] = useState("");
   const [userprofile, setUserprofile] = useState("");
   const [userShippingaddress, setUsershippingaddress] = useState("");
   const [userBillingaddress, setUserbillingaddress] = useState("");
   const [newDiscount, setNewdiscount] = useState(0);

   const [errorMessage, setErrorMessage] = useState("");
   const [redeemAmounttoPay, setRedeemAmounttoPay] = useState(null);
   const [rememberMe, setRememberme] = useState(false);
   const [paymentmethod, setPaymentmethod] = useState("");
   const [messageModal, setMessageModal] = useState(false);
   const [errormodal, setErrormodal] = useState(false);

   const [redeem, setRedeem] = useState("");
   const [selectShipping, setSelectshipping] = useState(false);
   const [selectPay, setSelectpay] = useState(false);
   const [selectborder, setSelectborder] = useState(false);
   const [onlineMethod, setOnlineMethod] = useState(false);
   const [isEmpty, setisEmpty] = useState(true);
   const [discountamount, setDiscountamount] = useState(0);
   const [cartupdate, setCartupdate] = useState(false);
   const [qty, setQty] = useState(0);
   const [updateaddress, setUpdateaddress] = useState(false);
   const [esewaStatus, setesewaStatus] = useState(false);
   const [prabhuStatus, setprabhuStatus] = useState(false);
   const [connectIpsStatus, setconnectIpsStatus] = useState(false);
   const [khaltiStatus, setkhaltiStatus] = useState(false);
   const [imeStatus, setimeStatus] = useState(false);
   const [shipvalue, setShipvalue] = useState("");
   const { token, userDetails } = Token();
   const accessToken = token.accessToken;
   const [paymentgateway, setPaymentgateway] = useState("");
   const [paymentProceed, setPaymentproceed] = useState(false);
   const [shippingDetails, setShippingDetails] = useState("");
   const [loading, setLoading] = useState(false);
   const [ispaid, setIspaid] = useState(false);
   const [shippingcharge, setShippingcharge] = useState(0);
   const [cashborder, setCashborder] = useState(false);
   const [onlineBorder, setOnlineborder] = useState(false);

   //breadcrumbs
   const breadcrumbs = useBreadcrumbs();
   // console.log(
   //    "bread",
   //    breadcrumbs.map(({ breadcrumb }) => breadcrumb),
   // );
   let provinceData = [];
   let districtData = [];
   useEffect(() => {
      if (userDetails?.accessToken === null) {
         alert("Expired");
      }
   }, [userDetails]);

   let navigate = useNavigate();
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();

   //get user profile
   const [billingStatus, setBillingStatus] = useState(false);
   useEffect(() => {
      async function get_profile() {
         const response = await update_profile();
         setUserprofile(response?.data);
         setUsershippingaddress(response?.data?.shipping_address);
         setUserbillingaddress(response?.data?.billing_address);
         if (response?.data?.billing_address) {
            setBillingStatus(true);
         }
      }
      get_profile();
   }, [billingStatus]);
   // console.log("user_profile", billingStatus);
   // console.log("billing", userBillingaddress);
   //payment header

   //check min balance

   const [prabhuLogin, setPrabhuLogin] = useState("");

   useEffect(() => {
      if (prabhuLogin) {
         window.open(prabhuLogin, "_blank");
      }
   }, [prabhuLogin]);

   //CART ITEMS

   useEffect(() => {
      async function get_cart() {
         const response = await get_cart_items();
         if (response?.status === 200) {
            setcartItems(response?.data?.data?.cart?.cart_items);

            setTotalItems(response.data.data.total_price);
         } else {
            setcartItems("");
         }
      }
      get_cart();
   }, [cartupdate, stockError]);

   //GET SHIPPING CHARGE
   useEffect(() => {
      async function shipping_charge() {
         const res = await get_shipping_charge();
         const response = res?.data?.data;

         setShippingDetails(response);
      }
      shipping_charge();
   }, []);
   //GET SHIPPING ADDRESS

   useEffect(() => {
      async function get_data() {
         const response = await get_shipping_address();
         // console.log("shipad", response?.data?.data);
         setshipAddress(response?.data?.data);
      }
      get_data();
   }, [updateaddress]);

   let gatewayname = query.get("gatewayname");
   let queryreedem = query.get("queryreedem");
   useEffect(() => {
      setPaymentgateway(gatewayname);
   }, []);

   const [errorPayment, setErrorPayment] = useState(false);
   const [errorShipping, setErrorShipping] = useState(false);

   const handlePaymentCheck = () => {
      if (onlineMethod === false && selectPay === false) {
         setErrorPayment(true);
      }
      if (selectShipping === false) {
         setErrorShipping(true);
      }
   };

   //selecting shipping address filled by user on KYC
   useEffect(() => {
      Object.entries(userShippingaddress).map(([key, value]) => {
         if (value?.same_as_billing === true) {
            setErrorShipping(false);
            setSelectshipping(true);
            setsingleShipAddress(value);
         }
      });
   }, [userShippingaddress]);
   // console.log("user", userBillingaddress);

   //redeem  state
   const redeemTotal = totalPrice - discountamount > 0 ? totalPrice - discountamount : 0;
   const [redeemErrorModal, setRedeemErrormodal] = useState(false);
   const [redeemDiscount, setRedeemdiscount] = useState(0);

   //handle redeem point
   const handleReedem = async (e) => {
      setRememberme(e.target.checked);
      const response = await get_redeem_point(
         discountamount ? totalPrice - discountamount : totalPrice,
      );
      if (e?.target?.checked) {
         if (response?.status === 200) {
            setRedeem(response?.data.usedReward);
            setRedeemdiscount(Math.floor(response?.data?.discount));
            setRedeemAmounttoPay(Math.round(response?.data?.additionalAmountToPay));
            setTotalpay("");
         } else {
            setRedeemErrormodal(true);
            setMessage(response?.response?.data?.message);
         }
      } else {
      }
   };
   // console.log("Redeem Point", redeem, rememberMe);
   //if remember me checkbox is checked then set previous redeem amout to 0
   useEffect(() => {
      if (rememberMe === false) {
         setRedeemAmounttoPay(null);
         setRedeem(0);
         setRedeemdiscount(0);
      }
   }, [rememberMe, newDiscount]);

   useEffect(() => {
      if (totalPrice - discountamount + shippingcharge > 0) {
         setTotalpay(totalPrice - newDiscount + shippingcharge);
      }
   }, [totalPay, totalPrice, discountamount, newDiscount, shippingcharge]);
   // console.log("totalpay", totalPay);

   //cart order
   const handleOrderCart = async (e) => {
      let Filtered = cartitems?.filter((item) => selectedCartItems?.includes(item?.productId?._id));
      const config = {
         headers: { Authorization: `Bearer ${accessToken}` },
      };
      let bodyParameters = {
         order_items: Object.entries(Filtered).map(([key, item]) => {
            return {
               productId: item.productId,
               name: item?.productId?.name,
               brand: item?.brand,
               color: item?.color,
               image: item?.productId?.featured_image,
               price: item?.productId?.sale?.sale_price
                  ? item?.productId?.sale?.sale_price
                  : item?.productId?.price,
               quantity: item.quantity,
               is_linked: item?.is_linked,
               comment: item?.comment,
            };
         }),

         shipping_address: {
            name: singleshipAddress?.name,
            address: singleshipAddress?.address,
            street: singleshipAddress?.street,
            landmark: singleshipAddress?.landmark,
            district: singleshipAddress?.district,
            province: singleshipAddress?.province,
         },
         voucher: {
            name: vouchername ? vouchername : null,
            voucherId: voucherid ? voucherid : null,
         },
         billing_address: {
            name: userBillingaddress?.name ? userBillingaddress?.name : "",
            address: userBillingaddress?.address ? userBillingaddress?.address : "",
            street: userBillingaddress?.street ? userBillingaddress?.street : "",
            landmark: userBillingaddress?.landmark ? userBillingaddress?.landmark : "",
            district: userBillingaddress?.district ? userBillingaddress?.district : "",
            province: userBillingaddress?.province ? userBillingaddress?.province : "",
         },
         reward: redeem,
         // sub_total: totalPrice - discountamount > 0 ? totalPrice - discountamount : 0,
         sub_total: totalPrice - newDiscount,
         discount: newDiscount,
         tax_price: 0,
         shipping_price: shippingcharge,
         // total: totalPay,
         total: totalPrice - newDiscount + shippingcharge,

         // total: totalPrice - discountamount > 0 ? totalPrice - discountamount : 0,
         payment_method: paymentgateway,
         is_paid: ispaid,
         // paid_at: `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`,
         comment: "adasdsads",
      };

      const bodyFormData = new FormData();
      bodyFormData.append("data", bodyParameters);
      axios
         .post(process.env.REACT_APP_BASE_URL + "v1/shop/order", bodyParameters, config)
         .then(async (res) => {
            if (res.status === 201) {
               // Object.entries(cartitems).map(([key, item]) => {
               //    ReactGA.event("purchase", {
               //       category: item?.productId?.name,
               //       action: "Purchase",
               //       transport: "xhr",
               //    });
               //    return null;
               // });
               // if (res?.data?.data?.payment_method === "khalti") {
               //    const data = await handleOnlinePayment({
               //       order_id: res?.data?.data?.orderId,
               //       payment_method: res?.data?.data?.payment_method,
               //       order_name: res?.data?.data?.order_name,
               //       uid: userDetails?.id,
               //       setSuccess: setMessageModal,
               //    });
               //    setHeading(" Purchase  Successful");
               //    setMessage(
               //       "Thank you for your purchase! You will get a call from our team shortly for confirmation and delivery.",
               //    );
               //    const checkout = new KhaltiCheckout(data);
               //    checkout.show({ amount: res?.data?.data?.total * 100 });
               // } else
               if (res?.data?.data?.payment_method === "cod") {
                  setMessageModal(true);
                  setHeading(" Purchase  Successful");
                  setMessage(
                     "Thank you for your purchase! You will get a call from our team shortly for confirmation and delivery.",
                  );
                  setCartupdate(!cartupdate);
               } else
                  handleOnlinePayment({
                     order_id: res?.data?.data?.orderId,
                     payment_method: res?.data?.data?.payment_method,
                     order_name: res?.data?.data?.order_name,
                     total: res?.data?.data?.total,
                     uid: userDetails?.id,
                  });
            } else {
               setErrormodal(true);
               setCartloading(false);
            }
         })
         .catch((err) => {
            if (err) {
               setErrormodal(true);
               setCartloading(false);
               setMessage(err?.response?.data?.message);
            }
         });
   };
   // console.log("subtotal", totalPrice);
   // console.log("subtotal", redeemAmounttoPay);

   //MODAL
   const [show, setShow] = useState(false);
   const [editshow, setEditshow] = useState(false);
   const [paymentmodal, setPaymentmodal] = useState(false);

   const [deletcartmodal, setDeletecartmodal] = useState(false);
   const handleClose = () => {
      setErrorMessage("");
      setName("");
      setAddress("");
      setStreet("");
      setLandmark("");
      setDistrict("");
      setProvince("");
      setShow(false);
   };
   const handleEditClose = () => {
      setErrorMessage("");
      setEditshow(false);
      setName("");
      setAddress("");
      setStreet("");
      setLandmark("");
      setDistrict("");
      setProvince("");
   };
   const handleShow = () => setShow(true);
   //cart delete
   const [deleteId, setDeleteid] = useState("");
   const CartdeleteModalopen = (id) => {
      setDeleteid(id);
      setShow(false);
      setDeletecartmodal(true);
   };
   console.log("modal", deletcartmodal);
   const CartdeleteModalclose = (id) => {
      setDeletecartmodal(false);
      setShow(false);
   };
   const [editId, setEditId] = useState("");
   const handleeditShow = (val) => {
      setEditshow(true);
      setEditId(val._id);
      setName(val.name);
      setAddress(val.address);
      setStreet(val.street);
      setLandmark(val.landmark);
      setDistrict(val.landmark);
      setDistrict(val.district);
      setProvince(val.province);
   };
   //cart increase state
   const [limitexceeds, setLimitexceeds] = useState(false);
   const [limitexceedsincrease, setLimitexceedsincrease] = useState(false);
   //FORM VALUE
   const [name, setName] = useState("");
   const [address, setAddress] = useState("");
   const [street, setStreet] = useState("");
   const [landmark, setLandmark] = useState("");
   const [district, setDistrict] = useState("");
   const [province, setProvince] = useState("");
   let districtOption = [];
   let districtOption1 = [];

   const handleName = (e) => {
      if (e.target.value.indexOf(" ") === 0) {
         setName("");
      } else {
         setName(e.target.value);
      }
   };

   const handleDistrict = (option) => {
      setDistrict(option.label);
   };
   const handleStreet = (e) => {
      setStreet(e.target.value);
   };
   const handleLandmark = (e) => {
      if (e.target.value.indexOf(" ") === 0) {
         setLandmark("");
      } else {
         setLandmark(e.target.value);
      }
   };
   const handleAddress = (e) => {
      if (e.target.value.indexOf(" ") === 0) {
         setAddress("");
      } else {
         setAddress(e.target.value);
      }
   };

   //ADD SHIPPING ADDRESS

   const [errorState, setErrorState] = useState(false);

   //formik schema
   const locationschema = yup.object().shape({
      // customer_id: yup.string().required(),
      name: yup.string().required(),
      address: yup.string().required(),
      landmark: yup.string().required(),
      district: yup.string().required(),
      province: yup.string().required(),
   });

   //adding shipping address
   const handleSubmit = async (e) => {
      if (name === "" || landmark === "" || district === "" || address === "" || province === "") {
         setErrorMessage("Please fill all the fields");
         setErrorState(true);
      } else {
         setErrorMessage("");
         setErrorState(false);
         const response = await add_shipping_address(
            name,
            address,
            street,
            landmark,
            district,
            province,
         );
         setErrorState(false);
         setShow(false);
         setName("");
         setAddress("");
         setStreet("");
         setLandmark("");
         setDistrict("");
         setProvince("");
         setUpdateaddress(!updateaddress);
      }
   };

   //DELETE CART
   const removeFromCart = async (items) => {
      const response = await delete_cart_items(items?._id);
      if (response.data.status === "success") {
         setDeletecartmodal(false);
      }
      setCartupdate(!cartupdate);
   };

   const handleDeleteShip = async (id) => {
      const response = await delete_shipping_address(id);
      setSelectshipping(false);

      setUpdateaddress(!updateaddress);
   };

   //EDIT SHIPPING ADDRESS
   const [shippingModal, setShippingModal] = useState(false);
   const [shippingModalError, setShippingModalError] = useState(false);
   const handleEditShip = async (id) => {
      if (name === "" || landmark === "" || district === "" || address === "" || province === "") {
         setErrorMessage("Please fill all the fields");
         setErrorState(true);
      } else {
         const response = await edit_shipping_address(
            id,
            name,
            address,
            street,
            landmark,
            district,
            province,
         );
         if (response?.status === 200) {
            setShippingModal(true);
            setEditshow(false);
            setName("");
            setAddress("");
            setStreet("");
            setLandmark("");
            setDistrict("");
            setProvince("");
            setUpdateaddress(!updateaddress);
         } else {
            setShippingModalError(true);
         }
      }
   };
   //decreasing product
   const handleDecrease = async (val) => {
      // if (val?.quantity > 0) {
      const response = await decrease_cart_page(val.productId?._id);
      setQty(qty);
      setRememberme(false);

      if (response?.data?.message === "Quantity cannot be less than 0") {
         setLimitexceeds(true);
      }
      setLimitexceedsincrease(false);
      setCartupdate(!cartupdate);
   };

   const handleIncrease = async (val) => {
      setQty(qty);
      const response = await increase_cart_page(val.productId?._id);
      setRememberme(false);
      setLimitexceeds(false);
      if (response?.data?.message === "Quantity limit exceeds") {
         setLimitexceedsincrease(true);
      }
      setCartupdate(!cartupdate);
   };

   const [checked, setisChecked] = useState(false);

   //total of cart
   const [selectedCartItems, setSelectedCartItems] = useState([]);

   const [totalProducts, setTotalProducts] = useState(0);
   useEffect(() => {
      // setFiltered(cartitems?.filter((item) => selectedCartItems?.includes(item?._id)));
      let items = 0;
      let price = 0;
      [...cartitems].forEach((item) => {
         // console.log("items", item);
         if (selectedCartItems?.includes(item?.productId?._id)) {
            items += item.quantity;
            item?.productId?.sale?.sale_price
               ? (price += item.quantity * item?.productId?.sale?.sale_price)
               : (price += item.quantity * item?.productId?.price);
         }
      });

      setTotalProducts(items);
      setTotalPrice(price);
   }, [cartitems, redeemAmounttoPay, selectedCartItems]);
   // console.log("totalprice", totalPay);
   const [updateCharge, setUpdateCharge] = useState(false);

   //VOUCHER
   const [voucherlist, setVoucherList] = useState("");

   const [newTotal, setnewTotal] = useState(0);
   const [errorvoucher, seterrorVoucher] = useState(false);
   const [vouchermessage, setVouchermessage] = useState("");
   const [successVoucher, setsuccessVoucher] = useState(false);
   const [voucherid, setVoucherid] = useState("");

   const sendVoucher = async () => {
      let Filtered = cartitems?.filter((item) => selectedCartItems?.includes(item?.productId?._id));
      const order_items = Object.entries(Filtered).map(([key, item]) => {
         return `${item.productId?._id}`;
      });
      const response = await get_voucher_discount(totalPrice, vouchername, "Store", order_items);
      setRememberme(false);

      if (response?.status === 200) {
         setsuccessVoucher(true);
         // console.log("response", response);
         setVoucherid(response?.data?.data?.voucherId);
         setDiscountamount(Math.round(response?.data?.data.discountAmount));
         setVouchermessage(response?.data?.message);

         setTimeout(() => {
            setsuccessVoucher(false);
         }, 5000);
         seterrorVoucher(false);
      } else {
         seterrorVoucher(true);
         setVouchermessage(response?.response?.data?.message);
         setTimeout(() => {
            seterrorVoucher(false);
         }, 5000);
      }
   };

   useEffect(() => {
      totalPrice === 0 && setDiscountamount(0);
      totalPrice !== 0 && discountamount && sendVoucher();
   }, [totalPrice]);
   const handleChange = (e) => {
      if (e.target.value.indexOf(" ") === 0) {
         setVouchername("");
      } else {
         setVouchername(e.target.value);
      }
   };
   useEffect(() => {
      if (discountamount >= 0 || redeemDiscount >= 0) {
         setNewdiscount(discountamount + redeemDiscount);
      }
   }, [redeemDiscount, discountamount, rememberMe, newDiscount]);

   const current = new Date();
   const handleSingleShippingChange = (e) => {
      setsingleShipAddress(JSON.parse(e.target.value));

      setSelectshipping(true);
   };
   // console.log("Hello", singleshipAddress);
   let found = false;

   useEffect(() => {
      [...shippingDetails].map((item) => {
         // console.log("shippingcharge", item);

         if (shipaddress?.length === 0) {
            setShippingcharge(0);
         } else if (
            item.address?.toUpperCase() === singleshipAddress.district?.toUpperCase() &&
            item.province?.toUpperCase() === singleshipAddress.province?.toUpperCase() &&
            selectShipping
         ) {
            setShippingcharge(item.amount);
            found = true;
         } else {
            !found && setShippingcharge(0);
         }
      });
   }, [shippingcharge, singleshipAddress, selectShipping, province, district]);
   // console.log("shipping", shipaddress);

   const [filteredAddress, setFilteredAddress] = useState([]);

   useEffect(() => {
      let newfilteredaddress = [];
      [...shippingDetails].map((item) => {
         if (item.province === province) {
            newfilteredaddress.push(item);
            setFilteredAddress(newfilteredaddress);
         } else {
            setFilteredAddress(newfilteredaddress);
         }
      });
   }, [province]);

   const options = ["1", "2", "3", "4", "5", "6", "7"];

   //open payment modal

   const [paymentshow, setPaymentshow] = useState(true);

   const paymentModalclose = () => {
      setPaymentmodal(false);

      setShow(false);
   };

   //check stockquantity
   useEffect(() => {
      Object.entries(cartitems)?.map(([key, items]) => {
         if (items?.quantity > items?.productId?.stock_quantity) {
            setStockError(true);
         } else {
            setStockError(false);
         }
      });
   }, [cartitems, stockError, cartupdate]);

   //Company Details
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);
   const textInput = useRef(null);
   const textInput2 = useRef(null);

   function handleClick() {
      textInput.current.focus();
   }
   function handleClick2() {
      textInput2.current.focus();
   }
   return loading || cartloading ? (
      <Loading />
   ) : (
      <div>
         {SeoTags("Cart | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}
         <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
            {/* start of modal */}
            <Modal
               centered
               show={deletcartmodal}
               onHide={CartdeleteModalclose}
               animation={false}
               id="deletecart-modal"
            >
               <Modal.Header closeButton id="modal-header"></Modal.Header>
               <Modal.Body id="modal-body" style={{ textAlign: "center" }}>
                  <div>
                     <img src={cartdelete} alt="" />
                  </div>
                  <p className="cartdelete-title">Remove from cart</p>
                  <p className="cartdelete-sub">This item will be removed from order</p>
               </Modal.Body>
               <Modal.Footer id="modal-footer">
                  <Button variant="secondary" onClick={() => CartdeleteModalclose()} id="cancel">
                     Cancel
                  </Button>
                  <Button
                     variant="primary"
                     onClick={() => {
                        removeFromCart(deleteId);
                        CartdeleteModalclose();
                        setRememberme(false);
                     }}
                     id="removecart"
                  >
                     Remove
                  </Button>
               </Modal.Footer>
            </Modal>
            <Modal
               centered
               onHide={paymentModalclose}
               show={paymentmodal}
               animation={false}
               id="deletecart-modal"
            >
               <Modal.Header closeButton id="modal-header"></Modal.Header>
               <Modal.Body id="modal-body">
                  <div>
                     <img src={tick} alt="" />
                  </div>
                  <p className="cartdelete-title">Payment successful</p>
               </Modal.Body>
               <Modal.Footer id="modal-footer"></Modal.Footer>
            </Modal>

            <MessageModal
               show={messageModal}
               setShow={setMessageModal}
               heading={heading}
               message={message}
               buttonText=" Home Page"
               isNavigate={true}
               button2={"My orders"}
               link="/"
               link2="/dashboard?type=9&option=product"
            />
            <MessageModal
               type="error"
               show={errormodal}
               setShow={setErrormodal}
               heading={"Purchase Failed"}
               // message={"Something went wrong. Please try paying again from My Orders page."}
               message={message}
               buttonText=" Home Page"
               button2={"My orders"}
               link="/"
               link2="/dashboard?type=9"
            />
            <MessageModal
               show={shippingModal}
               setShow={setShippingModal}
               heading={heading}
               message={"Shipping address updated!"}
               buttonText="Ok"
            />
            <MessageModal
               error={shippingModalError}
               setShow={setShippingModalError}
               heading={" Failed to update"}
               message={"Something went wrong. Please try again."}
               buttonText="OK"
            />

            <MessageModal
               type="error"
               error={errormodal}
               setShow={setErrormodal}
               heading={"Purchase Failed"}
               message={"Something went wrong. Please try paying again from My Orders page."}
               buttonText=" Home Page"
               button2={"My orders"}
               link="/"
               link2="/dashboard?type=9&option=product"
            />

            <MessageModal
               show={redeemErrorModal}
               setShow={setRedeemErrormodal}
               heading={heading}
               message={message}
               buttonText="OK"
               type={"error"}
            />
            <MessageModal
               show={redeemErrorModal}
               setShow={setRedeemErrormodal}
               heading={heading}
               message={message}
               buttonText="OK"
               type={"error"}
            />
            {/* end of modal */}
            {/* Edit Moda */}

            <Modal id="modal-body-location" show={editshow} onHide={handleEditClose}>
               <Form
                  onSubmit={(e) => {
                     e.preventDefault();
                     handleEditShip(editId);
                  }}
               >
                  <Modal.Header id="modal-header">
                     <Modal.Title id="modal-title">Edit</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address Name</Form.Label>
                        <Form.Control
                           type="name"
                           placeholder=""
                           id="modal-form"
                           minLength={2}
                           maxLength={50}
                           value={name}
                           required={required}
                           // onChange={handleName}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data) {
                                 if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z \b]")) {
                                    handleName(e);
                                 }
                                 if (e?.nativeEvent?.data === null) {
                                    handleName(e);
                                 }
                              } else {
                                 handleName(e);
                              }
                           }}
                        />
                     </Form.Group>
                     {errorState === true && name === "" && (
                        <p style={{ color: "red" }}>Name is required</p>
                     )}
                     <div id="shipping-charge-address">
                        <label>Province</label>

                        {/* mapping province from district-province json */}
                        {provinceData?.length === 0 &&
                           Object.entries(provinces).map(([key, value]) => {
                              provinceData.push({
                                 value: value.id,
                                 label: value.name,
                              });
                              return null;
                           })}
                        {provinceData && (
                           <div
                              className="focus-dropdown"
                              tabIndex="0"
                              ref={textInput}
                              onClick={handleClick}
                           >
                              <Dropdown
                                 ref={textInput}
                                 onClick={handleClick}
                                 value={province}
                                 onChange={(option) => {
                                    setProvince(option.label);
                                    districtData = [];
                                    setDistrict("");
                                 }}
                                 options={provinceData}
                              />
                           </div>
                        )}
                     </div>
                     {errorState === true && province === "" && (
                        <p
                           style={{
                              color: "red",
                              marginTop: "10px",
                           }}
                        >
                           Province is required
                        </p>
                     )}
                     <div
                        id="shipping-charge-address"
                        style={{
                           marginTop: "15px",
                           marginBottom: "10px",
                        }}
                     >
                        <label>District</label>
                        {/* mapping of district */}
                        {districtData?.length === 0 &&
                           Object.entries(districts).map(([key, value]) => {
                              province === value?.province &&
                                 districtData.push({
                                    value: value.id,
                                    label: value.name,
                                 });
                              return null;
                           })}
                        <div
                           className="focus-dropdown"
                           tabIndex="0"
                           ref={textInput2}
                           onClick={handleClick2}
                        >
                           <Dropdown
                              ref={textInput2}
                              onClick={handleClick2}
                              value={district}
                              onChange={(option) => setDistrict(option.label)}
                              options={districtData}
                           />
                        </div>
                     </div>
                     {errorState === true && district === "" && (
                        <p style={{ color: "red" }}>District is required</p>
                     )}
                     <div
                        id="shipping-charge-address"
                        style={{
                           marginTop: "15px",
                           marginBottom: "10px",
                        }}
                     >
                        <label id="modal-label">Street Name</label>
                        <br />{" "}
                        <Form.Control
                           type="name"
                           placeholder=""
                           minLength={5}
                           maxLength={350}
                           id="modal-form"
                           required
                           value={street}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z0-9-, ]")) {
                                 handleStreet(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleStreet(e);
                              } else {
                                 handleStreet(e);
                              }
                           }}
                        />
                     </div>
                     {errorState === true && street === "" && (
                        <p style={{ color: "red" }}>Street Name is required</p>
                     )}
                     <div
                        id="shipping-charge-address"
                        style={{
                           marginTop: "15px",
                           marginBottom: "10px",
                        }}
                     >
                        <label id="modal-label">Address</label>
                        <br />{" "}
                        <Form.Control
                           type="name"
                           placeholder=""
                           minLength={5}
                           maxLength={350}
                           id="modal-form"
                           required
                           value={address}
                           onChange={(e) => {
                              if (e?.nativeEvent?.data?.toString()?.match("[A-Za-z0-9-, ]")) {
                                 handleAddress(e);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 handleAddress(e);
                              } else {
                                 handleAddress(e);
                              }
                           }}
                        />
                     </div>
                     {errorState === true && address === "" && (
                        <p style={{ color: "red" }}>Address is required</p>
                     )}

                     <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Landmark</Form.Label>
                        <Form.Control
                           type="name"
                           placeholder=""
                           minLength={5}
                           maxLength={350}
                           required
                           id="modal-form"
                           value={landmark}
                           onChange={handleLandmark}
                        />
                     </Form.Group>
                     {errorState === true && landmark === "" && (
                        <p style={{ color: "red" }}>Landmark is required</p>
                     )}
                  </Modal.Body>
                  <Modal.Footer id="modal-footer-address">
                     <Button id="modal_btn2" variant="secondary" onClick={handleEditClose}>
                        Cancel
                     </Button>
                     <Button id="modal_btn" type="submit">
                        Save
                     </Button>
                  </Modal.Footer>
               </Form>
            </Modal>

            {/* ModalEnd */}

            {cartitems?.length !== 0 ? (
               <div className="cart-container ">
                  <p className="fixed-width cart-container_title  ">My Cart</p>
                  <p className="fixed-width cart-container_title  ">
                     <BreadcrumbComponent pathname={"Cart"} />
                  </p>

                  <div className="cart-container_details fixed-width">
                     <div style={{ flexBasis: "53%" }}>
                        <div className="container cart-container_address ">
                           <div className="cart-container_address_flexcontainer ">
                              <div style={{ width: "100%" }}>
                                 <p className="cart-container_address_title">Shipping Address</p>
                                 <p className="cart-container_address_subtitle">
                                    You can edit your shipping address or choose the added one
                                 </p>
                                 {/* shipping address form */}
                                 <form
                                    onChange={(e) => {
                                       setsingleShipAddress(JSON.parse(e.target.value));
                                       setSelectshipping(true);
                                       setErrorShipping(false);
                                    }}
                                 >
                                    {Object.entries(shipaddress).map(([key, item]) => {
                                       return (
                                          <>
                                             <div id="shipping-address">
                                                {shipaddress ? (
                                                   <div>
                                                      {billingStatus ? (
                                                         <>
                                                            <input
                                                               type="radio"
                                                               id={item._id}
                                                               defaultChecked={
                                                                  item?.same_as_billing === true
                                                               }
                                                               name="fav_language"
                                                               value={JSON.stringify(item)}
                                                            />
                                                         </>
                                                      ) : (
                                                         <>
                                                            <input
                                                               type="radio"
                                                               id={item._id}
                                                               name="fav_language"
                                                               value={JSON.stringify(item)}
                                                            />
                                                         </>
                                                      )}

                                                      <label for={item._id} id="address-shipping">
                                                         {item.name}
                                                      </label>
                                                      <br />
                                                   </div>
                                                ) : null}

                                                <div className="edit">
                                                   <p
                                                      className="edit"
                                                      onClick={() => handleeditShow(item)}
                                                   >
                                                      Edit
                                                   </p>

                                                   <p
                                                      onClick={() => {
                                                         handleDeleteShip(item._id);
                                                         window.location.reload();
                                                         setSelectshipping(false);
                                                         setErrorShipping(false);
                                                      }}
                                                      className="delete-ship"
                                                   >
                                                      Delete
                                                   </p>
                                                </div>
                                             </div>
                                          </>
                                       );
                                    })}
                                 </form>
                                 {errorShipping === true ? (
                                    <p style={{ color: "red" }}>
                                       Please select your shipping address
                                    </p>
                                 ) : (
                                    <></>
                                 )}
                              </div>
                              <div
                                 style={{ position: "absolute", right: "15px" }}
                                 className="cursor_pointer"
                              >
                                 <img onClick={handleShow} src={addlocation} />
                                 <Modal id="modal-body-location" show={show} onHide={handleClose}>
                                    {/* form for adding new shipping address */}
                                    <Form
                                       onSubmit={(e) => {
                                          e.preventDefault();
                                          handleSubmit(e);
                                       }}
                                    >
                                       <Modal.Header id="modal-header">
                                          <Modal.Title id="modal-title">
                                             Add New Location
                                          </Modal.Title>
                                       </Modal.Header>
                                       <Modal.Body>
                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                             <Form.Label id="modal-label">Address Name</Form.Label>
                                             <Form.Control
                                                type="name"
                                                placeholder=""
                                                minLength={2}
                                                maxLength={50}
                                                value={name}
                                                required={required}
                                                onChange={(e) => {
                                                   if (e?.nativeEvent?.data) {
                                                      if (
                                                         e?.nativeEvent?.data
                                                            ?.toString()
                                                            ?.match("[A-Za-z \b]")
                                                      ) {
                                                         handleName(e);
                                                      }
                                                      if (e?.nativeEvent?.data === null) {
                                                         handleName(e);
                                                      }
                                                   } else {
                                                      handleName(e);
                                                   }
                                                }}
                                                id="modal-form"
                                                style={{ border: "1px solid red" }}
                                             />
                                          </Form.Group>

                                          {errorState === true && name === "" && (
                                             <p style={{ color: "red" }}>Name is required</p>
                                          )}

                                          <div id="shipping-charge-address">
                                             <label id="modal-label">Province</label>
                                             {provinceData?.length === 0 &&
                                                Object.entries(provinces).map(([key, value]) => {
                                                   if (provinceData?.length === 0) {
                                                      provinceData?.push({
                                                         value: "",
                                                         label: "Select...",
                                                      });
                                                   }
                                                   provinceData.push({
                                                      value: value.id,
                                                      label: value.name,
                                                   });
                                                   return null;
                                                })}{" "}
                                             {provinceData && (
                                                <div
                                                   className="focus-dropdown"
                                                   tabIndex="0"
                                                   ref={textInput}
                                                   onClick={handleClick}
                                                >
                                                   <Dropdown
                                                      ref={textInput}
                                                      onClick={handleClick}
                                                      // value={provinceData[0].label}
                                                      onChange={(option) => {
                                                         setProvince(option.label);
                                                         districtData = [];
                                                         setDistrict("");
                                                      }}
                                                      options={provinceData}
                                                   />
                                                </div>
                                             )}
                                          </div>

                                          {errorState === true && province === "" && (
                                             <p style={{ color: "red", marginTop: "10px" }}>
                                                Province is required
                                             </p>
                                          )}

                                          <div
                                             id="shipping-charge-address"
                                             style={{ marginTop: "15px", marginBottom: "10px" }}
                                          >
                                             <label id="modal-label">District</label>
                                             <br />{" "}
                                             {districtData?.length === 0 &&
                                                Object.entries(districts).map(([key, value]) => {
                                                   province === value?.province &&
                                                      districtData.push({
                                                         value: value.id,
                                                         label: value.name,
                                                      });
                                                   return null;
                                                })}
                                             <div
                                                className="focus-dropdown"
                                                tabIndex="0"
                                                ref={textInput2}
                                                onClick={handleClick2}
                                             >
                                                <Dropdown
                                                   ref={textInput2}
                                                   onClick={handleClick2}
                                                   value={district}
                                                   onChange={(option) => setDistrict(option.label)}
                                                   options={districtData}
                                                />
                                             </div>
                                          </div>
                                          {errorState === true && district === "" && (
                                             <p style={{ color: "red" }}>District is required</p>
                                          )}
                                          <div
                                             id="shipping-charge-address"
                                             style={{ marginTop: "15px", marginBottom: "10px" }}
                                          >
                                             <label id="modal-label">Street Name</label>
                                             <br />{" "}
                                             <Form.Control
                                                type="name"
                                                placeholder=""
                                                minLength={5}
                                                maxLength={350}
                                                id="modal-form"
                                                required
                                                value={street}
                                                onChange={(e) => {
                                                   if (
                                                      e?.nativeEvent?.data
                                                         ?.toString()
                                                         ?.match("[A-Za-z0-9-, ]")
                                                   ) {
                                                      handleStreet(e);
                                                   }
                                                   if (e?.nativeEvent?.data === null) {
                                                      handleStreet(e);
                                                   } else {
                                                      handleStreet(e);
                                                   }
                                                }}
                                             />
                                          </div>
                                          {errorState === true && street === "" && (
                                             <p style={{ color: "red" }}>Street Name is required</p>
                                          )}
                                          <div
                                             id="shipping-charge-address"
                                             style={{ marginTop: "15px", marginBottom: "10px" }}
                                          >
                                             <label id="modal-label">Address</label>
                                             <br />{" "}
                                             <Form.Control
                                                type="name"
                                                placeholder=""
                                                minLength={5}
                                                maxLength={350}
                                                id="modal-form"
                                                required
                                                value={address}
                                                onChange={(e) => {
                                                   if (
                                                      e?.nativeEvent?.data
                                                         ?.toString()
                                                         ?.match("[A-Za-z0-9-, ]")
                                                   ) {
                                                      handleAddress(e);
                                                   }
                                                   if (e?.nativeEvent?.data === null) {
                                                      handleAddress(e);
                                                   } else {
                                                      handleAddress(e);
                                                   }
                                                }}
                                             />
                                          </div>
                                          {errorState === true && address === "" && (
                                             <p style={{ color: "red" }}>Address is required</p>
                                          )}

                                          <Form.Group className="mb-3" controlId="formBasicEmail">
                                             <Form.Label id="modal-label">Landmark</Form.Label>
                                             <Form.Control
                                                type="name"
                                                minLength={5}
                                                maxLength={350}
                                                id="modal-form"
                                                placeholder=""
                                                value={landmark}
                                                onChange={(e) => {
                                                   if (
                                                      e?.nativeEvent?.data
                                                         ?.toString()
                                                         ?.match("[A-Za-z0-9-, ]")
                                                   ) {
                                                      handleLandmark(e);
                                                   }
                                                   if (e?.nativeEvent?.data === null) {
                                                      handleLandmark(e);
                                                   } else {
                                                      handleLandmark(e);
                                                   }
                                                }}
                                             />
                                          </Form.Group>
                                          {errorState === true && landmark === "" && (
                                             <p style={{ color: "red" }}>Landmark is required</p>
                                          )}
                                       </Modal.Body>
                                       <Modal.Footer id="modal-footer-address">
                                          <Button
                                             id="modal_btn2"
                                             variant="secondary"
                                             onClick={handleClose}
                                          >
                                             Cancel
                                          </Button>
                                          <Button id="modal_btn" type="submit">
                                             Save
                                          </Button>
                                       </Modal.Footer>
                                    </Form>
                                 </Modal>
                              </div>
                           </div>
                        </div>
                        {/* selection of payment method */}
                        <div className="cart-container_address mb-3" style={{ marginTop: "30px" }}>
                           <div className="cart-container_address_flexcontainer ">
                              <div>
                                 <p className="cart-container_address_title">Payment Method</p>
                                 <p className="cart-container_address_subtitle">
                                    You can choose your desired payment method
                                 </p>
                              </div>
                           </div>
                           <div>
                              <div>
                                 <div className="cart_container_address_li ">
                                    <form>
                                       {process.env.REACT_APP_STORE_ONLINE_PAYMENT_STATUS ===
                                          "true" && (
                                          <div
                                             id={"selectborder"}
                                             // id={selectborder ? "" : "selectborder"}
                                             onClick={() => {
                                                setOnlineborder(true);
                                                setCashborder(false);
                                             }}
                                          >
                                             {" "}
                                             <input
                                                onClick={() => {
                                                   setisChecked(true);
                                                   setSelectpay(false);

                                                   // setPaymentgateway("digital");
                                                }}
                                                type="radio"
                                                value="digital"
                                                id="pay"
                                                name="fav_language"
                                             />{" "}
                                             <label id="digital" for="pay">
                                                Online Payment
                                             </label>{" "}
                                             <br />
                                             {checked === true ? (
                                                <div
                                                   className="payment_methods"
                                                   onClick={() => {
                                                      setOnlineMethod(true);
                                                      setErrorPayment(false);
                                                   }}
                                                >
                                                   {contactDetails?.disable_imepay === false && (
                                                      <div>
                                                         <img
                                                            src={ime}
                                                            className={`${
                                                               imeStatus
                                                                  ? "payment_border"
                                                                  : "payment_hover"
                                                            }`}
                                                            alt="ime pay"
                                                            onClick={() => {
                                                               setPaymentgateway("imepay");
                                                               setkhaltiStatus(false);
                                                               setesewaStatus(false);
                                                               setimeStatus(true);
                                                               setprabhuStatus(false);
                                                               setconnectIpsStatus(false);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                                   {contactDetails?.disable_esewa === false && (
                                                      <div>
                                                         <img
                                                            id={`${
                                                               esewaStatus
                                                                  ? "payment_border"
                                                                  : "esewa-border"
                                                            }`}
                                                            className={`${
                                                               esewaStatus
                                                                  ? "payment_border"
                                                                  : "payment_hover"
                                                            }`}
                                                            alt="esewa"
                                                            src={esewa}
                                                            onClick={() => {
                                                               setPaymentgateway("esewa");
                                                               setkhaltiStatus(false);
                                                               setesewaStatus(true);
                                                               setimeStatus(false);
                                                               setprabhuStatus(false);
                                                               setconnectIpsStatus(false);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                                   {contactDetails?.disable_khalti === false && (
                                                      <div>
                                                         <img
                                                            className={`${
                                                               khaltiStatus
                                                                  ? "payment_border"
                                                                  : "payment_hover"
                                                            }`}
                                                            alt="Khalti"
                                                            src={khalti}
                                                            onClick={() => {
                                                               setPaymentgateway("khalti");
                                                               setkhaltiStatus(true);
                                                               setesewaStatus(false);
                                                               setimeStatus(false);
                                                               setprabhuStatus(false);
                                                               setconnectIpsStatus(false);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                                   {contactDetails?.disable_connectips ===
                                                      false && (
                                                      <div>
                                                         <img
                                                            src={ips}
                                                            className={`${
                                                               connectIpsStatus
                                                                  ? "payment_border"
                                                                  : "payment_hover"
                                                            }`}
                                                            alt="ips"
                                                            onClick={() => {
                                                               setPaymentgateway("connectips");
                                                               setkhaltiStatus(false);
                                                               setesewaStatus(false);
                                                               setimeStatus(false);
                                                               setprabhuStatus(false);
                                                               setconnectIpsStatus(true);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                                   {/* <div>
                                                   <img src={paypoint} />
                                                </div> */}
                                                   {contactDetails?.disable_prabhupay === false && (
                                                      <div>
                                                         <img
                                                            src={prabhu}
                                                            className={`${
                                                               prabhuStatus
                                                                  ? "payment_border"
                                                                  : "payment_hover"
                                                            }`}
                                                            alt="prabhupay"
                                                            onClick={() => {
                                                               setPaymentgateway("prabhupay");
                                                               setkhaltiStatus(false);
                                                               setimeStatus(false);
                                                               setesewaStatus(false);
                                                               setconnectIpsStatus(false);
                                                               setprabhuStatus(true);
                                                            }}
                                                         />
                                                      </div>
                                                   )}
                                                </div>
                                             ) : null}
                                          </div>
                                       )}
                                       <div
                                          style={{ marginBottom: "18px", marginTop: "14px" }}
                                          // id={selectborder ? "selectborder" : ""}
                                          id={"selectborder"}
                                          onClick={() => {
                                             setSelectborder(false);
                                             setCashborder(true);
                                             setOnlineborder(false);
                                             // setSelectpay(true);
                                             // setErrorPayment(false);
                                          }}
                                       >
                                          <input
                                             onClick={() => {
                                                {
                                                   setSelectpay(true);
                                                   setErrorPayment(false);
                                                   setkhaltiStatus(false);
                                                   setesewaStatus(false);
                                                   setisChecked(false);
                                                   setPaymentgateway("cod");
                                                }
                                             }}
                                             type="radio"
                                             value="cash"
                                             id="cash-on"
                                             name="fav_language"
                                          />{" "}
                                          <label id="digital" for="cash-on">
                                             Cash on Delivery
                                          </label>
                                       </div>
                                       {errorPayment === true && (
                                          <p style={{ color: "red" }}>
                                             Please select one of the payment method!
                                          </p>
                                       )}
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="cart-container_yourorders mb-3">
                        <p className="cart-container_yourorders_title ps-3">Your Orders</p>
                        <hr />

                        <div>
                           {/* mapping of cart items */}
                           {Object.entries(cartitems)?.map(([key, items]) => {
                              return (
                                 <div>
                                    <div className="cart-container_yourorders_box">
                                       <div className="checkbox ms-3">
                                          <input
                                             type="checkbox"
                                             name="product"
                                             id="product"
                                             checked={selectedCartItems?.includes(
                                                items?.productId?._id,
                                             )}
                                             onChange={() => {
                                                setRememberme(false);
                                                selectedCartItems?.includes(items?.productId?._id)
                                                   ? setSelectedCartItems(
                                                        selectedCartItems?.filter(
                                                           (item) => item !== items?.productId?._id,
                                                        ),
                                                     )
                                                   : setSelectedCartItems((oldArray) => [
                                                        ...oldArray,
                                                        items?.productId?._id,
                                                     ]);
                                             }}
                                          />
                                       </div>
                                       <div className="cart-container_yourorders_box_img">
                                          <img
                                             src={`${process.env.REACT_APP_FILE_URL}/${items?.productId?.featured_image}`}
                                             alt="Product"
                                          ></img>
                                       </div>
                                       <div className="cart-container_yourorders_description">
                                          <p className="cart-container_yourorders_description_name">
                                             {items?.productId?.name}{" "}
                                          </p>
                                          {items?.color && (
                                             <p className="cart-container_yourorders_description_color">
                                                Colors: {items?.color}
                                             </p>
                                          )}

                                          <p className="cart-container_yourorders_description_price">
                                             Rs.{" "}
                                             {(items?.productId?.sale?.sale_price
                                                ? items?.productId?.sale?.sale_price
                                                : items?.productId?.price
                                             )?.toLocaleString("hi-IN")}{" "}
                                             X {items?.quantity}{" "}
                                          </p>
                                          <div className="cart-container_yourorders_box_description_pricecontainer">
                                             <p className="cart-container_yourorders_description_price">
                                                <button
                                                   variant="secondary"
                                                   className={
                                                      limitexceeds
                                                         ? "increase-cart-disable"
                                                         : "increase-cart"
                                                   }
                                                   onClick={() => handleDecrease(items)}
                                                >
                                                   -
                                                </button>{" "}
                                                {items.quantity}
                                                <button
                                                   variant="secondary"
                                                   className={
                                                      limitexceedsincrease
                                                         ? "increase-cart-disable"
                                                         : "increase-cart"
                                                   }
                                                   onClick={() => handleIncrease(items)}
                                                >
                                                   +
                                                </button>
                                                <img
                                                   id="delete_cart"
                                                   onClick={() =>
                                                      // () => removeFromCart(items.productId)
                                                      CartdeleteModalopen(items?.productId)
                                                   }
                                                   src={deleteicon}
                                                   alt=""
                                                />
                                             </p>
                                          </div>
                                       </div>
                                    </div>

                                    {items?.productId?.stock_quantity <= 0 ? (
                                       <p
                                          style={{
                                             marginTop: "10px",
                                             color: "red",
                                             textAlign: "center",
                                             width: "98%",
                                          }}
                                       >
                                          No items remaining in stock
                                       </p>
                                    ) : items?.quantity > items?.productId?.stock_quantity ? (
                                       <p
                                          style={{
                                             marginTop: "10px",
                                             color: "red",
                                             textAlign: "center",
                                             width: "98%",
                                          }}
                                       >
                                          Only {items?.productId?.stock_quantity} items can be
                                          purchased
                                       </p>
                                    ) : (
                                       ""
                                    )}

                                    <hr />
                                 </div>
                              );
                           })}
                           <div className="cart-container_yourorders_total">
                              <div>
                                 <p className="cart-container_yourorders_total_text">Item Total</p>

                                 <p className="cart-container_yourorders_total_text">
                                    Voucher Discount
                                 </p>
                                 {redeemDiscount > 0 && (
                                    <p className="cart-container_yourorders_total_text">
                                       Reward Discount
                                    </p>
                                 )}
                                 <p className="cart-container_yourorders_total_text">Sub Total</p>
                                 <p className="cart-container_yourorders_total_text">
                                    Shipping Charge
                                 </p>
                                 <p className="cart-container_yourorders_total_text">Total</p>
                              </div>

                              <div
                                 style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "baseline",
                                 }}
                              >
                                 <p className="cart-container_yourorders_total">
                                    Rs. {totalPrice?.toLocaleString("hi-IN")}
                                 </p>

                                 {cartitems.length !== 0 ? (
                                    <p className="cart-container_yourorders_total">
                                       Rs. {discountamount?.toLocaleString("hi-IN")}
                                    </p>
                                 ) : (
                                    <p className="cart-container_yourorders_total">Rs. 0</p>
                                 )}
                                 {redeemDiscount > 0 && (
                                    <p className="cart-container_yourorders_total">
                                       Rs. {redeemDiscount?.toLocaleString("hi-IN")}
                                    </p>
                                 )}
                                 <p className="cart-container_yourorders_total">
                                    Rs. {(totalPrice - newDiscount)?.toLocaleString("hi-IN")}
                                 </p>
                                 <p className="cart-container_yourorders_total">
                                    Rs. {shippingcharge?.toLocaleString("hi-IN")}
                                 </p>
                                 <p className="cart-container_yourorders_total">
                                    <div>
                                       <p className={"cart-container_yourorders_total_sum_text"}>
                                          Rs.{" "}
                                          {(
                                             totalPrice +
                                             shippingcharge -
                                             newDiscount
                                          ).toLocaleString("hi-IN")}
                                       </p>
                                    </div>
                                 </p>
                              </div>
                           </div>

                           {/* {redeemDiscount > 0 && rememberMe === true && (
                              <div className="cart-container_yourorders_total_sum">
                                 <div>
                                    <p className="cart-container_yourorders_total_text">
                                       Reward discount
                                    </p>
                                 </div>
                                 <div>
                                    <p className="cart-container_yourorders_total">
                                       {Math.floor(redeemDiscount).toLocaleString("hi-IN")}
                                    </p>
                                 </div>
                              </div>
                           )} */}
                           {/* {redeemAmounttoPay !== null && rememberMe === true ? (
                              <>
                                 <div className="cart-container_yourorders_total_sum">
                                    <div>
                                       <p className="cart-container-redeem">
                                          New total after redeem
                                       </p>
                                    </div>
                                    <div>
                                       <p
                                          className="cart-container_yourorders_total"
                                          id="cart-container-price"
                                       >
                                          {redeemAmounttoPay !== null && rememberMe === true
                                             ? Math.floor(
                                                  redeemAmounttoPay + shippingcharge,
                                               ).toLocaleString("hi-IN")
                                             : 0}
                                       </p>
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <></>
                           )} */}

                           <div>
                              {/* section for applying discount voucher */}
                              <InputGroup className="mb-3" id="voucher-button">
                                 <FormControl
                                    placeholder="Enter voucher code"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    value={vouchername}
                                    name="vouchername"
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                       if (
                                          e?.nativeEvent?.data?.toString()?.match("[A-Za-z0-9-, ]")
                                       ) {
                                          handleChange(e);
                                       }
                                       if (e?.nativeEvent?.data === null) {
                                          handleChange(e);
                                       } else {
                                          handleChange(e);
                                       }
                                    }}
                                 />
                                 {/* {console.log(
                                    "length",
                                    selectedCartItems?.length && vouchername === "",
                                 )} */}
                                 <Button
                                    variant="outline-secondary"
                                    id="button-addon2"
                                    // className="apply-button"
                                    className={
                                       vouchername === "" || selectedCartItems?.length === 0
                                          ? "apply-button"
                                          : "apply-button-green"
                                    }
                                    onClick={() => {
                                       vouchername !== "" &&
                                          selectedCartItems?.length !== 0 &&
                                          sendVoucher();
                                    }}
                                 >
                                    Apply
                                 </Button>
                              </InputGroup>
                              {errorvoucher ? (
                                 <Alert
                                    variant="danger"
                                    style={{ width: "249px", marginLeft: "52px" }}
                                 >
                                    <MdError />
                                    &nbsp;&nbsp; {vouchermessage}
                                 </Alert>
                              ) : (
                                 ""
                              )}
                              {successVoucher ? (
                                 <Alert
                                    variant="success"
                                    style={{ width: "249px", marginLeft: "52px" }}
                                 >
                                    <MdError />
                                    &nbsp;&nbsp;{vouchermessage}
                                 </Alert>
                              ) : (
                                 ""
                              )}
                           </div>
                           <div>
                              <RewardPoint
                                 totalPrice={
                                    discountamount ? totalPrice - discountamount : totalPrice
                                 }
                                 shippingcharge={shippingcharge}
                                 discountamount={discountamount}
                                 handleReedem={handleReedem}
                                 rememberMe={rememberMe}
                                 redeemTotal={redeemTotal}
                              />
                           </div>

                           {onlineMethod === true &&
                           selectShipping &&
                           stockError === false &&
                           selectedCartItems?.length !== 0 ? (
                              <Button
                                 variant="secondary"
                                 className="totalsum_btn"
                                 onClick={handleOrderCart}
                              >
                                 Place Order
                              </Button>
                           ) : selectShipping &&
                             selectPay &&
                             stockError === false &&
                             selectedCartItems?.length !== 0 ? (
                              <>
                                 <Button
                                    variant="secondary"
                                    className="totalsum_btn"
                                    onClick={handleOrderCart}
                                 >
                                    Place Order
                                 </Button>
                              </>
                           ) : (
                              <Button
                                 // disabled
                                 variant="secondary"
                                 style={{ backgroundColor: "grey" }}
                                 className="totalsum_btn_disabled"
                                 onClick={handlePaymentCheck}
                              >
                                 Place Order
                              </Button>
                           )}

                           <div>
                              <RemainingReward
                                 totalPrice={totalPrice}
                                 newTotalprice={redeemAmounttoPay}
                                 shippingcharge={shippingcharge}
                                 discountamount={discountamount}
                                 rememberMe={rememberMe}
                              />
                           </div>
                           <div></div>
                           <p className="text_privacy">
                              By placing your order, you agree to our company
                              <a href="/privacy-policy" target="_blank">
                                 {" "}
                                 Privacy Policy
                              </a>{" "}
                              and
                              <a href="pages/termsandcondition" target="_blank">
                                 {" "}
                                 Terms and Conditions
                              </a>
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            ) : (
               <div
                  style={{
                     marginBottom: "50px",
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <p
                     style={{
                        marginTop: "80px",
                        textAlign: "center",
                        fontSize: "24px",
                        color: "grey",
                     }}
                  >
                     No items in cart
                  </p>
                  <Button
                     onClick={() => {
                        navigate("/store");
                     }}
                     variant="secondary"
                     className="red"
                     style={{
                        backgroundColor: "red",
                        border: "none",
                        width: "fit-content",
                        marginTop: "25px",
                     }}
                  >
                     Continue Shopping
                  </Button>
               </div>
            )}
         </Layout>
      </div>
   );
};

export default Cart;
