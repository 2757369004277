import React, { useState, useEffect } from "react";
import "../../../scss/packages/feature.scss";
import { get_why_dishhome } from "../../../data/api";
import { useNavigate } from "react-router";

const FeatureSection = ({ category }) => {
   let navigate = useNavigate();

   const [bannerdata, setBannerData] = useState("");
   useEffect(() => {
      async function get_banner() {
         const res = await get_why_dishhome();
         const response = res.data.data;
         setBannerData(response);
      }
      get_banner();
   }, []);
   return (
      <>
         {Object.entries(bannerdata).map(([key, value]) => {
            return (
               <div key={key}>
                  {value.page === "Tv_4k" && category === "4k" ? (
                     <>
                        <div className="feature_wrapper">
                           <div className="feature_header fixed-width my-5 ">
                              <h1>{value.title}</h1>
                           </div>
                           <div className="feature_container mb-3 fixed-width">
                              <div className="feature_image p-3">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="Feature"
                                 />
                              </div>
                              <div className="feature_text px-5 py-5">
                                 {Object.entries(value?.whyDishhome).map(([key, item]) => {
                                    return (
                                       <div className="text_section">
                                          <h1>{item.title}</h1>
                                          <p>{item.description}</p>
                                       </div>
                                    );
                                 })}
                                 {category === "4k" && (
                                    <button
                                       onClick={() => navigate(value?.link)}
                                       className="order_button "
                                    >
                                       Get Now ➔
                                    </button>
                                 )}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : null}
                  {value.page === "Tv_dth" && category === "dishhome-dth" ? (
                     <>
                        <div className="feature_wrapper">
                           <div className="feature_header fixed-width my-5 ">
                              <h1>{value.title}</h1>
                           </div>
                           <div className="feature_container mb-3 fixed-width">
                              <div className="feature_image p-3">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="Feature"
                                 />
                              </div>
                              <div className="feature_text px-5 py-5">
                                 {Object.entries(value.whyDishhome).map(([key, item]) => {
                                    return (
                                       <div className="text_section">
                                          <h1>{item.title}</h1>
                                          <p>{item.description}</p>
                                       </div>
                                    );
                                 })}

                                 {category === "dishhome-dth" && (
                                    <button
                                       onClick={() => navigate(value?.link)}
                                       className="order_button "
                                    >
                                       Get Now ➔
                                    </button>
                                 )}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : null}
                  {value.page === "Tv_itv" && category === "itv" ? (
                     <>
                        <div className="feature_wrapper">
                           <div className="feature_header fixed-width my-5 ">
                              <h1>{value.title}</h1>
                           </div>
                           <div className="feature_container mb-3 fixed-width">
                              <div className="feature_image p-3">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="Feature"
                                 />
                              </div>
                              <div className="feature_text px-5 py-5">
                                 {Object.entries(value.whyDishhome).map(([key, item]) => {
                                    return (
                                       <div className="text_section">
                                          <h1>{item.title}</h1>
                                          <p>{item.description}</p>
                                       </div>
                                    );
                                 })}

                                 {category === "itv" && (
                                    <button
                                       onClick={() => navigate(value?.link)}
                                       className="order_button "
                                    >
                                       Get Now ➔
                                    </button>
                                 )}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : null}
                  {value.page === "Tv_dvb" && category === "dvb" ? (
                     <>
                        <div className="feature_wrapper">
                           <div className="feature_header fixed-width my-5 ">
                              <h1>{value.title}</h1>
                           </div>
                           <div className="feature_container mb-3 fixed-width">
                              <div className="feature_image p-3">
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${value.image}`}
                                    alt="Feature"
                                 />
                              </div>
                              <div className="feature_text px-5 py-5">
                                 {Object.entries(value.whyDishhome).map(([key, item]) => {
                                    return (
                                       <div className="text_section">
                                          <h1>{item.title}</h1>
                                          <p>{item.description}</p>
                                       </div>
                                    );
                                 })}

                                 {category === "dvb" && (
                                    <button
                                       onClick={() => navigate(value?.link)}
                                       className="order_button "
                                    >
                                       Get Now ➔
                                    </button>
                                 )}
                              </div>
                           </div>
                        </div>
                     </>
                  ) : null}
               </div>
            );
         })}
      </>
   );
};

export default FeatureSection;
