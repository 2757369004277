import axios from "axios";
// import { baseUrl } from "../constants/defaultValue";

export const base = () => {
   const data = {
      baseURL: process.env.REACT_APP_BASE_URL + "v1",
      headers: {
         "content-type": "application/json",
         Token: process.env.REACT_APP_API_SECRET_TOKEN,
      },
   };
   return axios.create(data);
};

//data api calls

//#region user
//register
export const register = async (name, phone, password, confirm_password, email, recaptcha) => {
   return await base()
      .post(
         "/auth/register",
         JSON.stringify({
            name: name,
            phone: phone,
            password: password,
            confirm_password: confirm_password,
            email: email,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//newsletter
export const newsletter = async (email, recaptcha) => {
   return await base()
      .post("/newsletter/subscribe", {
         email: email,
         recaptcha: recaptcha,
      })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//login
export const login = async (phone, password, flag, recaptcha) => {
   return await base()
      .post(
         "/auth/login",
         JSON.stringify({
            phone: phone,
            password: password,
            stay_signed_in: flag,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//facebook
export const facecook_login = async () => {
   return await base()
      .post("/auth/facebook")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//google
export const google_login = async () => {
   return await base()
      .post("/auth/google")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Forgot password
export const forgot_password = async (phone, recaptcha) => {
   return await base()
      .post("auth/forgot-password", JSON.stringify({ phone: phone, recaptcha: recaptcha }))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//otp login
export const otp_login = async (phone, recaptcha, loginType) => {
   const postData =
      loginType === "customerID"
         ? {
              loginType: loginType,
              customer_id: phone,
              recaptcha: recaptcha,
           }
         : {
              phone: phone,
              recaptcha: recaptcha,
           };
   return await base()
      .post("auth/login-with-otp", JSON.stringify(postData))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get your ott

//Forgot Password Verify Otp
export const forgot_password_verify_otp = async (phone, otp, recaptcha) => {
   return await base()
      .post("auth/forgot-password-verify-otp", { phone: phone, otp: otp, recaptcha: recaptcha })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Frogot Password Change PW
export const forgot_password_change_pw = async (resetToken, pw, conf_pw, recaptcha) => {
   return await base()
      .post(
         "auth/change-password",
         JSON.stringify({
            resetToken: resetToken,
            password: pw,
            confirm_password: conf_pw,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//verify otp
export const verify_otp = async (phone, otp, recaptcha, type) => {
   const postData =
      type === "customerID"
         ? {
              customer_id: phone,
              otp: parseInt(otp),
              recaptcha: recaptcha,
              type: type,
           }
         : { phone: phone, otp: parseInt(otp), recaptcha: recaptcha };
   return await base()
      .post("auth/verify-otp", JSON.stringify(postData))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//resend otp
export const resend_otp = async (phone) => {
   return await base()
      .post("auth/resend-otp", JSON.stringify({ phone: phone }))
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//#endregion

//#region banner
export const get_all_banner = async () => {
   return await base()
      .get("/banner/get-banner/home")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//#endregion

//#region Navigation

//get navigation

export const get_all_navigation = async () => {
   return await base()
      .get("/navigation/get-all-navigation")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//get all product category

//get single products by category
export const get_products_by_category = async (id) => {
   return await base()
      .get("/shop/product-category/" + id + "/products")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//view count of product
export const get_product_count = async (id) => {
   return await base()
      .get("/shop/product/" + id + "/count")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//#endregion

//#region Tv package

//get all channel
export const get_all_channel = async () => {
   return await base()
      .get("/channel/get-channels")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get package
export const get_tv_package = async () => {
   return await base()
      .get("/package/get-packages/")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get tv package category
export const get_tv_package_category = async (title) => {
   return await base()
      .get("/category/get-category/" + title)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//order tv package

//#endregion

//#endregion

//Home page
//banner
export const get_banner = async () => {
   return await base()
      .get("/banner/get-banner/home")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Dishhome Go package banner
export const get_dishhome_go_package_banner = async () => {
   return await base()
      .get("/banner/get-dishhomego-package-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Get Dishhome GO Package
export const get_dishhome_go_package = async (page) => {
   return await base()
      .get(`/ott-package/packages/getactive?limit=12&page=${page}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_dishhome_go_sub_package = async (page, type) => {
   return await base()
      .get(`/ott-package/subPackages/getactive?limit=12&page=${page}&type=${type}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_dishhome_go_settings = async () => {
   return await base()
      .get(`/ott-setting/get`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Get Dishhome GO INDV Package
export const get_dishhome_go_indv_package = async (id) => {
   return await base()
      .get(`/ott-package/packages/${id}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//STORE PAGE
//shop by category->store page

export const get_popular_category = async (title) => {
   return await base()
      .get("/shop/product")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//get all category data
export const get_all_category = async () => {
   return await base()
      .get("/shop/product-category/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get single category
export const get_single_category = async (id) => {
   return await base()
      .get("/shop/product-category/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//new arrival->store page
export const get_new_arrival = async (title) => {
   return await base()
      .get("/shop/new-arrivals")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//SALES
export const get_sales = async (brand, brandcolor, minprice, maxprice) => {
   return await base().get(
      `/shop/sale/products/get?limit=&page=1&name=&sale=&category=&brand=${brand}&color=${brandcolor}&minPrice=${minprice}&maxPrice=${maxprice}`,
   );
};

//GET ALL PRODUCTS
export const get_all_products = async (title) => {
   return await base()
      .get("/shop/product")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//featured product
export const get_featured_product = async (title) => {
   return await base()
      .get("/shop/featured-products")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
export const get_product_category = async (title) => {
   return await base()
      .get("/shop/product-category")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//hot deals
export const get_hot_deals = async (title) => {
   return await base()
      .get("/shop/hot-deals")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//single product

export const get_single_product = async (id) => {
   return await base()
      .get("/shop/product/" + id)
      .then(function (response) {
         return response;
         // console.log(response);
      })
      .catch(function (error) {
         return error;
      });
};

//single internet
export const get_single_internet = async (id) => {
   return await base()
      .get("/internet/get-internet-package/" + id)
      .then(function (response) {
         return response;
         // console.log(response);
      })
      .catch(function (error) {
         return error;
      });
};
//single internet
export const get_single_tv = async (id) => {
   return await base()
      .get("/package/get-package/" + id)
      .then(function (response) {
         return response;
         // console.log(response);
      })
      .catch(function (error) {
         return error;
      });
};
export const get_all_product = async () => {
   return await base()
      .get("/shop/product/")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//#region PPV Movies

export const get_all_movies = async () => {
   return await base()
      .get("/ppv-movie/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_itv_ppv_movies = async () => {
   return await base()
      .get("/dh-itv/movies")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_itv_ppv_movies_by_id = async (id) => {
   return await base()
      .get("/dh-itv/movies/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_all_ppv_movies = async () => {
   return await base()
      .get("/ppv-movie/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get single_ppv_movie
export const get_single_ppv_movie = async (id) => {
   return await base()
      .get("/ppv-movie/get/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//INTERNET PAGE
export const get_internet_package = async () => {
   return await base()
      .get("internet/get-internet-packages")
      .then(function (response) {
         return response;
         // console.log(response)
      })
      .catch(function (error) {
         return error;
      });
};

//get home components
export const get_home_components = async () => {
   return await base()
      .get("banner/get-home-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get tv component
export const get_tv_components = async () => {
   return await base()
      .get("banner/get-tv-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get internet component
export const get_internet_components = async () => {
   return await base()
      .get("banner/get-internet-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get OTT comonent
export const get_Ott_components = async () => {
   return await base()
      .get("banner/get-ott-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

// get ppv banner
export const get_ppv_banner = async () => {
   return await base()
      .get("banner/get-ppv-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_itv_banner = async () => {
   return await base()
      .get("banner/get-itv-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get Internet Recommended

export const get_internet_recommended = async () => {
   return await base()
      .get("recommend/list?page=1&limit=3")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//cart
export const get_cart_items = async () => {
   return await base()
      .get("/shop/cart")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get store component
export const get_store_components = async () => {
   return await base()
      .get("banner/get-store-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get individual package

export const get_individual_package = async (id) => {
   return await base()
      .get("package/get-package/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//DELETE CART
export const delete_cart_items = async (id) => {
   return await base()
      .delete("/shop/cart")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get faq category

export const get_faq_category = async () => {
   return await base()
      .get("/faq-category/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get faq

export const get_faq = async () => {
   return await base()
      .get("/faq/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get tutorial

export const get_tutorial = async (page, keyword) => {
   return await base()
      .get(`/video-tutorial/get-public?page=${page}&limit=9&keyword=${keyword}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get download_item

export const get_download_item = async (page) => {
   return await base()
      .get(`/download/get/public?page=${page}&limit=6`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get download file

export const get_download_file = async (id) => {
   return await base()
      .get("/download/" + id, { responseType: "blob" })
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//api
export const get_agent_details = async (keyword, pageNumber, limit, name, phone, address, type) => {
   return await base()
      .get(`/agent/get?&page=1&limit=5&name=${name}&phone=&address=&type=`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//color
export const get_colors = async (k) => {
   return await base()
      .get("/shop/color/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//post contact-us
export const post_contact_us = async (nature, name, email, phone, subject, recaptcha) => {
   return await base()
      .post(
         "/contact-us/contact",
         JSON.stringify({
            nature_of_enquiry: nature,
            name: name,
            email: email,
            phone: phone,
            subject: subject,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get career_opportunity

export const get_career_opportunity = async (keyword) => {
   return await base()
      .get(`career/list-active-careers?keyword=${keyword}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//search product
export const search_product = async (productsearch, currentPage, limit) => {
   return await base()
      .get(`search/product?page=${currentPage}&limit=${limit}&keyword=${productsearch}`)

      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//filter product
export const filter_product = async (
   limit,
   currentPage,
   category,
   brand,
   brandcolor,
   tags,
   minprice,
   maxprice,
   saleName,
   sortBy,
   orderBy,
) => {
   return await base()
      .get(
         `shop/get?limit=${limit}&page=${currentPage}&name=&category=${category}&tags=${tags}&brand=${brand}&color=${brandcolor}&minPrice=${minprice}&maxPrice=${maxprice}&sale=${saleName}&sortBy=${sortBy}&orderBy=${orderBy}`,
      )

      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
//get career_category

export const get_career_category = async () => {
   return await base()
      .get("career-category/list-career-categories/?limit=100")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get single_career

export const get_single_career = async (id) => {
   return await base()
      .get("career/get-career/" + id)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//apply for job

export const apply_for_job = async (id, name, gender, email, phone, resume, answers, recaptcha) => {
   const formdata = new FormData();
   formdata.append("applied_for", id);
   formdata.append("name", name);
   formdata.append("email", email);
   formdata.append("phone", phone);
   formdata.append("gender", gender);
   formdata.append("resume", resume);
   formdata.append("answers", JSON.stringify(answers));
   formdata.append("recaptcha", recaptcha);
   return await base()
      .post("career-applied/apply", formdata)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get ppv movies

//get welcome page
export const get_welcome_page = async () => {
   return await base()
      .get("welcome-page/get-welcome-page")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get brand
export const get_brand = async () => {
   return await base()
      .get("shop/brand/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get active sale
export const get_active_sale = async () => {
   return await base()
      .get("shop/sale/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get produt tag
export const get_product_tag = async () => {
   return await base()
      .get("shop/tag/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//product of category page
export const get_all_category_product = async () => {
   return await base()
      .get("shop/product?limit=10&page=1")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_shipping_charge = async () => {
   return await base()
      .get("/shop/shipping-charge/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_why_dishhome = async () => {
   return await base()
      .get("/whyDishhomeCategory/list")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_aboutus = async () => {
   return await base()
      .get("/about")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const bod_message = async () => {
   return await base()
      .get("/testimonial/list")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const internet_requests = async (
   internetType,
   companyName,
   contactName,
   email,
   phone,
   requirement,
   location,
   remarks,
   recaptcha,
) => {
   return await base()
      .post(
         "/internet-request",
         JSON.stringify({
            internetType: internetType,
            companyName: companyName,
            contactName: contactName,
            email: email,
            phone: phone,
            requirement: requirement,
            location: location,
            remarks: remarks,
            recaptcha: recaptcha,
         }),
      )
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_company_details = async () => {
   return await base()
      .get("company/get-details")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const enterprise_data = async () => {
   return await base()
      .get("enterprise?type=Enterprise")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
export const sme_data = async () => {
   return await base()
      .get("enterprise?type=SME")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_refer_component = async () => {
   return await base()
      .get("refer-page")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get support category
export const get_support_category = async () => {
   return await base()
      .get("support/fetch-categories")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get enterprise issue type
export const get_enterprise_issue_type = async () => {
   return await base()
      .get("support/fetch-enterprise-issue")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get support sub category
export const get_support_sub_category = async (id) => {
   return await base()
      .get(`support/fetch-sub-categories/${id}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get static page
export const get_static_page = async (id) => {
   return await base()
      .get(`custom-pages/public/${id}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get all static page
export const get_all_static_page = async () => {
   return await base()
      .get("custom-pages/get")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get category data

export const get_category_data = async (id) => {
   return await base()
      .get(`category/get-category/${id}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const get_category = async () => {
   return await base()
      .get("category/get-categories")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get referbanner
export const get_refer_banner = async () => {
   return await base()
      .get("banner/get-refer-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get career banner
export const get_career_banner = async () => {
   return await base()
      .get("banner/get-career-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get enterprise banner
export const get_enterprise_banner = async () => {
   return await base()
      .get("banner/get-enterprise-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get sme banner
export const get_sme_banner = async () => {
   return await base()
      .get("banner/get-sme-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get contact us banner
export const get_contact_us_banner = async () => {
   return await base()
      .get("banner/get-contact-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get agent Type
export const get_agent_type = async () => {
   return await base()
      .get("agent-type/active")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//Locate a Dealer

export const locate_dealer = async (name, address, type, page, province, district) => {
   return await base().get(
      `agent/get?&page=${page}&limit=5&name=${name}&phone=&address=${address}&type=${type}&province=${province}&district=${district}`,
   );
};

//get combo type
export const get_combo_type = async () => {
   return await base()
      .get("internet/get-combo-types")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get footer Banner

export const get_footer_banner = async () => {
   return await base()
      .get("banner/get-footer-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get email list
export const get_email_list = async () => {
   return await base()
      .get("/email/list")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get latest offer
export const get_latest_offers = async () => {
   return await base()
      .get("/offers")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get single offer
export const get_single_offer = async (id) => {
   return await base()
      .get(`/offers/get/${id}`)
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

//get faq banner
export const get_faq_banner = async () => {
   return await base()
      .get("banner/get-faq-banners")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};

export const check_status = async () => {
   return await base()
      .get("/status")
      .then(function (response) {
         return response;
      })
      .catch(function (error) {
         return error;
      });
};
