import React, { useRef } from "react";
import "../../scss/pages/login.scss";
import "../../scss/pages/form.scss";
// import Logo from "../include/logo";

import Login_img from "../../assets/refer.png";
import mobile from "../../assets/mobile.png";

import { AiOutlineEyeInvisible } from "react-icons/ai";
// import Cookies from "js-cookie";
import { useState } from "react";
import { login, resend_otp } from "../../data/api";
// import AuthContext from "../../context/AuthProvider";
import Layout from "../include/layout";
import { Alert } from "react-bootstrap";
import { MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useToken from "../../utils/useToken";
import MessageModal from "../customcomponents/messageModal";
import SeoTags from "../../utils/seoTags";
import Widthcontainer from "../customcomponents/widthcontainer";
import ReactGA from "react-ga4";
import Captcha from "../include/captcha";

const Login = () => {
   //Set  password and username and show password use state
   //Variables
   const { setToken } = useToken();
   const [showPassword, setshowPassword] = useState(false);
   const [Password, setPassword] = useState("");
   const [Email, setEmail] = useState("");
   const [ErrorMessage, setErrorMessage] = useState("");
   const [ErrorStatus, setErrorStatus] = useState(false);
   const [rememberMe, setRememberMe] = useState(false);
   const [messageModal, setMessageModal] = useState(false);
   const reRef = useRef();
   //used to change state of show password
   const togglePassword = () => {
      setshowPassword(!showPassword);
   };
   let navigate = useNavigate();
   //setting up Auth Context
   // const { auth, setAuth } = useContext(AuthContext);
   //for button state
   const [btnState, setBtnState] = useState(false);
   //used to login and store access token and refresh token
   // console.log(Email?.slice(0, 4) === "+977");
   const handleSubmit = async (e) => {
      setErrorStatus(false);
      setBtnState(true);
      e.preventDefault();
      const captchaToken = await reRef.current.executeAsync();
      // console.log("Token", captchaToken);
      const response = await login(
         Email?.length > 10 ? (Email?.slice(0, 4) === "+977" ? Email?.slice(4, 18) : Email) : Email,
         Password,
         rememberMe,
         captchaToken,
      );
      // console.log(response);
      if (response?.status === 200) {
         const accessToken = response?.data?.token?.accessToken;
         const refreshToken = response?.data?.token?.refreshToken;
         const expiresIn = response?.data?.token?.expiresIn;
         const user = response?.data?.user;
         // setting accessToken and RefreshToken
         // setAuth({ accessToken, refreshToken, expiresIn });
         // console.log(auth);
         // console.log(accessToken);
         // console.log(refreshToken);
         // console.log(expiresIn);
         // ReactGA.event({
         //    category: "user",
         //    action: "Logged in",
         //    label: "USer Login in tracking", // optional
         //    nonInteraction: false, // optional, true/false
         //    transport: "xhr",
         // });
         setToken({ accessToken, refreshToken, expiresIn, user }, rememberMe);
         navigate(-1);
      } else if (response.response.data.message === "Phone number not verified") {
         await resend_otp(Email);
         setMessageModal(true);
      } else {
         setErrorStatus(true);
         if (Email === "") {
            setErrorMessage("Phone Number is required");
         } else if (Password === "") {
            setErrorMessage("Password is required");
         } else setErrorMessage(response?.response?.data?.message);
         setBtnState(false);
      }
   };

   return (
      <Layout>
         <MessageModal
            show={messageModal}
            setShow={setMessageModal}
            heading="Verification code sent"
            message="Phone number not verified"
            buttonText="Verify"
            link={"/otp-verify/" + Email}
         />
         {SeoTags(
            "Login | DishHome nepal",
            "DishHome Login",
            "Login to DishHome",
            "Login to DishHome",
            "Login to DishHome",
            `${process.env.REACT_APP_PUBLIC_URL}login`,
         )}
         <Widthcontainer>
            <div className={"Login_Wrapper mt-2 mb-4 fixed-width"}>
               <div className={"Login_Form"}>
                  {/* <Logo /> */}

                  <div className={"Login_Container"}>
                     <h1 className={"Login_Header mt-3"}>Login to DishHome</h1>
                     <h3 className={"Register_Header "}>
                        Don't have an account? <a href="/signup">Register</a>
                     </h3>
                     <label htmlFor="number">Phone Number</label>
                     <input
                        type="tel"
                        name="number"
                        className={"Email_Input"}
                        maxLength="14"
                        placeholder=""
                        autoComplete="on"
                        value={Email}
                        onChange={(e) => {
                           if (e?.nativeEvent?.data) {
                              if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                 setEmail(e.target?.value);
                              }
                              if (e?.nativeEvent?.data === null) {
                                 setEmail(e?.target?.value);
                              }
                           } else {
                              setEmail(e?.target?.value);
                           }
                        }}
                     />
                     <div className={"Password_Container"}>
                        <label htmlFor="password">Password</label>

                        <input
                           type={showPassword ? "text" : "password"}
                           name="password"
                           className={"Email_Input"}
                           placeholder=""
                           autoComplete="off"
                           min={"0"}
                           // maxLength={20}
                           onPaste={(e) => {
                              e.preventDefault();
                              return false;
                           }}
                           onChange={(e) => setPassword(e.target.value)}
                           onKeyPress={(e) => {
                              if (e.charCode === 13) {
                                 handleSubmit(e);
                              }
                           }}
                        />
                        {!showPassword && (
                           <AiOutlineEyeInvisible
                              size={19}
                              className={"Pw_Icon"}
                              onClick={togglePassword}
                              style={{}}
                           />
                        )}
                        {showPassword && (
                           <span
                              className={"Pw_Icon"}
                              onClick={togglePassword}
                              style={{ top: "30px" }}
                           >
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M8.00002 2C11.5947 2 14.5854 4.58667 15.2127 8C14.586 11.4133 11.5947 14 8.00002 14C4.40535 14 1.41469 11.4133 0.787354 8C1.41402 4.58667 4.40535 2 8.00002 2ZM8.00002 12.6667C9.35967 12.6664 10.6789 12.2045 11.7419 11.3568C12.8049 10.509 13.5486 9.32552 13.8514 8C13.5475 6.67554 12.8033 5.49334 11.7404 4.64668C10.6776 3.80003 9.35889 3.33902 8.00002 3.33902C6.64115 3.33902 5.32248 3.80003 4.2596 4.64668C3.19673 5.49334 2.45253 6.67554 2.14869 8C2.45142 9.32552 3.19514 10.509 4.25812 11.3568C5.3211 12.2045 6.64037 12.6664 8.00002 12.6667ZM8.00002 11C7.20437 11 6.44131 10.6839 5.8787 10.1213C5.31609 9.55871 5.00002 8.79565 5.00002 8C5.00002 7.20435 5.31609 6.44129 5.8787 5.87868C6.44131 5.31607 7.20437 5 8.00002 5C8.79567 5 9.55873 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55873 10.6839 8.79567 11 8.00002 11ZM8.00002 9.66667C8.44205 9.66667 8.86597 9.49107 9.17853 9.17851C9.49109 8.86595 9.66669 8.44203 9.66669 8C9.66669 7.55797 9.49109 7.13405 9.17853 6.82149C8.86597 6.50893 8.44205 6.33333 8.00002 6.33333C7.55799 6.33333 7.13407 6.50893 6.82151 6.82149C6.50895 7.13405 6.33335 7.55797 6.33335 8C6.33335 8.44203 6.50895 8.86595 6.82151 9.17851C7.13407 9.49107 7.55799 9.66667 8.00002 9.66667Z"
                                    fill="#374253"
                                 />
                              </svg>{" "}
                           </span>
                        )}
                     </div>
                     <div className={"ForgetPassword_Wrapper"}>
                        <div className={"checkbox_Wrapper"} style={{ display: "flex" }}>
                           <div className="_checkbox">
                              <label class="myCheckbox">
                                 <input
                                    type="checkbox"
                                    name="remember"
                                    id="remember"
                                    className="CheckBox"
                                    checked={rememberMe}
                                    style={{ cursor: "pointer" }}
                                    onChange={(e) => {
                                       setRememberMe(e.target.checked);
                                    }}
                                 />
                              </label>
                              <label htmlFor="remember">Keep me signed in</label>
                           </div>

                           <a href="/forgot-password" className={"ForgetPassword_Link"}>
                              Forgot Password ?
                           </a>
                        </div>
                        <Captcha reRef={reRef} />
                        <Alert
                           variant="danger"
                           className={"Login_Alert" + (ErrorStatus ? "_show" : "")}
                        >
                           {/* <MdError /> */}
                           {ErrorMessage}
                        </Alert>
                        <button
                           className={btnState ? "Signin_Button disabled" : "Signin_Button"}
                           onClick={handleSubmit}
                        >
                           {btnState ? "Loading..." : "Log In"}
                        </button>
                        <div className="otp-login">
                           <div>
                              <img
                                 style={{ width: "38px", height: "38px" }}
                                 src={mobile}
                                 alt="Otp Login Icon"
                              />
                           </div>

                           <p>
                              Don’t have a password setup yet? You can also login with{" "}
                              <a
                                 id="otp-href"
                                 onClick={() => navigate("/otp-login", { replace: true })}
                              >
                                 {" "}
                                 Phone Number
                              </a>{" "}
                              or{" "}
                              <a
                                 id="otp-href"
                                 onClick={() => navigate("/customer-id-login", { replace: true })}
                              >
                                 {" "}
                                 Customer ID
                              </a>
                           </p>
                        </div>
                        {/* <p
                           style={{
                              fontSize: "14px",
                              marginTop: "18px",
                              marginBottom: "18px",
                              marginRight: "25px",
                              marginLeft: "25px",
                              color: "grey",
                           }}
                        >
                           -------------- Or, login with---------------
                        </p> */}
                        {/* <div
                           onClick={() => navigate("/otp-login", { replace: true })}
                           className={"ForgetPassword_Link cursor_pointer"}
                           id="verification-code-btn"
                        >
                           Verification Code{" "}
                        </div> */}
                     </div>
                  </div>
               </div>
               <div className={"Login_Image"}>
                  <img src={Login_img} alt="" />
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};
export default Login;
