import React from "react";
import "../../scss/components/slidebanner.scss";
import Carousel from "react-bootstrap/Carousel";
// import banner from "../../assets/banner.png";
import imagebanner from "../../assets/imagebanner.png";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import TrackVisibility from "react-on-screen";
import ReactPlayer from "react-player";
import WidthContainer from "../../components/customcomponents/widthcontainer";
import { useMediaQuery } from "react-responsive";

const ComponentToTrack = (props) => {
   let navigate = useNavigate();
   const mobile = useMediaQuery({ query: "(max-width: 767px)" });
   // console.log(
   //    "New",
   //    props?.data?.mobile_media_type == "video" && props?.data?.mobile_media && mobile
   //       ? props?.data?.mobile_media
   //       : props?.data?.media,
   // );
   return (
      <div
         className="slideBanner__player-wrapper"
         style={{
            height: "25px",
         }}
      >
         <ReactPlayer
            url={`${process.env.REACT_APP_FILE_URL}/${
               props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                  ? props?.data?.mobile_media
                  : props?.data?.media
            }`}
            playing={props.isVisible}
            loop={true}
            width="100%"
            height="100%"
            className="react-player"
            muted={
               props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                  ? props?.data?.mobile_mute
                  : props?.data?.mute
            }
            style={{
               position: "absolute",
               top: "0",
               right: "0",
               zIndex: "1",
               pointerEvents: "none",
               objectFit: "cover",
            }}
            config={{
               youtube: {
                  playerVars: {
                     autoplay: 1,
                     rel: 0,
                     controls: 0,
                     showinfo: 0,
                     disablekb: 1,
                     modestbranding: 1,
                  },
               },
            }}
            playsinline
         />
      </div>
   );
};

const SlideBanner = ({ banners }) => {
   //React Router Hooks
   let navigate = useNavigate();
   const mobile = useMediaQuery({ query: "(max-width: 767px)" });
   return (
      <div>
         <Carousel>
            {banners?.map((data) => (
               <Carousel.Item interval={5000}>
                  <img
                     className="slideBanner_img d-block w-100"
                     src={
                        data === undefined
                           ? imagebanner
                           : `${process.env.REACT_APP_FILE_URL}/${
                                data?.media_type === "video" && data?.mobile_media_type !== "image"
                                   ? data?.thumbnail
                                   : data?.media === undefined
                                   ? null
                                   : data?.media
                             }`
                     }
                     alt="banner"
                  />
                  <div
                     className="video"
                     style={{
                        background: "none",
                        borderRadius: "0px",
                        height: "0px",
                     }}
                  >
                     <TrackVisibility>
                        <ComponentToTrack data={data} />
                     </TrackVisibility>
                  </div>
                  <Carousel.Caption
                     style={{
                        zIndex: "5",
                        width: "100%",
                        left: "0% !important",
                        right: "0%",
                        top: "50%",
                        transform: "translateY(-50%)",
                     }}
                  >
                     <WidthContainer>
                        <div className="fixed-width" style={{ marginLeft: "55px" }}>
                           <p
                              className="carousel-title"
                              style={{
                                 color: data?.text_color,
                              }}
                           >
                              {/* The Future
                           <br /> <span id="dishome">DishHome</span> Hits
                            <p>Different</p> */}
                              {data?.caption === undefined ? (
                                 <p style={{ color: "#FFF", fontSize: "65px" }}>
                                    The Future <br /> <span id="dishome">DishHome</span> Hits
                                    Different
                                 </p>
                              ) : (
                                 data?.caption
                              )}
                           </p>
                           <p className="carousel-para" id="hd" style={{ color: data?.text_color }}>
                              {data?.sub_caption === undefined ? "dishhome" : data?.sub_caption}
                           </p>
                           {data?.button_type !== "" && (
                              <>
                                 <Button
                                    variant="secondary"
                                    className="banner-button"
                                    onClick={() =>
                                       navigate(
                                          data?.external_link === undefined
                                             ? "/internet/residential"
                                             : data?.external_link,
                                       )
                                    }
                                    style={{
                                       color: data?.button_text_color,
                                       backgroundColor: data?.button_bg_color,
                                    }}
                                 >
                                    {/* Get DishHome */}

                                    {data?.button_type === undefined
                                       ? "Get DishHome"
                                       : data?.button_type}
                                 </Button>{" "}
                              </>
                           )}
                        </div>
                     </WidthContainer>
                  </Carousel.Caption>
               </Carousel.Item>
            ))}
         </Carousel>
      </div>
   );
};

export default SlideBanner;
