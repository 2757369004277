import React, { useEffect, useRef, useState } from "react";
// import logo from "../../assets/logo.png";
import cartimage from "../../assets/cartimage.webp";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../../scss/include/navbar.scss";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { AiOutlineDown } from "react-icons/ai";
// import no from "../../assets/no.png";
import notify from "../../assets/notify.png";

import Nav from "react-bootstrap/Nav";
// import { BsFillCartFill } from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import { BiSearchAlt2, BiHeart } from "react-icons/bi";
import { IoCart } from "react-icons/io5";
import { get_all_navigation, get_company_details, get_category } from "../../data/api";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

// import closeIcon from "../../assets/closeIcon.png";

// import { Alert } from "bootstrap";
import { Avatar } from "@mui/material";
import Token from "../../utils/useToken";
import { InputGroup } from "react-bootstrap";
import axios from "axios";
import { MdNotificationsNone } from "react-icons/md";
import { get_wishlist_items } from "../../data/protectedapi";
import { mark_notifiaciton_read } from "../../data/protectedapi";
import { useMediaQuery } from "react-responsive";
import { formatDistanceToNowStrict } from "date-fns";

const Navigation = ({
   cartupdate,
   wishState,
   notification,
   socket,
   setNotification,
   notificationRead,
   setNotificationRead,
}) => {
   const [searchKey, setSearchKey] = useState("");
   const { token, userDetails } = Token();
   const updatetoken = token?.accessToken;
   //wishlist item
   const [wishlist, setWishlist] = useState("");
   const [categoryData, setCategoryData] = useState("");
   const [mobileSearch, setMobileSearch] = useState(false);
   //for image error
   const [imgError, setImgerror] = useState(false);
   const handleImg = () => {
      setImgerror(true);
   };

   const [notificationPanel, setnotificationPanel] = useState(false);

   useEffect(() => {
      async function get_wishlist() {
         const response = await get_wishlist_items();
         // setWishlistupdate(!wishlistupdate);
         setWishlist(response?.data?.data?.wishlist);
      }
      updatetoken && get_wishlist();
   }, [wishState]);

   // useEffect(() => {
   //    if (updatetoken)
   //       axios
   //          .get(process.env.REACT_APP_BASE_URL + "v1/shop/wishlist", config)
   //          .then((response) => {
   //             setWishlist(response?.data?.data?.wishlist);
   //          })

   //          .catch((error) => console.log(error));
   // }, [wishState]);
   //navigation data
   const [navdata, setnavData] = useState("");
   const [profileMenu, setProfileMenu] = useState(false);
   let navigate = useNavigate();
   const location = useLocation();

   //close menu
   const handleClickClosePanelFromOutside = (e) => {
      setProfileMenu(false);
      setnotificationPanel(false);
      // setMobileSearch(false);
   };
   // console.log("notify", notification);

   useEffect(() => {
      if (profileMenu === true || notificationPanel === true) {
         document.body.addEventListener("click", handleClickClosePanelFromOutside);
         return () => {
            document.body.removeEventListener("click", handleClickClosePanelFromOutside);
         };
      }
   }, [profileMenu, notificationPanel, mobileSearch]);

   useEffect(() => {
      async function get_navigation() {
         const res = await get_all_navigation();
         const res2 = await get_category();
         const response = res?.data?.data;
         const response2 = res2?.data?.data;
         setnavData(response);
         setCategoryData(response2);
      }
      get_navigation();
   }, []);

   const myContainer = useRef(null);
   const [openham, setOpenham] = useState(false);
   const openTrue = () => {
      myContainer.current.style.width = "50%";
      myContainer.current.style.display = "block";
   };
   const openFalse = () => {
      setOpenham(!openham);
      myContainer.current.style.width = "0%";
   };
   const openNav = () => {
      setOpenham(!openham);
      searchResponsive && setMobileSearch(false);
      setProfileMenu(false);
      setnotificationPanel(false);
      // eslint-disable-next-line no-lone-blocks
      {
         !openham ? openTrue() : (myContainer.current.style.width = "0%");
      }
   };
   const handleLogout = () => {
      clearToken();
      window.location.href = "/";
   };
   const { clearToken } = Token();

   //Notification read
   const NotificationRead = async (id) => {
      const res = await mark_notifiaciton_read(id);
      notification &&
         Object.entries(notification).map(([key, value]) => {
            if (value.read === true) {
               setNotificationRead(true);
            } else {
               setNotificationRead(false);
            }
            return null;
         });
   };
   const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

   //CART ITEMS
   const [cartitems, setcartItems] = useState("");
   const [cartnumber, setCartNumber] = useState(0);
   useEffect(() => {
      const config = {
         headers: {
            Authorization: `Bearer ${updatetoken}`,
            "Content-Type": "application/x-www-form-urlencoded",
         },
      };
      if (updatetoken)
         axios
            .get(process.env.REACT_APP_BASE_URL + "v1/shop/cart", config)
            .then((response) => {
               setcartItems(response?.data?.data?.cart?.cart_items);
            })
            .catch((error) => console.log(error));
   }, [cartupdate]);

   useEffect(() => {
      let items = 0;
      [...cartitems].forEach((item) => {
         items += item.quantity;
      });
      setCartNumber(items);
   }, [cartitems]);
   const [submitted, setSubmitted] = useState("");
   const handleSearch = (e) => {
      // e.preventDefault();
      setSearchKey(e.target.value);
   };
   const handleSubmit = (e) => {
      setSubmitted(searchKey);
      setSearchKey("");
   };
   const handleKeySubmit = (e) => {
      if (e.key === "Enter") {
         setSubmitted(searchKey);
         setSearchKey("");
      }
   };
   useEffect(() => {
      if (submitted) {
         axios
            .get(`${process.env.REACT_APP_BASE_URL}v1/search?page=1&limit=10&keyword=${submitted}`)

            .then((res) => {
               navigate(`/searchresult?search=${submitted}`);
            })

            .catch((err) => console.log(err));
      }
   }, [submitted]);
   const [contactDetails, setContactDetails] = useState("");
   useEffect(() => {
      async function getCompanyDetails() {
         const response = await get_company_details();
         setContactDetails(response?.data?.data);
      }
      getCompanyDetails();
   }, []);

   //Notification
   useEffect(() => {
      if (socket) {
         socket.on(`USER-${userDetails?.id}`, (data) => {
            setNotification((oldArray) => [data, ...oldArray]);
            setNotificationRead(false);
         });
         socket.on(`USER`, (data) => {
            setNotification((oldArray) => [data, ...oldArray]);
            setNotificationRead(false);
         });
      }
   }, [socket]);
   // const isMobile = useMediaQuery({ query: "(max-width: 860px)" });
   const searchResponsive = useMediaQuery({ query: "(max-width: 600px" });

   return (
      <div className="nav_container">
         <Navbar className="first_nav">
            <Container
               fluid
               className="fixed-width"
               style={
                  isMobile ? { paddingLeft: "var(--bs-gutter-x,.75rem)" } : { paddingLeft: "0px" }
               }
            >
               {isMobile && (
                  <Navbar.Brand style={{ height: "auto" }}>
                     <Link to="" style={{ display: "inline" }}>
                        <GiHamburgerMenu
                           onClick={openNav}
                           height="22px"
                           width="22px"
                           style={{
                              cursor: "pointer",
                              marginRight: "4px",
                              marginLeft: "6px",
                              height: "auto",
                              color: "black",
                           }}
                           className="hamburger-icon"
                        />
                     </Link>
                  </Navbar.Brand>
               )}
               <Navbar.Brand>
                  <Link to="/">
                     <img
                        src={`${process.env.REACT_APP_FILE_URL}/${contactDetails?.image}`}
                        alt="DishHome"
                        title="DishHome"
                        className={"icon"}
                        height="35px"
                        style={{ cursor: "pointer", marginRight: "22px", marginLeft: "5px" }}
                     />
                  </Link>
               </Navbar.Brand>
               <Navbar.Toggle aria-controls="navbarScroll" />
               <Navbar.Collapse id="navbarScroll">
                  <Nav
                     className="me-auto my-2 my-lg-0"
                     style={{ maxHeight: "100px" }}
                     navbarScrolls
                  >
                     {navdata &&
                        Object.entries(navdata).map(([key, value]) => {
                           return (
                              <Link
                                 to={value?.isExternal ? "" : value?.link}
                                 id="top-navlink"
                                 className={
                                    location.pathname
                                       ?.replace(/[\s-]+/g, "")
                                       ?.includes(
                                          value?.title.toLowerCase()?.replace(/[\s\-_']+/g, ""),
                                       )
                                       ? // .includes(value?.title.toLowerCase())
                                         " topnav_active"
                                       : ""
                                 }
                                 onClick={() => {
                                    if (value?.isExternal) window.open(value?.link);
                                 }}
                                 style={isMobile ? { display: "none" } : {}}
                              >
                                 {" "}
                                 {value?.title}
                              </Link>
                           );
                        })}
                  </Nav>
                  <div className="icon-nav">
                     <div>
                        <InputGroup>
                           <InputGroup.Text id="search-locate-icon">
                              <BiSearchAlt2
                                 onClick={(e) => {
                                    handleSubmit(e);
                                    searchResponsive && setMobileSearch(!mobileSearch);
                                    searchResponsive && setnotificationPanel(false);
                                    searchResponsive && setProfileMenu(false);
                                    searchResponsive && openFalse();
                                 }}
                                 fontSize={searchResponsive ? "20px" : "20px"}
                                 style={{ cursor: "pointer" }}
                              />
                           </InputGroup.Text>
                           <FormControl
                              id="search-locate"
                              aria-label="Small"
                              placeholder="Search for"
                              onChange={(e) => handleSearch(e)}
                              onKeyPress={(e) => handleKeySubmit(e)}
                              aria-describedby="inputGroup-sizing-sm"
                           />
                        </InputGroup>
                     </div>
                     {userDetails && (
                        <div
                           className="notification-cart"
                           onClick={() => {
                              setnotificationPanel(!notificationPanel);
                              setProfileMenu(false);
                              setMobileSearch(false);
                              openFalse();
                           }}
                           style={{ height: "auto" }}
                        >
                           <p
                              // to="/notification"

                              style={{
                                 textDecoration: "none",
                                 color: "black",
                                 display: "flex",
                                 alignItems: "center",
                                 position: "relative",
                                 margin: "0",
                                 cursor: "pointer",
                              }}
                              className="notification-icon"
                           >
                              <MdNotificationsNone fontSize={"22px"} title="Notifications" />
                              {notificationRead ? null : (
                                 <div className="wishlist-notification" style={{ right: "12%" }}>
                                    {/* <p>{wishlist.length}</p> */}
                                 </div>
                              )}
                           </p>
                           <div
                              className="notification_panel"
                              style={notificationPanel ? { display: "flex" } : { display: "none" }}
                           >
                              {notification?.length === 0 ? (
                                 <div className="notificationHeader">
                                    <p>No notifications yet!</p>
                                    <div style={{ marginTop: "20px" }}>
                                       {/* <svg
                                          width={160}
                                          height={160}
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 1792 1792"
                                       >
                                          <path
                                             fill="#d85b53"
                                             d="M1430 852q61 356 298 556 0 52-38 90t-90 38h-448q0 106-75 181t-181 75-180.5-74.5T640 1537zm-534 860q16 0 16-16t-16-16q-59 0-101.5-42.5T752 1536q0-16-16-16t-16 16q0 73 51.5 124.5T896 1712zM1898 112q8 10 7.5 23.5T1895 158L23 1780q-10 8-23.5 7t-21.5-11l-84-96q-8-10-7.5-23.5t10.5-21.5l186-161q-19-32-19-66 50-42 91-88t85-119.5 74.5-158.5 50-206T384 576q0-152 117-282.5T808 135q-8-19-8-39 0-40 28-68t68-28 68 28 28 68q0 20-8 39 124 18 219 82.5T1351 375l418-363q10-8 23.5-7t21.5 11z"
                                             class="color000000 svgShape"
                                          />
                                       </svg> */}
                                       <img
                                          style={{ marginTop: "15px" }}
                                          src={notify}
                                          alt="notify"
                                       />
                                    </div>
                                 </div>
                              ) : (
                                 <>
                                    <div className="notificationHeader">
                                       <p className="notificationFooter"> Notifications</p>
                                       <p
                                          className="mark_all_Read cursor_pointer"
                                          onClick={() => {
                                             NotificationRead();
                                          }}
                                       >
                                          Mark All Read
                                       </p>
                                    </div>
                                 </>
                              )}

                              {notification &&
                                 Object.entries(notification)
                                    .slice(0, 4)
                                    .map(([key, value]) => {
                                       return (
                                          <div
                                             className="notificationCard cursor_pointer"
                                             onClick={() => {
                                                navigate(value?.link);
                                                setnotificationPanel(!notificationPanel);
                                                NotificationRead(value?._id);
                                             }}
                                             style={
                                                value?.read
                                                   ? { backeroundColor: "#FFF" }
                                                   : { backgroundColor: "#f5f5f5" }
                                             }
                                          >
                                             <div className="heading">
                                                <p> {value?.sender}</p>{" "}
                                                <p className="time">
                                                   {formatDistanceToNowStrict(
                                                      new Date(value?.createdAt),
                                                   )?.includes("0 seconds")
                                                      ? "a few seconds"
                                                      : formatDistanceToNowStrict(
                                                           new Date(value?.createdAt),
                                                        ) + " ago"}
                                                </p>
                                             </div>
                                             <p className="Content">{value?.message}</p>
                                          </div>
                                       );
                                    })}
                              {notification?.length !== 0 ? (
                                 <div
                                    className="notificationFooter"
                                    onClick={() => {
                                       navigate("/dashboard?type=12");
                                       setnotificationPanel(!notificationPanel);
                                    }}
                                 >
                                    <Link to="/dashboard?type=12">View all</Link>
                                 </div>
                              ) : (
                                 <></>
                              )}
                           </div>
                        </div>
                     )}

                     {contactDetails && contactDetails?.disable_store === false && (
                        <div className="notification-cart ">
                           <div>
                              <NavLink
                                 style={{ textDecoration: "none", display: "inline" }}
                                 to="/cart"
                              >
                                 <img src={cartimage} height="22px" alt="cart icon" title="Cart" />
                              </NavLink>
                           </div>
                           {cartnumber <= 0 ? (
                              <>
                                 <div>
                                    <p
                                       style={
                                          parseInt(cartnumber) > 9
                                             ? { fontSize: "12px" }
                                             : { fontSize: "13px" }
                                       }
                                    ></p>
                                 </div>
                              </>
                           ) : (
                              <>
                                 <div className="notification">
                                    <p
                                       style={
                                          parseInt(cartnumber) > 9
                                             ? { fontSize: "12px" }
                                             : { fontSize: "13px" }
                                       }
                                    >
                                       {cartnumber}
                                    </p>
                                 </div>
                              </>
                           )}
                        </div>
                     )}

                     {contactDetails && contactDetails?.disable_store === false && (
                        <div className="notification-cart">
                           {wishlist?.length > 0 ? (
                              <div style={{ position: "relative" }}>
                                 <NavLink
                                    style={{ textDecoration: "none" }}
                                    to="/dashboard?type=10"
                                 >
                                    <BiHeart
                                       size={24}
                                       style={{ color: "black" }}
                                       title="Wishlist"
                                    />
                                 </NavLink>
                                 <div className="wishlist-notification">
                                    {/* <p>{wishlist.length}</p> */}
                                 </div>
                              </div>
                           ) : (
                              <div>
                                 <NavLink
                                    style={{ textDecoration: "none" }}
                                    to="/dashboard?type=10"
                                 >
                                    <BiHeart
                                       size={24}
                                       style={{ color: "black" }}
                                       title="Wishlist"
                                    />
                                 </NavLink>
                              </div>
                           )}
                        </div>
                     )}

                     <div
                        onClick={() => {
                           token === null
                              ? navigate("/dashboard", { replace: true })
                              : setProfileMenu(!profileMenu);
                           setnotificationPanel(false);
                           setMobileSearch(false);
                           openFalse();
                        }}
                        style={{ cursor: "pointer", position: "relative" }}
                     >
                        {token === null ? (
                           <BsPersonCircle size="20px" />
                        ) : (
                           <>
                              {userDetails?.profile_image === undefined ||
                              userDetails?.profile_image === null ? (
                                 <Avatar
                                    sx={{
                                       bgcolor: "#C30C6B",
                                       height: 28,
                                       width: 28,
                                    }}
                                    style={{ fontSize: "16px", lineHeight: "24px" }}
                                 >
                                    <span> {userDetails?.name?.charAt(0)}</span>
                                 </Avatar>
                              ) : imgError === false ? (
                                 <img
                                    src={`${process.env.REACT_APP_FILE_URL}/${userDetails?.profile_image}`}
                                    alt="profile"
                                    onError={handleImg}
                                    height="35px"
                                    width="35px"
                                    style={{
                                       borderRadius: "50%",
                                       objectFit: "cover",
                                       border: "1px solid black",
                                    }}
                                 />
                              ) : (
                                 <>
                                    <Avatar
                                       sx={{
                                          bgcolor: "#C30C6B",
                                          height: 35,
                                          width: 35,
                                       }}
                                    >
                                       {userDetails?.name?.charAt(0)}
                                    </Avatar>
                                 </>
                              )}
                           </>
                        )}
                        <div
                           className={profileMenu ? "ProfileSubMenu" : "ProfileSubMenu menu_hide"}
                        >
                           <div
                              className="profileSubMenuItem"
                              onClick={() => {
                                 navigate("/dashboard");
                              }}
                           >
                              <svg
                                 width="15"
                                 height="15"
                                 viewBox="0 0 15 15"
                                 className="svg-fill"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path d="M3.33301 18.332C3.33301 16.5639 4.03539 14.8682 5.28563 13.618C6.53587 12.3677 8.23156 11.6654 9.99967 11.6654C11.7678 11.6654 13.4635 12.3677 14.7137 13.618C15.964 14.8682 16.6663 16.5639 16.6663 18.332H14.9997C14.9997 17.0059 14.4729 15.7342 13.5352 14.7965C12.5975 13.8588 11.3258 13.332 9.99967 13.332C8.67359 13.332 7.40182 13.8588 6.46414 14.7965C5.52646 15.7342 4.99967 17.0059 4.99967 18.332H3.33301ZM9.99967 10.832C7.23717 10.832 4.99967 8.59453 4.99967 5.83203C4.99967 3.06953 7.23717 0.832031 9.99967 0.832031C12.7622 0.832031 14.9997 3.06953 14.9997 5.83203C14.9997 8.59453 12.7622 10.832 9.99967 10.832ZM9.99967 9.16536C11.8413 9.16536 13.333 7.6737 13.333 5.83203C13.333 3.99036 11.8413 2.4987 9.99967 2.4987C8.15801 2.4987 6.66634 3.99036 6.66634 5.83203C6.66634 7.6737 8.15801 9.16536 9.99967 9.16536Z" />
                              </svg>
                              <p className="profileSubMenuItem__text">My Account</p>
                           </div>{" "}
                           <div
                              className="profileSubMenuItem"
                              onClick={() => {
                                 navigate("/dashboard?type=9&option=product");
                              }}
                           >
                              <svg
                                 width="14"
                                 height="13"
                                 viewBox="0 0 14 13"
                                 className="svg-stroke"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M1 3.26667L7.23333 1L13.4667 3.26667L7.23333 5.53333L1 3.26667Z"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                                 <path
                                    d="M13.4667 6.66797L7.23333 8.93463L1 6.66797"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                                 <path
                                    d="M13.4667 10.0664L7.23333 12.3331L1 10.0664"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                              </svg>
                              <p className="profileSubMenuItem__text">My Orders</p>
                           </div>{" "}
                           {contactDetails && contactDetails?.disable_store === false && (
                              <div
                                 className="profileSubMenuItem"
                                 onClick={() => {
                                    navigate("/dashboard?type=10");
                                 }}
                              >
                                 <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    className="svg-fill"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path
                                       d="M7.9505 13.8003C7.72641 13.8042 7.50326 13.7702 7.2905 13.6998C6.431 13.4103 5.0405 12.7 3.77075 11.5697C2.291 10.2603 0.521 8.04025 0.521 4.89025C0.521 2.41 2.5205 0.4 4.9805 0.4C6.101 0.4 7.151 0.81025 7.961 1.54C9.221 0.40975 11.141 0.0902495 12.7205 0.780249C14.3405 1.50025 15.3905 3.12025 15.3905 4.9C15.3905 9.64 11.231 12.82 8.6105 13.7103C8.39592 13.7716 8.17367 13.8019 7.9505 13.8003ZM4.97075 1.9C3.341 1.9 2.01125 3.24025 2.01125 4.89025C2.01125 7.48975 3.50075 9.34 4.76075 10.45C5.87075 11.4303 7.04075 12.04 7.7705 12.28C7.85075 12.31 8.0405 12.31 8.111 12.28C9.611 11.77 13.8807 9.31975 13.8807 4.89025C13.8807 3.7 13.181 2.62 12.1108 2.14975C10.901 1.62025 9.35075 2.02 8.5505 3.09025C8.411 3.28 8.141 3.37975 7.9505 3.39025C7.83391 3.39114 7.71877 3.36442 7.61449 3.31228C7.5102 3.26014 7.41974 3.18405 7.3505 3.09025C7.07477 2.71933 6.71577 2.41838 6.30241 2.21163C5.88906 2.00489 5.43292 1.89815 4.97075 1.9Z"
                                       fill="#596579"
                                    />
                                 </svg>
                                 <p className="profileSubMenuItem__text">My Wishlist</p>
                              </div>
                           )}
                           {contactDetails && contactDetails?.disable_store === false && (
                              <div
                                 className="profileSubMenuItem"
                                 onClick={() => {
                                    navigate("/cart");
                                 }}
                              >
                                 <svg
                                    width="15"
                                    height="15"
                                    viewBox="0 0 15 15"
                                    fill="none"
                                    className="svg-fill"
                                    xmlns="http://www.w3.org/2000/svg"
                                 >
                                    <path d="M2.66591 3.27654L0.503906 1.11521L1.44724 0.171875L3.60857 2.33387H13.7699C13.8738 2.33387 13.9763 2.35815 14.0692 2.40479C14.162 2.45142 14.2427 2.51912 14.3047 2.60247C14.3668 2.68583 14.4085 2.78253 14.4265 2.88487C14.4445 2.9872 14.4384 3.09233 14.4086 3.19187L12.8086 8.52521C12.7674 8.6626 12.683 8.78305 12.568 8.86868C12.4529 8.95431 12.3133 9.00055 12.1699 9.00054H3.99924V10.3339H11.3326V11.6672H3.33257C3.15576 11.6672 2.98619 11.597 2.86117 11.4719C2.73614 11.3469 2.66591 11.1774 2.66591 11.0005V3.27654ZM3.99924 3.66721V7.66721H11.6739L12.8739 3.66721H3.99924ZM3.66591 14.3339C3.40069 14.3339 3.14634 14.2285 2.9588 14.041C2.77126 13.8534 2.66591 13.5991 2.66591 13.3339C2.66591 13.0687 2.77126 12.8143 2.9588 12.6268C3.14634 12.4392 3.40069 12.3339 3.66591 12.3339C3.93112 12.3339 4.18548 12.4392 4.37301 12.6268C4.56055 12.8143 4.66591 13.0687 4.66591 13.3339C4.66591 13.5991 4.56055 13.8534 4.37301 14.041C4.18548 14.2285 3.93112 14.3339 3.66591 14.3339ZM11.6659 14.3339C11.4007 14.3339 11.1463 14.2285 10.9588 14.041C10.7713 13.8534 10.6659 13.5991 10.6659 13.3339C10.6659 13.0687 10.7713 12.8143 10.9588 12.6268C11.1463 12.4392 11.4007 12.3339 11.6659 12.3339C11.9311 12.3339 12.1855 12.4392 12.373 12.6268C12.5605 12.8143 12.6659 13.0687 12.6659 13.3339C12.6659 13.5991 12.5605 13.8534 12.373 14.041C12.1855 14.2285 11.9311 14.3339 11.6659 14.3339Z" />
                                 </svg>
                                 <p className="profileSubMenuItem__text">My Cart</p>
                              </div>
                           )}
                           <div
                              className="profileSubMenuItem"
                              onClick={() => {
                                 navigate("/dashboard?type=3");
                              }}
                           >
                              <svg
                                 width="13"
                                 height="16"
                                 viewBox="0 0 13 16"
                                 fill="none"
                                 className="svg-fill"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path d="M6.33398 15.7241V13.3241C2.99398 13.1507 0.333984 10.3774 0.333984 6.9974C0.333984 3.50406 3.17398 0.664062 6.66732 0.664062C10.1607 0.664062 13.0007 3.50406 13.0007 6.9974C13.0007 10.2974 10.7073 13.6174 7.28732 15.2641L6.33398 15.7241ZM6.66732 1.9974C3.90732 1.9974 1.66732 4.2374 1.66732 6.9974C1.66732 9.75739 3.90732 11.9974 6.66732 11.9974H7.66732V13.5307C10.094 11.9974 11.6673 9.4774 11.6673 6.9974C11.6673 4.2374 9.42732 1.9974 6.66732 1.9974ZM6.00065 9.66406H7.33398V10.9974H6.00065V9.66406ZM7.33398 8.66406H6.00065C6.00065 6.4974 8.00065 6.66406 8.00065 5.33073C8.00065 4.5974 7.40065 3.9974 6.66732 3.9974C5.93398 3.9974 5.33398 4.5974 5.33398 5.33073H4.00065C4.00065 3.8574 5.19398 2.66406 6.66732 2.66406C8.14065 2.66406 9.33398 3.8574 9.33398 5.33073C9.33398 6.9974 7.33398 7.16406 7.33398 8.66406Z" />
                              </svg>
                              <p className="profileSubMenuItem__text">Support</p>
                           </div>
                           <div className="profileSubMenuItem" onClick={handleLogout}>
                              <svg
                                 width="16"
                                 height="16"
                                 viewBox="0 0 16 16"
                                 fill="none"
                                 className="svg-stroke"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <path
                                    d="M5.93359 5.04016C6.14026 2.64016 7.37359 1.66016 10.0736 1.66016H10.1603C13.1403 1.66016 14.3336 2.85349 14.3336 5.83349V10.1802C14.3336 13.1602 13.1403 14.3535 10.1603 14.3535H10.0736C7.39359 14.3535 6.16026 13.3868 5.94026 11.0268"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                                 <path
                                    d="M9.99878 8H2.41211"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                                 <path
                                    d="M3.89935 5.76562L1.66602 7.99896L3.89935 10.2323"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                 />
                              </svg>
                              <p className="profileSubMenuItem__text">Logout</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </Navbar.Collapse>
            </Container>
         </Navbar>

         <Navbar bg="light" className="second_nav">
            <Container fluid className="fixed-width">
               <Navbar.Toggle aria-controls="navbarScroll" />
               <Navbar.Collapse id="navbarScroll ">
                  <Nav
                     className="me-auto my-2 my-lg-0  ps-3"
                     style={{ maxHeight: "100px" }}
                     navbarScroll
                  >
                     {navdata &&
                        Object.entries(navdata).map(([key, value]) => {
                           // console.log("child", location.pathname);
                           // console.log("child_title", value.title);

                           // if (location.pathname.includes("tv")) {
                           //    return null;
                           // } else
                           // console.log(
                           //    value?.title?.toLowerCase()?.replace(/[\s\-_']+/g, ""),
                           //    location.pathname?.toLocaleLowerCase()?.replace(/[\s\-_']+/g, ""),
                           //    location.pathname
                           //       ?.toLocaleLowerCase()
                           //       ?.replace(/[\s\-_']+/g, "")
                           //       .includes(value?.title?.toLowerCase()?.replace(/[\s\-_']+/g, "")),
                           //    value,
                           // );
                           if (
                              location.pathname
                                 ?.toLocaleLowerCase()
                                 ?.replace(/[\s\-_']+/g, "")
                                 .includes(value?.title?.toLowerCase()?.replace(/[\s\-_']+/g, ""))
                           ) {
                              console.log(location.pathname, value);
                              return (
                                 <>
                                    {value?.children
                                       ?.sort((a, b) => a.position - b.position)
                                       ?.map((child) => {
                                          return (
                                             <Link
                                                to={child?.isExternal ? "" : child?.link}
                                                id="secondnav-text"
                                                className={
                                                   location.pathname
                                                      ?.replace(/[\s-]+/g, "")
                                                      ?.includes(
                                                         child?.link
                                                            .toLowerCase()
                                                            ?.replace(/[\s\-_']+/g, ""),
                                                      )
                                                      ? " secondnav_active"
                                                      : ""
                                                }
                                                onClick={() => {
                                                   if (child?.isExternal) window.open(child?.link);
                                                }}
                                                style={isMobile ? { display: "none" } : {}}
                                             >
                                                {child?.title}
                                             </Link>
                                          );
                                       })}
                                 </>
                              );
                           } else {
                              return null;
                           }
                        })}
                     {
                        //  categoryData &&
                        //    Object.entries(categoryData)?.map(([key, value]) => {
                        //       if (location.pathname.includes("tv")) {
                        //          return (
                        //             <Link
                        //                to={`/tv/${value?.title
                        //                   ?.toLowerCase()
                        //                   ?.replace(/[\s]+/g, "-")}/${value?._id}`}
                        //                id="secondnav-text"
                        //                className={
                        //                   location.pathname
                        //                      ?.replace(/[\s-]+/g, "")
                        //                      ?.includes(
                        //                         value?.title
                        //                            ?.toLowerCase()
                        //                            ?.replace(/[\s\-_']+/g, ""),
                        //                      )
                        //                      ? " secondnav_active"
                        //                      : ""
                        //                }
                        //             >
                        //                {value?.title}
                        //             </Link>
                        //          );
                        //       } else {
                        //          return null;
                        //       }
                        //    })
                     }

                     {/* <Nav.Link
                        href="/"
                        id="secondnav-text"
                        style={{ textDecoration: "none" }}
                        className={location.pathname === "/" ? " secondnav_active" : ""}
                     >
                        Home
                     </Nav.Link>
                     <Nav.Link
                        href="/packages"
                        id="secondnav-text"
                        className={location.pathname === "/packages" ? " secondnav_active" : ""}
                     >
                        TV
                     </Nav.Link>
                     <Nav.Link
                        href="/internet"
                        id="secondnav-text"
                        className={location.pathname === "/internet" ? " secondnav_active" : ""}
                     >
                        Internet
                     </Nav.Link>
                     <Nav.Link
                        onClick={() => navigate("/store")}
                        id="secondnav-text"
                        className={location.pathname === "/store" ? " secondnav_active" : ""}
                     >
                        Store
                     </Nav.Link>
                     <Nav.Link
                        href="/ppv-movies"
                        id="secondnav-text"
                        className={location.pathname === "/ppv-movies" ? " secondnav_active" : ""}
                     >
                        PPV
                     </Nav.Link>
                     <Nav.Link
                        href="/ott"
                        id="secondnav-text"
                        className={location.pathname === "/ott" ? " secondnav_active" : ""}
                     >
                        OTT
                     </Nav.Link> */}
                  </Nav>

                  <Nav.Link href="tel: 1660-01-22000" className="secondnav-contact">
                     Toll Free Help Desk: 1660-01-22000
                  </Nav.Link>
               </Navbar.Collapse>
            </Container>
         </Navbar>
         <div className="sideNav" id="sideNav" ref={myContainer}>
            {navdata &&
               Object.entries(navdata).map(([key, value]) => {
                  if (value?.children?.length === 0) {
                     return (
                        <Link
                           to={value?.link}
                           className={
                              location.pathname
                                 ?.replace(/[\s-]+/g, "")
                                 ?.includes(value?.title?.toLowerCase()?.replace(/[\s\-_']+/g, ""))
                                 ? " SideNav-link sidenav_link_active"
                                 : "SideNav-link"
                           }
                        >
                           {value?.title}
                        </Link>
                     );
                  } else {
                     return (
                        <SideNavDropdown
                           title={value?.title}
                           children={value?.children}
                           link={value?.link}
                        />
                     );
                  }
               })}

            {/* <a href="/packages" className="">
               {" "}
               TV
            </a>
            <a href="/internet" className="">
               Internet
            </a>
            <a href="/store" className="">
               Store
            </a>
            <a href="/ppv-movies" className="">
               PPV
            </a>
            <a href="/ott" className="">
               OTT
            </a> */}
         </div>
         {searchResponsive && mobileSearch && (
            <div className="mobileSearch">
               <input
                  type="text"
                  name="search"
                  id="search "
                  onChange={(e) => handleSearch(e)}
                  onKeyPress={(e) => handleKeySubmit(e)}
               />
               <button
                  className="Search_submit"
                  onClick={(e) => {
                     handleSubmit(e);
                     searchResponsive && setMobileSearch(!mobileSearch);
                  }}
               >
                  Search
               </button>
            </div>
         )}
      </div>
   );
};

export default Navigation;

export const SideNavDropdown = ({ title, link, children }) => {
   const [open, setOpen] = useState(false);
   const location = useLocation();
   // let navigate = useNavigate();
   return (
      // <NavDropdown title={title}>
      //    {children.map((child) => {
      //       return (
      //          <NavDropdown.Item>
      //             {" "}
      //             <Link to={child?.link}>{child?.title}</Link>
      //          </NavDropdown.Item>
      //       );
      //    })}
      // </NavDropdown>
      <div className={"NavDropDown-container"}>
         <div
            className={
               location.pathname
                  ?.replace(/[\s-]+/g, "")
                  ?.includes(title?.toLowerCase()?.replace(/[\s\-_']+/g, ""))
                  ? "NavDropDown-title-container navdropdown_title_active"
                  : "NavDropDown-title-container"
            }
         >
            <Link to={link} className="NavDropDown-title">
               {title}
            </Link>
            <div
               className={open ? "NavDropDown-arrow  rotate-icon" : "NavDropDown-arrow"}
               onClick={() => setOpen(!open)}
            >
               {<AiOutlineDown />}
            </div>
         </div>
         <div className="NavDropDown-children-container">
            {open &&
               children.map((child) => {
                  return (
                     <Link
                        to={child?.isExternal ? "" : child?.link}
                        // className=""
                        className={
                           location.pathname
                              ?.replace(/[\s-]+/g, "")
                              ?.includes(child?.title.toLowerCase()?.replace(/[\s\-_']+/g, ""))
                              ? // .includes(value?.title.toLowerCase())
                                " NavDropDown-child navchild_active"
                              : "NavDropDown-child"
                        }
                        onClick={() => {
                           if (child?.isExternal) window.open(child?.link);
                        }}
                     >
                        {child?.title}
                     </Link>
                  );
               })}
         </div>
      </div>
   );
};
