import React, { useState, useEffect } from "react";
import { get_itv_banner, get_itv_ppv_movies } from "../data/api";
import Layout from "../components/include/layout";
import SlideBanner from "../components/customcomponents/slidebanner";
import ItvMovies from "./AvailableMovies";
import Loading from "../components/customcomponents/loading";

function Main() {
   const [cartupdate, setCartupdate] = useState(false);
   const [movies, setMovies] = useState([]);
   const [bannerData, setBannerData] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   useEffect(() => {
      async function getMovies() {
         const res = await get_itv_ppv_movies();
         if (res.status === 200) {
            setMovies(res?.data?.data?.data);
            setIsLoading(false);
            // setMovies(response?.data?.data?.reverse());
         }
         const bannerres = await get_itv_banner();
         if (bannerres.status === 200) {
            setIsLoading(false);
            setBannerData(bannerres?.data?.banners);
         }
      }
      getMovies();
   }, []);
   console.log("bannerres", bannerData);

   return (
      <Layout cartupdate={cartupdate} setCartupdate={setCartupdate}>
         {isLoading ? (
            <Loading />
         ) : (
            <>
               {bannerData && <SlideBanner banners={bannerData} />}
               <ItvMovies movies={movies} />
            </>
         )}
      </Layout>
   );
}

export default Main;
