import React from "react";
import "../../scss/internet/internetbanner.scss";
import earth from "../../assets/internet/earth.png";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import TrackVisibility from "react-on-screen";
import { check_enterprise_user } from "../../data/protectedapi";
import Token from "../../utils/useToken";
import { useMediaQuery } from "react-responsive";

export default function EnterpriseBanner({ data, setShow, muted }) {
   // console.log("enterprise", data);
   let navigate = useNavigate();
   const [flag, setFlag] = React.useState(false);
   const { userDetails } = Token();
   const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

   React.useEffect(() => {
      async function fetchData() {
         const response = await check_enterprise_user();
         setFlag(response?.data?.isEnterpriseCustomer);
      }
      userDetails && fetchData();
   }, [flag]);
   // console.log("flag", flag);
   const ComponentToTrack = (props) => {
      const mobile = useMediaQuery({ query: "(max-width: 767px)" });

      return (
         <div
            className="infoBanner__player-wrapper"
            style={{
               height: "25px",
            }}
         >
            <ReactPlayer
               url={`${process.env.REACT_APP_FILE_URL}/${
                  props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                     ? props?.data?.mobile_media
                     : props?.data?.media
               }`}
               playing={props?.isVisible}
               loop={true}
               width="100%"
               height="100%"
               className="react-player"
               muted={
                  props?.data?.mobile_media_type && props?.data?.mobile_media && mobile
                     ? props?.data?.mobile_mute
                     : props?.data?.mute
               }
               style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: "1",
                  pointerEvents: "none",
                  objectFit: "cover",
               }}
               config={{
                  youtube: {
                     playerVars: {
                        autoplay: 1,
                        rel: 0,
                        controls: 0,
                        showinfo: 0,
                        disablekb: 1,
                        modestbranding: 1,
                     },
                  },
               }}
               playsinline
            />
         </div>
      );
   };
   return (
      <div
         className="wrapper-internet "
         style={{ position: "relative" }}
         //    data?.media_type === "video" && data?.mobile_media_type !== "image"
         //       ? {
         //            backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${data.thumbnail})`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         //       : data?.media === undefined
         //       ? {
         //            backgroundImage: `url(${earth})`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         //       : {
         //            backgroundImage: `url(${process.env.REACT_APP_FILE_URL}/${
         //               isMobile && data?.mobile_media ? data?.mobile_media : data.media
         //            })`,
         //            backgroundSize: "cover",
         //            backgroundPosition: "center",
         //         }
         // }
      >
         <img
            src={
               data?.media_type === "video" && data?.mobile_media_type !== "image"
                  ? `${process.env.REACT_APP_FILE_URL}/${data.thumbnail}`
                  : data?.media === undefined
                  ? earth
                  : `${process.env.REACT_APP_FILE_URL}/${
                       isMobile && data?.mobile_media ? data?.mobile_media : data.media
                    }`
            }
            style={{ width: "100%", position: "absolute", height: "100%" }}
            alt=""
         />
         <div class="container col-xxl-8 py-5 fixed-width">
            <TrackVisibility>
               <ComponentToTrack data={data} />
            </TrackVisibility>
            <div
               class="row flex-lg-row-reverse align-items-center g-5 py-5 mt-4"
               style={{ flexWrap: "nowrap", flexDirection: "column-reverse" }}
            >
               <div
                  class="col mb-5"
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     zIndex: "1",
                     alignItems:
                        data?.text_align == "left"
                           ? "flex-start"
                           : data?.text_align == "right"
                           ? "flex-end"
                           : "center",
                  }}
               >
                  <h1
                     class="display-5 fw-bold lh-1 mb-3"
                     id="internetbanner-heading"
                     style={{ maxWidth: "450px", color: data?.text_color }}
                  >
                     {/* Bring The <span className="green">Fastest</span>{" "}
                            Broadband To Your Home */}
                     {data?.caption}
                  </h1>
                  <p class="internet-lead" style={{ maxWidth: "450px", color: data?.text_color }}>
                     {/* 100 Mbps with Free Net Tv and Dishhome Bundel
                            Starting from Rs 1300/ month */}
                     {data?.sub_caption}
                  </p>
                  {flag && (
                     <>
                        <div class="d-grid gap-2 d-md-flex justify-content-md-start py-4">
                           <button
                              type="button"
                              class="btn btn-primary btn-lg px-4 me-md-2"
                              id="banner-btn"
                              onClick={() => {
                                 navigate("/company/support");
                              }}
                              style={{
                                 position: "fixed",
                                 bottom: "78px",
                                 right: "24px",
                                 zIndex: "99",
                                 margin: "4px",
                              }}
                           >
                              Enterprise Support
                           </button>
                        </div>
                     </>
                  )}
                  {}
               </div>
            </div>
         </div>
      </div>
   );
}
