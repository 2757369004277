import React, { useEffect, useState } from "react";
import { get_brand } from "../../data/api";
import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Widthcontainer from "../../components/customcomponents/widthcontainer";

import "../../scss/store/brand.scss";
export default function Brand() {
   const [brandData, setBrandData] = useState("");
   const navigate = useNavigate();

   useEffect(() => {
      async function getbrand() {
         const res = await get_brand();
         const response = res.data.data;

         setBrandData(response);
      }
      getbrand();
   }, []);
   // console.log("data", brandData);

   const settings = {
      dots: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      cenerMode: true,
   };
   return (
      <div className="container " style={{ marginTop: "50px" }}>
         <Widthcontainer>
            <div className="fixed-width brandwrapper">
               <p className="categories_title">Our Brands.</p>
               <p className="categories_description">Best products from the best brands.</p>
               <div>
                  <Splide
                     options={{
                        type: "slide",
                        hasTrack: false,
                        perPage: 4,
                        autoHeight: true,
                        gap: 30,
                        breakpoints: {
                           400: {
                              perPage: 1,
                              perMove: 1,
                           },
                           780: {
                              perPage: 2,
                              perMove: 2,
                           },
                           992: {
                              perPage: 2,
                              perMove: 2,
                           },
                           1200: {
                              perPage: 3,
                              perMove: 3,
                           },
                           1800: {
                              perPage: 4,
                              perMove: 4,
                           },
                        },
                     }}
                  >
                     {Object.entries(brandData).map(([key, value]) => {
                        return (
                           value?.name !== "No Brand" && (
                              <SplideSlide>
                                 <BrandList
                                    id={value?._id}
                                    navigate={navigate}
                                    img={`${process.env.REACT_APP_FILE_URL}/${value?.image}`}
                                 />
                              </SplideSlide>
                           )
                        );
                     })}
                  </Splide>
               </div>
            </div>
         </Widthcontainer>
      </div>
   );
}
export function BrandList(props) {
   return (
      <div
         className="d-flex justify-content-center align-items-center brand-list"
         onClick={() => {
            props.navigate("/store/category/?type=" + props.id);
         }}
      >
         <img
            style={{
               display: "flex",
               alignItem: "center",
               justifyContent: "cnter",
               objectFit: "contain",
               width: "100px",
               height: "auto",
            }}
            className="brand"
            alt=""
            height="150px"
            width="150px"
            src={props.img}
         />
      </div>
   );
}
