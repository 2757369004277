import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import tick from "../../../assets/tick.svg";
import Loading from "../../../components/customcomponents/loading";
import { get_individual_package } from "../../../data/api";
import "../../../scss/packages/indvpackagecard.scss";
import SeoTags from "../../../utils/seoTags";
import { ShareBtnPopup } from "../../../utils/shareBtnPopup";

const IndividualPackageCard = ({ id }) => {
   const [packageData, setPackageData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [duration, setDuration] = useState("6 Months");
   useEffect(() => {
      async function fetchData() {
         const response = await get_individual_package(id);
         setPackageData(response.data.data);
      }
      fetchData();
   }, [empty, id]);

   useEffect(() => {
      if (packageData === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });
   let navigate = useNavigate();

   useEffect(() => {
      let status = false;
      packageData &&
         Object.entries(packageData.prices).map(([key, value]) => {
            if (value.type === duration) {
               status = true;
            }
         });
      packageData && !status && setDuration("1 Month");
   }, [packageData]);

   return empty ? (
      <Loading />
   ) : (
      <div className="individualpackagecard_wrapper px-3 py-1">
         {/* <div className="ribbon">Free Installation</div> */}
         {SeoTags(
            `${packageData.title} | DishHome Nepal`,
            packageData.seo_title,
            packageData.seo_description,
            packageData.seo_keywords,
            "DishHome",
            ` ${process.env.REACT_APP_PUBLIC_URL}tv/${id}`,
         )}

         <div className="info_wrapper py-4 mx-5">
            <h3 className="title">{packageData.title}</h3>
            <p className="description px-2">{packageData.description}</p>
            <h1 className="price">
               <sup>Rs. </sup>{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value.price.toLocaleString("hi-IN");
                  }
               })}
               *<sub> {duration}</sub>
            </h1>
         </div>
         <div className="individualpackagecard_feature_wrapper py-4 mx-5">
            <div style={{ position: "absolute", right: "6px", top: "-30px" }}>
               <ShareBtnPopup type={"icon"} />
            </div>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               {packageData.channels.hd_count} HD & {packageData.channels.sd_count} SD Channels
            </li>

            <li>
               {" "}
               <img src={tick} alt="tick" />
               Installation Charge: Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value.installation_charge.toLocaleString("hi-IN");
                  }
               })}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               Set Top Box Price: Rs.{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value.settopbox_price.toLocaleString("hi-IN");
                  }
               })}
            </li>
            <li>
               {" "}
               <img src={tick} alt="tick" />
               No. of Television: upto{" "}
               {Object.entries(packageData.prices).map(([key, value]) => {
                  if (value.type === duration) {
                     return value.multiple;
                  }
               })}
            </li>
         </div>
         <div className="duration_wrapper py-3 ps-5 mx-5">
            {Object.entries(packageData.prices).map(([key, value]) => {
               return (
                  <div className="checkbox_wrapper">
                     <input
                        type="radio"
                        id={value._id}
                        name="duration"
                        value={value.type}
                        checked={duration === value.type}
                        onClick={(e) => setDuration(e.target.value)}
                     />
                     <label style={{ cursor: "pointer" }} for={value._id}>
                        {value.type}
                     </label>
                  </div>
               );
            })}{" "}
         </div>
         <button
            className="order_button my-3"
            onClick={() => navigate(`/tv/order/${id}?type=${duration}`)}
         >
            Order Now ➔
         </button>
      </div>
   );
};

export default IndividualPackageCard;
