import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../../include/navbar";
import "../../../../scss/internet/compare/plans.scss";
import tick from "../../../../assets/tick.svg";
import { Button, Container, Form } from "react-bootstrap";
import QuickLink from "../../quicklink";
import filter from "../../../../assets/internet/filter.png";
import Footer from "../../../include/footer";
import phone from "../../../../assets/internet/phone.png";
import { get_internet_package, get_combo_type } from "../../../../data/api";
import Loading from "../../loading";
import Widthcontainer from "../../widthcontainer";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useLocation } from "react-router-dom";
import bulletkite from "../../../../assets/offer/kite 1.png";
import backgroundKite from "../../../../assets/offer/_bg.png";
import bodyBg1 from "../../../../assets/offer/_body_bg_1.png";
import bodyBg2 from "../../../../assets/offer/_body_bg_2.png";
import bodyBg3 from "../../../../assets/offer/_body_bg_3.png";
import ReactGA from "react-ga4";

import Layout from "../../../include/layout";
import SeoTags from "../../../../utils/seoTags";

export default function Plan() {
   //get type from banner
   function useQuery() {
      const { search } = useLocation();
      return React.useMemo(() => new URLSearchParams(search), [search]);
   }
   let query = useQuery();
   let type = query.get("type");

   const [plans, setPlans] = useState("12 Months");
   const [dropvalue, setDropvalue] = useState("new");
   const [sortvalue, setSortvalue] = useState("Sort by speed(high to low)");
   const [issorted, setIssorted] = useState(false);

   const [dropdata, setdropData] = useState("");
   const [combovalue, setCombovalue] = useState();
   // console.log("combo", combovalue);
   const [combodata, setComboData] = useState("");

   const handlePlans = (option) => {
      setIssorted(false);
      setPlans(option.label);
   };

   const options = ["1 Month", "3 Months", "6 Months", "12 Months", "24 Months"];

   const sortoptions = [
      "Sort by speed(high to low)",
      "Sort by speed(low to high)",
      "Sort by price(high to low)",
      "Sort by price(low to high)",
   ];

   const newoptions = [
      { value: "new", label: "New" },
      { value: "existing", label: "Existing" },
   ];
   const defaultOption = "12 Months";
   const defaultOption1 = newoptions[0].label;
   const sortingOption = sortoptions[0];

   const handleUsers = (newoptions) => {
      setIssorted(false);
      setDropvalue(newoptions.value);
   };
   // console.log("drop", dropvalue);
   const handleSort = (sortoptions) => {
      setIssorted(false);
      setSortvalue(sortoptions.label);
   };

   const handleCombovalue = (e, id) => {
      setIssorted(false);
      setCombovalue(id);
   };
   useEffect(() => {
      async function fetchData() {
         console.log("combo", window.location.pathname + window.location.search);
         ReactGA?.send({
            hittype: "pageview",
            page: window.location.pathname + window.location.search,
         });
      }
      fetchData();
   }, [type]);

   return (
      <div>
         {SeoTags("Plans | DishHome Nepal", "DishHome Nepal", "DishHome Nepal")}

         <Layout>
            <Header
               handlePlans={handlePlans}
               type={type}
               handleUsers={handleUsers}
               combovalue={combovalue}
               handleCombovalue={handleCombovalue}
               setCombovalue={setCombovalue}
               setDropvalue={setDropvalue}
               dropdata={dropdata}
               setdropData={setdropData}
            />
            <Headersecond
               handleUsers={handleUsers}
               handleSort={handleSort}
               sortoptions={sortoptions}
               sortingOption={sortingOption}
               defaultOption={defaultOption}
               defaultOption1={defaultOption1}
               newoptions={newoptions}
               options={options}
               handlePlans={handlePlans}
            />
            <PlanCard
               plans={plans}
               combovalue={combovalue}
               combodata={combodata}
               setComboData={setComboData}
               dropvalue={dropvalue}
               setDropvalue={setDropvalue}
               dropdata={dropdata}
               sortvalue={sortvalue}
               setdropData={setdropData}
               issorted={issorted}
               setIssorted={setIssorted}
            />
            {/* <PlansBanner /> */}
         </Layout>
      </div>
   );
}

export function PlanCard({
   sortvalue,
   plans,
   combovalue,
   dropvalue,
   dropdata,
   setdropData,
   setDropvalue,
   setCombovalue,
   issorted,
   setIssorted,
}) {
   const navigate = useNavigate();
   const [internetdata, setinternetData] = useState("");
   const [empty, setEmpty] = useState(true);
   const [viewData, setViewData] = useState("");

   useEffect(() => {
      async function get_internetpackage() {
         const res = await get_internet_package();
         const response = res?.data?.data;
         setinternetData(response);
      }
      get_internetpackage();
   }, []);
   useEffect(() => {
      if (internetdata === "") {
         setEmpty(true);
      } else {
         setEmpty(false);
      }
   });
   function priceAvailable(item, plan) {
      // Check if there is an entry for the selected plan type within the item.prices array
      const planIndex = item.prices.findIndex((price) => price.type === plan);
      // If planIndex is greater than or equal to 0, it means the plan type is found
      // and we should include the item; otherwise, exclude it
      return planIndex >= 0;
   }

   useEffect(() => {
      if (!internetdata) return; // Stop if internetdata is falsy
      let sortedData = [...internetdata]; // Create a copy of internetdata to avoid mutation
      if (sortvalue === "Sort by speed(low to high)") {
         sortedData.sort((a, b) => parseInt(a.speed) - parseInt(b.speed));
      } else if (sortvalue === "Sort by speed(high to low)") {
         sortedData.sort((a, b) => parseInt(b.speed) - parseInt(a.speed));
      } else if (
         sortvalue === "Sort by price(high to low)" ||
         sortvalue === "Sort by price(low to high)"
      ) {
         const planIndex = ["1 Month", "3 Months", "6 Months", "12 Months", "24 Months"].indexOf(
            plans,
         );
         sortedData.sort((a, b) => {
            const aPrice =
               a.prices[planIndex]?.installation_charge +
               a.prices[planIndex]?.internet_price +
               a.prices[planIndex]?.drop_wire +
               a.prices[planIndex]?.itv_price +
               a.prices[planIndex]?.itv_stb_charge +
               a.prices[planIndex]?.router;
            const bPrice =
               b.prices[planIndex]?.installation_charge +
               b.prices[planIndex]?.internet_price +
               b.prices[planIndex]?.drop_wire +
               b.prices[planIndex]?.itv_price +
               b.prices[planIndex]?.itv_stb_charge +
               b.prices[planIndex]?.router;
            return sortvalue === "Sort by price(high to low)" ? bPrice - aPrice : aPrice - bPrice;
         });
      }
      // setinternetData(sortedData);

      sortedData = sortedData.filter((item) => {
         return priceAvailable(item, plans);
      });
      setViewData(sortedData);
      setIssorted(true);
   }, [internetdata, sortvalue, plans]);

   const nodata = [];

   return empty && issorted ? (
      <Loading />
   ) : (
      <>
         <div className="plan-card mb-4">
            {Object.entries(viewData || internetdata).map(([key, item]) => {
               if (combovalue !== "all") {
                  if (item.package_type === dropvalue) {
                     if (item?.package_option !== "SME") {
                        if (item.combo_type === combovalue) {
                           nodata.push(item);

                           return (
                              <div
                                 className={`best-package `}
                                 style={
                                    item?.dashain_offer
                                       ? key % 3 == 0
                                          ? {
                                               backgroundImage: `url(${bodyBg3})`,
                                               backgroundRepeat: "no-repeat",
                                               backgroundPosition: "bottom",
                                               backgroundPositionX: "right",
                                            }
                                          : key % 2 == 0
                                          ? {
                                               backgroundImage: `url(${bodyBg1})`,
                                               backgroundRepeat: "no-repeat",
                                               backgroundPosition: "bottom",
                                               backgroundPositionX: "right",
                                            }
                                          : {
                                               backgroundImage: `url(${bodyBg2})`,
                                               backgroundRepeat: "no-repeat",
                                               backgroundPosition: "bottom",
                                               backgroundPositionX: "left",
                                            }
                                       : {}
                                 }
                                 key={key}
                              >
                                 <div
                                    className={key === 1 ? "inside-plans1" : "inside-plans"}
                                    style={
                                       item?.dashain_offer == true
                                          ? {
                                               backgroundImage: `url(${backgroundKite})`,
                                               background: "#E0001B",
                                               backgroundRepeat: "no-repeat",
                                               backgroundPosition: "center",
                                            }
                                          : {}
                                    }
                                 >
                                    {item.combo_type && item.combo_type !== "Fibernet" ? (
                                       <div id="combo-tag">
                                          <p>Combo </p>
                                       </div>
                                    ) : null}
                                    <div id="inside-plans-top">
                                       <p className="internet-speed"> {item.title} </p>
                                       <p className="internet-description"> {item.description}</p>
                                    </div>
                                 </div>

                                 <ul className="internet-li">
                                    {Object.entries(item?.prices).map(([key, value]) => {
                                       return (
                                          <>
                                             {value.type === plans ? (
                                                <>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Internet Charge: Rs.{" "}
                                                      {value?.internet_price.toLocaleString(
                                                         "hi-IN",
                                                      )}
                                                   </li>

                                                   {item?.combo_type !== "Fibernet" && (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         No. of TV: upto{" "}
                                                         {value?.no_of_itv ? value?.no_of_itv : 0}
                                                      </li>
                                                   )}

                                                   {/* <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Installation Charge: Rs.{" "}
                                                      {value?.installation_charge.toLocaleString(
                                                         "hi-IN",
                                                      )}
                                                   </li> */}
                                                   {item?.combo_type !== "Fibernet" && (
                                                      <li className="internet-feature">
                                                         {value?.itv_stb_charge !== 0 ||
                                                         value?.itv_stb_charge ? (
                                                            <>
                                                               {" "}
                                                               <img
                                                                  style={{ marginRight: "13px" }}
                                                                  src={
                                                                     item?.dashain_offer
                                                                        ? bulletkite
                                                                        : tick
                                                                  }
                                                                  alt="tick"
                                                               />{" "}
                                                               TV Installation Charge: Rs.{" "}
                                                               {value?.itv_stb_charge.toLocaleString(
                                                                  "hi-IN",
                                                               )}
                                                            </>
                                                         ) : null}
                                                      </li>
                                                   )}

                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Router Rental Charge: Rs.{" "}
                                                      {value?.router.toLocaleString("hi-IN")}
                                                   </li>
                                                </>
                                             ) : null}
                                          </>
                                       );
                                    })}
                                 </ul>

                                 {Object.entries(item.prices).map(([key, value]) => {
                                    if (value.type === plans) {
                                       return (
                                          <div>
                                             {" "}
                                             <p className="li-price">
                                                Rs.{" "}
                                                {(
                                                   value?.installation_charge +
                                                   value?.internet_price +
                                                   value?.drop_wire +
                                                   value?.itv_price +
                                                   value?.itv_stb_charge +
                                                   value?.router
                                                ).toLocaleString("hi-IN")}
                                                *<span id="li-subprice">/{plans}</span>
                                             </p>
                                             {dropvalue === "new" ? (
                                                <div className="internet-button">
                                                   <Button
                                                      variant="secondary"
                                                      onClick={() => {
                                                         navigate(
                                                            "/internet/" +
                                                               item._id +
                                                               `?type=${value.type}`,
                                                         );
                                                      }}
                                                      className="choose-btn"
                                                   >
                                                      Choose Plan
                                                   </Button>
                                                </div>
                                             ) : (
                                                <div className="internet-button">
                                                   <Button
                                                      variant="secondary"
                                                      onClick={() => {
                                                         navigate("/dashboard");
                                                      }}
                                                      className="choose-btn"
                                                   >
                                                      Upgrade Plan
                                                   </Button>
                                                </div>
                                             )}
                                          </div>
                                       );
                                    }
                                 })}

                                 {/*  */}
                              </div>
                           );
                        }
                     }
                  } else if (item.for_existing === true) {
                     if (item.combo_type === combovalue) {
                        nodata.push(item);
                        return (
                           <div
                              className="best-package"
                              key={key}
                              style={
                                 item?.dashain_offer
                                    ? key % 3 == 0
                                       ? {
                                            backgroundImage: `url(${bodyBg3})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "right",
                                         }
                                       : key % 2 == 0
                                       ? {
                                            backgroundImage: `url(${bodyBg1})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "right",
                                         }
                                       : {
                                            backgroundImage: `url(${bodyBg2})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "left",
                                         }
                                    : {}
                              }
                           >
                              <div
                                 className={key === 1 ? "inside-plans1" : "inside-plans"}
                                 style={
                                    item?.dashain_offer == true
                                       ? {
                                            background: "#E0001B",
                                            backgroundImage: `url(${backgroundKite})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                         }
                                       : {}
                                 }
                              >
                                 {item.combo_type && item.combo_type !== "Fibernet" ? (
                                    <div id="combo-tag">
                                       <p>Combo </p>
                                    </div>
                                 ) : null}
                                 <div id="inside-plans-top">
                                    <p className="internet-speed"> {item.title} </p>
                                    <p className="internet-description"> {item.description}</p>
                                 </div>
                              </div>

                              <ul className="internet-li">
                                 {Object.entries(item?.prices).map(([key, value]) => {
                                    return (
                                       <>
                                          {value.type === plans ? (
                                             <>
                                                <li className="internet-feature">
                                                   {" "}
                                                   <img
                                                      style={{ marginRight: "18px" }}
                                                      src={item?.dashain_offer ? bulletkite : tick}
                                                      alt="tick"
                                                   />
                                                   Internet Charge: Rs.{" "}
                                                   {value?.internet_price.toLocaleString("hi-IN")}
                                                </li>
                                                {item?.combo_type !== "Fibernet" && (
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      No. of TV: upto{" "}
                                                      {value?.no_of_itv ? value?.no_of_itv : 0}
                                                   </li>
                                                )}

                                                {/* {dropvalue === "new" ? (
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Installation Charge: Rs.{" "}
                                                      {value.installation_charge.toLocaleString(
                                                         "hi-IN",
                                                      )}
                                                   </li>
                                                ) : (
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Installation Charge: Rs. 0
                                                   </li>
                                                )} */}
                                                {dropvalue === "new"
                                                   ? item?.combo_type !== "Fibernet" && (
                                                        <li className="internet-feature">
                                                           {value?.itv_stb_charge !== 0 ||
                                                           value?.itv_stb_charge ? (
                                                              <>
                                                                 {" "}
                                                                 <img
                                                                    style={{
                                                                       marginRight: "13px",
                                                                    }}
                                                                    src={
                                                                       item?.dashain_offer
                                                                          ? bulletkite
                                                                          : tick
                                                                    }
                                                                    alt="tick"
                                                                 />{" "}
                                                                 TV Installation Charge: Rs.{" "}
                                                                 {value?.itv_stb_charge.toLocaleString(
                                                                    "hi-IN",
                                                                 )}
                                                              </>
                                                           ) : null}
                                                        </li>
                                                     )
                                                   : item?.combo_type !== "Fibernet" && (
                                                        <li className="internet-feature">
                                                           {value?.itv_stb_charge !== 0 ||
                                                           value?.itv_stb_charge ? (
                                                              <>
                                                                 {" "}
                                                                 <img
                                                                    style={{
                                                                       marginRight: "13px",
                                                                    }}
                                                                    src={
                                                                       item?.dashain_offer
                                                                          ? bulletkite
                                                                          : tick
                                                                    }
                                                                    alt="tick"
                                                                 />{" "}
                                                                 TV Installation Charge: Rs. 0
                                                              </>
                                                           ) : null}
                                                        </li>
                                                     )}

                                                {dropvalue === "new" ? (
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Router Rental Charge: Rs.{" "}
                                                      {value?.router.toLocaleString("hi-IN")}
                                                   </li>
                                                ) : (
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Router Rental Charge: Rs. 0
                                                   </li>
                                                )}
                                             </>
                                          ) : null}
                                       </>
                                    );
                                 })}
                              </ul>
                              {Object.entries(item.prices).map(([key, value]) => {
                                 if (value.type === plans) {
                                    return (
                                       <div>
                                          {" "}
                                          <p className="li-price">
                                             Rs.{" "}
                                             {(
                                                value?.installation_charge +
                                                value?.internet_price +
                                                value?.drop_wire +
                                                value?.itv_price +
                                                value?.itv_stb_charge +
                                                value?.router
                                             ).toLocaleString("hi-IN")}
                                             *<span id="li-subprice">/{plans}</span>
                                          </p>
                                          <div className="internet-button">
                                             {dropvalue === "new" ? (
                                                <Button
                                                   variant="secondary"
                                                   onClick={() => {
                                                      navigate(
                                                         "/internet/" +
                                                            item._id +
                                                            `?type=${value.type}`,
                                                      );
                                                   }}
                                                   className="choose-btn"
                                                >
                                                   Choose Plan
                                                </Button>
                                             ) : (
                                                <Button
                                                   variant="secondary"
                                                   onClick={() => {
                                                      navigate("/dashboard");
                                                   }}
                                                   className="choose-btn"
                                                >
                                                   Upgrade Plan
                                                </Button>
                                             )}
                                          </div>
                                       </div>
                                    );
                                 }
                              })}

                              {/*  */}
                           </div>
                        );
                     }
                  }
               } else if (combovalue === "all") {
                  if (item?.package_option !== "SME") {
                     if (item.package_type === dropvalue) {
                        nodata.push(item);
                        return (
                           <div
                              className="best-package"
                              key={key}
                              style={
                                 item?.dashain_offer
                                    ? key % 3 == 0
                                       ? {
                                            backgroundImage: `url(${bodyBg3})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "right",
                                         }
                                       : key % 2 == 0
                                       ? {
                                            backgroundImage: `url(${bodyBg1})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "right",
                                         }
                                       : {
                                            backgroundImage: `url(${bodyBg2})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "bottom",
                                            backgroundPositionX: "left",
                                         }
                                    : {}
                              }
                           >
                              <div
                                 className={key === 1 ? "inside-plans1" : "inside-plans"}
                                 style={
                                    item?.dashain_offer == true
                                       ? {
                                            background: "#E0001B",
                                            backgroundImage: `url(${backgroundKite})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                         }
                                       : {}
                                 }
                              >
                                 {item.combo_type && item.combo_type !== "Fibernet" ? (
                                    <div id="combo-tag">
                                       <p>Combo </p>
                                    </div>
                                 ) : null}
                                 <div id="inside-plans-top">
                                    <p className="internet-speed"> {item.title} </p>
                                    <p className="internet-description"> {item.description}</p>
                                 </div>
                              </div>
                              <ul className="internet-li">
                                 {item.package_type === "new" ? (
                                    <>
                                       {Object.entries(item.prices).map(([key, value]) => {
                                          return (
                                             <>
                                                {value.type === plans ? (
                                                   <>
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Internet Charge: Rs.{" "}
                                                         {value?.internet_price.toLocaleString(
                                                            "hi-IN",
                                                         )}
                                                      </li>
                                                      {item?.combo_type !== "Fibernet" && (
                                                         <li className="internet-feature">
                                                            {" "}
                                                            <img
                                                               style={{ marginRight: "18px" }}
                                                               src={
                                                                  item?.dashain_offer
                                                                     ? bulletkite
                                                                     : tick
                                                               }
                                                               alt="tick"
                                                            />
                                                            No. of TV: upto {value?.no_of_itv}
                                                         </li>
                                                      )}

                                                      {/* <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Installation Charge: Rs.{" "}
                                                         {value.installation_charge.toLocaleString(
                                                            "hi-IN",
                                                         )}
                                                      </li> */}
                                                      {item?.combo_type !== "Fibernet" && (
                                                         <li className="internet-feature">
                                                            {" "}
                                                            <img
                                                               style={{ marginRight: "13px" }}
                                                               src={
                                                                  item?.dashain_offer
                                                                     ? bulletkite
                                                                     : tick
                                                               }
                                                               alt="tick"
                                                            />{" "}
                                                            TV Installation Charge: Rs.{" "}
                                                            {value?.itv_stb_charge.toLocaleString(
                                                               "hi-IN",
                                                            )}
                                                         </li>
                                                      )}
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Router Rental Charge: Rs.{" "}
                                                         {value?.router.toLocaleString("hi-IN")}
                                                      </li>
                                                   </>
                                                ) : null}
                                             </>
                                          );
                                       })}
                                    </>
                                 ) : (
                                    <>
                                       {Object.entries(item.prices).map(([key, value]) => {
                                          // console.log("type", value);
                                          return (
                                             <>
                                                {value.type === plans ? (
                                                   <>
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Internet Charge: Rs.{" "}
                                                         {value?.internet_price.toLocaleString(
                                                            "hi-IN",
                                                         )}
                                                      </li>
                                                      {item?.combo_type !== "Fibernet" && (
                                                         <li className="internet-feature">
                                                            {" "}
                                                            <img
                                                               style={{ marginRight: "18px" }}
                                                               src={
                                                                  item?.dashain_offer
                                                                     ? bulletkite
                                                                     : tick
                                                               }
                                                               alt="tick"
                                                            />
                                                            No. of TV: upto {value.no_of_itv}
                                                         </li>
                                                      )}

                                                      {/* <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Installation Charge: {"0"}
                                                      </li> */}
                                                      {item?.combo_type !== "Fibernet" && (
                                                         <li className="internet-feature">
                                                            {" "}
                                                            <img
                                                               style={{ marginRight: "13px" }}
                                                               src={
                                                                  item?.dashain_offer
                                                                     ? bulletkite
                                                                     : tick
                                                               }
                                                               alt="tick"
                                                            />{" "}
                                                            TV Installation Charge: {"0"}
                                                         </li>
                                                      )}
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Router Rental Charge: {"0"}
                                                      </li>
                                                   </>
                                                ) : null}
                                             </>
                                          );
                                       })}
                                    </>
                                 )}
                              </ul>
                              {Object.entries(item?.prices).map(([key, value]) => {
                                 if (value.type === plans) {
                                    return (
                                       <>
                                          <>
                                             <div>
                                                {" "}
                                                <p className="li-price">
                                                   Rs.{" "}
                                                   {(
                                                      value?.installation_charge +
                                                      value?.internet_price +
                                                      value?.drop_wire +
                                                      value?.itv_price +
                                                      value?.itv_stb_charge +
                                                      value?.router
                                                   ).toLocaleString("hi-IN")}
                                                   *<span id="li-subprice">/{plans}</span>
                                                </p>
                                                {dropvalue === "new" ? (
                                                   <div className="internet-button">
                                                      <Button
                                                         variant="secondary"
                                                         onClick={() => {
                                                            navigate(
                                                               "/internet/" +
                                                                  item._id +
                                                                  "?type=" +
                                                                  value.type,
                                                            );
                                                         }}
                                                         className="choose-btn"
                                                      >
                                                         Choose Plan
                                                      </Button>
                                                   </div>
                                                ) : (
                                                   <div className="internet-button">
                                                      <Button
                                                         variant="secondary"
                                                         onClick={() => {
                                                            navigate("/dashboard");
                                                         }}
                                                         className="choose-btn"
                                                      >
                                                         Upgrade plan
                                                      </Button>
                                                   </div>
                                                )}
                                             </div>
                                          </>
                                       </>
                                    );
                                 }
                              })}

                              {/*  */}
                           </div>
                        );
                     }
                  }
                  if (item.for_existing === true) {
                     nodata.push(item);
                     return (
                        <div
                           className="best-package"
                           key={key}
                           style={
                              item?.dashain_offer
                                 ? key % 3 == 0
                                    ? {
                                         backgroundImage: `url(${bodyBg3})`,
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "bottom",
                                         backgroundPositionX: "right",
                                      }
                                    : key % 2 == 0
                                    ? {
                                         backgroundImage: `url(${bodyBg1})`,
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "bottom",
                                         backgroundPositionX: "right",
                                      }
                                    : {
                                         backgroundImage: `url(${bodyBg2})`,
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "bottom",
                                         backgroundPositionX: "left",
                                      }
                                 : {}
                           }
                        >
                           <div
                              className={key === 1 ? "inside-plans1" : "inside-plans"}
                              style={
                                 item?.dashain_offer == true
                                    ? {
                                         background: "#E0001B",
                                         backgroundImage: `url(${backgroundKite})`,
                                         backgroundRepeat: "no-repeat",
                                         backgroundPosition: "center",
                                      }
                                    : {}
                              }
                           >
                              {item.combo_type && item.combo_type !== "Fibernet" ? (
                                 <div id="combo-tag">
                                    <p>Combo </p>
                                 </div>
                              ) : null}
                              <div id="inside-plans-top">
                                 <p className="internet-speed"> {item.title} </p>
                                 <p className="internet-description"> {item.description}</p>
                              </div>
                           </div>
                           <ul className="internet-li">
                              {item.package_type === "new" ? (
                                 <>
                                    {Object.entries(item.prices).map(([key, items]) => {
                                       return (
                                          <>
                                             {items.type === plans ? (
                                                <>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Internet Charge: Rs.{" "}
                                                      {items?.internet_price.toLocaleString(
                                                         "hi-IN",
                                                      )}
                                                   </li>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      No. of TV: upto {items?.no_of_itv}
                                                   </li>

                                                   {/* {dropvalue === "new" ? (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Installation Charge: Rs.{" "}
                                                         {items?.installation_charge.toLocaleString(
                                                            "hi-IN",
                                                         )}
                                                      </li>
                                                   ) : (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Installation Charge: Rs. 0
                                                      </li>
                                                   )} */}
                                                   {dropvalue === "new" ? (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "13px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />{" "}
                                                         TV Installation Charge: Rs.{" "}
                                                         {items?.itv_stb_charge.toLocaleString(
                                                            "hi-IN",
                                                         )}
                                                      </li>
                                                   ) : (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "13px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />{" "}
                                                         TV Installation Charge: Rs. 0
                                                      </li>
                                                   )}

                                                   {dropvalue === "new" ? (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Router Rental Charge: Rs.{" "}
                                                         {items?.router.toLocaleString("hi-IN")}
                                                      </li>
                                                   ) : (
                                                      <li className="internet-feature">
                                                         {" "}
                                                         <img
                                                            style={{ marginRight: "18px" }}
                                                            src={
                                                               item?.dashain_offer
                                                                  ? bulletkite
                                                                  : tick
                                                            }
                                                            alt="tick"
                                                         />
                                                         Router Rental Charge: Rs. 0
                                                      </li>
                                                   )}
                                                </>
                                             ) : null}
                                          </>
                                       );
                                    })}
                                 </>
                              ) : (
                                 <>
                                    {Object.entries(item.prices).map(([key, items]) => {
                                       return (
                                          <>
                                             {items.type === plans ? (
                                                <>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Internet Charge: Rs.{" "}
                                                      {items?.internet_price.toLocaleString(
                                                         "hi-IN",
                                                      )}
                                                   </li>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      No. of TV: upto {items?.no_of_itv}
                                                   </li>
                                                   {/* 
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Installation Charge: {"0"}
                                                   </li> */}
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "13px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />{" "}
                                                      TV Installation Charge: {"0"}
                                                   </li>
                                                   <li className="internet-feature">
                                                      {" "}
                                                      <img
                                                         style={{ marginRight: "18px" }}
                                                         src={
                                                            item?.dashain_offer ? bulletkite : tick
                                                         }
                                                         alt="tick"
                                                      />
                                                      Router Rental Charge: {"0"}
                                                   </li>
                                                </>
                                             ) : null}
                                          </>
                                       );
                                    })}
                                 </>
                              )}
                           </ul>
                           {Object.entries(item.prices).map(([key, value]) => {
                              if (value.type === plans) {
                                 return (
                                    <>
                                       <div>
                                          {" "}
                                          {dropvalue === "new" ? (
                                             <p className="li-price">
                                                Rs.{" "}
                                                {(
                                                   value?.installation_charge +
                                                   value?.internet_price +
                                                   value?.drop_wire +
                                                   value?.itv_price +
                                                   value?.itv_stb_charge +
                                                   value?.router
                                                ).toLocaleString("hi-IN")}
                                                *<span id="li-subprice">/{plans}</span>
                                             </p>
                                          ) : (
                                             <p className="li-price">
                                                Rs.{" "}
                                                {(
                                                   value?.internet_price + value?.itv_price
                                                ).toLocaleString("hi-IN")}
                                                *<span id="li-subprice">/{plans}</span>
                                             </p>
                                          )}
                                          {dropvalue === "new" ? (
                                             <div className="internet-button">
                                                <Button
                                                   variant="secondary"
                                                   onClick={() => {
                                                      navigate(
                                                         "/internet/" +
                                                            item._id +
                                                            "?type=" +
                                                            value.type,
                                                      );
                                                   }}
                                                   className="choose-btn"
                                                >
                                                   Choose Plan
                                                </Button>
                                             </div>
                                          ) : (
                                             <div className="internet-button">
                                                <Button
                                                   variant="secondary"
                                                   onClick={() => {
                                                      navigate("/dashboard");
                                                   }}
                                                   className="choose-btn"
                                                >
                                                   Upgrade plan
                                                </Button>
                                             </div>
                                          )}
                                       </div>
                                    </>
                                 );
                              }
                           })}

                           {/*  */}
                        </div>
                     );
                  }
               }
            })}
         </div>
         {nodata.length === 0 ? (
            <div
               style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <p
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     color: "red",
                     textAlign: "center",
                  }}
               >
                  No package to display
               </p>
            </div>
         ) : null}
      </>
   );
}

//top menu section
export function Header({
   setCombovalue,
   type,
   handlePlans,
   handleUsers,
   plans,
   combovalue,
   handleCombovalue,
   setDropvalue,
   dropvalue,
}) {
   const [active, setActive] = useState("");
   const options = ["1 Month", "3 Months", "6 Months", "12 Months", "24 Months"];
   const newoptions = ["new", "existing"];
   const [comboType, setComboType] = useState("");
   useEffect(() => {
      async function fetchData() {
         const response = await get_combo_type();
         setComboType(response.data.data);
      }
      fetchData();
   }, []);
   useEffect(() => {
      if (type) {
         setActive(type);
         setCombovalue(type);
      } else {
         setActive("all");
         setCombovalue("all");
      }
   }, [type]);

   const defaultOption = options[0];
   const defaultOption1 = newoptions[0];
   const navigate = useNavigate();

   return (
      <Widthcontainer>
         <div className="header-dropdown ">
            <div className="header-li">
               {Object.entries(comboType).map(([key, value]) => {
                  return (
                     <div>
                        <a
                           className={
                              active === value?.title ? "existing-user active" : "existing-user"
                           }
                           onClick={(e) => {
                              // handleCombovalue(e, value?.title);
                              // setActive(value?.title);
                              navigate("/internet/plans?type=" + value?.title);
                           }}
                        >
                           {value?.title}
                        </a>
                     </div>
                  );
               })}
               {/* <div>
                  <a
                     className={active === "all" ? "existing-user active" : "existing-user"}
                     onClick={(e) => {
                        // handleCombovalue(e, "all");
                        // setActive("all");
                        navigate("/internet/plans?type=all");
                     }}
                  >
                     All
                  </a>{" "}
               </div> */}
            </div>
         </div>
      </Widthcontainer>
   );
}

//dropdown section in header
export function Headersecond({
   defaultOption1,
   handleSort,
   sortingOption,
   sortoptions,
   options,
   newoptions,
   handleUsers,
   handlePlans,
   defaultOption,
}) {
   return (
      <Widthcontainer>
         <div className="second-header-wrapper">
            <div className="plan-filter-wrapper">
               <Dropdown
                  options={options}
                  value={defaultOption}
                  onChange={handlePlans}
                  placeholder="Select an option"
               />
            </div>
            <div>
               <Dropdown
                  options={newoptions}
                  onChange={handleUsers}
                  value={defaultOption1}
                  placeholder="Select an option"
               />
            </div>
            <div className="sorting-plans">
               <Dropdown
                  options={sortoptions}
                  onChange={handleSort}
                  value={sortingOption}
                  placeholder="Select an option"
               />
            </div>
         </div>
      </Widthcontainer>
   );
}
export function PlansBanner() {
   return (
      <div className="plansbanner">
         <p className="installation">For Installation</p>
         <div className="plan-img">
            <img src={phone} />
         </div>
      </div>
   );
}
