import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
   return (
      <div
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
         }}
      >
         <ReactLoading type="cylon" color="#ed1c24" height={305} width={305} />
      </div>
   );
};

export default Loading;
