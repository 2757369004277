import { React, useState, useEffect, useRef } from "react";
import Widthcontainer from "../customcomponents/widthcontainer";
import Layout from "../include/layout";
import "../../scss/pages/careers.scss";
import MessageModal from "../customcomponents/messageModal";
import {
   get_career_opportunity,
   get_career_category,
   get_single_career,
   apply_for_job,
   get_career_banner,
   get_email_list,
} from "../../data/api";
import insuarance from "../../assets/insurance.png";
import insuarance2 from "../../assets/comp.webp";
import insuarance3 from "../../assets/training.png";
import Loading from "../customcomponents/loading";
import { Modal, Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { BiSearchAlt } from "react-icons/bi";
import SeoTags from "../../utils/seoTags";
import HeroBanner from "../customcomponents/herobanner";
import { ErrorMessage, Field, Formik } from "formik";
import * as yup from "yup";
import Captcha from "../include/captcha";

const Careers = () => {
   const [data, setData] = useState("");
   const [categoryData, setCategoryData] = useState("");
   const [loading, setLoading] = useState(true);
   const [formState, setFormState] = useState(false);
   const [id, setId] = useState("");
   const [contactDetails, setcontactDetails] = useState("");
   const [keyword, setKeyword] = useState("");
   const [bannerData, setBannerData] = useState("");
   const [seoData, setSeoData] = useState("");
   const [cartupdate, setCartupdate] = useState(false);
   const [recruitmentEmail, setRecruitmentEmail] = useState("");
   // const [headingMessage, setHeadingMessage] = useState("");
   useEffect(() => {
      async function fetchData() {
         const response = await get_career_opportunity(keyword);
         // console.log("data", response?.data?.data?.length);
         const response2 = await get_career_category();
         setData(response?.data?.data);
         setCategoryData(response2?.data?.data);
      }
      fetchData();
   }, [loading, keyword]);

   useEffect(() => {
      async function getdata() {
         const res = await get_career_banner();
         setBannerData(res?.data?.banners);
         setSeoData(res?.data?.seo);
      }
      getdata();
   }, [loading]);
   useEffect(() => {
      async function get_email() {
         const res = await get_email_list();
         setRecruitmentEmail(res?.data?.data);
      }
      get_email();
   }, [loading]);
   // console.log("response", recruitmentEmail);

   useEffect(() => {
      if (categoryData === "" || bannerData === "" || seoData === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });

   return loading ? (
      <Loading />
   ) : (
      <Layout
         setContactdetails={setcontactDetails}
         cartupdate={cartupdate}
         setCartupdate={setCartupdate}
      >
         <JobApplication state={formState} setState={setFormState} id={id} />
         {Object.entries(seoData).map(([key, value]) => {
            if (value?.page === "career") {
               return SeoTags(
                  value?.title,
                  value?.title,
                  "",
                  value?.keyword,
                  "",
                  `${process.env.REACT_APP_PUBLIC_URL}`,
               );
            } else {
               return null;
            }
         })}
         {Object.entries(bannerData).map(([key, value]) => {
            if (value.page_location === "career_top") {
               return <HeroBanner data={value} />;
            } else return null;
         })}
         {/* <Widthcontainer className={"career_width_container"}>
            <div className="career_banner fixed-width">
               <div className="career_banner_text">
                  <p className="career">careers </p>
                  <h1 className="career_header">
                     Find your <span>career</span>, You deserved
                  </h1>
                  <p className="career_sub_header">
                     Join our amazing team and help us build amazing connection. We’ve provide a
                     best for you, and we think you will too.
                  </p>
               </div>
               <div className="career_banner_img">
                  <img src={Img} alt="top" />
               </div>
            </div>
         </Widthcontainer> */}
         <Widthcontainer>
            <div className="career_wrapper fixed-width">
               <h1 className="career_title">
                  <span>DishHome</span> is the workplace for you.
               </h1>
               <div className="career_card_container">
                  <CareerCard
                     cardimg={insuarance2}
                     title="Competitive Compensation."
                     description={
                        "We offer competitive salary and benefits along with festival allowance, incentives, profit bonus, fringe benefits, etc. for your prolific performance."
                     }
                  />
                  <CareerCard
                     cardimg={insuarance3}
                     title="Training & Development."
                     description="We make sure our employees have the knowledge and skill they need to thrive on their career journeys and earn expert status on the job."
                  />
                  <CareerCard
                     cardimg={insuarance}
                     title="Insurance Options."
                     description={
                        "We provide access to excellent medical, accidental, group life and critical illness insurance options for you and your dependents."
                     }
                  />
               </div>
               <div className="career_container">
                  <div className="career_container_title_container ">
                     <div className="titleContainer">
                        <h2 className="title">DishHome Opportunity.</h2>
                        <p className="sub_caption">
                           Apply now to grab the best career opportunity by working with our global
                           team of experts.
                        </p>
                     </div>
                     {(data?.length > 0 || keyword) && (
                        <div className="searchContainer">
                           <InputGroup>
                              <InputGroup.Text id="search-icon">
                                 <BiSearchAlt />
                              </InputGroup.Text>

                              <input
                                 type="text"
                                 className="searchInput"
                                 placeholder="Search Career"
                                 onChange={(e) => setKeyword(e.target.value)}
                              />
                           </InputGroup>
                        </div>
                     )}
                  </div>
                  {data?.length === 0 ? (
                     keyword ? (
                        <p className="d-flex align-items-center justify-content-center w-100 fs-4 mt-4 ">
                           No result found!
                        </p>
                     ) : (
                        <p className="d-flex align-items-center justify-content-center w-100 fs-4 mt-4 ">
                           No open vacancies for now!
                        </p>
                     )
                  ) : (
                     <>
                        {Object.entries(categoryData).map(([key, value]) => {
                           return (
                              <CareerOpportunity
                                 title={value.title}
                                 data={data}
                                 setState={setFormState}
                                 setId={setId}
                              />
                           );
                        })}
                     </>
                  )}
               </div>
               <div className="career_footer_text">
                  <h1 className="caption">
                     Didn’t find the <span>role</span> you’re looking for?
                  </h1>
                  <p className="subcaption">
                     Don’t get discouraged. Send us your resume at{" "}
                     {Object.entries(recruitmentEmail).map(([key, value]) => {
                        return (
                           <>
                              {value?.title === "Career" && (
                                 <a className="cursor_pointer" href={`mailto:${value?.email}`}>
                                    {/* {console.log("contact", contactDetails)} */}
                                    {value?.email}
                                 </a>
                              )}
                           </>
                        );
                     })}
                     <span>
                        {" "}
                        so that in future we can contact you via your resume for better
                        co-ordination.
                     </span>
                  </p>
               </div>
            </div>
         </Widthcontainer>
      </Layout>
   );
};

export function CareerCard({ title, description, cardimg }) {
   return (
      <div className="career_card">
         <img src={cardimg} alt="" height={40} width={40} />
         <h2 className="card_title">{title}</h2>
         <p className="card_text">{description} </p>
      </div>
   );
}

export function CareerOpportunity({ title, data, setState, setId }) {
   const careers = [];
   return (
      <div className="career_opportunity">
         {/* <JobTitle title={"Hello"} no={2} /> */}
         {Object.entries(data).map(([key, value]) => {
            if (title === value.careerCategory.title) {
               careers.push(value);
            }
            return null;
         })}

         {careers?.length > 0 && <h1 className="career_opportunity_title">{title}</h1>}
         {careers.map((value) => {
            return (
               <JobTitle
                  title={value.title}
                  no={value.no_of_vacancy}
                  id={value._id}
                  setState={setState}
                  setId={setId}
               />
            );
         })}
      </div>
   );
}

export function JobTitle(props) {
   return (
      <div className="job_tab">
         <p
            className="job_title cursor_pointer"
            onClick={() => {
               props.setState(true);
               props.setId(props.id);
            }}
         >
            {" "}
            {props.title + " (" + props.no + ")"}
         </p>
         <button
            className="apply_now_buttton"
            onClick={() => {
               props.setState(true);
               props.setId(props.id);
            }}
         >
            Apply now
         </button>
      </div>
   );
}

export function JobApplication(props) {
   const [data, setData] = useState("");
   const [loading, setLoading] = useState(true);
   const [gender, setGender] = useState("male");
   const [resume, setResume] = useState(null);
   const [filename, setFilename] = useState(null);
   const [btnStatus, setBtnStatus] = useState(true);
   const [show, setShow] = useState(false);
   const [errorShow, setErrorShow] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [headingMessage, setHeadingMessage] = useState("");
   const reRef = useRef();

   useEffect(() => {
      async function fetchData() {
         const response = await get_single_career(props.id);
         props?.id && setData(response?.data?.data);
      }
      fetchData();
   }, [props.id]);

   useEffect(() => {
      if (data === "") {
         setLoading(true);
      } else {
         setLoading(false);
      }
   });

   const handleSubmit = async (values) => {
      setBtnStatus(false);
      let phone = values?.phone;
      if (values?.phone?.length > 10) {
         if (values?.phone?.charAt(0) === "0") {
            phone = phone.substring(1);
         } else if (values?.phone?.slice(0, 4) === "+977") {
            phone = phone?.slice(4, 18);
         }
      }
      if (btnStatus) {
         const captchaToken = await reRef.current.executeAsync();
         const answer = values?.answers?.map((data, key) => ({
            question: data?.question,
            answer: data?.answer,
         }));
         const response = await apply_for_job(
            props.id,
            values?.name,
            gender,
            values?.email,
            phone,
            resume,
            answer,
            captchaToken,
         );
         if (response?.data?.success) {
            setShow(true);
            setBtnStatus(true);
            setResume(null);
            setFilename(null);
            props.setState(false);
         } else {
            setErrorMessage(response?.response?.data?.errors[0]?.messages);
            setHeadingMessage(response?.response?.data?.message);
            setBtnStatus(true);
            setErrorShow(true);
         }
      }
   };

   const schema = yup.object().shape({
      name: yup
         .string()
         .required("Name is required")
         .min(2, "Name must be 2 characters long")
         .max(80)
         .matches(/^([a-zA-Z ]+)$/, "Characters Only allowed"),
      email: yup.string().email("Invalid email").required("Email is required").min(6).max(60),
      phone: yup
         .string()
         .required("Phone Number is required")
         .min(10, "Phone Number must be 10 characters long")
         .max(14, "Please Enter Valid Phone Number"),
      resume: yup.string().required("Resume is required"),
   });
   const questions = data?.questions
      ?.filter((data) => data?.status === true)
      ?.map((question, index) => ({
         question: question?.question,
         answer: "",
         type: question?.type,
         options: question?.options,
      }));

   var initialValues = {
      name: "",
      email: "",
      phone: "",
      resume: "",
      answers: [],
   };

   if (data)
      var initialValues = {
         name: "",
         email: "",
         phone: "",
         resume: "",
         answers: questions,
      };

   // console.log(initialValues);

   return (
      <>
         <MessageModal
            show={show}
            setShow={setShow}
            heading="Thank You"
            message="Your Job Application has been sent successfully"
            link="/"
            buttonText="Go Home"
         />
         <MessageModal
            show={errorShow}
            setShow={setErrorShow}
            heading={headingMessage}
            message={errorMessage}
            link="/"
            link2="/company/careers"
            type="error"
            buttonText="Go Home"
            button2="Go To Career "
            reload={true}
         />
         <Modal
            show={props.state}
            onHide={() => props.setState(false)}
            size={"xl"}
            centered
            bsPrefix=" job_application_modal  modal"
         >
            {/* <Modal.Header closeButton>Apply Now</Modal.Header> */}
            <Modal.Body className="m-0">
               <div className="job_application__wrapper">
                  {/* <span className="closeIcon" onClick={() => props.setState(false)}>
                     <AiOutlineCloseCircle style={{ cursor: "pointer" }} />
                  </span> */}
                  <div className="job__info">
                     <h4 className="jobtitle">
                        <p className="jobtitle">{data.title}</p>
                     </h4>
                     {/* <p className="jobdescription">
                     <b> Description:</b>
                  </p> */}
                     <div
                        dangerouslySetInnerHTML={{ __html: data.description }}
                        className="jobdescription"
                     ></div>
                     <p className="jobdescription_location">
                        Location:
                        <span className="careers-description"> {data.location}</span>
                     </p>
                     <p className="jobdescription">
                        No. of Vacancy :
                        <span className="careers-description"> {data.no_of_vacancy}</span>
                     </p>
                  </div>
                  <Formik
                     validationSchema={schema}
                     initialValues={initialValues}
                     onSubmit={handleSubmit}
                     enableReinitialize
                  >
                     {({ handleSubmit, setFieldValue, handleChange, values, touched, errors }) => (
                        <Form
                           className="job_application_container"
                           novalidate
                           autoComplete="false"
                           onSubmit={handleSubmit}
                        >
                           <label htmlFor="name" id="name">
                              Full Name
                           </label>
                           <input
                              type="text"
                              name="name"
                              id="name"
                              className="name_input"
                              placeholder=""
                              value={values?.name}
                              onChange={handleChange}
                              minLength="5"
                              maxLength={"80"}
                           />
                           {errors?.name ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.name}
                              </p>
                           ) : null}
                           <label htmlFor="email" id="email">
                              Email Address
                           </label>
                           <input
                              type="email"
                              name="email"
                              className="email_input"
                              placeholder=""
                              value={values?.email}
                              onChange={handleChange}
                              minLength="6"
                              maxLength={"60"}
                           />
                           {errors?.email ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.email}
                              </p>
                           ) : null}
                           <label htmlFor="phone" id="phone">
                              Phone Number
                           </label>
                           <input
                              type="tel"
                              name="phone"
                              className="ph_number_input"
                              placeholder=""
                              value={values?.phone}
                              maxLength={14}
                              onChange={(e) => {
                                 if (e?.nativeEvent?.data) {
                                    if (e?.nativeEvent?.data?.toString()?.match("[0-9+\b]")) {
                                       handleChange(e);
                                    }
                                    if (e?.nativeEvent?.data === null) {
                                       handleChange(e);
                                    }
                                 } else {
                                    handleChange(e);
                                 }
                              }}
                           />
                           {errors?.phone ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.phone}
                              </p>
                           ) : null}

                           <label htmlFor="gender" id="gender">
                              Gender
                           </label>
                           <div className="gender_checkbox_container">
                              <input
                                 type="radio"
                                 name="gender"
                                 id="male"
                                 value={"male"}
                                 defaultChecked
                                 onClick={(e) => {
                                    setGender(e.target.value);
                                 }}
                              />
                              <label htmlFor="male">Male</label>
                           </div>
                           <div className="gender_checkbox_container ">
                              <input
                                 type="radio"
                                 name="gender"
                                 id="female"
                                 value={"female"}
                                 onClick={(e) => {
                                    setGender(e.target.value);
                                 }}
                              />{" "}
                              <label htmlFor="female">Female</label>
                           </div>
                           <div className="gender_checkbox_container mb-2">
                              <input
                                 type="radio"
                                 name="gender"
                                 id="others"
                                 value={"others"}
                                 onClick={(e) => {
                                    setGender(e.target.value);
                                 }}
                              />{" "}
                              <label htmlFor="female">Others</label>
                           </div>
                           {values?.answers?.length > 0 &&
                              values?.answers?.map((data, index) => {
                                 return (
                                    <div key={index} className="d-flex flex-column">
                                       <label htmlFor={`question-${index}`}>{data?.question}</label>
                                       {data.type === "text" && (
                                          <Field
                                             type="text"
                                             name={`answers.${index}.answer`}
                                             onChange={handleChange}
                                             required
                                          />
                                       )}
                                       {data.type === "datepicker" && (
                                          <Field
                                             type="date"
                                             name={`answers.${index}.answer`}
                                             onChange={handleChange}
                                             required
                                          />
                                       )}
                                       {data.type === "radio" && (
                                          // <Field
                                          //    as="select"
                                          //    name={`answers.${index}.answer`}
                                          //    onChange={handleChange}
                                          //    required
                                          // >
                                          //    <option value="">Select an option</option>
                                          //    {data.options.map((option, optionIndex) => (
                                          //       <option key={optionIndex} value={option}>
                                          //          {option}
                                          //       </option>
                                          //    ))}
                                          // </Field>
                                          <div
                                             role="group"
                                             aria-labelledby="my-radio-group"
                                             className="d-flex flex-column"
                                          >
                                             {data.options.map((option, optionIndex) => (
                                                <label className="gender_checkbox_container">
                                                   <Field
                                                      type="radio"
                                                      name={`answers.${index}.answer`}
                                                      value={option}
                                                      required
                                                      style={{ width: "fit-contnet" }}
                                                   />
                                                   {option}
                                                </label>
                                             ))}
                                          </div>
                                       )}
                                       {/* <div>Picked: {values?.answers[index].answer}</div> */}
                                    </div>
                                 );
                              })}
                           <label htmlFor="resume" id="resume">
                              Resume
                           </label>
                           <input
                              type="file"
                              name="resume"
                              className="resume_input"
                              value={values?.resume}
                              onChange={(event) => {
                                 setFieldValue("resume", event.currentTarget?.value);
                                 setFilename(event.currentTarget.value);
                                 setResume(event.currentTarget.files[0]);
                              }}
                           />
                           {errors?.resume ? (
                              <p className="error_message" style={{ color: "#ed1c24" }}>
                                 {errors?.resume}
                              </p>
                           ) : null}
                           <div className="button_wrapper_modal">
                              <button
                                 type="button"
                                 className={"applynow_btn_cancel"}
                                 onClick={() => props.setState(false)}
                              >
                                 Cancel
                              </button>
                              <button
                                 type="submit"
                                 className={btnStatus ? "applynow_btn" : "applynow_btn_inactive"}
                              >
                                 {btnStatus ? "Apply Now" : "Sending ..."}
                              </button>
                           </div>
                           <Captcha reRef={reRef} />
                        </Form>
                     )}
                  </Formik>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
}

export default Careers;
